import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import Moment from 'moment';

import { vendorPackageActions } from '../../../../_actions';
import { RowStroked } from '../../../../_components';

import './Draft.scss';

class Draft extends Component {
	onDeleteDraft(id) {
		const { t } = this.props;

		if (window.confirm(t('confirm_to_delete_draft'))) {
			this.props.dispatch(vendorPackageActions.remove(true, id));
		}
	}

	render() {
		const { t, data } = this.props;

		return (
			<Row className="draft">
				<div className="flex-content">
					<div className="name">
						{data.name ? data.name : t('untitled_package')}
					</div>
					<div className="last-updated">
						<Row className="row-m-4">
							<Col xs={{ size: 'auto' }}>
								{t('last_updated_time')}
							</Col>
							<Col xs={{ size: 'auto' }}>
								{Moment.utc(data.updated_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}
							</Col>
						</Row>
					</div>
					<div className="actions">
						<RowStroked>
							<Col xs={{ size: 'auto' }}>
								<Link to={`/vendor/package/create/${data.id}`}>{t('edit_draft')}</Link>
							</Col>
							<Col xs={{ size: 'auto' }}>
								<Button color="link" onClick={() => this.onDeleteDraft(data.id)}>{t('delete_draft')}</Button>
							</Col>
						</RowStroked>
					</div>
				</div>
			</Row>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const translation = withNamespaces(['mypackages', 'default'])(Draft);
const connected = connect(mapStateToProps)(translation);
export { connected as Draft };