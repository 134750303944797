import ReactGA from 'react-ga';

export const analytics = {
	init,
	pageView,
	modal,
	event,
	set
};

const sendAnalytics = process.env.NODE_ENV !== 'development';
const isProd = process.env.REACT_APP_ENV === 'prod';

function init() {
	if (!sendAnalytics) return;


	ReactGA.initialize(process.env.REACT_APP_GA_ID, {
		debug: !isProd
	});
}

function pageView(path) {
	let resultPath = path;
	if (resultPath.includes('order')) {
		resultPath = resultPath.replace(/\/\d+/g, '/id');
	}

	if (!sendAnalytics) {
		console.info('[analytics] pageView: ', resultPath);
		return;
	}

	
	ReactGA.pageview(resultPath);
}

function modal(modal_name) {
	if (!sendAnalytics) {
		console.info('[analytics] modal: ', modal_name);
		return;
	}

	ReactGA.modalview(modal_name);
}

function event(params) {
	if (!sendAnalytics) {
		console.info('[analytics] event: ', params);
		return;
	}

	ReactGA.event(params);
}

function set(params) {
	if (!sendAnalytics) {
		console.info('[analytics] set: ', params);
		return;
	}

	ReactGA.set(params);
} 