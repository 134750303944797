import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, myCalendarConstants } from '../_constants';

export const myCalendarActions = {
	get,
	getClear,
	edit,
	editClear,
	getOrders,
	getOrdersClear
}

function get(start_date, end_date) {
	const requestOptions = api.requestOption(apiEndpoint.my_calendar.get, { start_date, end_date });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: myCalendarConstants.GET_REQUEST } }
    function success(responseData) { return { type: myCalendarConstants.GET_SUCCESS, responseData } }
    function failure(error) { return { type: myCalendarConstants.GET_FAILURE, error } }
}

function getClear() {
	return { type: myCalendarConstants.GET_CLEAR }
}

function edit(dates) {
	analytics.event({
		category: 'My Calendar',
		action: 'Edit',
		label: 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.my_calendar.edit, { dates });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: myCalendarConstants.EDIT_REQUEST } }
    function success(responseData) { return { type: myCalendarConstants.EDIT_SUCCESS, responseData } }
    function failure(error) { return { type: myCalendarConstants.EDIT_FAILURE, error } }
}

function editClear() {
	return { type: myCalendarConstants.EDIT_CLEAR }
}

function getOrders(start_date, end_date) {
	const requestOptions = api.requestOption(apiEndpoint.my_calendar.orders, { start_date, end_date });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: myCalendarConstants.GET_ORDERS_REQUEST } }
    function success(responseData) { return { type: myCalendarConstants.GET_ORDERS_SUCCESS, responseData } }
    function failure(error) { return { type: myCalendarConstants.GET_ORDERS_FAILURE, error } }
}

function getOrdersClear() {
	return { type: myCalendarConstants.GET_ORDERS_CLEAR }
}