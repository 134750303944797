import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { packageActions, vendorPackageActions } from '../../../../_actions';

import { PageContainer, Title, Desc, LoadingPage, ErrorPage } from '../../../../_components';

import { Location } from './Location';
import './Locations.scss';

class Locations extends Component {
	constructor(props) {
		super(props);

		const { match } = props;
		this.state = {
			isDraft: match.path.startsWith('/vendor/package/create'),
			isVendor: match.path.startsWith('/vendor'),
			tabIndex: 0,
			targetTabIndex: match.params.location_id
		}
	}

	componentDidMount() {
		const { isVendor, isDraft } = this.state;
		const id = this.props.match.params.id;
		if (isVendor) {
			this.props.dispatch(vendorPackageActions.getLocations(isDraft, id));
		} else {
			this.props.dispatch(packageActions.getLocations(id));
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const { location, package_details } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		if (package_details.locations.success && prevProps.package_details.locations.success !== package_details.locations.success && this.state.targetTabIndex) {
			var index = package_details.locations.list.findIndex((item) => item.id == this.state.targetTabIndex);
			if (index !== -1 && index !== this.state.tabIndex) {
				this.setState({ tabIndex: index, targetTabIndex: undefined });
			}
		}
	}
	
	onRouteChanged(props) {
		const { params } = props.match;

		this.setState({ targetTabIndex: params.location_id }, () => {
			const { isVendor, isDraft } = this.state;
			let id = params.id;

			if (isVendor) {
				this.props.dispatch(vendorPackageActions.getLocations(isDraft, id));
			} else {
				this.props.dispatch(packageActions.getLocations(id));
			}
		});
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
	}

	render() {
		const { t, package_details, vendor_package } = this.props;
		const { tabIndex, isVendor } = this.state;

		const isLoading = isVendor ? vendor_package.locations.loading : package_details.locations.loading;
		const error = isVendor ? vendor_package.locations.error : package_details.locations.error;
		const data = isVendor ? vendor_package.locations.list : package_details.locations.list;
			
		let vendor = undefined;
		if (data && data.length) {
			vendor = data[0].vendor;
		}

		return (
			<>
				{isLoading && <LoadingPage />}
				{error && <ErrorPage match={this.props.match} error={error} />}
				{data &&
					<PageContainer id="package_details_locations" fullPage={isVendor}>
						<Container className={isVendor ? 'mt-4' : ''}>
							<>
								<div className="title-container">
								<Title>{t('package_portfolio')}</Title>
								<Desc className="by">
									{t('by')}
									<Link to={`/package/vendor/${vendor.id}`}>
										{vendor ? vendor.nickname : ''}
									</Link>
								</Desc>
								</div>
								<div className="tabs">
									<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
										<TabList>
											{data.map((location, index) =>
												<Tab key={location.id}>{`${location.name} (${location.location_item_count})`}</Tab>
											)}
										</TabList>
										{data.map((location, index) =>
											<TabPanel key={location.id}>
												<Location title={location.name} items={location.items}></Location>
											</TabPanel>
										)}
									</Tabs>
								</div>
							</>
						</Container>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { package_details, vendor_package } = state; 
    return {
		package_details,
		vendor_package
    };
}

const translation = withNamespaces(['package', 'default'])(Locations);
const connected = connect(mapStateToProps)(translation);
export { connected as Locations };