import React from "react";
import { Link } from 'react-router-dom';

import { Desc, Title } from '../../../../../../_components';

import { BaseMessage } from '../../BaseMessage';

import './OrderMessage.scss';

export const OrderMessage = ({ t, message, className, systemMessageData, lang, ...props}) => {
	return (
		<BaseMessage
			message={message}
			className={`chat-message-system-order ${className ? className : ''}`}
			bubbleMarginSize="lg"
			{...props}
		>
			<Title size="xs" className="message-title">
				{systemMessageData.title}
			</Title>
			<Desc className="message">
				{systemMessageData.desc}
			</Desc>
			<hr />
			<div className="package">
				<div className="img-wrapper">
					<img src={systemMessageData.data.package_thumbnail} alt="" />
				</div>
				<div className="package-details">
					<Title size="xs" className="package-title">
						{systemMessageData.data.package_name}
					</Title>
					<Desc className="package-price">
						{systemMessageData.data[`package_currency_value_${lang.id}`] + systemMessageData.data.package_amount}
					</Desc>
				</div>
			</div>
			{systemMessageData.data.order_customer_remarks &&
				<>
					<hr />
					<Desc className="remarks">
						{systemMessageData.data.order_customer_remarks}
					</Desc>
				</>
			}
			{systemMessageData.url &&
				<Link to={systemMessageData.url} className="btn btn-sm btn-primary">
					{t(systemMessageData.btnTitleKey)}
				</Link>
			}
		</BaseMessage>
	);
}