import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

import { Loading } from '../../../_components';

import './Map.scss';

/* eslint-disable no-undef */

class Map extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bounds: null,
			center: props.center,
			markers: props.markers
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { center, markers } = nextProps;
		return { center, markers };
	}
	
	onBoundsChanged() {
		// this.setState({
		// 	bounds: this.map.getBounds(),
		// 	center: this.map.getCenter(),
		// })
	}

	render() {
		const { center, markers } = this.state;

		return (
			<GoogleMap
				ref={(ref) => this.map = ref}
				defaultZoom={15}
				center={center}
				onBoundsChanged={() => this.onBoundsChanged()}
			>
				{markers.map((marker, index) =>
					<Marker key={index} position={marker.position}>
						{/* <InfoBox
							options={{ closeBoxURL: ``, enableEventPropagation: true, pixelOffset: new google.maps.Size(-50, -90) }}
						>
							<div className="google-map-marker-infobox-wrapper" style={{ padding: '10px' }}>
								<div className="google-map-marker-infobox-content" style={{ fontSize: '14px' }}>
									{marker.name}
								</div>
							</div>
						</InfoBox> */}
						<InfoWindow>
							<div className="google-map-infowindow-content">{marker.name}</div>
						</InfoWindow>
					</Marker>
				)}
			</GoogleMap>
		)
	}
}

const composed = compose(
	withProps({
		googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB9CZzgIyjkh9KiulCd8-dHZtson4SeJ7o",
		loadingElement: <Loading />,
		containerElement: <div className="react-google-map-container" style={{ height: `400px` }} />,
		mapElement: <div className="react-google-map" style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)(Map);
export { composed as Map };