import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { DetailsContent } from './DetailsContent';
import { Locations } from './Locations';

export class DetailsContentRouter extends Component {
	render() {
		const { match, ...otherProps } = this.props;
		const isPreview = match.path.startsWith('/vendor');

		return (
			<Switch>
				<Route exact path={`${match.path}/portfolio`} render={props => (
					<Locations {...props} {...otherProps} isPreview={isPreview} />
				)} />
				<Route exact path={`${match.path}/portfolio/:location_id`} render={props => (
					<Locations {...props} {...otherProps} isPreview={isPreview} />
				)} />
				<Route exact path={`${match.path}/reviews`} render={props => (
					<DetailsContent {...props} {...otherProps} isPreview={isPreview} />
				)} />
				<Route exact path={`${match.path}`} render={props => (
					<DetailsContent {...props} {...otherProps} isPreview={isPreview} />
				)} />
				<Redirect to={`${isPreview? '/vendor' : ''}/error/404`} />
			</Switch>
		);
	}
}