import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col } from 'reactstrap';
import Moment from 'moment';
import { withNamespaces } from 'react-i18next';

import { savedItemsActions } from '../../_actions';
import { savedItemType } from '../../_constants';
import { history, auth as authHelper } from '../../_helpers';
import { Loading, Title, ProfilePicImg, ShareButton, VideoPlayer } from '../../_components';

import img_bookmark from '../../_common/img/home_article_icon_bookmark.svg';
import img_bookmarked from '../../_common/img/home_article_icon_bookmarked.svg';

import './ArticleContent.scss';

class ArticleContent extends Component {
	onBookmark() {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			const { item } = this.props;

			if (item.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsArticle(item.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsArticle(item.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	render() {
		const { item, t, saved_item_action, preview } = this.props;

		let htmlContent = preview? item.preview_content : item.content;
		const isLoading = saved_item_action.loading && saved_item_action.type == savedItemType.ARTICLE && saved_item_action.id == item.id;

		return (
			<div key={item.id} className="article">
				<Title size='sm'>
					{item.title}
				</Title>
				<Row className="header">
					<Col xs={{ size: true }} className="author">
						<ProfilePicImg src={item.profile_pic_url} alt={item.user_name} />
						<Row className="row-m-6 row-name">
							<Col xs={{ size: 'auto' }}>
								<div className="name">{item.user_name}</div>
							</Col>
							<Col xs={{ size: 'auto' }}>
								<div className="date">{Moment.utc(item.create_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY')}</div>
							</Col>
						</Row>
					</Col>
					<Col xs={{ size: 'auto' }} className="actions">
						<Row className="row-m-10">
							<Col xs={{ size: true }}>
								<Button color="img" onClick={() => this.onBookmark(item)} title={t('bookmark')}><img src={item.is_bookmarked? img_bookmarked : img_bookmark} alt={t('bookmark')} /></Button>
							</Col>
							<Col xs={{ size: true }}>
								<ShareButton
									url={window.location.href}
									title={item.title}
									body={`${item.title}`} //TODO: share content
									dropdownMenuAlignRight
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				{item.video_url &&
					<VideoPlayer
						disabledAutoPlay={preview}
						videoUrl={item.video_url}
					/>
				}
				<div className="content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>

				{isLoading && <Loading overlay />}
			</div>
		);
	}
}

function mapStateToProps(state) {
    const { auth, saved_item_action } = state; 
    return {
		auth,
		saved_item_action
    };
}

const translation = withNamespaces(['article', 'default'])(ArticleContent);
const connected = connect(mapStateToProps)(translation);
export { connected as ArticleContent };