import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { findDOMNode } from 'react-dom';

import './VideoPlayer.scss';

import img_video_control_btn_play from './img/details_video_icon_play.svg';
import img_video_control_btn_pause from './img/details_video_icon_pause.svg';
import img_video_control_btn_sound_off from './img/details_video_icon_sound_off.svg';
import img_video_control_btn_sound_on from './img/details_video_icon_sound_on.svg';
import img_video_control_btn_fullscreen from './img/details_video_icon_enlarge.svg';

class VideoPlayer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			videoPlaying: !props.disabledAutoPlay,
			videoPlayed: 0,
			videoLoaded: 0,
			videoDuration: 0,
			videoSeeking: false,
			videoMuted: false,
			videoVolume: 0.5,
			videoShowControl: false,
		}
	}

	onHoverPlayer(isEnter) {
		if (this.state.videoShowControl === isEnter) return;
		this.setState({ videoShowControl: isEnter });
	}

	videoRef(player) {
		this.videoPlayer = player;
	}

	onVideoPlayPause() {
		this.setState({ videoPlaying: !this.state.videoPlaying });
	}

	onVideoSeekMouseDown() {
		this.setState({ videoSeeking: true });
	}

	onVideoSeekChange(newValue) {
		this.setState({ videoPlayed: newValue });
	}

	onVideoSeekMouseUp() {
		this.setState({ videoSeeking: false });
		this.videoPlayer.seekTo(this.state.videoPlayed);
	}

	onVideoProgress(state) {
		// console.log('onProgress', state);
		// We only want to update time slider if we are not currently videoSeeking

		/*
			loaded: 1
			loadedSeconds: 221.761
			played: 0.5383667323267877
			playedSeconds: 119.38874492752075
		*/
		if (!this.state.videoSeeking) {
			this.setState({ videoLoaded: state.loaded, videoPlayed: state.played });
		}

		const { disableLoop } = this.props;
		if (!disableLoop) {
			const { videoDuration } = this.state;
			if ((1 - state.played) * videoDuration < 1) {
				this.videoPlayer.seekTo(0);
			}
		}
	}

	onVideoDuration(duration) {
		this.setState({ videoDuration: duration });
	}

	onVideoSetVolume(newValue) {
		this.setState({ videoVolume: newValue })
	}

	onVideoToggleMuted() {
		this.setState({ videoMuted: !this.state.videoMuted })
	}

	onVideoClickFullscreen() {
		screenfull.request(findDOMNode(this.videoPlayer));
	}

	onVideoError(e) {
		console.log('onVideoError', e);
		// this.props.dispatch(alertActions.error(e));
	}

	videoPlayedDurationDisplay(played, duration) {
		var durationH = Math.floor(duration / 3600);

		let format = durationH > 0 ? 'h:mm:ss' : 'mm:ss';
		let playedStr = Moment.duration(played * duration, 'seconds').format(format, {
			trim: false
		});
		let durationStr = Moment.duration(duration, 'seconds').format(format, {
			trim: false
		});

		return `${playedStr}/${durationStr}`;
	}

	render() {
		const { t, videoUrl, className, hideControls } = this.props;
		const { videoPlaying, videoVolume, videoMuted, videoShowControl, videoDuration, videoPlayed, videoLoaded } = this.state;
		return (
			<div className={`video-player ${className? className : ''}`}
				// onMouseEnter={() => this.onHoverPlayer(true)}
				// onMouseLeave={() => this.onHoverPlayer(false)}
			>
				<div className="react-player-wrapper">
				<ReactPlayer
					ref={(player) => this.videoRef(player)}
					className="react-player"
					width="100%" height="100%"
					url={videoUrl}
					playing={videoPlaying}
					volume={videoVolume}
					muted={videoMuted}
					// loop
					controls={!hideControls}
					// onReady={() => console.log('onReady')}
					// onStart={() => console.log('onStart')}
					// onPlay={() => console.log('onPlay')}
					// onPause={() => console.log('onPause')}
					// onBuffer={() => console.log('onBuffer')}
					// onSeek={e => console.log('onSeek', e)}
					// onEnded={() => console.log('onEnded')}
					onError={(e) => this.onVideoError(e)}
					onProgress={(state) => this.onVideoProgress(state)}
					onDuration={(duration) => this.onVideoDuration(duration)}
				/>
				</div>
				{/* <div className={"player-control" + (videoShowControl ? '' : ' hide')}>
					<Container className="container-p-4">
						<Row className="row-m-4">
							<Col sm={{ order: 1 }} xs={{ size: 'auto', order: 2 }}>
								<Button className="player-control-item btn-playpause" onClick={() => this.onVideoPlayPause()}>{videoPlaying ? <img src={img_video_control_btn_pause} alt={t('pause')} /> : <img src={img_video_control_btn_play} alt={t('play')} />}</Button>
							</Col>
							<Col sm={{ size: true, order: 2 }} xs={{ order: 1 }}>
								<Slider className="player-control-item" min={0} max={1} step={1 / (videoDuration ? videoDuration : 1000)} value={videoPlayed}
									onBeforeChange={() => this.onVideoSeekMouseDown()}
									onChange={(newValue) => this.onVideoSeekChange(newValue)}
									onAfterChange={() => this.onVideoSeekMouseUp()}
									railStyle={{ backgroundImage: `linear-gradient(to right, #00a4a625, #00a4a625 ${videoLoaded * 100}%, #ffffff25 ${videoLoaded * 100}%, #ffffff25)` }}
								/>
							</Col>
							<Col sm={{ order: 3 }} xs={{ size: 'auto', order: 4 }}>
								<Button className="player-control-item btn-mute" onClick={() => this.onVideoToggleMuted()}>{videoMuted ? <img src={img_video_control_btn_sound_off} alt={t('sound_off')} /> : <img src={img_video_control_btn_sound_on} alt={t('sound_on')} />}</Button>
							</Col>
							<Col sm={{ order: 4 }} xs={{ size: 'auto', order: 5 }}>
								<Slider className="player-control-item slider-volume" min={0} max={1} step={0.01} value={videoVolume} disabled={videoMuted}
									onChange={(newValue) => this.onVideoSetVolume(newValue)}
								/>
							</Col>
							<Col sm={{ order: 5 }} xs={{ size: 'auto', order: 6 }}>
								<Button className="player-control-item btn-fullscreen" onClick={() => this.onVideoClickFullscreen()}><img src={img_video_control_btn_fullscreen} alt={t('fullscreen')} /></Button>
							</Col>
							<Col sm={{ order: 6 }} xs={{ size: 'auto', order: 3 }}>
								<div className="player-control-item time">{this.videoPlayedDurationDisplay(videoPlayed, videoDuration)}</div>
							</Col>
						</Row>
					</Container>
				</div> */}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const translation = withNamespaces(['videoplayer', 'default'])(VideoPlayer);
const connected = connect(mapStateToProps)(translation);
export { connected as VideoPlayer };