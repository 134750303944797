import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import LinesEllipsis from 'react-lines-ellipsis';

import { app } from '../../../../../_helpers';
import { InputField, InputSelect, Calendar, Title, InputItem, InputItemLabel, BaseModal } from '../../../../../_components';
import './InputForm.scss';

import { vendorProfileActions } from '../../../../../_actions';

class InputForm extends Component {
	constructor(props){
		super(props);

		this.state ={
			vendor_disabled_dates: [],
			calendarDropdownIsOpen: false
		}
	}

	componentDidMount() {
		// this.props.dispatch(vendorProfileActions.getCalendarClear());
	}
	
	toggleCalendarDropdown() {
		this.setState({ calendarDropdownIsOpen: !this.state.calendarDropdownIsOpen });
	}

	calendarIsDayDisabled(day) {
		if (!this.props.vendor_calendar.list) return false;

		let result = this.props.vendor_calendar.list.some((item) => {
			return day.isSame(Moment(item.date.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS'), 'day');
		});
		return result || day.isBefore(Moment());
	}

	calendarOnMonthChange(month, visibleMonths) {
		if (!this.props.data) return;
		if (this.props.data && this.props.data.isDraft) return;

		const monthStart = Moment(visibleMonths[0]).startOf('month');
		const monthEnd = Moment(visibleMonths[visibleMonths.length - 1]).endOf('month');
		let start_date = Moment(monthStart).startOf('week');
		let end_date = Moment(monthEnd).endOf('week');

		if (Moment(end_date).isBefore(Moment(), 'day')) {
			return;
		}

		if (Moment(start_date).isBefore(Moment(), 'day')) {
			start_date = Moment();
		}

		this.props.dispatch(vendorProfileActions.getCalendar(this.props.data.vendor.id, start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')));
	}

	render() {
		const { t, data, submitting, selectedDate } = this.props;
		const { calendarDropdownIsOpen } = this.state;

		const schema = Yup.object().shape({
			shoot_date: Yup.mixed().required(t('required')),
		});

		return (
			<Formik
				initialValues={{
					'shoot_date': selectedDate ? selectedDate.format('YYYY-MM-DD') : '',
					'remarks': ''
				}}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setFieldTouched
					/* and other goodies */
				}) => (
						<Form id="package_details_makebooking_modal_form" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('make_a_booking')}</Title>
							</ModalHeader>
							<ModalBody>
								<Container>
									<Row className="package">
										<Col xs={{ size: 4 }}>
											<img src={data.video_image_url} alt={data.name} />
										</Col>
										<Col xs={{ size: 8 }}>
											<div className="package-details">
												<LinesEllipsis
													className="name"
													text={data.name}
													maxLine='2'
													ellipsis='...'
													trimRight
													basedOn='words'
												/>
												<div className="vendor">
													{`${t('by')} ${data.vendor.nickname}`}
												</div>
												<div className="price-wrapper">
													<div className="price">
														{app.toCurrencyString(data.user_currency.value, data.user_currency_amount)}
													</div>
												</div>
											</div>
										</Col>
									</Row>
									<InputItem className="shooting_date">
										<InputItemLabel>
											{t('shooting_date')}
										</InputItemLabel>
										<Button className={`dropdown-toggle ${values.shoot_date ? '' : 'placeholder'} ${(touched.shoot_date && errors.shoot_date) ? "input-error" : ""}`} onClick={() => {
											setFieldTouched('shoot_date', true);
											this.toggleCalendarDropdown();
										}}>
											{values.shoot_date ? values.shoot_date : t('select')}
										</Button>
										<ErrorMessage name="shoot_date" component="span" className="error" />
									</InputItem>
									<InputItem className="remarks">
										<InputItemLabel>
											{t('remarks')}
										</InputItemLabel>
										<InputField component="textarea" type="text" name="remarks" placeholder={t('remarks_hint')} t={t} />
									</InputItem>
								</Container>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
							<BaseModal
								id="package_details_makebooking_modal_form_modal_calendar"
								className="modal-calendar"
								size="sm"
								isOpen={calendarDropdownIsOpen}
								toggle={() => this.toggleCalendarDropdown()}
							>
								<ModalHeader toggle={() => this.toggleCalendarDropdown()}>
									<Title>{t('shooting_date')}</Title>
								</ModalHeader>
								<ModalBody>
									<Calendar
										onMonthChange={(month, visibleMonths) => this.calendarOnMonthChange(month, visibleMonths)}
										onDayClick={(day) => {
											setFieldValue('shoot_date', day.format('YYYY-MM-DD'));
											setFieldTouched('shoot_date', true);
											this.toggleCalendarDropdown();
										}}
										isDayDisabled={(day) => this.calendarIsDayDisabled(day)}
										isDaySelected={(day) => {
											return values.shoot_date ? day.isSame(values.shoot_date) : false;
										}}
									/>
								</ModalBody>
							</BaseModal>
						</Form>
					)}
			</Formik>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_calendar } = state;
    return {
		vendor_calendar
    };
}

const translation = withNamespaces(['package', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
export { connected as InputForm };