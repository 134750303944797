import { alertConstants } from '../_constants';
import { toast } from 'react-toastify';

export function alert(state = {}, action) {
    switch (action.type) {
	case alertConstants.SUCCESS:
		toast.success(action.message);
        return {
        type: 'alert-success',
        message: action.message
        };
	case alertConstants.ERROR:
		toast.error(action.message);
        return {
        type: 'alert-danger',
        message: action.message
        };
	case alertConstants.CLEAR:
		toast.dismiss();
        return {};
    default:
        return state
    }
}
