import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { userActions } from '../../../_actions';
import { ActionModal } from '../../../_components';
import { SendCode, SendCodeType } from '../SendCode';
import { NewPassword } from './NewPassword';

import './ForgotPw.scss';

export const ForgotPwUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class ForgotPw extends Component {
	onSubmitResetPasswordVerifyCode(values, { setSubmitting }) {
		this.props.dispatch(userActions.resetPasswordVerifyCode(values));
	}

	onSubmitResetPasswordByCode(values, { setSubmitting }) {
		const { forgot_password } = this.props;
		values['user_type'] = forgot_password.verifyCode.user_type;
		values['email'] = forgot_password.verifyCode.email;
		values['temp_code'] = forgot_password.verifyCode.temp_code;
		this.props.dispatch(userActions.resetPasswordByCode(values));
	}

	render() {
		const { t, isOpen, userType, forgot_password } = this.props;

		return (
			<ActionModal
				id="user_forgotpw_modal"
				confirmComponent={
					forgot_password.verifyCode.success ?
						<NewPassword
							onSubmit={(values, { setSubmitting }) => this.onSubmitResetPasswordByCode(values, { setSubmitting })}
							submitting={forgot_password.loading}
							toggle={() => this.props.toggle()}
						/>
						:
						<SendCode
							type={SendCodeType.forgotPassword}
							title={t('reset_password.title')}
							userType={userType}
							desc={t('reset_password.desc')}
							submitBtnTitle={t('continue')}
							onSubmit={(values, { setSubmitting }) => this.onSubmitResetPasswordVerifyCode(values, { setSubmitting })}
							submitting={forgot_password.verifyCode.loading}
							toggle={() => this.props.toggle()}
						/>
				}
				successTitle={t('reset_password.success.title')}
				successDesc={t('reset_password.success.desc')}
				isLoading={forgot_password.loading}
				isSuccess={forgot_password.success}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(userActions.resetPasswordByCodeClear());
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { forgot_password } = state;
    return {
		forgot_password,
    };
}

const translation = withNamespaces(['resetpassword', 'default'])(ForgotPw);
const connected = connect(mapStateToProps)(translation);
export { connected as ForgotPw };