import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { packageActions } from '../../../_actions';
import { Title, Loading } from '../../../_components';
import { Section } from '../../Section';
import { HomeSwiper } from '../../HomeSwiper';

import './Destinations.scss';

class Destinations extends Component {
	componentDidMount() {
		this.props.dispatch(packageActions.getDestinations());
	}

	render() {
		const { t, package_destinations } = this.props;

		return (
			<>
				{package_destinations.loading && <Loading />}
				{package_destinations.error && <Loading error={package_destinations.error} />}
				{package_destinations.featured &&
					<Section className="home-section-destinations">
						<Title size="xs" className="section-title">{t('customer.featured_destinations')}</Title>
						<HomeSwiper swiperParams={{
							slidesPerView: 5,
							hideNavs: package_destinations.featured.length <= 5,
							hideDots: package_destinations.featured.length <= 5,
							loop: package_destinations.featured.length > 5,
							breakpoints: {
								576: {
									slidesPerView: 2,
									hideNavs: package_destinations.featured.length <= 2,
									hideDots: package_destinations.featured.length <= 2,
									loop: package_destinations.featured.length > 2,
								},
								768: {
									slidesPerView: 3,
									hideNavs: package_destinations.featured.length <= 3,
									hideDots: package_destinations.featured.length <= 3,
									loop: package_destinations.featured.length > 3,
								},
								992: {
									slidesPerView: 3,
									hideNavs: package_destinations.featured.length <= 3,
									hideDots: package_destinations.featured.length <= 3,
									loop: package_destinations.featured.length > 3,
								},
								1200: {
									slidesPerView: 4,
									hideNavs: package_destinations.featured.length <= 4,
									hideDots: package_destinations.featured.length <= 4,
									loop: package_destinations.featured.length > 4,
								},
							},
						}}>
							{package_destinations.featured.map((item, index) =>
								<div key={item.id} className="destination">
									<Link to={`/packages/destination/${item.id}`}>
										<div className="content-wrapper">
											<div className="img-wrapper">
												<img src={item.responsive_image_url} alt="" />
												<div className="gradient"></div>
											</div>
											<div className="title-wrapper">
												<Title size="xs">{item.value}</Title>
											</div>
										</div>
									</Link>
								</div>
							)}
						</HomeSwiper>
					</Section>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { package_destinations } = state;
	return {
		package_destinations,
	};
}

const translation = withNamespaces(['home', 'default'])(Destinations);
const connected = connect(mapStateToProps)(translation);
export { connected as Destinations };