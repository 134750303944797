import { vendorPackageConstants, packageStatus, userConstants } from '../_constants';

const vendorPackagesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	total: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	hasMore: true
}
export function vendor_packages(state = vendorPackagesInitialState, action) {
	switch (action.type) {
		case vendorPackageConstants.GET_LIST_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...vendorPackagesInitialState };
		case vendorPackageConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false };
		case vendorPackageConstants.GET_LIST_SUCCESS: {
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.packages;
			} else {
				list = list.concat(action.responseData.packages);
			}
			return {
				...state,
				loading: false,
				success: true,
				list,
				total: action.responseData.meta.pagination.total,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				error: undefined
			};
		}
		case vendorPackageConstants.GET_LIST_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case vendorPackageConstants.GET_COUNT_SUCCESS:
			return { ...state, total: action.responseData };

		case vendorPackageConstants.REMOVE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.filter((item) => item.id !== action.id);
				return { ...state, list: updatedList };
			}
			return state;
		}

		case vendorPackageConstants.PUBLISH_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.status = action.publish ? packageStatus.published : packageStatus.unpublished;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		default:
			return state
	}
}

const vendorDraftsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	total: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	hasMore: true
}

export function vendor_drafts(state = vendorDraftsInitialState, action) {
	switch (action.type) {
		case vendorPackageConstants.GET_DRAFTS_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...vendorDraftsInitialState };
		case vendorPackageConstants.GET_DRAFTS_REQUEST:
			return { ...state, loading: true, success: false };
		case vendorPackageConstants.GET_DRAFTS_SUCCESS: {
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.drafts;
			} else {
				list = list.concat(action.responseData.drafts);
			}
			return {
				...state,
				loading: false,
				success: true,
				list,
				total: action.responseData.meta.pagination.total,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				error: undefined
			};
		}
		case vendorPackageConstants.GET_DRAFTS_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case vendorPackageConstants.GET_DRAFTS_COUNT_SUCCESS:
			return { ...state, total: action.responseData };

		case vendorPackageConstants.REMOVE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.filter((item) => item.id !== action.id);
				return { ...state, list: updatedList };
			}
			return state;
		}

		case vendorPackageConstants.PUBLISH_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.status = action.publish ? packageStatus.published : packageStatus.unpublished;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		default:
			return state
	}
}

const vendorPackageLocationsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const vendorCreatePackageFormInfoInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const vendorPackageSaveInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const vendorPackageEditInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const vendorPackageRemoveInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const vendorPackagePublishInitialState = {
	loading: false,
	success: false,
	publish: undefined,
	error: undefined,
}

const vendorPackageInitialState = {
	isDraft: undefined,
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	locations: vendorPackageLocationsInitialState,
	form_info: vendorCreatePackageFormInfoInitialState,
	save: vendorPackageSaveInitialState,
	edit: vendorPackageEditInitialState,
	remove: vendorPackageRemoveInitialState,
	publish: vendorPackagePublishInitialState,
}

export function vendor_package(state = vendorPackageInitialState, action) {
	switch (action.type) {
		case vendorPackageConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...vendorPackageInitialState };
		case vendorPackageConstants.GET_REQUEST: {
			let updateFromState = state.id == action.id && state.isDraft == action.isDraft ? state : vendorPackageInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, isDraft: action.isDraft };
		}
		case vendorPackageConstants.GET_SUCCESS: {
			let data = action.responseData;
			data.isDraft = action.isDraft;
			return { ...state, loading: false, success: true, id: action.id, data, isDraft: action.isDraft };
		}
		case vendorPackageConstants.GET_FAILURE:
			return { ...state, loading: false, success: false, id: action.id, error: action.error, isDraft: action.isDraft };

		case vendorPackageConstants.GET_LOCATIONS_CLEAR: {
			let updateFromState = state.id == action.id ? state.locations : vendorPackageLocationsInitialState;
			return { ...state, locations: updateFromState };
		}
		case vendorPackageConstants.GET_LOCATIONS_REQUEST:
			return {
				...state,
				id: action.id,
				isDraft: action.isDraft,
				locations: {
					...state.locations,
					loading: true,
					success: false
				}
			};
		case vendorPackageConstants.GET_LOCATIONS_SUCCESS:
			return {
				...state,
				id: action.id,
				isDraft: action.isDraft,
				locations: {
					...state.locations,
					loading: false,
					success: true,
					list: action.responseData,
					error: undefined
				}
			};
		case vendorPackageConstants.GET_LOCATIONS_FAILURE:
			return {
				...state,
				id: action.id,
				isDraft: action.isDraft,
				locations: {
					...state.locations,
					loading: false,
					success: false,
					list: undefined,
					error: action.error
				}
			};

		case vendorPackageConstants.GET_FORM_INFO_CLEAR:
			return { ...state, form_info: vendorCreatePackageFormInfoInitialState };
		case vendorPackageConstants.GET_FORM_INFO_REQUEST:
			return { ...state, form_info: { ...vendorCreatePackageFormInfoInitialState, loading: true } };
		case vendorPackageConstants.GET_FORM_INFO_SUCCESS:
			return { ...state, form_info: { ...vendorCreatePackageFormInfoInitialState, data: action.responseData } };
		case vendorPackageConstants.GET_FORM_INFO_FAILURE:
			return { ...state, form_info: { ...vendorCreatePackageFormInfoInitialState, error: action.error } };

		case vendorPackageConstants.SAVE_CLEAR:
			return { ...state, id: action.id, save: vendorPackageSaveInitialState };
		case vendorPackageConstants.SAVE_REQUEST:
			return { ...state, id: action.id, save: { ...vendorPackageSaveInitialState, loading: true } };
		case vendorPackageConstants.SAVE_SUCCESS: {
			let data = action.responseData;
			data.isDraft = action.isDraft;
			return { ...state, id: action.id, save: { ...vendorPackageSaveInitialState, success: true, data } };
		}
		case vendorPackageConstants.SAVE_FAILURE:
			return { ...state, id: action.id, save: { ...vendorPackageSaveInitialState, error: action.error } };

		case vendorPackageConstants.EDIT_CLEAR:
			return { ...state, id: action.id, edit: vendorPackageEditInitialState };
		case vendorPackageConstants.EDIT_REQUEST:
			return { ...state, id: action.id, edit: { ...vendorPackageEditInitialState, loading: true } };
		case vendorPackageConstants.EDIT_SUCCESS: {
			let data = action.responseData;
			data.isDraft = action.isDraft;
			return { ...state, id: action.id, edit: { ...vendorPackageEditInitialState, success: true, data } };
		}
		case vendorPackageConstants.EDIT_FAILURE:
			return { ...state, id: action.id, edit: { ...vendorPackageEditInitialState, error: action.error } };

		case vendorPackageConstants.REMOVE_CLEAR:
			return { ...state, id: action.id, remove: vendorPackageRemoveInitialState };
		case vendorPackageConstants.REMOVE_REQUEST:
			return { ...state, id: action.id, remove: { ...vendorPackageRemoveInitialState, loading: true } };
		case vendorPackageConstants.REMOVE_SUCCESS:
			return { ...state, id: action.id, remove: { ...vendorPackageRemoveInitialState, success: true } };
		case vendorPackageConstants.REMOVE_FAILURE:
			return { ...state, id: action.id, remove: { ...vendorPackageRemoveInitialState, error: action.error } };

		case vendorPackageConstants.PUBLISH_CLEAR:
			return { ...state, id: action.id, publish: vendorPackagePublishInitialState };
		case vendorPackageConstants.PUBLISH_REQUEST:
			return { ...state, id: action.id, publish: { ...vendorPackagePublishInitialState, loading: true } };
		case vendorPackageConstants.PUBLISH_SUCCESS: {
			let data = state.data;
			if (data) {
				data.status = action.publish? packageStatus.published : packageStatus.unpublished;
			}
			return { ...state, id: action.id, data, publish: { ...vendorPackagePublishInitialState, success: true, publish: action.publish } };
		}
		case vendorPackageConstants.PUBLISH_FAILURE:
			return { ...state, id: action.id, publish: { ...vendorPackagePublishInitialState, error: action.error } };


		default:
			return state
	}
}

const vendorPackageRevenueInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function vendor_package_revenue(state = vendorPackageRevenueInitialState, action) {
	switch (action.type) {
		case vendorPackageConstants.GET_REVENUE_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...vendorCreatePackageFormInfoInitialState };
		case vendorPackageConstants.GET_REVENUE_REQUEST:
			return { ...vendorCreatePackageFormInfoInitialState, loading: true };
		case vendorPackageConstants.GET_REVENUE_SUCCESS:
			return { ...vendorCreatePackageFormInfoInitialState, success: true, data: action.responseData };
		case vendorPackageConstants.GET_REVENUE_FAILURE:
			return { ...vendorCreatePackageFormInfoInitialState, error: action.error };

		default:
			return state
	}
}