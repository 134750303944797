import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, BaseModal, Desc } from '../../../../_components';
import { payoutStatus, getPayoutStatusNameByStatus } from '../../../../_constants';

import './StatusInfoModal.scss';

class StatusInfoModal extends Component {
	render() {
		const { t, status } = this.props;
		let allStatuses = [];

	for (var i = 1; i <= payoutStatus.fail; i ++) {
			allStatuses.push({
				status: i,
				title: t(`status.${getPayoutStatusNameByStatus(i)}`),
				desc: t(`status_desc.${getPayoutStatusNameByStatus(i)}`),
			});
		}

		return (
			<BaseModal
				id="payout_status_info_modal"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>
						{t('status.all_statuses')}
					</Title>
				</ModalHeader>
				<ModalBody>
					{allStatuses.map(item =>
						<div key={item.status} className={`status ${(status !== undefined && status === item.status) ? 'status-bold' : ''}`}>
							<Title size="xs">
								{item.title}
							</Title>
							<Desc>
								{item.desc}
							</Desc>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => this.props.toggle()}>{t('ok')}</Button>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['payout', 'default'])(StatusInfoModal);
export { translation as StatusInfoModal };