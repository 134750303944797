import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Container, Row, Col } from 'reactstrap';

import { vendorActions } from '../../_actions';
import { ResultsGrid, Title, InputItem, InputItemLabel, PageContainer } from '../../_components';

import './Search.scss';

import { GridItem } from '../GridItem';

class Search extends Component {

	constructor(props) {
		super(props);

		this.state = {
			city: undefined,
			name: '',
		};	
	}

	componentDidMount() {
		this.props.dispatch(vendorActions.searchClear());
		this.props.dispatch(vendorActions.getCityOptionsClear());
		this.props.dispatch(vendorActions.getCityOptions());
	}

	onSelectCity(city) {
		if (this.state.city === city) return;
		this.setState({ city });
	}

	onSearch() {
		this.infiniteScrollComponent.reload();
	}

    render() {
		const { t, vendors, vendor_profile_options } = this.props;
		const { city, name } = this.state;

		let cities = [{ id: null, value: t('all_cities'), items: [] }];
		if (vendor_profile_options.cities.list) {
			cities = cities.concat(vendor_profile_options.cities.list);
		}

		return (
			<PageContainer id="vendor_search">
				<div className="header">
					<Container>
						<Title>{t('search_vendors')}</Title>
						<Row>
							<Col xl={{ size: 3 }}>
								<InputItem className="first-input-item">
									<InputItemLabel>{t('city')}</InputItemLabel>
									<UncontrolledButtonDropdown>
										<DropdownToggle caret className="btn-outline-primary">
											{city ? city.value : t('all_cities')}
										</DropdownToggle>
										<DropdownMenu>
											{cities.map((item, index) =>
												<DropdownItem key={item.id} onClick={() => this.onSelectCity(item)}>{item.value}</DropdownItem>
											)}
										</DropdownMenu>
									</UncontrolledButtonDropdown>
								</InputItem>
							</Col>
							<Col xl={{ size: 4 }}>
								<InputItem className="last-input-item">
									<InputItemLabel>{t('name')}</InputItemLabel>
									<input
									type="text"
									placeholder={t('enter_keywords')}
									value={name}
									onChange={(e) => {
										let text = e.target.value;
										this.setState({ name: text });
									}} />
								</InputItem>
							</Col>
							<Col xl={{ size: 2 }} className="col-submit">
								<Button onClick={() => this.onSearch()}>{t('search')}</Button>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="results-container">
					<ResultsGrid
						infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
						disableInitialLoad={true}
						showHeader={true}
						noOfCol_xxl={4}
						noOfCol_xl={3}
						noOfCol_lg={3}
						noOfCol_md={2}
						noOfCol_sm={1}
						loadMoreProp={vendors}
						onLoadMore={(nextPage) => {
							this.props.dispatch(vendorActions.search(city, name, nextPage));
						}}
						renderItem={(item) => {
							return <GridItem item={item} hideBookmark />;
						}}
						noResultDesc={t('no_result_found')}
					/>
				</Container>
			</PageContainer>
		)
	}
}

function mapStateToProps(state) {
    const { vendors, vendor_profile_options } = state; 
    return {
		vendors,
		vendor_profile_options
    };
}

const translation = withNamespaces(['vendor', 'default'])(Search);
const connected = connect(mapStateToProps)(translation);
export { connected as Search };