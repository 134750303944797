import React from 'react';
import { Button } from 'reactstrap';

import './GeneralGridItemContent.scss';

import { Loading } from '../Loading';

import img_bookmark from '../../_common/img/home_article_icon_bookmark.svg';
import img_bookmarked from '../../_common/img/home_article_icon_bookmarked.svg';
import img_disabled from '../../_common/img/icon_unavailable_item.svg';

export const GeneralGridItemContent = ({ className, children, isLoading, isBookmarked, onBookmark, hideBookmark, isDisabled, disabledTitle, onClick, ...props }) => {

	return (
		<div
			className={`grid-item-content ${isDisabled ? 'disabled' : ''} ${className ? className : ''}`}
			onClick={() => {
				if (!isDisabled) {
					onClick();
				}
			}}
			{...props}
		>
			{isDisabled ?
				<>
					<div className="img-wrapper img-wrapper-disabled">
						<div className="disabled-img-content-wrapper">
							<div className="disabled-img-content">
								<img src={img_disabled} alt="" />
							</div>
						</div>
					</div>
					<div className="disabled-title">{disabledTitle}</div>
				</>
				:
				children
			}

			{!hideBookmark &&
				<Button color="link" className="grid-item-btn-bookmark" onClick={(e) => {
					e.stopPropagation();
					onBookmark();
				}}>
					<img src={isBookmarked ? img_bookmarked : img_bookmark} alt="" />
				</Button>
			}

			{isLoading && <Loading overlay />}
		</div>
	);
}