import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect as connectFormik } from 'formik';
import { Row, Col } from 'reactstrap';

import { app } from '../../../../../_helpers';
import { InputField, Title, InputItem, InputItemLabel } from '../../../../../_components';
import { RevenueBreakdown } from '../../../../RevenueBreakdown';

import './InputForm.scss';

class InputForm extends Component {
	render() {
		const { t, data, formik } = this.props;
		const { values, setFieldValue, errors } = formik;

		return (
			<div id="vendor_order_details_summary_form">
				<div className="breakdown">
					<InputItem className="hide-error-msg">
						<Row>
							<Col md={{ size: 7 }}>
								<InputItemLabel>{data.package_name}</InputItemLabel>
							</Col>
							<Col md={{ size: 5 }}>
								<Row className="row-m-8">
									<Col xs={{ size: 'auto' }} sm={{ size: 3 }}>
										<InputItemLabel className="currency">
											{app.toCurrencyString(data.currency.value, 0).replace(/[\d., ]/gi, '')}
										</InputItemLabel>
									</Col>
									<Col xs={{ size: true }} sm={{ size: 9 }}>
										<InputField type="number" name="package_amount" placeholder={t('price')} t={t} />
									</Col>
								</Row>
							</Col>
						</Row>
					</InputItem>
					<InputItem className="hide-error-msg">
						<Row>
							<Col md={{ size: 7 }}>
								<InputItemLabel>{t('additional_items_&_charges')}</InputItemLabel>
							</Col>
							<Col md={{ size: 5 }}>
								<Row className="row-m-8">
									<Col xs={{ size: 'auto' }} sm={{ size: 3 }}>
										<InputItemLabel className="currency">
											{app.toCurrencyString(data.currency.value, 0).replace(/[\d., ]/gi, '')}
										</InputItemLabel>
									</Col>
									<Col xs={{ size: true }} sm={{ size: 9 }}>
										<InputField type="number" name="additional_amount" t={t} />
									</Col>
								</Row>
							</Col>
						</Row>
					</InputItem>
					{errors.additional_amount &&
						<InputItem className="input-item-error">
							<span className="error">{errors.additional_amount}</span>
						</InputItem>
					}
				</div>
				<hr />
				<InputItem className="total">
					<Row>
						<Col xs={{ size: true }}>
							{t('total')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(data.currency.value, Number(values.package_amount) + Number(values.additional_amount))}
						</Col>
					</Row>
				</InputItem>
				<RevenueBreakdown
				currency_id={data.currency.id}
				amount={Number(values.package_amount) + Number(values.additional_amount)}
				onUpdate={(revenueData) => {
					setFieldValue('revenue', revenueData.revenue);
				}}
				/>
				<hr />
				<InputItem className="vendor-remarks">
					<Title size='sm'>{t('vendor_remarks')}</Title>
					<InputField component="textarea" type="text" name="remarks" placeholder={t('remarks_hint')} t={t} />
				</InputItem>
			</div>
		);
	}
}

const translation = withNamespaces(['order', 'payment', 'package', 'default'])(InputForm);
const connectedFormik = connectFormik(translation);
export { connectedFormik as InputForm };