import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { connect as connectFormik } from 'formik';

import { userActions, vendorVerificationActions, vendorActions } from '../../../../_actions';
import { InputField, InputFilePreview, InputSelect, Loading, InputItemLabel, InputItem, Desc } from '../../../../_components';
import { getVendorVerificationStatusNameByStatus, vendorVerificationStatus } from '../../../../_constants';

import './Profile.scss';

import { VerifyModal } from './VerifyModal';

class Profile extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			verificationModalIsOpen: false
		}
	}
	
	componentDidMount() {
		this.props.dispatch(vendorActions.getCityOptions());
		this.props.dispatch(vendorActions.getLanguageOptions());

		this.props.dispatch(userActions.getUser());
		this.props.dispatch(vendorVerificationActions.get());
	}

	componentDidUpdate(prevProps, prevState) {
		const { vendor_verification } = this.props;

		if (vendor_verification.edit.success && vendor_verification.edit.success !== prevProps.vendor_verification.edit.success) {
			this.props.dispatch(userActions.getUser());
			this.props.dispatch(vendorVerificationActions.get());
		}
	}

	onVerify() {
		this.setState({ verificationModalIsOpen: true });
	}

	render() {
		const {
			t, user,
			edit_profile,
			vendor_verification,
			vendor_profile_options,
			formik
		 } = this.props;
		 const {
			values,
			setFieldValue,
			setFieldTouched,
			handleSubmit
		 } = formik;

		 const { verificationModalIsOpen } = this.state;

			let verificationStatusName = getVendorVerificationStatusNameByStatus(user.verification_status);
			let verifyBtnKey = 'verify';
			switch (user.verification_status) {
				case vendorVerificationStatus.not_verified:
					verifyBtnKey = 'verify';
					break;
				case vendorVerificationStatus.verifying:
					verifyBtnKey = 'view_edit_details';
					break;
				case vendorVerificationStatus.verified:
	
					break;
				case vendorVerificationStatus.failed:
					verifyBtnKey = 'verify_again';
					break;
				default:
					break;
			}

		return (
			<div id="vendor_myprofile_vendor_profile_profile">
				{(vendor_profile_options.cities.loading || vendor_profile_options.languages.loading) && <Loading />}
				{(vendor_profile_options.cities.error || vendor_profile_options.languages.error) && <Loading error={vendor_profile_options.cities.error ? vendor_profile_options.cities.error : vendor_profile_options.languages.error} />}
				{vendor_profile_options.cities.list && vendor_profile_options.languages.list &&
					<>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }}>
									<InputItemLabel className="required">{t('nickname')}</InputItemLabel>
								</Col>
								<Col lg={{ size: 9 }}>
									<InputField type="text" name="nickname" t={t} />
								</Col>
							</Row>
						</InputItem>
						<InputItem className="input-item-verify">
							<Row>
								<Col lg={{ size: 3 }}>
									<div className="required">{t('verify')}</div>
								</Col>
								<Col lg={{ size: 9 }}>
									<div className={`row-inner verify-status ${verificationStatusName}`}>
										{t(`status.${verificationStatusName}`)}
									</div>
									{user.verification_status === vendorVerificationStatus.failed && vendor_verification.data &&
										<Desc className={`row-inner verify-status-failed-reason ${verificationStatusName}`}>
											{vendor_verification.data.feedback}
										</Desc>
									}
									{user.verification_status !== vendorVerificationStatus.verified &&
										<div className="row-inner">
											<Button color="link" onClick={() => this.onVerify()}>
												{t(`${verifyBtnKey}`)}
											</Button>
										</div>
									}
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }}>
									<InputItemLabel>{t('profile_pic')}</InputItemLabel>
								</Col>
								<Col lg={{ size: 9 }}>
									<div className="row-inner">
										<InputFilePreview file={values.profile_pic} />
										{values.profile_pic ? null : <img className="profile-pic-preview" src={user.profile_pic_url} alt={user.nickname} />}
									</div>
									<div className="row-inner">
										<label htmlFor="profile_pic" className="upload-image">
											{t('upload_image')}
										</label>
										<input id="profile_pic" name="profile_pic" type="file" accept="image/*" onChange={(event) => {
											if (event.currentTarget.files.length) {
												setFieldTouched('profile_pic', true);
												setFieldValue("profile_pic", event.currentTarget.files[0]);
											}

											event.target.value = null;
										}} className="form-control input-upload-image" />
									</div>
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }}>
									<InputItemLabel className="required">{t('city_id')}</InputItemLabel>
								</Col>
								<Col lg={{ size: 9 }}>
									<InputSelect
										name="city_id" t={t}
										options={vendor_profile_options.cities.list}
										isSearchable={true}
									/>
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }}>
									<InputItemLabel className="required">{t('language_ids')}</InputItemLabel>
								</Col>
								<Col lg={{ size: 9 }}>
									<InputSelect
										name="language_ids" t={t}
										options={vendor_profile_options.languages.list}
										isMulti={true}
										isSearchable={true}
									/>
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }}>
									<InputItemLabel>{t('desc')}</InputItemLabel>
								</Col>
								<Col lg={{ size: 9 }}>
									<InputField component="textarea" type="text" name="desc" t={t} />
								</Col>
							</Row>
						</InputItem>
						<InputItem className="display-responsive">
							<Button
								block
								type="submit"
								disabled={edit_profile.loading}
								onClick={handleSubmit}>
								{t('save')}
							</Button>
						</InputItem>
						<VerifyModal
							data={vendor_verification.data}
							isOpen={verificationModalIsOpen}
							toggle={() => this.setState({ verificationModalIsOpen: !this.state.verificationModalIsOpen })}
						/>
					</>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth, edit_profile, vendor_verification, vendor_profile_options } = state;
	const { user } = auth;
	return {
		user,
		edit_profile,
		vendor_verification,
		vendor_profile_options
	};
}

const translation = withNamespaces(['profile', 'verify'])(Profile);
const connected = connect(mapStateToProps)(translation);
const connectedFormik = connectFormik(connected);
export { connectedFormik as Profile };