import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Dropdown, DropdownToggle, DropdownMenu, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ErrorMessage, connect as connectFormik } from 'formik';
import Moment from 'moment';

import { Title, Desc, InputItem, InputItemLabel, InputSelect, InputField, Calendar, BaseModal } from '../../../../_components';

import './InputForm.scss';

import { LocationAutocomplete } from './LocationAutocomplete';

class InputForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			calendarDropdownIsOpen: false
		}
	}

	toggleCalendarDropdown() {
		this.setState({ calendarDropdownIsOpen: !this.state.calendarDropdownIsOpen });
	}

	calendarIsDayDisabled(day) {
		const { data } = this.props;
		return day.isBefore(Moment()) || day.isAfter(Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset));
	}

	render() {
		const { t, formik, order } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, resetForm } = formik;
		const { calendarDropdownIsOpen } = this.state;

		return (
			<div id="vendor_order_meetup_form">
				<Card>
					<CardHeader>
						<CardTitle tag="h4">
							{t('meetup_details.title')}
						</CardTitle>
						<Desc>
							{t('meetup_details.desc')}
						</Desc>
					</CardHeader>
					<CardBody>
						<InputItem>
							<Row>
								<Col md={{ size: 2 }}>
									<InputItemLabel className="required">
										{t('date')}
									</InputItemLabel>
								</Col>
								<Col md={{ size: 9 }}>
									<Button className={`date-dropdown-toggle dropdown-toggle ${values.date ? '' : 'placeholder'} ${(touched.date && errors.date) ? "input-error" : ""}`} onClick={() => {
										setFieldTouched('date', true);
										this.toggleCalendarDropdown();
									}}>
										{values.date ? values.date : t('select')}
									</Button>
									<ErrorMessage name="date" component="span" className="error" />
								</Col>
							</Row>
						</InputItem>
						<InputItem className="time">
							<Row>
								<Col md={{ size: 2 }}>
									<InputItemLabel className="required">
										{t('time')}
									</InputItemLabel>
								</Col>
								<Col md={{ size: 9 }}>
									<Row className="row-m-4">
										<Col sm={{ size: true }}>
											<InputSelect
												name="time_h" t={t}
												placeholder={t('hour')}
												options={[
													{ id: '1', value: '1' },
													{ id: '2', value: '2' },
													{ id: '3', value: '3' },
													{ id: '4', value: '4' },
													{ id: '5', value: '5' },
													{ id: '6', value: '6' },
													{ id: '7', value: '7' },
													{ id: '8', value: '8' },
													{ id: '9', value: '9' },
													{ id: '10', value: '10' },
													{ id: '11', value: '11' },
													{ id: '12', value: '12' },
												]}
												isSearchable={true}
											/>
										</Col>
										<Col sm={{ size: true }}>
											<InputSelect
												name="time_m" t={t}
												placeholder={t('minute')}
												options={[
													{ id: '00', value: '00' },
													{ id: '15', value: '15' },
													{ id: '30', value: '30' },
													{ id: '45', value: '45' },
												]}
												isSearchable={true}
											/>
										</Col>
										<Col sm={{ size: true }}>
											<InputSelect
												name="time_a" t={t}
												placeholder={t('am/pm')}
												options={[
													{ id: 'am', value: t('am') },
													{ id: 'pm', value: t('pm') },
												]}
												isSearchable={true}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col md={{ size: 2 }}>
									<InputItemLabel className="required">
										{t('location')}
									</InputItemLabel>
								</Col>
								<Col md={{ size: 9 }}>
									<LocationAutocomplete
										placeholder={t('search_location')}
										locationName={values.location_title}
										locationLat={values.location_lat}
										locationLng={values.location_long}
										showError={(touched.location_title && errors.location_title)}
										onPlaceSelected={(place) => {
											if (place) {
												setFieldValue('location_title', place.name);
												setFieldValue('location_lat', `${place.geometry.location.lat()}`);
												setFieldValue('location_long', `${place.geometry.location.lng()}`);
												setFieldValue('utc_offset', Moment().utcOffset(place.utc_offset).format('Z'));
												setFieldValue('full_address', place.formatted_address);
											} else {
												setFieldValue('location_title', '');
												setFieldValue('location_lat', '');
												setFieldValue('location_long', '');
												setFieldValue('utc_offset', '');
												setFieldValue('full_address', '');
											}

											setFieldTouched('location_title', true);
											setFieldTouched('location_lat', true);
											setFieldTouched('location_long', true);
										}}
									/>
									<ErrorMessage name="location_title" component="span" className="error" />
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col md={{ size: 2 }}>
									<InputItemLabel className="required">
										{t('full_address')}
									</InputItemLabel>
								</Col>
								<Col md={{ size: 9 }}>
									<InputField type="text" name="full_address" t={t} />
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col md={{ size: 2 }}>
									<InputItemLabel>
										{t('remarks')}
									</InputItemLabel>
								</Col>
								<Col md={{ size: 9 }}>
									<InputField component="textarea" type="text" name="remarks" placeholder={t('remarks_hint')} t={t} />
								</Col>
							</Row>
						</InputItem>
					</CardBody>
					<CardFooter className="display-responsive">
						<Row className="row-m-8">
							<Col sm={{ size: 'auto' }}>
								<Button
									block
									disabled={order.meet_up.edit.loading}
									onClick={handleSubmit}>
									{t('submit')}
								</Button>
							</Col>
							<Col sm={{ size: 'auto' }}>
								<Button
									color="cancel"
									block
									disabled={order.meet_up.edit.loading}
									onClick={() => this.props.onEditCancel(Object.keys(touched).length !== 0, resetForm)}>
									{t('cancel')}
								</Button>
							</Col>
						</Row>
					</CardFooter>
				</Card>
				<BaseModal
					id="vendor_order_meetup_form_modal_calendar"
					className="modal-calendar"
					size="sm"
					isOpen={calendarDropdownIsOpen}
					toggle={() => this.toggleCalendarDropdown()}
				>
					<ModalHeader toggle={() => this.toggleCalendarDropdown()}>
						<Title>{t('date')}</Title>
					</ModalHeader>
					<ModalBody>
						<Calendar
							onDayClick={(day) => {
								setFieldValue('date', day.format('YYYY-MM-DD'));
								setFieldTouched('date', true);
								this.toggleCalendarDropdown();
							}}
							isDayDisabled={(day) => this.calendarIsDayDisabled(day)}
							isDaySelected={(day) => {
								return values.date ? day.isSame(values.date) : false;
							}}
						/>
					</ModalBody>
				</BaseModal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['meetup', 'package', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
const connectedFormik = connectFormik(connected);
export { connectedFormik as InputForm };