import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { userActions } from '../../../_actions';
import { userType } from '../../../_constants';
import { ActionModal } from '../../../_components';
import { SendCode, SendCodeType, SendCodeUserType } from '../SendCode';

import './EditEmail.scss';

class EditEmail extends Component {
	onSubmit(values, { setSubmitting }) {
		this.props.dispatch(userActions.editEmailByCode(values));
	}

	render() {
		const { t, user, edit_email, isOpen } = this.props;

		return (
			<ActionModal
				id="user_myprofile_edit_email_modal"
				confirmComponent={
					<SendCode
						type={SendCodeType.editEmail}
						userType={user.user_type === userType.customer ? SendCodeUserType.customer : SendCodeUserType.vendor}
						user_userType={user.user_type}
						title={t('change_email_address.title')}
						desc={t('change_email_address.desc')}
						inputEmailPlaceholder={t('new_email')}
						submitBtnTitle={t('submit')}
						onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
						submitting={edit_email.loading}
						toggle={() => this.props.toggle()} />
				}
				successTitle={t('change_email_address.success.title')}
				successDesc={t('change_email_address.success.desc')}
				isLoading={edit_email.loading}
				isSuccess={edit_email.success}
				isOpen={isOpen}
				onClosed={() => {
					const { user, edit_email } = this.props;
					if (edit_email.success) {
						this.props.dispatch(userActions.forceLogout(true, user.user_type === userType.customer ? '/customer/login' : '/vendor/login'));
						this.props.dispatch(userActions.editEmailByCodeClear());
					}
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { auth, edit_email } = state;
	const { user } = auth;
    return {
		user,
		edit_email
    };
}

const translation = withNamespaces(['editemail', 'default'])(EditEmail);
const connected = connect(mapStateToProps)(translation);
export { connected as EditEmail };