import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';

import './NavVendor.scss';

import { CreatePackageButton } from '../../../Vendor';
import { auth as authHelper } from '../../../_helpers';

class NavVendor extends Component {
    render() {
		const { t } = this.props;
		const isLoggedIn = authHelper.isLoggedIn(true, this.props.auth.user);

		return (
			<Nav navbar id="nav_vendor">
				<NavItem className="nav-display-responsive">
					<NavLink tag={Link} to='/vendor'>{t('home')}</NavLink>
				</NavItem>
				<NavItem>
					<CreatePackageButton className="btn-link nav-link" />
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to='/vendor/me/package'>{t('vendor.my_packages')}</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={Link} to='/vendor/me/calendar'>{t('vendor.my_calendar')}</NavLink>
				</NavItem>
				{!isLoggedIn &&
					<>
						<NavItem className="nav-display-responsive nav-item-hr"><hr /></NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/vendor/login'>{t('anonymous.login')}</NavLink>
						</NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/vendor/register'>{t('anonymous.register')}</NavLink>
						</NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/'>{t('anonymous.customer')}</NavLink>
						</NavItem>
					</>
				}
			</Nav>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
    return {
		auth
    };
}
const translation = withNamespaces(['header'])(NavVendor);
const connected = connect(mapStateToProps)(translation);
export { connected as NavVendor };