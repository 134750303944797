import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { app } from '../../_helpers';
import { vendorPackageActions } from '../../_actions';
import { Loading, Error } from '../../_components';

import './RevenueBreakdown.scss';

class RevenueBreakdown extends Component {
	componentDidMount() {
		this.props.dispatch(vendorPackageActions.getRevenueClear());
		// this.props.dispatch(vendorPackageActions.getPayoutSettings());

		const { currency_id, amount, payout_settings } = this.props
		this.getRevenue(currency_id, amount, payout_settings);
	}

	componentDidUpdate(prevProps, prevState) {
		const { currency_id, amount, vendor_package_revenue, onUpdate, payout_settings } = this.props;

		if ((currency_id && (!prevProps.currency_id || currency_id !== prevProps.currency_id)) ||
			(amount && (!prevProps.amount || amount !== prevProps.amount))) {

			let _currency_id = currency_id? currency_id : prevProps.currency_id;
			let _amount = amount? amount : prevProps.amount;

			this.getRevenue(_currency_id, _amount, payout_settings);
		}

		if (vendor_package_revenue.success && prevProps.vendor_package_revenue.success !== vendor_package_revenue.success) {
			if (onUpdate) {
				onUpdate(vendor_package_revenue.data);
			}
		}

		if (payout_settings.success && prevProps.payout_settings.success !== payout_settings.success) {
			this.getRevenue(currency_id, amount, payout_settings);
		}
	}
	
	getRevenue(currency_id, amount, payout_settings) {
		if (!payout_settings.data || !currency_id || !amount) {
			this.props.dispatch(vendorPackageActions.getRevenueClear());
			return;
		}

		if (amount > Number(payout_settings.data.currency.max_value) || amount < Number(payout_settings.data.currency.min_value)) {
			this.props.dispatch(vendorPackageActions.getRevenueClear());
			return;
		}

		this.props.dispatch(vendorPackageActions.getRevenue(currency_id, amount));
	}

	render() {
		const { t, vendor_package_revenue } = this.props;

		return (
			<div id="vendor_order_revenue_breakdown">
				{vendor_package_revenue.loading && <Loading />}
				{vendor_package_revenue.error && <Error error={vendor_package_revenue.error} />}
				{vendor_package_revenue.data &&
				<div className="amount-breakdown">
					<Row>
						<Col xs={{ size: true }}>
							{t('price')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(vendor_package_revenue.data.currency.value, vendor_package_revenue.data.amount)}
						</Col>
					</Row>
					<Row>
						<Col xs={{ size: true }}>
							{t('service_fee')}
						</Col>
						<Col xs={{ size: 'auto' }} className="minus-value">
							{app.toCurrencyString(vendor_package_revenue.data.currency.value, vendor_package_revenue.data.service_fee)}
						</Col>
					</Row>
					<hr />
					<Row className="total">
						<Col xs={{ size: true }}>
							{t('you_will_receive')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(vendor_package_revenue.data.currency.value, vendor_package_revenue.data.revenue)}
						</Col>
					</Row>
				</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_package_revenue, payout_settings } = state;
	return {
		vendor_package_revenue,
		payout_settings
	};
}

const translation = withNamespaces(['package', 'default'])(RevenueBreakdown);
const connected = connect(mapStateToProps)(translation);
export { connected as RevenueBreakdown };