import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import {
	Nav,
	NavItem,
	NavLink,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	Badge,
	Button } from 'reactstrap';

import { history, auth as authHelper } from '../../../_helpers';

import './NavUser.scss';
import img_placeholder from '../../../_common/img/FFFFFF-0.png';
import { userActions } from '../../../_actions';
import { userType } from '../../../_constants';
import { ProfilePicImg } from '../../../_components';
import { BtnInbox } from '../../../_components/BtnInbox/BtnInbox';

class NavUser extends Component {
	constructor(props) {
		super(props);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.state = {
			isDropdownOpen: false,
		}

		history.listen((location, action) => {
			this.setState({ isDropdownOpen: false });
		});
	}

	toggleDropdown() {
		this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
	}

	onHoverDropdown(isEnter) {
		if (this.state.isDropdownOpen === isEnter) return;
		this.setState({ isDropdownOpen: isEnter });
	}

	onLogout() {
		this.props.dispatch(userActions.logout(this.props.auth.user.user_type));
	}

    render() {
		const { t, isVendor } = this.props;
		const isLoggedIn = authHelper.isLoggedIn(isVendor, this.props.auth.user);
		const user = this.props.auth.user;

		return (
			<Nav navbar id="nav_user">
				{isLoggedIn?
					[
						<NavItem key="message" className="nav-item-msg">
							<BtnInbox nav isVendor={isVendor} className="nav-link" />
						</NavItem>,
						<Dropdown key="profile" nav inNavbar className="nav-item-user nav-display-desktop" 
						isOpen={this.state.isDropdownOpen} 
						toggle={this.toggleDropdown} 
						onMouseEnter={() => this.onHoverDropdown(true)} 
						onMouseLeave={() => this.onHoverDropdown(false)}
						>
							<DropdownToggle nav caret>
								<ProfilePicImg src={user.profile_pic_url} alt={user.nickname} /><span className="nav-item-user-name">{user.nickname}</span>
							</DropdownToggle>
							<DropdownMenu right>
								{user.user_type === userType.customer?
									[
										<Link key="me" className="dropdown-item-text" to="/customer/me">{t('customer.account_profile')}</Link>,
										<Link key="order_list" className="dropdown-item-text" to="/customer/me/orders">{t('customer.order_list')}</Link>,
										<Link key="deal_list" className="dropdown-item-text" to="/customer/me/deals">{t('customer.deal_list')}</Link>,
										<Link key="saved_items" className="dropdown-item-text" to="/customer/me/saved_items">{t('customer.saved_items')}</Link>,
									]
									:
									[
										<Link key="me" className="dropdown-item-text" to="/vendor/me">{t('vendor.account_profile.title')}</Link>,
										<Link key="payout_settings" className="dropdown-item-text" to="/vendor/me/payout_settings">{t('vendor.payout_settings')}</Link>,
										<Link key="payout_summary" className="dropdown-item-text" to="/vendor/me/payouts">{t('vendor.payout_summary')}</Link>,
										<Link key="order_list" className="dropdown-item-text" to="/vendor/me/orders">{t('vendor.order_list')}</Link>,
										<Link key="deal_list" className="dropdown-item-text" to="/vendor/me/deals">{t('vendor.deal_list')}</Link>,
										<Link key="photographer_profile" className="dropdown-item-text" to="/vendor/me/vendor_profile">{t('vendor.photographer_profile')}</Link>,
										<Link key="my_calendar" className="dropdown-item-text" to="/vendor/me/calendar">{t('vendor.my_calendar')}</Link>,
									]
								}
								<Button color="link" className="dropdown-item-text" onClick={() => this.onLogout()}>{t('logout')}</Button>
							</DropdownMenu>
						</Dropdown>,
						<div key="profile-responsive" className="nav-display-responsive">
							{user.user_type === userType.customer ?
								[
									<NavItem key="me"><NavLink tag={Link} to="/customer/me">{t('customer.account_profile')}</NavLink></NavItem>,
									<NavItem key="order_list"><NavLink tag={Link} to="/customer/me/orders">{t('customer.order_list')}</NavLink></NavItem>,
									<NavItem key="deal_list"><NavLink tag={Link} to="/customer/me/deals">{t('customer.deal_list')}</NavLink></NavItem>,
									<NavItem key="saved_items"><NavLink tag={Link} to="/customer/me/saved_items">{t('customer.saved_items')}</NavLink></NavItem>,
									<NavItem key="logout"><Button color="link" className="nav-link" onClick={() => this.onLogout()}>{t('logout')}</Button></NavItem>,
								]
								:
								[
									<NavItem key="me"><NavLink tag={Link} to="/vendor/me">{t('vendor.account_profile.title')}</NavLink></NavItem>,
									<NavItem key="payout_settings"><NavLink tag={Link} to="/vendor/me/payout_settings">{t('vendor.payout_settings')}</NavLink></NavItem>,
									<NavItem key="payout_summary"><NavLink tag={Link} to="/vendor/me/payouts">{t('vendor.payout_summary')}</NavLink></NavItem>,
									<NavItem key="order_list"><NavLink tag={Link} to="/vendor/me/orders">{t('vendor.order_list')}</NavLink></NavItem>,
									<NavItem key="deal_list"><NavLink tag={Link} to="/vendor/me/deals">{t('vendor.deal_list')}</NavLink></NavItem>,
									<NavItem key="photographer_profile"><NavLink tag={Link} to="/vendor/me/vendor_profile">{t('vendor.photographer_profile')}</NavLink></NavItem>,
									<NavItem key="my_calendar"><NavLink tag={Link} to="/vendor/me/calendar">{t('vendor.my_calendar')}</NavLink></NavItem>,
									<NavItem key="logout"><Button color="link" className="nav-link" onClick={() => this.onLogout()}>{t('logout')}</Button></NavItem>,
								]
							}
						</div>
					]
					:
					[
						<NavItem key="register">
							<NavLink tag={Link} to={isVendor? "/vendor/register" : "/customer/register"}>{t('anonymous.register')}</NavLink>
						</NavItem>,
						<NavItem key="login">
							<NavLink tag={Link} to={isVendor? "/vendor/login" : "/customer/login"}>{t('anonymous.login')}</NavLink>
						</NavItem>,
						<NavItem key="switch">
							<NavLink tag={Link} to={isVendor? "/" : "/vendor"}>{isVendor? t('anonymous.customer') : t('anonymous.vendor')}</NavLink>
						</NavItem>
					]
				}
			</Nav>
		);
	}
}

function mapStateToProps(state) {
    const { auth } = state; 
    return {
		auth
    };
}

const translation = withNamespaces(['header', 'profile', 'default'])(NavUser);
const connected = connect(mapStateToProps)(translation);
export { connected as NavUser };