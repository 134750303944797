import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, BaseModal, Desc } from '../../_components';
import { orderStatus, getOrderStatusNameByStatus } from '../../_constants';

import './StatusInfoModal.scss';

class StatusInfoModal extends Component {
	render() {
		const { t, status, isExpired } = this.props;
		let allStatuses = [];

		for (var i = 1; i <= orderStatus.refund_fail; i ++) {
			allStatuses.push({
				status: i,
				expired: false,
				title: t(`status.${getOrderStatusNameByStatus(i)}`),
				desc: t(`status_desc.${getOrderStatusNameByStatus(i)}`),
			});
		}
		allStatuses.push({
			status: 0,
			expired: true,
			title: t(`status.expired`),
			desc: t(`status_desc.expired`),
		});

		return (
			<BaseModal
				id="order_status_info_modal"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>
						{t('status.all_statuses')}
					</Title>
				</ModalHeader>
				<ModalBody>
					{allStatuses.map(item =>
						<div key={item.status} className={`status ${(status !== undefined && isExpired !== undefined && (status === item.status || (isExpired && isExpired === item.expired))) ? 'status-bold' : ''}`}>
							<Title size="xs">
								{item.title}
							</Title>
							<Desc>
								{item.desc}
							</Desc>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => this.props.toggle()}>{t('ok')}</Button>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['order', 'default'])(StatusInfoModal);
export { translation as StatusInfoModal };