import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet } from "react-helmet";

import "react-tabs/style/react-tabs.css";
import '../../_common/tabs.scss';

import { savedItemsActions, vendorProfileActions } from '../../_actions';
import { savedItemType } from '../../_constants';
import { history, auth as authHelper } from '../../_helpers';
import { PageContainer, ProfilePicImg, Title, Desc, ShareButton, LoadingPage, ErrorPage } from '../../_components';

import { Reviews } from './Reviews';
import { Packages } from './Packages';

import './Profile.scss';

import img_bookmark from '../../_common/img/home_article_icon_bookmark.svg';
import img_bookmarked from '../../_common/img/home_article_icon_bookmarked.svg';

const ProfileTabPathnames = ['reviews', 'packages'];

class Profile extends Component {
	constructor(props) {
		super(props);

		const { match } = props;
		let tabIndex = this.tabIndexByMatch(match);
		this.state = {
			tabIndex: tabIndex,
			keyPackages: Math.random()
		};
	}

	componentDidMount() {
		this.props.dispatch(vendorProfileActions.get(this.props.match.params.id));
	}

	componentDidUpdate(prevProps, prevState) {
		const { location, app } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		if (!prevProps.app.currency.currency || app.currency.currency.id !== prevProps.app.currency.currency.id) {
			this.setState({ keyPackages: Math.random() });
		}
	}

	onRouteChanged(props) {
		const { match } = props;

		let tabIndex = this.tabIndexByMatch(match);
		this.setState({ tabIndex: tabIndex });

		this.props.dispatch(vendorProfileActions.get(this.props.match.params.id));
	}

	tabIndexByMatch(match) {
		let tabIndex = ProfileTabPathnames.indexOf(match.params.tab);
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		return `${match.url.replace(`/${match.params.tab }`, '')}/${ProfileTabPathnames[tabIndex]}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	onBookmark() {
		if (!this.props.vendor_profile.data) return;

		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			const { id } = this.props.vendor_profile.data;

			if (this.props.vendor_profile.data.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsVendor(id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsVendor(id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	render() {
		const { t, vendor_profile, saved_item_action, app } = this.props;
		const { tabIndex, keyPackages } = this.state;
		const id = this.props.match.params.id;

		const isLoadingBookmark = saved_item_action.loading && saved_item_action.type == savedItemType.VENDOR && saved_item_action.id == id;

		return (
			<>
				{vendor_profile.loading && <LoadingPage />}
				{vendor_profile.error && <ErrorPage match={this.props.match} error={vendor_profile.error} />}
				{vendor_profile.data &&
				<>
					<Helmet>
						<meta property="og:title" content={`${t('og_title')} - ${vendor_profile.data.nickname}`} />
						<meta property="og:description" content={vendor_profile.data.desc} />
						<meta property="og:image" content={vendor_profile.data.profile_pic_url} />

						<meta property="twitter:title" content={`${t('og_title')} - ${vendor_profile.data.nickname}`} />
						<meta property="twitter:description" content={vendor_profile.data.desc} />
						<meta property="twitter:image" content={vendor_profile.data.profile_pic_url} />
					</Helmet>
					<PageContainer id="vendor_profile">
						<Container>
							<div className="header">
								<Row>
									<Col sm={{ size: true }} className="img-name-country">
										<ProfilePicImg src={vendor_profile.data.profile_pic_url} alt={vendor_profile.data.nickname} />
										<div className="name-country">
											<Title>{vendor_profile.data.nickname}</Title>
											<div className="country">{vendor_profile.data.city.value}</div>
										</div>
									</Col>
									<Col xs={{ size: 'auto' }} className="actions">
										<Button color="link" onClick={() => this.onBookmark()} title={t('bookmark')} disabled={isLoadingBookmark}><img src={vendor_profile.data.is_bookmarked ? img_bookmarked : img_bookmark} alt={t('bookmark')} /></Button>
									</Col>
									<Col xs={{ size: 'auto' }} className="actions">
										<ShareButton
											dropdownMenuAlignRight={app.windowSize.width > 576}
											url={window.location.href}
											title={vendor_profile.data.nickname} //TODO: share 
											body={`${vendor_profile.data.nickname} ${vendor_profile.data.city.value}`} //TODO: share content
										/>
									</Col>
								</Row>
								<div className="language">{t('speaking_language')} {vendor_profile.data.languages.map((language) => language.value).join(', ')}</div>
								<Desc>{vendor_profile.data.desc}</Desc>
							</div>
							<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
								<TabList>
									<Tab>{t('review') + (vendor_profile.data.review_count > 0? ` (${vendor_profile.data.review_count})` : '')}</Tab>
									<Tab>{t('package') + (vendor_profile.data.package_count > 0? ` (${vendor_profile.data.package_count})` : '')}</Tab>
								</TabList>
								<TabPanel>
									<Reviews vendor_id={id} />
								</TabPanel>
								<TabPanel>
									<Packages key={keyPackages} vendor_id={id} />
								</TabPanel>
							</Tabs>
						</Container>
					</PageContainer>
				</>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth, vendor_profile, saved_item_action, app } = state;
	return {
		auth,
		vendor_profile,
		saved_item_action,
		app,
	};
}

const translation = withNamespaces(['vendor'])(Profile);
const connected = connect(mapStateToProps)(translation);
export { connected as Profile };