import React from "react";

import { Desc } from '../../../../../_components';
import { BaseMessage } from '../BaseMessage';

import './TextMessage.scss';

export const TextMessage = ({ message, className, ...props}) => {
	return (
		<BaseMessage
			message={message}
			className={`chat-message-text ${className ? className : ''}`}
			{...props}
		>
			<Desc className="message">
				{message.message}
			</Desc>
		</BaseMessage>
	);
}