import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ErrorMessage, connect as connectFormik } from 'formik';
import { InputField, InputFilePreview, InputSelect, InputItem, InputItemLabel, Title } from '../../../_components';
import { Button, Row, Col } from 'reactstrap';

import { RevenueBreakdown } from '../../RevenueBreakdown';

import './BasicInfo.scss';

import img_placeholder from '../../../_common/img/FFFFFF-0.png';

class BasicInfo extends Component {
	render() {
		const { t, formInfo, formik } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

		return (
			<div id="vendor_createpackage_basicinfo">
				<Title size='sm'>
					{t('basic_information')}
				</Title>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<div className="required">
								{t('video_url')}
							</div>
							<div className="remarks pt-0">
								{t('video_url_remarks')}
							</div>
						</Col>
						<Col md={{ size: 9 }}>
							<InputField type="text" name="video_url" t={t} />
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<div className="required">
								{t('video_image')}
							</div>
							<div className="remarks pt-0">
								{t('video_image_remarks')}
							</div>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<div className={`row-inner ${(!values.video_image && !values.video_image_url) ? 'd-none' : ''}`}>
								<div className="video-image">
									{values.video_image && <InputFilePreview file={values.video_image} />}
									{!values.video_image && values.video_image_url && <img src={values.video_image_url} alt="" />}
								</div>
							</div>
							<div className="row-inner">
								<label htmlFor="video_image" className={`upload-image ${(touched['video_image'] && errors['video_image']) ? "input-error" : ""}`}>
									{t('upload_image')}
								</label>
								<input id="video_image" name="video_image" type="file" accept="image/*" onChange={(event) => {
									if (event.currentTarget.files.length) {
										setFieldTouched('video_image', true);
										setFieldValue("video_image", event.currentTarget.files[0]);
									}

									event.target.value = null;
								}} className="form-control input-upload-image" />
								<ErrorMessage name="video_image" component="span" className="error" />
							</div>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('name')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }}>
							<InputField type="text" name="name" t={t} />
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('price')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<Row className="row-m-8">
								<Col md={{ size: 4 }}>
									<InputSelect
										name="currency_id" t={t}
										options={formInfo.currencies}
										isSearchable={true}
										isDisabled={true}
									/>
								</Col>
								<Col md={{ size: 8 }}>
									<InputField type="number" name="amount" placeholder={t('price')} t={t} />
								</Col>
							</Row>
							<RevenueBreakdown currency_id={values.currency_id} amount={values.amount} />
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('destination_id')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<InputSelect
								name="destination_id" t={t}
								groupedOptions={formInfo.destination_groups}
								isSearchable={false}
							/>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('category_id')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<InputSelect
								name="category_id" t={t}
								groupedOptions={formInfo.category_groups}
								isSearchable={false}
							/>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('package_type_id')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<InputSelect
								name="package_type_id" t={t}
								options={formInfo.package_types}
								isSearchable={false}
							/>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('desc')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }}>
							<InputField component="textarea" type="text" name="desc" t={t} />
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('timezone_id')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<InputSelect
								name="timezone_id" t={t}
								options={formInfo.timezones}
								isSearchable={true}
							/>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col md={{ size: 3 }}>
							<InputItemLabel className="required">
								{t('shooting_day_hour')}
							</InputItemLabel>
						</Col>
						<Col md={{ size: 9 }} lg={{ size: 6 }}>
							<Row>
								<Col md={{ size: 6 }} className="shooting-day-hour">
									<Button className={`btn-radio ${values.shoot_day !== undefined ? 'selected' : ''}`}
										onClick={() => {
											setFieldValue('shoot_day', 0);
											setFieldValue('shoot_hour', undefined);
										}}
									>
										<img src={img_placeholder} alt={t('shoot_day')} />
									</Button>
									<span className={`shooting-day-hour-subtitle ${values.shoot_day !== undefined ? '' : 'disabled'}`}>{t('shoot_day')}</span>
									<InputSelect
										name="shoot_day" t={t}
										options={[
											{ id: 1, value: 1 },
											{ id: 2, value: 2 },
											{ id: 3, value: 3 },
											{ id: 4, value: 4 },
											{ id: 5, value: 5 },
											{ id: 6, value: 6 },
											{ id: 7, value: 7 },
											{ id: 8, value: 8 },
											{ id: 9, value: 9 },
											{ id: 10, value: 10 },
										]}
										isSearchable={true}
										isDisabled={values.shoot_day === undefined}
										hideErrorMessage={true}
									/>
								</Col>
								<Col md={{ size: 6 }} className="shooting-day-hour">
									<Button className={`btn-radio ${values.shoot_hour !== undefined ? 'selected' : ''}`} onClick={() => {
										setFieldValue('shoot_day', undefined);
										setFieldValue('shoot_hour', 0);
									}}>
										<img src={img_placeholder} alt={t('shoot_hour')} />
									</Button>
									<span className={`shooting-day-hour-subtitle ${values.shoot_hour !== undefined ? '' : 'disabled'}`}>{t('shoot_hour')}</span>
									<InputSelect
										name="shoot_hour" t={t}
										options={[
											{ id: 1, value: 1 },
											{ id: 2, value: 2 },
											{ id: 3, value: 3 },
											{ id: 4, value: 4 },
											{ id: 5, value: 5 },
											{ id: 6, value: 6 },
											{ id: 7, value: 7 },
											{ id: 8, value: 8 },
											{ id: 9, value: 9 },
											{ id: 10, value: 10 },
											{ id: 11, value: 11 },
											{ id: 12, value: 12 },
											{ id: 13, value: 13 },
											{ id: 14, value: 14 },
											{ id: 15, value: 15 },
											{ id: 16, value: 16 },
											{ id: 17, value: 17 },
											{ id: 18, value: 18 },
											{ id: 19, value: 19 },
											{ id: 20, value: 20 },
											{ id: 21, value: 21 },
											{ id: 22, value: 22 },
											{ id: 23, value: 23 },
										]}
										isSearchable={true}
										isDisabled={values.shoot_hour === undefined}
										hideErrorMessage={true}
									/>
								</Col>
							</Row>
							{errors.shoot_day && !errors.shoot_hour && <ErrorMessage name="shoot_day" component="span" className="error" />}
							{!errors.shoot_day && errors.shoot_hour && <ErrorMessage name="shoot_hour" component="span" className="error" />}
							{errors.shoot_day && errors.shoot_hour && <ErrorMessage name="shoot_day" component="span" className="error" />}
						</Col>
					</Row>
				</InputItem>
			</div>
		);
	}
}

const translation = withNamespaces(['package'])(BasicInfo);
const connectedFormik = connectFormik(translation);
export { connectedFormik as BasicInfo };