import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { Pay } from './Pay';
import { Receipt } from './Receipt';
import { EditShootingDatetimeHistory } from '../../Order';
import { Order } from './Order';

const OrderTabPathnames = ['details', 'meet_up', 'after_shooting', 'receipts', 'pending_items'];

export class OrderRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}/receipt/:receipt_id(\\d+)`} component={Receipt} />
				<PrivateRoute exact path={`${match.path}/payment/:payment_id(\\d+)/pay`} component={Pay} />
				<PrivateRoute exact path={`${match.path}/edit_shooting_datetime_history`} component={EditShootingDatetimeHistory} />
				<PrivateRoute exact path={`${match.path}/:tab(${OrderTabPathnames.join('|')})`} component={Order} />
				<PrivateRoute exact path={`${match.path}`} component={Order} />
				<Redirect to="/error/404" />
			</Switch>
		);
	}
}