import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userType } from '../../../../_constants';
import { utils } from '../../../utils';

import './Message.scss';

import { TextMessage } from './TextMessage';
import { ImageMessage } from './ImageMessage';
import { FileMessage } from './FileMessage';
import { SystemMessage } from './SystemMessage';


class Message extends Component {
	render() {
		const { t, auth, message, readReceipt, app } = this.props;
		let isFrom = message.sender? message.sender.userId == auth.user.id : true;
		let isVendor = auth.user.user_type !== userType.customer;

		let messageType = utils.getMessageTypeWithMessage(message);

		switch (messageType) {
			case 'image':
				return <ImageMessage id={`msg_${message.messageId}`} message={message} isFrom={isFrom} readReceipt={readReceipt} />;
			case 'file':
				return <FileMessage id={`msg_${message.messageId}`} message={message} isFrom={isFrom} readReceipt={readReceipt} />;
			case 'system':
				return <SystemMessage id={`msg_${message.messageId}`} message={message} isFrom={isFrom} readReceipt={readReceipt} t={t} isVendor={isVendor} lang={app.language.language}/>;
			default:
				return <TextMessage id={`msg_${message.messageId}`} message={message} isFrom={isFrom} readReceipt={readReceipt} />;
		}
	}
}

function mapStateToProps(state) {
	const { auth, app } = state;
	return {
		auth,
		app
	};
}

const connected = connect(mapStateToProps)(Message);
export { connected as Message };