import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { vendorVerificationStatus } from '../../_constants';
import { Title, SVGImg, BaseModal } from '../../_components';

import './VendorProfileIncompleteModal.scss';
import { history } from '../../_helpers';

class VendorProfileIncompleteModal extends Component {
	onOK() {
		history.push('/vendor/me');
		this.props.toggle();
	}

	render() {
		const { t, data } = this.props;

		return (
			<BaseModal
				centered
				id="vendor_profile_incomplete_modal"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>{t('vendor.incomplete_profile.title')}</Title>
				</ModalHeader>
				<ModalBody>
					{data &&
						<ul className="parts">
							<li className={data.verification_status === vendorVerificationStatus.verified ? "success" : "light-grey"}><SVGImg img="tick" />{t('vendor.vendor_verification')}</li>
							<li className={data.is_payout_completed ? "success" : "light-grey"}><SVGImg img="tick" />{t('vendor.payout_settings')}</li>
							<li className={data.is_profile_completed ? "success" : "light-grey"}><SVGImg img="tick" />{t('vendor.photographer_profile')}</li>
						</ul>
					}
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => this.onOK()}>{t('ok')}</Button>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['profile', 'default'])(VendorProfileIncompleteModal);
export { translation as VendorProfileIncompleteModal };