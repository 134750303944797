import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';
import Swiper from 'react-id-swiper';
import { Navigation, Pagination, Mousewheel } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/scss/swiper.scss";

import { SVGImg } from '../../_components';

import './HomeSwiper.scss';

class HomeSwiper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			swiper: undefined,
			currentIndex: 0
		}
	}

	onDot(index) {

	}

	render() {
		const { children, swiperParams, app } = this.props;
		const childrenLength = React.Children.count(children);
		const { swiper, currentIndex } = this.state;

		const params = {
			modules: [Pagination, Navigation, Mousewheel], // Add nescessary modules here
			// initialSlide: 0,
			// slidesPerView: 5,
			spaceBetween: 16,
			loop: true,
			getSwiper: (el) => {
				if (el) {
					el.on('slideChange', () => {
						this.setState({ currentIndex: el.realIndex });
					});
					this.setState({ swiper: el });
				}
			},
			mousewheel: true,
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
			},
			...swiperParams
		};
		const dots = [];
		for (let index = 0; index < childrenLength; index++) {
			dots.push(index);
		}

		let hideNavs = swiperParams.hideNavs? swiperParams.hideNavs : false;
		let hideDots = swiperParams.hideDots? swiperParams.hideDots : false;

		const windowWidth = app.windowSize.width;
		if (swiperParams.breakpoints) {
			let keys = Object.keys(swiperParams.breakpoints);
			keys = keys.sort(function (a,b) {
				const NumA = parseInt(a, 10);
				const NumB = parseInt(b, 10);
				if (NumA < NumB) {
					return 1;
				  }
				  if (NumA > NumB) {
					return -1;
				  }
				  return 0;
			});

			keys.forEach(key => {
				const breakpoint = parseInt(key, 10);
				if (windowWidth < breakpoint) {
					if (swiperParams.breakpoints[key].hideNavs !== undefined) {
						hideNavs = swiperParams.breakpoints[key].hideNavs;
					}
					if (swiperParams.breakpoints[key].hideDots !== undefined) {
						hideDots = swiperParams.breakpoints[key].hideDots;
					}
				}
			});
		}

		return (
			<div className="home-swiper">
				<Swiper {...params}>
					{this.props.children}
				</Swiper>
				{!hideNavs &&
					<Button color="img" className="btn-swiper btn-swiper-prev" onClick={() => {
						if (swiper) {
							swiper.slidePrev();
						}
					}}>
						<SVGImg img="arrow-left" />
					</Button>
				}
				{!hideNavs &&
					<Button color="img" className="btn-swiper btn-swiper-next" onClick={() => {
						if (swiper) {
							swiper.slideNext();
						}
					}}>
						<SVGImg img="arrow-right" />
					</Button>
				}
				{!hideDots &&
					<div className="swiper-dots">
						{dots.map((item, index) =>
							<Button key={index} color="img" className={`dot ${index === currentIndex ? 'active' : ''}`} onClick={() => {
								if (swiper) {
									swiper.slideToLoop(index);
								}
							}}></Button>
						)}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['default'])(HomeSwiper);
const connected = connect(mapStateToProps)(translation);
export { connected as HomeSwiper };