import React from 'react';
import { render } from "react-dom";
import { Provider } from 'react-redux';
import { store, api, analytics } from './_helpers';
import * as serviceWorker from './serviceWorker';

import './_helpers/i18n';

import { App } from './App';

function noop() { }

const hideDebug = (process.env.REACT_APP_ENV === 'prod' && process.env.NODE_ENV !== 'development');
//npm start -> 'development'
//npm test-> 'test'
//npm run build -> 'production'
if (hideDebug) {
	console.log = noop;
	console.info = noop;
	console.warn = noop;
	console.error = noop;
}

console.log(process.env); //debug

analytics.init();
api.init();

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
