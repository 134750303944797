
import React from "react";

import './PackageDetailsWrapper.scss';

import { Header } from '../Header';
import { PackageDetails } from '../../../Order';

export const PackageDetailsWrapper = ({ data, ...props}) => {
	return (
		<div id="vendor_order_package">
			<Header data={data} />
			<PackageDetails data={data.order_package} />
		</div>
	);
}