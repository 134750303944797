import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Prompt } from 'react-router';

class LeaveConfirmPrompt extends Component {
	render() {
		const { t, disabled ,message, disableLocations } = this.props;

		let msg = message? message : t('you_have_unsaved_changes_sure_to_leave');

		return(
			<Prompt
				when={!disabled}
				message={(location) => {
					return disableLocations? disableLocations.includes(location.pathname)? true : msg : msg;
				}}
			/>
		);
	}
}

const translation = withNamespaces(['default'])(LeaveConfirmPrompt);
export { translation as LeaveConfirmPrompt };