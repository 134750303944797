import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import TextareaAutosize from 'react-autosize-textarea';

import {
	KEY_ENTER,
	typingStart as sb_typingStart,
	typingEnd as sb_typingEnd,
	onSendButtonPress as sb_onSendButtonPress,
	onFileButtonPress as sb_onFileButtonPress,
} from '../../../_sendbird';

import './Input.scss';

import img_photo from '../../img/details_icon_item_included_photo.svg';

class Input extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: ''
		}
	}

	componentDidUpdate() {
		const { channel } = this.props;
	
		if (channel && !channel.isOpenChannel()) {
			const { message } = this.state;

			this.props.dispatch(message? sb_typingStart(channel.url) : sb_typingEnd(channel.url)); 
		}
	}

	onSend(message) {
		const trimmedMsg = message.trim();

		if (!trimmedMsg.length) return;

		this.setState({ message: '' }, () => {
			const { channel } = this.props;
			this.props.dispatch(sb_onSendButtonPress(channel.url, channel.isOpenChannel(), trimmedMsg));
		});
	}

	onSendFile(file) {
		const { channel } = this.props;
		this.props.dispatch(sb_onFileButtonPress(channel.url, channel.isOpenChannel(), file));
	}

	render() {
		const { t } = this.props;
		const { message } = this.state

		return (
			<div id="chat_area_input">
				<label htmlFor="send_file" className="btn btn-img btn-add-photo">
					<img src={img_photo} alt="" />
				</label>
				<input id="send_file" name="send-file" type="file" onChange={(event) => {
					if (event.currentTarget.files.length) {
						this.onSendFile(event.currentTarget.files[0]);
					}

					event.target.value = null;
				}} className="input-send-file" />
				<TextareaAutosize
					maxRows={5}
					placeholder={t('type_a_message')}
					value={message}
					onChange={(e) => this.setState({ message: e.target.value })}
					onKeyDown={(e) => {
						if (e.keyCode === KEY_ENTER) {
							if (!e.shiftKey) {
								e.preventDefault();
								this.onSend(message);
							}
						}
					}}
				/>
				<Button size="sm" className="btn-send" onClick={() => this.onSend(message)}>{t('send')}</Button>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const connected = connect(mapStateToProps)(Input);
export { connected as Input };