import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';

import { orderActions } from '../../../_actions';
import { Loading, ActionModal } from '../../../_components';
import { canEditOrderMeetupDetailsByData } from '../../../_constants';

import './Meetup.scss';

import { Header } from '../Header';
import { Details } from './Details';
import { InputForm } from './InputForm';

class Meetup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEdit: false,
			confirmModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.editMeetupDetailsClear());

		const { data } = this.props;
		this.props.dispatch(orderActions.getMeetupDetails(true, data.id));
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { data, order } = this.props;

		if (order.meet_up.success && order.meet_up.success !== prevProps.order.meet_up.success) {
			if (!order.meet_up.data) {
				this.setState({ isEdit: true });
			}
		}

		if (order.meet_up.edit.success && order.meet_up.edit.success !== prevProps.order.meet_up.edit.success) {
			this.props.dispatch(orderActions.getMeetupDetails(true, data.id));
		}
	}

	onEditCancel(showConfirm, resetForm) {
		resetForm();

		const { t } = this.props;
		if (showConfirm) {
			if (window.confirm(t('you_have_unsaved_changes_confirm_to_cancel'))) {
				this.setState({ isEdit: false });
			}
		} else {
			this.setState({ isEdit: false });
		}
	}

	onEdit() {
		if (this.state.isEdit) return;
		this.setState({ isEdit: true });
	}

	onSubmit(values) {
		let datetime = Moment(`${values.date} ${values.time_h}:${values.time_m}${values.time_a}`, 'YYYY-MM-DD h:mma').format('YYYY-MM-DD HH:mm:ss');
		values['datetime'] = datetime;

		const { data } = this.props;
		this.props.dispatch(orderActions.editMeetupDetails(true, data.id, values));
	}

	render() {
		const { t, data, order } = this.props;
		const { isEdit, confirmModalIsOpen } = this.state;

		const canEdit = canEditOrderMeetupDetailsByData(data);

		let initialValues = {
			'date': '',
			'time_h': '',
			'time_m': '',
			'time_a': '',
			'full_address': '',
			'remarks': '',
			'location_title': '',
			'location_lat': 0,
			'location_long': 0
		};

		let meetupDetailsData = null;
		if (order.meet_up.success && order.meet_up.data) {
			meetupDetailsData = order.meet_up.data;

			initialValues = Object.assign({}, order.meet_up.data);
			const datetime = Moment.utc(initialValues.datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset('+08:00');
			initialValues.date = datetime.format('YYYY-MM-DD');
			initialValues.time_h = datetime.format('h');
			initialValues.time_m = datetime.format('mm');
			initialValues.time_a = datetime.format('a');
		}

		const schema = Yup.object().shape({
			date: Yup.string().required(t('required')),
			time_h: Yup.string()
				.required(t('required')),
			time_m: Yup.string()
				.required(t('required')),
			time_a: Yup.string()
				.required(t('required')),
			full_address: Yup.string()
				.required(t('required')),
			location_title: Yup.string().required(t('required')),
		});

		return (
			<div id="vendor_order_meetup">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={(values, formikBag) => this.setState({ confirmModalIsOpen: true })}
					>
						{({ values,
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							setFieldTouched,
							validateField,
							validateForm,
							resetForm
							/* and other goodies */
						}) => (
								<Form>
									<Header data={data} accessories=
										{canEdit ?
											isEdit ?
												<>
													{meetupDetailsData &&
														<Col sm={{ size: 'auto' }} className="display-desktop">
															<Button
																color="cancel"
																block
																disabled={order.meet_up.edit.loading}
																onClick={() => this.onEditCancel(Object.keys(touched).length !== 0, resetForm)}>
																{t('cancel')}
															</Button>
														</Col>
													}
													<Col sm={{ size: 'auto' }} className="display-desktop">
														<Button
															block
															disabled={order.meet_up.edit.loading}
															onClick={handleSubmit}>
															{t('submit')}
														</Button>
													</Col>
												</>
												:
												<Col sm={{ size: 'auto' }}>
													<Button
														block
														onClick={() => {
															resetForm();
															this.onEdit();
														}}>
														{t('edit')}
													</Button>
												</Col>
											:
											null
										}
									/>
									{order.meet_up.loading && <Loading />}
									{order.meet_up.error && <Loading error={order.meet_up.error} />}
									{order.meet_up.success &&
										(
											isEdit ?
												(
													canEdit ?
														<InputForm
															data={data}
															onEditCancel={(showConfirm, resetForm) => this.onEditCancel(showConfirm, resetForm)}
														/>
														:
														<div>{t('N/A')}</div>
												)
												:
												<Details data={meetupDetailsData} />
										)
									}
									<ActionModal
										id="vendor_order_meetup_confirm_modal"
										confirmTitle={t('confirm.title')}
										confirmDesc={t('confirm.desc')}
										successTitle={t('success.title')}
										successDesc={t('success.desc')}
										isLoading={order.meet_up.edit.loading}
										isSuccess={order.meet_up.edit.success}
										error={order.meet_up.edit.error}
										isOpen={confirmModalIsOpen}
										toggle={() => this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen })}
										onSubmit={() => this.onSubmit(values)}
										onClosed={() => {
											this.props.dispatch(orderActions.editMeetupDetailsClear());
										}}
										onSuccess={() => {
											this.setState({ isEdit: false });
											resetForm();
										}}
									/>
								</Form>
							)}
					</Formik>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['meetup', 'package', 'default'])(Meetup);
const connected = connect(mapStateToProps)(translation);
export { connected as Meetup };