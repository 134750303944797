import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { alert } from './alert.reducer';
import { header } from './header.reducer';
import {
	articles,
	hot_articles,
	article
} from './article.reducer';

import {
	package_destinations,
	package_categories,
	packages,
	package_details,
} from './package.reducer';

import {
	register_send_code,
	register,
	login,
	forgot_password,
	edit_profile,
	edit_email,
	edit_password,
	get_user
} from './user.reducer';

import {
	saved_items,
	saved_item_action
} from './saved_items.reducer';

import {
	vendor_profile,
	vendor_calendar
} from './vendor_profile.reducer';

import {
	my_calendar
} from './my_calendar.reducer';

import {
	my_reviews
} from './my_reviews.reducer';

import {
	vendor_verification
} from './vendor_verification.reducer';

import { 
	payouts,
	payout,
	payout_receipt,
} from './payout.reducer';

import {
	payout_settings_form_info,
	payout_settings
} from './payout_settings.reducer';

import {
	orders,
	order,
} from './order.reducer';

import {
	vendor_packages,
	vendor_drafts,
	vendor_package,
	vendor_package_revenue
} from './vendor_package.reducer';

import {
	app
} from './app.reducer';

import {
	contactUs
} from './contact_us.reducer';

import {
	login as sb_login,
	profile as sb_profile,
	openChannel as sb_openChannel,
	openChannelCreate as sb_openChannelCreate,
	chat as sb_chat,
	member as sb_member,
	blockUser as sb_blockUser,
	groupChannel as sb_groupChannel,
	groupChannelInvite as sb_groupChannelInvite,
	connection as sb_connection,
	unreadCount as sb_unreadCount,
} from '../_sendbird';

import {
	vendor_profile_options,
	vendors,
	featured_vendors
} from './vendor.reducer';

import {
	special
} from './home.reducer';

const rootReducer = combineReducers({
	auth,
	alert,
	header,
	app,
	contactUs,
	sb_login,
	sb_profile,
	sb_openChannel,
	sb_openChannelCreate,
	sb_chat,
	sb_member,
	sb_blockUser,
	sb_groupChannel,
	sb_groupChannelInvite,
	sb_connection,
	sb_unreadCount,
	articles,
	hot_articles,
	article,
	package_destinations,
	package_categories,
	packages,
	package_details,
	register_send_code,
	register,
	login,
	forgot_password,
	edit_profile,
	edit_email,
	edit_password,
	get_user,
	saved_items,
	saved_item_action,
	vendor_profile,
	vendor_calendar,
	my_calendar,
	my_reviews,
	vendor_verification,
	payouts,
	payout,
	payout_receipt,
	payout_settings_form_info,
	payout_settings,
	orders,
	order,
	vendor_packages,
	vendor_drafts,
	vendor_package,
	vendor_package_revenue,
	vendor_profile_options,
	vendors,
	featured_vendors,
	special
});

export default rootReducer;
