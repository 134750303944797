import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { articleActions } from '../../_actions';
import { Loading } from '../../_components';

import './HotArticles.scss';

class HotArticles extends Component {
    componentDidMount() {
		this.props.dispatch(articleActions.getHotList());
    }

	render() {
		const { hot_articles, t } = this.props;

		return (
			<div className="hot-articles">
				<h5 className="heading">{t('hot_articles')}</h5>
				<div className="content">
					{hot_articles.loading && <Loading />}
					{hot_articles.error && <Loading error={hot_articles.error} />}
					{hot_articles.list &&
						<ul>
							{hot_articles.list.map((article, index) =>
							<li key={article.id}>
								<Link to={`/article/${article.id}`}>{article.title}</Link>
							</li>
							)}
						</ul>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { hot_articles } = state;
    return {
		hot_articles
    };
}

const translation = withNamespaces(['article'])(HotArticles);
const connected = connect(mapStateToProps)(translation);
export { connected as HotArticles };
