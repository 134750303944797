export const articleConstants = {
    GET_LIST_REQUEST: 'ARTICLE_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'ARTICLE_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'ARTICLE_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'ARTICLE_GET_LIST_CLEAR',

	GET_HOT_LIST_REQUEST: 'ARTICLE_GET_HOT_LIST_REQUEST',
    GET_HOT_LIST_SUCCESS: 'ARTICLE_GET_HOT_LIST_SUCCESS',
	GET_HOT_LIST_FAILURE: 'ARTICLE_GET_HOT_LIST_FAILURE',
	GET_HOT_LIST_CLEAR: 'ARTICLE_GET_HOT_LIST_CLEAR',

	GET_REQUEST: 'ARTICLE_GET_REQUEST',
    GET_SUCCESS: 'ARTICLE_GET_SUCCESS',
	GET_FAILURE: 'ARTICLE_GET_FAILURE',
	GET_CLEAR: 'ARTICLE_GET_CLEAR'
}