import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, orderConstants } from '../_constants';

export const orderActions = {
	sendRequest,
	sendRequestClear,
	edit,
	editClear,
	makeOffer,
	makeOfferClear,
	getList,
	getListClear,
	get,
	getClear,
	cancel,
	cancelClear,
	paymentProcess,
	paymentProcessClear,
	getPayments,
	getPaymentsClear,
	getPayment,
	getPaymentClear,
	getReceipt,
	getReceiptClear,
	editDatetime,
	editDatetimeClear,
	getEditDatetimeHistory,
	getEditDatetimeHistoryClear,
	getPendingItemsCount,
	getPendingItemsCountClear,
	getPendingItems,
	getPendingItemsClear,
	replyPendingItem,
	replyPendingItemClear,
	cancelPendingItem,
	cancelPendingItemClear,
	getMeetupDetails,
	getMeetupDetailsClear,
	editMeetupDetails,
	editMeetupDetailsClear,
	getAfterShootingMaterials,
	getAfterShootingMaterialsClear,
	editAfterShootingMaterials,
	editAfterShootingMaterialsClear,
	getReceipts,
	getReceiptsClear,
	getReview,
	getReviewClear,
	editReview,
	editReviewClear,
	complete,
	completeClear,
	getReportOptions,
	getReportOptionsClear,
	editReport,
	editReportClear,
	paymentApplyPromoCode,
	paymentApplyPromoCodeClear,
};

function sendRequest(isVendor, data) {
	analytics.event({
		category: 'Order Request',
		action: 'Submit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.request); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', data.id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(data.id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, data.id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), data.id));
			});
    };

    function request(id) { return { type: orderConstants.SEND_REQUEST_REQUEST, id} }
    function success(responseData, id) { return { type: orderConstants.SEND_REQUEST_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.SEND_REQUEST_FAILURE, error, id } }
}

function sendRequestClear() {
	return { type: orderConstants.SEND_REQUEST_CLEAR };
}

function edit(isVendor, id, data) {
	analytics.event({
		category: 'Order',
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.EDIT_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.EDIT_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.EDIT_FAILURE, error, id } }
}

function editClear() {
	return { type: orderConstants.EDIT_CLEAR };
}

function makeOffer(isVendor, id) {
	analytics.event({
		category: 'Order Make Offer',
		action: 'Submit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.make_offer); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.MAKE_OFFER_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.MAKE_OFFER_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.MAKE_OFFER_FAILURE, error, id } }
}

function makeOfferClear() {
	return { type: orderConstants.MAKE_OFFER_CLEAR };
}

function getList(isVendor, type, status, is_expired, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.order.list); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { type, status, is_expired, page_number, per_page });

    return dispatch => {
        dispatch(request(type));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, type));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), type));
			});
    };

    function request(type) { return { type: orderConstants.GET_LIST_REQUEST, order_type: type } }
    function success(responseData, type) { return { type: orderConstants.GET_LIST_SUCCESS, responseData, order_type: type } }
    function failure(error, type) { return { type: orderConstants.GET_LIST_FAILURE, error, order_type: type } }
}

function getListClear() {
	return { type: orderConstants.GET_LIST_CLEAR };
}

function get(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_FAILURE, error, id } }
}

function getClear() {
	return { type: orderConstants.GET_CLEAR };
}

function cancel(isVendor, id) {
	analytics.event({
		category: 'Order',
		action: 'Cancel',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.cancel); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.CANCEL_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.CANCEL_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.CANCEL_FAILURE, error, id } }
}

function cancelClear() {
	return { type: orderConstants.CANCEL_CLEAR };
}

function paymentProcess(isVendor, data) {
	analytics.event({
		category: 'Order Payment',
		action: 'Submit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.payment.process); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(data.payment_id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, data.payment_id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, false), data.payment_id));
			});
    };

    function request(id) { return { type: orderConstants.PAYMENT_PROCESS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.PAYMENT_PROCESS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.PAYMENT_PROCESS_FAILURE, error, id } }
}

function paymentProcessClear() {
	return { type: orderConstants.PAYMENT_PROCESS_CLEAR };
}

function getPayments(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.payment.list); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_PAYMENTS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_PAYMENTS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_PAYMENTS_FAILURE, error, id } }
}

function getPaymentsClear() {
	return { type: orderConstants.GET_PAYMENTS_CLEAR };
}

function getPayment(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.payment.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_PAYMENT_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_PAYMENT_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_PAYMENT_FAILURE, error, id } }
}

function getPaymentClear() {
	return { type: orderConstants.GET_PAYMENT_CLEAR };
}

function getReceipt(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.receipt.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_RECEIPT_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_RECEIPT_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_RECEIPT_FAILURE, error, id } }
}

function getReceiptClear() {
	return { type: orderConstants.GET_RECEIPT_CLEAR };
}

function editDatetime(isVendor, id, data, isDeal) {
	analytics.event({
		category: 'Order Edit Shooting Datetime',
		action: 'Submit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, isDeal? apiEndpoint.order.edit_shoot_datetime : apiEndpoint.order.pending_item.edit_date); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.EDIT_DATETIME_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.EDIT_DATETIME_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.EDIT_DATETIME_FAILURE, error, id } }
}

function editDatetimeClear() {
	return { type: orderConstants.EDIT_DATETIME_CLEAR };
}

function getEditDatetimeHistory(isVendor, id, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.order.pending_item.edit_date_history); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { page_number, per_page });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_EDIT_DATETIME_HISTORY_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_EDIT_DATETIME_HISTORY_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_EDIT_DATETIME_HISTORY_FAILURE, error, id } }
}

function getEditDatetimeHistoryClear() {
	return { type: orderConstants.GET_EDIT_DATETIME_HISTORY_CLEAR };
}

function getPendingItemsCount(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.pending_item.count); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_PENDING_ITEMS_COUNT_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_PENDING_ITEMS_COUNT_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_PENDING_ITEMS_COUNT_FAILURE, error, id } }
}

function getPendingItemsCountClear() {
	return { type: orderConstants.GET_PENDING_ITEMS_COUNT_CLEAR };
}

function getPendingItems(isVendor, id, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.order.pending_item.list); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { page_number, per_page });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_PENDING_ITEMS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_PENDING_ITEMS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_PENDING_ITEMS_FAILURE, error, id } }
}

function getPendingItemsClear() {
	return { type: orderConstants.GET_PENDING_ITEMS_CLEAR };
}

function replyPendingItem(isVendor, order_id, id, accept) {
	analytics.event({
		category: 'Order Edit Shooting Datetime',
		action: `Reply: ${accept? 'accept' : 'decline'}`,
		label: isVendor? 'Vendor' : 'Customer',
	});

	var endpoint = Object.assign({}, apiEndpoint.order.pending_item.reply); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, { accept });

    return dispatch => {
        dispatch(request(order_id, id, accept));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, order_id, id, accept));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, false), order_id, id, accept));
			});
    };

    function request(order_id, id, accept) { return { type: orderConstants.REPLY_PENDING_ITEM_REQUEST, order_id, id, accept } }
    function success(responseData, order_id, id, accept) { return { type: orderConstants.REPLY_PENDING_ITEM_SUCCESS, responseData, order_id, id, accept } }
    function failure(error, order_id, id, accept) { return { type: orderConstants.REPLY_PENDING_ITEM_FAILURE, error, order_id, id, accept } }
}

function replyPendingItemClear() {
	return { type: orderConstants.REPLY_PENDING_ITEM_CLEAR };
}

function cancelPendingItem(isVendor, order_id, id) {
	analytics.event({
		category: 'Order Edit Shooting Datetime',
		action: 'Cancel',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.pending_item.cancel); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(order_id, id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, order_id, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), order_id, id));
			});
    };

    function request(order_id, id) { return { type: orderConstants.CANCEL_PENDING_ITEM_REQUEST, order_id, id } }
    function success(responseData, order_id, id) { return { type: orderConstants.CANCEL_PENDING_ITEM_SUCCESS, responseData, order_id, id } }
    function failure(error, order_id, id) { return { type: orderConstants.CANCEL_PENDING_ITEM_FAILURE, error, order_id, id } }
}

function cancelPendingItemClear() {
	return { type: orderConstants.CANCEL_PENDING_ITEM_CLEAR };
}

function getMeetupDetails(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.meetup.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_MEETUP_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_MEETUP_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_MEETUP_FAILURE, error, id } }
}

function getMeetupDetailsClear() {
	return { type: orderConstants.GET_MEETUP_CLEAR };
}

function editMeetupDetails(isVendor, id, data) {
	analytics.event({
		category: 'Order Meetup Details',
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.meetup.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.EDIT_MEETUP_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.EDIT_MEETUP_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.EDIT_MEETUP_FAILURE, error, id } }
}

function editMeetupDetailsClear() {
	return { type: orderConstants.EDIT_MEETUP_CLEAR };
}

function getAfterShootingMaterials(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.materials.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_MATERIALS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_MATERIALS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_MATERIALS_FAILURE, error, id } }
}

function getAfterShootingMaterialsClear() {
	return { type: orderConstants.GET_MATERIALS_CLEAR };
}

function editAfterShootingMaterials(isVendor, id, data) {
	analytics.event({
		category: 'Order After Shooting Materials',
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.materials.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.EDIT_MATERIALS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.EDIT_MATERIALS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.EDIT_MATERIALS_FAILURE, error, id } }
}

function editAfterShootingMaterialsClear() {
	return { type: orderConstants.EDIT_MATERIALS_CLEAR };
}

function getReceipts(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.receipt.list); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_RECEIPTS_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_RECEIPTS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_RECEIPTS_FAILURE, error, id } }
}

function getReceiptsClear() {
	return { type: orderConstants.GET_RECEIPTS_CLEAR };
}

function getReview(isVendor, id) {
	var endpoint = Object.assign({}, apiEndpoint.order.review.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.GET_REVIEW_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.GET_REVIEW_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.GET_REVIEW_FAILURE, error, id } }
}

function getReviewClear() {
	return { type: orderConstants.GET_RECEIPTS_CLEAR };
}

function editReview(isVendor, id, data) {
	analytics.event({
		category: 'Order Review',
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.review.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.EDIT_REVIEW_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.EDIT_REVIEW_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.EDIT_REVIEW_FAILURE, error, id } }
}

function editReviewClear() {
	return { type: orderConstants.EDIT_REVIEW_CLEAR };
}

function complete(isVendor, id) {
	analytics.event({
		category: 'Order',
		action: 'Complete',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.complete); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: orderConstants.COMPLETE_REQUEST, id } }
    function success(responseData, id) { return { type: orderConstants.COMPLETE_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: orderConstants.COMPLETE_FAILURE, error, id } }
}

function completeClear() {
	return { type: orderConstants.COMPLETE_CLEAR };
}

function getReportOptions(isVendor) {
	var endpoint = Object.assign({}, apiEndpoint.order.report.options); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');

	const requestOptions = api.requestOption(endpoint);

	return dispatch => {
		dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: orderConstants.GET_REPORT_OPTIONS_REQUEST } }
	function success(responseData) { return { type: orderConstants.GET_REPORT_OPTIONS_SUCCESS, responseData } }
	function failure(error) { return { type: orderConstants.GET_REPORT_OPTIONS_FAILURE, error } }
}

function getReportOptionsClear() {
	return { type: orderConstants.GET_REPORT_OPTIONS_CLEAR };
}

function editReport(isVendor, id, data) {
	analytics.event({
		category: 'Order Report',
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.report.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

	return dispatch => {
		dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
	};

	function request(id) { return { type: orderConstants.EDIT_REPORT_REQUEST, id } }
	function success(responseData, id) { return { type: orderConstants.EDIT_REPORT_SUCCESS, responseData, id } }
	function failure(error, id) { return { type: orderConstants.EDIT_REPORT_FAILURE, error, id } }
}

function editReportClear() {
	return { type: orderConstants.EDIT_REPORT_CLEAR };
}

function paymentApplyPromoCode(isVendor, id, data) {
	analytics.event({
		category: 'Order Payment',
		action: 'Apply Promo Code',
		label: isVendor? 'Vendor' : 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.order.payment.apply_promo_code); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':user_type', isVendor? 'vendor' : 'user');
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, data);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, false), id));
			});
    };

    function request(id) { return { type: orderConstants.PAYMENT_APPLY_CODE_REQUEST, id } }
	function success(responseData, id) { return { type: orderConstants.PAYMENT_APPLY_CODE_SUCCESS, responseData, id } }
	function failure(error, id) { return { type: orderConstants.PAYMENT_APPLY_CODE_FAILURE, error, id } }
}

function paymentApplyPromoCodeClear() {
	return { type: orderConstants.PAYMENT_APPLY_CODE_CLEAR };
}