import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Title, Desc, InputItem, InputField } from '../../_components';
import { alertActions, contactUsActions } from '../../_actions';
import { StaticPageContainer } from '../StaticPageContainer';

import './ContactUs.scss';

class ContactUs extends Component {
	componentDidUpdate(prevProps, prevState) {
		const { contactUs, t } = this.props;

		if (contactUs.success && contactUs.success !== prevProps.contactUs.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));

			if (this.state.formikBag) {
				this.state.formikBag.resetForm();
			}
		}
	}
	
	onSubmit(values, formikBag) {
		this.setState({ formikBag }, () => {
			this.props.dispatch(contactUsActions.contactUs(values));
		});
	}

	render() {
		const { t, contactUs, match } = this.props;
		const messageMaxLength = 1000;

		const schema = Yup.object().shape({
			name: Yup.string()
				.required(t('required')),
			email: Yup.string()
				.email(t('email_format_error'))
				.required(t('required')),
			message: Yup.string()
				.max(messageMaxLength)
				.required(t('required')),
		});

		return (
			<StaticPageContainer id="contact_us" match={match}>
				<Formik
					enableReinitialize
					initialValues={{
						name: '',
						email: '',
						message: ''
					}}
					validationSchema={schema}
					onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
				>
					{({ values,
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						setFieldTouched,
						setFieldValue
						/* and other goodies */
					}) => (
							<Form>
								<Container>
									<Row>
										<Col md={{ size: 7 }}>
											<Title className="title-page">
												{t('contact_us')}
											</Title>
											<Desc className="desc-page" dangerouslySetInnerHTML={{ __html: t('contact_us_desc') }} />
											<InputItem>
												<InputField type="text" name="name" t={t} />
											</InputItem>
											<InputItem>
												<InputField type="text" name="email" t={t} />
											</InputItem>
											<InputItem className="input-item-message">
												<InputField component="textarea" type="text" name="message" t={t} />
												<Desc className={`word-count ${values.message.length > messageMaxLength ? 'invalid' : ''}`}>
													{`${values.message.length} / ${messageMaxLength}`}
												</Desc>
											</InputItem>
											<InputItem>
												<Button
													type="submit"
													disabled={contactUs.loading}
													onClick={handleSubmit}>
													{t('send')}
												</Button>
											</InputItem>
										</Col>
									</Row>
								</Container>
							</Form>
						)}
				</Formik>
			</StaticPageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { contactUs } = state;
	return {
		contactUs
	};
}

const translation = withNamespaces(['contact_us', 'default', 'register'])(ContactUs);
const connected = connect(mapStateToProps)(translation);
export { connected as ContactUs };