import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { Help } from './Help';

export class HelpRouter extends Component {
	render() {
		const { match } = this.props;

		let qna = require('./QnA.json');
		let sections = [];
		qna.forEach(section => {
			sections.push(section.key);
		});

		return (
			<Switch>
				<PrivateRoute path={`${match.path}/:tab(${sections.join('|')})`} component={Help} />
				<PrivateRoute exact path={`${match.path}`} component={Help} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}