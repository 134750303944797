import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../../_actions';
import { orderType } from '../../../_constants';
import { InfiniteScrollWrapper, Title } from '../../../_components';

import './Orders.scss';

import { Order } from './Order';

class Orders extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.getListClear());
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { order } = this.props;
		if (order.complete.success && order.complete.success !== prevProps.order.complete.success) {
			this.infiniteScrollComponent.reload();
		}
	}
	
	render() {
		const { t, orders, type } = this.props;
		
		return (
			<div id="customer_profile_orders">
				<Title>{t(type === orderType.deal? 'customer.deal_list' : 'customer.order_list')}</Title>
				<InfiniteScrollWrapper
					elementRef={(element) => this.infiniteScrollComponent = element}
					className={type === orderType.deal? 'deals' : 'orders'}
					loadMoreProp={orders}
					onLoadMore={(nextPage) => {
						this.props.dispatch(orderActions.getList(false, type, undefined, undefined, nextPage));
					}}
					renderItems={(items) => {
						return (
							items.map((item, index) =>
								<Order key={item.id} data={item} />
							)
						)
					}}
					noResultDesc={t(type === orderType.deal? 'no_items_in_deal_list' : 'no_items_in_order_list')}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { orders, order } = state;
	return {
		orders, order
	};
}

const translation = withNamespaces(['order', 'profile'])(Orders);
const connected = connect(mapStateToProps)(translation);
export { connected as Orders };