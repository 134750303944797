// User
export const INIT_LOGIN = 'sb_init_login';
export const LOGIN_REQUEST = 'sb_login_request';
export const LOGIN_SUCCESS = 'sb_login_success';
export const LOGIN_FAIL = 'sb_login_fail';

export const INIT_MENU = 'sb_init_menu';
export const DISCONNECT_SUCCESS = 'sb_disconnect_success';

export const INIT_PROFILE = 'sb_init_profile';
export const GET_PROFILE_SUCCESS = 'sb_get_profile_success';
export const UPDATE_PROFILE_SUCCESS = 'sb_update_profile_success';
export const UPDATE_PROFILE_FAIL = 'sb_update_profile_fail';

// Open Channel
export const INIT_OPEN_CHANNEL = 'sb_init_open_channel';
export const OPEN_CHANNEL_PROGRESS_START = 'sb_open_channel_progress_start';
export const OPEN_CHANNEL_PROGRESS_END = 'sb_open_channel_progress_end';
export const OPEN_CHANNEL_LIST_SUCCESS = 'sb_open_channel_list_success';
export const OPEN_CHANNEL_LIST_FAIL = 'sb_open_channel_list_fail';

export const GET_OPEN_CHANNEL_SUCCESS = 'sb_get_open_channel_success';
export const GET_OPEN_CHANNEL_FAIL = 'sb_get_open_channel_fail';
export const ADD_OPEN_CHANNEL_ITEM = 'sb_add_open_channel_item';
export const CLEAR_ADD_OPEN_CHANNEL = 'sb_clear_add_open_channel';
export const CLEAR_SELETED_OPEN_CHANNEL = 'sb_clear_selected_open_channel';

export const INIT_OPEN_CHANNEL_CREATE = 'sb_init_open_channel_create';
export const OPEN_CHANNEL_CREATE_SUCCESS = 'sb_open_channel_create_success';
export const OPEN_CHANNEL_CREATE_FAIL = 'sb_open_channel_create_fail';

// Group Channel
export const INIT_GROUP_CHANNEL = 'sb_init_group_channel';
export const GROUP_CHANNEL_PROGRESS_START = 'sb_group_channel_progress_start';
export const GROUP_CHANNEL_PROGRESS_END = 'sb_group_channel_progress_end';
export const GROUP_CHANNEL_LIST_REQUEST = 'sb_group_channel_list_request';
export const GROUP_CHANNEL_LIST_SUCCESS = 'sb_group_channel_list_success';
export const GROUP_CHANNEL_LIST_FAIL = 'sb_group_channel_list_fail';
export const GET_GROUP_CHANNEL_SUCCESS = 'sb_get_group_channel_success';
export const GET_GROUP_CHANNEL_FAIL = 'sb_get_group_channel_fail';
export const CHANNEL_EDIT_SUCCESS = 'sb_channel_edit_success';
export const CHANNEL_EDIT_FAIL = 'sb_channel_edit_fail';
export const ADD_GROUP_CHANNEL_ITEM = 'sb_add_group_channel_item';
export const CLEAR_SELECTED_GROUP_CHANNEL = 'sb_clear_selected_group_channel';

export const INIT_INVITE = 'sb_init_invite';
export const USER_LIST_SUCCESS = 'sb_user_list_success';
export const USER_LIST_FAIL = 'sb_user_list_fail';
export const CREATE_GROUP_CHANNEL_SUCCESS = 'sb_create_group_channel_success';
export const CREATE_GROUP_CHANNEL_FAIL = 'sb_create_group_channel_fail';
export const INVITE_GROUP_CHANNEL_SUCCESS = 'sb_invite_group_channel_success';
export const INVITE_GROUP_CHANNEL_FAIL = 'sb_invite_group_channel_fail';

export const GROUP_CHANNEL_CHANGED = 'sb_group_channel_changed';

export const GET_NUMBER_OF_UNREAD_GROUP_CHANNELS_SUCCESS = 'sb_get_number_of_unread_channels_success';
export const GET_NUMBER_OF_UNREAD_GROUP_CHANNELS_FAIL = 'sb_get_number_of_unread_channels_fail';

export const GROUP_CHANNEL_MESSAGE_RECEIVED = 'sb_group_channel_message_received';
export const GROUP_CHANNEL_TYPING_STATUS_UPDATED = 'sb_group_channel_typing_status_updated';

// Chat
export const INIT_CHAT_SCREEN = 'sb_init_chat_screen';
export const CREATE_CHAT_HANDLER_SUCCESS = 'sb_create_chat_handler_success';
export const CREATE_CHAT_HANDLER_FAIL = 'sb_create_chat_handler_fail';
export const CHANNEL_TITLE_CHANGED = 'sb_channel_title_changed';
export const CHANNEL_TITLE_CHANGED_FAIL = 'sb_channel_title_changed_fail';
export const MESSAGE_LIST_PROGRESS_START = 'sb_message_list_progress_start';
export const MESSAGE_LIST_PROGRESS_END = 'sb_message_list_progress_end';
export const MESSAGE_LIST_SUCCESS = 'sb_message_list_success';
export const MESSAGE_LIST_FAIL = 'sb_message_list_fail';
export const SEND_MESSAGE_TEMPORARY = 'sb_send_message_temporary';
export const SEND_MESSAGE_SUCCESS = 'sb_send_message_success';
export const SEND_MESSAGE_FAIL = 'sb_send_message_fail';

export const SEND_TYPING_START_SUCCESS = 'sb_send_typing_start_success';
export const SEND_TYPING_START_FAIL = 'sb_send_typing_start_fail';
export const SEND_TYPING_END_SUCCESS = 'sb_send_typing_end_success';
export const SEND_TYPING_END_FAIL = 'sb_send_typing_end_fail';

export const CHANNEL_EXIT_SUCCESS = 'sb_channel_exit_success';
export const CHANNEL_EXIT_FAIL = 'sb_channel_exit_fail';

export const MESSAGE_RECEIVED = 'sb_message_received';
export const MESSAGE_UPDATED = 'sb_message_updated';
export const MESSAGE_DELETED = 'sb_message_deleted';
export const CHANNEL_CHANGED = 'sb_channel_changed';
export const TYPING_STATUS_UPDATED = 'sb_typing_status_updated';
export const READ_RECEIPT_UPDATED = 'sb_read_receipt_updated';

// Member/Participant
export const INIT_MEMBER = 'sb_init_member';
export const MEMBER_LIST_SUCCESS = 'sb_member_list_success';
export const MEMBER_LIST_FAIL = 'sb_member_list_fail';

// Block
export const USER_BLOCK_SUCCESS = 'sb_user_block_success';
export const USER_BLOCK_FAIL = 'sb_user_block_fail';
export const INIT_BLOCK_USER = 'sb_init_block_user';
export const BLOCK_LIST_SUCCESS = 'sb_block_list_success';
export const BLOCK_LIST_FAIL = 'sb_block_list_fail';
export const USER_UNBLOCK_SUCCESS = 'sb_user_unblock_success';
export const USER_UNBLOCK_FAIL = 'sb_user_unblock_fail';

// Reconnect
export const CREATE_CONNECTION_HANDLER_SUCCESS = 'sb_create_connection_handler_success';
export const CREATE_CONNECTION_HANDLER_FAIL = 'sb_create_connection_handler_fail';
export const REMOVE_CONNECTION_HANDLER_SUCCESS = 'sb_remove_connection_handler_success';
export const RECONNECTION_START = 'sb_reconnection_start';
export const RECONNECTION_SUCCESS = 'sb_reconnection_success';
export const RECONNECTION_FAIL = 'sb_reconnection_fail';

// Unread count
export const CREATE_UNREAD_COUNT_HANDLER_SUCCESS = 'sb_create_unread_count_handler_success';
export const CREATE_UNREAD_COUNT_HANDLER_FAIL = 'sb_create_unread_count_handler_fail';
export const REMOVE_UNREAD_COUNT_HANDLER_SUCCESS = 'sb_remove_unread_count_handler_success';
export const UNREAD_COUNT_UPDATED = 'sb_unread_count_updated';