export const appConstants = {
    GET_CURRENCIES_REQUEST: 'APP_GET_CURRENCIES_REQUEST',
    GET_CURRENCIES_SUCCESS: 'APP_GET_CURRENCIES_SUCCESS',
	GET_CURRENCIES_FAILURE: 'APP_GET_CURRENCIES_FAILURE',
	GET_CURRENCIES_CLEAR: 'APP_GET_CURRENCIES_CLEAR',
	SET_CURRENCY: 'APP_SET_CURRENCY',

	GET_LANGUAGES_REQUEST: 'APP_GET_LANGUAGES_REQUEST',
    GET_LANGUAGES_SUCCESS: 'APP_GET_LANGUAGES_SUCCESS',
	GET_LANGUAGES_FAILURE: 'APP_GET_LANGUAGES_FAILURE',
	GET_LANGUAGES_CLEAR: 'APP_GET_LANGUAGES_CLEAR',
	SET_LANGUAGE: 'APP_SET_LANGUAGE',

	SET_WINDOW_SIZE: 'APP_SET_WINDOW_SIZE',

	SHOW_HIDE_FOOTER: 'APP_SHOW_HIDE_FOOTER',
}