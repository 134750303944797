
import React from "react";

import './PendingItemsWrapper.scss';

import { Header } from '../Header';
import { PendingItems } from '../../../Order';

export const PendingItemsWrapper = ({ data, ...props}) => {
	return (
		<div id="vendor_order_pendingitems">
			<Header data={data} />
			<PendingItems data={data} />
		</div>
	);
}