import { 
	CREATE_UNREAD_COUNT_HANDLER_SUCCESS,
	CREATE_UNREAD_COUNT_HANDLER_FAIL,
	UNREAD_COUNT_UPDATED,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
	count: 0
}

export function unreadCount (state = INITIAL_STATE, action) {
    switch(action.type) {
		case CREATE_UNREAD_COUNT_HANDLER_SUCCESS: 
		case CREATE_UNREAD_COUNT_HANDLER_FAIL:
            return { ...state };
		case UNREAD_COUNT_UPDATED:
			return { ...state, count: action.count };
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
				return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}
