import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CardsContainer, RowCards, ColCards } from '../../../_components';

import './Details.scss';

import { PackageDetails, Payments } from '../../../Order';
import { VendorRemarks } from './VendorRemarks';

class Details extends Component {
	render() {
		const { data, order } = this.props;

		return (
			<CardsContainer id="customer_order_details">
				<RowCards>
					<ColCards lg={{ size: (order.payments.list && order.payments.list.length)? 6 : 12, order: 1 }} xs={{ order: 2 }}>
						{data.remarks &&
							<VendorRemarks data={data} />
						}
						<PackageDetails data={data.order_package} />
					</ColCards>
					<ColCards lg={{ size: 6, order: 2 }} xs={{ order: 1 }} className={(order.payments.list && order.payments.list.length)? '' : 'd-none'}>
						<Payments data={data} />
					</ColCards>
				</RowCards>
			</CardsContainer>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const connected = connect(mapStateToProps)(Details);
export { connected as Details };