import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal } from 'reactstrap';

import './ActionModal.scss';

import { InputForm } from './InputForm';
import { Success } from './Success';
import { BaseModal } from '../BaseModal';

class ActionModal extends Component {
	componentDidUpdate(prevProps, prevState) {
		const { isSuccess, error, disabledCloseOnError } = this.props;

		if (isSuccess && isSuccess !== prevProps.isSuccess) {
			if (this.props.onSuccess) {
				this.props.onSuccess();
			}
		}

		if (error && error !== prevProps.error && !disabledCloseOnError) {
			this.props.toggle();
		}
	}
	
	render() {
		const { t, id, className, size, confirmTitle, confirmDesc, confirmBtnTitle, confirmComponent, successTitle, successDesc, successBtnTitle, successComponent, isSuccess, isLoading } = this.props;

		let _confirmBtnTitle = confirmBtnTitle? confirmBtnTitle : t('confirm');
		let _successBtnTitle = successBtnTitle? successBtnTitle : t('ok');

		return (
			<BaseModal
				id={id}
				className={`action-modal ${className? className : ''}`}
				size={size}
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
				onOpened={() => {
					if (this.props.onOpened) {
						this.props.onOpened();
					}
				}}
				onClosed={() => {
					if (this.props.onClosed) {
						this.props.onClosed();
					}
				}}
				contentClassName={isSuccess? 'modal-content-success' : 'modal-content-form'}
			>
				{isSuccess ?
					successComponent ?
						successComponent
						:
						<Success
							title={successTitle}
							desc={successDesc}
							btnTitle={_successBtnTitle}
							toggle={() => this.props.toggle()}
						/>
					:
					confirmComponent ?
						confirmComponent
						:
						<InputForm
							title={confirmTitle}
							desc={confirmDesc}
							btnTitle={_confirmBtnTitle}
							isLoading={isLoading}
							onSubmit={() => this.props.onSubmit()}
							toggle={() => this.props.toggle()}
						/>
				}
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['default'])(ActionModal);
export { translation as ActionModal };