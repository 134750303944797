export const payoutSettingsConstants = {
	GET_FORM_INFO_REQUEST: 'PAYOUT_SETTINGS_GET_FORM_INFO_REQUEST',
    GET_FORM_INFO_SUCCESS: 'PAYOUT_SETTINGS_GET_FORM_INFO_SUCCESS',
	GET_FORM_INFO_FAILURE: 'PAYOUT_SETTINGS_GET_FORM_INFO_FAILURE',
	GET_FORM_INFO_CLEAR: 'PAYOUT_SETTINGS_GET_FORM_INFO_CLEAR',

	GET_REQUEST: 'PAYOUT_SETTINGS_GET_REQUEST',
    GET_SUCCESS: 'PAYOUT_SETTINGS_GET_SUCCESS',
	GET_FAILURE: 'PAYOUT_SETTINGS_GET_FAILURE',
	GET_CLEAR: 'PAYOUT_SETTINGS_GET_CLEAR',

	EDIT_REQUEST: 'PAYOUT_SETTINGS_EDIT_REQUEST',
    EDIT_SUCCESS: 'PAYOUT_SETTINGS_EDIT_SUCCESS',
	EDIT_FAILURE: 'PAYOUT_SETTINGS_EDIT_FAILURE',
	EDIT_CLEAR: 'PAYOUT_SETTINGS_EDIT_CLEAR',
}