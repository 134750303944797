import { homeConstants, savedItemsConstants } from '../_constants';

const specialDetailsInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}
const specialsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const specialInitialState = {
	list: specialsInitialState,
	details: specialDetailsInitialState
}

export function special(state = specialInitialState, action) {
	switch (action.type) {
		case homeConstants.GET_SPECIAL_LIST_CLEAR:
			return { ...state, list: { ...specialsInitialState } };
		case homeConstants.GET_SPECIAL_LIST_REQUEST:
			return { ...state, list: { ...specialsInitialState, loading: true } };
		case homeConstants.GET_SPECIAL_LIST_SUCCESS:
			return { ...state, list: { ...specialsInitialState, loading: false, success: true, list: action.responseData, } };
		case homeConstants.GET_SPECIAL_LIST_FAILURE:
				return { ...state, list: { ...specialsInitialState, loading: false, success: false, error: action.error } };

		case homeConstants.GET_SPECIAL_DETAILS_CLEAR:
			return { ...state, details: { ...specialDetailsInitialState } };
		case homeConstants.GET_SPECIAL_DETAILS_REQUEST:{
			let updateFromState = state.id == action.id? state : specialDetailsInitialState;
			return { ...state, details: { ...updateFromState, id: action.id , loading: true } };
		}
		case homeConstants.GET_SPECIAL_DETAILS_SUCCESS: {
			return { ...state, details: { ...state.details, id: action.id, loading: false, success: true, error: undefined, data: action.responseData } };
		}
		case homeConstants.GET_SPECIAL_DETAILS_FAILURE:
			return { ...state, details: { ...state.details, id: action.id, loading: false, success: false, error: action.error } };


		case savedItemsConstants.ADD_ARTICLE_SUCCESS: {
			if (state.details.data && state.details.data.article_items) {
				const updatedList = state.details.data.article_items.map((item) => {
					if (item.article.id == action.id) {
						item.article.is_bookmarked = true;
					}
					return item;
				})
				let updatedDetails = state.details;
				updatedDetails.data.article_items = updatedList;
				return { ...state, details: updatedDetails };
			}
			return state;
		}
		case savedItemsConstants.REMOVE_ARTICLE_SUCCESS: {
			if (state.details.data && state.details.data.article_items) {
				const updatedList = state.details.data.article_items.map((item) => {
					if (item.article.id == action.id) {
						item.article.is_bookmarked = false;
					}
					return item;
				})
				let updatedDetails = state.details;
				updatedDetails.data.article_items = updatedList;
				return { ...state, details: updatedDetails };
			}
			return state;
		}
		case savedItemsConstants.ADD_PACKAGE_SUCCESS: {
			if (state.details.data && state.details.data.package_items) {
				const updatedList = state.details.data.package_items.map((item) => {
					if (item.package.id == action.id) {
						item.package.is_bookmarked = true;
					}
					return item;
				})
				let updatedDetails = state.details;
				updatedDetails.data.package_items = updatedList;
				return { ...state, details: updatedDetails };
			}
			return state;
		}
		case savedItemsConstants.REMOVE_PACKAGE_SUCCESS: {
			if (state.details.data && state.details.data.package_items) {
				const updatedList = state.details.data.package_items.map((item) => {
					if (item.package.id == action.id) {
						item.package.is_bookmarked = false;
					}
					return item;
				})
				let updatedDetails = state.details;
				updatedDetails.data.package_items = updatedList;
				return { ...state, details: updatedDetails };
			}
			return state;
		}

		
		default:
			return state
	}
}