import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'reactstrap';

import { userActions, alertActions } from '../../../_actions';
import { InputField, InputFilePreview, InputItemLabel, InputItem, Title } from '../../../_components';
import { EditEmail, EditPassword, EditPasswordUserType } from '../../../User';

import './AccountProfile.scss';

class AccountProfile extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			editEmailModalIsOpen: false,
			editPasswordModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(userActions.editProfileClear());
		this.props.dispatch(userActions.getUser());
	}

	componentDidUpdate(prevProps, prevState) {
		const { edit_profile, t } = this.props;

		if (edit_profile.success && edit_profile.success !== prevProps.edit_profile.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
		}
	}

	onEditEmail() {
		this.setState({ editEmailModalIsOpen: true });
	}

	onEditPassword() {
		this.setState({ editPasswordModalIsOpen: true });
	}

	onSubmit(values, { setSubmitting }) {
		let formData = new FormData();
		formData.append('nickname', values.nickname);
		if (values.profile_pic) {
			formData.append('profile_pic', values.profile_pic);
		}
		formData.append('profile_pic_url', values.profile_pic_url);
		this.props.dispatch(userActions.editProfile(this.props.user.user_type, formData));
	}

	render() {
		const { t, user, edit_profile } = this.props;
		const { editEmailModalIsOpen, editPasswordModalIsOpen } = this.state;
		let initialValues = Object.assign({}, user);
		user['profile_pic'] = undefined;

		var schema = Yup.object().shape({
			nickname: Yup.string()
				.required(t('required')),
		});

		return (
			<div id="customer_profile_account_profile">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
				>
					{({ values,
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setFieldTouched
						/* and other goodies */
					}) => (
							<Form>
								<Title>{t('customer.account_profile')}</Title>
								<InputItem>
									<Row>
										<Col lg={{ size: 3 }}>
											<div>{t('email')}</div>
										</Col>
										<Col lg={{ size: 9 }}>
											<div className="row-inner">
												{user.email}
											</div>
											<div className="row-inner">
												<Button color="link" onClick={() => this.onEditEmail()}>{t('change_email')}</Button>
											</div>
										</Col>
									</Row>
								</InputItem>
								<InputItem>
									<Row>
										<Col lg={{ size: 3 }}>
											<div>{t('password')}</div>
										</Col>
										<Col lg={{ size: 9 }}>
											<Button color="link" onClick={() => this.onEditPassword()}>{t('change_password')}</Button>
										</Col>
									</Row>
								</InputItem>
								<InputItem>
									<Row>
										<Col lg={{ size: 3 }}>
											<InputItemLabel>{t('nickname')}</InputItemLabel>
										</Col>
										<Col lg={{ size: 9 }}>
											<InputField type="text" name="nickname" t={t} />
										</Col>
									</Row>
								</InputItem>
								<InputItem>
									<Row>
										<Col lg={{ size: 3 }}>
											<InputItemLabel>{t('profile_pic')}</InputItemLabel>
										</Col>
										<Col lg={{ size: 9 }}>
											<div className="row-inner">
												<InputFilePreview file={values.profile_pic} />
												{values.profile_pic ? null : <img className="profile-pic-preview" src={user.profile_pic_url} alt={user.nickname} />}
											</div>
											<div className="row-inner">
												<label htmlFor="profile_pic" className="upload-image">
													{t('upload_image')}
												</label>
												<input id="profile_pic" name="profile_pic" type="file" accept="image/*" onChange={(event) => {
													if (event.currentTarget.files.length) {
														setFieldTouched('profile_pic', true);
														setFieldValue("profile_pic", event.currentTarget.files[0]);
													}

													event.target.value = null;
												}} className="form-control input-upload-image" />
											</div>
										</Col>
									</Row>
								</InputItem>
								<InputItem>
									<Row>
										<Col xs={{ size: 'auto' }}>
											<Button
												block
												type="submit"
												disabled={edit_profile.loading}
												onClick={handleSubmit}>
												{t('save')}
											</Button>
										</Col>
									</Row>
								</InputItem>
							</Form>
						)}
				</Formik>
				<EditEmail
					user_userType={user.user_type}
					isOpen={editEmailModalIsOpen}
					toggle={() => this.setState({ editEmailModalIsOpen: !this.state.editEmailModalIsOpen })}
				/>
				<EditPassword
					userType={EditPasswordUserType.customer}
					isOpen={editPasswordModalIsOpen}
					toggle={() => this.setState({ editPasswordModalIsOpen: !this.state.editPasswordModalIsOpen })}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth, edit_profile } = state;
	const { user } = auth;
	return {
		user,
		edit_profile
	};
}

const translation = withNamespaces(['profile', 'default'])(AccountProfile);
const connected = connect(mapStateToProps)(translation);
export { connected as AccountProfile };