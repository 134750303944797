export const vendorProfileConstants = {
    GET_CALENDAR_REQUEST: 'VENDOR_PROFILE_GET_CALENDAR_REQUEST',
    GET_CALENDAR_SUCCESS: 'VENDOR_PROFILE_GET_CALENDAR_SUCCESS',
	GET_CALENDAR_FAILURE: 'VENDOR_PROFILE_GET_CALENDAR_FAILURE',
	GET_CALENDAR_CLEAR: 'VENDOR_PROFILE_GET_CALENDAR_CLEAR',

	GET_DETAILS_REQUEST: 'VENDOR_PROFILE_GET_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'VENDOR_PROFILE_GET_DETAILS_SUCCESS',
	GET_DETAILS_FAILURE: 'VENDOR_PROFILE_GET_DETAILS_FAILURE',
	GET_DETAILS_CLEAR: 'VENDOR_PROFILE_GET_DETAILS_CLEAR',

	GET_REVIEWS_REQUEST: 'VENDOR_PROFILE_GET_REVIEWS_REQUEST',
    GET_REVIEWS_SUCCESS: 'VENDOR_PROFILE_GET_REVIEWS_SUCCESS',
	GET_REVIEWS_FAILURE: 'VENDOR_PROFILE_GET_REVIEWS_FAILURE',
	GET_REVIEWS_CLEAR: 'VENDOR_PROFILE_GET_REVIEWS_CLEAR',

	GET_PACKAGES_REQUEST: 'VENDOR_PROFILE_GET_PACKAGES_REQUEST',
    GET_PACKAGES_SUCCESS: 'VENDOR_PROFILE_GET_PACKAGES_SUCCESS',
	GET_PACKAGES_FAILURE: 'VENDOR_PROFILE_GET_PACKAGES_FAILURE',
	GET_PACKAGES_CLEAR: 'VENDOR_PROFILE_GET_PACKAGES_CLEAR',
}