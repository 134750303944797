import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { orderActions } from '../../../_actions';
import { Loading, ActionModal } from '../../../_components';
import { canEditOrderAfterShootingMaterialsByData } from '../../../_constants';

import './AfterShootingMaterials.scss';

import { Header } from '../Header';
import { AfterShootingMaterialsDetails } from '../../../Order';
import { InputForm } from './InputForm';

class AfterShootingMaterials extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEdit: false,
			confirmModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.editAfterShootingMaterialsClear());

		const { data } = this.props;
		this.props.dispatch(orderActions.getAfterShootingMaterials(true, data.id));
	}


	componentDidUpdate(prevProps, prevState) {
		const { data, order } = this.props;

		if (order.materials.success && order.materials.success !== prevProps.order.materials.success) {
			if (!order.materials.data) {
				this.setState({ isEdit: true });
			}
		}

		if (order.materials.edit.success && order.materials.edit.success !== prevProps.order.materials.edit.success) {
			this.props.dispatch(orderActions.getAfterShootingMaterials(true, data.id));
		}
	}
	
	onEditCancel(showConfirm, resetForm) {
		resetForm();

		const { t } = this.props;
		if (showConfirm) {
			if (window.confirm(t('you_have_unsaved_changes_confirm_to_cancel'))) {
				this.setState({ isEdit: false });
			}
		} else {
			this.setState({ isEdit: false });
		}
	}

	onEdit() {
		if (this.state.isEdit) return;
		this.setState({ isEdit: true });
	}

	onSubmit(values) {
		const { data } = this.props;
		this.props.dispatch(orderActions.editAfterShootingMaterials(true, data.id, values));
	}

	render() {
		const { t, data, order  } = this.props;
		const { isEdit, confirmModalIsOpen } = this.state;

		const canEdit = canEditOrderAfterShootingMaterialsByData(data);

		let initialValues = {
			items: [
			]
		};

		let materialsData = null;
		if (order.materials.success) {
			materialsData = order.materials.data;

			if (materialsData) {
				initialValues.items = materialsData.items;
			} else {
				if (data.order_package.package_includes.edited_photo) {
					initialValues.items.push({ key: 'edited_photo', name: t('name.edited_photo'), desc: ''});
				}
				if (data.order_package.package_includes.original_photo) {
					initialValues.items.push({ key: 'original_photo', name: t('name.original_photo'), desc: ''});
				}
				if (data.order_package.package_includes.video_highlight) {
					initialValues.items.push({ key: 'video_highlight', name: t('name.video_highlight'), desc: ''});
				}
				if (data.order_package.package_includes.photo_book) {
					initialValues.items.push({ key: 'photo_book', name: t('name.photo_book'), desc: ''});
				}
			}
		}

		const schema = Yup.object().shape({
			items: Yup.array().of(Yup.object().shape({
				name: Yup.string()
					.required(t('required')),
				desc: Yup.string()
					.required(t('required')),
			})).required(t('required'))
		});

		return (
			<div id="vendor_order_after_shooting_materials">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={(values, formikBag) => this.setState({ confirmModalIsOpen: true })}
				>
					{({ values,
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setFieldTouched,
						validateField,
						resetForm
						/* and other goodies */
					}) => (
							<Form>
								<Header data={data} accessories=
									{canEdit ?
										isEdit ?
											<>
												{materialsData &&
													<Col sm={{ size: 'auto' }} className="display-desktop">
														<Button
															color="cancel"
															block
															disabled={order.materials.edit.loading}
															onClick={() => this.onEditCancel(Object.keys(touched).length !== 0, resetForm)}>
															{t('cancel')}
														</Button>
													</Col>
												}
												<Col sm={{ size: 'auto' }} className="display-desktop">
													<Button
														block
														disabled={order.materials.edit.loading}
														onClick={handleSubmit}>
														{t('submit')}
													</Button>
												</Col>
											</>
											:
											<Col sm={{ size: 'auto' }}>
												<Button
													block
													onClick={() => {
														resetForm();
														this.onEdit();
													}}>
													{t('edit')}
												</Button>
											</Col>
										:
										null
									}
								/>
								{order.materials.loading && <Loading />}
								{order.materials.error && <Loading error={order.materials.error} />}
								{order.materials.success &&
									(
										isEdit ?
											(
												canEdit ?
													<InputForm />
													:
													<div>{t('please_edit_after_shooting')}</div>
											)
											:
											<AfterShootingMaterialsDetails data={materialsData} />
									)
								}
								<ActionModal
									id="vendor_order_after_shooting_materials_confirm_modal"
									confirmTitle={t('confirm.title')}
									confirmDesc={t('confirm.desc')}
									successTitle={t('success.title')}
									successDesc={t('success.desc')}
									isLoading={order.materials.edit.loading}
									isSuccess={order.materials.edit.success}
									error={order.materials.edit.error}
									isOpen={confirmModalIsOpen}
									toggle={() => this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen })}
									onSubmit={() => this.onSubmit(values)}
									onClosed={() => {
										this.props.dispatch(orderActions.editAfterShootingMaterialsClear());
									}}
									onSuccess={() => {
										this.setState({ isEdit: false });
										resetForm();
									}}
								/>
							</Form>
						)}
				</Formik>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['aftershooting','default'])(AfterShootingMaterials);
const connected = connect(mapStateToProps)(translation);
export { connected as AfterShootingMaterials };