import axios from 'axios';

import { api, app } from '../_helpers';
import { apiEndpoint, homeConstants } from '../_constants';

export const homeActions = {
	getSpecialList,
	getSpecialListClear,

	getSpecialDetails,
	getSpecialDetailsClear
};

function getSpecialList() {
	const requestOptions = api.requestOption(apiEndpoint.home.special.list);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: homeConstants.GET_SPECIAL_LIST_REQUEST } }
    function success(responseData) { return { type: homeConstants.GET_SPECIAL_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: homeConstants.GET_SPECIAL_LIST_FAILURE, error } }
}

function getSpecialListClear() {
	return { type: homeConstants.GET_SPECIAL_LIST_CLEAR };
}

function getSpecialDetails(id) {
	var endpoint = Object.assign({}, apiEndpoint.home.special.details); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, { currency_id: app.getCurrency().id });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: homeConstants.GET_SPECIAL_DETAILS_REQUEST, id } }
    function success(responseData, id) { return { type: homeConstants.GET_SPECIAL_DETAILS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: homeConstants.GET_SPECIAL_DETAILS_FAILURE, error, id } }
}

function getSpecialDetailsClear() {
	return { type: homeConstants.GET_SPECIAL_DETAILS_CLEAR };
}