import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Badge, Container, Row, Col } from 'reactstrap';

import { userActions } from '../../_actions';
import { orderType } from '../../_constants';
import { PageContainer, ProfilePicImg, PrivateRoute, BtnInbox } from '../../_components';

import './MyProfile.scss';

import { AccountProfile } from './AccountProfile';
import { SavedItemsRouter } from './SavedItems';
import { Orders } from './Orders';

class MyProfile extends Component {
	onLogout() {
		this.props.dispatch(userActions.logout(this.props.user.user_type));
	}

	render() {
		const { t, user, match } = this.props;

		const baseUrl = match.url.replace('/' + match.params.tab, '');
		const basePath = match.path.indexOf('/:tab') !== -1 ? match.path.substr(0, match.path.indexOf('/:tab')) : match.path;

		return (
			<PageContainer id="customer_profile">
				<Container>
					<Row>
						<Col lg={{ size: 3 }} className="left-content">
							<div className="user-profile">
								<ProfilePicImg src={user.profile_pic_url} alt={user.nickname} />
								<div className="nickname">{user.nickname}</div>
								<BtnInbox isVendor={false} />
							</div>
							<div className="menu">
								<Link to={`${baseUrl}`} className={"menu-item " + (!match.params.tab ? 'active' : '')}>{t('customer.account_profile')}</Link>
								<Link to={`${baseUrl}/orders`} className={"menu-item " + (match.params.tab === 'orders' ? 'active' : '')}>{t('customer.order_list')}</Link>
								<Link to={`${baseUrl}/deals`} className={"menu-item " + (match.params.tab === 'deals' ? 'active' : '')}>{t('customer.deal_list')}</Link>
								<Link to={`${baseUrl}/saved_items`} className={"menu-item " + (match.params.tab === 'saved_items' ? 'active' : '')}>{t('customer.saved_items')}</Link>
								<Button color="link" className="menu-item" onClick={() => this.onLogout()}>{t('logout')}</Button>
							</div>
						</Col>
						<Col lg={{ size: 9 }} className="right-content">
							<Switch>
								<PrivateRoute exact path={`${basePath}`} component={AccountProfile} />
								<PrivateRoute path={`${basePath}/saved_items`} component={SavedItemsRouter} />
								<PrivateRoute exact path={`${basePath}/orders`} render={props => (
									<Orders key="order" {...props} type={orderType.order} /> //adding key here to force rerender https://stackoverflow.com/a/49001192
								)} />
								<PrivateRoute exact path={`${basePath}/deals`} render={props => (
									<Orders key="deal" {...props} type={orderType.deal} />
								)} />
								<Redirect to='/error/404' />
							</Switch>
						</Col>
					</Row>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user
	};
}

const translation = withNamespaces(['profile', 'default'])(MyProfile);
const connected = connect(mapStateToProps)(translation);
export { connected as MyProfile };