import SendBird from 'sendbird';

export const sbGetChannelTitle = (channel) => {
    if (channel.isOpenChannel()) {
        return channel.name
    } else {
		let { members } = channel;
		if (members.length === 2) {
			members = members.slice(0).filter((member) => member.userId !== SendBird.getInstance().currentUser.userId);
		}
        let nicknames = members.map((member) => {
            return member.nickname
        }).join(', ');
        
        if (nicknames.length > 21) {
            nicknames = nicknames.substring(0, 17) + '...'
        }

        return nicknames;
    }
}

export const sbGetChannelInfo = (channel) => {
    if (channel.isOpenChannel()) {
        return { title: channel.name, imageUrl: channel.coverUrl, lastSeenAt: -1 }
    } else {
		let { members } = channel;
		let imageUrl = channel.coverUrl;
		let lastSeenAt = -1;
		let connectionStatus = undefined;

		if (members.length === 2) {
			members = members.slice(0).filter((member) => member.userId !== SendBird.getInstance().currentUser.userId);
			const targetUser = members[0];
			imageUrl = targetUser.profileUrl;
			lastSeenAt = targetUser.lastSeenAt;
			connectionStatus = targetUser.connectionStatus;
		}
        let nicknames = members.map((member) => {
            return member.nickname
        }).join(', ');

        return { title: nicknames, imageUrl, lastSeenAt, connectionStatus };
    }
}

export const sbIsImageMessage = (message) => {
    return message.type.match(/^image\/.+$/);
}

export const sbAdjustMessageList = (list) => {
    return list.map((message, i) => {
        message['time'] = sbUnixTimestampToDate(message.createdAt);
        message['readCount'] = 0;
        if (message.isUserMessage() || message.isFileMessage()) {
            message['isUser'] = (message.sender.userId === SendBird.getInstance().currentUser.userId);
        } else {
            message['isUser'] = false;
        }
        if (message.sender) {
            message.sender['isShow'] = true;
            if(!message.sender.profileUrl) {
                message.sender.profileUrl = 'default-image';
            }
        }

        if (i < list.length - 1) {
            let prevMessage = list[i + 1];
            if (message.isUserMessage() || message.isFileMessage()) {
                if (prevMessage.isUserMessage() || prevMessage.isFileMessage()) {
                    if (prevMessage.sender.userId === message.sender.userId) {
                        message.sender.isShow = false;  
                    }
                }
            }
        }
        return message
    });
}

export const sbUnixTimestampToDate = (unixTimestamp) => {
    const today = new Date();
    const date = new Date(unixTimestamp);

    if (today.getMonth() !== date.getMonth() || today.getDay() !== date.getDay()) {
        return date.getMonth() + '/' + date.getDay();
    } else {
        const hour = '0' + date.getHours();
        const minute = '0' + date.getMinutes();
        return hour.substr(-2) + ':' + minute.substr(-2);
    }
}
