import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { userActions, alertActions } from '../../../_actions';

import './VendorProfile.scss';

import { Profile } from './Profile';
import { Reviews } from './Reviews';
import { Title } from '../../../_components';

const VendorProfileTab = {
	profile: 0,
	reviews: 1
}

const VendorProfileTabPathnames = ['profile', 'reviews'];

class VendorProfile extends Component {
	constructor(props) {
		super(props);
		
		const { match } = props;
		let tabIndex = this.tabIndexByMatch(match);
		this.state = { tabIndex: tabIndex };
	}

	componentDidMount() {
		this.props.dispatch(userActions.getUser());
		this.props.dispatch(userActions.editProfileClear());
	}

	componentDidUpdate(prevProps, prevState) {
		const { location, edit_profile, t } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		if (edit_profile.success && edit_profile.success !== prevProps.edit_profile.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
		}
	}
	
	onRouteChanged(props) {
		const { match } = props;

		let tabIndex = this.tabIndexByMatch(match);
		this.setState({ tabIndex: tabIndex });
	}

	tabIndexByMatch(match) {
		let tabIndex = VendorProfileTabPathnames.indexOf(match.params.tab);
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		return `${match.url.replace(`/${match.params.tab }`, '')}/${VendorProfileTabPathnames[tabIndex]}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	onSubmit(values, { setSubmitting }) {
		let formData = new FormData();
		formData.append('nickname', values.nickname);
		if (values.profile_pic) {
			formData.append('profile_pic', values.profile_pic);
		}
		formData.append('profile_pic_url', values.profile_pic_url);
		formData.append('city_id', values.city_id);
		values.language_ids.forEach(item => {
			formData.append('language_ids[]', item);
		});
		if (values.desc) {
			formData.append('desc', values.desc);
		}

		this.props.dispatch(userActions.editProfile(formData));
	}

	render() {
		const { t, user, edit_profile } = this.props;
		const { tabIndex } = this.state;

		const schema = Yup.object().shape({
			nickname: Yup.string()
				.required(t('required')),
			city_id: Yup.number()
				.required(t('required')),
			language_ids: Yup.array()
				.required(t('required')),
			// desc: Yup.string()
			// 	.required(t('required')),
		});

		return (
			<Formik
				enableReinitialize
				initialValues={{
					profile_pic: '',
					nickname: user.nickname,
					profile_pic_url: user.profile_pic_url? user.profile_pic_url : '',
					city_id: user.city? user.city.id : '',
					language_ids: user.languages? user.languages.map((item) => { return item.id; }): '',
					desc: user.desc? user.desc : ''
				}}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					setFieldValue,
					setFieldTouched
					/* and other goodies */
				}) => (
						<div id="vendor_myprofile_vendor_profile">
							<Form>
								<Row>
									<Col md={{ size: true }}>
										<Title>{t('vendor.vendor_profile')}</Title>
									</Col>
									<Col md={{ size: 'auto' }} className="display-desktop">
										<Button
											block
											className={(tabIndex === VendorProfileTab.reviews)? 'd-none' : ''}
											type="submit"
											disabled={edit_profile.loading}
											onClick={handleSubmit}>
											{t('save')}
										</Button>
									</Col>
								</Row>
								<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
									<TabList>
										<Tab>{t('vendor.profile')}</Tab>
										<Tab>{t('vendor.review') + (user.review_count > 0 ? ` (${user.review_count})` : '')}</Tab>
									</TabList>
									<TabPanel>
										<Profile />
									</TabPanel>
									<TabPanel>
										<Reviews />
									</TabPanel>
								</Tabs>
							</Form>
						</div>
					)}
			</Formik>
		);
	}
}

function mapStateToProps(state) {
	const { auth, edit_profile } = state;
	const { user } = auth;
	return {
		user,
		edit_profile
	};
}

const translation = withNamespaces(['profile', 'default'])(VendorProfile);
const connected = connect(mapStateToProps)(translation);
export { connected as VendorProfile };