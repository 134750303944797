import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

import { ActionModal } from '../../../_components';

import './MakeOfferModal.scss';

import { InputForm } from './InputForm';
import { orderStatus } from '../../../_constants';

class MakeOfferModal extends Component {
	render() {
		const { t, data, isOpen, isLoading, isSuccess, error } = this.props;
		const isEdit = data && data.status !== orderStatus.requesting;
		const translationPrefix = isEdit? 'edit_offer' : 'make_offer';

		return (
			<ActionModal
				id="vendor_order_make_offer_modal"
				size="lg"
				confirmComponent={
					<InputForm
						title={t(`${translationPrefix}.confirm.title`)}
						desc={t(`${translationPrefix}.confirm.desc`)}
						data={data}
						isLoading={isLoading}
						onSubmit={() => this.props.onSubmit()}
						toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t(`${translationPrefix}.success.title`)}
				successDesc={t(`${translationPrefix}.success.desc`)}
				isLoading={isLoading}
				isSuccess={isSuccess}
				error={error}
				isOpen={isOpen}
				onClosed={() => {
					if (this.props.onClosed) {
						this.props.onClosed();
					}
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

const translation = withNamespaces(['order', 'package', 'default'])(MakeOfferModal);
export { translation as MakeOfferModal };