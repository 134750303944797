import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'reactstrap';

import {
	chatMessageListProgress as sb_chatMessageListProgress,
	getPrevMessageList as sb_getPrevMessageList,
	channelExit as sb_channelExit,
	sbCreatePreviousMessageListQuery as sb_createPreviousMessageListQuery,
	clearSelectedGroupChannel as sb_clearSelectedGroupChannel,
} from '../../../_sendbird';

import { Loading } from '../../../_components';

import './Body.scss';

import { Message } from './Message';

const initialState = {
	previousMessageListQuery: undefined,
	canLoad: true,
	oldScrollHeight: -1,
	oldScrollTop: -1,
	shouldScrollToMessage: true,
	showNewMessageButton: false
};

class Body extends Component {
	constructor(props) {
		super(props);

		this.state = initialState
	}

	componentDidUpdate(prevProps, prevState) {
		const { chat, channel } = this.props;

		if (channel && channel.url !== prevProps.channel.url) {
			this.setState(initialState);
		}

		if (!chat.isLoading && chat.isLoading !== prevProps.chat.isLoading) {
			this.goToPrevScroll();
		}

		//messageId = 0 means it is a temp message and prev and current should be with same content
		if (chat.list.length && prevProps.chat.list.length && prevProps.chat.list[0].messageId !== 0 && chat.list[0].messageId !== prevProps.chat.list[0].messageId) {
			if (this.messagesContainer) {
				//scroll to bottom on message sent/received if originally at the bottom
				const prevLastMessageDiv = document.getElementById(`msg_${prevProps.chat.list[0].messageId}`);
				const isBottom = this.messagesContainer.scrollTop === (this.messagesContainer.scrollHeight - this.messagesContainer.offsetHeight - prevLastMessageDiv.offsetHeight);

				if (isBottom) {
					this.setState({ oldScrollHeight: -1 }, this.goToPrevScroll());
				} else {
					this.setState({ showNewMessageButton: true });
				}
			}
		}
	}

	getMessageList(channel, init) {
		const { chat } = this.props;
		const { previousMessageListQuery } = this.state;

		if (chat.isLoading || (previousMessageListQuery && previousMessageListQuery.isLoading)) return;

		let oldScrollHeight = -1;
		let oldScrollTop = -1;

		if (this.messagesContainer) {
			oldScrollHeight = this.messagesContainer.scrollHeight;
			oldScrollTop = this.messagesContainer.scrollTop;
		}

		this.setState({ oldScrollHeight, oldScrollTop }, () => {

			this.props.dispatch(sb_chatMessageListProgress(true));

			if (init) {
				sb_createPreviousMessageListQuery(channel.url, channel.isOpenChannel())
					.then(query => {
						this.setState({ previousMessageListQuery: query }, () => {
							this.props.dispatch(sb_getPrevMessageList(query));
						});
					})
					.catch(error => {
						this.props.dispatch(sb_channelExit(channel.url, channel.isOpenChannel()));
						this.props.dispatch(sb_clearSelectedGroupChannel());
					});
			} else {
				this.props.dispatch(sb_getPrevMessageList(previousMessageListQuery));
			}

		})
	}

	goToPrevScroll() {
		if (!this.messagesContainer) return;

		const { oldScrollHeight, oldScrollTop } = this.state;
		
		let resultScrollTop = this.messagesContainer.scrollHeight;
		if (oldScrollHeight > 0) {
			resultScrollTop = this.messagesContainer.scrollHeight - oldScrollHeight + oldScrollTop;
		}

		this.messagesContainer.scrollTop = resultScrollTop;

		this.setState({ canLoad: true });
	}

	onNewMessage() {
		this.setState({ showNewMessageButton: false, oldScrollHeight: -1 }, this.goToPrevScroll());
	}

	render() {
		const { t, chat, channel } = this.props;
		const { canLoad, showNewMessageButton } = this.state;

		return (
			<div id="chat_area_body">
			{channel &&
			<>
				<div
					className="messages-container"
					ref={(el) => this.messagesContainer = el}
					onScroll={() => {
						const isBottom = this.messagesContainer.scrollTop === (this.messagesContainer.scrollHeight - this.messagesContainer.offsetHeight);

						if (showNewMessageButton && isBottom) {
							this.setState({ showNewMessageButton: false });
						}
					}}
				>
					{chat.isLoading && <Loading key="loader" />}
					<InfiniteScroll
						className="messages"
						initialLoad={canLoad}
						useWindow={false}
						isReverse={true}
						threshold={60}
						loadMore={(page) => {
							this.setState({ canLoad: false }, () => {
								this.getMessageList(channel, page === 1);
							})
						}}
						hasMore={canLoad}
						loader={null}
					>
						{
							chat.list.map((msg) => <Message key={msg.messageId} t={t} message={msg} readReceipt={channel.getReadReceipt(msg)}/>)
						}
					</InfiniteScroll>
				</div>
				{showNewMessageButton && <Button size="sm" className="btn-new-msg" onClick={() => this.onNewMessage()} >{t('show_new_message')}</Button>}
			</>
			}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const connected = connect(mapStateToProps)(Body);
export { connected as Body };