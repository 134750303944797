import React, { Component }  from 'react';
import { withNamespaces } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'reactstrap';

import { Loading } from '../Loading';

import './InfiniteScrollWrapper.scss';

class InfiniteScrollWrapper extends Component {
	componentWillMount() {
		if (this.props.elementRef) {
			this.props.elementRef(this);
		}
	}

	componentDidMount() {
		const { disableInitialLoad } = this.props;
		if (!disableInitialLoad) {
			this.props.onLoadMore(1);
		}
	}

	reload() {
		const { onLoadMore } = this.props;
		onLoadMore(1);
	}

	_onLoadMore() {
		const { loadMoreProp, disableInitialLoad } = this.props;

		if (!loadMoreProp) return;

		if (disableInitialLoad && loadMoreProp.nextPage === 1) {
			return;
		} 

		if (!loadMoreProp.loading && loadMoreProp.hasMore) {
			const { onLoadMore } = this.props;
			onLoadMore(loadMoreProp.nextPage);
		}
	}

	render() {
		const { t, disableInitialLoad, loadingDiv, errorDiv, endResultDiv, noResultDiv, loadMoreProp, className, noResultDesc } = this.props;

		const isLoading = loadMoreProp? loadMoreProp.loading : false;
		const error = loadMoreProp? loadMoreProp.error : { status_code: 999, message: t('no_result_available') };
		const hasMore = loadMoreProp? loadMoreProp.hasMore : false;
		const items = loadMoreProp? loadMoreProp.list : undefined;

		const isNoResult = error === undefined && (items? items.length === 0 : true);

		const loadingWrapper = <div key='loader' className="infinite-scroll-loader">{loadingDiv? loadingDiv : <Loading/>}</div>;
		// const endResultWrapper = <div key='footer'>{endResultDiv? endResultDiv : <div>End of Results</div>}</div>;
		const endResultWrapper = <div key='footer' className="infinite-scroll-footer">{endResultDiv? endResultDiv : null}</div>;
		const errorWrapper = <div key='error' className="infinite-scroll-error">{errorDiv? errorDiv : <Loading error={error} />}</div>;
		const noResultWrapper = <div key='noResult' className="infinite-scroll-no-result">{noResultDiv? noResultDiv : <div>{noResultDesc? noResultDesc : t('no_result_available')}</div>}</div>;

		return (
			<div
				className={`infinite-scroll ${isLoading? 'loading' : ''} ${error? 'error' : ''} ${(!isLoading && !error &&  hasMore)? 'has-more' : ''} ${className? className : ''}`}
				// initialLoad={!disableInitialLoad}
				// pageStart={1}
				// loader={isLoading? loadingWrapper : null}
				// hasMore={!error && hasMore && !isLoading}
				// loadMore={(page) => this._onLoadMore()}
			>
				{items &&
					<div className="items">
						{this.props.renderItems(items)}
					</div>
				}
				{isLoading && loadingWrapper}
				{error !== undefined && errorWrapper}
				

				{!isLoading && error === undefined && hasMore && items && loadMoreProp.total &&
					<div className="infinite-scroll-footer-load-more">
						<div className="viewed">
							{t('you_viewed_n_out_of_total', { n: items.length, total: loadMoreProp.total })}
						</div>
						<Button onClick={() => {
							this.props.onLoadMore(loadMoreProp.nextPage);
						}}>
							{t('load_more')}
						</Button>
					</div>
				}

				{!isLoading && error === undefined && !hasMore && (isNoResult? noResultWrapper : endResultWrapper)}
			</div>
		);
	}
}

const translation = withNamespaces(['default'])(InfiniteScrollWrapper);
export { translation as InfiniteScrollWrapper };