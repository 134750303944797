import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import { userType } from '../../_constants';
import { Desc, InputItem, InputItemLabel } from '../../_components';

import './AfterShootingMaterialsDetails.scss';

const regexURL = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

class AfterShootingMaterialsDetails extends Component {
	render() {
		const { auth, t, data } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		return (
			<div id="order_after_shooting_materials_details">
				<Card>
					<CardHeader className={`${isVendor? '' : 'no-border'}`}>
						<CardTitle tag="h4">
							{t(isVendor? 'after_shooting_materials.title' : 'get_your_materials.title')}
						</CardTitle>
						<Desc>
							{t(isVendor? 'after_shooting_materials.desc' : 'get_your_materials.desc')}
						</Desc>
					</CardHeader>
					<CardBody>
						{data.items.map((item, index) => {
							let desc = item.desc;
							let found = desc.match(regexURL);

							if (found) {
								for (var i = 0; i < found.length; i++) {
									let url = found[i];
									desc = desc.replace(url, `<a href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`)
								}
							}

							return (<InputItem key={index}>
								<InputItemLabel>
									{item.name}
								</InputItemLabel>
								<Desc dangerouslySetInnerHTML={{ __html: desc }} />
							</InputItem>);
						})}
					</CardBody>
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['aftershooting', 'default'])(AfterShootingMaterialsDetails);
const connected = connect(mapStateToProps)(translation);
export { connected as AfterShootingMaterialsDetails };