import { userConstants } from '../_constants';

const registerSendCodeInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

export function register_send_code(state = registerSendCodeInitialState, action) {
	switch (action.type) {
		case userConstants.SEND_CODE_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...registerSendCodeInitialState };
		case userConstants.SEND_CODE_REQUEST:
			return { ...registerSendCodeInitialState, loading: true };
		case userConstants.SEND_CODE_SUCCESS:
			return { ...registerSendCodeInitialState, success: true };
		case userConstants.SEND_CODE_FAILURE:
			return { ...registerSendCodeInitialState, error: action.error };

		default:
			return state
	}
}

const registerInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

export function register(state = registerInitialState, action) {
	switch (action.type) {
		case userConstants.REGISTER_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...registerInitialState };
		case userConstants.REGISTER_REQUEST:
			return { ...registerInitialState, loading: true };
		case userConstants.REGISTER_SUCCESS:
			return { ...registerInitialState, success: true };
		case userConstants.REGISTER_FAILURE:
			return { ...registerInitialState, error: action.error };

		default:
			return state
	}
}

const loginInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

export function login(state = loginInitialState, action) {
	switch (action.type) {
		case userConstants.LOGIN_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...loginInitialState };
		case userConstants.LOGIN_REQUEST:
			return { ...loginInitialState, loading: true };
		case userConstants.LOGIN_SUCCESS:
			return { ...loginInitialState, success: true };
		case userConstants.LOGIN_FAILURE:
			return { ...loginInitialState, error: action.error };

		default:
			return state
	}
}

const forgotPasswordSendCodeInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const forgotPasswordVerifyCodeInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const forgotPasswordInitialState = {
	loading: false,
	success: false,
	error: undefined,
	sendCode: forgotPasswordSendCodeInitialState,
	verifyCode: forgotPasswordVerifyCodeInitialState
}

export function forgot_password(state = forgotPasswordInitialState, action) {
	switch (action.type) {
		case userConstants.RESETPASSWORD_BY_CODE_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...forgotPasswordInitialState };
		case userConstants.RESETPASSWORD_BY_CODE_REQUEST:
			return { ...forgotPasswordInitialState, loading: true };
		case userConstants.RESETPASSWORD_BY_CODE_SUCCESS:
			return { ...forgotPasswordInitialState, success: true, sendCode: state.sendCode, verifyCode: state.verifyCode,  };
		case userConstants.RESETPASSWORD_BY_CODE_FAILURE:
			return { ...forgotPasswordInitialState, error: action.error, sendCode: state.sendCode, verifyCode: state.verifyCode };

		case userConstants.RESETPASSWORD_SEND_CODE_CLEAR:
			return { ...state, sendCode: forgotPasswordSendCodeInitialState };
		case userConstants.RESETPASSWORD_SEND_CODE_REQUEST:
			return { ...state, sendCode: { ...forgotPasswordSendCodeInitialState, loading: true } };
		case userConstants.RESETPASSWORD_SEND_CODE_SUCCESS:
			return { ...state, sendCode: { ...forgotPasswordSendCodeInitialState, success: true } };
		case userConstants.RESETPASSWORD_SEND_CODE_FAILURE:
			return { ...state, sendCode: { ...forgotPasswordSendCodeInitialState, error: action.error } };

		case userConstants.RESETPASSWORD_VERIFY_CODE_CLEAR:
			return { ...state, verifyCode: forgotPasswordVerifyCodeInitialState };
		case userConstants.RESETPASSWORD_VERIFY_CODE_REQUEST:
			return { ...state, verifyCode: { ...forgotPasswordVerifyCodeInitialState, loading: true } };
		case userConstants.RESETPASSWORD_VERIFY_CODE_SUCCESS:
			return { ...state, verifyCode: { ...forgotPasswordVerifyCodeInitialState, success: true } };
		case userConstants.RESETPASSWORD_VERIFY_CODE_FAILURE:
			return { ...state, verifyCode: { ...forgotPasswordVerifyCodeInitialState, error: action.error } };


		default:
			return state
	}
}

const editProfileInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

export function edit_profile(state = editProfileInitialState, action) {
	switch (action.type) {
		case userConstants.EDIT_PROFILE_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...editProfileInitialState };
		case userConstants.EDIT_PROFILE_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case userConstants.EDIT_PROFILE_SUCCESS:
			return { ...state, loading: false, success: true, error: undefined };
		case userConstants.EDIT_PROFILE_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		default:
			return state
	}
}

const editEmailSendCodeInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const editEmailInitialState = {
	loading: false,
	success: false,
	error: undefined,
	sendCode: editEmailSendCodeInitialState,
}

export function edit_email(state = editEmailInitialState, action) {
	switch (action.type) {
		case userConstants.EDIT_EMAIL_BY_CODE_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...editEmailInitialState };
		case userConstants.EDIT_EMAIL_BY_CODE_REQUEST:
			return { ...editEmailInitialState, loading: true };
		case userConstants.EDIT_EMAIL_BY_CODE_SUCCESS:
			return { ...editEmailInitialState, success: true, sendCode: state.sendCode };
		case userConstants.EDIT_EMAIL_BY_CODE_FAILURE:
			return { ...editEmailInitialState, error: action.error, sendCode: state.sendCode };

		case userConstants.EDIT_EMAIL_SEND_CODE_CLEAR:
			return { ...state, sendCode: editEmailSendCodeInitialState };
		case userConstants.EDIT_EMAIL_SEND_CODE_REQUEST:
			return { ...state, sendCode: { ...editEmailSendCodeInitialState, loading: true } };
		case userConstants.EDIT_EMAIL_SEND_CODE_SUCCESS:
			return { ...state, sendCode: { ...editEmailSendCodeInitialState, success: true } };
		case userConstants.EDIT_EMAIL_SEND_CODE_FAILURE:
			return { ...state, sendCode: { ...editEmailSendCodeInitialState, error: action.error } };

		default:
			return state
	}
}

const editPasswordInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

export function edit_password(state = editPasswordInitialState, action) {
	switch (action.type) {
		case userConstants.EDIT_PASSWORD_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...editPasswordInitialState };
		case userConstants.EDIT_PASSWORD_REQUEST:
			return { ...editPasswordInitialState, loading: true };
		case userConstants.EDIT_PASSWORD_SUCCESS:
			return { ...editPasswordInitialState, success: true };
		case userConstants.EDIT_PASSWORD_FAILURE:
			return { ...editPasswordInitialState, error: action.error };

		default:
			return state
	}
}

const getUserInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function get_user(state = getUserInitialState, action) {
	switch (action.type) {
		case userConstants.GET_USER_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...getUserInitialState };
		case userConstants.GET_USER_REQUEST:
			return { ...getUserInitialState, loading: true };
		case userConstants.GET_USER_SUCCESS:
			return { ...state, loading: false, success: true, error: undefined, data: action.responseData.user };
		case userConstants.GET_USER_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		default:
			return state
	}
}