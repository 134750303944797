
import React from "react";

import './InputItem.scss';

export const InputItem = ({ className, children, ...props}) => {
	return <div className={`input-item ${className? className : ''}`} {...props}>{children}</div>;
}

export const InputItemLabel = ({ className, children, ...props}) => {
	return <div className={`input-item-label ${className? className : ''}`} {...props}>{children}</div>;
}