import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import Moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';

import { history, app } from '../../../_helpers';
import { orderActions } from '../../../_actions';
import { getOrderStatusNameByStatus, orderStatus, orderType, getOrderStatusNameByData } from '../../../_constants';
import { ResponsiveTable, InfiniteScrollWrapper, Title, ProfilePicImg } from '../../../_components';

import './Orders.scss';

const statusesOptions = {
	'deal': [
		{ id: null, value: 'all_statuses' },
		{ id: orderStatus.requesting, value: getOrderStatusNameByStatus(orderStatus.requesting) },
		{ id: orderStatus.offered, value: getOrderStatusNameByStatus(orderStatus.offered) },
		{ id: orderStatus.deal_cancelled, value: getOrderStatusNameByStatus(orderStatus.deal_cancelled) },
		{ id: orderStatus.expired, value: 'expired' },
	],
	'order': [
		{ id: null, value: 'all_statuses' },
		{ id: orderStatus.full_paid, value: getOrderStatusNameByStatus(orderStatus.full_paid) },
		{ id: orderStatus.shooting, value: getOrderStatusNameByStatus(orderStatus.shooting) },
		{ id: orderStatus.shooted, value: getOrderStatusNameByStatus(orderStatus.shooted) },
		{ id: orderStatus.completed, value: getOrderStatusNameByStatus(orderStatus.completed) },
		{ id: orderStatus.order_cancelled, value: getOrderStatusNameByStatus(orderStatus.order_cancelled) },
		{ id: orderStatus.refunding, value: getOrderStatusNameByStatus(orderStatus.refunding) },
		{ id: orderStatus.refunded, value: getOrderStatusNameByStatus(orderStatus.refunded) },
		{ id: orderStatus.refund_fail, value: getOrderStatusNameByStatus(orderStatus.refund_fail) },
		{ id: orderStatus.expired, value: 'expired' },
	]
};

class Orders extends Component {
	constructor(props) {
		super(props);

		const { type } = props;
		this.state = {
			status: statusesOptions[type][0]
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.getListClear());
	}

	onSelectStatus(status) {
		if (this.state.status.id === status.id) return;
		this.setState({ status }, () => {
			this.infiniteScrollComponent.reload();
		});
	}

	onRowClick(data, index) {
		history.push(`/vendor/me/order/${data.id}`);
	}

	render() {
		const { t, type, orders } = this.props;
		const { status } = this.state;

		return (
			<div id="vendor_myprofile_orders">
				<Row className="header">
					<Col sm={{ size: true }}>
						<Title>
							{t(type === orderType.deal? 'vendor.deal_list' : 'vendor.order_list')}
						</Title>
					</Col>
					{(status.id || (orders.list && orders.list.length > 0)) &&
						<Col sm={{ size: 'auto' }}>
							<UncontrolledButtonDropdown>
								<DropdownToggle caret className="btn-block btn-outline-primary">
									{t(`status.${status.value}`)}
								</DropdownToggle>
								<DropdownMenu right>
									{statusesOptions[type].map((item, index) =>
										<DropdownItem key={item.id} onClick={() => this.onSelectStatus(item)}>{t(`status.${item.value}`)}</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</Col>
					}
				</Row>
				<InfiniteScrollWrapper
					elementRef={(element) => this.infiniteScrollComponent = element}
					pageStart={1}
					loadMoreProp={orders}
					onLoadMore={(nextPage) => {
						const { status } = this.state;
						
						let statusId = status.id;
						let isExpired = undefined;

						if (statusId) {
							if (statusId === 999) {
								isExpired = '1';
								statusId = undefined;
							} else {
								isExpired = '0'
							}
						} else {
							//all
						}

						this.props.dispatch(orderActions.getList(true, type, statusId, isExpired, nextPage));
					}}
					renderItems={(items) => {
						return (
							<ResponsiveTable
								t={t}
								columns={[
									{ data_key: 'customer', key: 'customer', grid_size: '3' },
									{ data_key: null, key: 'deal', grid_size: '4' },
									{ data_key: 'shoot_datetime', key: 'shoot_datetime', grid_size: '3' },
									{ data_key: 'status', key: 'status', name: t('status.status'), grid_size: '2' },
								]}
								data={items}
								renderCellValue={(column, cellData, rowData) => {
									switch (column.key) {
										case 'customer':
											return (
												<>
													<ProfilePicImg src={cellData.profile_pic_url} alt={cellData.nickname} />
													<div className="name">{cellData.nickname}</div>
												</>
											);
										case 'deal':
											return (
												<>
													<LinesEllipsis
														className="package-name"
														text={cellData.package_name}
														maxLine='2'
														ellipsis='...'
														trimRight
														basedOn='words'
													/>
													<div className="order-price">
														{app.toCurrencyString(cellData.currency.value, cellData.vendor_total_amount)}
													</div>
												</>
											);
										case 'shoot_datetime':
											return rowData.is_shoot_datetime_updated?
												`${Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).format('D MMM YYYY h:mma')} ${rowData.timezone.offset}`
												:
												`${Moment.utc(rowData.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).format('D MMM YYYY')} ${rowData.timezone.offset}`
										case 'status':
											let statusName = getOrderStatusNameByData(rowData);
											let remarks = undefined;

											if (!rowData.is_expired &&
												rowData.status !== orderStatus.deal_cancelled &&
												rowData.status !== orderStatus.order_cancelled &&
												rowData.status !== orderStatus.completed &&
												rowData.status !== orderStatus.refunding &&
												rowData.status !== orderStatus.refunded &&
												rowData.status !== orderStatus.refund_fail
												) {
												if (rowData.expiry_datetime && Moment.utc(rowData.expiry_datetime.date).isAfter(Moment())) {
													const days = Moment.utc(rowData.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).diff(Moment(), 'days');
													const hours = Moment.utc(rowData.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).diff(Moment(), 'hours');
													const minutes = Moment.utc(rowData.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).diff(Moment(), 'minutes');
													const seconds = Moment.utc(rowData.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(rowData.timezone.offset).diff(Moment(), 'seconds');

													if (days > 0) {
														remarks = t('expires_in_n_days', { n: days });
													} else if (hours > 0) {
														remarks = t('expires_in_n_hours', { n: hours });
													} else if (minutes > 0) {
														remarks = t('expires_in_n_minutes', { n: minutes });
													} else {
														remarks = t('expires_in_n_seconds', { n: seconds });
													}
												}
											}
											
											return (
												<>
													<div className={`name ${statusName}`}>{t(`status.${statusName}`)}</div>
													{remarks && <div className={`remarks ${statusName}`}>{remarks}</div>}
												</>
											);
										default:
											return null; //return null to use default rendering
									}
								}}
								onRowClick={(data, index) => this.onRowClick(data, index)}
							/>
						)
					}}
					noResultDesc={t(type === orderType.deal? 'no_items_in_deal_list' : 'no_items_in_order_list')}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { orders } = state;
	return {
		orders
	};
}

const translation = withNamespaces(['order', 'profile', 'default'])(Orders);
const connected = connect(mapStateToProps)(translation);
export { connected as Orders };