import React, { Component } from 'react';
import { Card, CardImg, CardColumns } from 'reactstrap';

import { LightBox } from '../../../../../_components';

import './Location.scss';

export class Location extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lightboxIsOpen: false,
			lightboxIndex: 0
		}
	}

	onClickImage(index) {
		this.setState({ lightboxIsOpen: true, lightboxIndex: index });
	}

	render() {
		const { items } = this.props;
		const { lightboxIsOpen, lightboxIndex } = this.state;

		return (
			<div className="package_details_locations_location">
				<CardColumns>
					{items.map((item, index) =>
						<Card key={index} onClick={() => this.onClickImage(index)}>
							<CardImg src={item.photo_url} alt={item.desc} />
						</Card>
					)}
				</CardColumns>
				<LightBox
					items={items}
					index={lightboxIndex}
					srcKey="photo_url"
					captionKey="desc"
					isOpen={lightboxIsOpen}
					toggle={() => this.setState({ lightboxIsOpen: !this.state.lightboxIsOpen })}
				/>
			</div>
		);
	}
}