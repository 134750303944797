import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import { vendorProfileActions } from '../../../_actions';

import { ResultsGrid } from '../../../_components';
import { GridItem } from '../../../Package';

import './Packages.scss';

class Packages extends Component {
	componentDidMount() {
		this.props.dispatch(vendorProfileActions.getPackagesClear());
	}

	render() {
		const { t, vendor_profile, vendor_id } = this.props;

		return (
			<div id="vendor_profile_packages">
				<ResultsGrid
					infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
					showHeader={vendor_profile.packages.list? vendor_profile.packages.list.length > 0 : false}
					noOfCol_lg={4}
					noOfCol_md={3}
					noOfCol_sm={2}
					loadMoreProp={vendor_profile.packages}
					onLoadMore={(nextPage) => {
						this.props.dispatch(vendorProfileActions.getPackages(vendor_id, nextPage));
					}}
					renderItem={(item) => {
						return <GridItem item={item} />;
					}}
					noResultDesc={t('no_packages')}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
    const { vendor_profile } = state; 
    return {
		vendor_profile
    };
}

const translation = withNamespaces(['package'])(Packages);
const connected = connect(mapStateToProps)(translation);
export { connected as Packages };