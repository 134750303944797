import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { orderActions } from '../../_actions';
import { userType } from '../../_constants';
import { InfiniteScrollWrapper, Title, Desc, BaseModal } from '../../_components';

import './PendingItems.scss';

import { PendingItem } from './PendingItem';

class PendingItems extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			replyError: undefined
		}
	}
	
	componentDidMount() {
		this.props.dispatch(orderActions.getPendingItemsClear());
	}

	componentDidUpdate(prevProps, prevState) {
		const { order } = this.props;

		let shouldRefreshList = false;
		if (order.edit_datetime.success && order.edit_datetime.success !== prevProps.order.edit_datetime.success) {
			shouldRefreshList = true;
		}
		if (prevProps.order.pending_item.reply.success && !order.pending_item.reply.success && prevProps.order.pending_item.reply.success) {
			shouldRefreshList = true;
		}
		if (prevProps.order.pending_item.cancel.success && !order.pending_item.cancel.success && prevProps.order.pending_item.cancel.success) {
			shouldRefreshList = true;
		}

		if (order.pending_item.reply.error && order.pending_item.reply.error !== prevProps.order.pending_item.reply.error) {
			shouldRefreshList = true;
			this.setState({ replyError: order.pending_item.reply.error });
		}

		if (shouldRefreshList) {
			this.infiniteScrollComponent.reload();
		}

	}
	
	render() {
		const { t, auth, order, data } = this.props;
		const { replyError } = this.state;

		const isVendor = auth.user.user_type !== userType.customer;

		return (
			<div id="order_pendingItems">
				<InfiniteScrollWrapper
					elementRef={(element) => this.infiniteScrollComponent = element}
					pageStart={1}
					loadMoreProp={order.pending_items}
					onLoadMore={(nextPage) => {
						this.props.dispatch(orderActions.getPendingItems(isVendor, data.id, nextPage));
					}}
					renderItems={(items) => {
						return (
							items.map((item, index) =>
								<PendingItem key={item.id} data={item} order={data} />
							)
						)
					}}
					noResultDesc={t('no_pending_items')}
				/>
				{replyError &&
					<BaseModal
						id="order_pending_item_error_expired_modal"
						isOpen={replyError !== undefined}
						toggle={() => this.setState({ replyError: undefined })}
						onClosed={() => {
							//error may due to expired pending item by datetime rule 4001
							//or order already cancelled 400
							//so instead of just reloading the list, reload also the order maybe better
							this.props.dispatch(orderActions.get(isVendor, order.id));
							this.props.dispatch(orderActions.getPendingItemsCount(isVendor, order.id));
							this.infiniteScrollComponent.reload();
						}}
					>
						<ModalHeader>
							<Title>{`${t('error')} ${replyError.status_code}`}</Title>
						</ModalHeader>
						<ModalBody>
							<Desc>{replyError.message}</Desc>
						</ModalBody>
						<ModalFooter>
							<Button outline onClick={() => this.setState({ replyError: undefined })}>{t('ok')}</Button>
						</ModalFooter>
					</BaseModal>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
	return {
		auth,
		order
	};
}

const translation = withNamespaces(['pendingitem', 'error'])(PendingItems);
const connected = connect(mapStateToProps)(translation);
export { connected as PendingItems };