import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Title, Desc } from '../../../_components';

import './Success.scss';

export const RegisterSuccessUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class Success extends Component {
	render() {
		const { t, userType } = this.props;

		return (
			<div id="user_register_success">
				<Title>{t('success.title')}</Title>
				<Desc>{userType === RegisterSuccessUserType.vendor? t('success.desc.vendor') : t('success.desc.customer')}</Desc>
				<Link to={userType === RegisterSuccessUserType.vendor? '/vendor/me/vendor_profile' : '/'} className="btn btn-outline-primary">{userType === RegisterSuccessUserType.vendor? t('add_your_profile') : t('start_exploring')}</Link>
			</div>
		);
	}
}

const translation = withNamespaces(['register'])(Success);
export { translation as Success };