import { 
    INIT_MEMBER,
    MEMBER_LIST_SUCCESS,
	MEMBER_LIST_FAIL,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    list: []
}

export function member (state = INITIAL_STATE, action) {
    switch(action.type) {
        case INIT_MEMBER:
            return { ...state, ...INITIAL_STATE };
        case MEMBER_LIST_SUCCESS: 
            return { ...state, list: action.list };
        case MEMBER_LIST_FAIL:
			return { ...state, list: [] };
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
			return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}
