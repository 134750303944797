import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { savedItemsActions } from '../../../_actions';
import { Title } from '../../../_components';

import './SavedItems.scss';

import { Packages } from './Packages';
import { Vendors } from './Vendors';
import { Articles } from './Articles';

const SavedItemsTabType = {
	package: 1,
	vendor: 2,
	article: 3
}

const SavedItemsTabPathname = ['packages', 'vendor', 'articles'];

class SavedItems extends Component {
	constructor(props) {
		super(props);
		
		const { match } = props;
		let tabIndex = this.tabIndexByMatch(match);
		this.state = { 
			tabIndex: tabIndex,
			keyPackages: Math.random()
		};
	}

	componentDidMount() {
		this.props.dispatch(savedItemsActions.getSavedItemsCounts());
	}

	componentDidUpdate(prevProps, prevState) {
		const { location, saved_item_action, app } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		if (saved_item_action.success && saved_item_action.success !== prevProps.saved_item_action.success) {
			this.props.dispatch(savedItemsActions.getSavedItemsCounts());
		}

		if (!prevProps.app.currency.currency || app.currency.currency.id !== prevProps.app.currency.currency.id) {
			this.setState({ keyPackages: Math.random() });
		}
	}

	onRouteChanged(props) {
		const { match } = props;

		let tabIndex = this.tabIndexByMatch(match);
		this.setState({ tabIndex: tabIndex });
	}

	tabIndexByMatch(match) {
		let tabIndex = SavedItemsTabPathname.indexOf(match.params.tab);
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		return `${match.url.replace(`/${match.params.tab }`, '')}/${SavedItemsTabPathname[tabIndex]}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	tabTitleKeyByTabType(tabType) {
		switch (tabType) {
			case SavedItemsTabType.package:
				return 'package';
			case SavedItemsTabType.vendor:
				return 'vendor';
			case SavedItemsTabType.article:
				return 'article';
			default:
				break;
		}
	}

	render() {
		const { t, saved_items } = this.props;
		const { tabIndex, keyPackages } = this.state;
		let tabTypes = [SavedItemsTabType.package, SavedItemsTabType.vendor, SavedItemsTabType.article];
		let tabTabTitles = [];

		tabTypes.forEach(type => {
			let count = undefined;
			if (saved_items.counts) {
				let item = saved_items.counts.find(item => {
					return type === item.type;
				});

				count = item? item.count : 0;
			}
			tabTabTitles.push({ title: t(this.tabTitleKeyByTabType(type)), count: count });
		});

		return (
			<div id="customer_profile_saved_items">
				<Title>{t('customer.saved_items')}</Title>
				<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
					<TabList>
					{ tabTabTitles.map((item, index) => 
						<Tab key={index}>{ item.title + (item.count? (` (${item.count})`) : '')}</Tab>
					)}
					</TabList>
					<TabPanel>
						<Packages key={keyPackages} />
					</TabPanel>
					<TabPanel>
						<Vendors />
					</TabPanel>
					<TabPanel>
						<Articles />
					</TabPanel>
				</Tabs>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { saved_items, saved_item_action, app } = state;
	return {
		saved_items,
		saved_item_action,
		app
	};
}

const translation = withNamespaces(['saveditems', 'profile'])(SavedItems);
const connected = connect(mapStateToProps)(translation);
export { connected as SavedItems };