import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
	updateUnreadCount as sb_updateUnreadCount
} from '../../_sendbird';

import './BtnInbox.scss';

import { SVGImg } from '../SVGImg';

class BtnInbox extends Component {
	constructor(props) {
		super(props);

	}

	componentDidMount() {
		this.props.dispatch(sb_updateUnreadCount());
	}

	render() {
		const { t, isVendor, className, sb_login, sb_connection, sb_unreadCount } = this.props;

		return (
			(sb_login.success && !sb_connection.isLoading && !sb_connection.error) ?
				<Link to={isVendor ? "/vendor/chat" : "/chat"} className={`btn btn-inbox ${className ? className : ''}`}>
					<div className="btn-content">
						<SVGImg img="inbox" />
						<span className="btn-title">{t('inbox')}</span>
						{sb_unreadCount.count > 0 && <Badge pill>{`${sb_unreadCount.count > 999 ? '999+' : sb_unreadCount.count}`}</Badge>}
					</div>
				</Link>
				:
				null
		);
	}
}

function mapStateToProps(state) {
	const { sb_login, sb_connection, sb_unreadCount } = state;
	return {
		sb_login,
		sb_connection,
		sb_unreadCount
	};
}

const translation = withNamespaces(['chat'])(BtnInbox);
const connected = connect(mapStateToProps)(translation);
export { connected as BtnInbox };