import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Title, Desc, BaseModal } from '../../../_components';
import './SuccessModal.scss';

class SuccessModal extends Component {
	render() {
		const { t } = this.props;

		return (
			<BaseModal
				id="vendor_createpackage_preview_success"
				isOpen={this.props.isOpen}
				toggle={() => {

				}}
			>
				<ModalHeader>
					<Title>
						{t('publish_package_draft.success.title')}
					</Title>
				</ModalHeader>
				<ModalBody>
					<Desc>
						{t('publish_package_draft.success.desc')}
					</Desc>
				</ModalBody>
				<ModalFooter>
					<Container className="container-p-8">
						<Row className="row-m-8">
							<Col md={{ size: true }}>
								<Link className="btn btn-primary btn-block" to='/vendor/me/package'>{t('view_my_packages')}</Link>
							</Col>
							<Col md={{ size: true }}>
								<Link className="btn btn-outline-primary btn-block" to='/vendor/me/calendar'>{t('edit_my_calendar')}</Link>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['package'])(SuccessModal);
const connected = connect()(translation);
export { connected as SuccessModal };