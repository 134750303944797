import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Swiper from 'react-id-swiper';
import { Navigation, Autoplay, EffectCoverflow, Mousewheel } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/scss/swiper.scss";
import ScrollAnimation from 'react-animate-on-scroll';

import { headerActions } from '../../_actions';
import { Title, Desc, PageContainer, VideoPlayer } from '../../_components';

import './Home.scss';

import img_logo from './img/logo_with_text.svg';
import img_map from './img/image_world_map.jpg';
import img_add from './img/icon_above_the_header.svg';

import img_protection_1 from './img/icon_protection_1.svg';
import img_protection_2 from './img/icon_protection_2.svg';
import img_protection_3 from './img/icon_protection_3.svg';
import img_protection_4 from './img/icon_protection_4.svg';
import img_protection_5 from './img/icon_protection_5.svg';
import img_protection_6 from './img/icon_protection_6.svg';
import img_standardized_package_1 from './img/icon_standarddized_packages_1.svg';
import img_standardized_package_2 from './img/icon_standarddized_packages_2.svg';
import img_standardized_package_3 from './img/icon_standarddized_packages_3.svg';
import img_standardized_package_4 from './img/icon_standarddized_packages_4.svg';
import img_standardized_package_5 from './img/icon_standarddized_packages_5.svg';
import img_standardized_package_6 from './img/icon_standarddized_packages_6.svg';
import img_pin_couples from './img/pin_couples.svg';
import img_pin_vendor from './img/pin_photographer.svg';

import img_destination_1_en from './img/screen_package_listing_european.jpg';
import img_destination_2_en from './img/screen_package_listing_korean.jpg';
import img_destination_3_en from './img/screen_package_listing_japan.jpg';
import img_package_details_locations_en from './img/screen_package_locations.jpg';
import img_my_packages_1_en from './img/screen_my_package_1.jpg';
import img_my_packages_2_en from './img/screen_my_package_2.jpg';
import img_my_packages_3_en from './img/screen_my_package_3.jpg';
import img_my_calendar_en from './img/showcase_my_calendar.png';
import img_order_list_en from './img/screen_order_list.jpg';

import img_destination_1_tc from './img/screen_package_listing_european_tc.jpg';
import img_destination_2_tc from './img/screen_package_listing_korean_tc.jpg';
import img_destination_3_tc from './img/screen_package_listing_japan_tc.jpg';
import img_package_details_locations_tc from './img/screen_package_locations_tc.jpg';
import img_my_packages_1_tc from './img/screen_my_package_tc_1.jpg';
import img_my_packages_2_tc from './img/screen_my_package_tc_2.jpg';
import img_my_packages_3_tc from './img/screen_my_package_tc_3.jpg';
import img_my_calendar_tc from './img/showcase_my_calendar_tc.png';
import img_order_list_tc from './img/screen_order_list_tc.jpg';

import img_destination_1_sc from './img/screen_package_listing_european_sc.jpg';
import img_destination_2_sc from './img/screen_package_listing_korean_sc.jpg';
import img_destination_3_sc from './img/screen_package_listing_japan_sc.jpg';
import img_package_details_locations_sc from './img/screen_package_locations_sc.jpg';
import img_my_packages_1_sc from './img/screen_my_package_sc_1.jpg';
import img_my_packages_2_sc from './img/screen_my_package_sc_2.jpg';
import img_my_packages_3_sc from './img/screen_my_package_sc_3.jpg';
import img_my_calendar_sc from './img/showcase_my_calendar_sc.png';
import img_order_list_sc from './img/screen_order_list_sc.jpg';

import video_home_en from './img/home_en.mp4';
import video_package_details_en from './img/package_details_en.mp4';

import video_home_tc from './img/home_tc.mp4';
import video_package_details_tc from './img/package_details_tc.mp4';

import video_home_sc from './img/home_sc.mp4';
import video_package_details_sc from './img/package_details_sc.mp4';

class Home extends Component {
	componentDidMount() {
		this.props.dispatch(headerActions.solidStyle(false));
		this.props.dispatch(headerActions.darkStyle(true));
	}

	componentWillUnmount() {
		this.props.dispatch(headerActions.solidStyle(true));
		this.props.dispatch(headerActions.darkStyle(false));
	}

	render() {
		const { t, app } = this.props;
		const windowWidth = app.windowSize.width;

		const swiperParams = {
			modules: [Navigation, Autoplay, EffectCoverflow, Mousewheel],

			loop: true,

			autoplay: {
				delay: 2000,
				disableOnInteraction: false
			},

			effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			initialSlide: windowWidth > 760? 2 : 1,
			slidesPerView: windowWidth > 760? 2 : 1,
			coverflowEffect: {
				rotate: 0,
				stretch: windowWidth > 760? 100 : 10,
				depth: windowWidth > 760? 250 : 100,
				modifier: 1,
				slideShadows: true
			},
			getSwiper: (el) => {
				this.setState({ swiper: el });
			},
			mousewheel: true,
		};

		let mapPins = [
			{ top: '25%', left: '54%', img: img_pin_vendor },
			{ top: '27%', left: '51%', img: img_pin_couples },
			{ top: '15%', left: '77%', img: img_pin_couples },
			{ top: '9%', left: '82%', img: img_pin_vendor },
			{ top: '30%', left: '86%', img: img_pin_couples },
			{ top: '28%', left: '81%', img: img_pin_couples },
			{ top: '36%', left: '83%', img: img_pin_vendor },
			{ top: '39%', left: '78%', img: img_pin_couples },
			{ top: '42%', left: '74%', img: img_pin_vendor },
			{ top: '70%', left: '85%', img: img_pin_vendor },
		];

		let packageFeatures = [
			{ img: img_standardized_package_1, desc: t('vendor.standardized_package.cover_video') },
			{ img: img_standardized_package_2, desc: t('vendor.standardized_package.no_of_locations') },
			{ img: img_standardized_package_3, desc: t('vendor.standardized_package.portfolio_gallery') },
			{ img: img_standardized_package_4, desc: t('vendor.standardized_package.category') },
			{ img: img_standardized_package_5, desc: t('vendor.standardized_package.indoor_outdoor') },
			{ img: img_standardized_package_6, desc: t('vendor.standardized_package.shooting_hrs') },
		];

		let protections = [
			{ img: img_protection_1, desc: t('vendor.protection.deposit_protection') },
			{ img: img_protection_2, desc: t('vendor.protection.payout') },
			{ img: img_protection_3, desc: t('vendor.protection.currencies') },
			{ img: img_protection_4, desc: t('vendor.protection.multiplatform') },
			{ img: img_protection_5, desc: t('vendor.protection.refund') },
			{ img: img_protection_6, desc: t('vendor.protection.promote') },
		];

		var img_destination_1 = img_destination_1_en;
		var img_destination_2 = img_destination_2_en;
		var img_destination_3 = img_destination_3_en;
		var img_package_details_locations = img_package_details_locations_en;
		var img_my_packages_1 = img_my_packages_1_en;
		var img_my_packages_2 = img_my_packages_2_en;
		var img_my_packages_3 = img_my_packages_3_en;
		var img_my_calendar = img_my_calendar_en;
		var img_order_list = img_order_list_en;
		var video_home = video_home_en;
		var video_package_details = video_package_details_en;

		switch (app.language.language.id) {
			case 'hk':
				img_destination_1 = img_destination_1_tc;
				img_destination_2 = img_destination_2_tc;
				img_destination_3 = img_destination_3_tc;
				img_package_details_locations = img_package_details_locations_tc;
				img_my_packages_1 = img_my_packages_1_tc;
				img_my_packages_2 = img_my_packages_2_tc;
				img_my_packages_3 = img_my_packages_3_tc;
				img_my_calendar = img_my_calendar_tc;
				img_order_list = img_order_list_tc;
				video_home = video_home_tc;
				video_package_details = video_package_details_tc;
				break;
			case 'cn':
				img_destination_1 = img_destination_1_sc;
				img_destination_2 = img_destination_2_sc;
				img_destination_3 = img_destination_3_sc;
				img_package_details_locations = img_package_details_locations_sc;
				img_my_packages_1 = img_my_packages_1_sc;
				img_my_packages_2 = img_my_packages_2_sc;
				img_my_packages_3 = img_my_packages_3_sc;
				img_my_calendar = img_my_calendar_sc;
				img_order_list = img_order_list_sc;
				video_home = video_home_sc;
				video_package_details = video_package_details_sc;
				break;
		
			default:
				break;
		}

		let my_packages = [
			{ img: img_my_packages_1 },
			{ img: img_my_packages_2 },
			{ img: img_my_packages_3 },
		];

		return (
			<>
				<Helmet>
					<meta property="og:title" content={`${t('og_title')} - Vendor`} />
					<meta property="twitter:title" content={`${t('og_title')} - Vendor`} />
				</Helmet>
				<PageContainer fullPage id="vendor_home">
					<div className="bg-section">
						<div className="section section-map">
							<Container className="container-lg">
								<Title size="lg" className="text-pink">{`WEDDING UNO Connecting you with engaged couples worldwide.`}</Title>
								<Desc>{t('vendor.create_package.desc')}</Desc>
								<div className="img-section-wrapper">
									<img src={img_map} alt="" className="img-section" />
									<ScrollAnimation
										className="pins"
										animateIn="pins-in"
										offset={0}
										delay={0}
										duration={0.4}
									>
										{
											mapPins.map((el, index) =>
												<img className="pin" key={index} src={el.img} alt="" style={{ top: el.top, left: el.left }} />
											)
										}
									</ScrollAnimation>
								</div>
							</Container>
						</div>
						<div className="section">
							<Container className="container-md">
								<Title size="lg" className="text-yellow">{`WEDDING UNO Makes the Process Simpler`}</Title>
							</Container>
							<Container className="">
								<div className="video-comp">
									<VideoPlayer
										hideControls
										videoUrl={video_home}
									/>
								</div>
							</Container>
						</div>
						<div className="section">
							<Container className="container-lg">
								<Container className="container-md">
									<Title size="lg" className="text-yellow">{`WEDDING UNO Makes the Process Simpler`}</Title>
								</Container>
								<div className="swiper">
									<Swiper {...swiperParams}>
										<div className="slider-obj">
											<img src={img_destination_1} alt="" className="en" />
										</div>
										<div className="slider-obj">
											<img src={img_destination_2} alt="" className="en" />
										</div>
										<div className="slider-obj">
											<img src={img_destination_3} alt="" className="en" />
										</div>
									</Swiper>
									<Button color="img" className="btn-swiper btn-swiper-prev" onClick={() => {
										const { swiper } = this.state;
										if (swiper) {
											swiper.slidePrev();
										}
									}}></Button>
									<Button color="img" className="btn-swiper btn-swiper-next" onClick={() => {
										const { swiper } = this.state;
										if (swiper) {
											swiper.slideNext();
										}
									}}></Button>
								</div>
							</Container>
						</div>
					</div>
					<div className="bg-section">
						<div className="section">
							<Container className="container container-p-60">
								<Row className="row-m-60">
									<Col lg={{ size: true }}>
										<Title size="sm" className="text-yellow">{t('vendor.standardized_package.title_1')}</Title>
										<Title size="xl">{t('vendor.standardized_package.title_2')}</Title>
									</Col>
									<Col lg={{ size: 'auto' }}>
										<ScrollAnimation
											
											className="row row-m-20 features"
											animateIn="features-in"
											offset={150}
											delay={0}
											duration={0.4}
											>
										{
											packageFeatures.map((el, index) =>
												<Col key={index} xs={{ size: 4 }} className="feature">
													<img src={el.img} alt="" />
													<Desc>{el.desc}</Desc>
												</Col>
											)
										}
										</ScrollAnimation>
										<div className="lists">
											<div className="list">
												<Title size="xs">{t('vendor.standardized_package.service_included.title')}</Title>
												<ul className="list-unstyled">
													<li>{t('vendor.standardized_package.service_included.makeup')}</li>
													<li>{t('vendor.standardized_package.service_included.hairstyling')}</li>
													<li>{t('vendor.standardized_package.service_included.clothes')}</li>
													<li>{t('vendor.standardized_package.service_included.accessory')}</li>
													<li>{t('vendor.standardized_package.service_included.transport')}</li>
													<li>{t('vendor.standardized_package.service_included.translator')}</li>
													<li>{t('vendor.standardized_package.service_included.wedding_assistant')}</li>
													<li>{t('vendor.standardized_package.service_included.other')}</li>
												</ul>
											</div>
											<div className="list">
												<Title size="xs">{t('vendor.standardized_package.photo_video_included.title')}</Title>
												<ul className="list-unstyled">
													<li>{t('vendor.standardized_package.photo_video_included.edited_photo')}</li>
													<li>{t('vendor.standardized_package.photo_video_included.original_photo')}</li>
													<li>{t('vendor.standardized_package.photo_video_included.video_highlight')}</li>
													<li>{t('vendor.standardized_package.photo_video_included.photo_book')}</li>
													<li>{t('vendor.standardized_package.photo_video_included.free_shipping')}</li>
													<li>{t('vendor.standardized_package.photo_video_included.other')}</li>
												</ul>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="section">
							<Container className="container-md">
								<img src={img_add} alt="" className="img-add" />
								<Title className="text-yellow">{t('vendor.package_cover_video.title')}</Title>
								<Desc>{t('vendor.package_cover_video.desc')}</Desc>
							</Container>
							<Container className="">
								<div className="video-comp">
									<VideoPlayer
										hideControls
										videoUrl={video_package_details}
									/>
								</div>
							</Container>
						</div>
						<div className="section">
							<Container className="container-p-60">
								<Row className="row-m-60">
									<Col>
										<img src={img_add} alt="" className="img-add" />
									</Col>
								</Row>
								<Row className="row-m-60">
									<Col lg={{ size: 4 }}>
										<Title className="text-yellow">{t('vendor.preset_shooting_locations.title')}</Title>
										<Desc>{t('vendor.preset_shooting_locations.desc')}</Desc>
									</Col>
									<Col lg={{ size: 8 }}>
										<img src={img_package_details_locations} alt="" className="img-section img-rounded-corners en" />
									</Col>
								</Row>
							</Container>
						</div>
					</div>
					<div className="bg-section">
						<div className="section">
							<Container className="container-p-60">
								<Row className="row-m-60">
									<Col lg={{ size: 5 }}>
										<Title size="xl">{t('vendor.manage_packages.title')}</Title>
										<ul className="text-yellow">
											<li>{t('vendor.manage_packages.list.point_1')}</li>
											<li>{t('vendor.manage_packages.list.point_2')}</li>
											<li>{t('vendor.manage_packages.list.point_3')}</li>
										</ul>
									</Col>
									<Col lg={{ size: 7 }}>
										<Title size="sm">{t('vendor.manage_packages.my_packages')}</Title>
										<ScrollAnimation
											className="my-packages"
											animateIn="my-packages-in"
											offset={150}
											delay={0}
											duration={0.4}
										>
										{
											my_packages.map((el, index) =>
												<img key={index} className="img-rounded-corners" src={el.img} alt="" />
												)
										}
										</ScrollAnimation>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="section">
							<Container className="container-md">
								<img src={img_add} alt="" className="img-add" />
								<Title className="text-yellow">{t('vendor.organising_schedule.title')}</Title>
								<Desc>{t('vendor.organising_schedule.desc')}</Desc>
							</Container>
							<Container className="">
								<img src={img_my_calendar} alt="" className="img-section en" />
							</Container>
						</div>
						<div className="section">
							<Container className="container-p-60">
								<Row className="row-m-60">
									<Col>
										<img src={img_add} alt="" className="img-add" />
									</Col>
								</Row>
								<Row className="row-m-60">
									<Col lg={{ size: 4 }}>
										<Title className="text-yellow">{t('vendor.manage_orders.title')}</Title>
										<Desc>{t('vendor.manage_orders.desc')}</Desc>
									</Col>
									<Col lg={{ size: 8 }}>
										<img src={img_order_list} alt="" className="img-section img-rounded-corners en" />
									</Col>
								</Row>
							</Container>
						</div>
					</div>
					<div className="bg-section">
						<div className="section">
							<Container className="container-p-60">
								<Row className="row-m-60">
									<Col lg={{ size: true }}>
										<Title size="xl">{t('vendor.protection.title')}</Title>
										<Desc className="text-yellow">{t('vendor.protection.desc')}</Desc>
									</Col>
									<Col lg={{ size: 'auto' }}>
										<ScrollAnimation
											className="row row-m-20 features"
											animateIn="features-in"
											offset={150}
											delay={0}
											duration={0.4}
											>
										{
											protections.map((el, index) =>
												<Col key={index} xs={{ size: 4 }} className="feature">
													<img src={el.img} alt="" />
													<Desc>{el.desc}</Desc>
												</Col>
											)
										}
										</ScrollAnimation>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
					<div className="section section-join-us">
						<Container className="container-lg">
							<Title size="xl">{t('vendor.join_us.join_us_now')}
							<span>
							{t('vendor.join_us.it_is')}
								<ScrollAnimation
									className="text-free text-yellow"
									animateIn="zoom-in"
									offset={150}
									delay={200}
									duration={0.4}
									>
									{t('vendor.join_us.free')}
								</ScrollAnimation>
							</span>
							</Title>
							<Title size="xs">{t('vendor.join_us.find_out_more')}</Title>
							<Desc>{t('vendor.join_us.get_in_touch')}</Desc>
							<Link to="/vendor/contact_us" className="text-yellow">info@weddinguno.com</Link>
							<img src={img_logo} alt="" className="img-logo" />
						</Container>
					</div>
				</PageContainer>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['home'])(Home);
const connected = connect(mapStateToProps)(translation);
export { connected as Home };