export const myCalendarConstants = {
	GET_REQUEST: 'MY_CALENDAR_GET_REQUEST',
    GET_SUCCESS: 'MY_CALENDAR_GET_SUCCESS',
	GET_FAILURE: 'MY_CALENDAR_GET_FAILURE',
	GET_CLEAR: 'MY_CALENDAR_GET_CLEAR',

	EDIT_REQUEST: 'MY_CALENDAR_EDIT_REQUEST',
    EDIT_SUCCESS: 'MY_CALENDAR_EDIT_SUCCESS',
	EDIT_FAILURE: 'MY_CALENDAR_EDIT_FAILURE',
	EDIT_CLEAR: 'MY_CALENDAR_EDIT_CLEAR',

	GET_ORDERS_REQUEST: 'MY_CALENDAR_GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: 'MY_CALENDAR_GET_ORDERS_SUCCESS',
	GET_ORDERS_FAILURE: 'MY_CALENDAR_GET_ORDERS_FAILURE',
	GET_ORDERS_CLEAR: 'MY_CALENDAR_GET_ORDERS_CLEAR',
}