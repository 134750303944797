import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { FieldArray, connect as connectFormik } from 'formik';

import { Desc, InputItem, InputItemLabel, InputField } from '../../../../_components';

import './InputForm.scss';

import img_remove from './img/create_package_icon_remove_location.svg';

class InputForm extends Component {
	render() {
		const { t, formik, order } = this.props;
		const { values, handleSubmit, touched, resetForm } = formik;

		return (
			<div id="vendor_order_after_shooting_materials_form">
				<Card>
					<CardHeader>
						<CardTitle tag="h4">
							{t('after_shooting_materials.title')}
						</CardTitle>
						<Desc>
							{t('after_shooting_materials.desc')}
						</Desc>
					</CardHeader>
					<FieldArray
						name="items"
						render={arrayHelpers => (
							<>
								<CardBody>
									{values.items.map((item, index) =>
										<InputItem key={index}>
											<Row>
												<Col md={{ size: 2, order: 1 }} xs={{ size: true, order: 1 }}>
													<InputItemLabel>
														{t('item')}
													</InputItemLabel>
												</Col>
												<Col md={{ size: 8, order: 2 }} xs={{ size: 12, order: 3 }}>
													<InputField type="text" name={`items.${index}.name`} t={t} placeholder={t(`name.${item.key? item.key : 'name'}`)} />
												</Col>
												<Col md={{ size: 2, order: 3 }} xs={{ size: 'auto', order: 2 }}>
													<Button color="link" className="btn-remove" disabled={values.items.length === 1} onClick={() => arrayHelpers.remove(index)}>
														<img src={img_remove} alt={t('remove')} />{t('remove')}
													</Button>
												</Col>
											</Row>
											<Row>
												<Col md={{ size: 2 }}>
													<InputItemLabel>
														{t('desc.desc')}
													</InputItemLabel>
												</Col>
												<Col md={{ size: 8 }}>
													<InputField component="textarea" type="text" name={`items.${index}.desc`} placeholder={t(`desc.${item.key? item.key : 'desc'}`)} t={t} />
												</Col>
											</Row>
										</InputItem>
									)}
								</CardBody>
								<CardFooter className="add-item">
									<Button outline size="sm" className="btn-add-item" onClick={() => arrayHelpers.push({ key: null, name: '', desc: '' })}>{t('add_more_item')}</Button>
								</CardFooter>
								<CardFooter className="display-responsive">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }}>
											<Button
												block
												disabled={order.materials.edit.loading}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }}>
											<Button
												color="cancel"
												block
												disabled={order.materials.edit.loading}
												onClick={() => this.props.onEditCancel(Object.keys(touched).length !== 0, resetForm)}>
												{t('cancel')}
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</>
						)}
					/>
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['aftershooting', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
const connectedFormik = connectFormik(connected);
export { connectedFormik as InputForm };