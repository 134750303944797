import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Helmet } from "react-helmet";
import Moment from 'moment';

import "react-tabs/style/react-tabs.css";
import '../_common/tabs.scss';

import './App.scss';

import { PrivateRoute, ErrorPage } from '../_components';

import { history, analytics } from '../_helpers';
import { alertActions, userActions, appActions } from '../_actions';
import {
	initLogin as sb_initLogin,
	sendbirdLogin as sb_sendbirdLogin,
	sendbirdLogout as sb_sendbirdLogout,
	updateProfile as sb_updateProfile
} from '../_sendbird';

import { Header, Footer } from '../Partials';

import { Home, Special } from '../Home';
import { Article, Articles } from '../Article';
import { 
	RegisterWrapper as CustomerRegister, 
	LoginWrapper as CustomerLogin, 
	MyProfileRouter as CustomerMyProfile,
	OrderRouter as CustomerOrder
} from '../Customer';
import { 
	Packages, 
	Details as PackageDetails, 
	Search as PackagesSearch 
} from '../Package';
import { 
	ProfileRouter as VendorProfile, 
	Home as VendorHome, 
	RegisterWrapper as VendorRegister, 
	LoginWrapper as VendorLogin, 
	MyProfileRouter as VendorMyProfile,
	PayoutRouter as VendorPayout,
	MyCalendar as VendorMyCalendar,
	CreatePackage as VendorCreatePackage,
	PackagePreview as VendorPackagePreview,
	MyPackagesRouter as VendorMyPackages,
	OrderRouter as VendorOrder,
	Search as VendorSearch
} from '../Vendor';
import { ContactUs, AboutUs, TNC, HelpRouter as Help, PrivacyPolicy } from '../StaticPage';
import { Chat } from '../Chat';

class App extends Component {
	constructor(props) {
		super(props);

		const { dispatch, app } = this.props;
		history.listen((location, action) => {
			this.setState({ hasError: undefined, error: undefined });
			// clear alert on location change
			dispatch(alertActions.clear());

			// Use setTimeout to make sure this runs after React Router's own listener
			setTimeout(() => {
				// Keep default behavior of restoring scroll position when user:
				// - clicked back button
				// - clicked on a link that programmatically calls `history.goBack()`
				// - manually changed the URL in the address bar (here we might want
				// to scroll to top, but we can't differentiate it from the others)
				if (location.action === 'POP') {
					return;
				}
				// In all other cases, scroll to top
				window.scrollTo(0, 0);
			});

			//GA record path on change
			analytics.pageView(location.pathname + location.search);
		});

		const currency_id = app.currency.currency? app.currency.currency.id : 'undefined';
		this.state = {
			keyPackages: `packages_currency_${currency_id}`,
			keyPackage: `package_currency_${currency_id}`
		}

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(sb_initLogin());
		
		const { auth } = this.props
		if (auth.loggedIn) {
			this.props.dispatch(userActions.getUser());	
			this.props.dispatch(sb_sendbirdLogin(auth.user));

			analytics.set({ userId: auth.user.id });
		}

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		//GA record first path
		analytics.pageView(history.location.pathname + history.location.search);

		//prerender.io
		window.prerenderReady = true;
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { auth, app } = this.props;

		if (auth.loggedIn !== prevProps.auth.loggedIn) {
			if (auth.loggedIn) {
				this.props.dispatch(sb_sendbirdLogin(auth.user));

				analytics.set({ userId: auth.user.id });
				
			} else {
				this.props.dispatch(sb_sendbirdLogout());
				if (auth.redirect) {
					history.push(auth.redirectPath);
				}

				analytics.set({ userId: null });
			}
		}

		if (auth.loggedIn && auth.user !== prevProps.auth.user) {
			this.props.dispatch(sb_updateProfile(auth.user));
		}
		
		if (app.currency.currency && (!app.currency.currency || app.currency.currency.id !== prevProps.app.currency.currency.id)) {
			this.setState({ keyPackages: `packages_currency_${app.currency.currency.id}`, keyPackage: `package_currency_${app.currency.currency.id}` });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);

		const { auth } = this.props
		if (auth.loggedIn) {
			this.props.dispatch(sb_sendbirdLogout());
		}
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		if (process.env.REACT_APP_ENV === 'dev') {
			this.setState({ error: { title: error.toString(), message: info.componentStack }});
		} else {
			this.setState({ error: { title: 'Error', message: error.toString() }});
		}
	}
	
	updateWindowDimensions() {
		this.props.dispatch(appActions.setWindowSize(window.innerWidth, window.innerHeight));
	}

	render() {
		const { t } = this.props;
		const { keyPackages, keyPackage, hasError, error } = this.state;

		return (
			<Router key="home"  history={history}>
				<>
					<Helmet>
						<title>{process.env.REACT_APP_NAME}</title>
						<meta property="og:url" content={`${process.env.REACT_APP_SHARE_URL}${history.location.pathname}`} />

						<meta property="og:title" content={t('og_title')} />
						<meta property="og:description" content={t('og_desc')} />
						<meta property="og:site_name" content={t('og_site_name')} />
						<meta property="og:image" content="https://weddinguno-prod.oss-cn-hongkong.aliyuncs.com/images/fb_banner_op4.jpg" />

						<meta property="twitter:title" content={t('og_title')} />
						<meta property="twitter:description" content={t('og_desc')} />
						<meta property="twitter:image" content="https://weddinguno-prod.oss-cn-hongkong.aliyuncs.com/images/fb_banner_op4.jpg" />
						<meta name="twitter:card" content="summary_large_image" />
					</Helmet>
					<ToastContainer hideProgressBar autoClose={2000} />
					<Header />
					<div className="body">
						<Switch>
							{hasError && error ?
								<Route key="ErrorPage" render={props => (
									<ErrorPage error={error} {...props} crash/>
								)} />
								:
								[
									<Route key="Home" exact path="/" component={Home} />,
									<Route key="Special" exact path="/special/:id(\d+)" component={Special} />,
									<Route key="Articles" exact path="/articles" component={Articles} />,
									<Route key="Article" exact path="/article/:id(\d+)" component={Article} />,
									<Route key="AboutUs" exact path="/about_us" component={AboutUs} />,
									<Route key="TNC" exact path="/tnc" component={TNC} />,
									<Route key="Help" path="/help" component={Help} />,
									<Route key="ContactUs" exact path="/contact_us" component={ContactUs} />,
									<Route key="PrivacyPolicy" exact path="/policy" component={PrivacyPolicy} />,
									<Route key="VendorAboutUs" exact path="/vendor/about_us" component={AboutUs} />,
									<Route key="VendorTNC" exact path="/vendor/tnc" component={TNC} />,
									<Route key="VendorHelp" path="/vendor/help" component={Help} />,
									<Route key="VendorContactUs" exact path="/vendor/contact_us" component={ContactUs} />,
									<Route key="VendorPrivacyPolicy" exact path="/vendor/policy" component={PrivacyPolicy} />,

									<PrivateRoute key="Chat" exact path="/chat" component={Chat} />,
									<PrivateRoute key="VendorChat" exact path="/vendor/chat" component={Chat} />,

									<Route key="CustomerRegister" exact path="/customer/register" component={CustomerRegister} />,
									<Route key="CustomerLogin" exact path="/customer/login" component={CustomerLogin} />,
									<PrivateRoute key="CustomerOrder" path="/customer/me/order/:id(\d+)" component={CustomerOrder} />,
									<PrivateRoute key="CustomerMyProfile" path="/customer/me" component={CustomerMyProfile} />,

									<Route key="Packages" exact path="/packages/:filter_type(category|destination)/:filter_id(\d+)" render={props => (
										<Packages key={keyPackages} {...props} />
									)} />,
									<Route key="PackagesSearch" exact path="/search/packages" component={PackagesSearch} />,
									<Route key="VendorProfile" path="/package/vendor/:id(\d+)" component={VendorProfile} />,
									<Route key="PackageDetails" path="/package/:id(\d+)" render={props => (
										<PackageDetails key={keyPackage} {...props} />
									)} />,
									<Route key="VendorSearch" exact path="/search/vendors" component={VendorSearch} />,
									<Route key="VendorHome" exact path="/vendor" component={VendorHome} />,
									<Route key="VendorRegister" exact path="/vendor/register" component={VendorRegister} />,
									<Route key="VendorLogin" exact path="/vendor/login" component={VendorLogin} />,
									<PrivateRoute key="VendorMyCalendar" exact path="/vendor/me/calendar" component={VendorMyCalendar} />,
									<PrivateRoute key="VendorMyPackages" path="/vendor/me/package" component={VendorMyPackages} />,
									<PrivateRoute key="VendorPayout" path="/vendor/me/payout/:id(\d+)" component={VendorPayout} />,
									<PrivateRoute key="VendorOrder" path="/vendor/me/order/:id(\d+)" component={VendorOrder} />,
									<PrivateRoute key="VendorMyProfile" path="/vendor/me" component={VendorMyProfile} />,
									<PrivateRoute key="VendorPackagePreview" path="/vendor/package/create/:id(\d+)/preview" component={VendorPackagePreview} />,
									<PrivateRoute key="VendorEditPackageDraft" exact path="/vendor/package/create/:id(\d+)" render={props => (
										<VendorCreatePackage key="edit_draft" {...props} />
									)} />,
									<PrivateRoute key="VendorCreatePackage" exact path="/vendor/package/create" render={props => (
										<VendorCreatePackage key="create_package" {...props} />
									)} />,
									<PrivateRoute key="VendorEditPackage" exact path="/vendor/package/:id(\d+)/edit" render={props => (
										<VendorCreatePackage key="edit_package" {...props} />
									)} />,
									<PrivateRoute key="VendorPackagePreview" path="/vendor/package/:id/preview" component={VendorPackagePreview} />,

									<Route key="VendorErrorPage" path="/vendor/error/:code(\d+)" component={ErrorPage} />,
									<Route key="ErrorPage" path="/error/:code(\d+)" component={ErrorPage} />,
									<Route key="ErrorPage" component={ErrorPage} />,
								]
							}
						</Switch>
					</div>
					<Footer />
				</>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	const { alert, auth, app } = state;
	return {
		alert,
		auth,
		app,
	};
}

const translation = withNamespaces(['default'])(App);
const connected = connect(mapStateToProps)(translation);
export { connected as App };
