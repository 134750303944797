import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';
import Img from 'react-image';

import { BaseModal } from '../BaseModal';
import { Loading } from '../Loading';
import './LightBox.scss';

import img_btn_close from '../../_common/img/general_btn_popup_close.svg';
import img_prev from './img/details_location_portfolio_fullscreen_arrow_left.svg';
import img_next from './img/details_location_portfolio_fullscreen_arrow_right.svg';

class LightBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentIndex: props.index? props.index : 0,
			srcKey: props.srcKey? props.srcKey : 'src',
			captionKey: props.captionKey? props.captionKey : 'caption',
			isReadMore: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { index, items } = this.props;

		if (items && prevProps.index !== index) {
			this.setState({ currentIndex: ((index + items.length) % items.length) });
		}
	}
	
	onPrev(e) {
		e.stopPropagation();

		this.setState({ currentIndex: ((this.state.currentIndex - 1 + this.props.items.length) % this.props.items.length), isReadMore: false });
	}

	onNext(e) {
		e.stopPropagation();

		this.setState({ currentIndex: (this.state.currentIndex + 1) % this.props.items.length, isReadMore: false });
	}

	onDoNoCloseElement(e) {
		e.stopPropagation();
	}

	captionOnReadMore(e, isReadMore) {
		e.stopPropagation();

		this.setState({ isReadMore: !isReadMore });
	}

	render() {
		const { t, items } = this.props;
		const { currentIndex, srcKey, captionKey, isReadMore } = this.state;
		let currentItem = items[currentIndex];

		return (
			<BaseModal
				id="lightbox"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
				backdropClassName="lightbox-backdrop"
				onClosed={() => {
					this.setState({ isReadMore: false });
				}}
			>
				<div className="modal-content-container" onClick={() => this.props.toggle()}>
					<ModalBody>
						<Row className="row-image">
							<Col md={{ size: 1, order: 1 }} xs={{ size: true, order: 2 }} className="col-control col-control-prev">
								<Button color="link" onClick={(e) => this.onPrev(e)} title={t('prev')}><img src={img_prev} alt={t('prev')} /></Button>
							</Col>
							<Col md={{ size: 10, order: 2 }} xs={{ size: 12, order: 1 }} className="col-item">
								{currentItem &&
									<div className="item-container">
										<Img
											src={currentItem[srcKey]}
											alt={currentItem[captionKey]}
											title={currentItem[captionKey]}
											loader={<Loading />} onClick={(e) => this.onDoNoCloseElement(e)} />
										{currentItem[captionKey] &&
											<div className={`caption-container ${isReadMore ? 'show' : ''}`}
											onClick={(e) => this.onDoNoCloseElement(e)}
											>
												<div className="caption-scroller">
													<div className="caption">
													{currentItem[captionKey]}
													</div>
												</div>
												<Button color="link" onClick={(e) => this.captionOnReadMore(e, isReadMore)}>{t(isReadMore ? 'read_less' : 'read_more')}</Button>
											</div>
										}
									</div>
								}
							</Col>
							<Col md={{ size: 1, order: 3 }} xs={{ size: true, order: 3 }} className="col-control col-control-next">
								<Button color="link" onClick={(e) => this.onNext(e)} title={t('next')}><img src={img_next} alt={t('next')} /></Button>
							</Col>
						</Row>
						<div className="pagecount" onClick={(e) => this.onDoNoCloseElement(e)}>{`${currentIndex + 1} / ${items.length}`}</div>
					</ModalBody>
				</div>
				<Button color="img" className="lightbox-btn-close" onClick={() => this.props.toggle()}><img src={img_btn_close} alt="" /></Button>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['default'])(LightBox);
export { translation as LightBox };