import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Moment from 'moment';

import { Title, Desc } from '../../_components';

import './PackageDetails.scss';

class PackageDetails extends Component {
	render() {
		const { t, data, receipt } = this.props;

		if (data) {
			const servicePrefix = ['outfit', 'service'];
			let services = [];
			for (let key in data.package_includes) {
				servicePrefix.forEach(prefix => {
					if (key.startsWith(`${prefix}_`) && !key.endsWith('desc') && key !== 'service_fee') {
						if (data.package_includes[key]) {
							if (!services[prefix]) {
								services[prefix] = [];
							}
							services[prefix].push({ name: key, desc: data.package_includes[`${key}_desc`] });
						}
					}
				});
			}
			data['package_includes']['services'] = services;
		}

		return (
			<Card id="order_package">
				<CardHeader>
					<CardTitle tag="h4">
						{t('package_details')}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<div className="basic-info">
						{receipt &&
							<Row>
								<Col md={{ size: 5 }} className="item-name">
									{t('shooting_datetime')}
								</Col>
								<Col md={{ size: 7 }}>
									{`${Moment.utc(receipt.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(receipt.timezone.offset).format('D MMM YYYY h:mma')} ${receipt.timezone.offset}`}
								</Col>
							</Row>
						}
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('destination')}
							</Col>
							<Col md={{ size: 7 }}>
								{data.destination.value}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('category')}
							</Col>
							<Col md={{ size: 7 }}>
								{data.category.value}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('type')}
							</Col>
							<Col md={{ size: 7 }}>
								{data.package_type.value}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('day_hour_of_shooting')}
							</Col>
							<Col md={{ size: 7 }}>
								{data.shoot_day !== null && t('n_days', { n: data.shoot_day })}
								{data.shoot_hour !== null && t('n_hours', { n: data.shoot_hour })}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('number_of_locations')}
							</Col>
							<Col md={{ size: 7 }}>
								{t('n_locations', { n: data.location_count })}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 5 }} className="item-name">
								{t('locations')}
							</Col>
							<Col md={{ size: 7 }}>
								{data.location_names.map((item, index) =>
									<div key={index}>{item}</div>
								)}
							</Col>
						</Row>
					</div>
					<hr />
					<div className="about">
						<Title size='xs'>
							{t('about_the_package')}
						</Title>
						<Desc>
							{data.desc}
						</Desc>
					</div>
					<div className="details-section photo-video">
						<Title size='xs' className="section-title">{t('photo_video_included')}</Title>
						<div className="included-items">
							{data.package_includes.edited_photo && <div className="included-item">{t('n_edited_photos', { n: data.package_includes.edited_photo_amount })}</div>}
							{data.package_includes.original_photo && <div className="included-item">{t('n_original_photo', { n: data.package_includes.original_photo_amount })}</div>}
							{data.package_includes.video_highlight && <div className="included-item">{t('n_video_highlight', { n: data.package_includes.video_highlight_amount })}</div>}
							{data.package_includes.photo_book && <div className="included-item">{t('n_photo_book_n_pages', { n: data.package_includes.photo_book_amount, n_pages: data.package_includes.photo_book_page })}</div>}
							{data.package_includes.free_shipping && <div className="included-item">{t('free_shipping')}</div>}
						</div>
						<div className="delivery">
							{t('materials_deliver_in_n_weeks', { n: data.package_includes.delivery_day })}
						</div>
						{data.package_includes.remarks &&
							<>
								<hr />
								<Desc className="included-items-desc remarks">
									{data.package_includes.remarks}
								</Desc>
							</>
						}
					</div>
					{Object.keys(data.package_includes.services).map(key => <ServiceIncludedGroup key={key} t={t} groupName={key} serviceGroup={data.package_includes.services[key]} />)}
					<div className="details-section terms">
						<Title size='xs' className="section-title">{t('terms_and_policy')}</Title>
						<div className="terms-group">
							<Title size="xs">{t('general_terms')}</Title>
							<Desc className="general-terms">
								{t('general_terms_desc')}
							</Desc>
						</div>
						<div className="terms-group">
							<Title size="xs">{t('cancellations')}</Title>
							<Desc className="cancellations">
								{t('cancellations_desc')}
							</Desc>
						</div>
						<div className="terms-group">
							<Title size="xs">{t('bad_weather_arrangement')}</Title>
							<Desc className="bad-weather-arrangement">
								{t('bad_weather_arrangement_desc')}
							</Desc>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}
}

const ServiceIncludedGroup = ({ t, groupName, serviceGroup }) => {
	return (
		<div className="details-section service-included">
			<Title size='xs' className="section-title">{`${t('service_included')} - ${t(groupName)}`}</Title>
			<div className="included-items">
				{
					Object.keys(serviceGroup).map(key => {
						let service = serviceGroup[key];
						return (
							<div key={key} className="included-item">{t(service.name)}</div>
						);
					})
				}
			</div>
			<hr />
			<div className="included-items-desc">
				{
					Object.keys(serviceGroup).map(key => {
						let service = serviceGroup[key];
						if (service.desc) {
							return (
								<div key={key} className="item-desc">
									<Title size='xs'>
										{t(service.name)}
									</Title>
									<Desc>
										{service.desc}
									</Desc>
								</div>
							);
						}
					})
				}
			</div>
		</div>
	);
}

const translation = withNamespaces(['package', 'default'])(PackageDetails);
export { translation as PackageDetails };