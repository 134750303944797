import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { Login, LoginUserType } from '../../User';

import './LoginWrapper.scss';

class LoginWrapper extends Component {
	render() {
		const { t } = this.props;

		return (
			<Login 
			className="customer-login"
			userType={LoginUserType.customer}
			title={t('customer.title')}
			/>
		);
	}
}

const translation = withNamespaces(['login'])(LoginWrapper);
const connected = connect()(translation);
export { connected as LoginWrapper };