import { headerConstants } from '../_constants';

export function header(state = { solid: true }, action) {
	switch (action.type) {
		case headerConstants.SOLID_STYLE:
			return {
				solid: action.solid,
				gradient: state.active,
				dark: state.dark
			};
		case headerConstants.GRADIENT_STYLE:
			return {
				solid: state.solid,
				gradient: action.active,
				dark: state.dark
			};
		case headerConstants.DARK_STYLE:
			return {
				solid: state.solid,
				gradient: state.active,
				dark: action.dark
			};
		default:
			return state
	}
}
