export const vendorVerificationConstants = {
	GET_REQUEST: 'VENDOR_VERIFICATION_GET_REQUEST',
    GET_SUCCESS: 'VENDOR_VERIFICATION_GET_SUCCESS',
	GET_FAILURE: 'VENDOR_VERIFICATION_GET_FAILURE',
	GET_CLEAR: 'VENDOR_VERIFICATION_GET_CLEAR',

	EDIT_REQUEST: 'VENDOR_VERIFICATION_EDIT_REQUEST',
    EDIT_SUCCESS: 'VENDOR_VERIFICATION_EDIT_SUCCESS',
	EDIT_FAILURE: 'VENDOR_VERIFICATION_EDIT_FAILURE',
	EDIT_CLEAR: 'VENDOR_VERIFICATION_EDIT_CLEAR',
}

export const vendorVerificationStatus = {
	not_verified: 1,
	verifying: 2,
	verified: 3,
	failed: 4
}

export function getVendorVerificationStatusNameByStatus(status) {
	switch (status) {
		case vendorVerificationStatus.not_verified: return 'not_verified';
		case vendorVerificationStatus.verifying: return 'verifying';
		case vendorVerificationStatus.verified: return 'verified';
		case vendorVerificationStatus.failed: return 'failed';
	
		default: return null;
	}
}