import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Moment from 'moment';

import { orderActions } from '../../../_actions';
import { CardsContainer, RowCards, ColCards, Title, Loading } from '../../../_components';

import './Meetup.scss';

import { Map } from '../../../Order';

class Meetup extends Component {
	componentDidMount() {
		const { data } = this.props;
		this.props.dispatch(orderActions.getMeetupDetails(false, data.id));
	}

	render() {
		const { t, order, data } = this.props;

		return (
			<div id="customer_order_meetup">
				{order.meet_up.loading && <Loading />}
				{order.meet_up.error && <Loading error={order.meet_up.error} />}
				{order.meet_up.success && !order.meet_up.data && <div className="no-result">{t('vendor_havent_added_yet')}</div>}
				{order.meet_up.success && order.meet_up.data &&
					<CardsContainer>
						<RowCards>
							<ColCards lg={{ size: 6 }}>
								<Card className="card-details">
									<CardHeader>
										<CardTitle tag="h4">
											{t('meetup_time_place')}
										</CardTitle>
										<Title>
										{`${Moment.utc(order.meet_up.data.datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(order.meet_up.data.utc_offset).format('D MMM YYYY h:mma')} ${order.meet_up.data.utc_offset}`}
										</Title>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={{ size: 3 }}>
												{t('location')}
											</Col>
											<Col md={{ size: 9 }}>
												{order.meet_up.data.full_address}
											</Col>
										</Row>
										{order.meet_up.data.remarks &&
											<Row>
												<Col md={{ size: 3 }}>
													{t('remarks')}
												</Col>
												<Col md={{ size: 9 }}>
													{order.meet_up.data.remarks}
												</Col>
											</Row>
										}
									</CardBody>
								</Card>
							</ColCards>
							<ColCards lg={{ size: 6 }}>
								<Card className="card-map">
									<Map
										center={{
											lat: Number(order.meet_up.data.location_lat), lng: Number(order.meet_up.data.location_long)
										}}
										markers={[{
											position: {
												lat: Number(order.meet_up.data.location_lat), lng: Number(order.meet_up.data.location_long)
											},
											name: order.meet_up.data.location_title
										}]}
									/>
								</Card>
							</ColCards>
						</RowCards>
					</CardsContainer>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['meetup', 'order', 'package', 'default'])(Meetup);
const connected = connect(mapStateToProps)(translation);
export { connected as Meetup };