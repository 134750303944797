import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Nav, NavItem, NavLink, Dropdown, DropdownToggle } from 'reactstrap';

import { packageActions } from '../../../_actions';
import { history, auth as authHelper } from '../../../_helpers';

import './NavCustomer.scss';

import { MegaMenu, MegaMenuType } from './MegaMenu';

import img_search_package from './img/menu_search_package.jpg';
import img_search_vendor from './img/menu_search_photographer.jpg';

class NavCustomer extends Component {
	// lifecycle
	constructor(props) {
		super(props);

		this.state = {
			isDestinationsMenuOpen: false,
			isCategoriesMenuOpen: false,
			isSearchMenuOpen: false
		}

		history.listen((location, action) => {
			this.setState({ isDestinationsMenuOpen: false, isCategoriesMenuOpen: false, isSearchMenuOpen: false });
		});
	}

	componentDidMount() {
		this.props.dispatch(packageActions.getDestinations());
		this.props.dispatch(packageActions.getCategories());
	}

	toggleMenu(type) {
		switch (type) {
			case MegaMenuType.destinations:
				this.setState({ isDestinationsMenuOpen: !this.state.isDestinationsMenuOpen });
				break;
			case MegaMenuType.categories:
				this.setState({ isCategoriesMenuOpen: !this.state.isCategoriesMenuOpen });
				break;
			case MegaMenuType.search:
				this.setState({ isSearchMenuOpen: !this.state.isSearchMenuOpen });
				break;

			default:
				break;
		}
	}

	onHoverMenu(type, isEnter) {
		const { app } = this.props;
		if (app.windowSize.width < 1200) return; //no hover if showing responsive header

		switch (type) {
			case MegaMenuType.destinations:
				if (this.state.isDestinationsMenuOpen === isEnter) return;
				break;
			case MegaMenuType.categories:
				if (this.state.isCategoriesMenuOpen === isEnter) return;
				break;
			case MegaMenuType.search:
				if (this.state.isSearchMenuOpen === isEnter) return;
				break;

			default: return;
		}

		this.toggleMenu(type);
	}

    render() {
		const { t, package_destinations, package_categories } = this.props;
		const { isDestinationsMenuOpen, isCategoriesMenuOpen, isSearchMenuOpen } = this.state
		const isLoggedIn = authHelper.isLoggedIn(false, this.props.auth.user);

		const searchMenu = [
			{
				id: 1,
				search_type: 'packages',
				value: t('search_packages'),
				thumbnail_url: img_search_package
			},
			{
				id: 2,
				search_type: 'vendors',
				value: t('search_vendors'),
				thumbnail_url: img_search_vendor
			}
		];

		let packageDestinationsAll = [];
		if (package_destinations.groups) {
			packageDestinationsAll = package_destinations.groups.reduce((acc, val) => acc.concat(val.items), []); //replacement for flat()
		}

		let packageCategoriesAll = [];
		if (package_categories.groups) {
			packageCategoriesAll = package_categories.groups.reduce((acc, val) => acc.concat(val.items), []); //replacement for flat()
		}

		return (
			<Nav navbar id="nav_customer">
				<NavItem className="nav-display-responsive">
					<NavLink tag={Link} to='/'>{t('home')}</NavLink>
				</NavItem>
				<Dropdown nav inNavbar className="nav-megamenu-dropdown"
					isOpen={isDestinationsMenuOpen}
					toggle={() => this.toggleMenu(MegaMenuType.destinations)}
					onMouseEnter={() => this.onHoverMenu(MegaMenuType.destinations, true)}
					onMouseLeave={() => this.onHoverMenu(MegaMenuType.destinations, false)}
					>
					<DropdownToggle nav className="nav-megamenu-dropdown-toggle">
						{t('destinations')}
					</DropdownToggle>
					<MegaMenu type={MegaMenuType.destinations} loading={package_destinations.loading} all={packageDestinationsAll} featured={package_destinations.featured}/>
				</Dropdown>
				<Dropdown nav inNavbar className="nav-megamenu-dropdown"
					isOpen={isCategoriesMenuOpen}
					toggle={() => this.toggleMenu(MegaMenuType.categories)}
					onMouseEnter={() => this.onHoverMenu(MegaMenuType.categories, true)}
					onMouseLeave={() => this.onHoverMenu(MegaMenuType.categories, false)}
					>
					<DropdownToggle nav className="nav-megamenu-dropdown-toggle">
					{t('categories')}
					</DropdownToggle>
					<MegaMenu type={MegaMenuType.categories} loading={package_categories.loading} all={packageCategoriesAll} featured={package_categories.featured}/>
				</Dropdown>
				<Dropdown nav inNavbar className="nav-megamenu-dropdown"
					isOpen={isSearchMenuOpen}
					toggle={() => this.toggleMenu(MegaMenuType.search)}
					onMouseEnter={() => this.onHoverMenu(MegaMenuType.search, true)}
					onMouseLeave={() => this.onHoverMenu(MegaMenuType.search, false)}
					>
					<DropdownToggle nav className="nav-megamenu-dropdown-toggle">
					{t('search')}
					</DropdownToggle>
					<MegaMenu type={MegaMenuType.search} loading={false} all={searchMenu} featured={searchMenu}/>
				</Dropdown>
				{!isLoggedIn &&
					<>
						<NavItem className="nav-display-responsive nav-item-hr"><hr /></NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/customer/login'>{t('anonymous.login')}</NavLink>
						</NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/customer/register'>{t('anonymous.register')}</NavLink>
						</NavItem>
						<NavItem className="nav-display-responsive">
							<NavLink tag={Link} to='/vendor'>{t('anonymous.vendor')}</NavLink>
						</NavItem>
					</>
				}
			</Nav>
		);
	}
}

function mapStateToProps(state) {
	const { package_destinations, package_categories, auth, app } = state;
    return {
		package_destinations,
		package_categories,
		auth,
		app
    };
}

const translation = withNamespaces(['header', 'default'])(NavCustomer);
const connected = connect(mapStateToProps)(translation);
export { connected as NavCustomer };