import React, { Component } from 'react';
import toPath from 'lodash/toPath';
import { Container, Row, Col } from 'reactstrap';

import './ResponsiveTable.scss';

export class ResponsiveTable extends Component {
	onRowClick(data, index) {
		if (this.props.onRowClick) {
			this.props.onRowClick(data, index);
		}
	}

	renderHeader(columns) {
		const { t } = this.props;

		return (
			<Row className="responsive-table-header">
				{columns.map((column, index) =>
					<Col key={column.key} md={{ size: column.grid_size }} className={`${column.key}`}>{column.name? column.name : t(column.key)}</Col>
				)}
			</Row>
		);
	}

	renderRow(columns, data, index) {
		return (
			<Row key={index} className="responsive-table-row" onClick={() => this.onRowClick(data, index)}>
				{columns.map((column) =>
					this.renderCell(column, data)
				)}
			</Row>
		);
	}

	/**
	 * Deeply get a value from an object via it's path.
	 */
	getIn(
		obj,//: any,
		key,//: string | string[],
		def = undefined,//?: any,
		p = 0//: number = 0
	) {
		const path = toPath(key);
		while (obj && p < path.length) {
			obj = obj[path[p++]];
		}
		return obj === undefined ? def : obj;
	}

	renderCell(column, data) {
		let cellData = column.data_key === null? data : this.getIn(data, column.data_key? column.data_key : column.key);
		let cellValue = null;

		const { t, renderCellValue } = this.props;
		if (renderCellValue) {
			cellValue = renderCellValue(column, cellData, data);
		}

		if (!cellValue) {
			if (typeof cellData === 'object') {
				cellValue = JSON.stringify(cellData);
			} else {
				cellValue = cellData;
			}
		}

		const cellValueWrapper = (
			<div className={`responsive-table-cell-value-inner`}>
			{cellValue}
			</div>
		)

		return (
			<Col key={column.key} md={{ size: column.grid_size }} className={`responsive-table-cell ${column.key}`}>
				<Row>
					<Col md={{ size: true }} className="responsive-table-cell-name">
						{column.name ? column.name : t(column.key)}
					</Col>
					<Col md={{ size: true }} className={`responsive-table-cell-value`}>
						{cellValueWrapper}
					</Col>
				</Row>
			</Col>
		);
	}

	render() {
		const { columns, data } = this.props;

		return (
			data.length ?
				<Container className="responsive-table">
					{this.renderHeader(columns)}
					<div className="responsive-table-body">
						{data.map((row, index) =>
							this.renderRow(columns, row, index)
						)}
					</div>
				</Container>
				:
				null
		);
	}
}