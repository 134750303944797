import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../../../_actions';
import { ActionModal } from '../../../../_components';

import './ReplyModal.scss';

export const PendingItemActionType = {
	none: 'none',
	cancel: 'cancel',
	accept: 'accept',
	reject: 'reject'
}

class ReplyModal extends Component {
	componentDidMount() {
		const { actionType } = this.props;
		
		switch (actionType) {
			case PendingItemActionType.cancel:
				this.props.dispatch(orderActions.cancelPendingItemClear());
				break;

			case PendingItemActionType.accept:
			case PendingItemActionType.reject:
				this.props.dispatch(orderActions.replyPendingItemClear());
				break;
			default:
				break;
		}
	}

	render() {
		const { t, isOpen, isVendor, actionType, data, order } = this.props;

		let confirmTitle = t(`${actionType}.confirm.title`);
		let confirmDesc = t(`${actionType}.confirm.desc`);
		let successTitle = t(`${actionType}.success.title`);
		let successDesc = t(`${actionType}.success.desc`);

		let isLoading = false;
		let isSuccess = false;
		let error = undefined;
		switch (actionType) {
			case PendingItemActionType.cancel:
				isLoading = order.pending_item.cancel.loading;
				isSuccess = order.pending_item.cancel.success;
				error = order.pending_item.cancel.error;
				break;
			case PendingItemActionType.accept:
			case PendingItemActionType.reject:
				isLoading = order.pending_item.reply.loading;
				isSuccess = order.pending_item.reply.success;
				error = order.pending_item.reply.error;
				break;
			default:
				break;
		}

		return (
			<ActionModal
				id="pending_items_reply_modal"
				confirmTitle={confirmTitle}
				confirmDesc={confirmDesc}
				successTitle={successTitle}
				successDesc={successDesc}
				isLoading={isLoading}
				isSuccess={isSuccess}
				error={error}
				isOpen={isOpen}
				toggle={() => this.props.toggle()}
				onClosed={() => {
					switch (actionType) {
						case PendingItemActionType.cancel:
							this.props.dispatch(orderActions.cancelPendingItemClear());
							break;

						case PendingItemActionType.accept:
						case PendingItemActionType.reject:
							this.props.dispatch(orderActions.replyPendingItemClear());
							break;
						default:
							break;
					}

					if (this.props.onClosed) {
						this.props.onClosed();
					}
				}}
				onSubmit={() => {
					switch (actionType) {
						case PendingItemActionType.cancel:
							this.props.dispatch(orderActions.cancelPendingItem(isVendor, data.order_id, data.id));
							break;

						case PendingItemActionType.accept:
						case PendingItemActionType.reject:
							const accept = actionType === PendingItemActionType.accept;
							this.props.dispatch(orderActions.replyPendingItem(isVendor, data.order_id, data.id, accept));
							break;
						default:
							break;
					}
				}}
				onSuccess={() => {
					if (this.props.onSuccess) {
						this.props.onSuccess();
					}
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['pendingitem', 'default'])(ReplyModal);
const connected = connect(mapStateToProps)(translation);
export { connected as ReplyModal };