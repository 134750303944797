export const homeConstants = {
    GET_SPECIAL_LIST_REQUEST: 'HOME_GET_SPECIAL_LIST_REQUEST',
    GET_SPECIAL_LIST_SUCCESS: 'HOME_GET_SPECIAL_LIST_SUCCESS',
	GET_SPECIAL_LIST_FAILURE: 'HOME_GET_SPECIAL_LIST_FAILURE',
	GET_SPECIAL_LIST_CLEAR: 'HOME_GET_SPECIAL_LIST_CLEAR',

	GET_SPECIAL_DETAILS_REQUEST: 'HOME_GET_SPECIAL_DETAILS_REQUEST',
    GET_SPECIAL_DETAILS_SUCCESS: 'HOME_GET_SPECIAL_DETAILS_SUCCESS',
	GET_SPECIAL_DETAILS_FAILURE: 'HOME_GET_SPECIAL_DETAILS_FAILURE',
	GET_SPECIAL_DETAILS_CLEAR: 'HOME_GET_SPECIAL_DETAILS_CLEAR',
}