import React, { Component } from 'react';
import { Button, Container, Col } from 'reactstrap';
import LinesEllipsis from 'react-lines-ellipsis';

import { savedItemsActions } from '../../_actions';
import { savedItemType } from '../../_constants';
import { history, auth as authHelper } from '../../_helpers';
import { ProfilePicImg, Desc, RowStroked, GeneralGridItemContent } from '../../_components';

import './GridItem.scss';

export class GridItemContent extends Component {
	onBookmark(item) {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			if (this.props.item.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsVendor(item.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsVendor(item.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	onReviews(e) {
		e.preventDefault();
		e.stopPropagation();

		const { item } = this.props;
		history.push(`/package/vendor/${item.id}/reviews`);
	}

	onPackages(e) {
		e.preventDefault();
		e.stopPropagation();

		const { item } = this.props;
		history.push(`/package/vendor/${item.id}/packages`);
	}

	onClick(item) {
		history.push(`/package/vendor/${item.id}`);
	}

	render() {
		const { t, item, saved_item_action, hideBookmark } = this.props;

		const isLoading = saved_item_action.loading && saved_item_action.type == savedItemType.VENDOR && saved_item_action.id == item.id;
		const is_disabled = false;

		return (
			<GeneralGridItemContent className="grid-item-content-vendor"
				isLoading={isLoading}
				isDisabled={is_disabled}
				disabledTitle={t('unavailable_vendor')}
				hideBookmark={hideBookmark}
				isBookmarked={item.is_bookmarked}
				onBookmark={() => this.onBookmark(item)}
				onClick={() => this.onClick(item)}
			>
				<ProfilePicImg src={item.profile_pic_url} alt={item.nickname} />

				<div className="grid-item-name-country">
					<div className="grid-item-name">{item.nickname}</div>
					<div className="grid-item-country">{item.city.value}</div>
				</div>

				<LinesEllipsis
					className="grid-item-language"
					text={`${t('speaking_language')} ${item.languages.map((language) => language.value).join(', ')}`}
					maxLine='3'
					ellipsis='...'
					trimRight
					basedOn='words'
				/>

				{/* <div className="grid-item-desc-wrapper">
					<Desc className="grid-item-desc">
						{item.desc}
					</Desc>
				</div> */}

				<Container className="grid-item-btns">
					<RowStroked>
						<Col xs={{ size: 'auto' }}>
							<Button color="link" onClick={(e) => this.onReviews(e)}>{`${item.review_count} ${t('reviews')}`}</Button>
						</Col>
						<Col xs={{ size: 'auto' }}>
							<Button color="link" onClick={(e) => this.onPackages(e)}>{`${item.package_count} ${t('packages')}`}</Button>
						</Col>
					</RowStroked>
				</Container>

			</GeneralGridItemContent>
		);
	}
}