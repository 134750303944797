import React from "react";

import { Desc } from '../../../../../_components';
import { BaseMessage } from '../BaseMessage';

import './ImageMessage.scss';

export const ImageMessage = ({ message, className, ...props}) => {
	return (
		<BaseMessage
			message={message}
			className={`chat-message-img ${className ? className : ''}`}
			bubbleMarginSize="lg"
			onMessageBody={() => {
				window.open(message.url);
			}}
			{...props}
		>
			<img src={message.url} alt="" />
			<Desc className="message">
				{message.name}
			</Desc>
		</BaseMessage>
	);
}