import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container } from 'reactstrap';

import { InfiniteScrollWrapper } from '../../../_components';
import { vendorPackageActions } from '../../../_actions';

import './Packages.scss';

import { Package } from './Package';
import { CreatePackageButton } from '../../CreatePackageButton';

class Packages extends Component {
	componentDidMount() {
		this.props.dispatch(vendorPackageActions.getListClear(false));
	}

	render() {
		const { t, vendor_packages } = this.props;

		return (
			<div id="vendor_mypackages_packages">
				<Container>
					<InfiniteScrollWrapper
						elementRef={(element) => this.infiniteScrollComponent = element}
						pageStart={1}
						loadMoreProp={vendor_packages}
						onLoadMore={(nextPage) => {
							this.props.dispatch(vendorPackageActions.getList(false, nextPage));
						}}
						renderItems={(items) => {
							return (
								items.map((item, index) =>
									<Package key={item.id} data={item} />
								)
							)
						}}
						noResultDiv={
							<div className="no-result">
								<div>{t('no_packages')}</div>
								<CreatePackageButton />
							</div>
						}
					/>
				</Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_packages } = state;
	return {
		vendor_packages
	};
}

const translation = withNamespaces(['mypackages'])(Packages);
const connected = connect(mapStateToProps)(translation);
export { connected as Packages };