import SendBird from 'sendbird';

export const sbConnect = (user) => {
    return new Promise((resolve, reject) => {
        if (!user.id) {
            reject('UserID is required.');
            return;
        }
        if (!user.nickname) {
            reject('Nickname is required.');
            return;
		}
        const sb = new SendBird({'appId': process.env.REACT_APP_SENDBIRD_APP_ID});
        sb.connect(user.id, (sb_user, error) => {
            if (error) {
                reject('SendBird Login Failed.');
            } else {
                resolve(sbUpdateProfile(user));
            }
        })
    })
};

export const sbUpdateProfile = (user) => {
    return new Promise((resolve, reject) => {
        if (!user.nickname) {
            reject('Nickname is required.');
            return;
        }
        let sb = SendBird.getInstance();
        if(!sb) sb = new SendBird({'appId': process.env.REACT_APP_SENDBIRD_APP_ID});
        sb.updateCurrentUserInfo(user.nickname, user.profile_pic_url, (sb_user, error) => {
            if (error) {
                reject('Update profile failed.')
            } else {
                // AsyncStorage.setItem('user', JSON.stringify(user), () => {
                //     resolve(user);
				// });
				resolve(sb_user);
            }
        })
    })
}

export const sbDisconnect = () => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        if (sb) {
            // AsyncStorage.removeItem('user', () => {
                sb.disconnect(() => {
                    resolve(null);
                });
			// });
			resolve('SendBird disconnect success.');
        } else {
            resolve('SendBird disconnect failed.');
        }
    })
}

export const sbGetCurrentInfo = () => {
    const sb = SendBird.getInstance();
    if(sb.currentUser) {
        return {
            profileUrl: sb.currentUser.profileUrl,
            nickname: sb.currentUser.nickname
        }
    }
    return {};
}

export const sbUserBlock = (blockUserId) => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        sb.blockUserWithUserId(blockUserId, (user, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(user);
            }
        })
    });
}

export const sbUserUnblock = (unblockUserId) => {
    return new Promise((resolve, reject) => {
        const sb = SendBird.getInstance();
        sb.unblockUserWithUserId(unblockUserId, (user, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(user);
            }
        })
    });
}

export const sbCreateBlockedUserListQuery = () => {
    const sb = SendBird.getInstance();
    return sb.createBlockedUserListQuery();
}

export const sbGetBlockUserList = (blockedUserListQuery) => {
    return new Promise((resolve, reject) => {
        blockedUserListQuery.next((blockedUsers, error) => {
            if (error) {
                reject(error);
            } else {
                resolve(blockedUsers);
            }
        });
    });
}
