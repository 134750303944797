import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import {
	initGroupChannel as sb_initGroupChannel,
	groupChannelProgress as sb_groupChannelProgress,
	getGroupChannelList as sb_getGroupChannelList,
	createGroupChannelListHandler as sb_createGroupChannelListHandler,
	sbCreateGroupChannelListQuery as sb_createGroupChannelListQuery,
	onGroupChannelPress as sb_onGroupChannelPress,
	channelExit as sb_channelExit,
	clearSelectedGroupChannel as sb_clearSelectedGroupChannel,
	sbCreateUserListQuery as sb_createUserListQuery,
	getUserList as sb_getUserList,
	createGroupChannel as sb_createGroupChannel,
} from '../../_sendbird';

import { Loading } from '../../_components';

import './Channels.scss';

import { Channel } from './Channel';

class Channels extends Component {
	constructor(props) {
		super(props);

		this.state = {
			groupChannelListQuery: undefined,
			canLoad: true,
			targetChannel: props.targetChannel
		}
	}

	componentDidMount() {
		this.props.dispatch(sb_initGroupChannel());
		this.props.dispatch(sb_createGroupChannelListHandler());
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { sb_groupChannel, sb_groupChannelInvite } = this.props;
		const { groupChannelListQuery, canLoad } = this.state;

		if (groupChannelListQuery && !groupChannelListQuery.isLoading && sb_groupChannel && !sb_groupChannel.isLoading) {
			if (!canLoad) {
				this.setState({ canLoad: true });
			}
		}

		let targetChannelProps = this.props.targetChannel;
		let targetChannelState = this.state.targetChannel;

		if ((!prevProps.targetChannel || targetChannelProps !== prevProps.targetChannel) && targetChannelProps !== targetChannelState) {
			this.setState({ targetChannel: targetChannelProps });
		}

		if (groupChannelListQuery && !groupChannelListQuery.isLoading && sb_groupChannel && !sb_groupChannel.isLoading) {
			if ((!prevProps.groupChannelListQuery || groupChannelListQuery.isLoading !== prevProps.groupChannelListQuery.isLoading) || sb_groupChannel.isLoading !== prevProps.sb_groupChannel.isLoading) {
				if (targetChannelState) {
					const resultChannel = sb_groupChannel.list.find((channel) => {
						return channel.memberMap[targetChannelState] !== undefined;
					});
	
					if (resultChannel) {
						this.setState({ targetChannel: undefined }, () => {
							//scroll to channel
							const channels = document.getElementById('chat_channels');
							const resultChannelDiv = document.getElementById(resultChannel.url);
							if (channels && resultChannelDiv) {
								if (resultChannelDiv.offsetTop > channels.offsetHeight) {
									//scroll to middle of resultChannelDiv
									channels.scrollTop = Math.max(resultChannelDiv.offsetTop + (resultChannelDiv.offsetHeight / 2) - (channels.offsetHeight / 2), 0);
								}
							}

							//goto channel
							this.onChannel(resultChannel);
						});
					} else {
						if (groupChannelListQuery.hasNext) {
							//load more
							this.getGroupChannelList(false);
						} else {
							//oh not found
							this.setState({ targetChannel: undefined });

							//assume channel between this 2 member should be not exist
							//validate target user_id by get user list with target user_id filter
							const userListQuery = sb_createUserListQuery();
							userListQuery.userIdsFilter = [targetChannelState];
							this.props.dispatch(sb_getUserList(userListQuery));
						}
					}
				}
			}
		}

		if (sb_groupChannelInvite.channel && (!sb_groupChannel.channel || sb_groupChannelInvite.channel.url !== sb_groupChannel.channel.url) && (!prevProps.sb_groupChannelInvite.channel || sb_groupChannelInvite.channel.url !== prevProps.sb_groupChannelInvite.channel.url)) {
			//just created new channel for targetChannel
			//go to channel
			this.onChannel(sb_groupChannelInvite.channel);
		}

		if (prevProps.sb_groupChannelInvite && sb_groupChannelInvite) {
		}
		if (sb_groupChannelInvite.list && (!prevProps.sb_groupChannelInvite || sb_groupChannelInvite.list !== prevProps.sb_groupChannelInvite.list)) {
			if (sb_groupChannelInvite.list.length) {
				//target user_id is valid
				//create distinct channel for these 2 users
				//go to newly created channel after success
				const sb_user = sb_groupChannelInvite.list[0];
				this.props.dispatch(sb_createGroupChannel([sb_user.userId], true));
			}
		}
	}
	
	componentWillUnmount() {
		const { sb_groupChannel } = this.props;
		
		if (sb_groupChannel.channel) {
			this.props.dispatch(sb_channelExit(sb_groupChannel.channel.url, sb_groupChannel.channel.isOpenChannel()));
			this.props.dispatch(sb_clearSelectedGroupChannel());
		}
	}
	

	getGroupChannelList(init) {
		const { groupChannelListQuery } = this.state;
		const { sb_groupChannel } = this.props;

		if (sb_groupChannel.isLoading || (groupChannelListQuery && groupChannelListQuery.isLoading)) return;

		this.props.dispatch(sb_groupChannelProgress(true));

		if (init) {
			let query = sb_createGroupChannelListQuery();
			query.limit = 5;
			this.setState({ groupChannelListQuery: query }, () => {
				this.props.dispatch(sb_getGroupChannelList(query));
			});
		} else {
			this.props.dispatch(sb_getGroupChannelList(groupChannelListQuery));
		}	
	}

	onChannel(channel) {
		const { sb_groupChannel } = this.props;
		if (sb_groupChannel.channel && channel.url === sb_groupChannel.channel.url) return;
		
		if (sb_groupChannel.channel) {
			this.props.dispatch(sb_channelExit(sb_groupChannel.channel.url, sb_groupChannel.channel.isOpenChannel()));
			this.props.dispatch(sb_clearSelectedGroupChannel());
		}
		
		this.props.dispatch(sb_onGroupChannelPress(channel.url));
	}

	render() {
		const { t, className, sb_groupChannel } = this.props;
		const { groupChannelListQuery, canLoad } = this.state;

		return (
			<div id="chat_channels" className={className}>
				{sb_groupChannel.isLoading && <Loading />}
				<InfiniteScroll
					className="chat-channels-list"
					initialLoad={canLoad}
					useWindow={false}
					loadMore={(page) => {
						this.setState({ canLoad: false }, () => {
							this.getGroupChannelList(page === 1);
						})
					}}
					hasMore={groupChannelListQuery? groupChannelListQuery.hasNext : true}
					loader={sb_groupChannel.isLoading? <Loading key="loader" /> : null}
				>
					{
						sb_groupChannel.list.map((channel, index) =>
							<Channel key={index} t={t} channel={channel} active={sb_groupChannel.channel && channel.url === sb_groupChannel.channel.url} onClick={(channel) => this.onChannel(channel)} />)
					}
				</InfiniteScroll>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { sb_groupChannel, sb_groupChannelInvite } = state;
	return {
		sb_groupChannel,
		sb_groupChannelInvite
	};
}

const connected = connect(mapStateToProps)(Channels);
export { connected as Channels };