import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';

import { orderActions } from '../../../../_actions';
import { PageContainer, Title, Desc } from '../../../../_components';

import './Success.scss';


class Success extends Component {
	componentWillUnmount() {
		this.props.dispatch(orderActions.paymentProcessClear());
	}

	render() {
		const { t, order_id } = this.props;

		return (
			<PageContainer id="customer_order_pay_success">
				<Container>
					<Title>{t('payment_success.title')}</Title>
					<Desc>{t('payment_success.desc')}</Desc>
					<Link className="btn btn-primary" to={`/customer/me/order/${order_id}`}>{t('view_order')}</Link>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const translation = withNamespaces(['payment', 'default'])(Success);
const connected = connect(mapStateToProps)(translation);
export { connected as Success };