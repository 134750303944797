import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	initChatScreen as sb_initChatScreen,
	createChatHandler as sb_createChatHandler,
	markAsRead as sb_markAsRead,
} from '../../_sendbird';

import './ChatArea.scss';

import { Body } from './Body';
import { Input } from './Input';
import { Header } from './Header';

class ChatArea extends Component {
	componentDidMount() {
		this.props.dispatch(sb_initChatScreen());
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { channel } = this.props;
		if (channel && (!prevProps.channel || channel.url !== prevProps.channel.url)) {
			this.props.dispatch(sb_initChatScreen());
			this.initChat(channel);
		}
	}

	initChat(channel) {
		this.props.dispatch(sb_createChatHandler(channel.url, channel.isOpenChannel()));
		this.props.dispatch(sb_markAsRead(channel));
	}

	render() {
		const { t, channel, chat } = this.props;

		return (
			<div id="chat_area">
				{channel && chat?
					<>
						<Header t={t} channel={channel} chat={chat} />
						<Body t={t} channel={channel} chat={chat} />
						<Input t={t} channel={channel} chat={chat} />
					</>
					:
					<div style={{ margin: 'auto' }}>
						{t('select_channel_and_start_chatting')}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const connected = connect(mapStateToProps)(ChatArea);
export { connected as ChatArea };