import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { vendorVerificationActions } from '../../../../../_actions';
import { ActionModal } from '../../../../../_components';

import './VerifyModal.scss';

import { InputForm } from './InputForm';

class VerifyModal extends Component {
	componentDidMount() {
		this.props.dispatch(vendorVerificationActions.editClear());
	}

	onSubmit(values, formikBag) {
		let formData = new FormData();
		formData.append('info', values.info);
		if (values.photo) {
			formData.append('photo', values.photo);
		}
		if (values.photo_url) {
			formData.append('photo_url', values.photo_url);
		}

		this.props.dispatch(vendorVerificationActions.edit(formData));
	}

	render() {
		const { t, isOpen, vendor_verification, data } = this.props;

		return (
			<ActionModal
				id="vendor_myprofile_vendor_profile_profile_verify_modal"
				confirmComponent={
					<InputForm
						data={data}
						submitting={false}
						onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
						toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t('success.title')}
				successDesc={t('success.desc')}
				isLoading={vendor_verification.edit.loading}
				isSuccess={vendor_verification.edit.success}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(vendorVerificationActions.editClear());
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_verification } = state;
	return {
		vendor_verification
	};
}

const translation = withNamespaces(['verify', 'default'])(VerifyModal);
const connected = connect(mapStateToProps)(translation);
export { connected as VerifyModal };