import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { articleActions } from '../../_actions';
import { PageContainer, LoadingPage, ErrorPage } from '../../_components';

import { ArticleContent } from '../ArticleContent';
import { HotArticles } from '../HotArticles';
import './Article.scss';

class Article extends Component {
	componentDidMount() {
		this.props.dispatch(articleActions.get(this.props.match.params.id));
	}

	componentDidUpdate(prevProps, prevState) {
		const { match } = this.props;
		const { params } = match;

		if (prevProps.match.params !== params) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		this.props.dispatch(articleActions.get(props.match.params.id));
	}
	
	render() {
		const { article } = this.props;

		return (
			<>
				{article.loading && <LoadingPage />}
				{article.error && <ErrorPage match={this.props.match} error={article.error} />}
				{article.data &&
					<PageContainer id="article">
						<Container>
							<Row>
								<Col xl={{ size: 8 }}>
									<ArticleContent item={article.data} />
								</Col>
								<Col xl={{ size: 4 }}>
									<HotArticles />
								</Col>
							</Row>
						</Container>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { article } = state;
    return {
        article
    };
}

const connected = connect(mapStateToProps)(Article);
export { connected as Article };