import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { orderStatus, canEditOrderByData } from '../../../../_constants';
import { alertActions, payoutSettingsActions, orderActions } from '../../../../_actions';
import { Loading, Error } from '../../../../_components';

import './OrderSummary.scss';

import { Breakdown } from './Breakdown';
import { InputForm } from './InputForm';
import { MakeOfferModal } from '../../MakeOfferModal';

class OrderSummary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEdit: false,
			makeOfferModalIsOpen: false,
			newOfferData: undefined,
			values: undefined
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.editClear());
		this.props.dispatch(payoutSettingsActions.get());
	}

	componentDidUpdate(prevProps, prevState) {
		const { order, data, t } = this.props;
		if (order.edit.success && prevProps.order.edit.success !== order.edit.success) {
			this.setState({ isEdit: false, newOfferData: undefined, values: undefined });
			if (data.status === orderStatus.requesting) {
				this.props.dispatch(alertActions.success(t('alert.success')));
			}
		}
	}

	onEdit() {
		this.setState({ isEdit: true });
	}

	onEditCancel(showConfirm, resetForm) {
		resetForm();
		
		const { t } = this.props;
		if (showConfirm) {
			if (window.confirm(t('you_have_unsaved_changes_confirm_to_cancel'))) {
				this.setState({ isEdit: false, newOfferData: undefined, values: undefined });
			}
		} else {
			this.setState({ isEdit: false, newOfferData: undefined, values: undefined });
		}
	}

	onEditSubmit(values, { setSubmitting }) {
		const { data } = this.props;

		if (data.status === orderStatus.requesting) {
			this.props.dispatch(orderActions.edit(true, this.props.data.id, values));
		} else {
			/*
			package_amount
			additional_amount
			vendor_total_amount
			revenue
			remarks
			*/
			let newOfferData = Object.assign({}, data);
			newOfferData.package_amount = values.package_amount;
			newOfferData.additional_amount = values.additional_amount;
			newOfferData.vendor_total_amount = Number(values.package_amount) + Number(values.additional_amount);
			newOfferData.revenue = values.revenue;
			newOfferData.remarks = values.remarks;
			this.setState({ newOfferData, values, makeOfferModalIsOpen: true });
		}
	}

	render() {
		const { t, data, order, payout_settings } = this.props;
		const { isEdit, makeOfferModalIsOpen, newOfferData } = this.state;

		let initialValues = {
			package_amount: data.package_amount,
			additional_amount: data.additional_amount,
			remarks: data.remarks? data.remarks : ''
		}

		const amountMax = payout_settings.data? payout_settings.data.currency.max_value : 999999;
		const amountMin = payout_settings.data? payout_settings.data.currency.min_value : 200;

		const schema = Yup.object().shape({
			package_amount: Yup.number()
				.test('package_amount', t('total_amount_min_max_error', { min: amountMin, max: amountMax }), function (value) {
					if (!payout_settings.data) return true;
					
					const { additional_amount } = this.parent;
					const total = Number(value) + Number(additional_amount);

					return total >= amountMin && total <= amountMax;
				})
				.required(t('required')),
			additional_amount: Yup.number()
				.test('additional_amount', t('total_amount_min_max_error', { min: amountMin, max: amountMax }), function (value) {
					if (!payout_settings.data) return true;

					const { package_amount } = this.parent;
					const total = Number(value) + Number(package_amount);

					return total >= amountMin && total <= amountMax;
				}),
		});

		return (
			<Card id="vendor_order_details_summary" className="card-payment-summary">
				{payout_settings.loading && <Loading />}
				{payout_settings.error && <Error error={payout_settings.error} />}
				{payout_settings.data &&
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={(values, { setSubmitting }) => this.onEditSubmit(values, { setSubmitting })}
					>
						{({ values,
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							setFieldTouched,
							resetForm
							/* and other goodies */
						}) => (
								<Form>
									<CardHeader>
										<Row className="header">
											<Col sm={{ size: true }} className="col-title">
												<CardTitle tag="h4">
													{t('order_summary')}
												</CardTitle>
											</Col>
											{!isEdit && canEditOrderByData(data) &&
												<Col sm={{ size: 'auto' }}>
													<Button block outline onClick={() => this.onEdit()}>{t('edit')}</Button>
												</Col>
											}
											{isEdit &&
												<>
													<Col sm={{ size: 'auto' }}>
														<Button color="cancel" block onClick={() => this.onEditCancel(Object.keys(touched).length !== 0, resetForm)}>{t('cancel')}</Button>
													</Col>
													<Col sm={{ size: 'auto' }}>
														<Button
															block
															type="submit"
															disabled={order.edit.loading}
															onClick={handleSubmit}>
															{t('save_changes')}
														</Button>
													</Col>
												</>
											}
										</Row>
									</CardHeader>
									<CardBody>
										{isEdit ? <InputForm data={data} payoutSettingsData={payout_settings.data} /> : <Breakdown data={data} />}
									</CardBody>
								</Form>
							)}
					</Formik>
				}
				<MakeOfferModal
					data={newOfferData}
					isOpen={makeOfferModalIsOpen}
					isLoading={order.edit.loading}
					isSuccess={order.edit.success}
					error={order.edit.error}
					toggle={() => this.setState({ makeOfferModalIsOpen: !this.state.makeOfferModalIsOpen })}
					onSubmit={() => {
						const { values } = this.state;
						this.props.dispatch(orderActions.edit(true, this.props.data.id, values));
					}}
					onClosed={() => {
						this.props.dispatch(orderActions.editClear());
						this.setState({ newOfferData: undefined, values: undefined });
					}}
				/>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { order, payout_settings } = state;
	return {
		order,
		payout_settings
	};
}

const translation = withNamespaces(['order', 'payment', 'package', 'default'])(OrderSummary);
const connected = connect(mapStateToProps)(translation);
export { connected as OrderSummary };