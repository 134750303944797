import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from 'reactstrap';

import { userActions } from '../../_actions';
import { history } from '../../_helpers';

import { InputField, InputSelect, PageContainer, Title, InputItem } from '../../_components';
import { ForgotPw } from '../PopupModal';

import './Login.scss';

export const LoginUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class Login extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			forgotPwModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(userActions.forceLogout(false));
		this.props.dispatch(userActions.loginClear());
	}

	componentDidUpdate(prevProps, prevState) {
		const { login, userType } = this.props;

		if (login.success && prevProps.login.success !== login.success) {
			history.push(userType === LoginUserType.vendor? '/vendor' : '/');
		}
	}
	
	onForgotPw() {
		this.setState({ forgotPwModalIsOpen: true });
	}
	
	onSubmit(values, { setSubmitting }) {
		this.props.dispatch(userActions.login(values));
	}

	render() {
		const { login, t, className, title, userType } = this.props;
		const { forgotPwModalIsOpen } = this.state;

		let registerLinkTo = (userType === LoginUserType.vendor) ? '/vendor/register' : '/customer/register';

		const schema = Yup.object().shape({
			user_type: Yup.number()
				.required(t('required')),
			email: Yup.string()
				.required(t('required')),
			password: Yup.string()
				.required(t('required')),
		});

		return (
			<PageContainer id="user_login" className={(className ? ` ${className}` : '')}>
				<div className="form-container">
					<Formik
						enableReinitialize
						initialValues={{
							user_type: (userType === LoginUserType.vendor ? 0 : 1),
							email: '',
							password: '',
						}}
						validationSchema={schema}
						onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
					>
						{({ values,
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							setFieldTouched
							/* and other goodies */
						}) => (
								<Form>
									<Title>
										{title}
									</Title>
									{userType === LoginUserType.vendor &&
										<InputItem>
											<InputSelect
												name="user_type" t={t}
												options={[{ id: 2, value: t('vendor.photographer') }, { id: 3, value: t('vendor.agency') }]}
												isSearchable={false}
											/>
										</InputItem>
									}
									<InputItem>
										<InputField type="email" name="email" t={t} />
									</InputItem>
									<InputItem>
										<InputField type="password" name="password" t={t} />
									</InputItem>
									<InputItem className="forgot-pw"> <Button color="link" onClick={() => this.onForgotPw()}>{t('forgotten_pw')}</Button></InputItem>
									<InputItem>
										<Button
											className="btn-login"
											type="submit"
											disabled={login.loading}
											onClick={handleSubmit}>
											{t('login')}
										</Button>
									</InputItem>
									<InputItem className="register">
										{t('new_to_wedding_uno')} <Link to={registerLinkTo}>{t('create_account')}</Link>
									</InputItem>
								</Form>
							)}
					</Formik>
				</div>
				<ForgotPw
					userType={userType}
					isOpen={forgotPwModalIsOpen}
					toggle={() => this.setState({ forgotPwModalIsOpen: !this.state.forgotPwModalIsOpen })}
				/>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { auth, login, } = state;
    return {
		auth,
		login,
    };
}

const translation = withNamespaces(['login', 'register', 'default'])(Login);
const connected = connect(mapStateToProps)(translation);
export { connected as Login };