import SendBird from 'sendbird';
import {
	CREATE_CONNECTION_HANDLER_SUCCESS,
	REMOVE_CONNECTION_HANDLER_SUCCESS,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from './types';

export const createConnectionHandler = () => {
	return (dispatch) => {
		const sb = SendBird.getInstance();
		const handler = new sb.ConnectionHandler();
		handler.onReconnectStarted = () => {
			dispatch({
				type: RECONNECTION_START
			});
		};
		handler.onReconnectSucceeded = () => {
			dispatch({
				type: RECONNECTION_SUCCESS
			});
		};
		handler.onReconnectFailed = () => {
			dispatch({
				type: RECONNECTION_FAIL
			});
		};
		sb.addConnectionHandler('chat', handler);
		dispatch({
			type: CREATE_CONNECTION_HANDLER_SUCCESS
		});
		return;
	}
}

export const removeConnectionHandler = () => {
	return (dispatch) => {
		const sb = SendBird.getInstance();
		sb.removeConnectionHandler('chat');
		dispatch({
			type: REMOVE_CONNECTION_HANDLER_SUCCESS
		});
		return;
	}
}