import React from 'react';
import Moment from 'moment';

import { ProfilePicImg, Desc } from '../../../../../_components';

import './BaseMessage.scss';

export const BaseMessage = ({ message, readReceipt, isFrom, hideProfilePic, className, children, onMessageBody , bubbleMarginSize, ...props }) => {
	return (
		<div className={`chat-message chat-message-${isFrom? 'from' : 'to'} ${className? className : ''}`} {...props}>
			{!hideProfilePic &&
				<ProfilePicImg src={message.sender.profileUrl} />
			}
			<div className={`bubble bubble-m-${bubbleMarginSize? bubbleMarginSize : ''}`}>
				<div className="message-body" onClick={() => { if (onMessageBody) onMessageBody() }}>
					{children}
				</div>
				<div className="message-footer">
					{isFrom &&
						<img className={`message-status ${!Number.isNaN(message.updatedAt) ? 'sent' : ''} ${readReceipt === 0 ? 'read' : ''}`} src="" alt="" />
					}
					<Desc className="timestamp">
						{Moment(message.createdAt).format('D/MM h:mm a')}
					</Desc>
				</div>
			</div>
		</div>
	);
}