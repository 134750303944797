import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Container, Row, Col, CardGroup, Card, CardBody, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

import { savedItemsActions, vendorProfileActions } from '../../../_actions';
import { history, auth as authHelper, app } from '../../../_helpers';
import { Calendar, Title, Desc, ShareButton, VideoPlayer, PageContainer, ProfilePicImg } from '../../../_components';

import { ReviewsModal } from './ReviewsModal';
import { MakeBookingModal } from './MakeBookingModal';

import './DetailsContent.scss';

import img_reviews_star_off from '../../../_common/img/details_icon_reviews_star_off.svg';
import img_reviews_star_on from '../../../_common/img/details_icon_reviews_star_on.svg';

import img_category from './img/details_icon_basic_category.svg';
import img_type from './img/details_icon_basic_type.svg';
import img_shooting_hrs from './img/details_icon_basic_shooting_hours.svg';
import img_location from './img/details_icon_basic_location.svg';

import img_about from './img/details_icon_basic_about_the_package.svg';

import img_photo from './img/details_icon_item_included_photo.svg';
import img_video from './img/details_icon_item_included_video.svg';
import img_photobook from './img/details_icon_item_included_photo_book.svg';
import img_shipping from './img/details_icon_item_included_shipping.svg';
import img_outfit from './img/details_icon_serive_included_outfit.svg';
import img_service from './img/details_icon_serive_included_service.svg';

import img_bookmark from './img/details_icon_bookmark.svg';
import img_bookmarked from './img/details_icon_bookmarked.svg';
import img_share from './img/details_icon_share.svg';

import img_cancel_flow_arrow from './img/details_cancellations_arrow_pink.svg';

class DetailsContent extends Component {
	constructor(props){
		super(props);

		this.handleScroll = this.handleScroll.bind(this);

		let pathname = props.location.pathname;
		let reviewsModalIsOpen = pathname.endsWith('reviews');

		this.state = {
			calendarSelectedDate: undefined,
			fixFooter: true,
			reviewsModalIsOpen: reviewsModalIsOpen,
			vendor_disabled_dates: [],
			makeBookingModalIsOpen: false
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.props.dispatch(vendorProfileActions.getCalendarClear());
	};

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	};
	
	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		const { location } = props;

		let pathname = location.pathname;
		let reviewsModalIsOpen = pathname.endsWith('reviews');

		if (this.state.reviewsModalIsOpen !== reviewsModalIsOpen) {
			this.setState({ reviewsModalIsOpen: true });	
		}
	}

	handleScroll(event) {
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.offsetHeight;
		const offsetY = window.pageYOffset;
		const mainFooterHeight = document.getElementById('footer').offsetHeight; //this is referring the page main footer but not the footer in PackageDetail

		const shouldFix = documentHeight - offsetY > windowHeight + mainFooterHeight;
		this.setState({ fixFooter: shouldFix })
	};

	calendarIsDaySelected(day) {
		const { calendarSelectedDate } = this.state;
		return calendarSelectedDate? day.isSame(calendarSelectedDate) : false;
	}

	calendarIsDayDisabled(day) {
		if (!this.props.vendor_calendar.list) return false;
		
		let result = this.props.vendor_calendar.list.some((item) => {
			return day.isSame(Moment(item.date.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS'), 'day');
		});
		return result || day.isBefore(Moment());
	}

	calendarOnMonthChange(month, visibleMonths) {
		if (!this.props.data) return;
		if (this.props.data && this.props.isPreview) return;

		const monthStart = Moment(visibleMonths[0]).startOf('month');
		const monthEnd = Moment(visibleMonths[visibleMonths.length - 1]).endOf('month');
		let start_date = Moment(monthStart).startOf('week');
		let end_date = Moment(monthEnd).endOf('week');

		if (Moment(end_date).isBefore(Moment(), 'day')) {
			return;
		}

		if (Moment(start_date).isBefore(Moment(), 'day')) {
			start_date = Moment();
		}

		this.props.dispatch(vendorProfileActions.getCalendar(this.props.data.vendor.id, start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')));
	}

	calendarOnDayClick(day) {
		this.setState({ calendarSelectedDate: day });
	}

	onBookmark() {
		if (this.props.data && this.props.isPreview) return;

		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			if (this.props.data.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsPackage(this.props.data.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsPackage(this.props.data.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	onReviews() {
		this.setState({ reviewsModalIsOpen: true });
	}

	onBookNow() {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			this.setState({ makeBookingModalIsOpen: true });
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	render() {
		const { t, match, isPreview } = this.props;
		const {
			fixFooter,
			reviewsModalIsOpen,
			makeBookingModalIsOpen,
			calendarSelectedDate
		} = this.state;

		let data = this.props.data;

		const reviewStars = [1, 2, 3, 4, 5];
		const reviewsRate = data? Math.round(data.review_avg_rate) : 0;

		if (data) {
			const servicePrefix = ['outfit', 'service'];
			let services = [];
			for (let key in data) {
				servicePrefix.forEach(prefix => {
					if (key.startsWith(`${prefix}_`) && !key.endsWith('desc') && key !== 'service_fee') {
						if (data[key]) {
							if (!services[prefix]) {
								services[prefix] = [];
							}
							services[prefix].push({ name: key, desc: data[`${key}_desc`] });
						}
					}
				});
			}
			data['services'] = services;
		}

		return (
			<div id="package_details_content">
				<div className="wrapper">
					<div className="wrapper-content">
						<Container className="video-container">
							<VideoPlayer
								// className="top-banner top-banner-video"
								videoUrl={data.video_url}
							/>
						</Container>
						<Container className="content-container">
							<Row>
								<Col lg={{ size: 9, order: 1 }} xs={{ order: 2 }}>
									<div className="package">
										<Title>{data.name}</Title>
										{!isPreview && data.review_count > 0 &&
											<div className="reviews" onClick={() => this.onReviews()}>
												{reviewStars.map((item, index) =>
													<img key={item} src={(reviewsRate >= item) ? img_reviews_star_on : img_reviews_star_off} alt="" />
												)}
												<span className="count">{t('n_reviews', { n: data.review_count })}</span>
											</div>
										}
										<div className="basic-info">
											<Row className="row-m-20">
												<Col sm={{ size: 'auto' }} className="col-destination-category">
													<img src={img_category} alt={t('category')} />
													<span className="destination-category">
														<span>{data.destination.value}</span>
														<span>{data.category.value}</span>
													</span>
												</Col>
												<Col sm={{ size: 'auto' }}>
													<span><img src={img_type} alt={t('style')} />{data.package_type.value}</span>
												</Col>
												<Col sm={{ size: 'auto' }}>
													<span>
														<img src={img_shooting_hrs} alt={t('hrs_of_photoshoot')} />
														{data.shoot_day ?
															t('n_days_of_photoshoot', { n: data.shoot_day }) : t('n_hrs_of_photoshoot', { n: data.shoot_hour })}
													</span>
												</Col>
												<Col sm={{ size: 12 }} className="col-locations">
													<span><img src={img_location} alt={t('locations')} />{`${t('n_locations', { n: data.locations.length })}: ${data.locations.map((location) => location.name).join(' / ')}`}</span>
												</Col>
											</Row>
										</div>
										<Vendor
											t={t}
											data={data}
											isPreview={isPreview}
											className="display-responsive"
										/>
										<div className="locations">
											<Row>
												{data.locations.map((location, index) =>
													<Col sm={{ size: 4 }} key={location.id}>
														<Link to={`${match.url}/portfolio/${location.id}`}>
															<div className="img-wrapper">
																<img src={location.cover_image_url} alt={location.name} />
															</div>
															<div className="name">{location.name}</div>
															<div className="view">{t('view_portfolio')}</div>
														</Link>
													</Col>
												)}
											</Row>
										</div>
										<div className="details">
											<div className="details-section about">
												<Title size='xs' className="section-title"><img src={img_about} alt={t('about_the_package')} />{t('about_the_package')}</Title>
												<Desc id="about">
													{data.desc}
												</Desc>
											</div>
											<div className="details-section photo-video">
												<Title size='sm' className="section-title">{t('photo_video_included')}</Title>
												<Row className="row-m-20 included-items">
													{data.edited_photo &&
														<Col sm={{ size: 'auto' }}>
															<span><img src={img_photo} alt={t('edited_photo')} />{t('n_edited_photos', { n: data.edited_photo_amount })}</span>
														</Col>
													}
													{data.original_photo &&
														<Col sm={{ size: 'auto' }}>
															<span><img src={img_photo} alt={t('original_photo')} />{t('n_original_photo', { n: data.original_photo_amount })}</span>
														</Col>
													}
													{data.video_highlight &&
														<Col sm={{ size: 'auto' }}>
															<span><img src={img_video} alt={t('video_highlight')} />{t('n_video_highlight', { n: data.video_highlight_amount })}</span>
														</Col>
													}
													{data.photo_book &&
														<Col sm={{ size: 'auto' }}>
															<span><img src={img_photobook} alt={t('photo_book')} />{t('n_photo_book_n_pages', { n: data.photo_book_amount, n_pages: data.photo_book_page })}</span>
														</Col>
													}
													{data.free_shipping &&
														<Col sm={{ size: 'auto' }}>
															<span><img src={img_shipping} alt={t('shipping')} />{t('free_shipping')}</span>
														</Col>
													}
												</Row>
												<div className="delivery">
													<img src={img_shooting_hrs} alt={t('delivery_day')} />
													{t('materials_deliver_in_n_weeks', { n: data.delivery_day })}
												</div>
												{data.remarks &&
													<Desc className="remarks">
														{data.remarks}
													</Desc>
												}
											</div>
											{Object.keys(data.services).length > 0 &&
												<div className="details-section service-included">
													<Title size='sm' className="section-title">{t('service_included')}</Title>
													{
														Object.keys(data.services).map(key => <ServiceIncludedGroup key={key} t={t} groupName={key} serviceGroup={data.services[key]} />)
													}
												</div>
											}
											<div className="details-section availability">
												<Title size='sm' className="section-title">{t('availability')}</Title>
												<div className="calendar">
													<Calendar
														numberOfMonths={2}
														onMonthChange={(month, visibleMonths) => this.calendarOnMonthChange(month, visibleMonths)}
														onDayClick={(day) => this.calendarOnDayClick(day)}
														isDayDisabled={(day) => this.calendarIsDayDisabled(day)}
														isDaySelected={(day) => this.calendarIsDaySelected(day)}
													/>
													{isPreview &&
														<div className="layer-dummy">
															<div className="content-dummy">
																{t('calendar_dummy')}
															</div>
														</div>
													}
												</div>
											</div>
											{/* TODO: show real TNC */}
											<div className="details-section terms">
												<Title size='sm' className="section-title">{t('terms_and_policy')}</Title>
												<div className="terms-group">
													<Title size="xs">{t('general_terms')}</Title>
													<Desc className="general-terms">
														{t('general_terms_desc')}
													</Desc>
												</div>
												<div className="terms-group">
													<Title size="xs">{t('cancellations')}</Title>
													<CancellationFlow t={t} />
													<Desc className="cancellations">
														{t('cancellations_desc')}
													</Desc>
												</div>
												<div className="terms-group">
													<Title size="xs">{t('bad_weather_arrangement')}</Title>
													<Desc className="bad-weather-arrangement">
														{t('bad_weather_arrangement_desc')}
													</Desc>
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col lg={{ size: 3, order: 2 }} xs={{ order: 1 }}>
									<Vendor
										t={t}
										data={data}
										isPreview={isPreview}
										className="display-desktop"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<Footer
						match={match}
						t={t}
						onBookmark={() => this.onBookmark()}
						onBookNow={() => this.onBookNow()}
						data={data}
						fixFooter={fixFooter}
						is_bookmarked={data.is_bookmarked}
						isPreview={isPreview}
					/>
				</div>
				{
					!isPreview && data.review_count > 0 &&
					<ReviewsModal
						package_id={data.id}
						avg_rate={data.review_avg_rate}
						count={data.review_count}
						isOpen={reviewsModalIsOpen}
						toggle={() => this.setState({ reviewsModalIsOpen: !this.state.reviewsModalIsOpen })}
					/>
				}
				{
					!isPreview &&
					<MakeBookingModal
						data={data}
						selectedDate={calendarSelectedDate}
						isOpen={makeBookingModalIsOpen}
						toggle={() => this.setState({ makeBookingModalIsOpen: !this.state.makeBookingModalIsOpen })}
					/>
				}
			</div>
		);
	}
}

class Vendor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMouseOverVendor: false
		}
	}

	onHoverVendor(isEnter) {
		if (this.state.isMouseOverVendor === isEnter) return;
		this.setState({ isMouseOverVendor: isEnter });
	}

	onChat(e) {
		e.preventDefault();
		const { data } = this.props;
		history.push(`/chat?target=${data.vendor.id}`);
	}

	render() {
		const { t, data, isPreview, className } = this.props;
		const { isMouseOverVendor } = this.state;
		return (
			<div className={`vendor ${isMouseOverVendor? 'hover' : ''} ${className? className : ''}`}
				onMouseEnter={() => this.onHoverVendor(true)}
				onMouseLeave={() => this.onHoverVendor(false)}
			>
				<Link to={isPreview ? '/vendor/me/vendor_profile' : `/package/vendor/${data.vendor.id}`}>
						<Row className="row-m-8">
							<Col xs={{ size: 'auto', order: 1 }} lg={{ size: 12 }}>
								<ProfilePicImg src={data.vendor.profile_pic_url} alt={data.vendor.nickname} />
							</Col>
							<Col xs={{ size: true, order: 2 }} lg={{ size: 12 }}>
								<div className="name-country">
									<div className="name">{data.vendor.nickname}</div>
									<div className="country">{data.vendor.city.value}</div>
								</div>
							</Col>
							<Col xs={{ size: 12, order: 4 }} lg={{ size: 12, order: 3 }}>
								<div className="language">{t('speaking_language')} {data.vendor.languages.map((language) => language.value).join(', ')}</div>
								<div className="vendor-desc">{data.vendor.desc}</div>
							</Col>
							<Col xs={{ size: 'auto', order: 3 }} lg={{ size: 12, order: 4 }}>
								<Button block outline disabled={isPreview} onClick={(e) => this.onChat(e)}>{t('chat')}</Button>
							</Col>
						</Row>
				</Link>
			</div>
		);
	}
}

const Footer = ({ t, match ,onBookmark, onBookNow, data, fixFooter, is_bookmarked, isPreview }) => {
	return (
		<div className={"footer" + (fixFooter ? ' fixed' : ' sticky')}>
			<Container className="container-p-20">
				<Row className="row-m-8">
					<Col md={{ size: 'auto' }} xs={{ size: 'auto' }} className="actions">
						<Button color="img" onClick={() => onBookmark()} disabled={isPreview}><img src={is_bookmarked ? img_bookmarked : img_bookmark} alt={t('bookmark')} /></Button>
					</Col>
					<Col md={{ size: 'auto' }} xs={{ size: 'auto' }} className="actions">
						<ShareButton
							btnImg={img_share}
							url={window.location.href}
							title={data.name}
							body={`${data.name}`} //TODO: share content
							disabled={(data && isPreview)}
						/>
					</Col>
					<Col md={{ size: true }} xs={{ size: true }} className="price">
						<div className="currency-price">{`${(data.user_currency && data.user_currency.id !== data.currency.id)? '~ ' : ''}${app.toCurrencyString(data.user_currency ? data.user_currency.value : data.currency.value, data.user_currency ? data.user_currency_amount : data.amount)}`}</div>
						
						{data.user_currency && data.user_currency.id !== data.currency.id && <div className="original-price">{app.toCurrencyString(data.currency.value, data.amount)}</div>}
					</Col>
					<Col md={{ size: 'auto' }}>
						<Button block disabled={isPreview} onClick={() => onBookNow()}>{t('book_now')}</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

const ServiceIncludedGroup = ({ t, groupName, serviceGroup }) => {
	return (
		<div className="service-included-group">
			<div className="service-included-header"><img src={groupName === 'outfit' ? img_outfit : img_service} alt={t(groupName)} />{t(groupName)}</div>
			<Row>
				<Col sm={{ size: 4 }}>
					<ul className="service-included-list">
						{
							Object.keys(serviceGroup).map(key => {
								let service = serviceGroup[key];
								return (
									<li key={key} className="service-included-title">{t(service.name)}</li>
								);
							})
						}
					</ul>
				</Col>
				<Col sm={{ size: 8 }}>
					{
						Object.keys(serviceGroup).map(key => {
							let service = serviceGroup[key];
							if (service.desc) {
								return (
									<div key={key} className="service-included-desc">
										<Title size='xs'>{t(service.name)}</Title>
										<Desc>
											{service.desc}
										</Desc>
									</div>
								);
							} else {
								return null;
							}
						})
					}
				</Col>
			</Row>
		</div>
	);
}

const CancellationFlow = ({ t }) => {
	return (
		<CardGroup className="cancellation-flow">
			<Card>
				<CardBody>
					<div className="status status-responsive">
						{t('cancellations_flow.1.status')}
					</div>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.1.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div className="line"></div>
					<div className="status">{t('cancellations_flow.1.status')}</div>
				</CardFooter>
			</Card>
			<Card>
				<CardBody>
					<div className="status status-responsive">
						{t('cancellations_flow.2.status')}
					</div>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.2.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div className="line"></div>
					<div className="status">{t('cancellations_flow.2.status')}</div>
				</CardFooter>
			</Card>
			<Card>
				<CardBody>
					<div className="status status-responsive">
						{t('cancellations_flow.3.status')}
					</div>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.3.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
					<div className="status status-responsive">
						{t('cancellations_flow.4.status')}
					</div>
				</CardBody>
				<CardFooter>
					<div className="line"></div>
					<div className="status">
						<div>{t('cancellations_flow.3.status')}</div>
						<div>{t('cancellations_flow.4.status')}</div>
					</div>
				</CardFooter>
			</Card>
			{/* <Row className="bubbles">
				<Col xs={{ size: 4 }}>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.1.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
				</Col>
				<Col xs={{ size: 4 }}>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.2.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
				</Col>
				<Col xs={{ size: 4 }}>
					<div className="bubble-wrapper">
						<div className="bubble">
							<span>
								{t('cancellations_flow.3.bubble')}
							</span>
						</div>
						<div className="bubble-arrow">
							<img src={img_cancel_flow_arrow} alt="" />
						</div>
					</div>
				</Col>
			</Row>
			<Row className="lines">
				<Col xs={{ size: 4 }}>
					<div className="line">
					</div>
				</Col>
				<Col xs={{ size: 4 }}>
					<div className="line">
					</div>
				</Col>
				<Col xs={{ size: 4 }}>
					<div className="line">
					</div>
				</Col>
			</Row>
			<Row className="statuses">
				<Col xs={{ size: 4 }}>
					<div>{t('cancellations_flow.1.status')}</div>
				</Col>
				<Col xs={{ size: 4 }}>
					<div>{t('cancellations_flow.2.status')}</div>
				</Col>
				<Col xs={{ size: 2 }}>
					<div>{t('cancellations_flow.3.status')}</div>
				</Col>
				<Col xs={{ size: 2 }}>
					<div>{t('cancellations_flow.4.status')}</div>
				</Col>
			</Row> */}
		</CardGroup>
	);
}

function mapStateToProps(state) {
    const { auth, vendor_calendar } = state; 
    return {
		auth,
		vendor_calendar
    };
}

const translation = withNamespaces(['package', 'default'])(DetailsContent);
const connected = connect(mapStateToProps)(translation);
export { connected as DetailsContent };