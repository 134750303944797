import React from "react";

import { BaseMessage } from '../BaseMessage';

import { Desc } from '../../../../../_components';
import './FileMessage.scss';

import img_file from  '../../../../img/chat_thumbnail_file.svg';

export const FileMessage = ({ message, className, ...props}) => {
	return (
		<BaseMessage
			message={message}
			className={`chat-message-file ${className ? className : ''}`}
			bubbleMarginSize="lg"
			onMessageBody={() => {
				window.open(message.url);
			}}
			{...props}
		>
			<img src={img_file} alt="" />
			<Desc className="message">
				{message.name}
			</Desc>
		</BaseMessage>
	);
}