import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { MyProfile } from './MyProfile';

const MyProfilePathnames = ['payout_settings', 'payouts', 'orders', 'deals', 'vendor_profile'];

export class MyProfileRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute path={`${match.path}/:tab(${MyProfilePathnames.join('|')})`} component={MyProfile} />
				<PrivateRoute exact path={`${match.path}`} component={MyProfile} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}