import { 
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
	isLoading: false,
	success: false,
	error: false
}

export function connection (state = INITIAL_STATE, action) {
    switch(action.type) {
        case RECONNECTION_START: 
            return { ...INITIAL_STATE, isLoading: true };
        case RECONNECTION_SUCCESS:
            return { ...INITIAL_STATE, success: true  };
        case RECONNECTION_FAIL:
            return { ...INITIAL_STATE, error: true  };
        default:
            return state;
    }
}
