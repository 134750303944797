import { 
    INIT_PROFILE,
    GET_PROFILE_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAIL,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    userInfo: null,
    error: '',
    isSaved: false
}

export function profile (state = INITIAL_STATE, action) {
    switch (action.type) {
        case INIT_PROFILE: 
            return { ...state, ...INITIAL_STATE };
        case GET_PROFILE_SUCCESS: 
            return { ...state, userInfo: action.userInfo };
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, error: '', isSaved: true };
        case UPDATE_PROFILE_FAIL:
			return { ...state, error: action.error, isSaved: false };
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
			return { ...state, ...INITIAL_STATE };
        default: 
            return state;
    }
};
