import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Row, Col } from 'reactstrap';

import { Loading } from '../Loading';

import './Calendar.scss';

import img_calendar_arrow_left from './img/details_icon_availability_left.svg';
import img_calendar_arrow_right from './img/details_icon_availability_right.svg';

class Calendar extends Component {
	constructor(props) {
		super(props);

		let currentMonth = props.currentMonth? props.currentMonth : new Moment();
		let noOfMonths = props.numberOfMonths !== undefined? props.numberOfMonths : 1;
		this.state = {
			currentMonth: currentMonth,
			visibleMonths: this.getVisibleMonths(currentMonth, noOfMonths)
		}
	}

	componentDidMount() {
		const { currentMonth, visibleMonths } = this.state;
		const { onMonthChange } = this.props;
		if (onMonthChange) {
			onMonthChange(currentMonth, visibleMonths);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { currentMonth, numberOfMonths } = this.props;

		if (currentMonth && (!currentMonth.isSame(this.state.currentMonth, 'year') || !currentMonth.isSame(this.state.currentMonth, 'month'))) {
			let noOfMonths = numberOfMonths !== undefined? numberOfMonths : 1;
			this.setState({ currentMonth: currentMonth, visibleMonths: this.getVisibleMonths(currentMonth, noOfMonths)});
		}

		if (numberOfMonths !== undefined && prevProps.numberOfMonths !== numberOfMonths) {
			this.setState({ visibleMonths: this.getVisibleMonths(this.state.currentMonth, numberOfMonths)});
		}
	}

	getVisibleMonths(month, numberOfMonths) {
		let months = [];
		let noOfMonths = numberOfMonths? numberOfMonths : 1;
		for (var i = 0; i < noOfMonths; i ++) {
			months.push(Moment(month).startOf('month').add(i, 'months'));
		}

		return months;
	}

	renderBtnPrevMonth(visibleMonths) {
		const { t, minDate } = this.props;

		let month = visibleMonths[0];
		let isPrevBeforeMinDate = false;
		if (minDate) isPrevBeforeMinDate = Moment(month).subtract(1, 'month').isBefore(minDate, 'month');

		return (
			<Button colo="link" className='btn-calendar-control btn-calendar-control-prev btn-img' onClick={() => this.prevMonth()} disabled={isPrevBeforeMinDate}>
				<img src={img_calendar_arrow_left} alt={t('prev_month')} />
			</Button>
		);
	}

	renderBtnNextMonth(visibleMonths) {
		const { t, maxDate } = this.props;

		let month = visibleMonths[visibleMonths.length - 1];
		let isNextAfterMaxDate = false;
		if (maxDate) isNextAfterMaxDate = Moment(month).add(1, 'month').isAfter(maxDate, 'month');

		return (
			<Button colo="link" className='btn-calendar-control btn-calendar-control-next btn-img' onClick={() => this.nextMonth()} disabled={isNextAfterMaxDate}>
				<img src={img_calendar_arrow_right} alt={t('next_month')} />
			</Button>
		);
	}

	renderControls(visibleMonths) {
		let componentBtnNavPrevMonth = this.renderBtnPrevMonth(visibleMonths);
		let componentBtnNavNextMonth = this.renderBtnNextMonth(visibleMonths);

		let controls = (
			<div className="controls">
				{componentBtnNavPrevMonth}
				{componentBtnNavNextMonth}
			</div>
		);

		const { renderCalendarControls } = this.props;
		if (renderCalendarControls) {
			controls = renderCalendarControls(visibleMonths, componentBtnNavPrevMonth, componentBtnNavNextMonth);
		}

		return controls;
	}

	renderMonthTitle(month) {
		return (
			<div className="month-title">
				<span>
					<span className="big">{month.format('MM')}</span>
					<span className="stroke">|</span>
					{month.format('MMM YYYY')}
				</span>
			</div>
		);
	}

	renderMonthHeader(month) {
		let componentMonthTitle = this.renderMonthTitle(month);

		let header = (
			<div className="month-header">
				{componentMonthTitle}
			</div>
		);
		
		const { renderMonthHeader } = this.props;
		if (renderMonthHeader) {
			header = renderMonthHeader(month, componentMonthTitle);
		}

		return header;
	}

	renderWeekday(day) {
		return (
			<Col key={day} xs={{ size: true }} className="col-days">
				{day.format('ddd')}
			</Col>
		);
	}

	renderWeekdays(month) {
		const days = [];

		let day = Moment(month).startOf('week');

		for (let i = 0; i < 7; i++) {
			days.push(this.renderWeekday(day));
			day.add(1, 'days');
		}

		return <Row className="days">{days}</Row>;
	}

	renderDay(month, day) {
		const { renderDay } = this.props;
		if (renderDay) {
			return renderDay(month, day, () => this.onDayClick(day));
		} else {
			let isDisabled = false;
			let isSelected = false;

			const { isDayDisabled } = this.props;
			if (isDayDisabled) isDisabled = isDayDisabled(day);

			const { isDaySelected } = this.props;
			if (isDaySelected) isSelected = isDaySelected(day);

			const isSameMonth = day.isSame(month, 'month');

			return (
				<Col key={day} xs={{ size: true }}>
					<div className={`day ${isSameMonth ? '' : 'outside-month'} ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`} onClick={() => ((isDisabled || !isSameMonth) ? {} : this.onDayClick(day))}>
						<div className="day-number-container">
							<div className="day-number">
								{day.format('D')}
							</div>
						</div>
					</div>
				</Col>
			);
		}
	}

	renderDays(month) {
		const monthStart = Moment(month).startOf('month');
		const monthEnd = Moment(monthStart).endOf('month');
		const startDate = Moment(monthStart).startOf('week');
		let endDate = Moment(monthEnd).endOf('week');

		if (endDate.week() - startDate.week() < 5) {
			endDate = Moment(endDate).add(1, 'weeks');
		}

		const rows = [];

		let days = [];
		let day = startDate;

		while (day.isSameOrBefore(endDate)) {
			for (let i = 0; i < 7; i++) {
				days.push(this.renderDay(month, day));
				day = Moment(day).add(1, 'days');
			}
			rows.push(
				<Row key={day} className="week">
					{days}
				</Row>
			);
			days = [];
		}

		if (rows.length < 6) {
			//add 1 dummy row at the bottom
			days = [];
			for (let i = 0; i < 7; i++) {
				days.push(
					<Col key={`dummy_day_${i}`} xs={{ size: true }}>
						<div className="day dummy">
							<div className="day-number-container">
								<div className="day-number">
									{`00`}
								</div>
							</div>
						</div>
					</Col>
				);
			}
			rows.push(
				<Row key="dummy_week" className="week dummy">
					{days}
				</Row>
			);
		}

		return <div className="days-grid">{rows}</div>;
	}

	prevMonth() {
		if (this.props.onPrevMonth) {
			this.props.onPrevMonth();
			return;
		}

		const { numberOfMonths } = this.props;
		const { currentMonth } = this.state;

		let month = Moment(currentMonth).subtract(1, 'months');

		let noOfMonths = numberOfMonths !== undefined? numberOfMonths : 1;
		let visibleMonths = this.getVisibleMonths(month, noOfMonths);
		this.setState({ currentMonth: month, visibleMonths });

		const { onMonthChange } = this.props;
		if (onMonthChange) {
			onMonthChange(month, visibleMonths);
		}
	}

	nextMonth() {
		if (this.props.onNextMonth) {
			this.props.onNextMonth();
			return;
		}

		const { numberOfMonths } = this.props;
		const { currentMonth } = this.state;

		let month = Moment(currentMonth).add(1, 'months');

		let noOfMonths = numberOfMonths !== undefined? numberOfMonths : 1;
		let visibleMonths = this.getVisibleMonths(month, noOfMonths);
		this.setState({ currentMonth: month, visibleMonths });
		
		const { onMonthChange } = this.props;
		if (onMonthChange) {
			onMonthChange(month, visibleMonths);
		}
	}

	onDayClick(day) {
		const { visibleMonths } = this.state;

		const monthStart = Moment(visibleMonths[0]).startOf('month');
		const monthEnd = Moment(visibleMonths[visibleMonths.length - 1]).endOf('month');

		if (day.isBefore(monthStart, 'month')) {
			this.prevMonth();
		} else if (day.isAfter(monthEnd, 'month')) {
			this.nextMonth();
		}

		const { onDayClick } = this.props;
		if (onDayClick) {
			onDayClick(day);
		}
	}

	render() {
		const { isLoading, error } = this.props;
		const { visibleMonths } = this.state;

		return (
			<div id="calendar">
				<div className="months">
					{this.renderControls(visibleMonths)}
					<Row>
						{visibleMonths.map((month) =>
							<Col key={month} lg={{ size: true }}>
								<div className="month">
									<div className="month-body">
										{this.renderMonthHeader(month)}
										{this.renderWeekdays(month)}
										{this.renderDays(month)}
									</div>
								</div>
							</Col>
						)}
					</Row>
				</div>

				{isLoading && <Loading overlay />}
				{error && <Loading overlay error={error} />}

			</div>
		);
	}s
}

const translation = withNamespaces(['mycalendar', 'default'])(Calendar);
export { translation as Calendar };