import React from "react";
import { Title } from '../../../_components';

export const TermsEN = () => {
	return <>
		<div className="section">
			<Title size="sm" className="section-title">TERMS OF USE</Title>
			<ol>
				<li>
					<Title size="xs">
						These terms
				</Title>
					<ol>
						<li>These terms of use govern your use and access to our services, including our website(s), our application(s), our application programming interfaces (APIs), our notifications and any information or content appearing therein (collectively our <strong>“Platform”</strong>).</li>
						<li>By using our Platform, you agree to these terms regardless of whether you are paying user or a non-paying visitor. If you are using our Platform as a representative of an entity, you are agreeing to these terms on behalf of that entity.</li>
						<li>You should also read our Privacy Policy which sets out how we collect and use your personal information.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						About us and how to contact us
					</Title>
					<ol>
						<li>We are Unolab Innovations Limited, a company registered in Hong Kong, trading as WEDDING UNO.</li>
						<li>For any questions or problems relating to our Platform, our products or services, or these terms, you can contact us by telephoning our customer service team at (852) 37058458 or emailing us at <a href="mailto:info@weddinguno.com">info@weddinguno.com</a>.</li>
						<li>We are the data controller in relation to our Platform and are responsible for your personal data. Please refer to our Privacy Policy which is available at <a href="https://weddinguno.com/policy">https://weddinguno.com/policy</a> for information about what personal data we collect and what we do with it. It is important that you read our privacy policy, which may be updated from time to time, and understand how we use your information and the rights that you have about your information.</li>
						<li>If we have to contact you, we will do so by telephone or by writing to you at the email address or postal address you provided to us.</li>
						<li>When we use the words "writing" or "written" in these terms, this includes emails.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Changes of terms
					</Title>
					<ol>
						<li>We may amend these terms from time to time by posting the updated terms on our Platform. If we make material changes, we will notify you of the changes before they become effective. By continuing to use our Platform and our services after the changes come into effect means that you agree to be bound by the revised policy.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Availability of our services
					</Title>
					<ol>
						<li>We are constantly changing and improving our Platform and the products or services we provide. We may from time to time change or discontinue any of the products or services we offer, or add or remove functionalities or features, and we may suspend or stop certain products, services, functionalities or features altogether. If we discontinue certain products, services, functionalities or features, we will give you advance notice where reasonably possible.</li>
						<li>We may release products, services, functionalities or features that we are still testing and evaluating. We will label such services as “beta”, “preview”, “early access” or “trial” or any words or phrases with similar meanings. You understand that these beta services are not as reliable as other products or services we offer.</li>
						<li>We reserve the right to limit your use of our Platform and the services we provide, including the right to restrict, suspend or terminate your account if we believe you are in breach of these terms or are misusing our Platform or any services we provide.</li>
						<li>We try our best to ensure that our Platform is always available, but we do not guarantee that the operation of or access to our Platform will be uninterrupted or continuous. Our Platform may be interrupted for maintenance, repairs, upgrades, network or equipment failures.</li>
						<li>You are responsible for configuring your information technology, computer programmes and platform or system in order to access our Platform. We do not guarantee that our Platform will be free from bugs or viruses.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Your account and password
					</Title>
					<ol>
						<li>In registering for an account on our Platform, you must provide truthful, accurate and up-to-date information about yourself. You should choose a strong and secure password. You must keep your password secure and confidential.</li>
						<li>You agree not to share your account credentials or give others access to your account. If and when we detect that an account is shared by multiple users, we may treat this as a security breach and suspend or terminate your account.</li>
						<li>We have the right to disable any password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you are in breach of these terms.</li>
						<li>You agree to follow our Acceptable Use Policy, which is set out at the end of these terms.</li>
						<li>You are responsible for all actions or activities that happens by, through or under your account, unless you report misuse.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Use of the platform
					</Title>
					<ol>
						<li>You must comply with the Acceptable Use Policy and all applicable laws and regulatory requirements, including privacy laws and intellectual property laws in using or accessing the Platform.</li>
						<li>Subject to your payment of applicable fees, we give you a personal, worldwide, royalty-free, non-assignable, non-transferrable, non-sublicensable, non-exclusive and revocable licence to access and use our Platform, including any software or application as part of the services we offer. This licence is for the sole purpose of enabling you to use and enjoy the benefit of our Platform as provided by us and in the manner as permitted by these terms.</li>
						<li>This licence to use our Platform will terminate if you do not comply with these terms or other additional terms or conditions imposed by us from time to time.</li>
						<li>You must not copy, modify, distribute, sell, lease, loan or trade any access to the Platform or any data or information on it.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Your rights
					</Title>
					<ol>
						<li>You retain your rights to any information or content you submit, post or display on or through the Platform (<strong>“Your Content”</strong>). By submitting, posting or displaying such content, you grant us a worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, process, copy, reproduce, adapt, modify, publish, transmit, display and distribute such content in any and all media or through any distribution channels (now known or later developed), subject to the applicable provisions in our Privacy Policy.</li>
						<li>You are responsible for your use of Your Content and any consequences thereof, including any consequences of the use of Your Content by other users or third parties. We are not responsible or liable for any use of Your Content, nor the use of any content or information submitted or posted by other users or visitors.</li>
						<li>You warrant that Your Content is not and will not infringe rights of any third parties and that you have all the necessary rights, power and authority to satisfy your obligations with regard to Your Content under these terms.</li>
						<li>If you believe your intellectual property rights have been infringed, please contact us by emailing us at <a href="mailto:info@weddinguno.com">info@weddinguno.com</a> or call our customer service team at (852) 37058458.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Our rights
					</Title>
					<ol>
						<li>All intellectual property rights subsisting in the Platform or the products or services we provide belong to us or have been lawfully licensed to us. All rights under applicable laws are hereby reserved. You must not upload, post, publish, reproduce, transmit or distribute any content or component of our Platform in any way, or create any derivative works with respect to any such content or component.</li>
						<li>We may (at our discretion but are not obliged to) review content or information submitted or posted by users on our Platform. We reserve the right to remove any content which we consider as offensive, harmful, deceptive, discriminative, defamatory or otherwise inappropriate or misleading, or content that we believe may be infringing rights of third parties. We do not endorse or support any views expressed by any users on our Platform.</li>
						<li>Our name “WEDDING UNO” and our marks and logos are our trade marks (be it registered or unregistered) and may not be used without our express prior written consent.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Integrations
					</Title>
					<ol>
						<li>We integrate with third-party software to provide a full suite of functionalities to our users. We are not responsible for any issues or loss arising from the use of any third-party software. Your access and use of the third-party software is governed by the terms of service or user agreements of that software.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Feedback
					</Title>
					<ol>
						<li>We value and welcome feedback on our Platform. You agree that we are free to use, disclose, adopt and/or modify any feedback and any information (including any ideas, concepts, proposals, suggestions or comments) provided by you to use in connection with our Platform or any products or services we offer, without any payment to you.</li>
						<li>You hereby waive and agree to waive any rights to claim for any fees, royalties, charges or other payments in relation to our use, disclosure, adoption and/or modification of any of your feedback.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Limitation on liabilities
					</Title>
					<ol>
						<li>Some countries or jurisdictions may not allow the disclaimers in this clause, in which case these disclaimers will not apply to you.</li>
						<li>
							To the fullest extent permitted by law, we (including our holding company(ies), subsidiaries, affiliates, directors, officers, employees, agents, representatives, partners and licensors (collectively, <strong>“Our Entities”</strong>)) expressly limit our liabilities in connection with or arising out of the provision of the Platform as follows:
							<ol>
								<li>we provide the Platform and any products or services we offer on an “as is” and “as available” basis, and your access to or use of our Platform is at your own risk;</li>
								<li>we give no assurance, representation or warranty of any kind (whether express or implied) about the Platform and any products or services we provide;</li>
								<li>we do not guarantee that the information or content you find on the Platform is always accurate, truthful, complete and up-to-date;</li>
								<li>we expressly disclaim all warranties and representations (for example, warranties of merchantability, fitness for a particular purpose, and non-infringement);</li>
								<li>we are not responsible for any delay or disruption in our Platform or any defect, viruses, bugs or errors; and</li>
								<li>we are not responsible for the conduct of or any content or information submitted or posted by any user of the Platform (whether online or offline).</li>
							</ol>
						</li>
						<li>
							To the fullest extent permitted by law, Our Entities are not liable to you or others for:
							<ol>
								<li>any indirect, incidental, special, exemplary, consequential or punitive damages; or</li>
								<li>any loss of data, business, opportunities, reputation, profits or revenues,</li>
								<li>relating to the use of our Platform or any products or services we offer.</li>
							</ol>
						</li>
						<li>We do not exclude or limit our liability to you where it would be illegal to do so. This includes any of our liability for fraud or making fraudulent misrepresentation in operating the Platform or providing the products or services we offer.</li>
						<li>If you are using the Platform as a consumer, in some countries or jurisdictions you may have certain legal rights as a consumer. In such cases, nothing in these terms limit your legal rights as a consumer that may not be waived by contract.</li>
						<li>Other than the types of liabilities that we cannot limit by law, the liabilities of Our Entities to you (on aggregate) are limited to the amount you have paid us (if any) for the use of our Platform or for any products or services we offer over the last twelve (12) months.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Your representation
					</Title>
					<ol>
						<li>Our Platform is not intended for and may not be used by minors. By using our Platform, you represent that you are an adult and that you are able to legally enter into contractual agreements.</li>
						<li>If you are using the Platform on behalf of an entity, by using the Platform you represent that you have the necessary rights and authority to agree to these terms (and our Privacy Policy, Acceptable Use Policy and other documents referred to herein) on behalf of that entity.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Indemnity
					</Title>
					<ol>
						<li>You agree to indemnify and hold Our Entities harmless from and against all liabilities, damages, claims, costs (including legal fees and costs), and expenses in connection with or arising from (i) your breach of these terms, (ii) your use of our Platform and/or (iii) any misrepresentation made by you.</li>
						<li>You also agree to fully co-operate with us in the defence or settlement of any claim in relation to or arising out of our Platform or these terms.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Termination
					</Title>
					<ol>
						<li>These terms will continue to apply until terminated by either you or us as follows.</li>
						<li>You may stop using the Platform any time by deactivating your account.</li>
						<li>
							We reserve the right to suspend or terminate your access to our Platform, if we reasonably believe:
							<ol>
								<li>you are in serious or repeated breach of these terms (including a prolonged failure to settle any payment);</li>
								<li>you are using the Platform in a manner that would cause a real risk of harm or loss to us, other users, or the public;</li>
								<li>we are requested to do so by government or regulatory authorities or as required under applicable laws, regulations or legal processes; or</li>
								<li>our provision of the Platform to you is no longer possible or commercially viable.</li>
							</ol>
							In any of the above cases, we will notify you by the email address associated with your account or at the next time you attempt to access your account, unless we are prohibited from notifying you by law.
						</li>
						<li>Upon termination of your access, these terms will also terminate except for Clauses 11 to 18.</li>
						<li>Where we consider necessary or appropriate, we will report any breach of these terms (or the Acceptable Use Policy) to law enforcement authorities and we will cooperate with such authorities by disclosing your identity and providing any information about you within our systems to them.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Entire agreement
					</Title>
					<ol>
						<li>These terms constitute the entire agreement between any user and us in relation to the use of or any transactions on the Platform. These terms supersede and extinguish all other agreements, promises, assurances, warranties, representations and understandings between any user and us, whether written or oral, in relation to the use of or any transactions on the Platform.</li>
						<li>You acknowledge that you will have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these terms.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Other important terms
					</Title>
					<ol>
						<li>We may transfer our rights and obligations under these terms to another organisation. We will contact you to let you know if we plan to do this. If you do not wish to continue the contract with the transferee, you may contact us to end the contract within one (1) calendar month of us informing you of the proposed transfer and we will refund you any payments you have made in advance for any products not provided.</li>
						<li>You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</li>
						<li>If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
						<li>Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the products, we can still require you to make the payment at a later date.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Contact
					</Title>
					<ol>
						<li>If you have any questions about these terms or the Acceptable Use Policy, please contact us by <a href="mailto:info@weddinguno.com">info@weddinguno.com</a>.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Governing law and jurisdiction
					</Title>
					<ol>
						<li>These terms are governed by and shall be construed in accordance with the laws of the Hong Kong Special Administrative Region.</li>
						<li>The courts of the Hong Kong Special Administrative Region shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these terms.</li>
						<Title size="xs">
							Acceptable Use Policy
						</Title>
						<li>
							As part of the terms of use, you agree not to misuse the Platform or help anyone else to do so. For example, you agree not to do any of the following in connection with the Platform:
							<ol>
								<li>use our Platform for unlawful or unauthorised purposes;</li>
								<li>re-sell or attempt to benefit in a commercial fashion from any data, content or information available on the Platform;</li>
								<li>probe, scan, or test the vulnerability of any system or network;</li>
								<li>breach or otherwise circumvent any security or authentication measures or service use limits;</li>
								<li>access, tamper with, or use non-public areas or parts of the Platform;</li>
								<li>interfere with or disrupt any user, host, or network, for example by sending a virus, trojan, worm, logic bomb, or any other material that is malicious or technologically harmful, overloading, flooding, spamming, or mail-bombing any part of the Platform, or by scripting the creation of any content in such manner as to interfere with or create an undue burden on the Platform;</li>
								<li>reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Platform or any related technology that is not open source;</li>
								<li>access, search, or create accounts for the Platform by any means (automated or otherwise) other than our publicly supported interfaces (for example, "scraping" or creating accounts in bulk) or attempt to do so;</li>
								<li>send unsolicited communications, promotions or advertisements, or spam;</li>
								<li>forge any TCP/IP packet header or any part of the header information in any email;</li>
								<li>send altered, deceptive, or false source-identifying information, including "spoofing" or "phishing";</li>
								<li>conduct surveys, contests, or pyramid schemes, or promote or advertise products or services without appropriate authorisation;</li>
								<li>abuse referrals or promotions;</li>
								<li>post, publish, upload, display, distribute, or share materials that are unlawful, inappropriate, profane, pornographic, obscene, indecent, libelous, defamatory, abusive, or knowingly false, and/or that infringe intellectual property rights;</li>
								<li>violate the letter or spirit of our terms of use;</li>
								<li>violate applicable laws or regulations in any way; or</li>
								<li>violate the privacy or infringe the rights of others.</li>
							</ol>
						</li>
					</ol>
				</li>
			</ol>
			<p>Last updated: 6 May 2019</p>
		</div>
		<hr/>
		<div className="section">
			<Title size="sm" className="section-title">TERMS AND CONDITIONS FOR ONLINE MARKETPLACE</Title>
			<ol>
				<li>
					<Title size="xs">
						These terms
					</Title>
					<ol>
						<li>WEDDING UNO is a marketplace that allows users to offer, sell and buy Wedding-related Service (<strong>“Products”</strong>). These terms and conditions govern your use of our website, software and applications provided by us (collectively, our <strong>“Platform”</strong>).</li>
						<li>Please read these terms carefully before you submit any order on our Platform. These terms tell you who we are, the conditions on which you may be a seller or a buyer on our Platform, what to do if there is a problem, and other important information. If you think that there is a mistake in these terms or require any changes, please contact us to discuss.</li>
						<li><strong>By using our Platform, you agree to these terms.</strong> If you are using our Platform as a representative of an entity, you are agreeing to these terms on behalf of that entity.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Information about us and how to contact us
					</Title>
					<ol>
						<li>We are Unolab Innovations Limited, a company registered in Hong Kong, trading as WEDDING UNO.</li>
						<li>For any questions or problems relating to our Platform, our Products or these terms, you can contact us by telephoning our customer service team at (852) 37058458 or email us at <a href="mailto:info@weddinguno.com">info@weddinguno.com</a>.</li>
						<li>We are the data controller in relation to our Platform and are responsible for your personal data. Please see Clause 13 and our privacy policy which is available at <a href="https://weddinguno.com/policy">https://weddinguno.com/policy</a> for information about what personal data we collect and what we do with it. It is important that you read our privacy policy, which may be updated from time to time, and understand how we use your information and the rights that you have about your information.</li>
						<li>If we have to contact you, we will do so by telephone or by writing to you at the email address or postal address you provided to us.</li>
						<li>When we use the words "writing" or "written" in these terms, this includes emails.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Contracts for sale
					</Title>
					<ol>
						<li>We are a marketplace that allows users to offer, sell and buy Products. The actual contract for sale of Products is directly between the seller and the buyer. <strong>We are not liable to any person in relation to an offer for sale, sale, or purchase of any Product listed on our Platform. We are not responsible for the enforcement of any contractual obligations between any buyer and any seller.</strong></li>
						<li>We have no control over and we do not guarantee the existence, quality, safety or legality of the items offered or advertised on our Platform, the accuracy, completeness or truth of any content or listings posted by users, the credit worthiness of any user, the ability of sellers (‘Service Providers’) to sell or buyers to buy, or that a particular buyer and seller will complete a particular transaction.</li>
						<li>We are not involved in any transaction between a buyer and a seller on our Platform save that we facilitate a marketplace for buyers and sellers and process payments on behalf of sellers.</li>
						<li>We may (at our discretion but are not obliged to) check, audit or monitor the information contained in listings, comments and/or reviews posted by users.</li>
						<li>A buyer may place orders on the Platform as instructed on our Platform. A seller's acceptance of a buyer's order will take place when they email the buyer to accept it, at which point a contract will come into existence between the seller and the buyer.</li>
						<li>We will assign an order number to each order. Please tell us the order number whenever you contact us about your order.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Contracts for sale
					</Title>
					<ol>
						<li>We are a marketplace that allows users to offer, sell and buy Products. The actual contract for sale of Products is directly between the seller and the buyer. <strong>We are not liable to any person in relation to an offer for sale, sale, or purchase of any Product listed on our Platform. We are not responsible for the enforcement of any contractual obligations between any buyer and any seller.</strong></li>
						<li>We have no control over and we do not guarantee the existence, quality, safety or legality of the items offered or advertised on our Platform, the accuracy, completeness or truth of any content or listings posted by users, the credit worthiness of any user, the ability of sellers (‘Service Providers’) to sell or buyers to buy, or that a particular buyer and seller will complete a particular transaction.</li>
						<li>We are not involved in any transaction between a buyer and a seller on our Platform save that we facilitate a marketplace for buyers and sellers and process payments on behalf of sellers.</li>
						<li>We may (at our discretion but are not obliged to) check, audit or monitor the information contained in listings, comments and/or reviews posted by users.</li>
						<li>A buyer may place orders on the Platform as instructed on our Platform. A seller's acceptance of a buyer's order will take place when they email the buyer to accept it, at which point a contract will come into existence between the seller and the buyer.</li>
						<li>We will assign an order number to each order. Please tell us the order number whenever you contact us about your order.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Terms and conditions for sale
					</Title>
					<ol>
						<li>If you list an item on our Platform, you agree to comply with our rules for listing, content policies and selling practices from time to time in force. <strong>You are responsible for the accuracy, completeness and truth of the content of the listing and the Product offered</strong>. We reserve the right to modify, suspend or delete any listing that violates our rules, policies and practices, or to improve user experience.</li>
						<li>We have full discretion in determining the appearance, placement and order of listings in search and browse results. The factors we may consider in making such a determination may include the buyer and seller's respective location, the search terms used, and the buyer and seller's respective history on the Platform.</li>
						<li>All sellers on the Platform must clearly state the service details.</li>
						<li>You are responsible in keeping any content and information posted accurate and up to date. You should delete any listings that are no longer available.</li>
						<li>You must comply with all applicable laws and regulations in providing, delivering or rendering the Products that you offer or sell.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Terms and conditions for purchase
					</Title>
					<ol>
						<li>When purchasing a Product, you agree to comply with our applicable rules and policies from time to time in force. <strong>You are responsible for reading the full listing before committing to buy or making a bid for any Product</strong>.</li>
						<li>Any order placed or bid submitted in respect of a Product is deemed irrevocable and unconditional. In placing an order or submitted a bid you agree to the Seller's Terms set out in the seller's listing for the Product.</li>
						<li>You enter into a legally binding contract to buy a Product when you commit to buy a Product, your order for a Product is accepted, or if you have the winning bid for a Product (or your bid for a Product is otherwise accepted).</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Content
					</Title>
					<ol>
						<li>When providing content on or to our Platform, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable right to use, reproduce, process, adapt, modify, publish, display and distribute such content for the purposes of providing the Products to you and the promotion of our Platform.</li>
						<li>
							For all content that you provide, you warrant that:
							<ol>
								<li>the content is accurate, up to date and not misleading;</li>
								<li>you own or otherwise control all necessary rights to meet your obligations under these terms regarding such content; and</li>
								<li>the use of such content does not and will not infringe any intellectual property rights of any third party.</li>
							</ol>
						</li>
						<li>While we try to offer reliable information, we do not promise that any content or information provided on our Platform will be accurate, complete, up to date or always available. If and where we offer catalogs of listings, such catalogs may include product images, descriptions and specifications provided by users or other third parties.</li>
						<li>If you are buying Products, you agree that we are not responsible for examining or warranting any listings provided by any users. If you are selling Products, it is your responsibility to review the content of your listings for accuracy.</li>
						<li>We do not take any responsibility, nor do we assume any liability, for any content provided by you or any third party. You agree not to hold us liable for any inaccurate or misleading content.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Financial Terms for Buyers
					</Title>
					<ol>
						<li>The Services are stated to be subject to instant confirmation. Other than these Services, any required time for confirmation as stated on the Website is solely for reference only. You agree that the time it takes to confirm the success of your bookings/orders may be adjusted based on the actual situation.</li>
						<li>Through this Website / Mobile Application, you may purchase packages from WEDDING UNO for the Services (Packages) offered by the Service Providers in the various destinations. Subject to the policy of the relevant Service Providers, you will receive an email confirmation of your purchase that will contain an order and package order number ("Order ID").</li>
						<li>In order to use your package booked, you must appear in person at the meeting point designated by the relevant Service Providers on time, and present such documents and/or information as may be required by the Service Providers, that may include your Order ID and/or your order information and personal information. If you fail to appear on time or to provide the required documents or information, no refunds will be granted.</li>
						<li>A Service Provider may also require you to provide an identification document in order to use your packages booked. Neither WEDDING UNO nor the Service Providers is responsible for lost, destroyed or stolen information or order numbers. The Packages will be void if the relevant Services to be provided are prohibited by law. If you attempt to use a package in an unlawful manner (e.g., you attempt to shoot pre-wedding photography in an illegal place, or a place which pre-wedding photography is prohibited), the respective Service Provider may refuse to accept your request, and no refunds will be granted.</li>
						<li>The Packages purchased are one-time events, the date(s) on which the packages can be used will be stated in the order details. If you do not use your package on or by the date(s) noted on such order details, except as expressly set forth therein, no refunds will be granted</li>
						<li>You may cancel your package booking by contacting WEDDING UNO customer service within the cancelation period, as stated at the time of purchase on the Website. Cancelation windows vary on a case by case basis. A package canceled with the required notice will be refunded to the credit card you used to purchase such package or by bank transfer. You fully acknowledge and agree that the amount refunded may differ from the amount you originally pay due to the foreign exchange rate, or the administration fees or service fees collected by the credit card company or the payment processor or WEDDING UNO's service fees, depending the actual terms stated in different package details.</li>
						<li>The Service Providers, not WEDDING UNO, is the offeror of the Services, to which the Packages correspond to, and is solely responsible for accepting or rejecting any Packages you purchase, as related to all such Services.</li>
						<li>Please directly consult with the Service Providers if you have any enquiries or complaints in respect of the Service you received in connection with your Package. Except as expressly set forth herein, all fees paid for Packages are non-refundable. Prices quoted for Packages are in the currency stated on the Website at the time prior to purchase.</li>
						<li>If you have purchased a package and is canceled by the Service Providers, WEDDING UNO will notify you as soon as reasonably practicable, and will process a refund to the credit card you used to purchase such Package or by bank transfer. You fully acknowledge and agree that the amount refunded may differ from the amount you originally pay due to the foreign exchange rate, or the administration fees or service fees collected by the credit card company or the payment processor or WEDDING UNO's service fees, depending the actual terms stated in different package details.</li>
						<li>WEDDING UNO reserves the right to require payment of fees or charges for any Services offered by WEDDING UNO. You shall pay all applicable fees or charges, as described on this Website in connection with such Services selected by you.</li>
						<li>WEDDING UNO reserves the right to change its price list for fees or charges at any time, upon notice to you, which may be sent to you by email or posted on this Website. Your use, or continued use, of the Services offered by WEDDING UNO following such notification constitutes your acceptance of any new or revised fees or charges.</li>
						<li>WEDDING UNO reserves the right to deny and cancel bookings or purchases of any Services that are deemed in violation of this policy. Such a determination is at WEDDING UNO's sole discretion.</li>
						<li>WEDDING UNO intends to offer or procure the Service Providers to offer the Services to you at the best price available on the market. You acknowledge and agree that all taxes and additional fees for particular Services that may be payable for using the Services are expressly excluded in determining the best price.</li>
						<li>Whilst the Service Providers are required to provide WEDDING UNO with accurate and updated prices of the Services on this Website, WEDDING UNO cannot guarantee that all prices for the Services provided by the Service Providers are accurate and updated at all times.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Financial Terms for Service Providers
					</Title>
					<ol>
						<li>You agreed that in order to provide the service for the package that the customers booked, you must appear in person at the meeting point designated on time. If you fail to appear on time or to provide the required services, no payments will be granted. And the payments made by the customers will be refunded.</li>
						<li>You agree that in order to control the quality of the service to be provided, if the service level is not up to standard, which WEDDING UNO will have our sole discretion, to terminate the orders / packages booked and have the rights to refund the payments to the customers.</li>
						<li>WEDDING UNO reserves the right to deny and cancel bookings or purchases of any Services that are deemed in violation of this policy. Such a determination is at WEDDING UNO's sole discretion.</li>
						<li>You are required to provide WEDDING UNO with accurate and updated prices of the Services on this Website, WEDDING UNO cannot guarantee that all prices for the Services provided by the Service Providers are accurate and updated at all times.</li>
						<li>WEDDING UNO reserves the right to terminate or suspend the Services Providers Accounts that are deemed in violation of this policy. Such a determination is at WEDDING UNO's sole discretion.</li>
						<li>Payouts
							<ol>
								<li>When the service package has been booked by the buyers, there will be a full refund period, as stated at the time of purchase on the Website, which may vary on a case by case basis. Within the refund period, the buyers are allowed to cancel purchases with fund refund. No payments will be granted to the services providers.</li>
								<li>When the purchase from client has passed the refund period, a deposit, as stated at the time of purchase on the Website, which may vary on a case by case basis, will be paid to service providers by bank transfer.</li>
								<li>When the service has provided the service to the client and completed the details on the purchased package, the remaining amount of the package will be paid to service providers by bank transfer.</li>
								<li>If the service providers fail to provide the service stated in the packages that the buyers purchased, the received deposit must pay back to WEDDING UNO. Such a determination is at WEDDING UNO's sole discretion.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Discounts
					</Title>
					<ol>
						<li>WEDDING UNO Coupons are coupons with a one-time use that will be sent to your designated email address or applied directly to your WEDDING UNO Account or by other authorized means as determined at WEDDING UNO's sole discretion., which may be used in exchange for discounts off future bookings on the WEDDING UNO Platform.</li>
						<li>In the event where it has come to WEDDING UNO’s attention that the WEDDING UNO Coupons were earned in a fraudulent manner, in a manner that violates this Terms of Use or in a manner otherwise not intended by WEDDING UNO, WEDDING UNO reserves the right to the following: (a) Termination of your WEDDING UNO Account with immediate effect; or (b) Cancelation of all WEDDING UNO Coupons as previously accrued; or (c) Refusal of the provision of the Services to you; or (d) Any other measures as deemed appropriate by WEDDING UNO at its sole discretion.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Price and payment
					</Title>
					<ol>
						<li>The price of the Product will be the price indicated on the order pages when a buyer places his order. Each seller must take all reasonable care to ensure that the price of the Product advised is correct.&nbsp;</li>
						<li>It is always possible that, despite best efforts by the seller, some Products may be incorrectly priced on the Platform. If the correct price for the Product at a buyer's order date is less than the price stated to the buyer, the seller will charge the lower amount; but if it's higher than the price stated to the buyer, the seller will contact the buyer for instructions before accepting the buyer's order.</li>
						<li>A buyer must pay for the Products by such time and in such manner as specified in the Seller's Terms.</li>
						<li>If a buyer thinks an invoice is wrong, please contact the seller promptly to let them know.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Our responsibility for loss or damage suffered by you
					</Title>
					<ol>
						<li>
							Subject to Clause 12.2:
							<ol>
								<li>all warranties, conditions, or terms relating to fitness for purpose, quality, or condition of the Products, whether express or implied by statute or common law or otherwise, are expressly excluded;</li>
								<li>we shall not be liable to you (whether in contract, tort or otherwise) for any loss of profit or any indirect or consequential loss arising from or in connection with the provision of the Products; and</li>
								<li>our total liability to you for all losses arising from or in connection with the use of the Platform shall be limited to the price of the relevant Products sold to you on our Platform.</li>
							</ol>
						</li>
						<li>
							Nothing in these terms will limit or exclude our liability for:
							<ol>
								<li>death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors (where applicable);</li>
								<li>fraud or fraudulent misrepresentation; or</li>
								<li>any matter in respect of which it would be unlawful for us to exclude or restrict liability.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Use of your personal information
					</Title>
					<ol>
						<li>When you register or otherwise use our Platform, you provide us with personal data which is collected and used in accordance with the terms of our Privacy Policy which is accessible at https://weddinguno.com/policy. It is important that you read our privacy policy, which may be updated from time to time, and understand how we use your information and the rights that you have about your information.</li>
						<li>In the event you process personal data relating to a buyer or a seller for the purpose of effecting a transaction or otherwise using the Platform, you confirm and undertake that you will only process such personal data for the sole purpose of participating in the marketplace on the Platform and (where applicable) to supply the Products to buyers and you will apply all adequate technical and organisational measures to keep such personal data secure.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Entire agreement
					</Title>
					<ol>
						<li>These terms constitute the entire agreement between any user and us in relation to the use of the Platform. These terms supersede and extinguish all other agreements, promises, assurances, warranties, representations and understandings between any user and us, whether written or oral, in relation to the use of the Platform.</li>
						<li>You acknowledge that you will have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these terms.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Other important terms
					</Title>
					<ol>
						<li>We may transfer our rights and obligations under these terms to another organisation. We will contact you to let you know if we plan to do this. If you do not wish to continue the contract with the transferee, you may contact us to end the contract within one (1) calendar month of us informing you of the proposed transfer and we will refund you any payments you have made in advance for Products not provided.</li>
						<li>You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</li>
						<li>If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
						<li>Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the Products, we can still require you to make the payment at a later date.</li>
					</ol>
				</li>
				<li>
					<Title size="xs">
						Governing law and jurisdiction
					</Title>
					<ol>
						<li>The above terms and conditions & return policies are applicable to all WEDDING UNO users worldwide.</li>
						<li>These terms are governed by and shall be construed in accordance with the laws of the Hong Kong Special Administrative Region.</li>
						<li>The courts of Hong Kong Special Administrative Region shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these terms.</li>
					</ol>
				</li>
			</ol>
			<p>Last updated: 10 May 2019</p>
		</div>
	</>;
}