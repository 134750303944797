
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';

import { CardsContainer } from '../CardsContainer';
import { Title } from '../Title';
import { Desc } from '../Desc';

import './ReceiptPrintable.scss';

import img_logo from './img/logo_with_text.svg';

class ReceiptPrintable extends Component {
	render() {
		const { t, className, header, body } = this.props;

		return (
			<div id="receipt_printable" className={className}>
				<CardsContainer>
					<Row className="row-header">
						<Col lg={{ size: true, order: 1 }} xs={{ order: 2 }} className="col-header">
							{header}
						</Col>
						<Col lg={{ size: 'auto', order: 2 }} xs={{ order: 1 }} className="col-company">
							<div className="company-details">
								<div className="logo">
									<img src={img_logo} alt="logo"/>
								</div>
								<div className="name">
									{t('weddinguno')}
								</div>
								<div className="details">
									{t('weddinguno_address')}
								</div>
							</div>
						</Col>
					</Row>
					<hr />
					{body}
					<hr />
					<div className="tnc">
						<Title size="xs">
							{t('tnc.title')}
						</Title>
						<Desc>
							{t('tnc.desc')}
						</Desc>
					</div>
				</CardsContainer>
			</div>
		);
	}
}

const translation = withNamespaces(['receipt', 'default'])(ReceiptPrintable);
export { translation as ReceiptPrintable };