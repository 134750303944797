import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../_components';

import { SavedItems } from './SavedItems';

const SavedItemsTabPathname = ['packages', 'vendor', 'articles'];

export class SavedItemsRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}/:tab(${SavedItemsTabPathname.join('|')})`} component={SavedItems} />
				<PrivateRoute exact path={`${match.path}`} component={SavedItems} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}