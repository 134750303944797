import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Row, Col, Card, CardBody, CardTitle, CardHeader, CardFooter } from 'reactstrap';

import { getPayoutTypeNameByType } from '../../../../_constants';
import { app } from '../../../../_helpers';
import { PaymentSummary } from '../../../../Order';

import './PayoutSummary.scss';

class PayoutSummary extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<div id="vendor_profile_payout_card_payout_summary">
				<PaymentSummary className="card card-secondary card-receipt">
					<CardHeader>
						<CardTitle tag="h4">
							{t(`payout_summary.${getPayoutTypeNameByType(data.type)}`)}
						</CardTitle>
					</CardHeader>
					<CardBody>
						<div className="amount-breakdown">
							<Row>
								<Col xs={{ size: true }}>{`${t('order_number')}${data.order_id} - ${data.order.package_name}`}</Col>
								<Col xs={{ size: 'auto' }}>{app.toCurrencyString(data.currency.value, data.order.package_amount)}</Col>
							</Row>
							{data.order.additional_amount &&
								<Row>
									<Col xs={{ size: true }}>{t('additional_amount')}</Col>
									<Col xs={{ size: 'auto' }}>{app.toCurrencyString(data.currency.value, data.order.additional_amount)}</Col>
								</Row>
							}
							<Row>
								<Col xs={{ size: true }}>{t('service_fee')}</Col>
								<Col xs={{ size: 'auto' }} className="minus-value">{app.toCurrencyString(data.currency.value, data.order.vendor_service_fee)}</Col>
							</Row>
						</div>
					</CardBody>
					<CardFooter>
						<Row className="amount-breakdown total">
							<Col xs={{ size: true }}>
								{t('order_total')}
							</Col>
							<Col xs={{ size: 'auto' }}>
								{app.toCurrencyString(data.currency.value, data.order.revenue)}
							</Col>
						</Row>
					</CardFooter>
					<CardFooter className="payout-total">
						<Row className="amount-breakdown total">
							<Col xs={{ size: true }}>
								{t(`payout_total.${getPayoutTypeNameByType(data.type)}`)}
							</Col>
							<Col xs={{ size: 'auto' }}>
								{app.toCurrencyString(data.currency.value, data.amount)}
							</Col>
						</Row>
					</CardFooter>
					{data.transferred_at &&
						<CardFooter>
							<Row className="date">
								<Col xs={{ size: true }}>{t('transaction_date')}</Col>
								<Col xs={{ size: 'auto' }}>{Moment.utc(data.transferred_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}</Col>
							</Row>
						</CardFooter>
					}
				</PaymentSummary>
			</div>
		);
	}
}

const translation = withNamespaces(['payout', 'receipt', 'payment', 'order', 'package'])(PayoutSummary);
export { translation as PayoutSummary };