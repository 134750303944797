import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, getIn, setIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'reactstrap';
import axios from 'axios';

import { payoutSettingsActions, appActions, alertActions } from '../../../_actions';
import { InputField, InputSelect, Title, Desc, InputItemLabel, InputItem, Loading } from '../../../_components';

import './PayoutSettings.scss';

class PayoutSettings extends Component {
	componentDidMount() {
		this.props.dispatch(payoutSettingsActions.editClear());
		this.props.dispatch(payoutSettingsActions.get());
		this.props.dispatch(appActions.getCurrencies());
	}

	componentDidUpdate(prevProps, prevState) {
		const { payout_settings, t } = this.props;

		if (payout_settings.edit.success && prevProps.payout_settings.edit.success !== payout_settings.edit.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
			this.props.dispatch(payoutSettingsActions.get());
		}

		if (payout_settings.success && prevProps.payout_settings.success !== payout_settings.success) {
			if (payout_settings.data) {
				this.props.dispatch(payoutSettingsActions.getFormInfo({ currency_id: payout_settings.data.currency_id }));
			}
		}
	}
	
	onSubmit(values, formikBag) {
		this.props.dispatch(payoutSettingsActions.edit(values));
	}

	render() {
		const { t, app, payout_settings_form_info, payout_settings } = this.props;

		let initialValues = {
			currency_id: '',
			accountHolderName: '',
		};

		if (payout_settings.data) {
			initialValues.currency_id = payout_settings.data.currency_id;
			initialValues.type = payout_settings.data.payout_gateway_info.type;
			initialValues.accountHolderName = payout_settings.data.payout_gateway_info.accountHolderName;

			let details = payout_settings.data.payout_gateway_info.details;
			for (var propName in details) {
				if (details[propName] === null || details[propName] === undefined) {
					delete details[propName];
				}
			}
			initialValues.details = details;
		}

		const schema = Yup.object().shape({
			currency_id: Yup.string()
				.required(t('required')),
			accountHolderName: Yup.string()
				.required(t('required'))
		});

		return (
			<div id="vendor_profile_payout_settings">
				{(payout_settings.loading || app.currency.loading) && <Loading />}
				{(payout_settings.error || app.currency.error) && <Loading error={payout_settings.error? payout_settings.error : app.currency.error} />}
				{payout_settings.success && app.currency.list &&
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
					>
						{({ values,
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							setFieldTouched,
							setFieldError,
							resetForm
							/* and other goodies */
						}) => (
								<Form>
									<Row className="row-title">
										<Col xs={{ size: true }}>
											<Title>{t('payout_settings.title')}</Title>
										</Col>
										<Col xs={{ size: 'auto' }} className="display-desktop">
											<Button
												block
												type="submit"
												disabled={payout_settings_form_info.loading || payout_settings.edit.loading || Object.keys(errors).length !== 0 || (!payout_settings.data && Object.keys(touched).length === 0)}
												onClick={handleSubmit}>
												{t('save')}
											</Button>
										</Col>
									</Row>
									<Desc>{t('payout_settings.desc')}</Desc>
									<InputItem>
										<Row>
											<Col lg={{ size: 3 }}>
												<InputItemLabel>{t('currency_id')}</InputItemLabel>
											</Col>
											<Col lg={{ size: 6 }}>
												<InputSelect
													name="currency_id" t={t}
													options={app.currency.list}
													isSearchable={false}
													validate={() => {
														if (!getIn(values, 'currency_id')) {
															return t('required');
														}

														return undefined;
													}}
													onChange={(name, value) => {
														this.props.dispatch(payoutSettingsActions.getFormInfo({ currency_id: value }));

														resetForm();
														setFieldValue(name, value);
														setFieldValue('type', undefined);
														setFieldValue('details', undefined);
													}}
												/>
											</Col>
										</Row>
									</InputItem>

									<div className="bank-info">
										{payout_settings_form_info.loading && <Loading />}
										{payout_settings_form_info.error && <Loading error={payout_settings_form_info.error} />}
										{payout_settings_form_info.data &&
											<>
												<Title key="title" size="sm">{t('bank_transfer_information')}</Title>
												<InputItem key="data_type">
													<Row>
														<Col lg={{ size: 3 }}>
															<InputItemLabel>{t('type')}</InputItemLabel>
														</Col>
														<Col lg={{ size: 9 }}>
															<InputSelect
																name={`type`}
																placeholder={t('type')}
																options={payout_settings_form_info.data}
																optionValueKey="type"
																optionLabelKey="title"
																isSearchable={true}
																validate={() => {
																	if (!getIn(values, 'type')) {
																		return t('required');
																	}

																	return undefined;
																}}
																onChange={(name, value) => {

																}}
															/>
														</Col>
													</Row>
												</InputItem>
												{

													payout_settings_form_info.data.map((object, index) => {
														let inputFields = [];
														if (values.type && object.type === values.type) {
															object.fields.map((item, index) => {
																item.group.map((groupItem, index) => {
																	switch (groupItem.type) {
																		case 'select':
																			if (groupItem.valuesAllowed) {
																				inputFields.push(
																					<InputItem key={`${item.name}.${groupItem.key}.${index}`}>
																						<Row>
																							<Col lg={{ size: 3 }}>
																								<InputItemLabel>{groupItem.name}</InputItemLabel>
																							</Col>
																							<Col lg={{ size: 9 }}>
																								<InputSelect
																									name={`details.${groupItem.key}`}
																									placeholder={groupItem.name}
																									options={groupItem.valuesAllowed}
																									optionValueKey="key"
																									optionLabelKey="name"
																									isSearchable={true}
																									validate={() => {
																										if (groupItem.required && !getIn(values, `details.${groupItem.key}`)) {
																											return t('required');
																										}

																										return undefined;
																									}}
																									onBlur={(name) => {
																										if (groupItem.refreshRequirementsOnChange) {
																											this.props.dispatch(payoutSettingsActions.getFormInfo(values));
																										}
																									}}
																								/>
																							</Col>
																						</Row>
																					</InputItem>
																				);
																			}
																			break;
																		case 'radio':
																			if (groupItem.valuesAllowed) {
																				inputFields.push(
																					<InputItem key={`${item.name}.${groupItem.key}.${index}`}>
																						<Row>
																							<Col lg={{ size: 3 }}>
																								<InputItemLabel>{groupItem.name}</InputItemLabel>
																							</Col>
																							<Col lg={{ size: 9 }}>
																								<InputSelect
																									name={`details.${groupItem.key}`}
																									placeholder={groupItem.name}
																									options={groupItem.valuesAllowed}
																									optionValueKey="key"
																									optionLabelKey="name"
																									isSearchable={true}
																									validate={() => {
																										if (groupItem.required && !getIn(values, `details.${groupItem.key}`)) {
																											return t('required');
																										}

																										return undefined;
																									}}
																									onBlur={(name) => {
																										if (groupItem.refreshRequirementsOnChange) {
																											this.props.dispatch(payoutSettingsActions.getFormInfo(values));
																										}
																									}}
																								/>
																							</Col>
																						</Row>
																					</InputItem>
																				);
																			}
																			break;
																		case 'text':
																			inputFields.push(
																				<InputItem key={`${item.name}.${groupItem.key}.${index}`}>
																					<Row>
																						<Col lg={{ size: 3 }}>
																							<InputItemLabel>{groupItem.name}</InputItemLabel>
																						</Col>
																						<Col lg={{ size: 9 }}>
																							<InputField
																								type="text"
																								name={`details.${groupItem.key}`}
																								placeholder={`${t('example')}${groupItem.example}`}
																								validate={(value) => {
																									if (groupItem.required && !value) return t('required');

																									if (groupItem.validationAsync) {
																										return axios.get(`${groupItem.validationAsync.url}?${groupItem.validationAsync.params[0].key}=${value}`)
																											.then(response => {
																												return undefined;
																											})
																											.catch(error => {
																												let errorMsg = error.response.data.errors.map((err) => err.message);
																												return errorMsg.join('. ');
																											});
																									} else if (groupItem.validationRegexp) {
																										if (!value.match(groupItem.validationRegexp)) {
																											return t('invalid_format');
																										}

																										return undefined;
																									} else {
																										return undefined;
																									}
																								}}
																								onBlur={(name) => {
																									if (groupItem.refreshRequirementsOnChange) {
																										this.props.dispatch(payoutSettingsActions.getFormInfo(values));
																									}
																								}}
																							/>
																						</Col>
																					</Row>
																				</InputItem>
																			);
																			break;
																		default:
																			break;
																	}
																})
															});
														}

														if (inputFields.length) {
															inputFields.push(
																<InputItem key="accountHolderName">
																	<Row>
																		<Col lg={{ size: 3 }}>
																			<InputItemLabel>{t('accountHolderName')}</InputItemLabel>
																		</Col>
																		<Col lg={{ size: 9 }}>
																			<InputField type="text" name="accountHolderName" t={t} />
																		</Col>
																	</Row>
																</InputItem>
															);
														}

														return inputFields;
													})
												}
											</>
										}
									</div>
									<InputItem className="input-item-footer display-responsive">
										<Button
											block
											type="submit"
											disabled={payout_settings_form_info.loading || payout_settings.edit.loading || Object.keys(errors).length !== 0 || (!payout_settings.data && Object.keys(touched).length === 0)}
											onClick={handleSubmit}>
											{t('save')}
										</Button>
									</InputItem>
								</Form>
							)}
					</Formik>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { app, payout_settings_form_info, payout_settings } = state;
	return {
		app, payout_settings_form_info, payout_settings
	};
}

const translation = withNamespaces(['payout', 'default'])(PayoutSettings);
const connected = connect(mapStateToProps)(translation);
export { connected as PayoutSettings };