import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import "react-tabs/style/react-tabs.css";

import { alertActions, payoutSettingsActions, vendorPackageActions } from '../../_actions';
import { history } from '../../_helpers';
import { Loading, InputFormLeaveConfirmPrompt, Title, PageContainer, LoadingPage, ErrorPage } from '../../_components';

import './CreatePackage.scss';

import { BasicInfo } from './BasicInfo';
import { LocationPortfolio } from './LocationPortfolio';
import { PackageIncluded } from './PackageIncluded';

class CreatePackage extends Component {
	initialStateWithProps(props) {
		const isDraft = props.match.path.startsWith('/vendor/package/create');

		return {
			isDraft: isDraft,
			tabIndex: 0,
			draft: {
				isDraft: isDraft,
				timestamp: props.timestamp,
				current_step: 0,
				check: true,
				video_url: '',
				video_image: undefined,
				video_image_url: '',
				name: '',
				currency_id: '',
				amount: '',
				package_type_id: '',
				desc: '',
				shoot_day: 0,
				shoot_hour: undefined,
				category_id: '',
				destination_id: '',
				timezone_id: '',
				locations: [],
				edited_photo: false,
				edited_photo_amount: '',
				original_photo: false,
				original_photo_amount: '',
				photo_book: false,
				photo_book_amount: '',
				photo_book_page: '',
				video_highlight: false,
				video_highlight_amount: '',
				free_shipping: false,
				remarks: '',
				delivery_day: '',
				outfit_makeup: false,
				outfit_makeup_desc: '',
				outfit_hairstyling: false,
				outfit_hairstyling_desc: '',
				outfit_clothes: false,
				outfit_clothes_desc: '',
				outfit_accessory: false,
				outfit_accessory_desc: '',
				outfit_other: false,
				outfit_other_desc: '',
				service_transport: false,
				service_transport_desc: '',
				service_translator: false,
				service_translator_desc: '',
				service_wedding_assistant: false,
				service_wedding_assistant_desc: '',
				service_other: false,
				service_other_desc: '',
			},
			stepChecked: {
				1: !isDraft,
				2: !isDraft,
				3: !isDraft
			},
			disableLeaveConfirmPrompt: false
		};
	}

	constructor(props) {
		super(props);

		this.state = this.initialStateWithProps(props);
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		const { isDraft } = this.state;
		if (id) {
			this.props.dispatch(vendorPackageActions.get(isDraft, id)); //will also clear edit, save ...
		} else {
			this.props.dispatch(vendorPackageActions.getClear()); //will also clear edit, save ...
		}

		this.props.dispatch(vendorPackageActions.getFormInfo());
		this.props.dispatch(payoutSettingsActions.get());
	}

	componentDidUpdate(prevProps, prevState) {
		const { vendor_package, payout_settings, t, location } = this.props;
		const { draft } = this.state;

		//location.key seems will change when route re-render or wt
		if (location.key && prevProps.location.key && prevProps.location.key !== location.key) {
			this.setState(this.initialStateWithProps(this.props));
		}

		if (payout_settings.success && prevProps.payout_settings.success !== payout_settings.success) {
			if (payout_settings.data) {
				let { draft } = this.state;
				draft.currency_id = payout_settings.data.currency_id;
				this.setState({ draft }, () => {
					this.handleServerResponseData(vendor_package.data, true);
				});
			}
		}

		if (vendor_package.save.success && prevProps.vendor_package.save.success !== vendor_package.save.success) {
			this.handleServerResponseData(vendor_package.save.data);

			if (!draft.check) {
				this.props.dispatch(alertActions.success(t('alert.success')));
			}
		}

		if (vendor_package.edit.success && prevProps.vendor_package.edit.success !== vendor_package.edit.success) {
			if (draft.isDraft) {
				this.handleServerResponseData(vendor_package.edit.data);
			} else {
				//back to My Packages
				history.push('/vendor/me/package');
			}

			if ((!draft.check && draft.isDraft) || !draft.isDraft) {
				this.props.dispatch(alertActions.success(t('alert.success')));
			}
		}

		if (vendor_package.success && prevProps.vendor_package.success !== vendor_package.success) {
			this.handleServerResponseData(vendor_package.data, true);
		}

		if (vendor_package.remove.success && prevProps.vendor_package.remove.success !== vendor_package.remove.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
			history.push(draft.isDraft? '/vendor/me/package/drafts' : '/vendor/me/package');
		}
	}

	componentWillUnmount() {
		this.props.dispatch(vendorPackageActions.saveClear(this.state.isDraft));
	}

	handleServerResponseData(responseData, init = false) {
		if (!responseData) {
			return;
		}
		
		//check = true -> Next
		//check = false -> Save Draft
		let draft = this.state.draft;

		let check = init? false : draft.check;
		let currentStep = responseData.current_step;
		
		let stepChecked = this.state.stepChecked;
		const isDraft = responseData.isDraft;
		
		if (isDraft) {
			let steps = Object.keys(stepChecked);
			for (var i = 0; i < steps.length; i++) {
				var key = steps[i];
				stepChecked[key] = currentStep >= key;
			}
		}
		
		this.mergeServerResponseToLocalDraft(responseData, draft);
		this.clearObjectFilesInDraft(draft);

		if (check && currentStep === 1 && !draft['locations'].length) {
			draft['locations'] = [{name: '', items:[]}];
		}

		let tabIndex = this.state.tabIndex;
		if (isDraft) {
			//proceed to next step if checked
			tabIndex = check ? currentStep : (currentStep - 1);
		}

		if (isDraft && tabIndex >= Object.keys(stepChecked).length) {
			//go to preview

			this.setState({
				draft,
				stepChecked,
				disableLeaveConfirmPrompt: true
			}, () => {
				if (this.state.resetForm) this.state.resetForm();
				window.scrollTo(0, 0);
				history.push(`/vendor/package/create/${draft.id}/preview`);
			});
			
		} else {
			this.setState({
				draft,
				tabIndex,
				stepChecked
			}, () => {
				if (this.state.resetForm) this.state.resetForm();
				window.scrollTo(0, 0);
			});
		}
	}

	mergeServerResponseToLocalDraft(responseData, draft) {
		const keyIdFromObject = ['currency', 'destination', 'category', 'package_type', 'timezone'];

		for (var key in responseData) {
			if (responseData[key] !== null) {
				draft[key] = responseData[key];

				if (keyIdFromObject.includes(key)) {
					draft[`${key}_id`] = responseData[key]['id'];
				}
				if (key === 'shoot_day') {
					draft['shoot_day'] = Number(responseData[key]);
					draft['shoot_hour'] = undefined;
				}
				if (key === 'shoot_hour') {
					draft['shoot_hour'] = Number(responseData[key]);
					draft['shoot_day'] = undefined;
				}
			}
		}
	}

	clearObjectFilesInDraft(draft) {
		for (var key in draft) {
			if (draft[key] !== null) {
				if (Array.isArray(draft[key])) {
					for (var i = 0; i < draft[key].length; i ++) {
						this.clearObjectFilesInDraft(draft[key][i]);
					}
				} else if (Object.prototype.toString.call(draft[key]) === '[object File]' && draft[`${key}_url`].length) {
					//only delete file if file_url exist (ie. already submitted)
					delete draft[key];
				}
			} else {
				delete draft[key];
			}
		}
	}

	onSelectTab(tabIndex) {
		const id = this.props.match.params.id;
		const { isDraft } = this.state;
		if (!isDraft && id) {
			this.setState({ tabIndex: tabIndex });
		}
	}

	onSubmit(values, resetForm) {
		// values.check = false => save draft
		// values.check = true => next/submit

		values['current_step'] = this.state.tabIndex + 1;

		this.setState({ draft: values, resetForm }, () => {
			if (values.id) {
				this.props.dispatch(vendorPackageActions.edit(values.isDraft, values));
			} else {
				this.props.dispatch(vendorPackageActions.save(values.isDraft, values));
			}
			
		});
	}

	onBack() {
		const { tabIndex } = this.state;
		if (tabIndex) {
			this.setState({ tabIndex: tabIndex - 1});
		} else {
			history.push('/vendor/me/package');
		}
	}

	onDelete() {
		const { t, match } = this.props;
		const id = match.params.id;
		const { isDraft } = this.state;

		if (window.confirm(t('confirm_to_delete', { n: t(isDraft? 'draft' : 'package')}))) {	
			this.props.dispatch(vendorPackageActions.remove(isDraft, id));
		}
	}

	getFormSchemaWithStep(step) {
		const { t, payout_settings, vendor_package } = this.props;
		const amountMax = payout_settings.data? payout_settings.data.currency.max_value : 999999;
		const amountMin = payout_settings.data? payout_settings.data.currency.min_value : 200;

		const locationsMax = vendor_package.form_info.data? vendor_package.form_info.data.max_location : 3;
		const locationItemsMax = vendor_package.form_info.data? vendor_package.form_info.data.max_photo_per_location : 10;

		const step1Schema = Yup.object().shape({
			video_url: Yup.string().required(t('required')),
			video_image: Yup.mixed()
				.test('video_image', t('required'), function (value) {
					const { video_image_url } = this.parent;
					return video_image_url || value;
				}),
			video_image_url: Yup.string()
				.test('video_image_url', t('required'), function (value) {
					const { video_image } = this.parent;
					return video_image || value;
				}),
			name: Yup.string().required(t('required')),
			currency_id: Yup.number().required(t('required')),
			amount: Yup.number().min(amountMin).max(amountMax).required(t('required')),
			package_type_id: Yup.number().required(t('required')),
			desc: Yup.string().required(t('required')),
			shoot_day: Yup.number()
				.test('shoot_day', t('shooting_days_hours_required'), function (value) {
					const { shoot_hour } = this.parent;
					return shoot_hour || value;
				}),
			shoot_hour: Yup.number()
				.test('shoot_hour', t('shooting_days_hours_required'), function (value) {
					const { shoot_day } = this.parent;
					return shoot_day || value;
				}),
			category_id: Yup.number().required(t('required')),
			destination_id: Yup.number().required(t('required')),
			timezone_id: Yup.number().required(t('required')),
		});
		
		const step2Schema = Yup.object().shape({
			video_url: Yup.string().required(t('required')),
			video_image: Yup.mixed()
				.test('video_image', t('required'), function (value) {
					const { video_image_url } = this.parent;
					return video_image_url || value;
				}),
			video_image_url: Yup.string()
				.test('video_image_url', t('required'), function (value) {
					const { video_image } = this.parent;
					return video_image || value;
				}),
			name: Yup.string().required(t('required')),
			currency_id: Yup.number().required(t('required')),
			amount: Yup.number().min(amountMin).max(amountMax).required(t('required')),
			package_type_id: Yup.number().required(t('required')),
			desc: Yup.string().required(t('required')),
			shoot_day: Yup.number()
				.test('shoot_day', t('shooting_days_hours_required'), function (value) {
					const { shoot_hour } = this.parent;
					return shoot_hour || value;
				}),
			shoot_hour: Yup.number()
				.test('shoot_hour', t('shooting_days_hours_required'), function (value) {
					const { shoot_day } = this.parent;
					return shoot_day || value;
				}),
			category_id: Yup.number().required(t('required')),
			destination_id: Yup.number().required(t('required')),
			timezone_id: Yup.number().required(t('required')),
			locations: Yup.array()
				.max(locationsMax)
				.of(Yup.object().shape({
					name: Yup.string()
						.required(t('required')),
					items: Yup.array()
						.max(locationItemsMax)
						.of(Yup.object().shape({
							photo: Yup.mixed()
								.test('photo', t('required'), function (value) {
									const { photo_url } = this.parent;
									return photo_url || value;
								}),
							photo_url: Yup.string()
								.test('photo_url', t('required'), function (value) {
									const { photo } = this.parent;
									return photo || value;
								}),
							is_cover: Yup.boolean()
								.required(t('required')),
						})).required(t('required')),
				}))
				.required(t('required'))
		});
		
		const step3Schema = Yup.object().shape({
			video_url: Yup.string().required(t('required')),
			video_image: Yup.mixed()
				.test('video_image', t('required'), function (value) {
					const { video_image_url } = this.parent;
					return video_image_url || value;
				}),
			video_image_url: Yup.string()
				.test('video_image_url', t('required'), function (value) {
					const { video_image } = this.parent;
					return video_image || value;
				}),
			name: Yup.string().required(t('required')),
			currency_id: Yup.number().required(t('required')),
			amount: Yup.number().min(amountMin).max(amountMax).required(t('required')),
			package_type_id: Yup.number().required(t('required')),
			desc: Yup.string().required(t('required')),
			shoot_day: Yup.number()
				.test('shoot_day', t('shooting_days_hours_required'), function (value) {
					const { shoot_hour } = this.parent;
					return shoot_hour || value;
				}),
			shoot_hour: Yup.number()
				.test('shoot_hour', t('shooting_days_hours_required'), function (value) {
					const { shoot_day } = this.parent;
					return shoot_day || value;
				}),
			category_id: Yup.number().required(t('required')),
			destination_id: Yup.number().required(t('required')),
			timezone_id: Yup.number().required(t('required')),
			locations: Yup.array()
				.max(locationsMax)
				.of(Yup.object().shape({
					name: Yup.string()
						.required(t('required')),
					items: Yup.array()
						.max(locationItemsMax)
						.of(Yup.object().shape({
							photo: Yup.mixed()
								.test('photo', t('required'), function (value) {
									const { photo_url } = this.parent;
									return photo_url || value;
								}),
							photo_url: Yup.string()
								.test('photo_url', t('required'), function (value) {
									const { photo } = this.parent;
									return photo || value;
								}),
							is_cover: Yup.boolean()
								.required(t('required')),
						})).required(t('required')),
				}))
				.required(t('required')),
			edited_photo: Yup.boolean().required(t('required'))
				.test('edited_photo', t('package_includes_output_error'), function (value) {
					const { edited_photo, original_photo, photo_book, video_highlight } = this.parent;
					return edited_photo || original_photo || photo_book || video_highlight;
				}),
			edited_photo_amount: Yup.number()
				.min(1)
				.test('edited_photo_amount', t('required'), function (value) {
					const { edited_photo } = this.parent;
					return edited_photo ? value : true;
				}),
			original_photo: Yup.boolean().required(t('required'))
				.test('edited_photo', t('package_includes_output_error'), function (value) {
					const { edited_photo, original_photo, photo_book, video_highlight } = this.parent;
					return edited_photo || original_photo || photo_book || video_highlight;
				}),
			original_photo_amount: Yup.number()
				.min(1)
				.test('original_photo_amount', t('required'), function (value) {
					const { original_photo } = this.parent;
					return original_photo ? value : true;
				}),
			photo_book: Yup.boolean().required(t('required'))
				.test('edited_photo', t('package_includes_output_error'), function (value) {
					const { edited_photo, original_photo, photo_book, video_highlight } = this.parent;
					return edited_photo || original_photo || photo_book || video_highlight;
				}),
			photo_book_amount: Yup.number()
				.min(1)
				.test('photo_book_amount', t('required'), function (value) {
					const { photo_book } = this.parent;
					return photo_book ? value : true;
				}),
			photo_book_page: Yup.number()
				.min(1)
				.test('photo_book_page', t('required'), function (value) {
					const { photo_book } = this.parent;
					return photo_book ? value : true;
				}),
			video_highlight: Yup.boolean().required(t('required'))
				.test('edited_photo', t('package_includes_output_error'), function (value) {
					const { edited_photo, original_photo, photo_book, video_highlight } = this.parent;
					return edited_photo || original_photo || photo_book || video_highlight;
				}),
			video_highlight_amount: Yup.number()
				.min(1)
				.test('video_highlight_amount', t('required'), function (value) {
					const { video_highlight } = this.parent;
					return video_highlight? value : true;
				}),
			free_shipping: Yup.boolean().required(t('required')),
			remarks: Yup.string(),
			delivery_day: Yup.number().required(t('required')),
			outfit_makeup: Yup.boolean().required(t('required')),
			outfit_makeup_desc: Yup.string(),
			outfit_hairstyling: Yup.boolean().required(t('required')),
			outfit_hairstyling_desc: Yup.string(),
			outfit_clothes: Yup.boolean().required(t('required')),
			outfit_clothes_desc: Yup.string(),
			outfit_accessory: Yup.boolean().required(t('required')),
			outfit_accessory_desc: Yup.string(),
			outfit_other: Yup.boolean().required(t('required')),
			outfit_other_desc: Yup.string(),
			service_transport: Yup.boolean().required(t('required')),
			service_transport_desc: Yup.string(),
			service_translator: Yup.boolean().required(t('required')),
			service_translator_desc: Yup.string(),
			service_wedding_assistant: Yup.boolean().required(t('required')),
			service_wedding_assistant_desc: Yup.string(),
			service_other: Yup.boolean().required(t('required')),
			service_other_desc: Yup.string(),
		});

		let schema = step3Schema;
		switch (step) {
			case 0:
				schema = step1Schema
				break;
			case 1:
				schema = step2Schema
				break;
			case 2:
				schema = step3Schema
				break;
			default:
				break;
		}

		return schema;
	}
	
	render() {
		const { t, vendor_package, payout_settings } = this.props;
		const id = this.props.match.params.id;
		const { tabIndex, draft, stepChecked, disableLeaveConfirmPrompt, isDraft } = this.state;

		let schema = this.getFormSchemaWithStep(tabIndex);
		const isLoading = vendor_package.form_info.loading || vendor_package.loading || payout_settings.loading;
		const error = vendor_package.form_info.error? vendor_package.form_info.error : vendor_package.error? vendor_package.error : payout_settings.error? payout_settings.error : undefined;

		return (
			<>
				{isLoading && <LoadingPage />}
				{error && <ErrorPage match={this.props.match} error={error} />}
				{(vendor_package.form_info.data && (id? vendor_package.data : true)) && 
					<PageContainer id="vendor_createpackage">
						<Formik
							enableReinitialize
							initialValues={draft}
							validationSchema={schema}
							onSubmit={(values, formikBag) => this.onSubmit(values, formikBag.resetForm)}
						>
							{({ values,
								errors,
								touched,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								setFieldTouched,
								resetForm
								/* and other goodies */
							}) => (
									<Form>
										<Container className="container-lg">
											<Row className="header">
												<Col sm={{ size: true }} className="col-title">
													<Title>
														{t(isDraft ? 'create_package' : 'edit_package')}
													</Title>
												</Col>
												{id &&
													<Col sm={{ size: 'auto' }}>
														<Button
															block
															size="sm"
															color="danger"
															onClick={() => {
																this.onDelete();
															}}
														>
															{t(isDraft ? 'delete_draft' : 'delete_package')}
														</Button>
													</Col>
												}
												<Col sm={{ size: 'auto' }} className="display-desktop">
													<Button
														block
														size="sm"
														disabled={id? false : Object.keys(touched).length === 0}
														onClick={() => {
															values.check = false;
															this.onSubmit(values, resetForm);
														}}
													>
														{t(isDraft ? 'save_draft' : 'save_changes')}
													</Button>
												</Col>
											</Row>
											<Tabs className="steps" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
												<TabList>
													<Tab className={`react-tabs__tab ${tabIndex === 0 ? 'react-tabs__tab--selected' : ''} ${isDraft ? 'steps__tab-draft' : 'steps__tab-package'} ${stepChecked[1] ? 'steps__tab--checked' : ''}`}>
														{`${t('step')}1: ${t('basic_information')}`}
													</Tab>
													<Tab className={`react-tabs__tab ${tabIndex === 1 ? 'react-tabs__tab--selected' : ''} ${isDraft ? 'steps__tab-draft' : 'steps__tab-package'} ${stepChecked[2] ? 'steps__tab--checked' : ''}`}>
														{`${t('step')}2: ${t('location_portfolio')}`}
													</Tab>
													<Tab className={`react-tabs__tab ${tabIndex === 2 ? 'react-tabs__tab--selected' : ''} ${isDraft ? 'steps__tab-draft' : 'steps__tab-package'} ${stepChecked[3] ? 'steps__tab--checked' : ''}`}>
														{`${t('step')}3: ${t('package_included')}`}
													</Tab>
												</TabList>
												<TabPanel>
													<BasicInfo
														formInfo={vendor_package.form_info.data}
													/>
												</TabPanel>
												<TabPanel>
													<LocationPortfolio
														formInfo={vendor_package.form_info.data}
													/>
												</TabPanel>
												<TabPanel>
													<PackageIncluded
														formInfo={vendor_package.form_info.data}
													/>
												</TabPanel>
											</Tabs>
											{isDraft &&
												<Row className="row-btns row-m-8">
													<Col sm={{ size: 'auto', order: 1 }} xs={{ order: 2 }}>
														<Button color="cancel" block onClick={() => this.onBack()}>
															{t('back')}
														</Button>
													</Col>
													<Col sm={{ size: 'auto', order: 2 }} xs={{ order: 1 }}>
														<Button
															block
															disabled={vendor_package.save.loading || vendor_package.edit.loading}
															onClick={() => {
																values.check = true;
																handleSubmit();
															}}
														>
															{t('next')}
														</Button>
													</Col>
												</Row>
											}
											<div className="btn-footer display-responsive">
												<Button
													block
													size="sm"
													disabled={id ? false : Object.keys(touched).length === 0}
													onClick={() => {
														values.check = false;
														this.onSubmit(values, resetForm);
													}}
												>
													{t(isDraft ? 'save_draft' : 'save_changes')}
												</Button>
											</div>
										</Container>
										<InputFormLeaveConfirmPrompt disabled={disableLeaveConfirmPrompt} />
									</Form>
								)}
						</Formik>
					{vendor_package.save.loading && <Loading overlay />}
					{vendor_package.edit.loading && <Loading overlay />}
					{vendor_package.remove.loading && <Loading overlay />}
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_package, payout_settings } = state;
	return {
		vendor_package,
		payout_settings
	};
}

const translation = withNamespaces(['package', 'default'])(CreatePackage);
const connected = connect(mapStateToProps)(translation);
export { connected as CreatePackage };