import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container } from 'reactstrap';

import { Title, Desc } from '../../_components';
import { StaticPageContainer } from '../StaticPageContainer';

import './PrivacyPolicy.scss';

import { PolicyEN } from './Policy';

class PrivacyPolicy extends Component {
	render() {
		const { t, match, app } = this.props;
		let lang = app.language.language.id;

		return (
			<StaticPageContainer id="privacy_policy" match={match}>
				<Container>
					<Title className="title-page">
						{t('privacy_policy')}
					</Title>
					<div className="content">
					{<PolicyEN />}
					</div>
				</Container>
			</StaticPageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['privacypolicy'])(PrivacyPolicy);
const connected = connect(mapStateToProps)(translation);
export { connected as PrivacyPolicy };