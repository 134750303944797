import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container } from 'reactstrap';
import queryString from 'query-string';

import { headerActions, appActions } from '../_actions';
import { PageContainer, Title, LoadingPage, ErrorPage } from '../_components';

import './Chat.scss';

import { Channels } from './Channels';
import { ChatArea } from './ChatArea';

import img_back from './img/topbar_btn_back.svg';

class Chat extends Component {
	constructor(props) {
		super(props);

		const { auth, location } = props;
		let targetChannel = queryString.parse(location.search).target;
		targetChannel = targetChannel === auth.user.id? undefined : targetChannel;

		this.state = {
			sideMenuIsOpen: false,
			targetChannel: targetChannel
		}
	}

	componentDidMount() {
		this.props.dispatch(headerActions.solidStyle(true));
	}

	componentWillUnmount() {
		this.props.dispatch(appActions.showHideFooter(true));
	}

	componentDidUpdate(prevProps, prevState) {
		const { sb_groupChannel, app } = this.props;
		const { sideMenuIsOpen } = this.state

		if (!sb_groupChannel.channel && !sideMenuIsOpen) {
			this.setState({ sideMenuIsOpen: true });
		}

		if (sb_groupChannel.channel && (!prevProps.sb_groupChannel.channel || sb_groupChannel.channel.url !== prevProps.sb_groupChannel.channel.url) && sideMenuIsOpen) {
			this.setState({ sideMenuIsOpen: false });
		}

		const shouldShowFooter = app.windowSize.width >= 992;
		if (shouldShowFooter !== app.showFooter) {
			this.props.dispatch(appActions.showHideFooter(shouldShowFooter));
		}
	}

	toggleSideMenu() {
		const { sb_groupChannel } = this.props;
		if (!sb_groupChannel.channel) return;

		this.setState({ sideMenuIsOpen: !this.state.sideMenuIsOpen });
	}

	render() {
		const { t, sb_login, sb_groupChannel, sb_chat, sb_connection } = this.props;
		const { sideMenuIsOpen, targetChannel } = this.state

		return (
			<>
				{(sb_login.isLoading || sb_connection.isLoading) && <LoadingPage desc={t('connecting_chat')} />}
				{(sb_login.error || sb_connection.error) && <ErrorPage match={this.props.match} error={{ status_code: 1001, message: t('chat_connection_error') }} />}
				{sb_login.success && !sb_connection.isLoading && !sb_connection.error &&
					<PageContainer id="chat">
						<Container className="chat-container">
							<div className="chats">
								<div className="title-toggle">
									<Button color="img" className="btn-toggle-sidemenu" onClick={() => this.toggleSideMenu()}><img src={img_back} alt="" /></Button>
									<Title className="title-page">
										{t('chats')}
									</Title>
								</div>
								<Channels className={`${sideMenuIsOpen ? 'open' : ''}`} t={t} targetChannel={targetChannel} />
							</div>
							<div className="chat">
								<ChatArea channel={sb_groupChannel.channel} chat={sb_chat} t={t} />
							</div>
						</Container>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth, sb_login, sb_groupChannel, sb_chat, sb_connection, app } = state;
	return {
		auth,
		sb_login,
		sb_groupChannel,
		sb_chat,
		sb_connection,
		app
	};
}

const translation = withNamespaces(['chat', 'default'])(Chat);
const connected = connect(mapStateToProps)(translation);
export { connected as Chat };