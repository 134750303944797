import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { InputField, Title, InputItem } from '../../../../_components';

import './NewPassword.scss';

class NewPassword extends Component {
	render() {
		const { t } = this.props;

		const schema = Yup.object().shape({
			password: Yup.string()
				.matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, t('password_remarks'))
				.required(t('required')),
			password_confirmation: Yup.string()
				.oneOf([Yup.ref('password')], t('password_confirmation_not_match'))
				.required(t('required'))
		});

		return (
			<Formik
				enableReinitialize
				initialValues={{
					password: '',
					password_confirmation: ''
				}}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
						<Form id="user_modal_forgotpw_newpw" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('reset_password.title')}</Title>
							</ModalHeader>
							<ModalBody>
								<InputItem>
									<InputField type="password" name="password" t={t} />
									<div className="remarks">{t('password_remarks')}</div>
								</InputItem>
								<InputItem>
									<InputField type="password" name="password_confirmation" t={t} />
								</InputItem>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={this.props.submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</Form>
					)}
			</Formik>
		);
	}
}

const translation = withNamespaces(['resetpassword', 'register', 'default'])(NewPassword);
export { translation as NewPassword };