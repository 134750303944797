import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { Order } from './Order';
import { EditShootingDatetimeHistory } from '../../Order';

const OrderTabPathnames = ['package', 'payments', 'meet_up', 'after_shooting', 'pending_items'];

export class OrderRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}/edit_shooting_datetime_history`} component={EditShootingDatetimeHistory} />
				<PrivateRoute exact path={`${match.path}/:tab(${OrderTabPathnames.join('|')})`} component={Order} />
				<PrivateRoute exact path={`${match.path}`} component={Order} />
				<Redirect to="/vendor/error/404" />
			</Switch>
		);
	}
}