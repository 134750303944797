import React, { Component } from 'react';
import { ErrorMessage, connect, getIn, setIn } from 'formik';
import Select, { components } from 'react-select';
import createFilterOptions from "react-select-fast-filter-options";

import './InputSelect.scss';

import img_arrow_down from '../../_common/img/topbar_arrow_down_black.svg';
import img_arrow_up from '../../_common/img/topbar_arrow_up_black.svg';

const DropdownIndicator = props => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<img src={props.selectProps.menuIsOpen ? img_arrow_up : img_arrow_down} alt="" width="9px" height="5px"/>
			</components.DropdownIndicator>
		)
	);
};

class InputSelect extends Component {
	componentDidMount() {
		this.props.formik.registerField(this.props.name, this);
	}

	componentDidUpdate(prevProps, prevState) {
		const { name, validate } = this.props
		if (name !== prevProps.name) {
			this.props.formik.unregisterField(prevProps.name);
			this.props.formik.registerField(this.props.name, this);
		}

		if (validate !== prevProps.validate) {
			this.props.formik.registerField(this.props.name, this);
		}
	}

	componentWillUnmount() {
		this.props.formik.unregisterField(this.props.name);
	}

	onChange(selectedOption) {
		const { optionValueKey, name, onChange, formik } = this.props;
		const { setFieldValue, setFieldTouched } = formik;

		setFieldTouched(name, true);

		let valueKey = optionValueKey? optionValueKey : 'id';
		let value = undefined;

		if (Array.isArray(selectedOption)) {
			value = selectedOption.map((item) => { return item[valueKey]; });
		} else {
			value = selectedOption[valueKey];
		}

		setFieldValue(name, value);
		if (onChange) onChange(name, value);
	}

	onBlur() {
		const { name, formik, onBlur } = this.props;
		const { setFieldTouched } = formik;

		setFieldTouched(name, true);
		if (onBlur) onBlur(name);
	}

	render() {
		const { t, field_id, name, placeholder, error_id, className, options, groupedOptions, 
			isSearchable, isMulti, isDisabled, optionValueKey, optionLabelKey, hideErrorMessage, formik } = this.props;
		const { touched, errors, values } = formik;

		let value = getIn(values, name);
		let valueKey = optionValueKey? optionValueKey : 'id';
		let labelKey = optionLabelKey? optionLabelKey : 'value';
		let parsedOptions = undefined;
		let allOptions = undefined;

		if (groupedOptions) {
			parsedOptions = groupedOptions.map((group) => { 
				return { label: group.value, options: group.items };
			});
			allOptions = groupedOptions.map((group) => { 
				return group.items;
			});
			allOptions = allOptions.reduce((acc, val) => acc.concat(val), []); //replacement for flat()
		} else {
			parsedOptions = options;
			allOptions = options;
		}
		
		let selectedOption = null;
		if (parsedOptions && value !== undefined) {
			if (Array.isArray(value)) {
				let vals = [];
				value.forEach(val => {
					let matched = allOptions.find(option => (option[valueKey] === val));
					if (matched) { vals.push(matched); }
				});
				selectedOption = vals;
			} else if ((isNaN(value) && value.length) || !isNaN(value)){
				selectedOption = allOptions.find(option => (option[valueKey] === value));
			}
		}

		const filterOptions = createFilterOptions({
			labelKey: labelKey,
			valueKey: valueKey,
			options: parsedOptions
		});

		return (
			<>
				<Select 
				id={field_id} name={name}
				components={{ DropdownIndicator }}
				placeholder={placeholder ? placeholder : t(name)} 
				className={'react-select' + (className? ` ${className}` : '') + (((getIn(touched, name) && getIn(errors, name)) ? " input-error" : ""))} 
				classNamePrefix="react-select"
				filterOptions={filterOptions}
				options={parsedOptions}
				onChange={(selectedOption) => this.onChange(selectedOption)}
				onBlur={() => this.onBlur()}
				value={selectedOption}
				isSearchable={isSearchable}
				isMulti={isMulti}
				isDisabled={isDisabled}
				getOptionLabel={(option) => option[labelKey]}
				getOptionValue={(option) => option[valueKey]}
				/>
				{!hideErrorMessage && <ErrorMessage id={error_id} name={name} component="span" className="error" />}
			</>
		);
	}
}

const connected = connect(InputSelect);
export { connected as InputSelect };