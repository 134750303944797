import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { InputField, Title, Desc, InputItem } from '../../../../_components';

import './InputForm.scss';

class InputForm extends Component {
	schema() {
		const { t } = this.props;
		return Yup.object().shape({
			current_password: Yup.string()
				.required(t('required')),
			password: Yup.string()
				.matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, t('password_remarks'))
				.required(t('required')),
			password_confirmation: Yup.string()
				.oneOf([Yup.ref('password')], t('new_password_confirmation_not_match'))
				.required(t('required')),
		});
	}

	render() {
		const { t } = this.props;

		return (
			<Formik
				enableReinitialize
				initialValues={{
					current_password: '',
					password: '',
					password_confirmation: ''
				}}
				validationSchema={this.schema()}
				onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
						<Form id="user_modal_edit_password_form" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('change_password.title')}</Title>
							</ModalHeader>
							<ModalBody>
								<Desc>{t('change_password.desc')}</Desc>
								<InputItem>
									<InputField type="password" name="current_password" t={t} />
								</InputItem>
								<InputItem>
									<InputField type="password" name="password" t={t} placeholder={t('new_password')} />
									<div className="remarks">{t('password_remarks')}</div>
								</InputItem>
								<InputItem>
									<InputField type="password" name="password_confirmation" t={t} placeholder={t('new_password_confirmation')} />
								</InputItem>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={this.props.submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</Form>
					)}
			</Formik>
		);
	}
}

const translation = withNamespaces(['editpassword', 'register', 'default'])(InputForm);
export { translation as InputForm };