import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

import { InfiniteScrollWrapper } from '../../_components';

import './ReviewsList.scss';

import { Review } from './Review';

class ReviewsList extends Component {
	render() {
		const { t, showHeader, loadMoreProp, onLoadMore } = this.props;

		return (
			<div className="package-reviews">
				{showHeader &&
					<div className="package-reviews-header">
						{t('n_results', { n: loadMoreProp.list.length })}
					</div>
				}
				<InfiniteScrollWrapper
					elementRef={(element) => this.infiniteScrollComponent = element}
					loadMoreProp={loadMoreProp}
					onLoadMore={(nextPage) => onLoadMore(nextPage)}
					renderItems={(items) => {
						return (
							items.map((item, index) =>
								<Review key={item.id} data={item} />
							)
						)
					}}
					noResultDesc={t('no_reviews')}
				/>
			</div>
		);
	}
}

const translation = withNamespaces(['package'])(ReviewsList);
export { translation as ReviewsList };