import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';

import { savedItemsActions } from '../../_actions';
import { history, auth as authHelper, app } from '../../_helpers';
import { packageStatus, savedItemType } from '../../_constants';
import { GeneralGridItemContent } from '../../_components';

import './GridItem.scss';

class GridItem extends Component {
	onBookmark(item) {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			if (item.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsPackage(item.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsPackage(item.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	onClick(item) {
		history.push(`/package/${item.id}`);
	}

	render() {
		const { t, item, saved_item_action, hideBookmark } = this.props;

		const isLoading = saved_item_action.loading && saved_item_action.type == savedItemType.PACKAGE && saved_item_action.id == item.id;
		const is_disabled = item.status !== packageStatus.published;

		return (
			<GeneralGridItemContent className="grid-item-content-package"
				isLoading={isLoading}
				isDisabled={is_disabled}
				disabledTitle={t('unavailable_package')}
				hideBookmark={hideBookmark}
				isBookmarked={item.is_bookmarked}
				onBookmark={() => this.onBookmark(item)}
				onClick={() => this.onClick(item)}
			>
				<div className="img-wrapper">
					<img src={item.video_image_url} alt={item.name} />
				</div>
				<div className="grid-item-package-content">
					<LinesEllipsis
						className="grid-item-title"
						text={item.name}
						maxLine='2'
						ellipsis='...'
						trimRight
						basedOn='words'
					/>
					<div className="grid-item-by">{t('by')} <Link to={`/package/vendor/${item.vendor.id}`} onClick={(e) => e.stopPropagation()}>{item.vendor.nickname}</Link></div>
				</div>
				<div className="grid-item-price">{app.toCurrencyString(item.user_currency.value, item.user_currency_amount)}</div>
			</GeneralGridItemContent>
		);
	}
}

function mapStateToProps(state) {
    const { auth, saved_item_action } = state; 
    return {
		auth,
		saved_item_action
    };
}

const translation = withNamespaces(['package', 'default'])(GridItem);
const connected = connect(mapStateToProps)(translation);
export { connected as GridItem };
