import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';

import { app } from '../../../../_helpers';
import { SwitchButton, RowStroked } from '../../../../_components';
import { vendorPackageActions } from '../../../../_actions';
import { packageStatus, getPackageStatusNameByStatus } from '../../../../_constants';
import { TotalSalesInfoModal } from './TotalSalesInfoModal';

import './Package.scss';

import img_info from './img/order_details_status_icon_question.svg';

class Package extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			totalSalesInfoModalIsOpen: false
		}
	}
	
	onPublishToggle(id, publish) {
		if (window.confirm(`Confirm to ${publish? 'publish' : 'unpublish'} this package?`)) {
			this.props.dispatch(vendorPackageActions.publish(false, id, publish));
		}
	}

	onTotalSalesInfo() {
		this.setState({ totalSalesInfoModalIsOpen: true });
	}

	render() {
		const { t, data } = this.props;
		const { totalSalesInfoModalIsOpen } = this.state;

		return (
			<Row className="package">
				<Col xl={{ size: 8 }} lg={{ size: 7 }}>
					<div className="flex-content">
						<div className="id">
							{`${t('package')} #${data.id}`}
						</div>
						<div className="name">
							{data.name}
						</div>
						<RowStroked className="row-package-details">
							<Col sm={{ size: 'auto', order: 1 }} xs={{ order: 3 }}>
								<div className="price">
									{app.toCurrencyString(data.currency.value, data.amount)}
								</div>
							</Col>
							<Col sm={{ order: 2 }} xs={{ size: 'auto', order: 1 }} className="col-location">
								<div className="location">
									{data.destination.value}
								</div>
							</Col>
							<Col sm={{ order: 3 }} xs={{ size: 'auto', order: 2  }}>
								<div className="no_of_locations">
									{`${data.package_location_count} ${t('locations')}`}
								</div>
							</Col>
						</RowStroked>
						<div className="actions">
							<RowStroked>
								{data.status === packageStatus.suspended &&
									<Col sm={{ size: 'auto' }} className="col-status">
										<div className={`status ${getPackageStatusNameByStatus(data.status)}`}>{t(`status.${getPackageStatusNameByStatus(data.status)}`)}</div>
									</Col>
								}
								{data.status != packageStatus.suspended &&
									<Col sm={{ size: 'auto' }} className="col-status">
										<SwitchButton
											labelOn={t('publish')}
											labelOff={t('unpublish')}
											isOn={data.status === packageStatus.published}
											onChange={(value) => this.onPublishToggle(data.id, value)}
										/>
									</Col>
								}
								<Col xs={{ size: 'auto' }}>
									<Link to={`/vendor/package/${data.id}/edit`}>{t('edit_package')}</Link>
								</Col>
								<Col xs={{ size: 'auto' }}>
									<Link to={`/vendor/package/${data.id}/preview`}>{t('preview_mode')}</Link>
								</Col>
							</RowStroked>
						</div>
					</div>
				</Col>
				<Col xl={{ size: 4 }} lg={{ size: 5 }} className="package-sales">
					<div className="flex-content">
						<Row>
							<Col xs={{ size: true }}>
								<div className="total-sales">
									{t('completed_bookings')}
								</div>
							</Col>
							<Col xs={{ size: 'auto' }}>
								<div className="value">
									{data.completed_order_count}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={{ size: true }}>
								<div className="total-sales">
									{t('total_sales')} <Button color="img" onClick={() => this.onTotalSalesInfo()}><img src={img_info} alt={t('total_sales')} /></Button>
								</div>
							</Col>
							<Col xs={{ size: 'auto' }}>
								{data.package_revenues.map((item, index) =>
									<div key={index} className="value">
										{app.toCurrencyString(item.currency.value, item.total_amount)}
									</div>
								)}
							</Col>
						</Row>
						{data.completed_order_count > 0 &&
							<div className="view-payout-summary">
								<Link to={`/vendor/me/payouts?package_id=${data.id}`}>{t('view_payout_summary')}</Link>
							</div>
						}
					</div>
				</Col>
				<TotalSalesInfoModal
					isOpen={totalSalesInfoModalIsOpen}
					toggle={() => this.setState({ totalSalesInfoModalIsOpen: !this.state.totalSalesInfoModalIsOpen })}
					data={data}
				/>
			</Row>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_package } = state;
	return {
		vendor_package
	};
}

const translation = withNamespaces(['mypackages', 'package'])(Package);
const connected = connect(mapStateToProps)(translation);
export { connected as Package };