import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Container, Row, Col } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import {
	VERTICAL_ORIENTATION,
	HORIZONTAL_ORIENTATION
} from 'react-dates/constants';

import { app as appHelper } from '../../_helpers';
import { packageActions } from '../../_actions';
import { ResultsGrid, Title, InputItem, InputItemLabel, PageContainer } from '../../_components';
import { GridItem } from '../GridItem';

import '../../_common/date_range_picker.scss';
import './Search.scss';

import img_calendar_arrow from '../img/filter_icon_arrow.svg';
import img_calendar_clear from '../img/filter_icon_close.svg';

class Search extends Component {

	constructor(props) {
		super(props);

		this.state = {
			destination: undefined,
			category: undefined,
			price: undefined,
			sorting: undefined,
			calendarStartDate: undefined,
			calendarEndDate: undefined,
			calendarFocusedInput: undefined,
		};	
	}

	componentDidMount() {
		this.props.dispatch(packageActions.searchClear());
		this.props.dispatch(packageActions.getFilterItems());
	}

	componentDidUpdate(prevProps, prevState) {
		const { packages } = this.props;

		if (packages.filter_items.success && packages.filter_items.success !== prevProps.packages.filter_items.success) {
			if (packages.filter_items.data && (!this.state.currency || !this.state.sorting)) {
				let currency = packages.filter_items.data.currency;
				let sorting = packages.filter_items.data.sortings[0];
				this.setState({ currency, sorting });
			}
		}
	}
	
	onSelectDestination(destination) {
		if (this.state.destination === destination) return;
		this.setState({ destination });
	}

	onSelectCategory(category) {
		if (this.state.category === category) return;
		this.setState({ category });
	}

	onSelectPrice(price) {
		if (this.state.price === price) return;
		this.setState({ price });
	}

	onCalendar() {
		if (this.state.calendarFocusedInput) return;
		this.setState({ calendarFocusedInput: 'startDate' });
	}

	onSelectDateRange(startDate, endDate) {
		if (this.state.calendarStartDate === startDate && this.state.calendarEndDate === endDate) return;
		this.setState({ calendarStartDate: startDate, calendarEndDate: endDate });
	}

	onSelectSorting(sorting) {
		if (this.state.sorting === sorting) return;
		this.setState({ sorting }, () => {
			this.onSearch();
		});
	}

	onSearch() {
		this.infiniteScrollComponent.reload();
	}

    render() {
		const { t, packages, app } = this.props;
		const { currency, destination, category, price, calendarStartDate, calendarEndDate, calendarFocusedInput, sorting } = this.state;

		let destinations = [{ id: null, value: t('all_destinations'), items: [] }];
		let categories = [{ id: null, value: t('all_categories'), items: [] }];
		let prices = [{ min_amount: null, max_amount: null, value: t('all_price') }];
		let sortings = [];
		if (packages.filter_items.data) {
			destinations = destinations.concat(packages.filter_items.data.destination_groups);
			categories = categories.concat(packages.filter_items.data.category_groups);
			prices = prices.concat(packages.filter_items.data.price_items);
			sortings = packages.filter_items.data.sortings;
		}

		let showCalendarFromTo = (calendarStartDate || calendarEndDate || calendarFocusedInput);

		const smallDevice = app.windowSize.width < 770;
		const calendarOrientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

		return (
			<PageContainer id="package_search">
				<div className="header">
					<Container>
						<Title>{t('search_package')}</Title>
						<Row>
							<Col xl={{ size: 2 }}>
								<InputItem className="first-input-item">
									<InputItemLabel>{t('destination')}</InputItemLabel>
									<UncontrolledButtonDropdown>
										<DropdownToggle caret className="btn-outline-primary">
											{destination ? destination.value : t('all_destinations')}
										</DropdownToggle>
										<DropdownMenu>
											{destinations.map((group, index) =>
												group.id ?
													<div key={group.id}>
														<DropdownItem header>{group.name}</DropdownItem>
														{group.items.map((item, index) =>
															<DropdownItem key={item.id} onClick={() => this.onSelectDestination(item)}>{item.value}</DropdownItem>
														)}
													</div>
													:
													<DropdownItem key='all' onClick={() => this.onSelectDestination(group)}>{group.value}</DropdownItem>
											)}
										</DropdownMenu>
									</UncontrolledButtonDropdown>
								</InputItem>
							</Col>
							<Col xl={{ size: 2 }}>
								<InputItem>
									<InputItemLabel>{t('category')}</InputItemLabel>
									<UncontrolledButtonDropdown>
										<DropdownToggle caret className="btn-outline-primary">
											{category ? category.value : t('all_categories')}
										</DropdownToggle>
										<DropdownMenu>
											{categories.map((group, index) =>
												group.id ?
													<div key={group.id}>
														<DropdownItem header>{group.name}</DropdownItem>
														{group.items.map((item, index) =>
															<DropdownItem key={item.id} onClick={() => this.onSelectCategory(item)}>{item.value}</DropdownItem>

														)}
													</div>
													:
													<DropdownItem key='all' onClick={() => this.onSelectCategory(group)}>{group.value}</DropdownItem>
											)}
										</DropdownMenu>
									</UncontrolledButtonDropdown>
								</InputItem>
							</Col>
							<Col xl={{ size: 3 }}>
								<InputItem>
									<InputItemLabel>{t('price')}</InputItemLabel>
									<UncontrolledButtonDropdown>
										<DropdownToggle caret className="btn-outline-primary">
											{currency ? price ? price.value ? price.value : `${appHelper.toCurrencyString(currency.value, price.min_amount)} - ${appHelper.toCurrencyString(currency.value, price.max_amount)}` : t('all_price') : t('loading')}
										</DropdownToggle>
										{currency &&
											<DropdownMenu>
												{prices.map((item, index) =>
													item.min_amount ?
														<DropdownItem key={index} onClick={() => this.onSelectPrice(item)}>{`${appHelper.toCurrencyString(currency.value, item.min_amount)} - ${appHelper.toCurrencyString(currency.value, item.max_amount)}`}</DropdownItem>
														:
														<DropdownItem key={index} onClick={() => this.onSelectPrice(item)}>{item.value}</DropdownItem>
												)}
											</DropdownMenu>
										}
									</UncontrolledButtonDropdown>
								</InputItem>
							</Col>
							<Col xl={{ size: 3 }}>
								<InputItem className="last-input-item">
									<InputItemLabel>{t('date_range')}</InputItemLabel>
									{showCalendarFromTo ?
										<DateRangePicker
											startDateId="startDate"
											endDateId="endDate"
											startDate={calendarStartDate}
											endDate={calendarEndDate}
											onDatesChange={({ startDate, endDate }) => this.onSelectDateRange(startDate, endDate)}
											focusedInput={calendarFocusedInput}
											onFocusChange={focusedInput => this.setState({ calendarFocusedInput: focusedInput })}
											showClearDates={true}
											customArrowIcon={<img src={img_calendar_arrow} alt='->' />}
											customCloseIcon={<img src={img_calendar_clear} alt='close' />}
											hideKeyboardShortcutsPanel={true}
											startDatePlaceholderText={t('from')}
											endDatePlaceholderText={t('to')}
											renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (<span><span className="big">{month.format('MM')}</span><span className="stroke">|</span>{month.format('MMM YYYY')}</span>)}
											weekDayFormat='ddd'
											orientation={calendarOrientation}
											withFullScreenPortal={smallDevice}
											readOnly
										/>
										:
										<Button outline className='dropdown-toggle' onClick={() => this.onCalendar()}>{t('all_dates')}</Button>
									}
								</InputItem>
							</Col>
							<Col xl={{ size: 2 }} className="col-submit">
								<Button onClick={() => this.onSearch()}>{t('search')}</Button>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="results-container">
				{packages.total > 0 &&
					<Row className="results-header">
						<Col sm={{ size: true }}>
							{t('n_results', { n: packages.total })}
						</Col>
						<Col sm={{ size: 'auto' }} xs={{ size: true }}>
							<span>{t('sort_by')}</span>
						</Col>
						<Col sm={{ size: 'auto' }} xs={{ size: 'auto' }}>
							<UncontrolledButtonDropdown>
								<DropdownToggle caret className="btn-outline-primary">
									{sorting ? sorting.value : '--'}
								</DropdownToggle>
								<DropdownMenu>
									{sortings.map((item, index) =>
										<DropdownItem key={item.id} onClick={() => this.onSelectSorting(item)}>{item.value}</DropdownItem>
										)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</Col>
					</Row>
				}
					<ResultsGrid
						infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
						disableInitialLoad={true}
						showHeader={false}
						noOfCol_lg={4}
						noOfCol_md={3}
						noOfCol_sm={2}
						loadMoreProp={packages}
						onLoadMore={(nextPage) => {
							const { currency, sorting, category, destination, price } = this.state;
							this.props.dispatch(packageActions.search(currency, sorting, category, destination, price, undefined, undefined, nextPage));
						}}
						renderItem={(item) => {
							return <GridItem item={item} hideBookmark />;
						}}
						noResultDesc={t('no_result_found')}
					/>
				</Container>
			</PageContainer>
		)
	}
}

function mapStateToProps(state) {
    const { packages, app } = state; 
    return {
		packages,
		app
    };
}

const translation = withNamespaces(['package', 'default'])(Search);
const connected = connect(mapStateToProps)(translation);
export { connected as Search };