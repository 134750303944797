import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';

import { userActions } from '../../../_actions';
import { userType } from '../../../_constants';
import { Title, Desc, InputItem } from '../../../_components';

import './AccountProfile.scss';

import { EditEmail, EditPassword, EditPasswordUserType } from '../../../User';

class AccountProfile extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			editEmailModalIsOpen: false,
			editPasswordModalIsOpen: false,
		}
	}

	componentDidMount() {
		this.props.dispatch(userActions.getUser());
	}
	
	onEditEmail() {
		this.setState({ editEmailModalIsOpen: true });
	}

	onEditPassword() {
		this.setState({ editPasswordModalIsOpen: true });
	}

	render() {
		const { t, user } = this.props;
		const { editEmailModalIsOpen, editPasswordModalIsOpen } = this.state;

		return (
			<div id="vendor_profile_account_profile">
				<Title>{t('vendor.account_profile.title')}</Title>
				<Desc>{t('vendor.account_profile.desc')}</Desc>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }}>
							<div>{t('email')}</div>
						</Col>
						<Col lg={{ size: 9 }}>
							<div className="row-inner">
								{user.email}
							</div>
							<div className="row-inner">
								<Button color="link" onClick={() => this.onEditEmail()}>{t('change_email')}</Button>
							</div>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }}>
							<div>{t('password')}</div>
						</Col>
						<Col lg={{ size: 9 }}>
							<Button color="link" onClick={() => this.onEditPassword()}>{t('change_password')}</Button>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }}>
							<div>{t('vendor.user_type')}</div>
						</Col>
						<Col lg={{ size: 9 }}>
							{t(user.user_type === userType.photographer ? 'vendor.photographer' : 'vendor.agency')}
						</Col>
					</Row>
				</InputItem>
				<EditEmail
					user_userType={user.user_type}
					isOpen={editEmailModalIsOpen}
					toggle={() => this.setState({ editEmailModalIsOpen: !this.state.editEmailModalIsOpen })}
				/>
				<EditPassword
					userType={EditPasswordUserType.vendor}
					isOpen={editPasswordModalIsOpen}
					toggle={() => this.setState({ editPasswordModalIsOpen: !this.state.editPasswordModalIsOpen })}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	const { user } = auth;
	return {
		user,
	};
}

const translation = withNamespaces(['profile', 'verify'])(AccountProfile);
const connected = connect(mapStateToProps)(translation);
export { connected as AccountProfile };