import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';

import { orderStatus, getOrderTypeByOrderStatus, orderType } from '../../../_constants';
import { orderActions, alertActions } from '../../../_actions';
import { Title } from '../../../_components';

import './Header.scss';

import { MakeOfferModal } from '../MakeOfferModal';
import { CancelOfferModal } from '../CancelOfferModal';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			makeOfferModalIsOpen: false,
			cancelOfferModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.makeOfferClear());
	}

	onReject() {
		this.setState({ cancelOfferModalIsOpen: true });
	}

	onMakeOffer() {
		const { data, t } = this.props;
		if (!data.is_shoot_datetime_updated) {
			this.props.dispatch(alertActions.error(t('make_offer_no_shoot_time_error')));
			return;
		}

		this.setState({ makeOfferModalIsOpen: true });
	}

	render() {
		const { t, data, accessories, order } = this.props;
		const { makeOfferModalIsOpen, cancelOfferModalIsOpen } = this.state;

		const isDeal = (data && getOrderTypeByOrderStatus(data.status) === orderType.deal);

		return (
			<div id="vendor_order_header">
				<Row className="row-m-8">
					<Col sm={{ size: true }}>
						<Title>
							{t(isDeal ? 'deal_details' : 'order_details')}
						</Title>
					</Col>
					{data.status === orderStatus.requesting && !data.is_expired &&
						<>
							<Col sm={{ size: 'auto' }}>
								<Button color="danger" block onClick={() => this.onReject()}>{t('reject_booking.title')}</Button>
							</Col>
							<Col sm={{ size: 'auto' }}>
								<Button block onClick={() => this.onMakeOffer()}>{t('make_offer.title')}</Button>
							</Col>
						</>
					}
					{accessories? accessories : null}
				</Row>
				<div className="order-no">
					{`${t(isDeal ? 'deal_number' : 'order_number')}${data.id} - ${data.package_name}`}
				</div>
				<MakeOfferModal
					data={data}
					isOpen={makeOfferModalIsOpen}
					isLoading={order.make_offer.loading}
					isSuccess={order.make_offer.success}
					error={order.make_offer.error}
					toggle={() => this.setState({ makeOfferModalIsOpen: !this.state.makeOfferModalIsOpen })}
					onSubmit={() => {
						this.props.dispatch(orderActions.makeOffer(true, data.id));
					}}
					onClosed={() => {
						this.props.dispatch(orderActions.makeOfferClear());
					}}
				/>
				<CancelOfferModal
					data={data}
					isOpen={cancelOfferModalIsOpen}
					toggle={() => this.setState({ cancelOfferModalIsOpen: !this.state.cancelOfferModalIsOpen })}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order', 'default'])(Header);
const connected = connect(mapStateToProps)(translation);
export { connected as Header };