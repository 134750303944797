import SendBird from 'sendbird';

import {
	CREATE_UNREAD_COUNT_HANDLER_SUCCESS,
	CREATE_UNREAD_COUNT_HANDLER_FAIL,
	REMOVE_UNREAD_COUNT_HANDLER_SUCCESS,
	UNREAD_COUNT_UPDATED
} from './types';

export const createUnreadCountHandler = () => {
    return (dispatch) => {
        const sb = SendBird.getInstance();
        let channelHandler = new sb.ChannelHandler();
		channelHandler.onMessageReceived =  (channel, message) => {
			dispatch(updateUnreadCount());
		}; 
		sb.addChannelHandler('UNREAD_COUNT_HANDLER', channelHandler);
		dispatch({ 
			type: CREATE_UNREAD_COUNT_HANDLER_SUCCESS,
		});
        return;
    }
}

export const removeUnreadCountHandler = () => {
	return (dispatch) => {
        const sb = SendBird.getInstance();
		sb.removeChannelHandler('UNREAD_COUNT_HANDLER');
		dispatch({ 
			type: REMOVE_UNREAD_COUNT_HANDLER_SUCCESS,
		});
        return;
    }
}

export const updateUnreadCount = () => {
	return (dispatch) => {
        const sb = SendBird.getInstance();
		sb.getTotalUnreadMessageCount(function(count, error) {
			if (error) {
				return;
			}
			
			dispatch({ 
				type: UNREAD_COUNT_UPDATED,
				count
			});
		});
        return;
    }
}