import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col, Badge } from 'reactstrap';
import { Redirect, Switch } from 'react-router-dom';

import { orderActions } from '../../_actions';
import { history } from '../../_helpers';
import { getOrderTypeByOrderStatus, orderType } from '../../_constants';
import { LoadingPage, ErrorPage, PageContainer, ProfilePicImg, RowStroked, PrivateRoute, Loading } from '../../_components';

import './Order.scss';

import { Details } from './Details';
import { PackageDetailsWrapper } from './PackageDetailsWrapper';
import { PaymentsWrapper } from './PaymentsWrapper';
import { PendingItemsWrapper } from './PendingItemsWrapper';
import { Meetup } from './Meetup';
import { AfterShootingMaterials } from './AfterShootingMaterials';
import { ReportModal } from '../../Order';

class Order extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reportModalIsOpen: false,
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.get(true, this.props.match.params.id));
		this.props.dispatch(orderActions.getPendingItemsCount(true, this.props.match.params.id));
	}

	componentDidUpdate(prevProps, prevState) {
		let shouldReloadOrder = false;
		let shouldReloadPendingItemsCount = false;

		const { match, order } = this.props;

		if (order.success && prevProps.order.success !== order.success) {
			this.setState({ data: order.data });
		}

		if (order.edit.success && prevProps.order.edit.success !== order.edit.success) {
			shouldReloadOrder = true;
		}

		if (order.make_offer.success && prevProps.order.make_offer.success !== order.make_offer.success) {
			shouldReloadOrder = true;
		}

		if (order.cancel.success && prevProps.order.cancel.success !== order.cancel.success) {
			shouldReloadOrder = true;
			shouldReloadPendingItemsCount = true;
		}

		if (order.edit_datetime.success && prevProps.order.edit_datetime.success !== order.edit_datetime.success) {
			shouldReloadOrder = true;
		}

		if (order.pending_item.cancel.order_id == match.params.id && order.pending_item.cancel.success && prevProps.order.pending_item.cancel.success !== order.pending_item.cancel.success) {
			shouldReloadPendingItemsCount = true;
		}

		if (order.pending_item.reply.order_id == match.params.id && order.pending_item.reply.success && prevProps.order.pending_item.reply.success !== order.pending_item.reply.success) {
			shouldReloadPendingItemsCount = true;

			if (order.pending_item.reply.accept) {
				shouldReloadOrder = true;
			}
		}

		//reload order if errors
		if (order.edit.error && prevProps.order.edit.error !== order.edit.error) {
			shouldReloadOrder = true;
		}
		if (order.make_offer.error && prevProps.order.make_offer.error !== order.make_offer.error) {
			shouldReloadOrder = true;
		}
		if (order.cancel.error && prevProps.order.cancel.error !== order.cancel.error) {
			shouldReloadOrder = true;
		}
		if (order.payments.error && prevProps.order.payments.error !== order.payments.error) {
			shouldReloadOrder = true;
		}
		if (order.edit_datetime.error && prevProps.order.edit_datetime.error !== order.edit_datetime.error) {
			shouldReloadOrder = true;
		}
		if (order.edit_datetime_history.error && prevProps.order.edit_datetime_history.error !== order.edit_datetime_history.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_items.error && prevProps.order.pending_items.error !== order.pending_items.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_item.reply.error && prevProps.order.pending_item.reply.error !== order.pending_item.reply.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_item.cancel.error && prevProps.order.pending_item.cancel.error !== order.pending_item.cancel.error) {
			shouldReloadOrder = true;
		}
		if (order.meet_up.error && prevProps.order.meet_up.error !== order.meet_up.error) {
			shouldReloadOrder = true;
		}
		if (order.meet_up.edit.error && prevProps.order.meet_up.edit.error !== order.meet_up.edit.error) {
			shouldReloadOrder = true;
		}
		if (order.materials.error && prevProps.order.materials.error !== order.materials.error) {
			shouldReloadOrder = true;
		}
		if (order.materials.edit.error && prevProps.order.materials.edit.error !== order.materials.edit.error) {
			shouldReloadOrder = true;
		}
		if (order.report.error && prevProps.order.report.error !== order.report.error) {
			shouldReloadOrder = true;
		}



		if (shouldReloadOrder && !order.loading) {
			this.props.dispatch(orderActions.get(true, this.props.match.params.id));
		}

		if (shouldReloadPendingItemsCount) {
			this.props.dispatch(orderActions.getPendingItemsCount(true, this.props.match.params.id));
		}
	}

	onReport() {
		this.setState({ reportModalIsOpen: true });
	}

	onChat() {
		let url = '/vendor/chat';
		const { match, order } = this.props;
		if (match.params.id == order.id && order.data) {
			url = url + '?target=' + order.data.customer.id;
		}
		history.push(url);
	}

	render() {
		const { t, match, order } = this.props;
		const { reportModalIsOpen, data } = this.state;

		const isDeal = (data && getOrderTypeByOrderStatus(data.status) === orderType.deal);

		const baseUrl = match.url.replace('/' + match.params.tab, '');
		const basePath = match.path.indexOf('/:tab') !== -1 ? match.path.substr(0, match.path.indexOf('/:tab')) : match.path;

		return (
			<>
				{!data && order.loading && <LoadingPage />}
				{order.error && <ErrorPage match={this.props.match} error={order.error} />}
				{data && !order.error &&
					<PageContainer id="vendor_order">
						<Container>
							<Row>
								<Col lg={{ size: 3 }} className="left-content">
									<Row className="row-m-8 customer">
										<Col xs={{ size: 'auto' }}>
											<ProfilePicImg src={data.customer.profile_pic_url} alt={data.customer.nickname} />
										</Col>
										<Col xs={{ size: true }}>
											<div className="name">
												{data.customer.nickname}
											</div>
											<RowStroked className="actions">
												<Col xs={{ size: 'auto' }}>
													<Button color="link" onClick={() => this.onReport()}>{t('report')}</Button>
												</Col>
												<Col xs={{ size: 'auto' }}>
													<Button color="link" onClick={() => this.onChat()}>{t('chat')}</Button>
												</Col>
											</RowStroked>
										</Col>
									</Row>
									<div className="menu">
										<Link to={`${baseUrl}`} className={"menu-item " + (!match.params.tab ? 'active' : '')}>{t(isDeal ? 'deal_details' : 'order_details')}</Link>
										<Link to={`${baseUrl}/package`} className={"menu-item " + (match.params.tab === 'package' ? 'active' : '')}>{t('package_details')}</Link>
										{!isDeal &&
											<>
												<Link to={`${baseUrl}/payments`} className={"menu-item " + (match.params.tab === 'payments' ? 'active' : '')}>{t('payment_details')}</Link>
												<Link to={`${baseUrl}/meet_up`} className={"menu-item " + (match.params.tab === 'meet_up' ? 'active' : '')}>{t('meetup_details.title')}</Link>
												<Link to={`${baseUrl}/after_shooting`} className={"menu-item " + (match.params.tab === 'after_shooting' ? 'active' : '')}>{t('after_shooting_materials')}</Link>
											</>
										}
										<Link to={`${baseUrl}/pending_items`} className={"menu-item menu-item-pending-items " + (match.params.tab === 'pending_items' ? 'active' : '')}>
											{t('pending_items')}
											{order.pending_items.pending_total > 0 &&
												<Badge pill>{order.pending_items.pending_total > 1000 ? '999+' : order.pending_items.pending_total}</Badge>
											}
										</Link>
									</div>
								</Col>
								<Col lg={{ size: 9 }} className="right-content">
									<Switch>
										<PrivateRoute exact path={`${basePath}`} render={(props) => <Details {...props} data={data} />} />
										<PrivateRoute exact path={`${basePath}/package`} render={(props) => <PackageDetailsWrapper {...props} data={data} />} />
										<PrivateRoute exact path={`${basePath}/payments`} render={(props) => <PaymentsWrapper {...props} data={data} />} />
										<PrivateRoute exact path={`${basePath}/meet_up`} render={(props) => <Meetup {...props} data={data} />} />
										<PrivateRoute exact path={`${basePath}/after_shooting`} render={(props) => <AfterShootingMaterials {...props} data={data} />} />
										<PrivateRoute exact path={`${basePath}/pending_items`} render={(props) => <PendingItemsWrapper {...props} data={data} />} />
										<Redirect to="/vendor/error/404" />
									</Switch>
								</Col>
							</Row>
						</Container>
						{data && order.loading && <Loading overlay />}
						<ReportModal
							isOpen={reportModalIsOpen}
							order_id={match.params.id}
							toggle={() => this.setState({ reportModalIsOpen: !this.state.reportModalIsOpen })}
						/>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order', 'meetup', 'package', 'report' ,'default'])(Order);
const connected = connect(mapStateToProps)(translation);
export { connected as Order };