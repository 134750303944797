import { api } from '../_helpers';
import axios from 'axios';
import { apiEndpoint, articleConstants } from '../_constants';

export const articleActions = {
	getList,
	getListClear,
	getHotList,
	getHotListClear,
	get,
	getClear
};

function getList(page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.article.list, { page_number, per_page });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: articleConstants.GET_LIST_REQUEST } }
    function success(responseData) { return { type: articleConstants.GET_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: articleConstants.GET_LIST_FAILURE, error } }
}

function getListClear() {
	return { type: articleConstants.GET_LIST_CLEAR };
}

function getHotList() {
	const requestOptions = api.requestOption(apiEndpoint.article.hot_list);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: articleConstants.GET_HOT_LIST_REQUEST } }
    function success(responseData) { return { type: articleConstants.GET_HOT_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: articleConstants.GET_HOT_LIST_FAILURE, error } }
}

function getHotListClear() {
	return { type: articleConstants.GET_LIST_CLEAR };
}

function get(id) {
	var endpoint = Object.assign({}, apiEndpoint.article.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: articleConstants.GET_REQUEST, id } }
    function success(responseData, id) { return { type: articleConstants.GET_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: articleConstants.GET_FAILURE, error, id } }
}

function getClear() {
	return { type: articleConstants.GET_CLEAR }
}