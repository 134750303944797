import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Badge, Container, Row, Col } from 'reactstrap';

import { userActions } from '../../_actions';
import { orderType } from '../../_constants';
import { PrivateRoute, ProfilePicImg, PageContainer, BtnInbox } from '../../_components';

import './MyProfile.scss';

import { AccountProfile } from './AccountProfile';
import { Payouts } from './Payouts';
import { VendorProfileRouter } from './VendorProfile';
import { Orders } from './Orders';
import { PayoutSettings } from './PayoutSettings';

class MyProfile extends Component {
	onLogout() {
		this.props.dispatch(userActions.logout(this.props.auth.user.user_type));
	}

	render() {
		const { t, auth, match } = this.props;
		const { user } = auth;

		const baseUrl = match.url.replace('/' + match.params.tab, '');
		const basePath = match.path.indexOf('/:tab') !== -1 ? match.path.substr(0, match.path.indexOf('/:tab')) : match.path;

		return (
			<>
			<div id="vendor_myprofile_bg"></div>
			<PageContainer id="vendor_myprofile">
				<Container>
					<Row>
						<Col lg={{ size: 4 }} className="content-container left-content">
							<div className="user-profile">
								<ProfilePicImg src={user.profile_pic_url} alt={user.nickname} />
								<div className="nickname">{user.nickname}</div>
								<BtnInbox isVendor={true} />
							</div>
							<div className="menu">
								<Link to={`${baseUrl}`} className={"menu-item account-settings " + (!match.params.tab ? 'active' : '')}>{t('vendor.account_profile.title')}</Link>
								<Link to={`${baseUrl}/payout_settings`} className={"menu-item payout_settings " + (match.params.tab === 'payout_settings' ? 'active' : '')}>{t('vendor.payout_settings')}</Link>
								<Link to={`${baseUrl}/payouts`} className={"menu-item payouts " + (match.params.tab === 'payouts' ? 'active' : '')}>{t('vendor.payout_summary')}</Link>
								<Link to={`${baseUrl}/orders`} className={"menu-item orders " + (match.params.tab === 'orders' ? 'active' : '')}>{t('vendor.order_list')}</Link>
								<Link to={`${baseUrl}/deals`} className={"menu-item deals " + (match.params.tab === 'deals' ? 'active' : '')}>{t('vendor.deal_list')}</Link>
								<Link to={`${baseUrl}/vendor_profile`} className={"menu-item profile " + (match.params.tab === 'vendor_profile' ? 'active' : '')}>{t('vendor.photographer_profile')}</Link>
								<Button color="link" className="menu-item logout" onClick={() => this.onLogout()}>{t('logout')}</Button>
							</div>
						</Col>
						<Col lg={{ size: 8 }} xs={{ size: 12 }} className="content-container right-content">
							<Switch>
								<PrivateRoute exact path={`${basePath}`} component={AccountProfile} />
								<PrivateRoute exact path={`${basePath}/payout_settings`} component={PayoutSettings} />
								<PrivateRoute exact path={`${basePath}/payouts`} component={Payouts} />
								<PrivateRoute path={`${basePath}/vendor_profile`} component={VendorProfileRouter} />
								<PrivateRoute exact path={`${basePath}/orders`} render={props => (
									<Orders key="order" {...props} type={orderType.order} /> //adding key here to force rerender https://stackoverflow.com/a/49001192
								)} />
								<PrivateRoute exact path={`${basePath}/deals`} render={props => (
									<Orders key="deal" {...props} type={orderType.deal} />
								)} />
								<Redirect to='/error/404' />
							</Switch>
						</Col>
					</Row>
				</Container>
			</PageContainer>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['profile', 'default'])(MyProfile);
const connected = connect(mapStateToProps)(translation);
export { connected as MyProfile };