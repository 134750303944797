import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { homeActions } from '../../../_actions';
import { Title, Loading } from '../../../_components';
import { Section } from '../../Section';
import { HomeSwiper } from '../../HomeSwiper';

import './Specials.scss';

class Specials extends Component {
	componentDidMount() {
		this.props.dispatch(homeActions.getSpecialList());
	}

	render() {
		const { t, special } = this.props;

		return (
			<>
				{special.list.loading && <Loading />}
				{special.list.error && <Loading error={special.list.error} />}
				{special.list.list &&
					<Section className="home-section-specials">
						<Title size="xs" className="section-title">{t('customer.specials')}</Title>
						<HomeSwiper swiperParams={{
							slidesPerView: 2,
							hideNavs: special.list.list.length <= 2,
							loop: special.list.list.length > 2,
							hideDots: special.list.list.length <= 2,
							breakpoints: {
								768: {
									slidesPerView: 1,
									hideNavs: special.list.list.length <= 1,
									loop: special.list.list.length > 1,
									hideDots: special.list.list.length <= 1,
								},
							},
						}}>

						{special.list.list.map((item, index) =>
							<Link key={item.id} className="special" to={`/special/${item.id}`}>
								<img src={item.thumbnail_url} alt="" />
								<div className="title-overlay">
									<div className="title-wrapper">
										<Title size="sm">{item.banner_title}</Title>
										<Title size="xs">{item.banner_desc}</Title>
									</div>
								</div>
							</Link>
						)}

						</HomeSwiper>
					</Section>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { special } = state;
	return {
		special,
	};
}

const translation = withNamespaces(['home', 'default'])(Specials);
const connected = connect(mapStateToProps)(translation);
export { connected as Specials };