import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';

import './Order.scss';

class Order extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<div className="vendor-mycalendar-confirmed-orders-order">
				<Link to={`/vendor/me/order/${data.id}`}>
					<LinesEllipsis
						className="name"
						text={`${t('order_number')}${data.id} - ${data.package_name}`}
						maxLine='3'
						ellipsis='...'
						trimRight
						basedOn='words'
					/>
					<div className="info">
						<span className="location">{data.destination.value}</span>
						<span className="stroke">|</span>
						<span className="datetime">{`${Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).format('D MMM YYYY h:mma')} ${data.timezone.offset}`}</span>
					</div>
				</Link>
			</div>
		);
	}
}

const translation = withNamespaces(['order', 'default'])(Order);
export { translation as Order };