import { auth, app } from '../_helpers';
import { apiDomain, userType } from '../_constants';
import axios from 'axios';
import { alertActions, userActions } from '../_actions';
import { apiMethod } from '../_constants';

export const api = {
	init,
	requestOption,
	handleError,
	objectToFormData,
	clearEmptyStringInParams
};

function init() {
	// Add a request interceptor
	axios.interceptors.request.use(function (config) {
		// Do something before request is sent
		console.info('axois send request', config);
		return config;
	}, function (error) {
		// Do something with request error
		console.error('axois send request error', error);
		return Promise.reject(error);
	});

	// Add a response interceptor
	axios.interceptors.response.use(function (response) {
		// Do something with response data
		console.info('axois got response', response);
		return response;
	}, function (error) {
		// Do something with response error
		console.error('axois got response error', error);
		return Promise.reject(error);
	});

	axios.defaults.baseURL = apiDomain;
}

function handleError(error, dispatch, showAlertMessage) {
	const response = error.response;
	let status = undefined;
	const responseData = response? response.data : undefined;

	
	if (response) {
		status = response.status;
		
		if (responseData) {
			status = responseData.status_code;
		}
	}

	console.error('api handleError error: ', error, ' error.response: ', response, ' error.response.data: ', responseData, ' status: ', status);

	if (status) {
		if (status == 401) {
			const loginData = auth.getLogin();
			if (loginData) {
				dispatch(userActions.forceLogout(true, loginData.user.user_type === userType.customer ? '/customer/login' : '/vendor/login'));
			}
		}
	}

	const showErrorAlert = showAlertMessage && (status !== 401 || response.config.url.includes('login'));
	if (showErrorAlert) dispatch(alertActions.error(responseData? responseData.message : 'Error'));

	return responseData? responseData : error;
}

function requestOption(apiEndpoint, data) {
	let _data = this.clearEmptyStringInParams(data);

	const loginData = auth.getLogin();
	let option = {
		method: apiEndpoint.method,
		url: apiEndpoint.path,
		headers: {
			Language: app.getLanguage().id,
			Authorization: loginData? `${loginData.token_type} ${loginData.access_token}` : ''
		}
	};

	switch (apiEndpoint.method) {
		case apiMethod.get:
			option['params'] = _data;
			break;
	
		default:
			option['data'] = _data;
			break;
	}

	return option;
}

function clearEmptyStringInParams(data) {
	for (var key in data) {
		if (Array.isArray(data[key])) {
			for (var i = 0; i < data[key].length; i ++) {
				let item = data[key][i];
				data[key][i] = this.clearEmptyStringInParams(item);
			}
		} else if (((typeof data[key] === 'string') && !data[key].length)) {
			delete data[key];
		}
	}

	return data;
}

function objectToFormData(data, prefix = undefined, formData = undefined, isArray = false) {
	if (!formData) formData = new FormData();

	for (var key in data) {
		if (((typeof data[key] === 'string') && !data[key].length) || data[key] === undefined) continue; //skip empty string

		if (Array.isArray(data[key])) {
			for (var i = 0; i < data[key].length; i ++) {
				let item = data[key][i];
				let keyAppend = isArray? `[${key}]` : key;
				this.objectToFormData(item, `${prefix? prefix : ''}${keyAppend}[${i}]`, formData, true);
			}
		} else if (typeof data[key] === 'boolean') {
			let keyAppend = isArray? `[${key}]` : key;
			formData.append(`${prefix? prefix : ''}${keyAppend}`, data[key]? '1' : '0');
		} else {
			let keyAppend = isArray? `[${key}]` : key;
			formData.append(`${prefix? prefix : ''}${keyAppend}`, data[key]);
		}
	}

	return formData;
}