import { payoutSettingsConstants, userConstants } from '../_constants';

const payoutSettingsFormInfoInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function payout_settings_form_info(state = payoutSettingsFormInfoInitialState, action) {
	switch (action.type) {
		case payoutSettingsConstants.GET_FORM_INFO_CLEAR:
			return { ...payoutSettingsFormInfoInitialState };
		case payoutSettingsConstants.GET_FORM_INFO_REQUEST:
			return { ...payoutSettingsFormInfoInitialState, loading: true };
		case payoutSettingsConstants.GET_FORM_INFO_SUCCESS:
			return { ...payoutSettingsFormInfoInitialState, success: true, data: action.responseData };
		case payoutSettingsConstants.GET_FORM_INFO_FAILURE:
			return { ...payoutSettingsFormInfoInitialState, error: action.error };

		default:
			return state
	}
}

const payoutSettingsEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const payoutSettingsInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	edit: payoutSettingsEditInitialState
}

export function payout_settings(state = payoutSettingsInitialState, action) {
	switch (action.type) {
		case payoutSettingsConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...payoutSettingsInitialState };
		case payoutSettingsConstants.GET_REQUEST:
			return { ...payoutSettingsFormInfoInitialState, loading: true, edit: state.edit };
		case payoutSettingsConstants.GET_SUCCESS:
			return { ...payoutSettingsFormInfoInitialState, success: true, data: action.responseData, edit: state.edit };
		case payoutSettingsConstants.GET_FAILURE:
			return { ...payoutSettingsFormInfoInitialState, error: action.error, edit: state.edit };

		case payoutSettingsConstants.EDIT_CLEAR:
			return { ...state, edit: payoutSettingsEditInitialState };
		case payoutSettingsConstants.EDIT_REQUEST:
			return { ...state, edit: { ...payoutSettingsEditInitialState, loading: true } };
		case payoutSettingsConstants.EDIT_SUCCESS:
			return { ...state, edit: { ...payoutSettingsEditInitialState, success: true } };
		case payoutSettingsConstants.EDIT_FAILURE:
			return { ...state, edit: { ...payoutSettingsEditInitialState, error: action.error } };

		default:
			return state
	}
}