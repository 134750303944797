import { api } from '../_helpers';
import axios from 'axios';
import { apiEndpoint, payoutConstants } from '../_constants';

export const payoutActions = {
	getList,
	getListClear,
	get,
	getClear,
	getReceipt,
	getReceiptClear,
	getPackagesShortInfo,
	getPackagesShortInfoClear
}

function getList(page_number, status, package_id) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.payout.payouts, { page_number, per_page, status, package_id });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: payoutConstants.GET_LIST_REQUEST } }
    function success(responseData) { return { type: payoutConstants.GET_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: payoutConstants.GET_LIST_FAILURE, error } }
}

function getListClear() {
	return { type: payoutConstants.GET_LIST_CLEAR }
}

function get(id) {
	var endpoint = Object.assign({}, apiEndpoint.payout.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: payoutConstants.GET_REQUEST, id } }
    function success(responseData, id) { return { type: payoutConstants.GET_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: payoutConstants.GET_FAILURE, error, id } }
}

function getClear() {
	return { type: payoutConstants.GET_CLEAR }
}

function getReceipt(id) {
	var endpoint = Object.assign({}, apiEndpoint.payout.receipt); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: payoutConstants.GET_RECEIPT_REQUEST, id } }
    function success(responseData, id) { return { type: payoutConstants.GET_RECEIPT_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: payoutConstants.GET_RECEIPT_FAILURE, error, id } }
}

function getReceiptClear() {
	return { type: payoutConstants.GET_RECEIPT_CLEAR }
}

function getPackagesShortInfo() {
	const requestOptions = api.requestOption(apiEndpoint.payout.packages_short_info);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: payoutConstants.GET_PACKAGES_SHORT_INFO_REQUEST } }
    function success(responseData) { return { type: payoutConstants.GET_PACKAGES_SHORT_INFO_SUCCESS, responseData } }
    function failure(error) { return { type: payoutConstants.GET_PACKAGES_SHORT_INFO_FAILURE, error } }
}

function getPackagesShortInfoClear() {
	return { type: payoutConstants.GET_PACKAGES_SHORT_INFO_CLEAR }
}