import React from "react";

import { OrderMessage } from './OrderMessage';
import { AdminMessage } from './AdminMessage';
import { GeneralMessage } from './GeneralMessage';

import './SystemMessage.scss';
import { utils } from '../../../../utils';

export const SystemMessage = ({ t, message, isVendor, ...props}) => {
	const systemMessageData = utils.constructSystemMessageWithMessage({t, isVendor, message });
	switch (systemMessageData.type) {
		case 'order':
			return <OrderMessage t={t} message={message} systemMessageData={systemMessageData} {...props} />;
		case 'admin':
			return <AdminMessage t={t} message={message} systemMessageData={systemMessageData} {...props} />;
	
		default:
			return <GeneralMessage t={t} message={message} systemMessageData={systemMessageData} {...props} />;
	}
}