import axios from 'axios';

import { api, app, analytics } from '../_helpers';
import { apiEndpoint, savedItemsConstants } from '../_constants';

export const savedItemsActions = {
	getSavedItemsPackages,
	getSavedItemsPackagesClear,
	addSavedItemsPackage,
	addSavedItemsPackageClear,
	removeSavedItemsPackage,
	removeSavedItemsPackageClear,

	getSavedItemsVendors,
	getSavedItemsVendorsClear,
	addSavedItemsVendor,
	addSavedItemsVendorClear,
	removeSavedItemsVendor,
	removeSavedItemsVendorClear,

	getSavedItemsArticles,
	getSavedItemsArticlesClear,
	addSavedItemsArticle,
	addSavedItemsArticleClear,
	removeSavedItemsArticle,
	removeSavedItemsArticleClear,
	
	getSavedItemsCounts,
	getSavedItemsCountsClear,
};

function getSavedItemsPackages(page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.saved_items.packages.get, { currency_id: app.getCurrency().id, page_number, per_page });

	return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});

    };

    function request() { return { type: savedItemsConstants.GET_PACKAGES_REQUEST } }
    function success(responseData) { return { type: savedItemsConstants.GET_PACKAGES_SUCCESS, responseData } }
    function failure(error) { return { type: savedItemsConstants.GET_PACKAGES_FAILURE, error } }
}

function getSavedItemsPackagesClear() {
	return { type: savedItemsConstants.GET_PACKAGES_CLEAR }
}

function addSavedItemsPackage(id) {
	analytics.event({
		category: 'Saved Items - Package',
		action: 'Add',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.packages.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.ADD_PACKAGE_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.ADD_PACKAGE_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.ADD_PACKAGE_FAILURE, error, id } }
}

function addSavedItemsPackageClear() {
	return { type: savedItemsConstants.ADD_PACKAGE_CLEAR }
}

function removeSavedItemsPackage(id) {
	analytics.event({
		category: 'Saved Items - Package',
		action: 'Remove',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.packages.delete); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.REMOVE_PACKAGE_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.REMOVE_PACKAGE_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.REMOVE_PACKAGE_FAILURE, error, id } }
}

function removeSavedItemsPackageClear() {
	return { type: savedItemsConstants.REMOVE_PACKAGE_CLEAR }
}

function getSavedItemsVendors(page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.saved_items.vendors.get, { page_number, per_page });

	return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});

    };

    function request() { return { type: savedItemsConstants.GET_VENDORS_REQUEST } }
    function success(responseData) { return { type: savedItemsConstants.GET_VENDORS_SUCCESS, responseData } }
    function failure(error) { return { type: savedItemsConstants.GET_VENDORS_FAILURE, error } }
}

function getSavedItemsVendorsClear() {
	return { type: savedItemsConstants.GET_VENDORS_CLEAR }
}

function addSavedItemsVendor(id) {
	analytics.event({
		category: 'Saved Items - Vendor',
		action: 'Add',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.vendors.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.ADD_VENDOR_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.ADD_VENDOR_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.ADD_VENDOR_FAILURE, error, id } }
}

function addSavedItemsVendorClear() {
	return { type: savedItemsConstants.ADD_VENDOR_CLEAR }
}

function removeSavedItemsVendor(id) {
	analytics.event({
		category: 'Saved Items - Vendor',
		action: 'Remove',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.vendors.delete); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.REMOVE_VENDOR_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.REMOVE_VENDOR_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.REMOVE_VENDOR_FAILURE, error, id } }
}

function removeSavedItemsVendorClear() {
	return { type: savedItemsConstants.REMOVE_VENDOR_CLEAR }
}

function getSavedItemsArticles(page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.saved_items.articles.get, { page_number, per_page });

	return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});

    };

    function request() { return { type: savedItemsConstants.GET_ARTICLES_REQUEST } }
    function success(responseData) { return { type: savedItemsConstants.GET_ARTICLES_SUCCESS, responseData } }
    function failure(error) { return { type: savedItemsConstants.GET_ARTICLES_FAILURE, error } }
}

function getSavedItemsArticlesClear() {
	return { type: savedItemsConstants.GET_ARTICLES_CLEAR }
}

function addSavedItemsArticle(id) {
	analytics.event({
		category: 'Saved Items - Article',
		action: 'Add',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.articles.edit); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.ADD_ARTICLE_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.ADD_ARTICLE_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.ADD_ARTICLE_FAILURE, error, id } }
}

function addSavedItemsArticleClear() {
	return { type: savedItemsConstants.ADD_ARTICLE_CLEAR }
}

function removeSavedItemsArticle(id) {
	analytics.event({
		category: 'Saved Items - Article',
		action: 'Remove',
		label: 'Customer'
	});

	var endpoint = Object.assign({}, apiEndpoint.saved_items.articles.delete); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: savedItemsConstants.REMOVE_ARTICLE_REQUEST, id } }
    function success(responseData, id) { return { type: savedItemsConstants.REMOVE_ARTICLE_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: savedItemsConstants.REMOVE_ARTICLE_FAILURE, error, id } }
}

function removeSavedItemsArticleClear() {
	return { type: savedItemsConstants.REMOVE_ARTICLE_CLEAR }
}

function getSavedItemsCounts() {
	const requestOptions = api.requestOption(apiEndpoint.saved_items.count);

	return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});

    };

    function request() { return { type: savedItemsConstants.GET_COUNTS_REQUEST } }
    function success(responseData) { return { type: savedItemsConstants.GET_COUNTS_SUCCESS, responseData } }
    function failure(error) { return { type: savedItemsConstants.GET_COUNTS_FAILURE, error } }
}

function getSavedItemsCountsClear() {
	return { type: savedItemsConstants.GET_COUNTS_CLEAR }
}