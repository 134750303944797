import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';

import { vendorPackageActions } from '../../_actions';
import { history } from '../../_helpers';
import { packageStatus } from '../../_constants';

import { LeaveConfirmPrompt, PageContainer, Title, LoadingPage, ErrorPage } from '../../_components';

import { DetailsContentRouter } from '../../Package';
import './PackagePreview.scss';

import { SuccessModal } from './SuccessModal';

class PackagePreview extends Component {
	constructor(props) {
		super(props);

		const { match } = props;

		this.state = {
			isDraft: match.path.startsWith('/vendor/package/create'),
			successModalIsOpen: false,
			disableLeaveConfirmPrompt: false
		};
	}

	componentDidMount() {
		const { isDraft } = this.state;
		const { match } = this.props;

		this.props.dispatch(vendorPackageActions.get(isDraft ,match.params.id));
	}

	componentDidUpdate(prevProps, prevState) {
		const { vendor_package } = this.props;
		const { isDraft } = this.state;

		if (vendor_package.publish.success && prevProps.vendor_package.publish.success !== vendor_package.publish.success) {
			//show popup
			this.setState({ successModalIsOpen: true, disableLeaveConfirmPrompt: true }, () => {
				this.props.dispatch(vendorPackageActions.publishClear(isDraft));
			});
		}
	}

	onBack() {
		const { vendor_package, match } = this.props;
		const id = match.params.id;

		this.setState({ disableLeaveConfirmPrompt: true }, () => {
			history.push(vendor_package.data.status === packageStatus.published? `/vendor/package/${id}/edit` : `/vendor/package/create/${id}`);
			// history.goBack();
		});
	}

	onPublish() {
		this.props.dispatch(vendorPackageActions.publish(true, this.props.match.params.id, true));
	}

	onQuitPreview() {
		history.push('/vendor/me/package');
	}

	render() {
		const { t, vendor_package, match } = this.props;
		const { successModalIsOpen, disableLeaveConfirmPrompt, isDraft } = this.state;
		
		return (
			<>
				{vendor_package.loading && <LoadingPage />}
				{vendor_package.error && <ErrorPage match={this.props.match} error={vendor_package.error} />}
				{vendor_package.data &&
					<PageContainer id="vendor_package_preview" fullPage>
						<Container className="header">
							<Row>
								<Col md={{ size: true }}>
									<Title>{t('preview_package')}</Title>
								</Col>
								{isDraft ?
									<>
										<Col md={{ size: 'auto' }}>
											<Button color="cancel" block onClick={() => this.onBack()}>{t('back')}</Button>
										</Col>
										<Col md={{ size: 'auto' }}>
											<Button block disabled={vendor_package.data.status === packageStatus.published} onClick={() => this.onPublish()}>{t('publish')}</Button>
										</Col>
									</>
									:
									<Col md={{ size: 'auto' }}>
										<Button block onClick={() => this.onQuitPreview()}>{t('quit_preview')}</Button>
									</Col>
								}
							</Row>
						</Container>

						<DetailsContentRouter match={match} data={vendor_package.data} />

						<SuccessModal
							isOpen={successModalIsOpen}
							toggle={() => this.setState({ successModalIsOpen: !this.state.successModalIsOpen })}
						/>
						{isDraft &&
							<LeaveConfirmPrompt
								disabled={disableLeaveConfirmPrompt}
								message={t('confirm_to_leave_unpublish')}
								disableLocations={[match.url, `${match.url}/portfolio`]}
							/>
						}
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_package } = state;
	return {
		vendor_package
	};
}

const translation = withNamespaces(['package', 'default'])(PackagePreview);
const connected = connect(mapStateToProps)(translation);
export { connected as PackagePreview };