import { packageConstants, savedItemsConstants, userConstants } from '../_constants';

const packageDestinationsInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function package_destinations(state = packageDestinationsInitialState, action) {
	switch (action.type) {
		case packageConstants.GET_DESTINATIONS_CLEAR:
			return { ...packageDestinationsInitialState };
		case packageConstants.GET_DESTINATIONS_REQUEST:
			return { ...packageDestinationsInitialState, loading: true };
		case packageConstants.GET_DESTINATIONS_SUCCESS:
			return {
				...packageDestinationsInitialState,
				success: true,
				groups: action.responseData.destination_groups,
				featured: action.responseData.featured_destinations,
			};
		case packageConstants.GET_DESTINATIONS_FAILURE:
			return { ...packageDestinationsInitialState, error: action.error };

		default:
			return state
	}
}

const packageCategoriesInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function package_categories(state = packageCategoriesInitialState, action) {
	switch (action.type) {
		case packageConstants.GET_CATEGORIES_CLEAR:
			return { ...packageCategoriesInitialState };
		case packageConstants.GET_CATEGORIES_REQUEST:
			return { ...packageCategoriesInitialState, loading: true };
		case packageConstants.GET_CATEGORIES_SUCCESS:
			return {
				...packageCategoriesInitialState,
				success: true,
				groups: action.responseData.category_groups,
				featured: action.responseData.featured_categories,
			};
		case packageConstants.GET_CATEGORIES_FAILURE:
			return { ...packageCategoriesInitialState, error: action.error };

		default:
			return state
	}
}

const packageFilterItemsInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const packageSortingItemsInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const packagesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	filter_items: packageFilterItemsInitialState,
	sorting_items: packageSortingItemsInitialState,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

export function packages(state = packagesInitialState, action) {
	switch (action.type) {
		case packageConstants.GET_FILTER_ITEMS_CLEAR:
			return { ...state, filter_items: packageFilterItemsInitialState };
		case packageConstants.GET_FILTER_ITEMS_REQUEST:
			return { ...state, filter_items: { ...packageFilterItemsInitialState, loading: true } };
		case packageConstants.GET_FILTER_ITEMS_SUCCESS:
			return { ...state, filter_items: {...packageFilterItemsInitialState, success: true, data: action.responseData.filter_items } };
		case packageConstants.GET_FILTER_ITEMS_FAILURE:
			return { ...state, filter_items: { ...packageFilterItemsInitialState, error: action.error } };

		case packageConstants.GET_SORT_ITEMS_CLEAR:
			return { ...state, sorting_items: packageSortingItemsInitialState };
		case packageConstants.GET_SORT_ITEMS_REQUEST:
			return { ...state, sorting_items: { ...packageSortingItemsInitialState, loading: true } };
		case packageConstants.GET_SORT_ITEMS_SUCCESS:
			return { ...state, sorting_items: {...packageSortingItemsInitialState, success: true, data: action.responseData.filter_items } };
		case packageConstants.GET_SORT_ITEMS_FAILURE:
			return { ...state, sorting_items: { ...packageSortingItemsInitialState, error: action.error } };

		case packageConstants.GET_LIST_CLEAR:
			return { ...packagesInitialState, filter_items: state.filter_items, sorting_items: state.sorting_items};
		case packageConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case packageConstants.GET_LIST_SUCCESS:
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.packages;
			} else {
				list = list.concat(action.responseData.packages);
			}
			return { ...state,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined
			};
		case packageConstants.GET_LIST_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case savedItemsConstants.ADD_PACKAGE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			
			return state;
		}
		case savedItemsConstants.REMOVE_PACKAGE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = false;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					item.is_bookmarked = false;
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		default:
			return state
	}
}

const packageLocationsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const packageDetailsReviewInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const packageDetailsInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	locations: packageLocationsInitialState,
	reviews: packageDetailsReviewInitialState
}

export function package_details(state = packageDetailsInitialState, action) {
	switch (action.type) {
		case packageConstants.GET_CLEAR:
			return { ...packageDetailsInitialState };
		case packageConstants.GET_REQUEST: {
			let updateFromState = state.id == action.id? state : packageDetailsInitialState;
			return { 
				...updateFromState,
				id: action.id, 
				loading: true, 
				success: false,
				error: undefined,
			};
		}
		case packageConstants.GET_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				id: action.id,
				data: action.responseData,
				locations: {
					...state.locations,
					loading: false,
					success: true,
					list: action.responseData.locations,
					error: undefined
				},
				error: undefined
			};
		case packageConstants.GET_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				error: action.error,
				id: action.id,
				locations: {
					...state.locations,
					loading: false,
					success: false,
					error: action.error
				}
			};
		
		case savedItemsConstants.ADD_PACKAGE_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = true;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}
		case savedItemsConstants.REMOVE_PACKAGE_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.data) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		case packageConstants.GET_LOCATIONS_CLEAR:
			return { ...state, locations: { ...packageLocationsInitialState} };
		case packageConstants.GET_LOCATIONS_REQUEST: {
			let updateFromState = state.id == action.id? state : packageDetailsInitialState;
			let updateFromStateLocations = state.id == action.id? state.locations : packageLocationsInitialState;
			return { ...updateFromState, id: action.id, locations: { ...updateFromStateLocations, loading: true, success: false, error: undefined }};
		}
		case packageConstants.GET_LOCATIONS_SUCCESS:
			return { ...state, id: action.id, locations: { ...state.locations, loading: false, success: true, list: action.responseData, error: undefined }};
		case packageConstants.GET_LOCATIONS_FAILURE:
			return { ...state, id: action.id, locations: { ...state.locations, loading: false, error: action.error, success: false }};

		case packageConstants.GET_REVIEWS_CLEAR:
			return { ...state, reviews: { ...packageDetailsReviewInitialState }};
		case packageConstants.GET_REVIEWS_REQUEST: {
			let updateFromState = state.id == action.id? state : packageDetailsInitialState;
			let updateFromStateReviews = state.id == action.id? state.reviews : packageDetailsReviewInitialState;
			return { ...updateFromState, id: action.id, reviews: { ...updateFromStateReviews, loading: true, success: false, error: undefined }};
		}
		case packageConstants.GET_REVIEWS_SUCCESS:
			let list = state.reviews.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.reviews;
			} else {
				list = list.concat(action.responseData.reviews);
			}
			return {
				...state,
				id: action.id,
				reviews: {
					...state.reviews,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined
				}
			};
		case packageConstants.GET_REVIEWS_FAILURE:
			return { ...state, id: action.id, reviews: { ...state.reviews, error: action.error, loading: false, success: false }};

		default:
			return state
	}
}