import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import { myReviewsActions } from '../../../../_actions';
import { ReviewsList } from '../../../../Package';

import './Reviews.scss';

class Reviews extends Component {
	componentDidMount() {
		this.props.dispatch(myReviewsActions.getClear());
	}

	render() {
		const { my_reviews } = this.props;

		return (
			<div id="vendor_myprofile_vendor_profile_reviews">
				<Container>
					<ReviewsList
						showHeader={false}
						loadMoreProp={my_reviews}
						onLoadMore={(nextPage) => {
							this.props.dispatch(myReviewsActions.get(nextPage));
						}}
					/>
				</Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { my_reviews } = state;
	return {
		my_reviews
	};
}

const connected = connect(mapStateToProps)(Reviews);
export { connected as Reviews };