import { orderConstants, orderStatus, userConstants } from '../_constants';

const ordersInitialState = {
	type: undefined,
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

export function orders(state = ordersInitialState, action) {
	switch (action.type) {
		case orderConstants.GET_LIST_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...ordersInitialState };
		case orderConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false, type: action.order_type };
		case orderConstants.GET_LIST_SUCCESS:
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.orders;
			} else {
				list = list.concat(action.responseData.orders);
			}
			return { ...state,
				type: action.order_type,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined
			};
		case orderConstants.GET_LIST_FAILURE:
			return { ...state, type: action.order_type, loading: false, success: false, error: action.error };

		case orderConstants.COMPLETE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.status = orderStatus.completed;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		default:
			return state
	}
}

const orderCancelInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderMakeOfferInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderPaymentProcessInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderPaymentPromoCodeProcessInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

const orderPaymentInitialState = {
	order_id: undefined,
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	process: orderPaymentProcessInitialState,
	promo_code: orderPaymentPromoCodeProcessInitialState,
}

const orderReceiptInitialState = {
	id: undefined,
	loading: false,
	success: true,
	data: undefined,
	error: undefined,
}

const orderEditDatetimeInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderPendingItemsInitialState = {
	pending_total: undefined,
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const orderPendingItemReplyInitialState = {
	id: undefined,
	accept: undefined,
	loading: false,
	success: false,
	error: undefined,
}

const orderPendingItemCancelInitialState = {
	id: undefined,
	loading: false,
	success: false,
	error: undefined,
}

const orderPendingItemInitialState = {
	reply: orderPendingItemReplyInitialState,
	cancel: orderPendingItemCancelInitialState
}

const orderMeetupEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderMeetupInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	edit: orderMeetupEditInitialState
}

const orderMaterialsEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderMaterialsInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	edit: orderMaterialsEditInitialState
}

const orderReviewEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderReviewInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	edit: orderReviewEditInitialState
}

const orderReportEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderReportInitialState = {
	loading: false,
	success: false,
	options: undefined,
	error: undefined,
	edit: orderReportEditInitialState
}

const orderPaymentsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const orderReceiptsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const orderEditDatetimeHistoryInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const orderCompleteInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderMakeBookingInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const orderInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	make_booking: orderMakeBookingInitialState,
	cancel: orderCancelInitialState,
	edit: orderEditInitialState,
	make_offer: orderMakeOfferInitialState,
	payments: orderPaymentsInitialState,
	payment: orderPaymentInitialState,
	receipts: orderReceiptsInitialState,
	receipt: orderReceiptInitialState,
	edit_datetime: orderEditDatetimeInitialState,
	edit_datetime_history: orderEditDatetimeHistoryInitialState,
	pending_items: orderPendingItemsInitialState,
	pending_item: orderPendingItemInitialState,
	meet_up: orderMeetupInitialState,
	materials: orderMaterialsInitialState,
	review: orderReviewInitialState,
	report: orderReportInitialState,
	complete: orderCompleteInitialState
}

export function order(state = orderInitialState, action) {
	switch (action.type) {
		case orderConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...orderInitialState };
		case orderConstants.GET_REQUEST: {
			let updateFromState = state.id == action.id? state : orderInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, error: undefined };
		}
		case orderConstants.GET_SUCCESS:
			return { ...state, loading: false, success: true, id: action.id, data: action.responseData, error: undefined };
		case orderConstants.GET_FAILURE:
			return {...state, loading: false, success: false, id: action.id, data: undefined, error: action.error };

		case orderConstants.SEND_REQUEST_CLEAR:
			return { ...state, make_booking: orderMakeBookingInitialState };
		case orderConstants.SEND_REQUEST_REQUEST:
			return { ...state, make_booking: { ...orderMakeBookingInitialState, loading: true } };
		case orderConstants.SEND_REQUEST_SUCCESS:
			return { ...state, make_booking: { ...orderMakeBookingInitialState, success: true } };
		case orderConstants.SEND_REQUEST_FAILURE:
			return { ...state, make_booking: { ...orderMakeBookingInitialState, error: action.error } };

		case orderConstants.CANCEL_CLEAR:
			return { ...state, cancel: orderCancelInitialState };
		case orderConstants.CANCEL_REQUEST:
			return { ...state, cancel: { ...orderCancelInitialState, loading: true } };
		case orderConstants.CANCEL_SUCCESS:
			return { ...state, cancel: { ...orderCancelInitialState, success: true } };
		case orderConstants.CANCEL_FAILURE:
			return { ...state, cancel: { ...orderCancelInitialState, error: action.error } };

		case orderConstants.EDIT_CLEAR:
			return { ...state, edit: orderEditInitialState };
		case orderConstants.EDIT_REQUEST:
			return { ...state, edit: { ...orderEditInitialState, loading: true } };
		case orderConstants.EDIT_SUCCESS:
			return { ...state, data: action.responseData, edit: { ...orderEditInitialState, success: true } };
		case orderConstants.EDIT_FAILURE:
			return { ...state, edit: { ...orderEditInitialState, error: action.error } };

		case orderConstants.MAKE_OFFER_CLEAR:
			return { ...state, make_offer: orderMakeOfferInitialState };
		case orderConstants.MAKE_OFFER_REQUEST:
			return { ...state, make_offer: { ...orderMakeOfferInitialState, loading: true } };
		case orderConstants.MAKE_OFFER_SUCCESS:
			return { ...state, make_offer: { ...orderMakeOfferInitialState, success: true } };
		case orderConstants.MAKE_OFFER_FAILURE:
			return { ...state, make_offer: { ...orderMakeOfferInitialState, error: action.error } };
		
		case orderConstants.GET_PAYMENTS_CLEAR:
			return { ...state, payments: orderPaymentsInitialState };
		case orderConstants.GET_PAYMENTS_REQUEST:
			return { ...state, payments: { ...orderPaymentsInitialState, loading: true }};
		case orderConstants.GET_PAYMENTS_SUCCESS:
			return { ...state, payments: { ...orderPaymentsInitialState, success: true, list: action.responseData }};
		case orderConstants.GET_PAYMENTS_FAILURE:
			return { ...state, payments: { ...orderPaymentsInitialState, error: action.error }};

		case orderConstants.GET_PAYMENT_CLEAR:
			return { ...state, payment: { ...orderPaymentInitialState } };
		case orderConstants.GET_PAYMENT_REQUEST:
			return { ...state, payment: { ...orderPaymentInitialState, loading: true, id: action.id }};
		case orderConstants.GET_PAYMENT_SUCCESS:
			return { ...state, payment: { ...orderPaymentInitialState, success: true, data: action.responseData, id: action.id } };
		case orderConstants.GET_PAYMENT_FAILURE:
			return { ...state, payment: { ...orderPaymentInitialState, error: action.error, id: action.id } };

		case orderConstants.PAYMENT_PROCESS_CLEAR:
			return { ...state, payment: { ...state.payment, process: orderPaymentProcessInitialState } };
		case orderConstants.PAYMENT_PROCESS_REQUEST:
			return { ...state, payment: { ...state.payment, process: { ...orderPaymentProcessInitialState, loading: true } } };
		case orderConstants.PAYMENT_PROCESS_SUCCESS:
			return { ...state, payment: { ...state.payment, process: { ...orderPaymentProcessInitialState, success: true } } };
		case orderConstants.PAYMENT_PROCESS_FAILURE:
			return { ...state, payment: { ...state.payment, process: { ...orderPaymentProcessInitialState, error: action.error } } };
			
		case orderConstants.PAYMENT_APPLY_CODE_CLEAR:
			return { ...state, payment: { ...state.payment, promo_code: orderPaymentPromoCodeProcessInitialState } };
		case orderConstants.PAYMENT_APPLY_CODE_REQUEST:
			return { ...state, payment: { ...state.payment, promo_code: { ...orderPaymentPromoCodeProcessInitialState, loading: true } } };
		case orderConstants.PAYMENT_APPLY_CODE_SUCCESS:
			return { ...state, payment: { ...state.payment, promo_code: { ...orderPaymentPromoCodeProcessInitialState, success: true, data: action.responseData } } };
		case orderConstants.PAYMENT_APPLY_CODE_FAILURE:
			return { ...state, payment: { ...state.payment, promo_code: { ...orderPaymentPromoCodeProcessInitialState, error: action.error } } };
		
		case orderConstants.GET_RECEIPTS_CLEAR:
			return { ...state, receipts: orderReceiptsInitialState };
		case orderConstants.GET_RECEIPTS_REQUEST:
			return { ...state, receipts: { ...orderReceiptsInitialState, loading: true }};
		case orderConstants.GET_RECEIPTS_SUCCESS:
			return { ...state, receipts: { ...orderReceiptsInitialState, success: true, list: action.responseData }};
		case orderConstants.GET_RECEIPTS_FAILURE:
			return { ...state, receipts: { ...orderReceiptsInitialState, error: action.error }};

		case orderConstants.GET_RECEIPT_CLEAR:
			return { ...state, receipt: { ...orderReceiptInitialState } };
		case orderConstants.GET_RECEIPT_REQUEST: {
			let updateFromState = state.receipt && state.id == action.id? state : orderReceiptInitialState;
			return { ...state, receipt: { ...updateFromState, loading: true, success: false, id: action.id, error: undefined } };
		}
		case orderConstants.GET_RECEIPT_SUCCESS:
			return { ...state, receipt: { ...orderReceiptInitialState, success: true, data: action.responseData, id: action.id } };
		case orderConstants.GET_RECEIPT_FAILURE:
			return { ...state, receipt: { ...orderReceiptInitialState, error: action.error, id: action.id } };

		case orderConstants.EDIT_DATETIME_CLEAR:
			return { ...state, edit_datetime: { ...orderEditDatetimeInitialState } };
		case orderConstants.EDIT_DATETIME_REQUEST:
			return { ...state, edit_datetime: { ...orderEditDatetimeInitialState, loading: true }};
		case orderConstants.EDIT_DATETIME_SUCCESS:
			return { ...state, edit_datetime: { ...orderEditDatetimeInitialState, success: true } };
		case orderConstants.EDIT_DATETIME_FAILURE:
			return { ...state, edit_datetime: { ...orderEditDatetimeInitialState, error: action.error } };
		
		case orderConstants.GET_EDIT_DATETIME_HISTORY_CLEAR:
			return { ...state, edit_datetime_history: orderEditDatetimeHistoryInitialState };
		case orderConstants.GET_EDIT_DATETIME_HISTORY_REQUEST:
			return { ...state, edit_datetime_history: { ...state.edit_datetime_history, loading: true, success: false, error: undefined }};
		case orderConstants.GET_EDIT_DATETIME_HISTORY_SUCCESS: {
			let list = state.edit_datetime_history.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.pending_items;
			} else {
				list = list.concat(action.responseData.pending_items);
			}
			return {
				...state,
				edit_datetime_history: {
					...state.edit_datetime_history,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined
				}
			};
		}
		case orderConstants.GET_EDIT_DATETIME_HISTORY_FAILURE:
			return { ...state, edit_datetime_history: { ...state.edit_datetime_history, loading: false, success: false , error: action.error }};

		case orderConstants.GET_PENDING_ITEMS_CLEAR:
			return { ...state, pending_items: { ...orderPendingItemsInitialState } };
		case orderConstants.GET_PENDING_ITEMS_REQUEST:
			return { ...state, pending_items: { ...state.pending_items, loading: true, success: false, error: undefined }};
		case orderConstants.GET_PENDING_ITEMS_SUCCESS: {
			let list = state.pending_items.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.pending_items;
			} else {
				list = list.concat(action.responseData.pending_items);
			}
			return {
				...state,
				pending_items: {
					...state.pending_items,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined
				}
			};
		}
		case orderConstants.GET_PENDING_ITEMS_FAILURE:
			return { ...state, pending_items: { ...state.pending_items, loading: false, success: false, error: action.error }};

		case orderConstants.GET_PENDING_ITEMS_COUNT_SUCCESS:
			return { ...state, pending_items: { ...state.pending_items, pending_total: action.responseData }};

		case orderConstants.REPLY_PENDING_ITEM_CLEAR:
			return { ...state, pending_item: { ...state.pending_item, reply: orderPendingItemReplyInitialState} };
		case orderConstants.REPLY_PENDING_ITEM_REQUEST:
			return { ...state,
				pending_item: {
					...state.pending_item,
					reply: {
						...orderPendingItemReplyInitialState,
						loading: true,
						accept: action.accept,
						id: action.id
					}
				}
			};
		case orderConstants.REPLY_PENDING_ITEM_SUCCESS:
			return { ...state,
				pending_item: {
					...state.pending_item,
					reply: {
						...orderPendingItemReplyInitialState,
						success: true,
						accept: action.accept,
						id: action.id
					}
				}
			};
		case orderConstants.REPLY_PENDING_ITEM_FAILURE:
			return { ...state,
				pending_item: {
					...state.pending_item,
					id: action.id,
					reply: {
						...orderPendingItemReplyInitialState,
						error: action.error,
						accept: action.accept,
					}
				}
			};

		case orderConstants.CANCEL_PENDING_ITEM_CLEAR:
			return { ...state, pending_item: { ...state.pending_item, cancel: orderPendingItemCancelInitialState} };
		case orderConstants.CANCEL_PENDING_ITEM_REQUEST:
			return { ...state,
				pending_item: {
					...state.pending_item,
					cancel: {
						...orderPendingItemCancelInitialState,
						loading: true,
						id: action.id
					}
				}
			};
		case orderConstants.CANCEL_PENDING_ITEM_SUCCESS:
			return { ...state,
				pending_item: {
					...state.pending_item,
					cancel: {
						...orderPendingItemCancelInitialState,
						success: true,
						id: action.id
					}
				}
			};
		case orderConstants.CANCEL_PENDING_ITEM_FAILURE:
			return { ...state,
				pending_item: {
					...state.pending_item,
					cancel: {
						...orderPendingItemCancelInitialState,
						error: action.error,
						id: action.id
					}
				}
			};

		case orderConstants.GET_MEETUP_CLEAR:
			return { ...state, meet_up: { ...orderMeetupInitialState } };
		case orderConstants.GET_MEETUP_REQUEST:
			return { ...state, meet_up: { ...orderMeetupInitialState, edit: state.meet_up.edit, loading: true } };
		case orderConstants.GET_MEETUP_SUCCESS:
			return { ...state, meet_up: { ...orderMeetupInitialState, edit: state.meet_up.edit, success: true, data: action.responseData } };
		case orderConstants.GET_MEETUP_FAILURE:
			return { ...state, meet_up: { ...orderMeetupInitialState, edit: state.meet_up.edit, error: action.error } };

		case orderConstants.EDIT_MEETUP_CLEAR:
			return { ...state, meet_up: { ...state.meet_up, edit: orderMeetupEditInitialState } };
		case orderConstants.EDIT_MEETUP_REQUEST:
			return { ...state, meet_up: { ...state.meet_up, edit: { ...orderMeetupEditInitialState, loading: true } } };
		case orderConstants.EDIT_MEETUP_SUCCESS:
			return { ...state, meet_up: { ...state.meet_up, edit: { ...orderMeetupEditInitialState, success: true } } };
		case orderConstants.EDIT_MEETUP_FAILURE:
			return { ...state, meet_up: { ...state.meet_up, edit: { ...orderMeetupEditInitialState, error: action.error } } };
			
		case orderConstants.GET_MATERIALS_CLEAR:
			return { ...state, materials: { ...orderMaterialsInitialState } };
		case orderConstants.GET_MATERIALS_REQUEST:
			return { ...state, materials: { ...orderMaterialsInitialState, edit: state.materials.edit, loading: true } };
		case orderConstants.GET_MATERIALS_SUCCESS:
			return { ...state, materials: { ...orderMaterialsInitialState, edit: state.materials.edit, success: true, data: action.responseData } };
		case orderConstants.GET_MATERIALS_FAILURE:
			return { ...state, materials: { ...orderMaterialsInitialState, edit: state.materials.edit, error: action.error } };

		case orderConstants.EDIT_MATERIALS_CLEAR:
			return { ...state, materials: { ...state.materials, edit: orderMaterialsEditInitialState } };
		case orderConstants.EDIT_MATERIALS_REQUEST:
			return { ...state, materials: { ...state.materials, edit: { ...orderMaterialsEditInitialState, loading: true } } };
		case orderConstants.EDIT_MATERIALS_SUCCESS:
			return { ...state, materials: { ...state.materials, edit: { ...orderMaterialsEditInitialState, success: true } } };
		case orderConstants.EDIT_MATERIALS_FAILURE:
			return { ...state, materials: { ...state.materials, edit: { ...orderMaterialsEditInitialState, error: action.error } } };

		case orderConstants.GET_REVIEW_CLEAR:
			return { ...state, review: { ...orderReviewInitialState } };
		case orderConstants.GET_REVIEW_REQUEST:
			return { ...state, review: { ...orderReviewInitialState, edit: state.review.edit, loading: true } };
		case orderConstants.GET_REVIEW_SUCCESS:
			return { ...state, review: { ...orderReviewInitialState, edit: state.review.edit, success: true, data: action.responseData } };
		case orderConstants.GET_REVIEW_FAILURE:
			return { ...state, review: { ...orderReviewInitialState, edit: state.review.edit, error: action.error } };

		case orderConstants.EDIT_REVIEW_CLEAR:
			return { ...state, review: { ...state.review, edit: orderReviewEditInitialState } };
		case orderConstants.EDIT_REVIEW_REQUEST:
			return { ...state, review: { ...state.review, edit: { ...orderReviewEditInitialState, loading: true } } };
		case orderConstants.EDIT_REVIEW_SUCCESS:
			return { ...state, review: { ...state.review, edit: { ...orderReviewEditInitialState, success: true } } };
		case orderConstants.EDIT_REVIEW_FAILURE:
			return { ...state, review: { ...state.review, edit: { ...orderReviewEditInitialState, error: action.error } } };

		case orderConstants.GET_REPORT_OPTIONS_CLEAR:
			return { ...state, report: { ...orderReportInitialState } };
		case orderConstants.GET_REPORT_OPTIONS_REQUEST:
			return { ...state, report: { ...orderReportInitialState, edit: state.report.edit, loading: true } };
		case orderConstants.GET_REPORT_OPTIONS_SUCCESS:
			return { ...state, report: { ...orderReportInitialState, edit: state.report.edit, success: true, options: action.responseData } };
		case orderConstants.GET_REPORT_OPTIONS_FAILURE:
			return { ...state, report: { ...orderReportInitialState, edit: state.report.edit, error: action.error } };

		case orderConstants.EDIT_REPORT_CLEAR:
			return { ...state, report: { ...state.report, edit: orderReportEditInitialState } };
		case orderConstants.EDIT_REPORT_REQUEST:
			return { ...state, report: { ...state.report, edit: { ...orderReportEditInitialState, loading: true } } };
		case orderConstants.EDIT_REPORT_SUCCESS:
			return { ...state, report: { ...state.report, edit: { ...orderReportEditInitialState, success: true } } };
		case orderConstants.EDIT_REPORT_FAILURE:
			return { ...state, report: { ...state.report, edit: { ...orderReportEditInitialState, error: action.error } } };

		case orderConstants.COMPLETE_CLEAR:
			return { ...state, complete: { ...orderCompleteInitialState } };
		case orderConstants.COMPLETE_REQUEST:
			return { ...state, complete: { ...orderCompleteInitialState, loading: true }};
		case orderConstants.COMPLETE_SUCCESS:
			return { ...state, complete: { ...orderCompleteInitialState, success: true } };
		case orderConstants.COMPLETE_FAILURE:
			return { ...state, complete: { ...orderCompleteInitialState, error: action.error } };

		default:
			return state
	}
}