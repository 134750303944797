export const userConstants = {
	CHECK_EMAIL_REQUEST: 'USER_CHECK_EMAIL_REQUEST',
    CHECK_EMAIL_SUCCESS: 'USER_CHECK_EMAIL_SUCCESS',
	CHECK_EMAIL_FAILURE: 'USER_CHECK_EMAIL_FAILURE',
	CHECK_EMAIL_CLEAR: 'USER_CHECK_EMAIL_CLEAR',
	
    SEND_CODE_REQUEST: 'USER_SEND_CODE_REQUEST',
    SEND_CODE_SUCCESS: 'USER_SEND_CODE_SUCCESS',
	SEND_CODE_FAILURE: 'USER_SEND_CODE_FAILURE',
	SEND_CODE_CLEAR: 'USER_SEND_CODE_CLEAR',
	
	REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
	REGISTER_CLEAR: 'USER_REGISTER_CLEAR',
	
	LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	LOGIN_CLEAR: 'USER_LOGIN_CLEAR',
	
	LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
	LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
	LOGOUT_CLEAR: 'USER_LOGOUT_CLEAR',

	RESETPASSWORD_SEND_CODE_REQUEST: 'USER_RESETPASSWORD_SEND_CODE_REQUEST',
	RESETPASSWORD_SEND_CODE_SUCCESS: 'USER_RESETPASSWORD_SEND_CODE_SUCCESS',
	RESETPASSWORD_SEND_CODE_FAILURE: 'USER_RESETPASSWORD_SEND_CODE_FAILURE',
	RESETPASSWORD_SEND_CODE_CLEAR: 'USER_RESETPASSWORD_SEND_CODE_CLEAR',

	RESETPASSWORD_VERIFY_CODE_REQUEST: 'USER_RESETPASSWORD_VERIFY_CODE_REQUEST',
	RESETPASSWORD_VERIFY_CODE_SUCCESS: 'USER_RESETPASSWORD_VERIFY_CODE_SUCCESS',
	RESETPASSWORD_VERIFY_CODE_FAILURE: 'USER_RESETPASSWORD_VERIFY_CODE_FAILURE',
	RESETPASSWORD_VERIFY_CODE_CLEAR: 'USER_RESETPASSWORD_VERIFY_CODE_CLEAR',

	RESETPASSWORD_BY_CODE_REQUEST: 'USER_RESETPASSWORD_BY_CODE_REQUEST',
	RESETPASSWORD_BY_CODE_SUCCESS: 'USER_RESETPASSWORD_BY_CODE_SUCCESS',
	RESETPASSWORD_BY_CODE_FAILURE: 'USER_RESETPASSWORD_BY_CODE_FAILURE',
	RESETPASSWORD_BY_CODE_CLEAR: 'USER_RESETPASSWORD_BY_CODE_CLEAR',
	RESETPASSWORD_BY_CODE_CLEAR: 'USER_RESETPASSWORD_BY_CODE_CLEAR',

	EDIT_PROFILE_REQUEST: 'USER_EDIT_PROFILE_REQUEST',
	EDIT_PROFILE_SUCCESS: 'USER_EDIT_PROFILE_SUCCESS',
	EDIT_PROFILE_FAILURE: 'USER_EDIT_PROFILE_FAILURE',
	EDIT_PROFILE_CLEAR: 'USER_EDIT_PROFILE_CLEAR',

	EDIT_EMAIL_SEND_CODE_REQUEST: 'USER_EDIT_EMAIL_SEND_CODE_REQUEST',
	EDIT_EMAIL_SEND_CODE_SUCCESS: 'USER_EDIT_EMAIL_SEND_CODE_SUCCESS',
	EDIT_EMAIL_SEND_CODE_FAILURE: 'USER_EDIT_EMAIL_SEND_CODE_FAILURE',
	EDIT_EMAIL_SEND_CODE_CLEAR: 'USER_EDIT_EMAIL_SEND_CODE_CLEAR',

	EDIT_EMAIL_BY_CODE_REQUEST: 'USER_EDIT_EMAIL_BY_CODE_REQUEST',
	EDIT_EMAIL_BY_CODE_SUCCESS: 'USER_EDIT_EMAIL_BY_CODE_SUCCESS',
	EDIT_EMAIL_BY_CODE_FAILURE: 'USER_EDIT_EMAIL_BY_CODE_FAILURE',
	EDIT_EMAIL_BY_CODE_CLEAR: 'USER_EDIT_EMAIL_BY_CODE_CLEAR',

	EDIT_PASSWORD_REQUEST: 'USER_EDIT_PASSWORD_REQUEST',
	EDIT_PASSWORD_SUCCESS: 'USER_EDIT_PASSWORD_SUCCESS',
	EDIT_PASSWORD_FAILURE: 'USER_EDIT_PASSWORD_FAILURE',
	EDIT_PASSWORD_CLEAR: 'USER_EDIT_PASSWORD_CLEAR',

	GET_USER_REQUEST: 'USER_GET_USER_REQUEST',
    GET_USER_SUCCESS: 'USER_GET_USER_SUCCESS',
	GET_USER_FAILURE: 'USER_GET_USER_FAILURE',
	GET_USER_CLEAR: 'USER_GET_USER_CLEAR',
}

export const userType = {
	customer: 1,
	photographer: 2,
	agency: 3
}