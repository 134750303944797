import React, { Component } from 'react';
import { Button } from 'reactstrap';

import './SwitchButton.scss';

export class SwitchButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			on: props.isOn
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.on !== nextProps.isOn) {
			return { on: nextProps.isOn };
		}

		return null;
	}
	
	onClick() {
		let newValue = !this.state.on;
		this.setState({ on: newValue }, () => {
			const { onChange } = this.props;
			if (onChange) onChange(newValue);
		})
	}

	render() {
		const { labelOn, labelOff, disabled, className, id } = this.props;
		const { on } = this.state;
		let _labelOn = labelOn? labelOn : 'ON';
		let _labelOff = labelOff? labelOff : 'OFF';

		return(
			<Button
			id={id}
			className={`btn-switch ${on ? 'selected' : ''} ${className? className : ''}`} 
			disabled={disabled}
			onClick={() => this.onClick()}>
				<div className="label">{on? _labelOn : _labelOff }</div>
				<div className="handle"></div>
			</Button>
		);
	}
}