import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title } from '../../Title';
import { Desc } from '../../Desc';

import './InputForm.scss';

class InputForm extends Component {
	render() {
		const { t, isLoading, title, desc, btnTitle } = this.props;

		return (
			<div id="action_modal_form" className="modal-content-container">
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>{title}</Title>
				</ModalHeader>
				<ModalBody>
					<Desc>{desc}</Desc>
				</ModalBody>
				<ModalFooter>
					<Container className="container-p-8">
						<Row className="row-m-8 btns">
							<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
								<Button onClick={() => this.props.onSubmit()} disabled={isLoading}>{btnTitle}</Button>
							</Col>
							<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
								<Button color="cancel" onClick={() => this.props.toggle()}>{t('cancel')}</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</div>
		);
	}
}

const translation = withNamespaces(['default'])(InputForm);
export { translation as InputForm };