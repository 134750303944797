import { api } from '../_helpers';
import axios from 'axios';
import { apiEndpoint, myReviewsConstants } from '../_constants';

export const myReviewsActions = {
	get,
	getClear,
}

function get(page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.my_reviews.get, { page_number, per_page });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: myReviewsConstants.GET_REQUEST } }
    function success(responseData) { return { type: myReviewsConstants.GET_SUCCESS, responseData } }
    function failure(error) { return { type: myReviewsConstants.GET_FAILURE, error } }
}

function getClear() {
	return { type: myReviewsConstants.GET_CLEAR }
}