import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import Moment from 'moment';

import { getOrderStatusNameByData, canCancelOrderByData, orderStatus, userType } from '../../../_constants';
import { Desc } from '../../../_components';

import '../Card.scss';
import './Status.scss';

import img_info from './img/order_details_status_icon_question.svg';
import { StatusInfoModal } from '../../StatusInfoModal';

class Status extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			statusInfoModalIsOpen: false
		}
	}
	
	onStatusInfo() {
		this.setState({ statusInfoModalIsOpen: true });
	}

	render() {
		const { t, data, auth } = this.props;
		const { statusInfoModalIsOpen } = this.state;

		const isVendor = auth.user.user_type !== userType.customer;

		let desc = undefined;

		if (isVendor) {
			if (!data.is_expired &&
				data.status !== orderStatus.deal_cancelled &&
				data.status !== orderStatus.order_cancelled &&
				data.status !== orderStatus.completed &&
				data.status !== orderStatus.refunding &&
				data.status !== orderStatus.refunded &&
				data.status !== orderStatus.refund_fail
				) {
				if (data.expiry_datetime && Moment.utc(data.expiry_datetime.date).isAfter(Moment())) {
					const days = Moment.utc(data.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).diff(Moment(), 'days');
					const hours = Moment.utc(data.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).diff(Moment(), 'hours');
					const minutes = Moment.utc(data.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).diff(Moment(), 'minutes');
					const seconds = Moment.utc(data.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).diff(Moment(), 'seconds');
	
					if (days > 0) {
						desc = t('expires_in_n_days', { n: days });
					} else if (hours > 0) {
						desc = t('expires_in_n_hours', { n: hours });
					} else if (minutes > 0) {
						desc = t('expires_in_n_minutes', { n: minutes });
					} else {
						desc = t('expires_in_n_seconds', { n: seconds });
					}
				}
			}
		} else {
			desc = t(`status_desc.${getOrderStatusNameByData(data)}`);
		}

		return (
			<>
				<Card id="order_card_status" className="order-info-card">
					<CardHeader>
						<CardTitle tag='div'>
							{t('status.status')}
							<Button color="img" className="btn-info" onClick={() => this.onStatusInfo()}><img src={img_info} alt={``} /></Button>
						</CardTitle>
					</CardHeader>
					<CardBody>
						<div className={`status ${getOrderStatusNameByData(data)}`}>
							{t(`status.${getOrderStatusNameByData(data)}`)}
						</div>
						<Desc>
							{desc}
						</Desc>
					</CardBody>
					{(canCancelOrderByData(data) && !(data.status === orderStatus.requesting && isVendor)) ?
						<CardFooter>
							<Button color="link" onClick={() => this.props.onCancelOffer()}>{t('cancel_offer.title')}</Button>
						</CardFooter>
						:
						<CardFooter>
						</CardFooter>
					}
				</Card>
				<StatusInfoModal
					isOpen={statusInfoModalIsOpen}
					toggle={() => this.setState({ statusInfoModalIsOpen: !this.state.statusInfoModalIsOpen })}
					status={data.status}
					isExpired={data.is_expired}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['order', 'default'])(Status);
const connected = connect(mapStateToProps)(translation);
export { connected as Status };