import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, InputItem, InputField, Desc, InputItemLabel, InputFilePreview } from '../../../../../../_components';
import { userType } from '../../../../../../_constants';

import './InputForm.scss';

class InputForm extends Component {
	render() {
		const { t, auth, data } = this.props;
		const isAgency = auth.user.user_type === userType.agency;

		let schema = Yup.object().shape({
			info: Yup.string()
				.required('Required'),
		});
		if (isAgency) {
			schema = Yup.object().shape({
				photo: Yup.mixed()
					.test('photo', 'Required', function (value) {
						const { photo_url } = this.parent;
						return photo_url || value;
					}),
				photo_url: Yup.string()
					.test('photo_url', 'Required', function (value) {
						const { photo } = this.parent;
						return photo || value;
					}),
				info: Yup.string()
					.required('Required'),
			});
		}

		let initialValues = {
			photo: '',
			photo_url: '',
			info: ''
		};

		if (data) {
			initialValues.photo_url = data.photo_url;
			initialValues.info = data.info;
		}

		return (
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={(values, formikBag) => this.props.onSubmit(values, formikBag)}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					setFieldTouched,
					setFieldValue
					/* and other goodies */
				}) => (
						<Form id="vendor_verification_form" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('verification.title')}</Title>
							</ModalHeader>
							<ModalBody>
								<Desc>{t('verification.desc')}</Desc>
								{isAgency &&
									<InputItem className="input-item-photo">
										<InputItemLabel>{t('br_img')}</InputItemLabel>
										<InputFilePreview file={values.photo} />
										{values.photo ? null : <img className="preview" src={values.photo_url} alt="" />}
										<label htmlFor="photo" className="upload-image">
											{t('upload_image')}
										</label>
										<input id="photo" name="photo" type="file" onChange={(event) => {
											if (event.currentTarget.files.length) {
												setFieldTouched('photo', true);
												setFieldValue("photo", event.currentTarget.files[0]);
											}

											event.target.value = null;
										}} className="form-control input-upload-image" />
									</InputItem>
								}
								<InputItem>
									<InputItemLabel>{t('info')}</InputItemLabel>
									<InputField component="textarea" type="text" name="info" placeholder={t('info_hint')} t={t} />
								</InputItem>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={this.props.submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</Form>
					)}
			</Formik>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['verify', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
export { connected as InputForm };