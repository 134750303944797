import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { orderActions } from '../../_actions';
import { userType, getOrderEditDatetimeStatusNameByStatus, orderEditDatetimeStatus, getOrderTypeByOrderStatus, orderType } from '../../_constants';
import { ResponsiveTable, InfiniteScrollWrapper, Title, PageContainer } from '../../_components';

import './EditShootingDatetimeHistory.scss';

class EditShootingDatetimeHistory extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.getEditDatetimeHistoryClear());
	}

	onRowClick(data, index) {

	}

	render() {
		const { t, auth, order, match } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		let columns = [];
		if (isVendor) {
			columns = [
				{ data_key: 'edit_datetime.created_at', key: 'created_at', name: t('submitted_time'), grid_size: '3' },
				{ data_key: 'edit_datetime.from_datetime', key: 'from_datetime', name: t('original_datetime'), grid_size: '3' },
				{ data_key: 'edit_datetime.to_datetime', key: 'to_datetime', name: t('change_to'), grid_size: '4' },
				{ data_key: 'edit_datetime.status', key: 'status', name: t('status.status'), grid_size: '2' },
			];
		} else {
			columns = [
				{ data_key: 'edit_datetime.created_at', key: 'created_at', name: t('submitted_time'), grid_size: '2' },
				{ data_key: 'edit_datetime.from_datetime', key: 'from_datetime', name: t('original_datetime'), grid_size: '3' },
				{ data_key: 'edit_datetime.to_datetime', key: 'to_datetime', name: t('change_to'), grid_size: '3' },
				{ data_key: 'edit_datetime.status', key: 'status', name: t('status.status'), grid_size: '2' },
				{ data_key: 'edit_datetime.receipt_id', key: 'receipt', grid_size: '2' },
			];
		}

		return (
			<PageContainer id="order_edit_shooting_datetime_history">
				<Container className="container-xl">
					<Title>
						{t('shooting_datetime_edit_history')}
					</Title>
					<InfiniteScrollWrapper
						elementRef={(element) => this.infiniteScrollComponent = element}
						pageStart={1}
						loadMoreProp={order.edit_datetime_history}
						onLoadMore={(nextPage) => {
							this.props.dispatch(orderActions.getEditDatetimeHistory(isVendor, match.params.id, nextPage));
						}}
						renderItems={(items) => {
							return (
								<ResponsiveTable
									t={t}
									columns={columns}
									data={items}
									renderCellValue={(column, cellData, rowData) => {
										switch (column.key) {
											case 'created_at':
												return Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma');
											case 'from_datetime':
												return Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma');
											case 'to_datetime':
												return Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma');
											case 'status':
												let statusName = getOrderEditDatetimeStatusNameByStatus(cellData);

												return (
													<div className={`${statusName} ${isVendor? 'colored' : ''}`}>{t(`status.${statusName}`)}</div>
												);
											case 'receipt':
												const showReceipt = getOrderTypeByOrderStatus(rowData.edit_datetime.status) === orderType.order && rowData.edit_datetime.status === orderEditDatetimeStatus.accepted;
												return (
													showReceipt? <Link to={`/customer/me/order/${rowData.order_id}/receipt/${cellData}`}>{t('view_receipt')}</Link> : <div>-</div>
												);
											default:
												return null; //return null to use default rendering
										}
									}}
									onRowClick={(data, index) => this.onRowClick(data, index)}
								/>
							)
						}}
						noResultDesc={t('no_edit_history')}
					/>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
	return {
		auth,
		order
	};
}

const translation = withNamespaces(['pendingitem', 'receipt', 'order', 'default'])(EditShootingDatetimeHistory);
const connected = connect(mapStateToProps)(translation);
export { connected as EditShootingDatetimeHistory };