import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';

import { userActions } from '../../_actions';

const countDownSecond = 60;

export const OTPButtonType = {
	register: 'register',
	resetPassword: 'resetPassword',
	editEmail: 'editEmail',
}

class OTPButton extends Component {
	constructor(props){
		super(props);

		this.state = {
			startedTimer: false,
			time: this.toMMSS(countDownSecond),
			seconds: countDownSecond
		};
		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.timerTick = this.timerTick.bind(this);
	}

	componentDidMount() {
		this.setState({ time: this.toMMSS(this.state.seconds) });
	}

	componentDidUpdate(prevProps, prevState) {
		const { register_send_code, forgot_password, edit_email, email, type } = this.props;
		const { startedTimer } = this.state;
	
		if (prevProps.email !== email && startedTimer) {
			this.resetTimer();
		}
	
		let shouldStartTimer = false;
	
		switch (type) {
			case OTPButtonType.register: {
				if (register_send_code && register_send_code.success && !startedTimer) {
					shouldStartTimer = true;
					this.props.dispatch(userActions.registerSendCodeClear());
				}
				break;
			}
			case OTPButtonType.resetPassword: {
				if (forgot_password && forgot_password.sendCode.success && !startedTimer) {
					shouldStartTimer = true;
					this.props.dispatch(userActions.resetPasswordByCodeClear());
				}
				break;
			}
			case OTPButtonType.editEmail: {
				if (edit_email && edit_email.sendCode.success && !startedTimer) {
					shouldStartTimer = true;
					this.props.dispatch(userActions.editEmailSendCodeClear());
				}
				break;
			}
	
			default: return;
		}
	
		if (shouldStartTimer) {
			this.setState({ startedTimer: true }, this.startTimer());
		}
	}

	onOTPSend() {
		switch (this.props.type) {
			case OTPButtonType.register: {
				this.props.dispatch(userActions.registerSendCode(this.props.user_type, this.props.email));
				break;
			}
			case OTPButtonType.resetPassword: {
				this.props.dispatch(userActions.resetPasswordSendCode(this.props.user_type, this.props.email));
				break;
			}
			case OTPButtonType.editEmail: {
				this.props.dispatch(userActions.editEmailSendCode(this.props.user_type, this.props.email));
				break;
			}

			default: return;
		}
	}

	startTimer() {
		this.timer = setInterval(this.timerTick, 1000);
	}

	timerTick() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		this.setState({
			time: this.toMMSS(seconds),
			seconds: seconds,
		});

		// Check if we're at zero.
		if (seconds === 0) {
			this.resetTimer();
		}
	}

	resetTimer() {
		clearInterval(this.timer);
		this.setState({ startedTimer: false, time: this.toMMSS(countDownSecond), seconds: countDownSecond });
	}
	
	toMMSS(sec) {
		var minutes = Math.floor(sec / 60);
		var seconds = sec - (minutes * 60);

		if (minutes < 10) { minutes = "0" + minutes; }
		if (seconds < 10) { seconds = "0" + seconds; }
		return minutes + ':' + seconds;
	}

	render() {
		const { t, disabled, register_send_code, forgot_password, edit_email } = this.props;
		const { startedTimer, time } = this.state;

		let loading = false;
		switch (this.props.type) {
			case OTPButtonType.register: {
				loading = register_send_code.loading;
				break;
			}
			case OTPButtonType.resetPassword: {
				loading = forgot_password.sendCode.loading;
				break;
			}
			case OTPButtonType.editEmail: {
				loading = edit_email.sendCode.loading;
				break;
			}

			default: break;
		}

		return(
			<Button outline block size="sm" onClick={() => this.onOTPSend()} disabled={disabled || loading || startedTimer}>
			{startedTimer ? t('email_code_resend', { time: time }) : t('email_code_send')}
			</Button>
		);
	}
}

function mapStateToProps(state) {
	const { register_send_code, forgot_password, edit_email } = state;
    return {
		register_send_code,
		forgot_password,
		edit_email,
    };
}

const translation = withNamespaces(['register', 'default'])(OTPButton);
const connected = connect(mapStateToProps)(translation);
export { connected as OTPButton };