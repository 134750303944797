import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import LinesEllipsis from 'react-lines-ellipsis';

import { history, app } from '../../../../_helpers';
import { getOrderStatusNameByData, canCompleteOrderByData, orderPaymentStatus, orderPaymentType } from '../../../../_constants';
import { RowStroked } from '../../../../_components';

import './Order.scss';

import { CompleteBookingModal } from '../../../Order/CompleteBookingModal/CompleteBookingModal';

class Order extends Component {
	constructor(props) {
		super(props);

		this.state = {
			completeBookingModalIsOpen: false,
		}
	}

	onClick() {
		const { data } = this.props;
		history.push(`/customer/me/order/${data.id}`);
	}

	onCompleteOrder(e) {
		e.stopPropagation();

		this.setState({ completeBookingModalIsOpen: true });
	}

	onVendor(e) {
		e.stopPropagation();

		const { data } = this.props;
		history.push(`/package/vendor/${data.vendor.id}`);
	}

	onPay(e) {
		e.stopPropagation();

		const { data } = this.props;
		const unpaidPayment = data.order_payments.find((item) => item.type === orderPaymentType.full_paid && item.status === orderPaymentStatus.unpaid);
		history.push(`/customer/me/order/${data.id}/payment/${unpaidPayment.id}/pay`);
	}

	render() {
		const { t, data } = this.props;
		const { completeBookingModalIsOpen } = this.state;
		const unpaidPayment = data.order_payments.find((item) => item.type === orderPaymentType.full_paid && item.status === orderPaymentStatus.unpaid);

		return (
			<div className="customer-profile-orders-order">
				<Row onClick={() => this.onClick()}>
					<Col sm={{ size: 4 }}>
						<div className="package-img-wrapper">
							<img className="package-img" src={data.package_video_image_url} alt={data.package_name} />
						</div>
					</Col>
					<Col sm={{ size: 8 }}>
						<div className="flex-content order-details">
							<Row className="order-name-status">
								<Col xs={{ size: true }}>
									<LinesEllipsis
										className="name"
										text={data.package_name}
										maxLine='2'
										ellipsis='...'
										trimRight
										basedOn='words'
									/>
								</Col>
								<Col xs={{ size: 'auto' }}>
									<div className="status">
										{t(`status.${getOrderStatusNameByData(data)}`)}
									</div>
								</Col>
							</Row>
							<div className="price">
								{app.toCurrencyString(data.currency.value, Number(data.package_amount) + Number(data.additional_amount))}
							</div>
							<RowStroked className="order-datetime-vendor">
								<Col sm={{ size: 'auto' }}>
									<div className="shooting-datetime">
										{data.is_shoot_datetime_updated?
											`${Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).format('D MMM YYYY h:mma')} ${data.timezone.offset}`
											:
											`${Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).format('D MMM YYYY')} ${data.timezone.offset}`
										}
									</div>
								</Col>
								<Col sm={{ size: 'auto' }}>
									<div className="vendor">
										<Button color="link" onClick={(e) => this.onVendor(e)}>{data.vendor.nickname}</Button>
									</div>
								</Col>
							</RowStroked>
							{unpaidPayment &&
								<Row className="payment">
									<Col md={{ size: true }} className="col-remarks">
										<div className="remarks" dangerouslySetInnerHTML={{ __html: t('payment_remarks.customer', { date: Moment.utc(unpaidPayment.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma') }) }}></div>
									</Col>
									<Col md={{ size: 'auto' }}>
										<Button size="sm" block onClick={(e) => this.onPay(e)}>{t('pay_now')}</Button>
									</Col>
								</Row>
							}
							{canCompleteOrderByData(data) &&
								<Row className="complete-order">
									<Col md={{ size: true }} className="col-remarks">
										<div className="remarks">{t('would_you_like_to_complete_the_order')}</div>
									</Col>
									<Col md={{ size: 'auto' }}>
										<Button block onClick={(e) => this.onCompleteOrder(e)}>{t('complete')}</Button>
									</Col>
								</Row>
							}
						</div>
					</Col>
				</Row>
				<CompleteBookingModal
					order_id={data.id}
					isOpen={completeBookingModalIsOpen}
					toggle={() => this.setState({ completeBookingModalIsOpen: !this.state.completeBookingModalIsOpen })}
				/>
			</div>
		);
	}
}

// function mapStateToProps(state) {
// 	const { order } = state;
// 	return {
// 		order
// 	};
// }

const translation = withNamespaces(['order', 'payment'])(Order);
// const connected = connect(mapStateToProps)(translation);
export { translation as Order };