import React, { Component } from 'react';
import _ from 'lodash';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap } from "react-google-maps";
import StandaloneSearchBox from "react-google-maps/lib/components/places/StandaloneSearchBox";

import { Loading } from '../../../../../_components';
import { Map } from '../../../../../Order';

import './LocationAutocomplete.scss';

class LocationAutocomplete extends Component {
	constructor(props) {
		super(props);

		const { locationName, locationLat, locationLng } = props;

		let selectedPlace = null;
		if (locationName && locationLat && locationLng) {
			selectedPlace = {};
			selectedPlace.name = locationName;
			selectedPlace.geometry = {
				location: {
					lat: function() { return Number(locationLat); }, lng: function() { return Number(locationLng); }
				}
			}

		}

		this.state = {
			selectedPlace: selectedPlace,
			searchText: null
		}
	}

	onPlacesChanged() {
		const places = this.searchBox.getPlaces();

		if (!places.length) {
			if (this.props.onPlaceSelected) {
				this.props.onPlaceSelected(null);
			}
			return;
		}

		/* eslint-disable no-undef */
		const bounds = new google.maps.LatLngBounds();

		places.forEach(place => {
			if (place.geometry.viewport) {
				bounds.union(place.geometry.viewport)
			} else {
				bounds.extend(place.geometry.location)
			}
		});

		this.setState({
			searchText: null,
			selectedPlace: places[0],
			bounds
		});
		// refs.map.fitBounds(bounds);

		if (this.props.onPlaceSelected) {
			this.props.onPlaceSelected(places[0]);
		}
	}

	render() {
		const { placeholder, showError } = this.props;
		const { selectedPlace, searchText, bounds } = this.state;

		return (
			<div id="vendor_order_meetup_location_autocomplete">
				<StandaloneSearchBox
					ref={(ref) => this.searchBox = ref}
					bounds={bounds}
					onPlacesChanged={() => this.onPlacesChanged()}
				>
					<input
						type="text"
						className={`${showError? 'input-error' : ''}`}
						placeholder={placeholder}
						value={searchText? searchText : selectedPlace? selectedPlace.name : ''}
						onChange={(e) => {
							let text = e.target.value;

							if (selectedPlace && selectedPlace.name !== text) {
								this.setState({ selectedPlace: null });

								if (this.props.onPlaceSelected) {
									this.props.onPlaceSelected(null);
								}
							}

							this.setState({ searchText: text });
						}}
					/>
				</StandaloneSearchBox>
				{selectedPlace &&
					<Map
						center={{
							lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng()
						}}
						markers={[{
							position : {
								lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng()
							},
							name: selectedPlace.name
						}]}
					/>
				}
			</div>
		);
	}
}

const composed = compose(
	withProps({
		googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB9CZzgIyjkh9KiulCd8-dHZtson4SeJ7o",
		loadingElement: <Loading />,
		containerElement: <div />,
		mapElement: <div />,
	}),
	withScriptjs,
	withGoogleMap
)(LocationAutocomplete);
export { composed as LocationAutocomplete };