import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';

import { getPayoutStatusNameByStatus, payoutStatus } from '../../../_constants';
import { payoutActions } from '../../../_actions';
import { PageContainer, Title, Desc, CardsContainer, RowCards, ColCards, LoadingPage, ErrorPage } from '../../../_components';

import './Payout.scss';

import { PayoutSettings, PayoutSummary } from '../Card';

import img_tips from './img/order_details_status_icon_question.svg';
import { StatusInfoModal } from './StatusInfoModal/StatusInfoModal';

class Payout extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			statusInfoModalIsOpen: false
		}
	}
	
	componentDidMount() {
		this.props.dispatch(payoutActions.get(this.props.match.params.id));
	}

	onStatusInfo() {
		this.setState({ statusInfoModalIsOpen: true });
	}

	render() {
		const { t, match, payout, payout_settings_form_info } = this.props;
		const { statusInfoModalIsOpen } = this.state;

		return (
			<>
				{payout.loading && <LoadingPage />}
				{payout.error && <ErrorPage match={this.props.match} error={payout.error} />}
				{payout.data &&
					<PageContainer id="vendor_profile_payout">
						<CardsContainer>
							<Row className="row-title">
								<Col sm={{ size: true }}>
									<Title>{`${t('payout')} - ${t('order_number')}${payout.data.order_id}`}</Title>
								</Col>
								{payout.data.status === payoutStatus.success &&
									<Col sm={{ size: 'auto' }}>
										<Link to={`${match.url}/receipt`} className={`btn btn-block btn-primary btn-view-receipt ${payout_settings_form_info.data ? '' : 'disabled'}`}>{t('view_receipt')}</Link>
									</Col>
								}
							</Row>
							<RowCards>
								<ColCards xl={{ size: 6 }}>
									<Card className="card-payout-status">
										<CardBody>
											<div className="status-info">
												<div className="status-info-title">{t('status.status')}</div>
												<Button color="link" className="btn btn-tips" onClick={() => this.onStatusInfo()}><img src={img_tips} alt={t('status.status')} /></Button>
											</div>
											<div className={`status ${getPayoutStatusNameByStatus(payout.data.status)}`}>
												{t(`status.${getPayoutStatusNameByStatus(payout.data.status)}`)}
											</div>
											<Desc>
												{t(`status_desc.${getPayoutStatusNameByStatus(payout.data.status)}`)}
											</Desc>
										</CardBody>
									</Card>
									<PayoutSettings data={payout.data} />
								</ColCards>
								<ColCards xl={{ size: 6 }}>
									<PayoutSummary data={payout.data} />
								</ColCards>
							</RowCards>
						</CardsContainer>
						<StatusInfoModal
							isOpen={statusInfoModalIsOpen}
							toggle={() => this.setState({ statusInfoModalIsOpen: !this.state.statusInfoModalIsOpen })}
							status={payout.data.status}
						/>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { payout, payout_settings_form_info } = state;
	return {
		payout,
		payout_settings_form_info
	};
}

const translation = withNamespaces(['payout', 'payment', 'receipt', 'order', 'package'])(Payout);
const connected = connect(mapStateToProps)(translation);
export { connected as Payout };