import React from "react";
import { Link } from 'react-router-dom';

import { Desc, Title } from '../../../../../../_components';

import { BaseMessage } from '../../BaseMessage';

import './GeneralMessage.scss';

export const GeneralMessage = ({ t, message, className, systemMessageData, ...props}) => {
	return (
		<BaseMessage
			message={message}
			className={`chat-message-system-general ${className ? className : ''}`}
			bubbleMarginSize="lg"
			{...props}
		>
			<Title size="xs" className="message-title">
				{systemMessageData.title}
			</Title>
			<Desc className="message">
				{systemMessageData.desc}
			</Desc>
			{systemMessageData.url &&
				<Link to={systemMessageData.url} className="btn btn-sm btn-primary">
					{t(systemMessageData.btnTitleKey)}
				</Link>
			}
		</BaseMessage>
	);
}