import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { InfiniteScrollWrapper } from '../InfiniteScrollWrapper';

import './ResultsGrid.scss';

class ResultsGrid extends Component {
	render() {
		const { t, disableInitialLoad, showHeader, noOfCol_xxl, noOfCol_xl, noOfCol_lg, noOfCol_md, noOfCol_sm, renderItem, onLoadMore, loadMoreProp, infiniteScrollComponentRef, noResultDesc } = this.props;

		const col_sm = noOfCol_sm? noOfCol_sm : 2;
		const col_md = noOfCol_md? noOfCol_md : 3;
		const col_lg = noOfCol_lg? noOfCol_lg : 4;
		const col_xl = noOfCol_xl? noOfCol_xl : 4;
		const col_xxl = noOfCol_xxl? noOfCol_xxl : 4;

		return (
			<div className="items-grid">
				{showHeader && loadMoreProp && loadMoreProp.success &&
					<div className="items-grid-header">
						{t('n_results', { n: loadMoreProp.total })}
					</div>
				}
				<InfiniteScrollWrapper
					elementRef={(element) => {
						if (infiniteScrollComponentRef) {
							infiniteScrollComponentRef(element);
						} else {
							this.infiniteScrollComponent = element;
						}
					}}
					disableInitialLoad={disableInitialLoad}
					loadMoreProp={loadMoreProp}
					onLoadMore={(nextPage) => onLoadMore(nextPage)}
					didLoadMore={(nextPage, items, hasMore) => this.setState({ totalItems: items.length })}
					renderItems={(items) => {
						return (
							<Row>
								{items.map((item, index) =>
									<Col key={index} sm={{ size: `${12 / col_sm}` }} md={{ size: `${12 / col_md}` }} lg={{ size: `${12 / col_lg}` }} xl={{ size: `${12 / col_xl}`}} className={`col-xxl-${12 / col_xxl}`}>
										{renderItem(item)}
									</Col>
								)}
							</Row>
						)
					}}
					noResultDesc={noResultDesc}
				/>
			</div>
		);
	}
}

const translation = withNamespaces(['default'])(ResultsGrid);
export { translation as ResultsGrid };