import React, { Component } from 'react';
import { connect } from 'formik';

import { LeaveConfirmPrompt } from '../LeaveConfirmPrompt';

class InputFormLeaveConfirmPrompt extends Component {
	render() {
		const { formik ,message, disabled } = this.props;
		const { touched } = formik;

		return(
			<LeaveConfirmPrompt disabled={disabled || Object.keys(touched).length === 0} message={message}/>
		);
	}
}

const connected = connect(InputFormLeaveConfirmPrompt);
export { connected as InputFormLeaveConfirmPrompt };