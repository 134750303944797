import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../../_actions';
import { Loading } from '../../../_components';

import './AfterShootingMaterials.scss';

import { AfterShootingMaterialsDetails } from '../../../Order';

class AfterShootingMaterials extends Component {
	componentDidMount() {
		const { data } = this.props;
		this.props.dispatch(orderActions.getAfterShootingMaterials(false, data.id));
	}

	render() {
		const { t, order } = this.props;

		return (
			<div id="customer_order_after_shooting_materials">
				{order.materials.loading && <Loading />}
				{order.materials.error && <Loading error={order.materials.error} />}
				{order.materials.success && !order.materials.data && <div className="no-result">{t('vendor_havent_added_yet')}</div>}
				{order.materials.success && order.materials.data &&
					<AfterShootingMaterialsDetails data={order.materials.data} />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order,
	};
}

const translation = withNamespaces(['order'])(AfterShootingMaterials);
const connected = connect(mapStateToProps)(translation);
export { connected as AfterShootingMaterials };