import { 
    INIT_OPEN_CHANNEL_CREATE,
    OPEN_CHANNEL_CREATE_SUCCESS,
	OPEN_CHANNEL_CREATE_FAIL,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    channel: null
}

export function openChannelCreate (state = INITIAL_STATE, action) {
    switch(action.type) {
        case INIT_OPEN_CHANNEL_CREATE:
            return { ...state, ...INITIAL_STATE };
        case OPEN_CHANNEL_CREATE_SUCCESS: 
            return { ...state, ...INITIAL_STATE, channel: action.channel };
        case OPEN_CHANNEL_CREATE_FAIL: 
			return { ...state, ...INITIAL_STATE, error: action.error };
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
			return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}
