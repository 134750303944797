import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';

import { orderActions } from '../../../../_actions';
import { userType, getOrderTypeByOrderStatus, orderType } from '../../../../_constants';
import { ActionModal } from '../../../../_components';

import './EditModal.scss';

import { InputForm } from './InputForm';

class EditModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.editDatetimeClear());
	}

	onSubmit(values, { setSubmitting }) {
		let shoot_datetime = Moment(`${values.shooting_date} ${values.shooting_time_h}:${values.shooting_time_m}${values.shooting_time_a}`, 'YYYY-MM-DD h:mma').format('YYYY-MM-DD HH:mm:ss');
		values['shoot_datetime'] = shoot_datetime;

		const { data, auth } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;
		this.props.dispatch(orderActions.editDatetime(isVendor, data.id, values, getOrderTypeByOrderStatus(data.status) === orderType.deal));
	}

	render() {
		const { t, data, order, isOpen } = this.props;

		const originalShootingDatetime = Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset);
		return (
			<ActionModal
				id="order_edit_shooting_datetime_modal"
				confirmComponent={
					<InputForm
						vendor_id={data.vendor.id}
						order_id={data.id}
						selectedDate={originalShootingDatetime}
						selectedTime={data.is_shoot_datetime_updated? originalShootingDatetime : undefined}
						includeRemarks={getOrderTypeByOrderStatus(data.status) !== orderType.deal}
						submitting={order.edit_datetime.loading}
						onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
						toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t('edit_date.success.title')}
				successDesc={t('edit_date.success.desc')}
				isLoading={order.edit_datetime.loading}
				isSuccess={order.edit_datetime.success}
				error={order.edit_datetime.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.editDatetimeClear());
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
    return {
		auth,
		order
    };
}

const translation = withNamespaces(['order'])(EditModal);
const connected = connect(mapStateToProps)(translation);
export { connected as EditModal };