import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Container, Row, Col } from 'reactstrap';
import { withNamespaces } from 'react-i18next';

import { appActions } from '../../../_actions';
import { Title, Loading } from '../../../_components';

import './Customer.scss';
// import img_placeholder from './img/FFFFFF-0.png';
import social_network_img_fb from './img/footer_icon_fb.svg';
import social_network_img_ig from './img/footer_icon_instagram.svg';
import social_network_img_w7 from './img/footer_icon_wechat.svg';
import social_network_img_wb from './img/footer_icon_weibo.svg';

class Customer extends Component {
	componentDidMount() {
		this.props.dispatch(appActions.getCurrencies());
	}


	onSelectCurrency(item) {
		this.props.dispatch(appActions.setCurrency(item));
		// window.location.reload();
	}

    render() {
		const { t, languages, selectedLanguage, onSelectLanguage, app } = this.props;

		return (
			<div id="customer_footer" className="footer-wrapper">
				<Container className="footer-content">
					<Row>
						<Col xl={{ size: 'auto' }}>
							<Title>
								{t('weddinguno.title')}
							</Title>
							<ul>
								<li><Link to="/about_us">{t('weddinguno.about_us')}</Link></li>
								<li><Link to="/contact_us">{t('weddinguno.contact_us')}</Link></li>
								<li><Link to="/help">{t('weddinguno.help_&_support')}</Link></li>
								<li><Link to="/tnc">{t('weddinguno.terms_&_conditions')}</Link></li>
								<li><Link to="/policy">{t('weddinguno.privacy_policy')}</Link></li>
							</ul>
						</Col>
						<Col xl={{ size: 'auto' }}>
							<Title>
								{t('discover.title')}
							</Title>
							<ul>
								<li><Link to="/vendor">{t('discover.join_as_photographer')}</Link></li>
							</ul>
						</Col>
						<Col xl={{ size: true }} className="settings">
							<Title>
								{t('regional_settings.title')}
							</Title>
							<ul>
								<li>
									<UncontrolledButtonDropdown>
										<DropdownToggle caret className="btn-outline-primary">
											{t('regional_settings.language.title')} : {selectedLanguage ? selectedLanguage.value : ''}
										</DropdownToggle>
										<DropdownMenu>
											{languages.map((item, index) =>
												<DropdownItem key={item.id} onClick={() => onSelectLanguage(item)}>{item.value}</DropdownItem>
											)}
										</DropdownMenu>
									</UncontrolledButtonDropdown>
								</li>
								<li>
									{app.currency.loading && <Loading />}
									{app.currency.list &&
										<UncontrolledButtonDropdown>
											<DropdownToggle caret className="btn-outline-primary" disabled={app.error}>
												{t('regional_settings.currency.title')} : {app.currency.currency ? app.currency.currency.value : ''}
											</DropdownToggle>
											<DropdownMenu>
												{app.currency.list && app.currency.list.map((item, index) =>
													<DropdownItem key={item.id} onClick={() => this.onSelectCurrency(item)}>{item.value}</DropdownItem>
												)}
											</DropdownMenu>
										</UncontrolledButtonDropdown>
									}
								</li>
							</ul>
						</Col>
						{/* TODO: our social media links */}
						<Col xl={{ size: 'auto' }} className="social-medias">
							<Link to="/" title={t('social_media.Facebook')}><img src={social_network_img_fb} alt={t('social_media.Facebook')} /></Link>
							<Link to="/" title={t('social_media.Instagram')}><img src={social_network_img_ig} alt={t('social_media.Instagram')} /></Link>
							<Link to="/" title={t('social_media.WeChat')}><img src={social_network_img_w7} alt={t('social_media.WeChat')} /></Link>
							<Link to="/" title={t('social_media.Weibo')}><img src={social_network_img_wb} alt={t('social_media.Weibo')} /></Link>
						</Col>
					</Row>
				</Container>
				<hr />
				<Container className="footer-content footer-content-copyright">
					<Row>
						<Col xl={{ size: true }}>
							<span className="copyright">{t('copyright')}</span>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['footer'])(Customer);
const connected = connect(mapStateToProps)(translation);
export { connected as Customer };