import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Container, Row, Col } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import {
	VERTICAL_ORIENTATION,
	HORIZONTAL_ORIENTATION
} from 'react-dates/constants';
import { Helmet } from "react-helmet";

import { app as appHelper } from '../../_helpers';
import { headerActions, packageActions } from '../../_actions';

import { ResultsGrid, Title, Desc, PageContainer, LoadingPage, ErrorPage } from '../../_components';
import { GridItem } from '../GridItem';

import '../../_common/date_range_picker.scss';
import './Packages.scss';
import img_calendar_arrow from '../img/filter_icon_arrow.svg';
import img_calendar_clear from '../img/filter_icon_close.svg';

export const PackagesPageFilterType = {
	destination: 'destination',
	category: 'category'
}

const initialState = {
	page_filter_type: undefined,
	page_filter_id: undefined,
	currency: undefined,
	destination: undefined,
	category: undefined,
	price: undefined,
	sorting: undefined,
	startDate: undefined,
	endDate: undefined,
	calendarFocusedInput: undefined,
}

class Packages extends Component {
	constructor(props) {
		super(props);

		let state = initialState;
		const { match } = props;
		state['page_filter_type'] = match.params.filter_type;
		state['page_filter_id'] = match.params.filter_id;

		this.state = state;
	}

	componentDidMount() {
		this.props.dispatch(packageActions.getListClear());
		this.props.dispatch(packageActions.getFilterItems());
	}

	componentWillUnmount() {
		this.props.dispatch(headerActions.solidStyle(true));
	}

	componentDidUpdate(prevProps, prevState) {
		const { location, packages } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		if (packages.filter_items.success && packages.filter_items.success !== prevProps.packages.filter_items.success) {
			this.updateStateWithFilterItemsData(packages.filter_items.data);
		}
	}
	
	updateStateWithFilterItemsData(filterItemsData) {
		const { page_filter_type, page_filter_id } = this.state
		let pageFilterItem = this.state.destination;
		switch (page_filter_type) {
			case PackagesPageFilterType.destination:
				pageFilterItem = this.state.destination;
				break;
			case PackagesPageFilterType.category:
				pageFilterItem = this.state.category;
				break;
			default:
				break;
		}

		//init
		if (!this.state.currency || !this.state.sorting || (page_filter_type && page_filter_id && !pageFilterItem)) {
			let defaultSorting = filterItemsData.sortings[0];

			let pageFilterKey = 'destination_groups';
			switch (page_filter_type) {
				case PackagesPageFilterType.destination:
					pageFilterKey = 'destination_groups';
					break;
				case PackagesPageFilterType.category:
					pageFilterKey = 'category_groups';
					break;
				default:
					break;
			}

			let default_page_filter_item_group = filterItemsData[pageFilterKey].find(group =>
				group.items.some(item => item.id == page_filter_id)
			);
			let default_page_filter_item = undefined;

			if (default_page_filter_item_group) {
				default_page_filter_item = default_page_filter_item_group.items.find(item => item.id == page_filter_id);
			}

			if (default_page_filter_item) {
				this.props.dispatch(headerActions.solidStyle(false));
			}

			this.setState({
				currency: filterItemsData.currency,
				sorting: defaultSorting,
				[this.state.page_filter_type]: default_page_filter_item
			}, () => this.getResult());
		}
	}

	onRouteChanged(props) {
		const { match } = props;

		let type = match.params.filter_type;
		let id = match.params.filter_id;
		if (this.state.page_filter_type == type && this.state.page_filter_id == id) return;

		//1. updated page destination/category by query string
		//2. reset to initialState
		let state = initialState;
		state['page_filter_type'] = type;
		state['page_filter_id'] = id;
		this.setState(state, () => this.updateStateWithFilterItemsData(this.props.packages.filter_items.data));

		//3. update results by updated destination/category
	}

	onSelectDestination(destination) {
		if (this.state.destination === destination) return;
		this.setState({ destination }, () => this.getResult());
	}

	onSelectCategory(category) {
		if (this.state.category === category) return;
		this.setState({ category }, () => this.getResult());
	}

	onSelectPrice(price) {
		if (this.state.price === price) return;
		this.setState({ price }, () => this.getResult());
	}

	onCalendar() {
		if (this.state.calendarFocusedInput) return;
		this.setState({ calendarFocusedInput: 'startDate' });
	}

	onSelectDateRange(startDate, endDate) {
		if (this.state.startDate === startDate && this.state.endDate === endDate) return;
		this.setState({ startDate, endDate }, () => {
			if (!this.state.calendarFocusedInput) {
				this.getResult();
			}
		});
	}

	onSelectSorting(sorting) {
		if (this.state.sorting === sorting) return;
		this.setState({ sorting }, () => {
			this.getResult();
		});
	}

	getResult() {
		if (!this.infiniteScrollComponent) return;
		this.infiniteScrollComponent.reload();
	}

	render() {
		const { t, packages, app, location } = this.props;
		const {
			page_filter_type,
			currency,
			destination,
			category,
			price,
			sorting,
			startDate,
			endDate,
			calendarFocusedInput,
		} = this.state;

		let bannerItem = page_filter_type === PackagesPageFilterType.destination? destination : category;

		let destinations = [{ id: null, value: t('all_destinations'), items: [] }];
		let categories = [{ id: null, value: t('all_categories'), items: [] }];
		let prices = [{ min_amount: null, max_amount: null, value: t('all_price') }];
		let sortings = [];
		if (packages.filter_items.data) {
			destinations = destinations.concat(packages.filter_items.data.destination_groups);
			categories = categories.concat(packages.filter_items.data.category_groups);
			prices = prices.concat(packages.filter_items.data.price_items);
			sortings = packages.filter_items.data.sortings;
		}

		let showCalendarFromTo = (startDate || endDate || calendarFocusedInput);

		const smallDevice = app.windowSize.width < 770;
		const calendarOrientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

		//if package_getFilterItems = success but bannerItem = null => the item referred by filter_item_id is not found => show ErrorPage

		const loading = packages.filter_items.loading;
		const error = packages.filter_items.error? packages.filter_items.error : (packages.filter_items.data && !bannerItem)? { status_code: 404 } : undefined;

		return (
			<>
				{loading && <LoadingPage />}
				{error && <ErrorPage match={this.props.match} error={error} />}
				{!loading && !error && packages.filter_items.data && bannerItem &&
				<>
					<Helmet>
						<meta property="og:title" content={`${t('og_title')} - ${bannerItem.value}`} />
						<meta property="og:description" content={bannerItem.desc} />
						<meta property="og:image" content={bannerItem.image_url} />

						<meta property="twitter:title" content={`${t('og_title')} - ${bannerItem.value}`} />
						<meta property="twitter:description" content={bannerItem.desc} />
						<meta property="twitter:image" content={bannerItem.image_url} />
					</Helmet>
					<PageContainer fullPage id="packages">
						<div className="top-banner">
							<div className="banner-info-container">
								<div className="banner-info-content" style={{ backgroundColor: `#${bannerItem.theme_color}55` }}>
									<div className="banner-info-inner">
										<Title size='xl'>{bannerItem.value}</Title>
										<Desc>{bannerItem.desc}</Desc>
									</div>
								</div>
							</div>
							<img src={bannerItem.image_url} alt={bannerItem.value} />
						</div>
						<div className="header-container">
							<Container>
								<Title className="banner-title">{bannerItem.value}</Title>
								<Row className="filter-sort row-m-8">
									<Col lg={{ size: 'auto' }} className={((page_filter_type === PackagesPageFilterType.destination) ? ' d-none' : '')}>
										<UncontrolledButtonDropdown>
											<DropdownToggle caret className="btn-outline-primary">
												{destination ? destination.value : t('all_destinations')}
											</DropdownToggle>
											<DropdownMenu>
												{destinations.map((group, index) =>
													group.id ?
														<div key={group.id}>
															<DropdownItem header>{group.name}</DropdownItem>
															{group.items.map((item, index) =>
																<DropdownItem key={item.id} onClick={() => this.onSelectDestination(item)}>{item.value}</DropdownItem>
															)}
														</div>
														:
														<DropdownItem key='all' onClick={() => this.onSelectDestination(group)}>{group.value}</DropdownItem>
												)}
											</DropdownMenu>
										</UncontrolledButtonDropdown>
									</Col>
									<Col lg={{ size: 'auto' }} className={((page_filter_type === PackagesPageFilterType.category) ? ' d-none' : '')}>
										<UncontrolledButtonDropdown>
											<DropdownToggle caret className="btn-outline-primary">
												{category ? category.value : t('all_categories')}
											</DropdownToggle>
											<DropdownMenu>
												{categories.map((group, index) =>
													group.id ?
														<div key={group.id}>
															<DropdownItem header>{group.name}</DropdownItem>
															{group.items.map((item, index) =>
																<DropdownItem key={item.id} onClick={() => this.onSelectCategory(item)}>{item.value}</DropdownItem>

															)}
														</div>
														:
														<DropdownItem key='all' onClick={() => this.onSelectCategory(group)}>{group.value}</DropdownItem>
												)}
											</DropdownMenu>
										</UncontrolledButtonDropdown>
									</Col>
									<Col lg={{ size: 'auto' }}>
										<UncontrolledButtonDropdown>
											<DropdownToggle caret className="btn-outline-primary">
												{currency ? price ? price.value ? price.value : `${appHelper.toCurrencyString(currency.value, price.min_amount)} - ${appHelper.toCurrencyString(currency.value, price.max_amount)}` : t('all_price') : t('loading')}
											</DropdownToggle>
											{currency &&
												<DropdownMenu>
													{prices.map((item, index) =>
														item.min_amount ?
															<DropdownItem key={index} onClick={() => this.onSelectPrice(item)}>{`${appHelper.toCurrencyString(currency.value, item.min_amount)} - ${appHelper.toCurrencyString(currency.value, item.max_amount)}`}</DropdownItem>
															:
															<DropdownItem key={index} onClick={() => this.onSelectPrice(item)}>{item.value}</DropdownItem>
													)}
												</DropdownMenu>
											}
										</UncontrolledButtonDropdown>
									</Col>
									<Col lg={{ size: true }}>
										{showCalendarFromTo ?
											<DateRangePicker
												startDateId="startDate"
												endDateId="endDate"
												startDate={startDate}
												endDate={endDate}
												onDatesChange={({ startDate, endDate }) => this.onSelectDateRange(startDate, endDate)}
												focusedInput={calendarFocusedInput}
												onFocusChange={(focusedInput) => this.setState({ calendarFocusedInput: focusedInput })}
												showClearDates={true}
												customArrowIcon={<img src={img_calendar_arrow} alt='->' />}
												customCloseIcon={<img src={img_calendar_clear} alt='close' />}
												hideKeyboardShortcutsPanel={true}
												startDatePlaceholderText={t('from')}
												endDatePlaceholderText={t('to')}
												renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (<span><span className="big">{month.format('MM')}</span><span className="stroke">|</span>{month.format('MMM YYYY')}</span>)}
												weekDayFormat='ddd'
												orientation={calendarOrientation}
												withFullScreenPortal={smallDevice}
												readOnly
											/>
											:
											<Button outline className='dropdown-toggle' onClick={() => this.onCalendar()}>{t('all_dates')}</Button>
										}
									</Col>
									<Col lg={{ size: 'auto' }} className="col-sorting">
										<Row className="row-m-8">
											<Col xs={{ size: 'auto' }}>
												<span>{t('sort_by')}</span>
											</Col>
											<Col xs={{ size: true }}>
												<UncontrolledButtonDropdown>
													<DropdownToggle caret className="btn-outline-primary">
														{sorting ? sorting.value : '--'}
													</DropdownToggle>
													<DropdownMenu>
														{sortings.map((item, index) =>
															<DropdownItem key={item.id} onClick={() => this.onSelectSorting(item)}>{item.value}</DropdownItem>
														)}
													</DropdownMenu>
												</UncontrolledButtonDropdown>
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>
						</div>
						<Container className="results-container">
							<div className="results">
								<ResultsGrid
									infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
									showHeader={true}
									noOfCol_lg={4}
									noOfCol_md={3}
									noOfCol_sm={2}
									loadMoreProp={packages}
									onLoadMore={(nextPage) => {
										const { page_filter_type, currency, sorting, category, destination, price, startDate, endDate } = this.state;
										if (!sorting) return;
										let pageFilterItem = destination;
										switch (page_filter_type) {
											case PackagesPageFilterType.destination:
												pageFilterItem = destination;
												break;
											case PackagesPageFilterType.category:
												pageFilterItem = category;
												break;
											default:
												break;
										}
										if (!pageFilterItem) return;
										let start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
										let end_date = endDate ? endDate.format('YYYY-MM-DD') : undefined;
										this.props.dispatch(packageActions.getList(currency, sorting, category, destination, price, start_date, end_date, nextPage));
									}}
									renderItem={(item) => {
										return <GridItem item={item} hideBookmark />;
									}}
									noResultDesc={t('no_result_found')}
								/>
							</div>
						</Container>
					</PageContainer>
				</>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { packages, app } = state; 
    return {
		packages,
		app
    };
}

const translation = withNamespaces(['package', 'default'])(Packages);
const connected = connect(mapStateToProps)(translation);
export { connected as Packages };