import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Profile } from './Profile';

const ProfileTabPathnames = ['reviews', 'packages'];

export class ProfileRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<Route path={`${match.path}/:tab(${ProfileTabPathnames.join('|')})`} component={Profile} />
				<Route exact path={`${match.path}`} component={Profile} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}