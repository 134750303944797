import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';

import { Title, Desc } from '../../_components';
import { StaticPageContainer } from '../StaticPageContainer';

import './AboutUs.scss';

import img_about_us from './img/image_about_us.jpg';

class AboutUs extends Component {
	render() {
		const { t, match } = this.props;

		return (
			<StaticPageContainer id="about_us" match={match}>
				<Container>
					<Title className="title-page">
						{t('about_us')}
					</Title>
					<Row>
						<Col md={{ size: 6 }}>
							<img src={img_about_us} alt="" />
						</Col>
						<Col md={{ size: 6 }}>
							<div className="section">
								<Title size="sm">
									{t('story.title')}
								</Title>
								<Desc>
									{t('story.desc')}
								</Desc>
							</div>
							<div className="section">
								<Title size="sm">
									{t('vision.title')}
								</Title>
								<Desc>
									{t('vision.desc')}
								</Desc>
							</div>
						</Col>
					</Row>
				</Container>
			</StaticPageContainer>
		);
	}
}

const translation = withNamespaces(['about_us'])(AboutUs);
export { translation as AboutUs };