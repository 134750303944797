export const packageConstants = {
    GET_DESTINATIONS_REQUEST: 'PACKAGE_GET_DESTINATIONS_REQUEST',
    GET_DESTINATIONS_SUCCESS: 'PACKAGE_GET_DESTINATIONS_SUCCESS',
	GET_DESTINATIONS_FAILURE: 'PACKAGE_GET_DESTINATIONS_FAILURE',
	GET_DESTINATIONS_CLEAR: 'PACKAGE_GET_DESTINATIONS_CLEAR',

	GET_CATEGORIES_REQUEST: 'PACKAGE_GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'PACKAGE_GET_CATEGORIES_SUCCESS',
	GET_CATEGORIES_FAILURE: 'PACKAGE_GET_CATEGORIES_FAILURE',
	GET_CATEGORIES_CLEAR: 'PACKAGE_GET_CATEGORIES_CLEAR',

	GET_FILTER_ITEMS_REQUEST: 'PACKAGE_GET_FILTER_ITEMS_REQUEST',
    GET_FILTER_ITEMS_SUCCESS: 'PACKAGE_GET_FILTER_ITEMS_SUCCESS',
	GET_FILTER_ITEMS_FAILURE: 'PACKAGE_GET_FILTER_ITEMS_FAILURE',
	GET_FILTER_ITEMS_CLEAR: 'PACKAGE_GET_FILTER_ITEMS_CLEAR',

	GET_SORT_ITEMS_REQUEST: 'PACKAGE_GET_SORT_ITEMS_REQUEST',
    GET_SORT_ITEMS_SUCCESS: 'PACKAGE_GET_SORT_ITEMS_SUCCESS',
	GET_SORT_ITEMS_FAILURE: 'PACKAGE_GET_SORT_ITEMS_FAILURE',
	GET_SORT_ITEMS_CLEAR: 'PACKAGE_GET_SORT_ITEMS_CLEAR',

	GET_LIST_REQUEST: 'PACKAGE_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'PACKAGE_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'PACKAGE_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'PACKAGE_GET_LIST_CLEAR',

	GET_REQUEST: 'PACKAGE_GET_REQUEST',
    GET_SUCCESS: 'PACKAGE_GET_SUCCESS',
	GET_FAILURE: 'PACKAGE_GET_FAILURE',
	GET_CLEAR: 'PACKAGE_GET_CLEAR',

	GET_LOCATIONS_REQUEST: 'PACKAGE_GET_LOCATIONS_REQUEST',
    GET_LOCATIONS_SUCCESS: 'PACKAGE_GET_LOCATIONS_SUCCESS',
	GET_LOCATIONS_FAILURE: 'PACKAGE_GET_LOCATIONS_FAILURE',
	GET_LOCATIONS_CLEAR: 'PACKAGE_GET_LOCATIONS_CLEAR',

	GET_REVIEWS_REQUEST: 'PACKAGE_GET_REVIEWS_REQUEST',
    GET_REVIEWS_SUCCESS: 'PACKAGE_GET_REVIEWS_SUCCESS',
	GET_REVIEWS_FAILURE: 'PACKAGE_GET_REVIEWS_FAILURE',
	GET_REVIEWS_CLEAR: 'PACKAGE_GET_REVIEWS_CLEAR',
}

export const packageStatus = {
	draft: 1,
	published: 2,
	deleted: 3,
	unpublished: 4,
	suspended: 5
}

export function getPackageStatusNameByStatus(status) {
	switch (status) {
		case packageStatus.draft: return 'draft';
		case packageStatus.published: return 'published';
		case packageStatus.deleted: return 'deleted';
		case packageStatus.unpublished: return 'unpublished';
		case packageStatus.suspended: return 'suspended';
	
		default: return null;
	}
}