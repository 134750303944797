import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
	injectStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement, } from 'react-stripe-elements';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';

import { app } from '../../../../_helpers';
import { orderActions } from '../../../../_actions';
import { InputItem, InputItemLabel, InputField, InputFieldRemarks, Title, Loading, PageContainer, LoadingPage, ErrorPage, Desc, BaseModal } from '../../../../_components';
import { Payment } from '../../../../Order';

import './InputForm.scss';

import img_visa from './img/order_payment_icon_visa.svg';
import img_master from './img/order_payment_icon_master.svg';
import img_ae from './img/order_payment_icon_ae.svg';

const StripeElementStyle = {
	base: {
		fontSize: '14px',
		fontFamily: '"Roboto Slab", serif',
		color: '#333333',
		'::placeholder': {
			color: '#a6a6a6',
		}
	},
	invalid: {
		color: '#333333',
	},
};

class InputForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			payingPayment: null,
			payingOrder: null,
			shouldUpdatePayment: 1,
			errorModalIsOpen: false
		}
	}

	componentDidMount() {
		this.reloadPayment();
	}

	componentDidUpdate(prevProps, prevState) {
		const { order } = this.props;
		let { payingPayment, payingOrder, shouldUpdatePayment } = this.state;

		let didUpdate = false;
		if (order.success && order.success !== prevProps.order.success) {
			payingOrder = Object.assign({}, order.data);
			didUpdate = true;
		}

		if (order.payment.success && order.payment.success !== prevProps.order.payment.success) {
			payingPayment = Object.assign({}, order.payment.data);
			didUpdate = true;
		}

		if ((order.payment.promo_code.success !== prevProps.order.payment.promo_code.success || order.payment.promo_code.error !== prevProps.order.payment.promo_code.error)) {
			if (order.payment.promo_code.success) {
				payingOrder.promotion_code = order.payment.promo_code.data.promotion_code;
				payingOrder.promotion_amount = order.payment.promo_code.data.promotion_amount;
				payingPayment.customer_amount = order.payment.promo_code.data.customer_total_amount;
			} else {
				payingOrder = Object.assign({}, order.data);
				payingPayment = Object.assign({}, order.payment.data);
			}
			
			didUpdate = true;
		}

		if (didUpdate) {
			this.setState({ payingPayment, payingOrder, shouldUpdatePayment: shouldUpdatePayment + 1 });
		}

		if (order.payment.process.error && order.payment.process.error !== prevProps.order.payment.process.error) {
			this.setState({ errorModalIsOpen: true });
		}
	}

	reloadPayment() {
		this.setState({
			payingPayment: null,
			payingOrder: null,
			shouldUpdatePayment: 1,
			errorModalIsOpen: false
		}, () => {
			const { order_id, payment_id } = this.props;
			this.props.dispatch(orderActions.getClear());
			this.props.dispatch(orderActions.paymentProcessClear());
			this.props.dispatch(orderActions.paymentApplyPromoCodeClear());
			this.props.dispatch(orderActions.get(false, order_id));
			this.props.dispatch(orderActions.getPayment(false, payment_id));
		});
	}

	onSubmit(values, { setSubmitting }) {
		this.props.stripe.createToken({ type: 'card', name: `${values.first_name} ${values.last_name}` }).then(({ token }) => {
			values.total_amount = this.state.payingPayment.customer_amount;
			values.stripeToken = token.id;
			this.props.onSubmit(values);
		});
	}

	onApplyPromoCode(code) {
		const { payment_id } = this.props;
		this.props.dispatch(orderActions.paymentApplyPromoCode(false, payment_id, { code }));
	}

	onRemovePromoCode(setFieldValue) {
		setFieldValue('code', '');
		this.props.dispatch(orderActions.paymentApplyPromoCodeClear());
	}

	render() {
		const { t, submitting, order, payment_id } = this.props;
		const { payingOrder, payingPayment, shouldUpdatePayment, errorModalIsOpen } = this.state;

		const schema = Yup.object().shape({
			first_name: Yup.string()
				.required(t('required')),
			last_name: Yup.string()
				.required(t('required')),
			credit_card_number: Yup.boolean()
				.oneOf([true], t('required')),
			credit_card_expiry_date: Yup.boolean()
				.oneOf([true], t('required')),
			credit_card_code: Yup.boolean()
				.oneOf([true], t('required')),
		});

		return (
			<>
				{(order.loading || order.payment.loading) && <LoadingPage />}
				{(order.error || order.payment.error) && <ErrorPage match={this.props.match} error={order.error ? order.error : order.payment.error} />}
				{payingOrder && payingPayment &&
					<PageContainer id="customer_order_pay_form">
						<Container className="container-p-60">
							<Title>
								{t('payment')}
							</Title>
							<Row className="row-m-60">
								<Col lg={{ size: 6, order: 1 }} xs={{ order: 2 }}>
									<Formik
										enableReinitialize
										initialValues={{
											order_payment_id: payment_id,
											first_name: '',
											last_name: '',
											credit_card_brand: undefined,
											code: '',
											credit_card_number: false,
											credit_card_expiry_date: false,
											credit_card_code: false,
											shoot_datetime: Moment.utc(payingOrder.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('YYYY-MM-DD HH:mm:ss')
										}}
										validationSchema={schema}
										onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
									>
										{({ values,
											errors,
											touched,
											handleSubmit,
											isSubmitting,
											setFieldValue,
											setFieldTouched,
											setFieldError,
											/* and other goodies */
										}) => (
												<Form>
													<div id="promo_code">
														<Title size="sm">
															{t('promotion_code')}
														</Title>
														{order.payment.promo_code.loading && <Loading />}
														{order.payment.promo_code.success ?
															<Row className="row-m-8">
																<Col sm={{ size: 9 }}>
																	<div className="promo-code-applied">
																		<div className="name">
																			{payingOrder.promotion_code}
																		</div>
																		<div className="type">
																			{`${app.toCurrencyString(payingOrder.currency.value, payingOrder.promotion_amount)} ${t('coupon')}`}
																		</div>
																	</div>
																</Col>
																<Col sm={{ size: 3 }}>
																	<Button size="sm" outline block disabled={!values.code} onClick={() => this.onRemovePromoCode(setFieldValue)}>{t('remove')}</Button>
																</Col>
															</Row>
															:
															<InputItem>
																<Row className="row-m-8">
																	<Col sm={{ size: 4 }}>
																		<InputItemLabel>{t('code')}</InputItemLabel>
																	</Col>
																	<Col sm={{ size: 5 }}>
																		<InputField type="text" name="code" t={t} showError={order.payment.promo_code.error} />
																		{order.payment.promo_code.error && <span className="error">{order.payment.promo_code.error.message}</span>}
																	</Col>
																	<Col sm={{ size: 3 }}>
																		<Button size="sm" outline block disabled={!values.code} onClick={() => this.onApplyPromoCode(values.code)}>{t('apply')}</Button>
																	</Col>
																</Row>
															</InputItem>
														}
													</div>
													<hr />
													<div id="payment">
														<Title size="sm">
															{t('payment_method')}
														</Title>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('pay_with')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<div className="credit-card">
																		{t('credit_card')}
																		<div className="brands">
																			<img src={img_visa} alt={t('visa')} className={(values.credit_card_brand && values.credit_card_brand !== 'visa') ? 'd-none' : ''} />
																			<img src={img_master} alt={t('master_card')} className={(values.credit_card_brand && values.credit_card_brand !== 'mastercard') ? 'd-none' : ''} />
																			<img src={img_ae} alt={t('american_express')} className={(values.credit_card_brand && values.credit_card_brand !== 'amex') ? 'd-none' : ''} />
																		</div>
																	</div>
																</Col>
															</Row>
														</InputItem>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('card_number')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<CardNumberElement
																		style={StripeElementStyle}
																		className={`StripeElement ${(touched.credit_card_number && !values.credit_card_number) ? 'StripeElement--empty' : ''} ${(touched.credit_card_number && errors.credit_card_number) ? 'StripeElement--invalid' : ''}`}
																		onBlur={() => {
																			// console.log('CardNumberElement onBlur values: ', values)
																			setFieldTouched('credit_card_number', true);
																		}}
																		onChange={(change) => {
																			// console.log('CardNumberElement change: ', change)
																			setFieldValue('credit_card_number', change.complete);
																			setFieldTouched('credit_card_number', true);
																			if (change.error) {
																				setFieldError('credit_card_number', change.error.message);
																			}

																			setFieldValue('credit_card_brand', change.brand === 'unknown' ? undefined : change.brand);
																			setFieldTouched('credit_card_brand', true);
																		}}
																	/>
																	<ErrorMessage name="credit_card_number" component="span" className="error" />
																</Col>
															</Row>
														</InputItem>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('expiry_date')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<CardExpiryElement
																		style={StripeElementStyle}
																		className={`StripeElement ${(touched.credit_card_expiry_date && !values.credit_card_expiry_date) ? 'StripeElement--empty' : ''} ${(touched.credit_card_expiry_date && errors.credit_card_expiry_date) ? 'StripeElement--invalid' : ''}`}
																		onBlur={() => {
																			setFieldTouched('credit_card_expiry_date', true);
																		}}
																		onChange={(change) => {
																			// console.log('CardExpiryElement change: ', change)
																			setFieldValue('credit_card_expiry_date', change.complete);
																			setFieldTouched('credit_card_expiry_date', true);
																			if (change.error) {
																				setFieldError('credit_card_expiry_date', change.error.message);
																			}
																		}}
																	/>
																	<ErrorMessage name="credit_card_expiry_date" component="span" className="error" />
																</Col>
															</Row>
														</InputItem>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('security_code')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<CardCVCElement
																		style={StripeElementStyle}
																		className={`StripeElement ${(touched.credit_card_code && !values.credit_card_code) ? 'StripeElement--empty' : ''} ${(touched.credit_card_code && errors.credit_card_code) ? 'StripeElement--invalid' : ''}`}
																		onBlur={() => {
																			setFieldTouched('credit_card_code', true);
																		}}
																		onChange={(change) => {
																			// console.log('CardCVCElement change: ', change)
																			setFieldValue('credit_card_code', change.complete);
																			setFieldTouched('credit_card_code', true);

																			if (change.error) {
																				setFieldError('credit_card_code', change.error.message);
																			}
																		}}
																	/>
																	<ErrorMessage name="credit_card_code" component="span" className="error" />
																	<InputFieldRemarks>{t('security_code_remarks')}</InputFieldRemarks>
																</Col>
															</Row>
														</InputItem>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('first_name')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<InputField type="text" name="first_name" t={t} />
																</Col>
															</Row>
														</InputItem>
														<InputItem>
															<Row>
																<Col md={{ size: 4 }}>
																	<InputItemLabel>{t('last_name')}</InputItemLabel>
																</Col>
																<Col md={{ size: 8 }}>
																	<InputField type="text" name="last_name" t={t} />
																</Col>
															</Row>
														</InputItem>
														<InputItem className="policy" dangerouslySetInnerHTML={{ __html: t('pay_now_policy') }} />
														<InputItem>
															<Button
																className="btn-submit"
																type="submit"
																disabled={submitting}
																onClick={handleSubmit}>
																{t('submit')}
															</Button>
														</InputItem>
													</div>
												</Form>
											)}
									</Formik>
								</Col>
								<Col lg={{ size: 6, order: 2 }} xs={{ order: 1 }}>
									<Payment key={shouldUpdatePayment} order={payingOrder} data={payingPayment} isPay={true} />
								</Col>
							</Row>
						</Container>
					</PageContainer>
				}
				{order.payment.process.error &&
					<BaseModal
						id="customer_order_pay_error_modal"
						isOpen={errorModalIsOpen}
						toggle={() => this.setState({ errorModalIsOpen: !errorModalIsOpen })}
						onClosed={() => {
							this.reloadPayment();
						}}
					>
						<ModalHeader>
							<Title>{t('error')}</Title>
						</ModalHeader>
						<ModalBody>
							<Desc>{order.payment.process.error.message}</Desc>
						</ModalBody>
						<ModalFooter>
							<Button outline onClick={() => this.setState({ errorModalIsOpen: !errorModalIsOpen })}>{t('ok')}</Button>
						</ModalFooter>
					</BaseModal>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order,
	};
}

const translation = withNamespaces(['payment', 'error', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
const injectedStripe = injectStripe(connected);
export { injectedStripe as InputForm };