import { payoutConstants, userConstants } from '../_constants';

const payoutsPackagesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined
}

const payoutsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true,
	packages: payoutsPackagesInitialState
}

export function payouts(state = payoutsInitialState, action) {
	switch (action.type) {
		case payoutConstants.GET_LIST_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...payoutsInitialState };
		case payoutConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case payoutConstants.GET_LIST_SUCCESS:
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.payouts;
			} else {
				list = list.concat(action.responseData.payouts);
			}
			return { ...state,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined
			};
		case payoutConstants.GET_LIST_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case payoutConstants.GET_PACKAGES_SHORT_INFO_CLEAR:
			return { ...state, packages: { ...payoutsPackagesInitialState }};
		case payoutConstants.GET_PACKAGES_SHORT_INFO_REQUEST:
			return { ...state, packages: { ...payoutsPackagesInitialState, loading: true }};
		case payoutConstants.GET_PACKAGES_SHORT_INFO_SUCCESS:
			return { ...state, packages: { ...payoutsPackagesInitialState, success: true, list: action.responseData }};
		case payoutConstants.GET_PACKAGES_SHORT_INFO_FAILURE:
			return { ...state, packages: { ...payoutsPackagesInitialState, error: action.error }};

		default:
			return state
	}
}

const payoutInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined
}

export function payout(state = payoutInitialState, action) {
	switch (action.type) {
		case payoutConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...payoutInitialState };
		case payoutConstants.GET_REQUEST: {
			let updateFromState = state.id == action.id? state : payoutInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, error: undefined };
		}
		case payoutConstants.GET_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				id: action.id,
				data: action.responseData,
				error: undefined
			};
		case payoutConstants.GET_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				id: action.id,
				error: action.error,
			};
		default:
			return state
	}
}

const payoutReceiptInitialState = {
	id: undefined,
	data: undefined,
	loading: false,
	error: undefined,
}

export function payout_receipt(state = payoutReceiptInitialState, action) {
	switch (action.type) {
		case payoutConstants.GET_RECEIPT_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...payoutReceiptInitialState };
		case payoutConstants.GET_RECEIPT_REQUEST: {
			let updateFromState = state.id == action.id? state : payoutReceiptInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, error: undefined };
		}
		case payoutConstants.GET_RECEIPT_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				id: action.id,
				data: action.responseData,
				error: undefined
			};
		case payoutConstants.GET_RECEIPT_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				id: action.id,
				error: action.error,
			};
		default:
			return state
	}
}