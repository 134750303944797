import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ErrorMessage, connect as connectFormik } from 'formik';
import { Button, Container, Row, Col } from 'reactstrap';

import { InputField, InputSelect, Loading, InputBtnCheckbox, InputItemLabel, InputItem, Title } from '../../../_components';

import './PackageIncluded.scss';

class PackageIncluded extends Component {
	render() {
		const { t, formInfo, formInfoError, formik } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
		const serviceIncluded = [
			{
				name: 'outfit',
				items: [
					'makeup',
					'hairstyling',
					'clothes',
					'accessory',
					'other',
				]
			},
			{
				name: 'service',
				items: [
					'transport',
					'translator',
					'wedding_assistant',
					'other',
				]
			}
		];

		return (
			<div id="vendor_createpackage_packageincluded">
				<Title size='sm'>
					{t('package_included')}
				</Title>
				<div className="section-title required">
					{t('basic_information')}
				</div>
				<InputItem className={`input-item-grouped-error ${(errors.edited_photo && touched.edited_photo) ? 'input-error' : ''}`}>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<Row className="row-m-8">
								<Col xs={{ size: 'auto' }} className="col-checkbox">
									<InputBtnCheckbox name="edited_photo" t={t} onClick={(name, value) => {
										if (!value) setFieldValue('edited_photo_amount', '');
									}} />
								</Col>
								<Col xs={{ size: true }}>
									<InputItemLabel>{t('edited_photo')}</InputItemLabel>
								</Col>
							</Row>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<Row>
								<Col sm={{ size: 2 }} xs={{ size: 4 }}>
									<InputItemLabel>{t('amount')}</InputItemLabel>
								</Col>
								<Col sm={{ size: 10 }} xs={{ size: 8 }}>
									<InputField type="number" name="edited_photo_amount" placeholder={t('amount')} t={t} disabled={!values.edited_photo} />
								</Col>
							</Row>
						</Col>

					</Row>
					<ErrorMessage name="edited_photo" component="span" className="error" />
				</InputItem>
				<InputItem className={`input-item-grouped-error ${(errors.original_photo && touched.original_photo) ? 'input-error' : ''}`}>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<Row className="row-m-8">
								<Col xs={{ size: 'auto' }} className="col-checkbox">
									<InputBtnCheckbox name="original_photo" t={t} onClick={(name, value) => {
										if (!value) setFieldValue('original_photo_amount', '');
									}} />
								</Col>
								<Col xs={{ size: true }}>
									<InputItemLabel>{t('original_photo')}</InputItemLabel>
								</Col>
							</Row>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<Row>
								<Col sm={{ size: 2 }} xs={{ size: 4 }}>
									<InputItemLabel>{t('amount')}</InputItemLabel>
								</Col>
								<Col sm={{ size: 10 }} xs={{ size: 8 }}>
									<InputField type="number" name="original_photo_amount" placeholder={t('amount')} t={t} disabled={!values.original_photo} />
								</Col>
							</Row>
						</Col>
					</Row>
					<ErrorMessage name="original_photo" component="span" className="error" />
				</InputItem>
				<InputItem className={`input-item-grouped-error ${(errors.photo_book && touched.photo_book) ? 'input-error' : ''}`}>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<Row className="row-m-8">
								<Col xs={{ size: 'auto' }} className="col-checkbox">
									<InputBtnCheckbox name="photo_book" t={t} onClick={(name, value) => {
										if (!value) {
											setFieldValue('photo_book_amount', '');
											setFieldValue('photo_book_page', '');
										}
									}} />
								</Col>
								<Col xs={{ size: true }}>
									<InputItemLabel>{t('photo_book')}</InputItemLabel>
								</Col>
							</Row>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<Row className="row-inner">
								<Col sm={{ size: 2 }} xs={{ size: 4 }}>
									<InputItemLabel>{t('amount')}</InputItemLabel>
								</Col>
								<Col sm={{ size: 10 }} xs={{ size: 8 }}>
									<InputField type="number" name="photo_book_amount" placeholder={t('amount')} t={t} disabled={!values.photo_book} />
								</Col>
							</Row>
							<Row className="row-inner">
								<Col sm={{ size: 2 }} xs={{ size: 4 }}>
									<InputItemLabel>{t('pages')}</InputItemLabel>
								</Col>
								<Col sm={{ size: 10 }} xs={{ size: 8 }}>
									<InputField type="number" name="photo_book_page" placeholder={t('pages')} t={t} disabled={!values.photo_book} />
								</Col>
							</Row>
						</Col>
					</Row>
					<ErrorMessage name="photo_book" component="span" className="error" />
				</InputItem>
				<InputItem className={`input-item-grouped-error ${(errors.video_highlight && touched.video_highlight) ? 'input-error' : ''}`}>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<Row className="row-m-8">
								<Col xs={{ size: 'auto' }} className="col-checkbox">
									<InputBtnCheckbox name="video_highlight" t={t} onClick={(name, value) => {
										if (!value) setFieldValue('video_highlight_amount', '');
									}} />
								</Col>
								<Col xs={{ size: true }}>
									<InputItemLabel>{t('video_highlight')}</InputItemLabel>
								</Col>
							</Row>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<Row>
								<Col sm={{ size: 2 }} xs={{ size: 4 }}>
									<InputItemLabel>{t('amount')}</InputItemLabel>
								</Col>
								<Col sm={{ size: 10 }} xs={{ size: 8 }}>
									<InputField type="number" name="video_highlight_amount" placeholder={t('amount')} t={t} disabled={!values.video_highlight} />
								</Col>
							</Row>
						</Col>
					</Row>
					<ErrorMessage name="video_highlight" component="span" className="error" />
				</InputItem>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<InputItemLabel>
								<Row className="row-m-8">
									<Col xs={{ size: 'auto' }} className="col-checkbox">
										<InputBtnCheckbox name="free_shipping" t={t} />
									</Col>
									<Col xs={{ size: true }}>
										{t('free_shipping')}
									</Col>
								</Row>
							</InputItemLabel>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<InputItemLabel className="required">
								{t('delivery_day')}
							</InputItemLabel>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<Row>
								<Col xs={{ size: true }}>
									<InputField type="number" name="delivery_day" t={t} />
								</Col>
								<Col xs={{ size: 'auto' }}>
									<InputItemLabel>
										{t('week(s)')}
									</InputItemLabel>
								</Col>
							</Row>
						</Col>
					</Row>
				</InputItem>
				<InputItem>
					<Row>
						<Col lg={{ size: 3 }} md={{ size: 4 }}>
							<InputItemLabel>
								{t('remarks')}
							</InputItemLabel>
						</Col>
						<Col lg={{ size: 6 }} md={{ size: 8 }}>
							<InputField component="textarea" type="text" name="remarks" t={t} />
						</Col>
					</Row>
				</InputItem>
				<hr />
				<div className="section-title">
					{t('service_included')}
				</div>
				<div className="service-included">
					{serviceIncluded.map((group, index) =>
						<div key={group.name} className="service-included-group">
							<div className="service-included-header">
								{t(group.name)}
							</div>
							{group.items.map((item, index) =>

								<InputItem key={item}>
									<Row >
										<Col lg={{ size: 3 }} md={{ size: 4 }}>
											<Row className="row-m-8">
												<Col xs={{ size: 'auto' }} className="col-checkbox">
													<InputBtnCheckbox name={`${group.name}_${item}`} t={t} onClick={(name, value) => {
														if (!value) setFieldValue(`${group.name}_${item}_desc`, '');
													}} />
												</Col>
												<Col xs={{ size: true }}>
													<InputItemLabel>{t(`${group.name}_${item}`)}</InputItemLabel>
												</Col>
											</Row>
										</Col>
										<Col lg={{ size: 6 }} md={{ size: 8 }}>
											<InputField
												component="textarea"
												type="text"
												name={`${group.name}_${item}_desc`}
												t={t}
												disabled={!values[`${group.name}_${item}`]} />
										</Col>
									</Row>
								</InputItem>
							)}
						</div>
					)}
				</div>
				<hr />
			</div>
		);
	}
}

const translation = withNamespaces(['package', 'default'])(PackageIncluded);
const connectedFormik = connectFormik(translation);
export { connectedFormik as PackageIncluded };