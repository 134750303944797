import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container } from 'reactstrap';

import { vendorPackageActions } from '../../_actions';

import './MyPackages.scss';

import { Packages } from './Packages';
import { Drafts } from './Drafts';
import { PageContainer, Title } from '../../_components';

const MyPackagesTabPathnames = ['packages', 'drafts'];

class MyPackages extends Component {
	constructor(props) {
		super(props);
		
		const { match } = props;
		let tabIndex = this.tabIndexByMatch(match);
		this.state = { tabIndex: tabIndex };
	}

	componentDidMount() {
		this.props.dispatch(vendorPackageActions.getCount(true));
		this.props.dispatch(vendorPackageActions.getCount(false));
	}

	componentDidUpdate(prevProps, prevState) {
		const { location, vendor_package } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}

		let shouldUpdatePackagesCount= false;
		let shouldUpdateDraftsCount= false;
		
		if (vendor_package.remove.success && vendor_package.remove.success !== prevProps.vendor_package.remove.success) {
			if (vendor_package.isDraft) {
				shouldUpdateDraftsCount = true;
			} else {
				shouldUpdatePackagesCount = true;
			}
		}


		if (shouldUpdateDraftsCount) {
			this.props.dispatch(vendorPackageActions.getCount(true));
		}

		if (shouldUpdatePackagesCount) {
			this.props.dispatch(vendorPackageActions.getCount(false));
		}
	}
	
	onRouteChanged(props) {
		const { match } = props;

		let tabIndex = this.tabIndexByMatch(match);
		this.setState({ tabIndex: tabIndex });
	}

	tabIndexByMatch(match) {
		let tabIndex = MyPackagesTabPathnames.indexOf(match.params.tab);
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		return `${match.url.replace(`/${match.params.tab }`, '')}/${MyPackagesTabPathnames[tabIndex]}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	render() {
		const { t, vendor_packages, vendor_drafts } = this.props;
		const { tabIndex } = this.state;

		return (
			<PageContainer id="vendor_mypackages">
				<Container>
					<Title>{t('my_packages')}</Title>
					<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
						<TabList>
							<Tab>{t('package') + `${vendor_packages.total > 0? ` (${vendor_packages.total})` : ''}`}</Tab>
							<Tab>{t('draft') + `${vendor_drafts.total > 0? ` (${vendor_drafts.total})` : ''}`}</Tab>
						</TabList>
						<TabPanel>
							<Packages/>
						</TabPanel>
						<TabPanel>
							<Drafts/>
						</TabPanel>
					</Tabs>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_packages, vendor_drafts, vendor_package } = state;
	return {
		vendor_packages, vendor_drafts, vendor_package 
	};
}

const translation = withNamespaces(['mypackages'])(MyPackages);
const connected = connect(mapStateToProps)(translation);
export { connected as MyPackages };