import { vendorProfileConstants, savedItemsConstants, userConstants } from '../_constants';

const vendorProfileReviewsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const vendorProfilePackagesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const vendorProfileInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	packages: vendorProfilePackagesInitialState,
	reviews: vendorProfileReviewsInitialState
}

export function vendor_profile(state = vendorProfileInitialState, action) {
	switch (action.type) {
		case vendorProfileConstants.GET_DETAILS_CLEAR:
			return { ...vendorProfileInitialState };
		case vendorProfileConstants.GET_DETAILS_REQUEST: {
			let updateFromState = state.id == action.id? state : vendorProfileInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, error: undefined };
		}
		case vendorProfileConstants.GET_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				id: action.id,
				data: action.responseData,
				error: undefined
			};
		case vendorProfileConstants.GET_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				id: action.id,
				error: action.error
			};

		case savedItemsConstants.ADD_VENDOR_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = true;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}
		case savedItemsConstants.REMOVE_VENDOR_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.data) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		case vendorProfileConstants.GET_REVIEWS_CLEAR:
			return { ...state, reviews: vendorProfileReviewsInitialState };
		case vendorProfileConstants.GET_REVIEWS_REQUEST: {
			let updateFromState = state.id == action.id? state : vendorProfileInitialState;
			let updateFromStateReviews = state.id == action.id? state.reviews : vendorProfileReviewsInitialState;
			return { ...updateFromState, reviews: { ...updateFromStateReviews, loading: true, success: false, error: undefined } };
		}
		case vendorProfileConstants.GET_REVIEWS_SUCCESS: {
			let list = state.reviews.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.reviews;
			} else {
				list = list.concat(action.responseData.reviews);
			}
			return { ...state,
				reviews: {
					...state.reviews,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined
				}
			};
		}
		case vendorProfileConstants.GET_REVIEWS_FAILURE:
			return { ...state, reviews: { ...state.reviews, loading: false, success: false, error: action.error  } };

		case vendorProfileConstants.GET_PACKAGES_CLEAR:
			return { ...state, packages: vendorProfilePackagesInitialState };
		case vendorProfileConstants.GET_PACKAGES_REQUEST: {
			let updateFromState = state.id == action.id? state : vendorProfileInitialState;
			let updateFromStatePackages = state.id == action.id? state.packages : vendorProfilePackagesInitialState;
			return { ...updateFromState, packages: { ...updateFromStatePackages, loading: true, success: false, error: undefined } };
		}
		case vendorProfileConstants.GET_PACKAGES_SUCCESS: {
			let list = state.packages.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.packages;
			} else {
				list = list.concat(action.responseData.packages);
			}
			return { ...state,
				packages: {
					...state.packages,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined
				}
			};
		}
		case vendorProfileConstants.GET_PACKAGES_FAILURE:
			return { ...state, packages: { ...state.packages, loading: false, success: false, error: action.error  } };

		case savedItemsConstants.ADD_PACKAGE_SUCCESS: {
			if (state.packages.list) {
				const updatedList = state.packages.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, packages: { ...state.packages, list: updatedList } };
			}
			
			return state;
		}

		default:
			return state
	}
}

const vendorProfileCalendarInitialState = {
	id: undefined,
	order_id: undefined,
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

export function vendor_calendar(state = vendorProfileCalendarInitialState, action) {
	switch (action.type) {
		case vendorProfileConstants.GET_CALENDAR_CLEAR:
			return { ...vendorProfileCalendarInitialState };
		case vendorProfileConstants.GET_CALENDAR_REQUEST: {
			let updateFromState = (state.id == action.id && state.order_id == action.order_id)? state : vendorProfileCalendarInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, order_id: action.order_id, error: undefined };
		}
		case vendorProfileConstants.GET_CALENDAR_SUCCESS: {
			let uniqueList;
			if (state.list) {
				uniqueList = action.responseData.reduce((uniqList, currentValue) => {
					let ids = uniqList.map(item => { return item.date.date });
					if (ids.indexOf(currentValue.date.date) < 0) {
						uniqList.push(currentValue);
					}
					return uniqList;
				}, state.list);
			} else {
				uniqueList = action.responseData;
			}

			return { ...state, loading: false, success: true, id: action.id, order_id: action.order_id, list: uniqueList, error: undefined };
		}
		case vendorProfileConstants.GET_CALENDAR_FAILURE:
			return { ...state, loading: false, success: false, id: action.id, order_id: action.order_id, error: action.error };
		default:
			return state
	}
}