import React from 'react';
import { Modal } from 'reactstrap';

import './BaseModal.scss';
import { analytics } from '../../_helpers';

export const BaseModal = ({ children, onOpened, ...props }) => {
	return (
		<Modal
			centered
			backdropTransition={{ timeout: 50 }}
			modalTransition={{ timeout: 100 }}
			onOpened={() => {
				if (props.id) {
					analytics.modal(props.id);
				}

				if (props.onOpened) {
					props.onOpened();
				}
			}}
			{ ...props }
		>
			{children}
		</Modal>
	);
}