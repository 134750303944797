import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactToPrint from "react-to-print";

import { payoutActions } from '../../../_actions';
import { PageContainer, Title, RowCards, ColCards, ReceiptPrintable, LoadingPage, ErrorPage } from '../../../_components';

import './Receipt.scss';

import { PayoutSettings, PayoutSummary } from '../Card';

class Receipt extends Component {
	componentDidMount() {
		this.props.dispatch(payoutActions.getReceipt(this.props.match.params.id));
	}

	render() {
		const { t, payout_receipt, payout_settings_form_info } = this.props;

		return (
			<>
				{payout_receipt.loading && <LoadingPage />}
				{payout_receipt.error && <ErrorPage match={this.props.match} error={payout_receipt.error} />}
				{payout_receipt.data &&
					<PageContainer id="vendor_profile_payout_receipt">
						<Container>
							<Row className="row-title">
								<Col sm={{ size: true }}>
									<Title>{t('payout_receipt')}</Title>
								</Col>
								<Col sm={{ size: 'auto' }}>
									<ReactToPrint
										trigger={() => <Button block className="btn-download-receipt" disabled={!payout_receipt.data || !payout_settings_form_info.data}>{t('download_receipt')}</Button>}
										content={() => this.componentRef}
										pageStyle="@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
									/>
								</Col>
							</Row>
						</Container>
						<div className="receipt-container">
							<div id="receipt" className="receipt" ref={el => (this.componentRef = el)}>
								<ReceiptPrintable
									header={
										<>
											<Row>
												<Col sm={{ size: 3 }}>
													{t('order_number')}
												</Col>
												<Col sm={{ size: 9 }} className="receipt-header-value">
													{payout_receipt.data.order_id}
												</Col>
											</Row>
											<Row>
												<Col sm={{ size: 3 }}>
													{t('last_updated_time')}
												</Col>
												<Col sm={{ size: 9 }} className="receipt-header-value">
													{Moment.utc(payout_receipt.data.updated_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}
												</Col>
											</Row>
											<Row>
												<Col sm={{ size: 3 }}>
													{t('from')}
												</Col>
												<Col sm={{ size: 9 }} className="receipt-header-value">
													{payout_receipt.data.from}
												</Col>
											</Row>
											<Row>
												<Col sm={{ size: 3 }}>
													{t('to')}
												</Col>
												<Col sm={{ size: 9 }} className="receipt-header-value">
													{payout_receipt.data.to}
												</Col>
											</Row>
										</>
									}
									body={
										<RowCards>
											<ColCards md={{ size: 6, order: 1 }} xs={{ order: 2 }}>
												<PayoutSettings data={payout_receipt.data} />
											</ColCards>
											<ColCards md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
												<PayoutSummary data={payout_receipt.data} />
											</ColCards>
										</RowCards>
									}
								/>
							</div>
						</div>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { payout_receipt, payout_settings_form_info } = state; 
    return {
		payout_receipt, payout_settings_form_info
    };
}

const translation = withNamespaces(['payout', 'receipt', 'payment', 'order', 'package'])(Receipt);
const connected = connect(mapStateToProps)(translation);
export { connected as Receipt };