import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../_components';

import { VendorProfile } from './VendorProfile';

const VendorProfileTabPathnames = ['profile', 'reviews'];

export class VendorProfileRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}/:tab(${VendorProfileTabPathnames.join('|')})`} component={VendorProfile} />
				<PrivateRoute exact path={`${match.path}`} component={VendorProfile} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}