import { myReviewsConstants, userConstants } from '../_constants';

const myReviewsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}
export function my_reviews(state = myReviewsInitialState, action) {
	switch (action.type) {
		case myReviewsConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...myReviewsInitialState };
		case myReviewsConstants.GET_REQUEST:
			return { ...state.reviews, loading: true, success: false, error: undefined };
		case myReviewsConstants.GET_SUCCESS: {
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.reviews;
			} else {
				list = list.concat(action.responseData.reviews);
			}
			return {
				...state,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				id: action.id,
				error: undefined
			};
		}
		case myReviewsConstants.GET_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		default:
			return state
	}
}