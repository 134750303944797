import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, vendorPackageConstants } from '../_constants';

export const vendorPackageActions = {
	getFormInfo,
	getFormInfoClear,
	getRevenue,
	getRevenueClear,
	_draftDataToFormData,
	save,
	saveClear,
	edit,
	editClear,
	getList,
	getListClear,
	publish,
	publishClear,
	get,
	getClear,
	getLocations,
	getLocationsClear,
	remove,
	removeClear,
	getCount,
	getCountClear,
};

function getFormInfo() {
	const requestOptions = api.requestOption(apiEndpoint.vendor_package.package.form_info);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: vendorPackageConstants.GET_FORM_INFO_REQUEST } }
    function success(responseData) { return { type: vendorPackageConstants.GET_FORM_INFO_SUCCESS, responseData } }
    function failure(error) { return { type: vendorPackageConstants.GET_FORM_INFO_FAILURE, error } }
}

function getFormInfoClear() {
	return { type: vendorPackageConstants.GET_FORM_INFO_CLEAR }
}

function getRevenue(currency_id, amount) {
	const requestOptions = api.requestOption(apiEndpoint.vendor_package.package.revenue, { currency_id, amount });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, false)));
			});
    };

    function request() { return { type: vendorPackageConstants.GET_REVENUE_REQUEST } }
    function success(responseData) { return { type: vendorPackageConstants.GET_REVENUE_SUCCESS, responseData } }
    function failure(error) { return { type: vendorPackageConstants.GET_REVENUE_FAILURE, error } }
}

function getRevenueClear() {
	return { type: vendorPackageConstants.GET_REVENUE_CLEAR };
}

function _draftDataToFormData(data) {
	let formData = new FormData();

	for (var key in data) {
		if (((typeof data[key] === 'string') && !data[key].length) || data[key] === undefined) continue; //skip empty string

		if (key === 'locations') {
			for (var i = 0; i < data.locations.length; i++) {
				let location = data.locations[i];

				if (!location.name.length) continue;
		
				formData.append(`locations[${i}][name]`, location.name);
				for (var j = 0; j < location.items.length; j ++) {
					var locationItem = location.items[j];

					for (var keyLocationItem in locationItem) {
						if (((typeof locationItem[keyLocationItem] === 'string') && !locationItem[keyLocationItem].length) || locationItem[keyLocationItem] === undefined) continue; //skip empty string


						if (typeof locationItem[keyLocationItem] === 'boolean') {
							formData.append(key, data[key]? '1' : '0');
						} else {
							formData.append(key, data[key]);
						}
						formData.append(`locations[${i}][items][${j}][${keyLocationItem}]`, locationItem[keyLocationItem]);
					}
				}
			}
		} else {
			if (typeof data[key] === 'boolean') {
				formData.append(key, data[key]? '1' : '0');
			} else {
				formData.append(key, data[key]);
			}
		}
	}

	return formData;
}

function save(isDraft, data) {
	if (!isDraft) return;

	analytics.event({
		category: 'Package',
		action: 'Save',
		label: 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.vendor_package.draft.save, api.objectToFormData(data));

    return dispatch => {
        dispatch(request(isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), isDraft));
			});
    };

    function request(isDraft) { return { type: vendorPackageConstants.SAVE_REQUEST, isDraft  } }
    function success(responseData, isDraft) { return { type: vendorPackageConstants.SAVE_SUCCESS, responseData, isDraft } }
    function failure(error, isDraft) { return { type: vendorPackageConstants.SAVE_FAILURE, error, isDraft } }
}

function saveClear() {
	return { type: vendorPackageConstants.SAVE_CLEAR };
}

function edit(isDraft, data) {
	analytics.event({
		category: 'Package',
		action: 'Edit',
		label: 'Vendor'
	});

	var endpoint = Object.assign({}, (isDraft? apiEndpoint.vendor_package.draft.edit : apiEndpoint.vendor_package.package.edit)); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', data.id);

	const requestOptions = api.requestOption(endpoint, api.objectToFormData(data));

    return dispatch => {
        dispatch(request(data.id, isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, data.id, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), data.id, isDraft));
			});
    };

    function request(id, isDraft) { return { type: vendorPackageConstants.EDIT_REQUEST, id, isDraft } }
    function success(responseData, id, isDraft) { return { type: vendorPackageConstants.EDIT_SUCCESS, responseData, id, isDraft } }
    function failure(error, id, isDraft) { return { type: vendorPackageConstants.EDIT_FAILURE, error, id, isDraft } }
}

function editClear() {
	return { type: vendorPackageConstants.EDIT_CLEAR };
}

function get(isDraft, id) {
	var endpoint = Object.assign({}, (isDraft? apiEndpoint.vendor_package.draft.get : apiEndpoint.vendor_package.package.get)); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id, isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id, isDraft));
			});
    };

    function request(id, isDraft) { return { type: vendorPackageConstants.GET_REQUEST, id, isDraft } }
    function success(responseData, id, isDraft) { return { type: vendorPackageConstants.GET_SUCCESS, responseData, id, isDraft } }
    function failure(error, id, isDraft) { return { type: vendorPackageConstants.GET_FAILURE, error, id, isDraft } }
}

function getClear() {
	return { type: vendorPackageConstants.GET_CLEAR };
}

function getLocations(isDraft, id) {
	var endpoint = Object.assign({}, (isDraft? apiEndpoint.vendor_package.draft.locations : apiEndpoint.vendor_package.package.locations)); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id, isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id, isDraft));
			});
    };

    function request(id, isDraft) { return { type: vendorPackageConstants.GET_LOCATIONS_REQUEST, id, isDraft } }
    function success(responseData, id, isDraft) { return { type: vendorPackageConstants.GET_LOCATIONS_SUCCESS, responseData, id, isDraft } }
    function failure(error, id, isDraft) { return { type: vendorPackageConstants.GET_LOCATIONS_FAILURE, error, id, isDraft } }
}

function getLocationsClear() {
	return { type: vendorPackageConstants.GET_LOCATIONS_CLEAR };
}

function publish(isDraft, id, publish) {
	analytics.event({
		category: 'Package',
		action: publish? 'Publish' : 'Unpublish',
		label: 'Vendor',
	});

	var endpoint = Object.assign({}, (isDraft? apiEndpoint.vendor_package.draft.publish : apiEndpoint.vendor_package.package.publish)); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint, { publish });

    return dispatch => {
        dispatch(request(id, isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id, publish, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id, isDraft));
			});
    };

    function request(id, isDraft) { return { type: vendorPackageConstants.PUBLISH_REQUEST, id, isDraft } }
    function success(responseData, id, publish, isDraft) { return { type: vendorPackageConstants.PUBLISH_SUCCESS, responseData, id, publish, isDraft } }
    function failure(error, id, isDraft) { return { type: vendorPackageConstants.PUBLISH_FAILURE, error, id, isDraft } }
}

function publishClear() {
	return { type: vendorPackageConstants.PUBLISH_CLEAR };
}

function getList(isDraft, page_number) {
	const per_page = 10;
	const requestOptions = api.requestOption((isDraft? apiEndpoint.vendor_package.draft.list : apiEndpoint.vendor_package.package.list), { page_number, per_page });

    return dispatch => {
        dispatch(request(isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), isDraft));
			});
    };

    function request(isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_REQUEST : vendorPackageConstants.GET_LIST_REQUEST) } }
    function success(responseData, isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_SUCCESS : vendorPackageConstants.GET_LIST_SUCCESS), responseData } }
    function failure(error, isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_FAILURE : vendorPackageConstants.GET_LIST_FAILURE), error } }
}

function getListClear(isDraft) {
	return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_CLEAR : vendorPackageConstants.GET_LIST_CLEAR) };
}

function remove(isDraft, id) {
	analytics.event({
		category: 'Package',
		action: 'Remove',
		label: 'Vendor'
	});

	var endpoint = Object.assign({}, (isDraft? apiEndpoint.vendor_package.draft.delete : apiEndpoint.vendor_package.package.delete)); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id, isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id, isDraft));
			});
    };

    function request(id, isDraft) { return { type: vendorPackageConstants.REMOVE_REQUEST, id, isDraft } }
    function success(responseData, id, isDraft) { return { type: vendorPackageConstants.REMOVE_SUCCESS, responseData, id, isDraft } }
    function failure(error, id, isDraft) { return { type: vendorPackageConstants.REMOVE_FAILURE, error, id, isDraft } }
}

function removeClear() {
	return { type: vendorPackageConstants.REMOVE_CLEAR };
}

function getCount(isDraft) {
	const requestOptions = api.requestOption((isDraft? apiEndpoint.vendor_package.draft.count : apiEndpoint.vendor_package.package.count));

    return dispatch => {
        dispatch(request(isDraft));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, isDraft));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), isDraft));
			});
    };

    function request(isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_COUNT_REQUEST : vendorPackageConstants.GET_COUNT_REQUEST) } }
    function success(responseData, isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_COUNT_SUCCESS : vendorPackageConstants.GET_COUNT_SUCCESS), responseData } }
    function failure(error, isDraft) { return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_COUNT_FAILURE : vendorPackageConstants.GET_COUNT_FAILURE), error } }
}

function getCountClear(isDraft) {
	return { type: (isDraft? vendorPackageConstants.GET_DRAFTS_COUNT_CLEAR : vendorPackageConstants.GET_COUNT_CLEAR) };
}