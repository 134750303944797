import axios from 'axios';

import { api, app, analytics } from '../_helpers';
import { apiEndpoint, packageConstants } from '../_constants';

export const packageActions = {
	getDestinations,
	getCategories,

	getFilterItems,
	getFilterItemsClear,
	
	getSortItems,
	getSortItemsClear,

	getList,
	getListClear,

	search,
	searchClear,
	
	get,
	getClear,
	
	getLocations,
	getLocationsClear,
	
	getReviews,
	getReviewsClear
};

function getDestinations() {
	const requestOptions = api.requestOption(apiEndpoint.package.destinations);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: packageConstants.GET_DESTINATIONS_REQUEST } }
    function success(responseData) { return { type: packageConstants.GET_DESTINATIONS_SUCCESS, responseData } }
    function failure(error) { return { type: packageConstants.GET_DESTINATIONS_FAILURE, error } }
}

function getCategories() {
	const requestOptions = api.requestOption(apiEndpoint.package.categories);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: packageConstants.GET_CATEGORIES_REQUEST } }
    function success(responseData) { return { type: packageConstants.GET_CATEGORIES_SUCCESS, responseData } }
    function failure(error) { return { type: packageConstants.GET_CATEGORIES_FAILURE, error } }
}

function getFilterItems() {
	const requestOptions = api.requestOption(apiEndpoint.package.filter_items, { currency_id: app.getCurrency().id });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: packageConstants.GET_FILTER_ITEMS_REQUEST } }
    function success(responseData) { return { type: packageConstants.GET_FILTER_ITEMS_SUCCESS, responseData } }
    function failure(error) { return { type: packageConstants.GET_FILTER_ITEMS_FAILURE, error } }
}

function getFilterItemsClear() {
	return { type: packageConstants.GET_FILTER_ITEMS_CLEAR }
}

function getSortItems() {
	const requestOptions = api.requestOption(apiEndpoint.package.filter_items);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: packageConstants.GET_SORT_ITEMS_REQUEST } }
    function success(responseData) { return { type: packageConstants.GET_SORT_ITEMS_SUCCESS, responseData } }
    function failure(error) { return { type: packageConstants.GET_SORT_ITEMS_FAILURE, error } }
}

function getSortItemsClear() {
	return { type: packageConstants.GET_SORT_ITEMS_CLEAR }
}

function getList(currency, sorting, category, destination, price, start_date, end_date, page_number) {
	let currency_id = currency? currency.id : undefined;
	let sorting_id = sorting? sorting.id : undefined;
	let category_id = category? category.id : undefined;
	let destination_id = destination? destination.id : undefined;
	let max_amount = price? price.max_amount : undefined;
	let min_amount = price? price.min_amount : undefined;

	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.package.packages, { currency_id, sorting_id, category_id, destination_id, max_amount, min_amount, start_date, end_date, page_number, per_page });

    return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: packageConstants.GET_LIST_REQUEST } }
    function success(responseData) { return { type: packageConstants.GET_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: packageConstants.GET_LIST_FAILURE, error } }
}

function getListClear() {
	return { type: packageConstants.GET_LIST_CLEAR };
}

function search(currency, sorting, category, destination, price, start_date, end_date, page_number) {
	analytics.event({
		category: 'Packages',
		action: 'Search',
		label: 'Customer'
	});

	if (currency) {
		analytics.event({
			category: `Packages - currency: ${currency.name}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (sorting) {
		analytics.event({
			category: `Packages - sorting: ${sorting.name}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (category) {
		analytics.event({
			category: `Packages - category: ${category.name}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (destination) {
		analytics.event({
			category: `Packages - destination: ${destination.name}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (currency && price) {
		analytics.event({
			category: `Packages - price: ${currency.name}${price.min_amount} - ${currency.name}${price.max_amount}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (start_date) {
		analytics.event({
			category: `Packages - start_date: ${start_date}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (end_date) {
		analytics.event({
			category: `Packages - end_date: ${end_date}`,
			action: 'Search',
			label: 'Customer'
		});
	}

	return packageActions.getList(currency, sorting, category, destination, price, start_date, end_date, page_number);
}

function searchClear() {
	return packageActions.getListClear();
}

function get(id) {
	var endpoint = Object.assign({}, apiEndpoint.package.get); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, { currency_id: app.getCurrency().id });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: packageConstants.GET_REQUEST, id } }
    function success(responseData, id) { return { type: packageConstants.GET_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: packageConstants.GET_FAILURE, error, id } }
}

function getClear() {
	return { type: packageConstants.GET_CLEAR };
}

function getLocations(id) {
	var endpoint = Object.assign({}, apiEndpoint.package.locations); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: packageConstants.GET_LOCATIONS_REQUEST, id } }
    function success(responseData, id) { return { type: packageConstants.GET_LOCATIONS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: packageConstants.GET_LOCATIONS_FAILURE, error, id } }
}

function getLocationsClear() {
	return { type: packageConstants.GET_LOCATIONS_CLEAR };
}

function getReviews(id, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.package.reviews); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { page_number, per_page });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: packageConstants.GET_REVIEWS_REQUEST, id } }
    function success(responseData, id) { return { type: packageConstants.GET_REVIEWS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: packageConstants.GET_REVIEWS_FAILURE, error, id } }
}

function getReviewsClear() {
	return { type: packageConstants.GET_REVIEWS_CLEAR };
}