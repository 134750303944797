import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import { vendorProfileActions } from '../../../_actions';
import { ReviewsList } from '../../../Package';

import './Reviews.scss';

class Reviews extends Component {
	componentDidMount() {
		this.props.dispatch(vendorProfileActions.getReviewsClear());
	}

	render() {
		const { vendor_profile, vendor_id } = this.props;

		return (
			<div id="vendor_profile_reviews">
				<Container>
					<ReviewsList
						showHeader={false}
						loadMoreProp={vendor_profile.reviews}
						onLoadMore={(nextPage) => {
							this.props.dispatch(vendorProfileActions.getReviews(this.props.vendor_id, nextPage));
						}}
					/>
				</Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_profile } = state;
	return {
		vendor_profile
	};
}

const connected = connect(mapStateToProps)(Reviews);
export { connected as Reviews };