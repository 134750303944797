import { appConstants } from '../_constants';

let currency = JSON.parse(localStorage.getItem('currency'));
if (!currency) {
	currency = {
		"id": 1,
		"name": "HKD",
		"value_en": "HKD",
		"value_hk": "HKD",
		"value_cn": "HKD",
		"value": "HKD",
		"min_value": "200.00",
		"max_value": "999999.00"
	};
	localStorage.setItem('currency', JSON.stringify(currency));
}

let language = JSON.parse(localStorage.getItem('language'));
if (!language) {
	language = {
		"id": 'en',
		"value": "English",
		"i18n_value": "en"
	};
	localStorage.setItem('language', JSON.stringify(language));
}

const currencyInitialState = {
	loading: false,
	error: undefined,
	list: undefined,
	currency: currency
}

const languageInitialState = {
	loading: false,
	error: undefined,
	list: undefined,
	language: language
}
const appInitialState = {
	currency: currencyInitialState,
	language: languageInitialState,
	windowSize: {
		width: undefined,
		height: undefined
	},
	showFooter: true
}

export function app(state = appInitialState, action) {
	switch (action.type) {
		case appConstants.GET_CURRENCIES_CLEAR:
			return { ...state, currency: { ...currencyInitialState }};
		case appConstants.GET_CURRENCIES_REQUEST:
			return { ...state, currency: { ...state.currency, loading: true }};
		case appConstants.GET_CURRENCIES_SUCCESS:
			return { ...state, currency: { ...state.currency, loading: false, list: action.responseData.currencies }};
		case appConstants.GET_CURRENCIES_FAILURE:
			return { ...state, currency: { ...state.currency, loading: false, error: action.error }};

		case appConstants.SET_CURRENCY:
			return { ...state, currency: { ...state.currency, loading: false, currency: action.data }};
		
		case appConstants.GET_LANGUAGES_CLEAR:
			return { ...state, language: { ...languageInitialState }};
		case appConstants.GET_LANGUAGES_REQUEST:
			return { ...state, language: { ...state.language, loading: true }};
		case appConstants.GET_LANGUAGES_SUCCESS:
			return { ...state, language: { ...state.language, loading: false, list: action.responseData }};
		case appConstants.GET_LANGUAGES_FAILURE:
			return { ...state, language: { ...state.language, loading: false, error: action.error }};

		case appConstants.SET_LANGUAGE:
			return { ...state, language: { ...state.language, loading: false, language: action.data }};

		case appConstants.SET_WINDOW_SIZE:
			return { ...state, windowSize: { width: action.width, height: action.height }};

		case appConstants.SHOW_HIDE_FOOTER:
			return { ...state, showFooter: action.show };

		default:
			return state
	}
}