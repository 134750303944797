import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FieldArray, connect as connectFormik } from 'formik';

import { Title } from '../../../_components';

import './LocationPortfolio.scss';

import { Location } from './Location';


class LocationPortfolio extends Component {
	constructor(props) {
		super(props);
		
		this.state = { tabIndex: 0 };
	}

	onSelectTab(tabIndex, arrayHelpers) {
		const { formik, formInfo } = this.props;
		const { values } = formik;

		if (tabIndex >= values.locations.length) {
			const currNoOfLocations = formik.values.locations.length;
			if (currNoOfLocations < formInfo.max_location) {
				arrayHelpers.push({ name: '', items: [] });
				this.setState({ tabIndex: currNoOfLocations });
			}
		} else {
			this.setState({ tabIndex: tabIndex });
		}
	}

	render() {
		const { t, formInfo, formik } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
		const { tabIndex } = this.state;

		return (
			<div id="vendor_createpackage_locationportfolio">
				<Title size="sm">
					{t('location_portfolio')}
				</Title>
				<FieldArray
					name="locations"
					render={arrayHelpers => (
						<Tabs className="locations-tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex, arrayHelpers)}>
							<TabList>
								{values.locations.map((location, index) =>
									<Tab key={`locations.tab.${index}`}>{`${t('location')} (${index + 1})`}</Tab>
								)}
								{values.locations.length < formInfo.max_location &&
									<Tab key="locations.tab.add-location" className="react-tabs__tab tab-add-location">{t('add_location')}</Tab>
								}
							</TabList>
							{values.locations.map((location, index) =>
								<TabPanel key={`locations.panel.${index}`}>
									<Location
										locationsArrayHelpers={arrayHelpers}
										index={index}
										formInfo={formInfo}
										didRemoveLocation={(index) => {
											this.setState({ tabIndex: Math.max(0, index - 1) });
										}}
									/>
								</TabPanel>
							)}
							{values.locations.length < formInfo.max_location &&
								<TabPanel key="locations.panel.add-location-dummy-tabpanel" className="d-none"></TabPanel>
							}
						</Tabs>
					)}
				/>
			</div>
		);
	}
}

const translation = withNamespaces(['package'])(LocationPortfolio);
const connectedFormik = connectFormik(translation);
export { connectedFormik as LocationPortfolio };