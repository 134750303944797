import { 
	INIT_LOGIN,
	LOGIN_REQUEST,
    LOGIN_SUCCESS, 
	LOGIN_FAIL,
	DISCONNECT_SUCCESS
} from './types';
import { sbConnect, sbDisconnect } from '../sendbirdActions';
import { createConnectionHandler, removeConnectionHandler } from './connectionActions';
import { createUnreadCountHandler, removeUnreadCountHandler } from './unreadCountActions';

export const initLogin = () => {
    return { type: INIT_LOGIN };
}

export const sendbirdLogin = (user) => {
    return (dispatch) => {
		loginRequest(dispatch, user);
		
        sbConnect(user)
        .then( (user) => loginSuccess(dispatch, user) )
        .catch( (error) => loginFail(dispatch, error) );
    }
}

const loginRequest = (dispatch) => {
    dispatch({ 
        type: LOGIN_REQUEST
    });
}

const loginFail = (dispatch, error) => {
    dispatch({ 
        type: LOGIN_FAIL,
        payload: error
    });
}

const loginSuccess = (dispatch, user) => {
	dispatch(createConnectionHandler());
	dispatch(createUnreadCountHandler());
	
    dispatch({
        type: LOGIN_SUCCESS, 
        payload: user 
    });
}

export const sendbirdLogout = () => {
    return (dispatch) => {
		dispatch(removeConnectionHandler());
		dispatch(removeUnreadCountHandler());

        sbDisconnect().then( () => dispatch({ type: DISCONNECT_SUCCESS }) );
    }
}