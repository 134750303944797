import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { packageActions } from '../../../../_actions';

import { Title, BaseModal } from '../../../../_components';
import { ReviewsList } from '../../../ReviewsList';
import './ReviewsModal.scss';

import img_reviews_star_off from '../../../../_common/img/details_icon_reviews_star_off.svg';
import img_reviews_star_on from '../../../../_common/img/details_icon_reviews_star_on.svg';

class ReviewsModal extends Component {
	componentDidMount() {
		this.props.dispatch(packageActions.getReviewsClear());
	}
	
	render() {
		const { t, avg_rate, count, package_details, package_id } = this.props;

		const reviewsRate = Math.round(avg_rate);
		const reviewStars = [1, 2, 3, 4, 5];

		return (
			<BaseModal
				id="package_details_reviews_modal"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
				size="lg"
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Container>
						<Row className="header">
							<Col sm={{ size: 'auto', order: 1 }} xs={{ order: 2 }}>
								<div className="stars">
									{reviewStars.map((item) =>
										<img key={item} src={(reviewsRate >= item) ? img_reviews_star_on : img_reviews_star_off} alt="" />
									)}
								</div>
							</Col>
							<Col sm={{ size: true, order: 2 }} xs={{ order: 1 }}>
								<Title>{t('n_reviews', { n: count })}</Title>
							</Col>
						</Row>
					</Container>
				</ModalHeader>
				<ModalBody>
					<Container>
						<ReviewsList
							showHeader={false}
							loadMoreProp={package_details.reviews}
							onLoadMore={(nextPage) => {
								this.props.dispatch(packageActions.getReviews(package_id, nextPage));
							}}
						/>
					</Container>
				</ModalBody>
			</BaseModal>
		);
	}
}

function mapStateToProps(state) {
	const { package_details } = state;
    return {
		package_details
    };
}

const translation = withNamespaces(['package'])(ReviewsModal);
const connected = connect(mapStateToProps)(translation);
export { connected as ReviewsModal };