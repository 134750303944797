import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { withNamespaces } from 'react-i18next';

import { articleActions } from '../../_actions';
import { ResultsGrid, PageContainer, Title } from '../../_components';

import { GridItem } from '../GridItem';

import './Articles.scss';

class Articles extends Component {
	componentDidMount() {
		this.props.dispatch(articleActions.getListClear());
	}
	
	render() {
		const { articles, t } = this.props;

		return (
			<PageContainer id="articles">
				<Container>
					<Title className="title-page">{t('articles')}</Title>
					<ResultsGrid
						infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
						showHeader={false}
						noOfCol_lg={3}
						noOfCol_md={2}
						loadMoreProp={articles}
						onLoadMore={(nextPage) => {
							this.props.dispatch(articleActions.getList(nextPage));
						}}
						renderItem={(item) => {
							return <GridItem key={item.id} item={item} />;
						}}
					/>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { articles } = state;
    return {
		articles
    };
}

const translation = withNamespaces(['article', 'default'])(Articles);
const connected = connect(mapStateToProps)(translation);
export { connected as Articles };