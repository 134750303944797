
import React from "react";

import './SVGImg.scss';

export const SVGImg = ({ className, color, img, ...props}) => {
	const colorClassName = color? `color-${color}` : '';
	const imgClassName = color? `svg-img-${img}` : '';
	let svg = null;

	switch (img) {
		case 'tick':
			svg = (
				<svg version="1.1" x="0px" y="0px" viewBox="0 0 18 14" width="18px" height="14px">
				<path d="M4.577,10.202c0.086-0.115,0.155-0.208,0.225-0.301c2.353-3.126,4.954-6.011,7.981-8.477
					c0.458-0.373,0.963-0.69,1.451-1.024c0.446-0.305,0.936-0.449,1.479-0.387c0.639,0.073,1.279,0.133,1.918,0.203
					c0.165,0.018,0.314,0.087,0.356,0.267c0.047,0.2-0.046,0.361-0.204,0.468c-0.211,0.143-0.433,0.267-0.651,0.399
					c-1.457,0.88-2.76,1.968-4.022,3.11c-2.574,2.33-4.849,4.929-6.812,7.814c-0.312,0.459-0.662,0.89-0.989,1.338
					c-0.218,0.299-0.523,0.429-0.864,0.376c-0.566-0.087-1.126-0.214-1.684-0.345c-0.29-0.068-0.481-0.267-0.592-0.559
					c-0.259-0.682-0.507-1.371-0.808-2.035C1.05,10.36,0.676,9.7,0.341,9.021c-0.122-0.247-0.225-0.507-0.31-0.77
					c-0.076-0.237-0.008-0.471,0.162-0.639c0.549-0.541,1.21-0.824,1.974-0.706c0.252,0.039,0.504,0.216,0.714,0.384
					c0.556,0.445,0.933,1.035,1.211,1.692C4.257,9.373,4.405,9.77,4.577,10.202z" />
				</svg>
			)
			break;
		case 'processing':
			svg = (
				<svg version="1.1" x="0px" y="0px" viewBox="0 0 18 18" width="18px" height="18px">
				<g>
					<path d="M17.969,8.695c-0.023-0.033-0.045-0.069-0.068-0.107c-0.056-0.091-0.12-0.195-0.212-0.277
						c-0.279-0.247-0.629-0.294-0.987-0.133c-0.326,0.147-0.505,0.428-0.532,0.833c-0.007,0.107-0.012,0.214-0.018,0.321
						c-0.017,0.33-0.035,0.672-0.096,0.993c-0.744,3.883-4.384,6.409-8.286,5.751c-1.814-0.306-3.427-1.3-4.54-2.799
						c-1.114-1.501-1.597-3.332-1.359-5.157C2.159,5.922,3.255,4.184,5.13,2.956C6.297,2.192,7.59,1.812,8.973,1.828
						c0.004,0,0.007,0,0.011,0c0.276,0,0.545-0.127,0.722-0.341C9.881,1.276,9.95,1,9.896,0.731C9.827,0.39,9.61,0.155,9.234,0.011
						L9.204,0H8.448L8.425,0.002C8.321,0.016,8.217,0.029,8.113,0.043C7.89,0.072,7.66,0.102,7.434,0.139
						c-3.517,0.573-6.485,3.418-7.22,6.918C0.157,7.332,0.12,7.613,0.085,7.884c-0.017,0.128-0.034,0.261-0.053,0.39
						c-0.004,0.028-0.011,0.058-0.018,0.09c-0.004,0.017-0.007,0.033-0.01,0.05L0,8.431v1.121l0.002,0.023
						c0.014,0.104,0.028,0.208,0.041,0.311c0.029,0.222,0.059,0.452,0.096,0.678c0.577,3.517,3.422,6.486,6.917,7.221
						c0.279,0.059,0.563,0.095,0.839,0.131c0.13,0.017,0.265,0.034,0.396,0.054c0.023,0.003,0.049,0.01,0.077,0.016
						c0.015,0.004,0.03,0.007,0.045,0.01L8.43,18h1.134l0.012-0.002c0.104-0.014,0.209-0.028,0.313-0.042
						c0.222-0.029,0.452-0.059,0.677-0.096c1.697-0.276,3.349-1.116,4.652-2.364c1.303-1.248,2.215-2.866,2.568-4.554
						c0.057-0.275,0.094-0.556,0.13-0.827c0.017-0.128,0.034-0.261,0.053-0.39c0.004-0.029,0.011-0.059,0.018-0.091
						c0.004-0.016,0.007-0.033,0.01-0.049L18,9.569v-0.83L17.969,8.695z"/>
					<g>
						<path d="M12.34,9.657c-0.95-0.48-1.682-0.846-2.373-1.185C9.905,8.441,9.901,8.435,9.902,8.368
							c0.006-1.506,0.004-3.051,0.002-4.413c0-0.096,0-0.228-0.034-0.352C9.756,3.183,9.335,2.895,8.912,2.946
							C8.435,3.003,8.099,3.373,8.097,3.847C8.093,4.456,8.094,5.075,8.095,5.674c0,0.248,0.001,0.495,0.001,0.743
							c0,0.241,0,0.483,0,0.724c0,0.599,0.001,1.218-0.001,1.826C8.093,9.353,8.265,9.644,8.592,9.809
							c1.046,0.529,1.99,1.004,2.945,1.467c0.12,0.058,0.27,0.089,0.422,0.089c0.054,0,0.107-0.004,0.16-0.012
							c0.388-0.058,0.674-0.368,0.727-0.789C12.893,10.198,12.689,9.833,12.34,9.657z"/>
						<path d="M16.477,4.994c-0.001,0-0.003,0-0.004,0c-0.505,0.002-0.902,0.4-0.903,0.905
							c-0.001,0.499,0.403,0.908,0.901,0.911c0.002,0,0.004,0,0.006,0c0.242,0,0.469-0.094,0.64-0.264
							c0.174-0.174,0.269-0.406,0.267-0.656C17.381,5.387,16.983,4.994,16.477,4.994z"/>
						<path d="M12.121,0.617L12.121,0.617c-0.505-0.009-0.912,0.376-0.926,0.876c-0.007,0.249,0.08,0.481,0.245,0.655
							c0.162,0.171,0.385,0.269,0.626,0.274c0.008,0,0.016,0,0.024,0c0.244,0,0.471-0.089,0.641-0.252
							c0.171-0.165,0.268-0.39,0.272-0.635C13.012,1.03,12.624,0.626,12.121,0.617z"/>
						<path d="M14.731,2.37c-0.248-0.003-0.48,0.09-0.651,0.26c-0.171,0.17-0.266,0.401-0.265,0.649
							c0.001,0.503,0.397,0.9,0.902,0.902c0.002,0,0.003,0,0.005,0c0.242,0,0.468-0.092,0.636-0.259c0.17-0.168,0.264-0.395,0.265-0.639
							C15.625,2.777,15.234,2.376,14.731,2.37z"/>
					</g>
				</g>
				</svg>
			)
			break;
			case 'menu-hamburger':
			svg = (
				<svg version="1.1" x="0px" y="0px" viewBox="0 0 24 21" width="24px" height="21px">
				<path d="M12,1.166q5.3,0,10.589,0a1.327,1.327,0,1,1,.159,2.65c-.073,0-.146,0-.218,0H1.473A1.331,1.331,0,1,1,1.22,1.172c.125-.009.25-.006.375-.006Z"/>
				<path d="M12,9.164q5.3,0,10.589,0a1.327,1.327,0,1,1,.161,2.65c-.073,0-.146,0-.219,0H1.474A1.33,1.33,0,1,1,1.22,9.171c.124-.009.249-.006.374-.006Z"/>
				<path d="M11.965,19.818q-5.278,0-10.557,0a1.328,1.328,0,1,1-.154-2.651c.073,0,.146,0,.219,0q10.526,0,21.052-.008a1.385,1.385,0,0,1,1.465,1.2,1.317,1.317,0,0,1-1.311,1.462c-1.041.013-2.082,0-3.124,0Z"/>
				</svg>
			)
			break;
			case 'inbox':
			svg = (
				<svg version="1.1" x="0px" y="0px" viewBox="0 0 24 21" width="24px" height="21px">
				<path d="M12,0C5.487,0,0.188,4.707,0.188,10.493c0,2.533,1.023,4.95,2.894,6.863c-0.306,0.518-0.725,1.014-1.254,1.481
				l-0.01,0.009c-0.254,0.233-0.4,0.563-0.4,0.904c0,0.569,0.388,1.048,0.916,1.192L2.373,21l0.245-0.013c0.015,0,0.032,0,0.048,0
				l0.096,0.002l0.048-0.013c1.353-0.078,2.743-0.415,4.137-1.007c1.592,0.675,3.29,1.018,5.055,1.018
				c6.513,0,11.812-4.707,11.812-10.493S18.513,0,12,0z M12,19.531c-1.663,0-3.261-0.343-4.749-1.02l-0.304-0.138l-0.306,0.136
				c-1.137,0.514-2.265,0.836-3.363,0.965c0.596-0.61,1.057-1.27,1.371-1.964l0.216-0.474l-0.384-0.355
				c-1.822-1.691-2.826-3.888-2.826-6.188c0-4.984,4.64-9.037,10.344-9.037s10.344,4.053,10.344,9.037S17.705,19.531,12,19.531z"/>
				<path d="M7.455,9.478c-0.721-0.005-1.292,0.549-1.3,1.26c-0.008,0.715,0.549,1.287,1.264,1.296
					c0.735,0.01,1.312-0.55,1.314-1.274C8.735,10.046,8.174,9.483,7.455,9.478z"/>
				<path d="M12.021,9.478c-0.722-0.008-1.297,0.543-1.307,1.253c-0.01,0.729,0.554,1.302,1.284,1.304
					c0.72,0.002,1.288-0.555,1.293-1.267C13.296,10.052,12.738,9.486,12.021,9.478z"/>
				<path d="M16.569,9.478c-0.72-0.004-1.292,0.551-1.298,1.262c-0.006,0.728,0.562,1.296,1.293,1.295
					c0.718-0.001,1.283-0.563,1.284-1.276C17.85,10.044,17.289,9.483,16.569,9.478z"/>
				</svg>
			)
			break;
			case 'arrow-left':
			svg = (
				<svg x="0px" y="0px" viewBox="0 0 14 23"><path d="M13.871,22.837,3.209,11.5,13.871.163A1.092,1.092,0,0,0,14,0H11.135L1.017,10.459,0,11.5l1.046,1.071L11.135,23H14A1.1,1.1,0,0,0,13.871,22.837Z"/></svg>
			)
			break;
			case 'arrow-right':
			svg = (
				<svg x="0px" y="0px" viewBox="0 0 14 23"><path d="M.128.163,10.79,11.5.129,22.837A1.1,1.1,0,0,0,0,23H2.865L12.982,12.541,14,11.5l-1.047-1.071L2.865,0H0A1.092,1.092,0,0,0,.128.163Z"/></svg>
			)
			break;
		default:
			break;
	}

	return svg? <span className={`svg-img ${imgClassName} ${colorClassName? colorClassName : ''} ${className? className : ''}`} {...props}>{svg}</span> : null;
}