import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

const loggerMiddleware = createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let appliedMiddleware = applyMiddleware(
	thunkMiddleware,
);

const hideDebug = (process.env.REACT_APP_ENV === 'prod' && process.env.NODE_ENV !== 'development');
if (!hideDebug) {
	appliedMiddleware = applyMiddleware(
		thunkMiddleware,
		loggerMiddleware
	);
}

export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(appliedMiddleware));
