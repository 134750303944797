import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';
import { InputField, InputFilePreview } from '../../../../../_components';
import { connect as connectFormik } from 'formik';

import './Photo.scss';

class Photo extends Component {
	render() {
		const { t, locationIndex, index, locationItemsArrayHelpers, formik } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

		return (
			<div id="vendor_createpackage_locationportfolio_location_photo">
				<div className="photo-wrapper">
					<div className="photo">
					{values.locations[locationIndex].items[index].photo && <InputFilePreview file={values.locations[locationIndex].items[index].photo} />}
					{!values.locations[locationIndex].items[index].photo && values.locations[locationIndex].items[index].photo_url && <img src={values.locations[locationIndex].items[index].photo_url} alt=""/>}
					</div>
				</div>
				<div className={`cover-image ${values.locations[locationIndex].items[index].is_cover? '' : 'd-none'}`}>
					{t('cover_image')}
				</div>
				<InputField
					component="textarea"
					name={`locations.${locationIndex}.items.${index}.desc`}
					placeholder={t('location_photo_desc')}
					t={t} />
				<div className="flex-btns">
					<Button className="btn-plain" onClick={() => {
						//set any items.is_cover === true to false =.=
						values.locations[locationIndex].items.forEach((item, index) => {
							setFieldValue(`locations.${locationIndex}.items.${index}.is_cover`, false);	
						});
						setFieldValue(`locations.${locationIndex}.items.${index}.is_cover`, true);
						setFieldTouched(`locations.${locationIndex}.items.${index}.is_cover`, true);

					}}>{t('make_cover')}</Button>
					<Button className="btn-plain" onClick={() => {
						let item = values.locations[locationIndex].items[index];

						if (item.is_cover && values.locations[locationIndex].items.length > 1) {
							let indexCover = index? 0 : ((index + 1) % values.locations[locationIndex].items.length);
							values.locations[locationIndex].items[indexCover].is_cover = true;
						}

						locationItemsArrayHelpers.remove(index);
					}}>{t('remove')}</Button>
				</div>
			</div>
		);
	}
}

const translation = withNamespaces(['package', 'default'])(Photo);
const connectedFormik = connectFormik(translation);
export { connectedFormik as Photo };