import React, { Component } from 'react';
import { CardGroup } from 'reactstrap';

import './Details.scss';

import { Header } from '../Header';
import { Status, ShootingDatetime } from '../../../Order';
import { OrderSummary } from './OrderSummary';
import { CancelOfferModal } from '../CancelOfferModal';

export class Details extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cancelOfferModalIsOpen: false
		}
	}

	onCancelOffer() {
		this.setState({ cancelOfferModalIsOpen: true });
	}

	render() {
		const { match, data } = this.props;
		const { cancelOfferModalIsOpen } = this.state;

		return (
			<div id="vendor_order_details">
				<Header data={data}/>
				<CardGroup className="order-info">
					<Status
						data={data}
						onCancelOffer={() => this.onCancelOffer()}
					/>
					<ShootingDatetime
						match={match}
						data={data}
					/>
				</CardGroup>
				<OrderSummary
					data={data}
				/>
				<CancelOfferModal
					data={data}
					isOpen={cancelOfferModalIsOpen}
					toggle={() => this.setState({ cancelOfferModalIsOpen: !this.state.cancelOfferModalIsOpen })}
				/>
			</div>
		);
	}
}