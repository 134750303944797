import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Container } from 'reactstrap';

import { Title, Desc } from '../../_components';
import { connect } from 'react-redux';
import { StaticPageContainer } from '../StaticPageContainer';

import '../StaticPage.scss';
import './TNC.scss';

import { TermsEN } from './Terms';

class TNC extends Component {
	render() {
		const { t, match, app } = this.props;
		let lang = app.language.language.id;

		return (
			<StaticPageContainer id="tnc" match={match}>
				<Container>
					<Title className="title-page">
						{t('tnc')}
					</Title>
					{<TermsEN />}
				</Container>
			</StaticPageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['tnc'])(TNC);
const connected = connect(mapStateToProps)(translation);
export { connected as TNC };