import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { vendorActions } from '../../../_actions';
import { Title, Loading } from '../../../_components';
import { Section } from '../../Section';
import { HomeSwiper } from '../../HomeSwiper';

import './Vendors.scss';

import { GridItemContent as VendorGridItem } from '../../../Vendor';

class Vendors extends Component {
	componentDidMount() {
		this.props.dispatch(vendorActions.getFeaturedList());
	}

	render() {
		const { t, featured_vendors, saved_item_action, auth, dispatch } = this.props;

		return (
			<>
				{featured_vendors.loading && <Loading />}
				{featured_vendors.error && <Loading error={featured_vendors.error} />}
				{featured_vendors.list &&
					<Section className="home-section-vendors">
						<Title size="xs" className="section-title">{t('customer.featured_vendors')}</Title>
						<HomeSwiper swiperParams={{
							slidesPerView: 5,
							hideNavs: featured_vendors.list.length <= 5,
							hideDots: featured_vendors.list.length <= 5,
							loop: featured_vendors.list.length > 5,
							breakpoints: {
								576: {
									slidesPerView: 1,
									hideNavs: featured_vendors.list.length <= 1,
									hideDots: featured_vendors.list.length <= 1,
									loop: featured_vendors.list.length > 1,
								},
								768: {
									slidesPerView: 2,
									hideNavs: featured_vendors.list.length <= 2,
									hideDots: featured_vendors.list.length <= 2,
									loop: featured_vendors.list.length > 2,
								},
								992: {
									slidesPerView: 2,
									hideNavs: featured_vendors.list.length <= 2,
									hideDots: featured_vendors.list.length <= 2,
									loop: featured_vendors.list.length > 2,
								},
								1200: {
									slidesPerView: 3,
									hideNavs: featured_vendors.list.length <= 3,
									hideDots: featured_vendors.list.length <= 3,
									loop: featured_vendors.list.length > 3,
								},
								1500: {
									slidesPerView: 4,
									hideNavs: featured_vendors.list.length <= 4,
									hideDots: featured_vendors.list.length <= 4,
									loop: featured_vendors.list.length > 4,
								}
							},
						}}>
							{featured_vendors.list.map((item, index) =>
								<div key={item.id} className="vendor">
									<VendorGridItem
										item={item}
										t={t}
										saved_item_action={saved_item_action}
										auth={auth}
										dispatch={dispatch}
									/>
								</div>
							)}
						</HomeSwiper>
					</Section>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { featured_vendors, saved_item_action, auth } = state;
	return {
		featured_vendors, saved_item_action, auth
	};
}

const translation = withNamespaces(['home', 'default'])(Vendors);
const connected = connect(mapStateToProps)(translation);
export { connected as Vendors };