import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Moment from 'moment';

import { Desc, InputItem, InputItemLabel, Title } from '../../../../_components';

import './Details.scss';

import { Map } from '../../../../Order';

class Details extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<div id="vendor_order_meetup_details">
				<Card>
					<CardHeader>
						<CardTitle tag="h4">
							{t('meetup_details.title')}
						</CardTitle>
						<Desc>
							{t('meetup_details.desc')}
						</Desc>
					</CardHeader>
					<CardBody>
						<InputItem>
							<InputItemLabel>
								{t('meetup_time_place')}
							</InputItemLabel>
							<Title>
								{`${Moment.utc(data.datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.utc_offset).format('D MMM YYYY h:mma')} ${data.utc_offset}`}
							</Title>
						</InputItem>
						<InputItem>
							<InputItemLabel>
								{t('location')}
							</InputItemLabel>
							<Desc>
								{data.full_address}
							</Desc>
							<Map
								center={{
									lat: Number(data.location_lat), lng: Number(data.location_long)
								}}
								markers={[{
									position: {
										lat: Number(data.location_lat), lng: Number(data.location_long)
									},
									name: data.location_title
								}]}
							/>
						</InputItem>
						{data.remarks &&
							<InputItem>
								<InputItemLabel>
									{t('remarks')}
								</InputItemLabel>
								<Desc>
									{data.remarks}
								</Desc>
							</InputItem>
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}

const translation = withNamespaces(['meetup', 'package', 'default'])(Details);
export { translation as Details };