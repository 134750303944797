import { articleConstants, savedItemsConstants, userConstants } from '../_constants';

const articlesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	hasMore: true,
	total: undefined
}

export function articles(state = articlesInitialState, action) {
	switch (action.type) {
		case articleConstants.GET_LIST_CLEAR:
			return { ...articlesInitialState };
		case articleConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case articleConstants.GET_LIST_SUCCESS:
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.articles;
			} else {
				list = list.concat(action.responseData.articles);
			}
			return { ...state,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined
			};
		case articleConstants.GET_LIST_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case savedItemsConstants.ADD_ARTICLE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		case savedItemsConstants.REMOVE_ARTICLE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = false;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					item.is_bookmarked = false;
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		default:
			return state
	}
}

const hotArticlesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

export function hot_articles(state = hotArticlesInitialState, action) {
	switch (action.type) {
		case articleConstants.GET_HOT_LIST_CLEAR:
			return { ...hotArticlesInitialState };
		case articleConstants.GET_HOT_LIST_REQUEST:
			return { ...hotArticlesInitialState, loading: true };
		case articleConstants.GET_HOT_LIST_SUCCESS:
			return { ...hotArticlesInitialState, loading: false, success: true, list: action.responseData, };
		case articleConstants.GET_HOT_LIST_FAILURE:
			return {...hotArticlesInitialState, loading: false, error: action.error };

		case savedItemsConstants.ADD_ARTICLE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		case savedItemsConstants.REMOVE_ARTICLE_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = false;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		
		default:
			return state
	}
}

const articleDetailsInitialState = {
	id: undefined,
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
}

export function article(state = articleDetailsInitialState, action) {
	switch (action.type) {
		case articleConstants.GET_CLEAR:
			return { ...articleDetailsInitialState };
		case articleConstants.GET_REQUEST: {
			let updateFromState = state.id == action.id? state : articleDetailsInitialState;
			return { ...updateFromState, loading: true, success: false, id: action.id, error: undefined };
		}
		case articleConstants.GET_SUCCESS:
			return { ...state, loading: false, success: true, id: action.id, data: action.responseData, error: undefined };
		case articleConstants.GET_FAILURE:
			return {...state, loading: false, success: false, id: action.id, error: action.error };

		case savedItemsConstants.ADD_ARTICLE_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = true;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}
		case savedItemsConstants.REMOVE_ARTICLE_SUCCESS: {
			if (state.data && state.data.id == action.id) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.data) {
				const updatedData = state.data;
				updatedData.is_bookmarked = false;
				return { ...state, data: updatedData };
			}
			return { ...state };
		}

		default:
			return state
	}
}