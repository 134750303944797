import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { orderActions } from '../../../_actions';
import { userType } from '../../../_constants';
import { Title, InputItem, InputField, InputSelect, Loading } from '../../../_components';

import './InputForm.scss';

class InputForm extends Component {
	componentDidMount() {
		const { auth } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		this.props.dispatch(orderActions.getReportOptions(isVendor));
	}

	render() {
		const { t, order } = this.props;

		const schema = Yup.object().shape({
			report_item_id: Yup.string()
				.required(t('required')),
			desc: Yup.string()
				.required(t('required')),
		});

		return (
			<>
				{order.report.loading && <Loading className="modal-content-container" />}
				{order.report.error && <Loading className="modal-content-container" error={order.report.error} />}
				{order.report.options &&
					<Formik
						enableReinitialize
						initialValues={{
							report_item_id: '',
							desc: ''
						}}
						validationSchema={schema}
						onSubmit={(values, formikBag) => this.props.onSubmit(values, formikBag)}
					>
						{({ values,
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							setFieldTouched,
							setFieldValue
							/* and other goodies */
						}) => (
								<Form id="order_report_form" className="modal-content-container">
									<ModalHeader toggle={() => this.props.toggle()}>
										<Title>{t('report')}</Title>
									</ModalHeader>
									<ModalBody>
										<Container>
											<InputItem>
												<InputSelect
													name="report_item_id" t={t}
													options={order.report.options}
													isSearchable={false}
												/>
											</InputItem>
											<InputItem>
												<InputField component="textarea" type="text" name="desc" t={t} />
											</InputItem>
										</Container>
									</ModalBody>
									<ModalFooter>
										<Container className="container-p-8">
											<Row className="row-m-8">
												<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
													<Button
														block
														type="submit"
														disabled={this.props.submitting}
														onClick={handleSubmit}>
														{t('submit')}
													</Button>
												</Col>
												<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
													<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
												</Col>
											</Row>
										</Container>
									</ModalFooter>
								</Form>
							)}
					</Formik>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
	return {
		auth,
		order
	};
}

const translation = withNamespaces(['report', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
export { connected as InputForm };