export const apiDomain = process.env.REACT_APP_API_HOST;

export const apiMethod = {
	get: 'GET',
	post: 'POST',
	delete: 'DELETE'
};

export const apiEndpoint = {
	app: {
		currencies: { method: apiMethod.get, path: '/currencies' },
	},
	contact_us: { method: apiMethod.post, path: '/contact_us' },
	user: {
		checkEmail: { method: apiMethod.post, path: '/user/email/check' },
		sendCode: { method: apiMethod.post, path: '/user/register/send_code' },
		register: { method: apiMethod.post, path: '/user/register' },
		login: { method: apiMethod.post, path: '/user/login' },
		logout: { method: apiMethod.post, path: '/user/logout' },
		reset_password: {
			send_code: { method: apiMethod.post, path: '/user/password/send_code' },
			verify_code: { method: apiMethod.post, path: '/user/password/verify_code' },
			edit_by_code: { method: apiMethod.post, path: '/user/password/edit_by_code' },
		},
		edit_profile: { method: apiMethod.post, path: '/user' },
		edit_email: {
			send_code: { method: apiMethod.post, path: '/user/email/send_code' },
			edit_by_code: { method: apiMethod.post, path: '/user/email/edit' },
		},
		edit_password: { method: apiMethod.post, path: '/user/password/edit' },
		get: { method: apiMethod.get, path: '/user' },
	},
	vendor: {
		options: {
			language: { method: apiMethod.get, path: 'vendor/language_options' },
			city: { method: apiMethod.get, path: 'vendor/city_options' },
		},
		search: { method: apiMethod.get, path: 'vendor/search' },
		featured_list: { method: apiMethod.get, path: 'vendor/list_featured_vendors' },
	},
	article: {
		list: { method: apiMethod.get, path: '/articles' },
		hot_list: { method: apiMethod.get, path: '/hot_articles' },
		get: { method: apiMethod.get, path: '/article/:id' },
	},
	package: {
		destinations: { method: apiMethod.get, path: '/destinations' },
		categories: { method: apiMethod.get, path: '/categories' },
		filter_items: { method: apiMethod.get, path: '/package/filter_items' },
		sort_items: { method: apiMethod.get, path: '/package/sort_items' },
		packages: { method: apiMethod.get, path: '/packages' },
		get: { method: apiMethod.get, path: '/package/:id' },
		locations: { method: apiMethod.get, path: '/package/:id/locations' },
		reviews: { method: apiMethod.get, path: '/package/:id/reviews' },
	},
	saved_items: {
		packages: {
			get: { method: apiMethod.get, path: '/user/bookmark/package' },
			edit: { method: apiMethod.post, path: '/user/bookmark/package/:id' },
			delete: { method: apiMethod.delete, path: '/user/bookmark/package/:id' },
		},
		vendors: {
			get: { method: apiMethod.get, path: '/user/bookmark/vendor' },
			edit: { method: apiMethod.post, path: '/user/bookmark/vendor/:id' },
			delete: { method: apiMethod.delete, path: '/user/bookmark/vendor/:id' },
		},
		articles: {
			get: { method: apiMethod.get, path: '/user/bookmark/article' },
			edit: { method: apiMethod.post, path: '/user/bookmark/article/:id' },
			delete: { method: apiMethod.delete, path: '/user/bookmark/article/:id' },
		},
		count: { method: apiMethod.get, path: '/user/bookmark/item_counts' }, 
	},
	vendor_profile: {
		calendar: { method: apiMethod.get, path: '/vendor/:id/calendar' },
		calendar_order: { method: apiMethod.get, path: '/user/vendor/:id/calendar' },
		details: { method: apiMethod.get, path: '/vendor/:id' },
		reviews: { method: apiMethod.get, path: '/vendor/:id/reviews' },
		packages: { method: apiMethod.get, path: '/vendor/:id/packages' },
	},
	my_reviews: {
		get: { method: apiMethod.get, path: 'vendor/reviews' },
	},
	my_calendar: {
		get: { method: apiMethod.get, path: '/vendor/calendar' },
		edit: { method: apiMethod.post, path: '/vendor/calendar' },
		orders: { method: apiMethod.get, path: '/vendor/calendar/orders' },
	},
	vendor_verification: {
		get: { method: apiMethod.get, path: '/vendor/verification' },
		edit: { method: apiMethod.post, path: '/vendor/verification' },
	},
	payout: {
		payouts: { method: apiMethod.get, path: '/vendor/payouts' },
		get: { method: apiMethod.get, path: '/vendor/payout/:id' },
		receipt: { method: apiMethod.get, path: '/vendor/payout/:id/receipt' },
		packages_short_info: { method: apiMethod.get, path: '/vendor/package/short_info' },
	},
	payout_settings: {
		form_info: { method: apiMethod.post, path: '/vendor/payout/form_info' },
		get: { method: apiMethod.get, path: '/vendor/payout/setting' },
		edit: { method: apiMethod.post, path: '/vendor/payout/setting' },
	},
	order: {
		request: { method: apiMethod.post, path: '/:user_type/package/:id/request' },
		make_offer: { method: apiMethod.post, path: '/:user_type/order/:id/offer' },
		list: { method: apiMethod.get, path: '/:user_type/orders' },
		get: { method: apiMethod.get, path: '/:user_type/order/:id' },
		edit: { method: apiMethod.post, path: '/:user_type/order/:id' },
		cancel: { method: apiMethod.post, path: '/:user_type/order/:id/cancel' },
		complete: { method: apiMethod.post, path: '/:user_type/order/:id/complete' },
		pending_item: {
			edit_date: { method: apiMethod.post, path: '/:user_type/order/:id/edit_datetime' },
			edit_date_history: { method: apiMethod.get, path: '/:user_type/order/:id/edit_datetime_history' },
			list: { method: apiMethod.get, path: '/:user_type/order/:id/pending_items' },
			count: { method: apiMethod.get, path: '/:user_type/order/:id/pending_item_count' },
			reply: { method: apiMethod.post, path: '/:user_type/pending_item/:id/reply' },
			cancel: { method: apiMethod.post, path: '/:user_type/pending_item/:id/cancel' },
		},
		payment: {
			list: { method: apiMethod.get, path: '/:user_type/order/:id/payments' },
			process: { method: apiMethod.post, path: '/:user_type/payment/process' },
			get: { method: apiMethod.get, path: '/:user_type/payment/:id' },
			apply_promo_code: { method: apiMethod.post, path: '/:user_type/payment/:id/apply_promotion_code' },
		},
		receipt: {
			list: { method: apiMethod.get, path: '/user/order/:id/receipts' },
			get: { method: apiMethod.get, path: '/user/receipt/:id' },
		},
		meetup: {
			edit: { method: apiMethod.post, path: '/:user_type/order/:id/meetup' },
			get: { method: apiMethod.get, path: '/:user_type/order/:id/meetup' },
		},
		materials: {
			edit: { method: apiMethod.post, path: '/:user_type/order/:id/material' },
			get: { method: apiMethod.get, path: '/:user_type/order/:id/material' },
		},
		review: {
			get: { method: apiMethod.get, path: '/:user_type/order/:id/review' },
			edit: { method: apiMethod.post, path: '/:user_type/order/:id/review' },
		},
		report: {
			options: { method: apiMethod.get, path: '/report/options' },
			edit: { method: apiMethod.post, path: '/:user_type/order/:id/report' },
		},
		edit_shoot_datetime: { method: apiMethod.post, path: '/:user_type/order/:id/shoot_datetime' },
	},
	vendor_package: {
		package: {
			form_info: { method: apiMethod.get, path: '/vendor/package/form_info' },
			revenue: { method: apiMethod.post, path: '/vendor/package/revenue' },
			publish: { method: apiMethod.post, path: 'vendor/package/:id/publish_status' },

			list: { method: apiMethod.get, path: '/vendor/packages' },
			get: { method: apiMethod.get, path: '/vendor/package/:id' },
			locations: { method: apiMethod.get, path: '/vendor/package/:id/locations' },
			edit: { method: apiMethod.post, path: '/vendor/package/:id' },
			delete: { method: apiMethod.delete, path: '/vendor/package/:id' },
			count: { method: apiMethod.get, path: 'vendor/package/count' },
		},
		draft: {
			save: { method: apiMethod.post, path: '/vendor/draft/save' },
			publish: { method: apiMethod.post, path: '/vendor/draft/:id/publish' },

			list: { method: apiMethod.get, path: '/vendor/drafts' },
			get: { method: apiMethod.get, path: '/vendor/draft/:id' },
			locations: { method: apiMethod.get, path: '/vendor/draft/:id/locations' },
			edit: { method: apiMethod.post, path: '/vendor/draft/:id' },
			delete: { method: apiMethod.delete, path: '/vendor/draft/:id' },
			count: { method: apiMethod.get, path: 'vendor/draft/count' },
		},
	},
	home: {
		special: {
			list: { method: apiMethod.get, path: '/specials' },
			details: { method: apiMethod.get, path: '/special/:id' },
		}
	}
};
