import React from 'react';

import './StaticPageContainer.scss';

import { PageContainer } from '../../_components';

export const StaticPageContainer = ({match, children, ...props}) => {
	const isVendor = match? match.url.startsWith('/vendor') : false;

	return (
		<PageContainer className={`static-page-container ${isVendor? 'static-page-container-vendor' : ''}`} {...props}>
		{children}
		</PageContainer>
	);
}