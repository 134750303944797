import {
	sbIsImageMessage as sb_isImageMessage,
} from '../_sendbird';
import { orderStatus, orderPaymentStatus } from '../_constants';

export const utils = {
	getMessageTypeWithMessage,
	constructSystemMessageWithMessage
}

function getMessageTypeWithMessage(message) {
	let messageType = 'user';
	if (message.isUserMessage()) {
		if (message.customType.length) {
			messageType = 'system';
		}
	} else if (message.isFileMessage()) {
		messageType = 'file';

		if (sb_isImageMessage(message)) {
			messageType = 'image';
		}
	}

	return messageType;
}

function constructSystemMessageWithMessage({t, message, isVendor}) {
	const customType = message.customType;
	const type = customType === 'MSG_ORDER_CREATE'? 'order' : customType.startsWith('REMINDER_')? 'admin' : 'general';

	let title = '';
	let desc = '';
	let url = undefined;
	let btnTitleKey = 'view_details';
	let data = JSON.parse(message.data);

	//for title and desc
	if (customType === 'MSG_ORDER_CANCELLED_VENDOR') {
		const isRequesting = data.order_status === orderStatus.requesting;
		title = t(`system_message.${customType}.${isRequesting ? 'requesting' : 'order'}.title`);
		desc = t(`system_message.${customType}.${isRequesting ? 'requesting' : 'order'}.desc`);
	} else if (customType === 'MSG_ORDER_MEETUP_UPDATED' || customType === 'MSG_ORDER_MATERIALS_UPDATED' || customType === 'MSG_ORDER_REVIEW_UPDATED') {
		const isEdit = data.is_edit;
		title = t(`system_message.${customType}.${isEdit ? 'edit' : 'add'}.title`);
		desc = t(`system_message.${customType}.${isEdit ? 'edit' : 'add'}.desc`);
	} else if (customType === 'MSG_ORDER_EDIT_SHOOTING_DATETIME_REPLIED_CUSTOMER' || customType === 'MSG_ORDER_EDIT_SHOOTING_DATETIME_REPLIED_VENDOR') {
		const accept = data.accept;
		title = t(`system_message.${customType}.${accept ? 'accept' : 'reject'}.title`);
		desc = t(`system_message.${customType}.${accept ? 'accept' : 'reject'}.desc`);
	} else if (customType === 'REMINDER_ORDER_REFUND_UPDATED') {
		const isSuccess = data.status == orderPaymentStatus.paid;
		const isFailed = data.status == orderPaymentStatus.failed;

		title = t(`system_message.${customType}.${isSuccess ? 'success' : isFailed? 'failed' : 'processing'}.title`);
		desc = t(`system_message.${customType}.${isSuccess ? 'success' : isFailed? 'failed' : 'processing'}.desc`);
	} else {
		title = t(`system_message.${customType}.title`);
		desc = t(`system_message.${customType}.desc`);
	}

	//for viewDetailsUrl
	if (customType.includes('ORDER')) {
		url = `/__user_type__/me/order/${data.order_id}`;

		if (customType.includes('SHOOTING_DATETIME')) {
			if (customType.includes('CANCELLED')) {
				
			} else if (customType.includes('REQUESTED')) {
				url = url + '/pending_items';
			} else if (customType.includes('REPLIED')) {
				const accept = data.accept;
				if (accept) {

				} else {
					url = url + '/edit_shooting_datetime_history';
				}
			}
		} else if (customType.includes('MEETUP')) {
			url = url + '/meet_up';
		} else if (customType.includes('MATERIALS')) {
			url = url + '/after_shooting';
		} else if (customType.includes('REVIEW')) {
			if (isVendor) {
				url = '/vendor/me/vendor_profile/reviews';
			} else {

			}
		} else if (customType.includes('REMINDER_ORDER_PAYMENT')) {
			if (isVendor) {
				url = undefined;
			} else {
				url = url + `/payment/${data.payment_id}/pay`;
				btnTitleKey = 'pay_now';
			}
		}
	} else if (customType.includes('VERIFICATION_STATUS')) {
		if (isVendor) {
			url = '/__user_type__/me/vendor_profile';
		}
	}

	if (url) {
		url = url.replace('__user_type__', isVendor? 'vendor' : 'customer');
	}

	return { type, customType, title, desc, url, data, btnTitleKey };
}