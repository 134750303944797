import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Helmet } from "react-helmet";

import { headerActions, packageActions } from '../../_actions';

import '../../_common/date_range_picker.scss';
import './Details.scss';

import { PageContainer, LoadingPage, ErrorPage } from '../../_components';

import { DetailsContentRouter } from './DetailsContent';

class Details extends Component {
	componentDidMount() {
		// this.props.dispatch(headerActions.gradientStyle(true));
		this.props.dispatch(packageActions.get(this.props.match.params.id));
	};

	componentWillUnmount() {
		// this.props.dispatch(headerActions.gradientStyle(false));
	};

	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		this.props.dispatch(packageActions.get(props.match.params.id));
	}

	render() {
		const { package_details, match, t } = this.props;

		return (
			<>
				{package_details.loading && <LoadingPage />}
				{package_details.error && <ErrorPage match={this.props.match} error={package_details.error} />}
				{package_details.data &&
					<>
						<Helmet>
							<meta property="og:title" content={`${t('og_title')} - ${package_details.data.name}`} />
							<meta property="og:description" content={package_details.data.desc} />
							<meta property="og:image" content={package_details.data.video_image_url} />

							<meta property="twitter:title" content={`${t('og_title')} - ${package_details.data.name}`} />
							<meta property="twitter:description" content={package_details.data.desc} />
							<meta property="twitter:image" content={package_details.data.video_image_url} />
						</Helmet>
						<PageContainer fullPage id="package_details">
							<DetailsContentRouter match={match} data={package_details.data} />
						</PageContainer>
					</>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { package_details } = state; 
    return {
		package_details
    };
}

const translation = withNamespaces(['default'])(Details);
const connected = connect(mapStateToProps)(translation);
export { connected as Details };