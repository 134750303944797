import { vendorVerificationConstants, userConstants } from '../_constants';

const vendorVerificationEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const vendorVerificationInitialState = {
	loading: false,
	success: false,
	data: undefined,
	error: undefined,
	edit: vendorVerificationEditInitialState
}

export function vendor_verification(state = vendorVerificationInitialState, action) {
	switch (action.type) {
		case vendorVerificationConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...vendorVerificationInitialState, edit: state.edit };
		case vendorVerificationConstants.GET_REQUEST:
			return { ...vendorVerificationInitialState, loading: true, edit: state.edit };
		case vendorVerificationConstants.GET_SUCCESS:
			return { ...vendorVerificationInitialState, success: true, data: action.responseData, edit: state.edit };
		case vendorVerificationConstants.GET_FAILURE:
			return { ...vendorVerificationInitialState, error: action.error, edit: state.edit };

		case vendorVerificationConstants.EDIT_CLEAR:
			return { ...state, edit: { ...vendorVerificationEditInitialState } };
		case vendorVerificationConstants.EDIT_REQUEST:
			return { ...state, edit: { ...vendorVerificationEditInitialState, loading: true } };
		case vendorVerificationConstants.EDIT_SUCCESS:
			return { ...state, edit: { ...vendorVerificationEditInitialState, success: true } };
		case vendorVerificationConstants.EDIT_FAILURE:
			return { ...state, edit: { ...vendorVerificationEditInitialState, error: action.error } };

		default:
			return state
	}
}