import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { userActions } from '../../../_actions';
import { InputField, OTPButton, OTPButtonType, InputSelect, Title, Desc, InputItem } from '../../../_components';

import './SendCode.scss';

export const SendCodeUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

export const SendCodeType = {
	forgotPassword: 'forgotPassword',
	editEmail: 'editEmail',
}

class SendCode extends Component {
	render() {
		const { t, type, userType, user_userType, title, desc, inputEmailPlaceholder, submitBtnTitle } = this.props;

		const schema = Yup.object().shape({
			user_type: Yup.number()
				.required(t('required')),
			email: Yup.string()
				.email(t('email_format_error'))
				.required(t('required'))
				.test('email', t('email_unique_error'), function (value) {
					if (type === SendCodeType.forgotPassword) return true;
					if (!value) return false;

					const { user_type } = this.parent;
		
					return userActions.checkEmailAPI(user_type, value)
						.then(response => {
							return true;
						})
						.catch(error => {
							return false;
						});
				}),
			email_code: Yup.string()
				.required(t('required')),
		});

		let OPTBtnType = OTPButtonType.resetPassword;

		switch (this.props.type) {
			case SendCodeType.forgotPassword:
				OPTBtnType = OTPButtonType.resetPassword;
				break;
			case SendCodeType.editEmail:
				OPTBtnType = OTPButtonType.editEmail;
				break;

			default:
				break;
		}

		return (
			<Formik
				enableReinitialize
				initialValues={{
					user_type: (type === SendCodeType.forgotPassword ? (userType === SendCodeUserType.vendor ? 0 : 1) : user_userType),
					email: '',
					email_code: ''
				}}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setFieldTouched
					/* and other goodies */
				}) => (
						<Form id="user_modal_sendcode" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{title}</Title>
							</ModalHeader>
							<ModalBody>
								<Desc>{desc}</Desc>
								{type === SendCodeType.forgotPassword && userType === SendCodeUserType.vendor &&
									<InputItem>
										<InputSelect
											name="user_type" t={t}
											options={[{ id: 2, value: t('vendor.photographer') }, { id: 3, value: t('vendor.agency') }]}
											isSearchable={false}
										/>
									</InputItem>
								}
								<InputItem>
									<InputField type="email" name="email" placeholder={inputEmailPlaceholder} t={t} />
								</InputItem>
								<InputItem>
									<Row className="row-m-8 send_email_code">
										<Col sm={{ size: true }}>
											<InputField type="text" name="email_code" t={t} />
										</Col>
										<Col sm={{ size: 'auto' }}>
											<OTPButton
												type={OPTBtnType}
												disabled={!values.email.length || errors.email != null}
												email={values.email}
												user_type={values.user_type}
											/>
										</Col>
									</Row>
									<div className="remarks">{t('email_code_remarks')}</div>
								</InputItem>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={this.props.submitting}
												onClick={handleSubmit}>
												{submitBtnTitle}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</Form>
					)}
			</Formik>
		);
	}
}

const translation = withNamespaces(['register', 'default'])(SendCode);
export { translation as SendCode };