import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../_actions';
import { userType } from '../../_constants';
import { Loading } from '../../_components';

import './Payments.scss';

import { Payment } from './Payment';

class Payments extends Component {
	componentDidMount() {
		const { auth, data } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		this.props.dispatch(orderActions.getPayments(isVendor, data.id));
	}

	render() {
		const { t, data, order } = this.props;

		return (
			<div id="order_payments">
				{order.payments.loading && <Loading />}
				{order.payments.error && <Loading error={order.payments.list.error} />}
				{order.payments.list && !order.payments.list.length && <div className="no-result">{t('no_payments')}</div>}
				{order.payments.list && order.payments.list.length > 0 &&
					order.payments.list.map((item, index) =>
						<Payment key={item.id} data={item} order={data} />
					)
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
	return {
		auth,
		order
	};
}

const translation = withNamespaces(['payment'])(Payments);
const connected = connect(mapStateToProps)(translation);
export { connected as Payments };