export const payoutConstants = {
	GET_LIST_REQUEST: 'PAYOUT_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'PAYOUT_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'PAYOUT_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'PAYOUT_GET_LIST_CLEAR',

	GET_REQUEST: 'PAYOUT_GET_REQUEST',
    GET_SUCCESS: 'PAYOUT_GET_SUCCESS',
	GET_FAILURE: 'PAYOUT_GET_FAILURE',
	GET_CLEAR: 'PAYOUT_GET_CLEAR',

	GET_RECEIPT_REQUEST: 'PAYOUT_GET_RECEIPT_REQUEST',
    GET_RECEIPT_SUCCESS: 'PAYOUT_GET_RECEIPT_SUCCESS',
	GET_RECEIPT_FAILURE: 'PAYOUT_GET_RECEIPT_FAILURE',
	GET_RECEIPT_CLEAR: 'PAYOUT_GET_RECEIPT_CLEAR',

	GET_PACKAGES_SHORT_INFO_REQUEST: 'PAYOUT_GET_PACKAGES_SHORT_INFO_REQUEST',
    GET_PACKAGES_SHORT_INFO_SUCCESS: 'PAYOUT_GET_PACKAGES_SHORT_INFO_SUCCESS',
	GET_PACKAGES_SHORT_INFO_FAILURE: 'PAYOUT_GET_PACKAGES_SHORT_INFO_FAILURE',
	GET_PACKAGES_SHORT_INFO_CLEAR: 'PAYOUT_GET_PACKAGES_SHORT_INFO_CLEAR',
}

export const payoutStatus = {
	pending: 1,
	ready_for_process: 2,
	processing: 3,
	success: 4,
	fail: 5
}

export function getPayoutStatusNameByStatus(status) {
	switch (status) {
		case payoutStatus.pending: return 'pending';
		case payoutStatus.ready_for_process: return 'ready_for_process';
		case payoutStatus.processing: return 'processing';
		case payoutStatus.success: return 'success';
		case payoutStatus.fail: return 'fail';
	
		default: return null;
	}
}

export const payoutType = {
	deposit: 1,
	remaining: 2,
}

export function getPayoutTypeNameByType(type) {
	switch (type) {
		case payoutType.deposit: return 'deposit';
		case payoutType.remaining: return 'remaining';
	
		default: return null;
	}
}