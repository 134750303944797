import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'reactstrap';

import { history } from '../../_helpers';

import './ErrorPage.scss';

import { PageContainer } from '../PageContainer';
import img_icon from '../Error/img/icon_404_page_not_found.svg';
import { Title } from '../Title';
import { Desc } from '../Desc';

class ErrorPage extends Component {
	onBack() {
		history.goBack();
	}

	render() {
		const { t, match, error, crash } = this.props;
		const isVendor = match? match.url.startsWith('/vendor') : false;
		const code = error? error.status_code : match? match.params.code : undefined;

		let title = crash? error.title : t([`error_page.${code}.title`, 'error_page.title'], { code: code });
		let desc = error ? error.message : undefined;

		return (
			<PageContainer id="error_page" className={crash? 'crash' : ''}>
				<Container>
					<div className="content">
						<Row>
							<Col md={{ size: 'auto' }} className="col-img">
								<img src={img_icon} alt="" />
							</Col>
							<Col md={{ size: true }} className="col-content">
								<div className="content-wrapper">
									<Title>
										{title}
									</Title>
									<Desc className="desc-error">
										{desc}
									</Desc>
									<Desc className="desc-actions">
										{t([`error_page.${code}.desc`, 'error_page.desc'], { code: code })}
									</Desc>
									<div className="actions">
										<Row className="row-m-8">
											<Col md={{ size: 'auto' }}>
												<Button block onClick={() => this.onBack()}>
													{t('back')}
												</Button>
											</Col>
											<Col md={{ size: 'auto' }}>
												<Link to={isVendor ? '/vendor' : '/'} className="btn btn-block btn-outline-primary">
													{t('visit_homepage')}
												</Link>
											</Col>
										</Row>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const translation = withNamespaces(['error'])(ErrorPage);
const connected = connect(mapStateToProps)(translation);
export { connected as ErrorPage };