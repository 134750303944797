import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';

import './Error.scss';

import img_icon from './img/icon_404_page_not_found.svg';
import { Title } from '../Title';
import { Desc } from '../Desc';

class Error extends Component {
	render() {
		const { t, error, app } = this.props
		let title = t([`error_page.${error.status_code}.title`, 'error_page.title'], { code: error.status_code });
		let desc = error ? error.message : t([`error_page.${error.status_code}.desc`, 'error_page.desc'], { code: error.status_code });

		const parentWidth = this.error_component? this.error_component.parentNode.clientWidth : undefined;
		const isVertical = parentWidth? (parentWidth < 400) : false;

		return (
			<div ref={(element) => this.error_component = element } id="error_component" className={`${isVertical? 'vertical' : ''}`}>
				<img src={img_icon} alt="" />
				<div className="error-content">
				<Title className="title-error">
					{title}
				</Title>
				<Desc className="desc-error">
					{desc}
				</Desc>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['error'])(Error);
const connected = connect(mapStateToProps)(translation);
export { connected as Error };