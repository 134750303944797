import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../_actions';
import { userType } from '../../_constants';
import { ActionModal } from '../../_components';

import './ReportModal.scss';

import { InputForm } from './InputForm';

class ReportModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.editReportClear());
	}

	onSubmit(values, formikBag) {
		const { auth, order_id } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		this.props.dispatch(orderActions.editReport(isVendor, order_id, values));
	}

	render() {
		const { t, isOpen, order } = this.props;

		return (
			<ActionModal
				id="order_report_modal"
				confirmComponent={
					<InputForm
						submitting={order.report.edit.loading}
						onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
						toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t('success.title')}
				successDesc={t('success.desc')}
				isLoading={order.report.edit.loading}
				isSuccess={order.report.edit.success}
				error={order.report.edit.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.editReportClear());
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { auth, order } = state;
	return {
		auth, order
	};
}

const translation = withNamespaces(['report', 'default'])(ReportModal);
const connected = connect(mapStateToProps)(translation);
export { connected as ReportModal };