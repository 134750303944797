import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Container, Row, Col } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactToPrint from "react-to-print";

import { orderActions } from '../../../_actions';
import { PageContainer, Title, RowCards, ColCards, ReceiptPrintable, LoadingPage, ErrorPage } from '../../../_components';

import './Receipt.scss';

import { PackageDetails } from '../../../Order';
import { Payment } from '../../../Order';
import { orderPaymentType, getOrderPaymentTypeNameByType, getOrderReceiptTypeNameByType } from '../../../_constants';

class Receipt extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.getClear());
		this.props.dispatch(orderActions.get(false, this.props.match.params.id));
		this.props.dispatch(orderActions.getReceipt(false, this.props.match.params.receipt_id));
	}

	onDownload() {
		// this.pdfExportComponent.save();

		const input = document.getElementById('receipt');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');

				const pdf = new jsPDF();

				var pageWidth = pdf.internal.pageSize.getWidth();
				var pageHeight = pdf.internal.pageSize.getHeight();

				var imgWidth = pageWidth;
				var imgHeight = canvas.height * imgWidth / canvas.width;

				var heightLeft = imgHeight;
				var position = 0;

				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;

				while(heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
					heightLeft -= pageHeight;
				}
				
				const { order } = this.props;
				pdf.save(`WeddingUno_Order#${order.receipt.data.order_id}_Receipt#${order.receipt.data.id}.pdf`);

			});
	}

	render() {
		const { t, order, match } = this.props;

		return (
			<>
				{(order.receipt.loading || order.loading) && <LoadingPage />}
				{(order.receipt.error || order.error) && <ErrorPage match={this.props.match} error={order.receipt.error? order.receipt.error : order.error} />}
				{order.receipt.id == match.params.receipt_id && order.receipt.data && order.data &&
					<PageContainer id="customer_order_receipt" >
						<Container>
							<Row className="row-title">
								<Col sm={{ size: true }}>
									<Title>{t(`type.${getOrderReceiptTypeNameByType(order.receipt.data.type)}`)}</Title>
								</Col>
								<Col sm={{ size: 'auto' }}>
									{/* <Button className="btn btn-download-receipt" onClick={() => this.onDownload()} disabled={!(order.receipt.id == match.params.receipt_id && order.receipt.data) || !(order.id == match.params.id && order.data)}>{t('download_receipt')}</Button> */}

									<ReactToPrint
										trigger={() => <Button className="btn btn-block btn-download-receipt" disabled={!order.receipt.data || !order.data}>{t('download_receipt')}</Button>}
										content={() => this.componentRef}
										pageStyle="@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
									/>
								</Col>
							</Row>
						</Container>

						<div id="receipt" className="receipt" ref={el => (this.componentRef = el)}>
							<ReceiptPrintable
								header={
									<>
										<Row>
											<Col sm={{ size: 3 }}>
												{t('order_number')}
											</Col>
											<Col sm={{ size: 9 }} className="receipt-header-value">
												{order.receipt.data.order_id}
											</Col>
										</Row>
										<Row>
											<Col sm={{ size: 3 }}>
												{t('last_updated_time')}
											</Col>
											<Col sm={{ size: 9 }} className="receipt-header-value">
												{Moment.utc(order.receipt.data.updated_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}
											</Col>
										</Row>
										<Row>
											<Col sm={{ size: 3 }}>
												{t('from')}
											</Col>
											<Col sm={{ size: 9 }} className="receipt-header-value">
												{t('weddinguno')}
											</Col>
										</Row>
										<Row>
											<Col sm={{ size: 3 }}>
												{t('to')}
											</Col>
											<Col sm={{ size: 9 }} className="receipt-header-value">
												{`${order.receipt.data.customer.nickname} (${order.receipt.data.customer.email})`}
											</Col>
										</Row>
									</>
								}
								body={
									<RowCards>
										<ColCards md={{ size: 6, order: 1 }} xs={{ order: 2 }}>
											<PackageDetails data={order.receipt.data.order_package} receipt={order.receipt.data} />
										</ColCards>
										<ColCards md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
											{order.receipt.data.order_payments.map((item, index) =>
													<Payment key={item.id} data={item} order={order.data} isReceipt={true} />
											)}
										</ColCards>
									</RowCards>
								}
							/>
						</div>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
    const { order } = state; 
    return {
		order
    };
}

const translation = withNamespaces(['receipt', 'payment', 'default'])(Receipt);
const connected = connect(mapStateToProps)(translation);
export { connected as Receipt };