import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../../_actions';
import { ActionModal } from '../../../_components';

import './CancelBookingModal.scss';

class CancelBookingModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.cancelClear());
	}
	
	render() {
		const { t, isOpen, order, order_id } = this.props;

		return (
			<ActionModal
				id="customer_order_cancelbooking_modal"
				confirmTitle={t('cancel_booking.confirm.title')}
				confirmDesc={t('cancel_booking.confirm.desc')}
				successTitle={t('cancel_booking.success.title')}
				successDesc={t('cancel_booking.success.desc')}
				isLoading={order.cancel.loading}
				isSuccess={order.cancel.success}
				error={order.cancel.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.cancelClear());
				}}
				toggle={() => this.props.toggle()}
				onSubmit={() => {
					this.props.dispatch(orderActions.cancel(false, order_id));
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order'])(CancelBookingModal);
const connected = connect(mapStateToProps)(translation);
export { connected as CancelBookingModal };