import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container } from 'reactstrap';

import { vendorPackageActions, alertActions } from '../../../_actions';
import { InfiniteScrollWrapper } from '../../../_components';

import './Drafts.scss';

import { Draft } from './Draft';

class Drafts extends Component {
	componentDidMount() {
		this.props.dispatch(vendorPackageActions.getListClear(true));
	}

	componentDidUpdate(prevProps, prevState) {
		const { vendor_package, t } = this.props;

		if (vendor_package.remove.success && prevProps.vendor_package.remove.success !== vendor_package.remove.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
		}
	}

	render() {
		const { t, vendor_drafts } = this.props;

		return (
			<div id="vendor_mypackages_drafts">
				<Container>
					<InfiniteScrollWrapper
						pageStart={1}
						loadMoreProp={vendor_drafts}
						onLoadMore={(nextPage) => {
							this.props.dispatch(vendorPackageActions.getList(true, nextPage));
						}}
						renderItems={(items) => {
							return (
								items.map((item, index) =>
									<Draft key={item.id} data={item} />
								)
							)
						}}
						noResultDesc={t('no_drafts')}
					/>
				</Container>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_drafts, vendor_package } = state;
	return {
		vendor_drafts,
		vendor_package
	};
}

const translation = withNamespaces(['mypackages'])(Drafts);
const connected = connect(mapStateToProps)(translation);
export { connected as Drafts };