import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';

import { history, auth as authHelper } from '../../_helpers';
import { vendorVerificationStatus } from '../../_constants';
import { userActions } from '../../_actions';

import './CreatePackageButton.scss';

import { VendorProfileIncompleteModal } from '../VendorProfileIncompleteModal';

class CreatePackageButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			vendorProfileIncompleteModalIsOpen: false,
			isCheckingVendorProfile: false
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { isCheckingVendorProfile } = this.state;
		const { get_user } = this.props;

		if (isCheckingVendorProfile && get_user.success && get_user.success !== prevProps.get_user.success) {
			this.setState({ isCheckingVendorProfile: false }, () => {
				let showIncompleteModal = get_user.data.verification_status !== vendorVerificationStatus.verified || !get_user.data.is_profile_completed || !get_user.data.is_payout_completed;
				if (showIncompleteModal) {
					this.setState({ vendorProfileIncompleteModalIsOpen: showIncompleteModal });
				} else {
					history.push('/vendor/package/create');
				}
			});
		}
	}
	
	onClick() {
		if (authHelper.isLoggedIn(true, this.props.auth.user)) {
			this.setState({ isCheckingVendorProfile: true }, () => {
				this.props.dispatch(userActions.getUser());
			});
		} else {
			history.push('/vendor/login');
		}
	}

	render() {
		const { t, className, get_user } = this.props;
		const { vendorProfileIncompleteModalIsOpen } = this.state;

		return (
			<>
				<Button id="vendor_btn_create_package" className={className} onClick={() => this.onClick()} >
					{t('vendor.create_package')}
				</Button>
				<VendorProfileIncompleteModal
				isOpen={vendorProfileIncompleteModalIsOpen}
				data={get_user.data ? get_user.data : null}
				toggle={() => { this.setState({ vendorProfileIncompleteModalIsOpen: !this.state.vendorProfileIncompleteModalIsOpen }) }}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth, get_user } = state;
	return {
		auth,
		get_user
	};
}

const translation = withNamespaces(['header'])(CreatePackageButton);
const connected = connect(mapStateToProps)(translation);
export { connected as CreatePackageButton };