
import React from "react";
import { Container, Row, Col } from 'reactstrap';

import './CardsContainer.scss';

export const CardsContainer = ({ className, children, ...props}) => {
	return <Container className={`cards-container ${className? className : ''}`} {...props}>{children}</Container>;
}

export const RowCards = ({ className, children, ...props}) => {
	return <Row className={`row-cards ${className? className : ''}`} {...props}>{children}</Row>;
}

export const ColCards = ({ className, children, ...props}) => {
	return <Col className={`col-cards ${className? className : ''}`} {...props}>{children}</Col>;
}