import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Title, Desc } from '../../_components';
import { StaticPageContainer } from '../StaticPageContainer';

import './Help.scss';

class Help extends Component {
	constructor(props) {
		super(props);

		let sections = require('./QnA.json');
		let tabIndex = this.tabIndexByMatch(props.match, sections);
		this.state = {
			sections,
			tabIndex: tabIndex,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		const { match } = props;
		const { sections } = this.state

		let tabIndex = this.tabIndexByMatch(match, sections);
		this.setState({ tabIndex: tabIndex });
	}

	tabIndexByMatch(match, sections) {
		let tabIndex = -1;
		sections.forEach((section, index) => {
			if (section.key === match.params.tab) {
				tabIndex = index;
			}
		});
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		const { sections } = this.state
		return `${match.url.replace(`/${match.params.tab }`, '')}/${sections[tabIndex]['key']}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	render() {
		const { t, match, app } = this.props;
		const { tabIndex } = this.state;

		let qna = require('./QnA.json');
		let lang = app.language.language.id;

		return (
			<StaticPageContainer id="help" match={match}>
				<Container>
					<Title className="title-page">
						{t('help_&_support')}
					</Title>
					<Tabs className="tabs-menu" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
						<Row>
							<Col md={{ size: 3 }}>
								<TabList className="react-tabs__tab-list tabs-menu__tab-list">
									{qna.map((section) =>
										<Tab key={section.key} className="react-tabs__tab tabs-menu__tab">{section.title[lang]}</Tab>
									)}
								</TabList>
							</Col>
							<Col md={{ size: 9 }}>
								{qna.map((section) => {
									let questions = [];
									section.qa.forEach((qa, index) => {
										questions.push(
											<div key={index} className="qa">
												<Title size="xs" className="question">
													{qa.question[lang]}
												</Title>
												<Desc className="answer">
													{qa.answer.desc &&
														<p>{qa.answer.desc[lang]}</p>
													}
													{qa.answer.points &&
														<ul>
															{qa.answer.points.map((item, index) =>
																<li key={index}>{item[lang]}</li>
															)}
														</ul>
													}
												</Desc>
											</div>
										);
									});
									
									return (
										<TabPanel key={section.key} className="react-tabs__tab-panel tabs-menu__tab-panel">
											{questions}
										</TabPanel>
									);
								})}
							</Col>
						</Row>
					</Tabs>
				</Container>
			</StaticPageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['help'])(Help);
const connected = connect(mapStateToProps)(translation);
export { connected as Help };