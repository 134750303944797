import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import Moment from 'moment';

import { ProfilePicImg, Desc, Title } from '../../../_components';
import {
	sbGetChannelInfo as sb_getChannelInfo,
	CONNECTION_STATUS_ONLINE,
} from '../../../_sendbird';

import './Channel.scss';

import img_image from '../../img/chat_icon_image.svg';
import img_file from '../../img/chat_icon_file.svg';
import img_admin from '../../img/chat_icon_reminder.svg';
import { utils } from '../../utils';
import { userType } from '../../../_constants';

class Channel extends Component {
	render() {
		const { t, channel, active, auth } = this.props;
		const channelInfo = sb_getChannelInfo(channel);

		const lastMessage = channel.lastMessage;
		let isFrom = undefined;
		let messageType = undefined;
		if (lastMessage) {
			isFrom = channel.lastMessage? channel.lastMessage.sender? channel.lastMessage.sender.userId == auth.user.id : true : undefined;
			messageType = utils.getMessageTypeWithMessage(channel.lastMessage);
		}

		let systemMessageData = undefined;
		if (messageType === 'system') {
			let isVendor = auth.user.user_type !== userType.customer;
			systemMessageData = utils.constructSystemMessageWithMessage({t, isVendor, message: channel.lastMessage });
		}

		const isOnline = channelInfo.connectionStatus === CONNECTION_STATUS_ONLINE;

		return (
			<div id={channel.url} className={`chat-channel ${active ? 'active' : ''}`} onClick={() => this.props.onClick(channel)}>
				<ProfilePicImg src={channelInfo.imageUrl} />
				<div className="channel-info">
					<div className="title-unread">
						<Title size="xs" className="name">
							{channelInfo.title}
						</Title>
						<div className="unread-count-wrapper">
							{channel.unreadMessageCount > 0 &&
								<Badge pill className="unread-count">{channel.unreadMessageCount > 999 ? '999+' : channel.unreadMessageCount}</Badge>
							}
						</div>
					</div>
					{lastMessage &&
						<Desc className={`last-message last-message-${messageType} ${channel.unreadMessageCount > 0 ? 'unread' : ''}`}>
							<div className="last-message-body">
								{messageType === 'image' &&
									<img className="icon-img" src={img_image} alt="" />
								}
								{messageType === 'file' &&
									<img className="icon-img" src={img_file} alt="" />
								}
								{messageType === 'admin' &&
									<img className="icon-img" src={img_admin} alt="" />
								}
								<Desc className="message">
									{(messageType === 'image' || messageType === 'file') && lastMessage.name}
									{messageType === 'user' && lastMessage.message}
									{(messageType === 'system') && systemMessageData.title}
								</Desc>
								{isFrom &&
									<img className={`message-status ${!Number.isNaN(lastMessage.updatedAt) ? 'sent' : ''} ${channel.getReadReceipt(lastMessage) === 0 ? 'read' : ''}`} src="" alt="" />
								}
							</div>
						</Desc>
					}
					{channel.typing && channel.typing.length > 0 ?
						<Desc className="typing">
							{channel.typing.map((sb_user) => sb_user.nickname).join(', ') + ' ' + (channel.typing.length > 1 ? t('are_typing') : t('is_typing'))}
						</Desc>
						:
						channelInfo.lastSeenAt !== -1 ?
							<Desc className={`online-status ${isOnline ? 'online' : ''}`}>
								{isOnline ?
									t('online')
									:
									channelInfo.lastSeenAt > 0 ? t('online_n_ago', { n: Moment(channelInfo.lastSeenAt).fromNow(true) }) : null
								}
							</Desc>
							:
							null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth,
	};
}

const connected = connect(mapStateToProps)(Channel);
export { connected as Channel };