import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title } from '../../Title';
import { Desc } from '../../Desc';

import './Success.scss';

export const Success = ({ title, desc, toggle, btnTitle, className }) => (
	<div id="action_modal_success" className="modal-content-container">
		<ModalHeader toggle={() => toggle()}>
			<Title>{title}</Title>
		</ModalHeader>
		<ModalBody>
			<Desc>{desc}</Desc>
		</ModalBody>
		<ModalFooter>
			<Button outline onClick={() => toggle()}>{btnTitle}</Button>
		</ModalFooter>
	</div>
);