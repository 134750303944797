import { 
    INIT_BLOCK_USER,
    BLOCK_LIST_SUCCESS,
    BLOCK_LIST_FAIL,
    USER_UNBLOCK_SUCCESS,
	USER_UNBLOCK_FAIL,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    list: [],
    unblockedUserId: ''
}

export function blockUser (state = INITIAL_STATE, action) {
    switch(action.type) {
        case INIT_BLOCK_USER: 
            return { ...state, ...INITIAL_STATE };
        case BLOCK_LIST_SUCCESS:
            return { ...state, list: action.list };
        case BLOCK_LIST_FAIL:
            return { ...state, list: [] };
        case USER_UNBLOCK_SUCCESS:
            return { ...state, unblockedUserId: action.unblockedUserId }
        case USER_UNBLOCK_FAIL:
			return { ...state, unblockedUserId: '' }
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
				return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}
