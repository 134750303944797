import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col, CardDeck, Badge } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { orderActions } from '../../_actions';
import { getOrderTypeByOrderStatus, orderType, getOrderStatusNameByData, canCancelOrderByData, canCompleteOrderByData, canReviewOrderByData } from '../../_constants';
import { PageContainer, Title, LoadingPage, ErrorPage, Loading } from '../../_components';

import './Order.scss';

import { ShootingDatetime, Vendor, PendingItems, StatusInfoModal } from '../../Order';
import { CancelBookingModal } from './CancelBookingModal';
import { Details } from './Details';
import { Meetup } from './Meetup';
import { AfterShootingMaterials } from './AfterShootingMaterials';
import { Receipts } from './Receipts';
import { CompleteBookingModal } from './CompleteBookingModal';

import img_info from './img/order_details_status_icon_question.svg';
import { ReviewModal } from './ReviewModal';

const OrderTab = {
	order_details: 0,
	meetup_details: 1,
	after_shooting: 2,
	receipts: 3,
	pending_items: 4
}

const OrderTabPathnames = ['details', 'meet_up', 'after_shooting', 'receipts', 'pending_items'];

class Order extends Component {
	constructor(props) {
		super(props);

		const { match } = props;
		let tabIndex = this.tabIndexByMatch(match);

		this.state = {
			cancelBookingModalIsOpen: false,
			tabIndex: tabIndex,
			completeBookingModalIsOpen: false,
			reviewModalIsOpen: false,
			statusInfoModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.get(false, this.props.match.params.id));
		this.props.dispatch(orderActions.getPendingItemsCount(false, this.props.match.params.id));
	}

	componentDidUpdate(prevProps, prevState) {
		let shouldReloadOrder = false;
		let shouldReloadPendingItemsCount = false;
		let shouldReloadOrderReview = false;
		let shouldReloadOrderPayments = false;

		const { match, order, location } = this.props;

		if (order.cancel.success && prevProps.order.cancel.success !== order.cancel.success) {
			shouldReloadOrder = true;
			shouldReloadPendingItemsCount = true;
			shouldReloadOrderPayments = true;
		}

		if (order.edit_datetime.success && prevProps.order.edit_datetime.success !== order.edit_datetime.success) {
			shouldReloadPendingItemsCount = true;
		}

		if (order.pending_item.reply.success && prevProps.order.pending_item.reply.success !== order.pending_item.reply.success) {
			shouldReloadPendingItemsCount = true;

			if (order.pending_item.reply.accept) {
				shouldReloadOrder = true;
			}
		}

		if (order.pending_item.cancel.success && prevProps.order.pending_item.cancel.success !== order.pending_item.cancel.success) {
			shouldReloadPendingItemsCount = true;
		}

		if (order.complete.success && prevProps.order.complete.success !== order.complete.success) {
			shouldReloadOrder = true;
		}

		if (order.success && prevProps.order.success !== order.success) {
			shouldReloadOrderReview = canReviewOrderByData(order.data);
			this.setState({ data: order.data });
		}

		if (order.review.edit.success && prevProps.order.review.edit.success !== order.review.edit.success) {
			shouldReloadOrderReview = true;
		}

		//reload order if errors
		if (order.cancel.error && prevProps.order.cancel.error !== order.cancel.error) {
			shouldReloadOrder = true;
		}
		if (order.payments.error && prevProps.order.payments.error !== order.payments.error) {
			shouldReloadOrder = true;
		}
		if (order.payment.error && prevProps.order.payment.error !== order.payment.error) {
			shouldReloadOrder = true;
		}
		if (order.payment.process.error && prevProps.order.payment.process.error !== order.payment.process.error) {
			shouldReloadOrder = true;
		}
		if (order.receipts.error && prevProps.order.receipts.error !== order.receipts.error) {
			shouldReloadOrder = true;
		}
		if (order.receipt.error && prevProps.order.receipt.error !== order.receipt.error) {
			shouldReloadOrder = true;
		}
		if (order.edit_datetime.error && prevProps.order.edit_datetime.error !== order.edit_datetime.error) {
			shouldReloadOrder = true;
		}
		if (order.edit_datetime_history.error && prevProps.order.edit_datetime_history.error !== order.edit_datetime_history.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_items.error && prevProps.order.pending_items.error !== order.pending_items.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_item.reply.error && prevProps.order.pending_item.reply.error !== order.pending_item.reply.error) {
			shouldReloadOrder = true;
		}
		if (order.pending_item.cancel.error && prevProps.order.pending_item.cancel.error !== order.pending_item.cancel.error) {
			shouldReloadOrder = true;
		}
		if (order.meet_up.error && prevProps.order.meet_up.error !== order.meet_up.error) {
			shouldReloadOrder = true;
		}
		if (order.materials.error && prevProps.order.materials.error !== order.materials.error) {
			shouldReloadOrder = true;
		}
		if (order.review.error && prevProps.order.review.error !== order.review.error) {
			shouldReloadOrder = true;
		}
		if (order.review.edit.error && prevProps.order.review.edit.error !== order.review.edit.error) {
			shouldReloadOrder = true;
		}
		if (order.report.error && prevProps.order.report.error !== order.report.error) {
			shouldReloadOrder = true;
		}
		if (order.complete.error && prevProps.order.complete.error !== order.complete.error) {
			shouldReloadOrder = true;
		}


		if (shouldReloadOrder && !order.loading) {
			this.props.dispatch(orderActions.get(false, match.params.id));
		}

		if (shouldReloadPendingItemsCount) {
			this.props.dispatch(orderActions.getPendingItemsCount(false, this.props.match.params.id));
		}

		if (shouldReloadOrderReview && !order.review.loading) {
			this.props.dispatch(orderActions.getReview(false, this.props.match.params.id));
		}

		if (shouldReloadOrderPayments && !order.payments.loading) {
			this.props.dispatch(orderActions.getPayments(false, this.props.match.params.id));
		}

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		const { match } = props;

		let tabIndex = this.tabIndexByMatch(match);
		this.setState({ tabIndex: tabIndex });
	}

	tabIndexByMatch(match) {
		let tabIndex = OrderTabPathnames.indexOf(match.params.tab);
		return tabIndex === -1? 0 : tabIndex;
	}

	pathnameByTabIndex(tabIndex) {
		const { match } = this.props;
		return `${match.url.replace(`/${match.params.tab }`, '')}/${OrderTabPathnames[tabIndex]}`;
	}

	onSelectTab(tabIndex) {
		this.setState({ tabIndex: tabIndex });
		const { history } = this.props;
		history.push(this.pathnameByTabIndex(tabIndex));
	}

	onStatusInfo() {
		this.setState({ statusInfoModalIsOpen: true });
	}

	onCancel() {
		this.setState({ cancelBookingModalIsOpen: true });
	}

	onComplete() {
		this.setState({ completeBookingModalIsOpen: true });
	}

	onReview() {
		const { order } = this.props;
		if (order.data && canReviewOrderByData(order.data)) {
			this.setState({ reviewModalIsOpen: true });
		}
	}

	render() {
		const { t, match, order } = this.props;
		const { cancelBookingModalIsOpen, tabIndex, completeBookingModalIsOpen, reviewModalIsOpen, data, statusInfoModalIsOpen } = this.state;

		const isDeal = (order.data && getOrderTypeByOrderStatus(order.data.status) === orderType.deal);

		return (
			<>
				{!data && order.loading && <LoadingPage />}
				{order.error && <ErrorPage match={this.props.match} error={order.error} />}
				{!order.error && data &&
					<PageContainer id="customer_order" fullPage>
						<div className={`header ${isDeal ? 'deal' : 'order'} ${getOrderStatusNameByData(data)}`}>
							<Container>
								<Title className="package-name">
									{data.package_name}
								</Title>
								<div className="order-no">
									{`${t('order_number')}${data.id}`}
								</div>
								<Row className="order-info">
									<Col lg={{ size: 6 }}>
										<div className="flex-content order-status">
											<div className="status">
												{t(`status.${getOrderStatusNameByData(data)}`)}
												<Button color="img" className="btn-info" onClick={() => this.onStatusInfo()}><img src={img_info} alt={``} /></Button>
											</div>
											<div className="status-desc">
												{t(`status_desc.${getOrderStatusNameByData(data)}`)}
											</div>
											<div className="btns">
												<Row className="row-m-8">
													{canCompleteOrderByData(data) &&
														<Col md={{ size: true }}>
															<Button size="sm" className="btn-complete" onClick={() => this.onComplete()}>{t('complete_order.title')}</Button>
														</Col>
													}
													{canCancelOrderByData(data) &&
														<Col md={{ size: true }}>
															<Button size="sm" outline color="white" onClick={() => this.onCancel()}>{t('cancel_booking.title')}</Button>
														</Col>
													}
													{canReviewOrderByData(data) && order.review.success &&
													<Col md={{ size: true }}>
														<Button size="sm" className="btn-review" onClick={() => this.onReview()}>{t(order.review.data ? 'edit_review' : 'leave_review')}</Button>
													</Col>
													}
												</Row>
											</div>
										</div>
									</Col>
									<Col lg={{ size: 6 }}>
										<CardDeck className="card-deck-order-info">
											<Vendor
												data={data}
											/>
											<ShootingDatetime
												match={match}
												data={data}
											/>
										</CardDeck>
									</Col>
								</Row>
							</Container>
						</div>
						<Container>
						<Tabs className="tabs" selectedIndex={tabIndex} onSelect={(tabIndex) => this.onSelectTab(tabIndex)}>
							<TabList>
								<Tab className={`react-tabs__tab`}>{t('order_details')}</Tab>
								<Tab className={`react-tabs__tab ${isDeal? 'd-none' : ''}`}>{t('meetup_details.title')}</Tab>
								<Tab className={`react-tabs__tab ${isDeal? 'd-none' : ''}`}>{t('after_shooting_materials')}</Tab>
								<Tab className={`react-tabs__tab ${isDeal? 'd-none' : ''}`}>{t('receipts')}</Tab>
								<Tab className={`react-tabs__tab react-tabs__tab-pending-items`}>
									<div className="tab-pending-items">
										{t('pending_items')}
										{order.pending_items.pending_total > 0 && <Badge pill>{order.pending_items.pending_total > 1000 ? '999+' : order.pending_items.pending_total}</Badge>}
									</div>
								</Tab>
							</TabList>
							<TabPanel className={`react-tabs__tab-panel`}>
								<Details data={data} />
							</TabPanel>
							<TabPanel className={`react-tabs__tab-panel ${isDeal? 'd-none' : ''}`}><Meetup data={data} /></TabPanel>
							<TabPanel className={`react-tabs__tab-panel ${isDeal? 'd-none' : ''}`}><AfterShootingMaterials data={data} /></TabPanel>
							<TabPanel className={`react-tabs__tab-panel ${isDeal? 'd-none' : ''}`}><Receipts data={data} /></TabPanel>
							<TabPanel className={`react-tabs__tab-panel`}><PendingItems data={data} /></TabPanel>
						</Tabs>
						</Container>
						</PageContainer>
				}
				{data && order.loading && <Loading overlay />}
				<CancelBookingModal
					order_id={data? data.id : undefined}
					isOpen={cancelBookingModalIsOpen}
					toggle={() => this.setState({ cancelBookingModalIsOpen: !this.state.cancelBookingModalIsOpen })}
				/>
				<CompleteBookingModal
					isOpen={completeBookingModalIsOpen}
					order_id={data? data.id : undefined}
					toggle={() => this.setState({ completeBookingModalIsOpen: !this.state.completeBookingModalIsOpen })}
				/>
				<ReviewModal
					order_id={data? data.id : undefined}
					data={order.review.data}
					isOpen={reviewModalIsOpen}
					toggle={() => this.setState({ reviewModalIsOpen: !this.state.reviewModalIsOpen })}
				/>
				<StatusInfoModal
					isOpen={statusInfoModalIsOpen}
					toggle={() => this.setState({ statusInfoModalIsOpen: !this.state.statusInfoModalIsOpen })}
					status={data? data.status : undefined}
					isExpired={data? data.is_expired : undefined}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order', 'meetup', 'review', 'default'])(Order);
const connected = connect(mapStateToProps)(translation);
export { connected as Order };