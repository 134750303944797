import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import LinesEllipsis from 'react-lines-ellipsis';

import { savedItemsActions } from '../../../_actions';
import { history, auth as authHelper, app } from '../../../_helpers';
import { savedItemType, packageStatus } from '../../../_constants';
import { GeneralGridItemContent } from '../../../_components';

import img_category from './img/details_icon_basic_category.svg';
import img_type from './img/details_icon_basic_type.svg';
import img_location from './img/details_icon_basic_location.svg';

import './GridItem.scss';

class GridItem extends Component {
	onBookmark(item) {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			if (item.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsPackage(item.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsPackage(item.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	onClick(item) {
		history.push(`/package/${item.id}`);
	}

	render() {
		const { t, item, saved_item_action, hideBookmark } = this.props;

		const isLoading = saved_item_action.loading && saved_item_action.type == savedItemType.PACKAGE && saved_item_action.id == item.id;
		const is_disabled = false;//item.status !== packageStatus.published;

		return (
			<GeneralGridItemContent className={`grid-item-content-package-special`}
				isLoading={isLoading}
				isDisabled={is_disabled}
				disabledTitle={t('unavailable_package')}
				hideBookmark={hideBookmark}
				isBookmarked={item.is_bookmarked}
				onBookmark={() => this.onBookmark(item)}
				onClick={() => this.onClick(item)}
			>
				<img className="cover" src={item.video_image_url} alt="" />
				<div className="details">
					<LinesEllipsis
						className="title-sm name"
						text={item.name}
						maxLine='2'
						ellipsis='...'
						trimRight
						basedOn='words'
					/>
					<div className="grid-item-by">
						{t('by')} <Link to={`/package/vendor/${item.vendor.id}`} onClick={(e) => e.stopPropagation()}>{item.vendor.nickname}</Link>
						<span className="grid-item-price">{app.toCurrencyString(item.user_currency.value, item.user_currency_amount)}</span>
					</div>
					<div className="properties">
						<Row>
							<Col sm={{ size: 'auto' }}>
								<img src={img_category} alt={t('category')} />
								<span className="destination-category">
									<span>{item.destination.value}</span>
									<span>{item.category.value}</span>
								</span>
							</Col>
							<Col sm={{ size: 'auto' }}>
								<img src={img_type} alt={t('style')} />
								<span>{item.package_type.value}</span>
							</Col>
						</Row>
						<div className="no-of-locations">
							<img src={img_location} alt={t('locations')} />
							<span>
							{`${t('n_locations', { n: item.locations.length })}: ${item.locations.map((location) => location.name).join(' / ')}`}
							</span>
						</div>
					</div>
					<div className="locations">
						{
							item.locations.map((location, index) =>
								<Link key={location.id} to={`/package/${item.id}/portfolio/${location.id}`} onClick={(e) => e.stopPropagation()}>
									<img src={location.cover_image_url} alt="" />
								</Link>
							)
						}
					</div>
				</div>
			</GeneralGridItemContent>
		);
	}
}

function mapStateToProps(state) {
    const { auth, saved_item_action } = state; 
    return {
		auth,
		saved_item_action
    };
}

const translation = withNamespaces(['package', 'default'])(GridItem);
const connected = connect(mapStateToProps)(translation);
export { connected as GridItem };
