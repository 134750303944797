import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Button, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';

import { orderStatus, canEditOrderShootingDatetimeByData, userType, getOrderTypeByOrderStatus, orderType } from '../../../_constants';
import { Desc, RowStroked } from '../../../_components';

import '../Card.scss';
import './ShootingDatetime.scss';

import { EditModal } from './EditModal';

class ShootingDatetime extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editModalIsOpen: false
		}
	}

	onEdit() {
		this.setState({ editModalIsOpen: true });
	}

	render() {
		const { t, match, data, auth } = this.props;
		const { editModalIsOpen } = this.state;

		const isVendor = auth.user.user_type !== userType.customer;
		const isDeal = getOrderTypeByOrderStatus(data.status) === orderType.deal;
		const canEdit = isVendor || !isDeal;
		const days_to_go = Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).diff(Moment(), 'days');
		const historyUrl = `${match.url.replace(`/${match.params.tab }`, '')}/edit_shooting_datetime_history`;

		return (
			<Card id="order_card_shooting_datetime" className="order-info-card">
				<CardHeader>
					<CardTitle tag='div'>
						{t(isVendor || data.is_shoot_datetime_updated? 'shoot_datetime' : 'shooting_date')}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<div className="datetime-wrapper">
					<div className="datetime">
						{data.is_shoot_datetime_updated?
							`${Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).format('D MMM YYYY h:mma')} ${data.timezone.offset}`
							:
							`${Moment.utc(data.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(data.timezone.offset).format('D MMM YYYY')} ${data.timezone.offset}`
						}
					</div>
					{(data.status === orderStatus.full_paid || data.status === orderStatus.offered || data.status === orderStatus.requesting) && !data.is_expired && days_to_go > 0 &&
						<Desc>
							{t('n_days_to_go', { n: days_to_go })}
						</Desc>
					}
					</div>
				</CardBody>
				<CardFooter>
					{canEdit &&
						<RowStroked persist>
							<Col xs={{ size: 'auto' }}>
								<Button color="link" onClick={() => this.onEdit()} disabled={!canEditOrderShootingDatetimeByData(data)}>{t(isVendor && !data.is_shoot_datetime_updated? 'edit_add_shooting_time' : 'edit')}</Button>
							</Col>
							{!isDeal &&
								<Col xs={{ size: 'auto' }}>
									<Link to={historyUrl}>{t('edit_history')}</Link>
								</Col>
							}
						</RowStroked>
					}
				</CardFooter>
				{canEdit &&
					<EditModal
					data={data}
					isOpen={editModalIsOpen}
					toggle={() => this.setState({ editModalIsOpen: !this.state.editModalIsOpen })}
					/>
				}
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['order', 'package', 'default'])(ShootingDatetime);
const connected = connect(mapStateToProps)(translation);
export { connected as ShootingDatetime };