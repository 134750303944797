import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { Payout } from './Payout';
import { Receipt } from './Receipt';

export class PayoutRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}`} component={Payout} />
				<PrivateRoute exact path={`${match.path}/receipt`} component={Receipt} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}