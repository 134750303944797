import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { history } from '../../_helpers';

import './Footer.scss';

import { Customer } from './Customer';
import { Vendor } from './Vendor';
import { appActions } from '../../_actions';

class Footer extends Component {
	constructor(props) {
		super(props);
		const { location } = history;

		history.listen((location, action) => {
			let isVendor = location.pathname.startsWith('/vendor');
			if (this.state.isVendor !== isVendor) {
				this.setState({ isVendor });
			}
		});

		this.state = {
			isVendor: location.pathname.startsWith('/vendor')
		}
	}

	componentDidMount() {
		this.props.dispatch(appActions.getLanguages());
	}

	onSelectLanguage(isVendor, item) {
		this.props.dispatch(appActions.setLanguage(isVendor, item));
		// this.props.i18n.changeLanguage(item.i18n_value);

		window.location.reload();
	}

    render() {
		const { app } = this.props;

		const { isVendor } = this.state;

		return (
			<div id="footer" className={app.showFooter? '' : 'd-none'}>
				{app.language.list ?
					isVendor ?
						<Vendor languages={app.language.list} onSelectLanguage={(item) => this.onSelectLanguage(true, item)} selectedLanguage={app.language.language} />
						:
						<Customer languages={app.language.list} onSelectLanguage={(item) => this.onSelectLanguage(false, item)} selectedLanguage={app.language.language} />
					:
					null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { app } = state;
	return {
		app
	};
}

const translation = withNamespaces(['default'])(Footer);
const connected = connect(mapStateToProps)(translation);
export { connected as Footer };