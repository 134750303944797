import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Container, Row, Col } from 'reactstrap';
import { withNamespaces } from 'react-i18next';

import './Vendor.scss';
// import img_placeholder from './img/FFFFFF-0.png';
import social_network_img_fb from './img/footer_icon_fb.svg';
import social_network_img_ig from './img/footer_icon_instagram.svg';
import social_network_img_w7 from './img/footer_icon_wechat.svg';
import social_network_img_wb from './img/footer_icon_weibo.svg';
import { Title } from '../../../_components';

class Vendor extends Component {
    render() {
		const { t, languages, selectedLanguage, onSelectLanguage } = this.props;

		return (
			<div id="vendor_footer" className="footer-wrapper">
				<Container className="footer-content">
					<Row>
						<Col xl={{ size: 'auto' }}>
							<Title>
								<Link to="/vendor/about_us">{t('weddinguno.about_us')}</Link>
							</Title>
						</Col>
						<Col xl={{ size: 'auto' }}>
							<Title>
								<Link to="/vendor/contact_us">{t('weddinguno.contact_us')}</Link>
							</Title>
						</Col>
						<Col xl={{ size: 'auto' }}>
							<Title>
								<Link to="/vendor/help">{t('weddinguno.help_&_support')}</Link>
							</Title>
						</Col>
						<Col xl={{ size: 'auto' }}>
							<Title>
								<Link to="/vendor/tnc">{t('weddinguno.terms_&_conditions')}</Link>
							</Title>
						</Col>
						<Col xl={{ size: true }}>
							<Title>
								<Link to="/vendor/policy">{t('weddinguno.privacy_policy')}</Link>
							</Title>
						</Col>
						<Col xl={{ size: 'auto' }} className="settings">
							<UncontrolledButtonDropdown>
								<DropdownToggle caret className="btn-outline-primary">
									{t('regional_settings.language.title')} : {selectedLanguage ? selectedLanguage.value : 'Select Language'}
								</DropdownToggle>
								<DropdownMenu>
									{languages.map((item, index) =>
										<DropdownItem key={item.id} onClick={() => onSelectLanguage(item)}>{item.value}</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</Col>
						{/* TODO: our social media links */}
						<Col xl={{ size: 'auto' }} className="social-medias">
							<Link to="/" title={t('social_media.Facebook')}><img src={social_network_img_fb} alt={t('social_media.Facebook')} /></Link>
							<Link to="/" title={t('social_media.Instagram')}><img src={social_network_img_ig} alt={t('social_media.Instagram')} /></Link>
							<Link to="/" title={t('social_media.WeChat')}><img src={social_network_img_w7} alt={t('social_media.WeChat')} /></Link>
							<Link to="/" title={t('social_media.Weibo')}><img src={social_network_img_wb} alt={t('social_media.Weibo')} /></Link>
						</Col>
					</Row>
				</Container>
				<hr />
				<Container className="footer-content">
					<Row>
						<Col xl={{ size: true }}>
							<Title className="copyright">{t('copyright')}</Title>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const translation = withNamespaces(['footer'])(Vendor);
export { translation as Vendor };