import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Title, Desc } from '../../../../../_components';

import './Success.scss';

class Success extends Component {
	render() {
		const { t } = this.props;

		return (
			<div id="package_details_makebooking_modal_success" className="modal-content-container">
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>{t('booking_success.title')}</Title>
				</ModalHeader>
				<ModalBody>
					<Desc>{t('booking_success.desc')}</Desc>
				</ModalBody>
				<ModalFooter>
					<Container className="container-p-8">
						<Row className="ow-m-8">
							<Col sm={{ size: true }}>
								<Link className='btn btn-block btn-primary' to={`/customer/me/deals`}>{t('view_my_deal_list')}</Link>
							</Col>
							<Col sm={{ size: true }}>
								<Button block outline onClick={() => this.props.toggle()}>{t('continue_explore')}</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</div>
		);
	}
}

const translation = withNamespaces(['package'])(Success);
export { translation as Success };
