import React, { Component } from 'react';
import { connect, getIn } from 'formik';
import { Button } from 'reactstrap';

import './InputBtnCheckbox.scss';

import img_placeholder from '../../_common/img/FFFFFF-0.png';

class InputBtnCheckbox extends Component {
	render() {
		const { name, t, className, disabled, formik, onClick } = this.props;
		const { values, touched, errors, setFieldTouched, setFieldValue } = formik;

		return(
			<Button 
			className={`btn-checkbox ${getIn(values, name) ? 'selected' : ''} ${className? className : ''}`} 
			disabled={disabled}
			onClick={() => {
				let result = !getIn(values, name);
				setFieldTouched(name, true);
				setFieldValue(name, result);

				if (onClick) onClick(name, result);
			}}>
				<img src={img_placeholder} alt={t(name)} />
			</Button>
		);
	}
}

const connected = connect(InputBtnCheckbox);
export { connected as InputBtnCheckbox };