import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { userActions } from '../../../_actions';
import { userType } from '../../../_constants';
import { ActionModal } from '../../../_components';
import { InputForm } from './InputForm';

import './EditPassword.scss';

export const EditPasswordUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class EditPassword extends Component {
	onSubmit(values, { setSubmitting }) {
		values.user_type = this.props.auth.user.user_type;
		this.props.dispatch(userActions.editPassword(values));
	}

	render() {
		const { t, edit_password, isOpen } = this.props;

		return (
			<ActionModal
				id="user_myprofile_edit_pw_modal"
				confirmComponent={
					<InputForm
						onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
						toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t('change_password.success.title')}
				successDesc={t('change_password.success.desc')}
				isLoading={edit_password.loading}
				isSuccess={edit_password.success}
				isOpen={isOpen}
				onClosed={() => {
					const { edit_password } = this.props;
					if (edit_password.success) {
						this.props.dispatch(userActions.forceLogout(true, this.props.auth.user.user_type === userType.customer ? '/customer/login' : '/vendor/login'));
						this.props.dispatch(userActions.editPasswordClear());
					}
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { auth, edit_password } = state;
	return {
		auth,
		edit_password
	};
}

const translation = withNamespaces(['editpassword', 'default'])(EditPassword);
const connected = connect(mapStateToProps)(translation);
export { connected as EditPassword };