export const app = {
	getLanguage,
	getCurrency,
	isMobile,
	toCurrencyString
};

function getLanguage() {
	return JSON.parse(localStorage.getItem('language'));
}

function getCurrency() {
	return JSON.parse(localStorage.getItem('currency'));
}

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function toCurrencyString(currency, value) {
	const currencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency });
	return currencyFormatter.format(value);
}
