import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, contactUsConstants } from '../_constants';

export const contactUsActions = {
	contactUs
};

function contactUs(data) {
	analytics.event({
		category: 'Contact Us',
		action: 'Submit',
		label: 'Customer'
	});
	
	const requestOptions = api.requestOption(apiEndpoint.contact_us, data);

    return dispatch => {
        dispatch(clear());
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function clear() { return { type: contactUsConstants.CLEAR } }
    function request() { return { type: contactUsConstants.REQUEST } }
    function success(responseData) { return { type: contactUsConstants.SUCCESS, responseData } }
    function failure(error) { return { type: contactUsConstants.FAILURE, error } }
}