import { api, app } from '../_helpers';
import axios from 'axios';
import { apiEndpoint, vendorProfileConstants } from '../_constants';

export const vendorProfileActions = {
	getCalendar,
	getCalendarClear,
	get,
	getClear,
	getReviews,
	getReviewsClear,
	getPackages,
	getPackagesClear
}

function getCalendar(id, start_date, end_date, order_id) {
	var endpoint = Object.assign({}, order_id? apiEndpoint.vendor_profile.calendar_order : apiEndpoint.vendor_profile.calendar); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint, { start_date, end_date, order_id });

    return dispatch => {
        dispatch(request(id, order_id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id, order_id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id, order_id));
			});
    };

    function request(id, order_id) { return { type: vendorProfileConstants.GET_CALENDAR_REQUEST, id, order_id } }
    function success(responseData, id, order_id) { return { type: vendorProfileConstants.GET_CALENDAR_SUCCESS, responseData, id, order_id } }
    function failure(error, id, order_id) { return { type: vendorProfileConstants.GET_CALENDAR_FAILURE, error, id, order_id } }
}

function getCalendarClear() {
	return { type: vendorProfileConstants.GET_CALENDAR_CLEAR }
}

function get(id) {
	var endpoint = Object.assign({}, apiEndpoint.vendor_profile.details); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);
	const requestOptions = api.requestOption(endpoint);

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: vendorProfileConstants.GET_DETAILS_REQUEST, id } }
    function success(responseData, id) { return { type: vendorProfileConstants.GET_DETAILS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: vendorProfileConstants.GET_DETAILS_FAILURE, error, id } }
}

function getClear() {
	return { type: vendorProfileConstants.GET_CALENDAR_CLEAR }
}

function getReviews(id, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.vendor_profile.reviews); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { page_number, per_page });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: vendorProfileConstants.GET_REVIEWS_REQUEST, id } }
    function success(responseData, id) { return { type: vendorProfileConstants.GET_REVIEWS_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: vendorProfileConstants.GET_REVIEWS_FAILURE, error, id } }
}

function getReviewsClear() {
	return { type: vendorProfileConstants.GET_REVIEWS_CLEAR }
}

function getPackages(id, page_number) {
	var endpoint = Object.assign({}, apiEndpoint.vendor_profile.packages); //make a copy to avoid modifying the const
	endpoint.path = endpoint.path.replace(':id', id);

	const per_page = 10;
	const requestOptions = api.requestOption(endpoint, { currency_id: app.getCurrency().id, per_page, page_number });

    return dispatch => {
        dispatch(request(id));

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data, id));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true), id));
			});
    };

    function request(id) { return { type: vendorProfileConstants.GET_PACKAGES_REQUEST, id } }
    function success(responseData, id) { return { type: vendorProfileConstants.GET_PACKAGES_SUCCESS, responseData, id } }
    function failure(error, id) { return { type: vendorProfileConstants.GET_PACKAGES_FAILURE, error, id } }
}

function getPackagesClear() {
	return { type: vendorProfileConstants.GET_PACKAGES_CLEAR }
}