export const orderConstants = {
	SEND_REQUEST_REQUEST: 'ORDER_SEND_REQUEST_REQUEST',
	SEND_REQUEST_SUCCESS: 'ORDER_SEND_REQUEST_SUCCESS',
	SEND_REQUEST_FAILURE: 'ORDER_SEND_REQUEST_FAILURE',
	SEND_REQUEST_CLEAR: 'ORDER_SEND_REQUEST_CLEAR',

	EDIT_REQUEST: 'ORDER_EDIT_REQUEST',
    EDIT_SUCCESS: 'ORDER_EDIT_SUCCESS',
	EDIT_FAILURE: 'ORDER_EDIT_FAILURE',
	EDIT_CLEAR: 'ORDER_EDIT_CLEAR',

	MAKE_OFFER_REQUEST: 'ORDER_MAKE_OFFER_REQUEST',
    MAKE_OFFER_SUCCESS: 'ORDER_MAKE_OFFER_SUCCESS',
	MAKE_OFFER_FAILURE: 'ORDER_MAKE_OFFER_FAILURE',
	MAKE_OFFER_CLEAR: 'ORDER_MAKE_OFFER_CLEAR',

	GET_LIST_REQUEST: 'ORDER_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'ORDER_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'ORDER_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'ORDER_GET_LIST_CLEAR',

	GET_REQUEST: 'ORDER_GET_REQUEST',
    GET_SUCCESS: 'ORDER_GET_SUCCESS',
	GET_FAILURE: 'ORDER_GET_FAILURE',
	GET_CLEAR: 'ORDER_GET_CLEAR',

	CANCEL_REQUEST: 'ORDER_CANCEL_REQUEST',
    CANCEL_SUCCESS: 'ORDER_CANCEL_SUCCESS',
	CANCEL_FAILURE: 'ORDER_CANCEL_FAILURE',
	CANCEL_CLEAR: 'ORDER_CANCEL_CLEAR',

	PAYMENT_PROCESS_REQUEST: 'ORDER_PAYMENT_PROCESS_REQUEST',
    PAYMENT_PROCESS_SUCCESS: 'ORDER_PAYMENT_PROCESS_SUCCESS',
	PAYMENT_PROCESS_FAILURE: 'ORDER_PAYMENT_PROCESS_FAILURE',
	PAYMENT_PROCESS_CLEAR: 'ORDER_PAYMENT_PROCESS_CLEAR',

	GET_PAYMENTS_REQUEST: 'ORDER_GET_PAYMENTS_REQUEST',
    GET_PAYMENTS_SUCCESS: 'ORDER_GET_PAYMENTS_SUCCESS',
	GET_PAYMENTS_FAILURE: 'ORDER_GET_PAYMENTS_FAILURE',
	GET_PAYMENTS_CLEAR: 'ORDER_GET_PAYMENTS_CLEAR',

	GET_PAYMENT_REQUEST: 'ORDER_GET_PAYMENT_REQUEST',
    GET_PAYMENT_SUCCESS: 'ORDER_GET_PAYMENT_SUCCESS',
	GET_PAYMENT_FAILURE: 'ORDER_GET_PAYMENT_FAILURE',
	GET_PAYMENT_CLEAR: 'ORDER_GET_PAYMENT_CLEAR',

	GET_RECEIPT_REQUEST: 'ORDER_GET_RECEIPT_REQUEST',
    GET_RECEIPT_SUCCESS: 'ORDER_GET_RECEIPT_SUCCESS',
	GET_RECEIPT_FAILURE: 'ORDER_GET_RECEIPT_FAILURE',
	GET_RECEIPT_CLEAR: 'ORDER_GET_RECEIPT_CLEAR',

	EDIT_DATETIME_REQUEST: 'ORDER_EDIT_DATETIME_REQUEST',
    EDIT_DATETIME_SUCCESS: 'ORDER_EDIT_DATETIME_SUCCESS',
	EDIT_DATETIME_FAILURE: 'ORDER_EDIT_DATETIME_FAILURE',
	EDIT_DATETIME_CLEAR: 'ORDER_EDIT_DATETIME_CLEAR',

	GET_EDIT_DATETIME_HISTORY_REQUEST: 'ORDER_GET_EDIT_DATETIME_HISTORY_REQUEST',
    GET_EDIT_DATETIME_HISTORY_SUCCESS: 'ORDER_GET_EDIT_DATETIME_HISTORY_SUCCESS',
	GET_EDIT_DATETIME_HISTORY_FAILURE: 'ORDER_GET_EDIT_DATETIME_HISTORY_FAILURE',
	GET_EDIT_DATETIME_HISTORY_CLEAR: 'ORDER_GET_EDIT_DATETIME_HISTORY_CLEAR',

	GET_PENDING_ITEMS_COUNT_REQUEST: 'ORDER_GET_PENDING_ITEMS_COUNT_REQUEST',
    GET_PENDING_ITEMS_COUNT_SUCCESS: 'ORDER_GET_PENDING_ITEMS_COUNT_SUCCESS',
	GET_PENDING_ITEMS_COUNT_FAILURE: 'ORDER_GET_PENDING_ITEMS_COUNT_FAILURE',
	GET_PENDING_ITEMS_COUNT_CLEAR: 'ORDER_GET_PENDING_ITEMS_COUNT_CLEAR',

	GET_PENDING_ITEMS_REQUEST: 'ORDER_GET_PENDING_ITEMS_REQUEST',
    GET_PENDING_ITEMS_SUCCESS: 'ORDER_GET_PENDING_ITEMS_SUCCESS',
	GET_PENDING_ITEMS_FAILURE: 'ORDER_GET_PENDING_ITEMS_FAILURE',
	GET_PENDING_ITEMS_CLEAR: 'ORDER_GET_PENDING_ITEMS_CLEAR',

	REPLY_PENDING_ITEM_REQUEST: 'ORDER_REPLY_PENDING_ITEM_REQUEST',
    REPLY_PENDING_ITEM_SUCCESS: 'ORDER_REPLY_PENDING_ITEM_SUCCESS',
	REPLY_PENDING_ITEM_FAILURE: 'ORDER_REPLY_PENDING_ITEM_FAILURE',
	REPLY_PENDING_ITEM_CLEAR: 'ORDER_REPLY_PENDING_ITEM_CLEAR',

	CANCEL_PENDING_ITEM_REQUEST: 'ORDER_CANCEL_PENDING_ITEM_REQUEST',
    CANCEL_PENDING_ITEM_SUCCESS: 'ORDER_CANCEL_PENDING_ITEM_SUCCESS',
	CANCEL_PENDING_ITEM_FAILURE: 'ORDER_CANCEL_PENDING_ITEM_FAILURE',
	CANCEL_PENDING_ITEM_CLEAR: 'ORDER_CANCEL_PENDING_ITEM_CLEAR',

	GET_MEETUP_REQUEST: 'ORDER_GET_MEETUP_REQUEST',
    GET_MEETUP_SUCCESS: 'ORDER_GET_MEETUP_SUCCESS',
	GET_MEETUP_FAILURE: 'ORDER_GET_MEETUP_FAILURE',
	GET_MEETUP_CLEAR: 'ORDER_GET_MEETUP_CLEAR',

	EDIT_MEETUP_REQUEST: 'ORDER_EDIT_MEETUP_REQUEST',
    EDIT_MEETUP_SUCCESS: 'ORDER_EDIT_MEETUP_SUCCESS',
	EDIT_MEETUP_FAILURE: 'ORDER_EDIT_MEETUP_FAILURE',
	EDIT_MEETUP_CLEAR: 'ORDER_EDIT_MEETUP_CLEAR',

	GET_MATERIALS_REQUEST: 'ORDER_GET_MATERIALS_REQUEST',
    GET_MATERIALS_SUCCESS: 'ORDER_GET_MATERIALS_SUCCESS',
	GET_MATERIALS_FAILURE: 'ORDER_GET_MATERIALS_FAILURE',
	GET_MATERIALS_CLEAR: 'ORDER_GET_MATERIALS_CLEAR',

	EDIT_MATERIALS_REQUEST: 'ORDER_EDIT_MATERIALS_REQUEST',
    EDIT_MATERIALS_SUCCESS: 'ORDER_EDIT_MATERIALS_SUCCESS',
	EDIT_MATERIALS_FAILURE: 'ORDER_EDIT_MATERIALS_FAILURE',
	EDIT_MATERIALS_CLEAR: 'ORDER_EDIT_MATERIALS_CLEAR',

	GET_RECEIPTS_REQUEST: 'ORDER_GET_RECEIPTS_REQUEST',
    GET_RECEIPTS_SUCCESS: 'ORDER_GET_RECEIPTS_SUCCESS',
	GET_RECEIPTS_FAILURE: 'ORDER_GET_RECEIPTS_FAILURE',
	GET_RECEIPTS_CLEAR: 'ORDER_GET_RECEIPTS_CLEAR',

	GET_REVIEW_REQUEST: 'ORDER_GET_REVIEW_REQUEST',
    GET_REVIEW_SUCCESS: 'ORDER_GET_REVIEW_SUCCESS',
	GET_REVIEW_FAILURE: 'ORDER_GET_REVIEW_FAILURE',
	GET_REVIEW_CLEAR: 'ORDER_GET_REVIEW_CLEAR',

	EDIT_REVIEW_REQUEST: 'ORDER_EDIT_REVIEW_REQUEST',
    EDIT_REVIEW_SUCCESS: 'ORDER_EDIT_REVIEW_SUCCESS',
	EDIT_REVIEW_FAILURE: 'ORDER_EDIT_REVIEW_FAILURE',
	EDIT_REVIEW_CLEAR: 'ORDER_EDIT_REVIEW_CLEAR',

	COMPLETE_REQUEST: 'ORDER_COMPLETE_REQUEST',
    COMPLETE_SUCCESS: 'ORDER_COMPLETE_SUCCESS',
	COMPLETE_FAILURE: 'ORDER_COMPLETE_FAILURE',
	COMPLETE_CLEAR: 'ORDER_COMPLETE_CLEAR',

	GET_REPORT_OPTIONS_REQUEST: 'ORDER_GET_REPORT_OPTIONS_REQUEST',
    GET_REPORT_OPTIONS_SUCCESS: 'ORDER_GET_REPORT_OPTIONS_SUCCESS',
	GET_REPORT_OPTIONS_FAILURE: 'ORDER_GET_REPORT_OPTIONS_FAILURE',
	GET_REPORT_OPTIONS_CLEAR: 'ORDER_GET_REPORT_OPTIONS_CLEAR',

	EDIT_REPORT_REQUEST: 'ORDER_EDIT_REPORT_REQUEST',
    EDIT_REPORT_SUCCESS: 'ORDER_EDIT_REPORT_SUCCESS',
	EDIT_REPORT_FAILURE: 'ORDER_EDIT_REPORT_FAILURE',
	EDIT_REPORT_CLEAR: 'ORDER_EDIT_REPORT_CLEAR',

	PAYMENT_APPLY_CODE_REQUEST: 'ORDER_PAYMENT_APPLY_CODE_REQUEST',
    PAYMENT_APPLY_CODE_SUCCESS: 'ORDER_PAYMENT_APPLY_CODE_SUCCESS',
	PAYMENT_APPLY_CODE_FAILURE: 'ORDER_PAYMENT_APPLY_CODE_FAILURE',
	PAYMENT_APPLY_CODE_CLEAR: 'ORDER_PAYMENT_APPLY_CODE_CLEAR',
};


export const orderStatus = {
	requesting: 1,
	offered: 2,
	deal_cancelled: 3,
	full_paid: 4,
	shooting: 5,
	shooted: 6,
	completed: 7,
	order_cancelled: 8,
	refunding: 9,
	refunded: 10,
	refund_fail: 11,
	expired: 999
}

export function getOrderStatusNameByStatus(status) {
	switch (status) {
		case orderStatus.requesting: return 'requesting';
		case orderStatus.offered: return 'offered';
		case orderStatus.deal_cancelled: return 'deal_cancelled';
		case orderStatus.full_paid: return 'full_paid';
		case orderStatus.shooting: return 'shooting';
		case orderStatus.shooted: return 'shooted';
		case orderStatus.completed: return 'completed';
		case orderStatus.order_cancelled: return 'order_cancelled';
		case orderStatus.refunding: return 'refunding';
		case orderStatus.refunded: return 'refunded';
		case orderStatus.refund_fail: return 'refund_fail';
	
		default: return null;
	}
}

export function getOrderStatusNameByData(data) {
	if (data.is_expired) {
		return 'expired';
	} else {
		return getOrderStatusNameByStatus(data.status);
	}
}

export const orderPaymentType = {
	full_paid: 1,
	half_refund: 2,
	full_refund: 3
}

export function getOrderPaymentTypeNameByType(type) {
	switch (type) {
		case orderPaymentType.full_paid: return 'full_paid';
		case orderPaymentType.half_refund: return 'half_refund';
		case orderPaymentType.full_refund: return 'full_refund';
	
		default: return null;
	}
}

export const orderPaymentStatus = {
	unpaid: 1,
	paid: 2,
	processing: 3,
	failed: 4,
	cancelled: 5,
	expired: 6
}

export function getOrderPaymentStatusNameByData(data) {
	let typeName = 'unknown';
	let statusName = 'unknown';
	switch (data.type) {
		case orderPaymentType.full_paid:
			typeName = 'full_paid'
			break;
			case orderPaymentType.half_refund:
			typeName = 'half_refund'
			break;
			case orderPaymentType.full_refund:
			typeName = 'full_refund'
			break;
		default:
			break;
	}
	switch (data.status) {
		case orderPaymentStatus.unpaid: statusName = 'unpaid'; break;
		case orderPaymentStatus.paid: statusName = 'paid'; break;
		case orderPaymentStatus.processing: statusName = 'processing'; break;
		case orderPaymentStatus.failed: statusName = 'failed'; break;
		case orderPaymentStatus.cancelled: statusName = 'cancelled'; break;
		case orderPaymentStatus.expired: statusName = 'expired'; break;
	
		default: break;
	}

	return `${typeName}.${statusName}`
}

export const orderType = {
	deal: 'deal',
	order: 'order'
}

export function getOrderTypeByOrderStatus(status) {
	const isDeal = ((status === orderStatus.requesting || status === orderStatus.offered || status === orderStatus.deal_cancelled));
	return isDeal? orderType.deal : orderType.order;
}

export function canEditOrderByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.requesting ||
			data.status === orderStatus.offered
		);
}

export function canEditOrderShootingDatetimeByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.requesting ||
			data.status === orderStatus.offered ||
			data.status === orderStatus.full_paid ||
			data.status === orderStatus.shooting ||
			data.status === orderStatus.shooted
		);
}

export function canCancelOrderByData(data) {
	return !data.is_expired &&
		(
			data.status !== orderStatus.deal_cancelled &&
			data.status !== orderStatus.order_cancelled &&
			data.status !== orderStatus.completed &&
			data.status !== orderStatus.refunding &&
			data.status !== orderStatus.refunded &&
			data.status !== orderStatus.refund_fail
		);
}

export const orderEditDatetimeStatus = {
	pending: 1,
	accepted: 2,
	rejected: 3,
	cancelled: 4,
	expired: 5,
}

export function getOrderEditDatetimeStatusNameByStatus(status) {
	switch (status) {
		case orderEditDatetimeStatus.pending: return 'pending';
		case orderEditDatetimeStatus.accepted: return 'accepted';
		case orderEditDatetimeStatus.rejected: return 'rejected';
		case orderEditDatetimeStatus.cancelled: return 'cancelled';
		case orderEditDatetimeStatus.expired: return 'expired';
	
		default: return null;
	}
}

export const orderReceiptType = {
	paid: 1,
	edit_shooting_datetime: 2,
	half_refund: 3,
	full_refund: 4,
}

export function getOrderReceiptTypeNameByType(type) {
	switch (type) {
		case orderReceiptType.paid: return 'paid';
		case orderReceiptType.edit_shooting_datetime: return 'edit_shooting_datetime';
		case orderReceiptType.half_refund: return 'half_refund';
		case orderReceiptType.full_refund: return 'full_refund';
		default: return null;
	}
}

export function canReviewOrderByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.completed
		);
}

export function canEditOrderMeetupDetailsByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.full_paid ||
			data.status === orderStatus.shooting
		);
}

export function canEditOrderAfterShootingMaterialsByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.shooting ||
			data.status === orderStatus.shooted ||
			data.status === orderStatus.completed
		);
}

export function canCompleteOrderByData(data) {
	return !data.is_expired &&
		(
			data.status === orderStatus.shooted
		);
}

export function canReplyOrderPendingItemsByData(data) {
	return !data.is_expired &&
		(
			data.status !== orderStatus.deal_cancelled &&
			data.status !== orderStatus.order_cancelled &&
			data.status !== orderStatus.completed &&
			data.status !== orderStatus.refunding &&
			data.status !== orderStatus.refunded &&
			data.status !== orderStatus.refund_fail
		);
}