import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav, NavItem, NavLink
} from 'reactstrap';

import { history, auth as authHelper } from '../../_helpers';
import { ProfilePicImg, SVGImg } from '../../_components';

import './Header.scss';

import img_placeholder from '../../_common/img/FFFFFF-0.png';
import img_hamburger from './img/topbar_btn_menu.svg';

import { NavUser } from './NavUser';
import { NavCustomer } from './NavCustomer';
import { NavVendor } from './NavVendor';

class Header extends Component {
	// lifecycle
	constructor(props) {
		super(props);
		this.handleScroll = this.handleScroll.bind(this);

		const { location } = history;

		this.state = {
			isShrink: false,
			isNavHamburgerOpen: false,
			isUserHamburgerOpen: false,
			isNavHamburgerShowing: false,
			isUserHamburgerShowing: false,
			isMouseEntered: false,
			isVendor: location.pathname.startsWith('/vendor')
		}

		history.listen((location, action) => {
			let isVendor = location.pathname.startsWith('/vendor');
			if (this.state.isVendor !== isVendor) {
				this.setState({ isVendor });
			}

			this.setState({ isNavHamburgerOpen: false, isUserHamburgerOpen: false });
		});
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		document.addEventListener('mousedown', this.onClickOutside);
	};

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		document.removeEventListener('mousedown', this.onClickOutside);
	};

	handleScroll(event) {
		const distanceY = window.pageYOffset || document.documentElement.scrollTop,
			shrinkOn = 60;

		const shouldShrink = distanceY > shrinkOn;
		this.setState({ isShrink: shouldShrink })
	};

	toggleNavHamburger() {
		this.setState({ isNavHamburgerOpen: !this.state.isNavHamburgerOpen,  isUserHamburgerOpen: !this.state.isNavHamburgerOpen? false : this.state.isUserHamburgerOpen });
	}

	toggleUserHamburger() {
		this.setState({ isUserHamburgerOpen: !this.state.isUserHamburgerOpen,  isNavHamburgerOpen: !this.state.isUserHamburgerOpen? false : this.state.isNavHamburgerOpen });
	}

	onNavHamburgerEvent(isEnter, isFinished) {
		const { isNavHamburgerShowing } = this.state;

		if (!isEnter && isFinished) {
			if (!isNavHamburgerShowing) return;
			this.setState({ isNavHamburgerShowing: false });
		} else {
			if (isNavHamburgerShowing) return;
			this.setState({ isNavHamburgerShowing: true });
		}
	}

	onUserHamburgerEvent(isEnter, isFinished) {
		const { isUserHamburgerShowing } = this.state;

		if (!isEnter && isFinished) {
			if (!isUserHamburgerShowing) return;
			this.setState({ isUserHamburgerShowing: false });
		} else {
			if (isUserHamburgerShowing) return;
			this.setState({ isUserHamburgerShowing: true });
		}
	}

	onHover(isEnter) {
		if (this.state.isMouseEntered === isEnter) return;
		this.setState({ isMouseEntered: isEnter });
	}

    render() {
		const { t, header } = this.props;
		const { isShrink, isNavHamburgerOpen, isMouseEntered, isVendor, isUserHamburgerOpen, isNavHamburgerShowing, isUserHamburgerShowing } = this.state;

		const isLoggedIn = authHelper.isLoggedIn(isVendor, this.props.auth.user);
		const user = this.props.auth.user;

		return (
			<Navbar id="nav" expand="xl" className={(header.gradient ? 'gradient' : '') + ' ' + ((isNavHamburgerOpen || isUserHamburgerOpen || isShrink || isMouseEntered || (header.solid && !header.gradient)) ? 'solid' : '') + ' ' + ((header.dark && !header.gradient)? 'dark' : '') + ' ' + (isShrink ? 'shrink' : '') + ' ' + ((isNavHamburgerShowing || isUserHamburgerShowing) ? 'nav-menu-opened' : '')}
				onMouseEnter={() => this.onHover(true)}
				onMouseLeave={() => this.onHover(false)}
			>
				<NavbarToggler onClick={() => this.toggleNavHamburger()} className="navbar-toggler-app">
					<SVGImg img="menu-hamburger" />
				</NavbarToggler>
				<Link className="navbar-brand" to={isVendor ? "/vendor" : "/"}>
					<img src={img_placeholder} alt="logo" />
					<span>{t('title')}</span>
				</Link>
				{isLoggedIn &&
					<NavbarToggler onClick={() => this.toggleUserHamburger()} className="navbar-toggler-user">
						<ProfilePicImg src={user.profile_pic_url} alt="menu" />
					</NavbarToggler>
				}
				<Collapse
					isOpen={isNavHamburgerOpen}
					navbar
					onEntering={() => this.onNavHamburgerEvent(true, false)}
					onEntered={() => this.onNavHamburgerEvent(true, true)}
					onExiting={() => this.onNavHamburgerEvent(false, false)}
					onExited={() => this.onNavHamburgerEvent(false, true)}
				>
					{isVendor ? <NavVendor /> : <NavCustomer />}
				</Collapse>
				<Collapse
					className={isLoggedIn ? "" : "nav-display-desktop"}
					isOpen={isUserHamburgerOpen}
					navbar
					onEntering={() => this.onUserHamburgerEvent(true, false)}
					onEntered={() => this.onUserHamburgerEvent(true, true)}
					onExiting={() => this.onUserHamburgerEvent(false, false)}
					onExited={() => this.onUserHamburgerEvent(false, true)}
				>
					<NavUser isVendor={isVendor} />
				</Collapse>
			</Navbar>
		);
	}
}

function mapStateToProps(state) {
	const { header, auth } = state;
	return {
		header,
		auth
	};
}

const translation = withNamespaces(['header'])(Header);
const connected = connect(mapStateToProps)(translation);
export { connected as Header };