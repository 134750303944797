import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import { savedItemsActions } from '../../_actions';
import { savedItemType } from '../../_constants';
import { history, auth as authHelper } from '../../_helpers';
import { GeneralGridItemContent } from '../../_components';

import './GridItem.scss';

export class GridItemContent extends Component {
	onBookmark(item) {
		if (authHelper.isLoggedIn(false, this.props.auth.user)) {
			if (item.is_bookmarked) {
				this.props.dispatch(savedItemsActions.removeSavedItemsArticle(item.id));
			} else {
				this.props.dispatch(savedItemsActions.addSavedItemsArticle(item.id));
			}
		} else {
			//go to login
			history.push('/customer/login');
		}
	}

	onClick(item) {
		history.push(`/article/${item.id}`);
	}

	render() {
		const { t, item, saved_item_action } = this.props;

		const isLoading = saved_item_action.loading && saved_item_action.type == savedItemType.ARTICLE && saved_item_action.id == item.id;

		const is_disabled = false;

		return (
			<GeneralGridItemContent className="grid-item-content-article"
				isLoading={isLoading}
				isDisabled={is_disabled}
				disabledTitle={t('unavailable_article')}
				isBookmarked={item.is_bookmarked}
				onBookmark={() => this.onBookmark(item)}
				onClick={() => this.onClick(item)}
			>
				<div className="img-wrapper">
					<img src={item.image_url} alt={item.title} />
				</div>
				<LinesEllipsis
					className="grid-item-title"
					text={item.title}
					maxLine='2'
					ellipsis='...'
					trimRight
					basedOn='words'
				/>
				<div className="grid-item-by">{t('by')} {item.user_name}</div>
			</GeneralGridItemContent>
		);
	}
}