
import React from 'react';

import './Loading.scss';

import { Error } from '../Error';

import img_loading from './img/loading.svg';

export const Loading = ({ className, retry, error, overlay, desc, ...props }) => {
	var content = null;
	let propsClassName = `${overlay? 'loading-overlay' : ''}`;
	var typeClassName = '';
	let customClassName = className ? className : '';

	if (error) {
		// When the loader has errored
		typeClassName = "loading-error";
		content = (
			<div className="loading-content">
				<Error
					error={error}
				/>
			</div>
		);
	} else {
		// When the loader has just started
		typeClassName = "loading-general";
		content = (
			<div className="loading-content">
				<div className="loading-img-wrapper">
					<img src={img_loading} alt="" />
				</div>
				<div className="loading-content-desc">{desc}</div>
			</div>
		);
	}

	return <div className={`loading ${typeClassName} ${propsClassName} ${customClassName}`}>{content}</div>;
}