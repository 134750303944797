import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { auth as authHelper } from '../_helpers';

export const PrivateRoute = ({ component: Component, render, ...rest }) => {
	const isVendor = rest.path.startsWith('/vendor');
	const loginData = authHelper.getLogin();
	const isLoggedIn = authHelper.isLoggedIn(isVendor, loginData? loginData.user : undefined);

	return (
		<Route {...rest} render={props => (
			//to redirect unauthenticated to login
			isLoggedIn? Component? <Component {...props} /> :  render(props) : <Redirect to={{ pathname: isVendor? '/vendor/login' : '/customer/login', state: { from: props.location } }} />
		)} />
	)
}
