import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Row, Col } from 'reactstrap';

import { Desc, ProfilePicImg } from '../../../_components';

import './Review.scss';

import img_reviews_star_off from '../../../_common/img/details_icon_reviews_star_off.svg';
import img_reviews_star_on from '../../../_common/img/details_icon_reviews_star_on.svg';

class Review extends Component {
	render() {
		const { t, data } = this.props;

		const reviewsStarTotal = 5;
		var reviewStarsBg = [];
		for (var i = 0; i < reviewsStarTotal; i++) {
			reviewStarsBg.push(<img key={i} src={img_reviews_star_off} alt="" />);
		}
		const reviewStars = [1, 2, 3, 4, 5];

		return (
			<Row className="reviews-list-review">
				<Col md={{ size: 4 }} className="user">
					<Row>
						<Col xs={{ size: 'auto' }} className="col-img">
							<ProfilePicImg src={data.from_user.profile_pic_url} alt='user' />
						</Col>
						<Col xs={{ size: true }}>
							<div className="name">{data.from_user.nickname}</div>
							<div className="date">
								{Moment.utc(data.created_at.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}
							</div>
						</Col>
					</Row>
				</Col>
				<Col md={{ size: 8 }} className="review">
					<Row className="row-m-8 review-header">
						<Col sm={{ size: 'auto' }}>
						<div className="stars">
							{reviewStars.map((item) =>
								<img key={item} src={(Math.round(data.rate) >= item) ? img_reviews_star_on : img_reviews_star_off} alt="" />
							)}
						</div>
						</Col>
						<Col sm={{ size: true }}>
						<div className="name">{data.title}</div>
						</Col>
					</Row>
					<Desc>{data.content}</Desc>
				</Col>
			</Row>
		);
	}
}

const translation = withNamespaces(['package'])(Review);
export { translation as Review };