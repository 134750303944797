import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Container, Row, Col } from 'reactstrap';
import queryString from 'query-string';

import { history, app } from '../../../_helpers';
import { payoutActions } from '../../../_actions';
import { getPayoutStatusNameByStatus, payoutStatus, getPayoutTypeNameByType } from '../../../_constants';
import { Title, ResponsiveTable, InfiniteScrollWrapper, Loading } from '../../../_components';

import './Payouts.scss';

class Payouts extends Component {
	constructor(props) {
		super(props);

		const { t, location } = props;
		let targetPackageId = queryString.parse(location.search).package_id;

		this.state = {
			status: {id: null, value: t('all_statuses')},
			selectedPackage: { id: null, name: t('all_packages') },
			targetPackageId
		};
	}

	componentDidMount() {
		this.props.dispatch(payoutActions.getListClear());
		this.props.dispatch(payoutActions.getPackagesShortInfo());
	}

	componentDidUpdate(prevProps, prevState) {
		const { payouts } = this.props;
		const { targetPackageId, selectedPackage } = this.state;

		if (payouts.packages.list && targetPackageId && selectedPackage.id !== targetPackageId) {
			const targetPackage = payouts.packages.list.find((item) => item.id == targetPackageId);

			this.setState({ targetPackageId: undefined }, () => {
				if (targetPackage) {
					this.onSelectPackage(targetPackage);
				}
			});
		}
	}
	

	onSelectStatus(status) {
		if (this.state.status.id !== status.id) {
			this.setState({ status }, () => {
				this.infiniteScrollComponent.reload()
			});
		}
	}

	onSelectPackage(selectedPackage) {
		if (this.state.selectedPackage.id !== selectedPackage.id) {
			this.setState({ selectedPackage }, () => {
				this.infiniteScrollComponent.reload()
			});
		}
	}

	onRowClick(data, index) {
		history.push(`/vendor/me/payout/${data.id}`);
	}
	
	render() {
		const { t, payouts } = this.props;
		const { status, selectedPackage } = this.state;

		const statues = [
			{ id: null, value: 'all_statuses' },
			{ id: payoutStatus.pending, value: getPayoutStatusNameByStatus(payoutStatus.pending) },
			{ id: payoutStatus.processing, value: getPayoutStatusNameByStatus(payoutStatus.processing) },
			{ id: payoutStatus.success, value: getPayoutStatusNameByStatus(payoutStatus.success) },
			{ id: payoutStatus.fail, value: getPayoutStatusNameByStatus(payoutStatus.fail) },
		];

		let packages = [
			{ id: null, name: t('all_packages') }
		];

		if (payouts.packages.list) {
			packages = packages.concat(payouts.packages.list);
		}

		return (
			<div id="vendor_profile_payouts">
				<Row className="row-m-8 header">
					<Col md={{ size: true }}>
						<Title>
							{t('vendor.payout_summary')}
						</Title>
					</Col>
					<Col md={{ size: 'auto' }} sm={{ size: true }} className="package">
						{payouts.packages.loading && <Loading />}
						{payouts.packages.list && payouts.packages.list.length > 0 &&
							<UncontrolledButtonDropdown>
								<DropdownToggle caret className="btn-block btn-outline-primary">
									{selectedPackage.name}
								</DropdownToggle>
								<DropdownMenu right>
									{packages.map((item, index) =>
										<DropdownItem key={item.id} onClick={() => this.onSelectPackage(item)}>{item.name}</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						}
					</Col>
					{(selectedPackage.id || status.id || (payouts.list && payouts.list.length > 0)) &&
						<Col md={{ size: 'auto' }} sm={{ size: true }} className="status">
							<UncontrolledButtonDropdown>
								<DropdownToggle caret className="btn-block btn-outline-primary">
									{t(`status.${status.value}`)}
								</DropdownToggle>
								<DropdownMenu>
									{statues.map((item, index) =>
										<DropdownItem key={item.id} onClick={() => this.onSelectStatus(item)}>{t(`status.${item.value}`)}</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</Col>
					}
				</Row>
				<InfiniteScrollWrapper
					elementRef={(element) => this.infiniteScrollComponent = element}
					pageStart={1}
					loadMoreProp={payouts}
					onLoadMore={(nextPage) => {
						const { status, selectedPackage, targetPackageId } = this.state;
						if (targetPackageId && selectedPackage.id !== targetPackageId) return;

						this.props.dispatch(payoutActions.getList(nextPage, status.id? status.id : undefined, selectedPackage.id? selectedPackage.id : undefined));
					}}
					renderItems={(items) => {
						return (
							<ResponsiveTable
								t={t}
								columns={[
									{ key: 'type', name: t('type'), grid_size: '2' },
									{ key: 'order_id', name: t('payout_for'), grid_size: '2' },
									{ key: 'amount', grid_size: '2' },
									{ key: 'status', name: t('status.status'), grid_size: '3' },
									{ key: 'updated_at', name: t('last_updated_time'), grid_size: '3' },
								]}
								data={items}
								renderCellValue={(column, cellData, rowData) => {
									switch (column.key) {
										case 'type':
											return t(`payout_type.${getPayoutTypeNameByType(cellData)}`);
										case 'order_id':
											return `${t('order_number')}${cellData}`;
										case 'amount':
											return app.toCurrencyString(rowData.currency.value, cellData);
										case 'status':
											let statusName = getPayoutStatusNameByStatus(cellData);
											return (
												<div className={`name ${statusName}`}>{t(`status.${statusName}`)}</div>
											);
										case 'updated_at':
											return Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma');
										default:
											return null; //return null to use default rendering
									}
								}}
								onRowClick={(data, index) => this.onRowClick(data, index)}
							/>
						)
					}}
					noResultDesc={t('no_payout_items')}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
    const { payouts } = state; 
    return {
		payouts
    };
}

const translation = withNamespaces(['payout', 'package', 'order', 'profile', 'default'])(Payouts);
const connected = connect(mapStateToProps)(translation);
export { connected as Payouts };