import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import { InputField, Title, InputItem } from '../../../../_components';
import { FieldArray, ErrorMessage, connect as connectFormik } from 'formik';

import './Location.scss';

import img_remove from './img/create_package_icon_remove_location.svg';

import { Photo } from './Photo';

class Location extends Component {
	async addFiles(arrayHelpers, event) {
		//otherwise the event will be gone after await
		event.persist();

		const { formInfo, formik, index } = this.props;
		const { values } = formik;

		const files = event.currentTarget.files;

		if (files.length) {
			for (var i = 0; i < Math.min((formInfo.max_photo_per_location - values.locations[index].items.length), files.length); i ++) {
				await arrayHelpers.push({ name: files[i].name, photo_url: '', photo: files[i], is_cover: (values.locations[index].items.length === 0 && i === 0) });
			}
		}

		event.target.value = null;
	}

	render() {
		const { t, index, locationsArrayHelpers, formInfo, formik } = this.props;
		const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

		return (
			<FieldArray
				name={`locations.${index}.items`}
				render={arrayHelpers => (
					<div id="vendor_createpackage_locationportfolio_location">
						<Row className="header">
							<Col xs={{ size: true }}>
								<Title size='sm'>
									{`${t('location')} (${index + 1})`}
								</Title>
							</Col>
							<Col xs={{ size: 'auto' }}>
								<Button className="btn-remove" color="link" onClick={() => {
									locationsArrayHelpers.remove(index);
									this.props.didRemoveLocation(index);
								}}>
									<img src={img_remove} alt={t('remove')} />{t('remove')}
								</Button>
							</Col>
						</Row>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }} md={{ size: 4 }}>
									<div className="required">
										{t('location_name')}
									</div>
									<div className="remarks pt-0">
										{t('location_portfolio_remarks', { n: formInfo.max_photo_per_location })}
									</div>
								</Col>
								<Col lg={{ size: 9 }} md={{ size: 8 }}>
									<InputField type="text" name={`locations.${index}.name`} placeholder={t('location_name')} t={t} />
								</Col>
							</Row>
						</InputItem>
						<InputItem>
							<Row>
								<Col lg={{ size: 3 }} md={{ size: 4 }}>
									<div className="required">
										{t('location_portfolio')}
									</div>
								</Col>
								<Col lg={{ size: 6 }} md={{ size: 8 }}>
									<div className={`upload-image-wrapper ${(values.locations[index].items.length >= formInfo.max_photo_per_location ? 'd-none' : '')}`}>
										<label htmlFor="locations_img" className={`upload-image ${(touched.locations && touched.locations[index] && touched.locations[index].items && errors.locations && errors.locations[index] && errors.locations[index].items) ? "input-error" : ""}`}>
											{t('upload_images')}
										</label>
										<input id="locations_img" name="locations_img" type="file" accept="image/*" multiple onChange={async (event) => {
											await this.addFiles(arrayHelpers, event);
										}} className="form-control input-upload-image" />
										<ErrorMessage name={`locations.${index}.items`} component="span" className="error" />
									</div>
								</Col>
							</Row>
						</InputItem>
						<InputItem className="photos">
							<Row className="row-m-10">
								{values.locations[index].items.map((item, i) =>
									<Col lg={{ size: 4 }} sm={{ size: 6 }} key={i}>
										<Photo
											locationIndex={index}
											locationItemsArrayHelpers={arrayHelpers}
											index={i}
											formInfo={formInfo}
										/>
									</Col>
								)}
							</Row>
						</InputItem>
					</div>

				)}
			/>
		);
	}
}

const translation = withNamespaces(['package', 'default'])(Location);
const connectedFormik = connectFormik(translation);
export { connectedFormik as Location };