export const savedItemsConstants = {
	GET_PACKAGES_REQUEST: 'SAVED_ITEMS_GET_PACKAGES_REQUEST',
	GET_PACKAGES_SUCCESS: 'SAVED_ITEMS_GET_PACKAGES_SUCCESS',
	GET_PACKAGES_FAILURE: 'SAVED_ITEMS_GET_PACKAGES_FAILURE',
	GET_PACKAGES_CLEAR: 'SAVED_ITEMS_GET_PACKAGES_CLEAR',

	ADD_PACKAGE_REQUEST: 'SAVED_ITEMS_ADD_PACKAGE_REQUEST',
	ADD_PACKAGE_SUCCESS: 'SAVED_ITEMS_ADD_PACKAGE_SUCCESS',
	ADD_PACKAGE_FAILURE: 'SAVED_ITEMS_ADD_PACKAGE_FAILURE',
	ADD_PACKAGE_CLEAR: 'SAVED_ITEMS_ADD_PACKAGE_CLEAR',

	REMOVE_PACKAGE_REQUEST: 'SAVED_ITEMS_REMOVE_PACKAGE_REQUEST',
	REMOVE_PACKAGE_SUCCESS: 'SAVED_ITEMS_REMOVE_PACKAGE_SUCCESS',
	REMOVE_PACKAGE_FAILURE: 'SAVED_ITEMS_REMOVE_PACKAGE_FAILURE',
	REMOVE_PACKAGE_CLEAR: 'SAVED_ITEMS_REMOVE_PACKAGE_CLEAR',

	GET_VENDORS_REQUEST: 'SAVED_ITEMS_GET_VENDORS_REQUEST',
	GET_VENDORS_SUCCESS: 'SAVED_ITEMS_GET_VENDORS_SUCCESS',
	GET_VENDORS_FAILURE: 'SAVED_ITEMS_GET_VENDORS_FAILURE',
	GET_VENDORS_CLEAR: 'SAVED_ITEMS_GET_VENDORS_CLEAR',

	ADD_VENDOR_REQUEST: 'SAVED_ITEMS_ADD_VENDOR_REQUEST',
	ADD_VENDOR_SUCCESS: 'SAVED_ITEMS_ADD_VENDOR_SUCCESS',
	ADD_VENDOR_FAILURE: 'SAVED_ITEMS_ADD_VENDOR_FAILURE',
	ADD_VENDOR_CLEAR: 'SAVED_ITEMS_ADD_VENDOR_CLEAR',

	REMOVE_VENDOR_REQUEST: 'SAVED_ITEMS_REMOVE_VENDOR_REQUEST',
	REMOVE_VENDOR_SUCCESS: 'SAVED_ITEMS_REMOVE_VENDOR_SUCCESS',
	REMOVE_VENDOR_FAILURE: 'SAVED_ITEMS_REMOVE_VENDOR_FAILURE',
	REMOVE_VENDOR_CLEAR: 'SAVED_ITEMS_REMOVE_VENDOR_CLEAR',

	GET_ARTICLES_REQUEST: 'SAVED_ITEMS_GET_ARTICLES_REQUEST',
	GET_ARTICLES_SUCCESS: 'SAVED_ITEMS_GET_ARTICLES_SUCCESS',
	GET_ARTICLES_FAILURE: 'SAVED_ITEMS_GET_ARTICLES_FAILURE',
	GET_ARTICLES_CLEAR: 'SAVED_ITEMS_GET_ARTICLES_CLEAR',

	ADD_ARTICLE_REQUEST: 'SAVED_ITEMS_ADD_ARTICLE_REQUEST',
	ADD_ARTICLE_SUCCESS: 'SAVED_ITEMS_ADD_ARTICLE_SUCCESS',
	ADD_ARTICLE_FAILURE: 'SAVED_ITEMS_ADD_ARTICLE_FAILURE',
	ADD_ARTICLE_CLEAR: 'SAVED_ITEMS_ADD_ARTICLE_CLEAR',

	REMOVE_ARTICLE_REQUEST: 'SAVED_ITEMS_REMOVE_ARTICLE_REQUEST',
	REMOVE_ARTICLE_SUCCESS: 'SAVED_ITEMS_REMOVE_ARTICLE_SUCCESS',
	REMOVE_ARTICLE_FAILURE: 'SAVED_ITEMS_REMOVE_ARTICLE_FAILURE',
	REMOVE_ARTICLE_CLEAR: 'SAVED_ITEMS_REMOVE_ARTICLE_CLEAR',

	GET_COUNTS_REQUEST: 'SAVED_ITEMS_GET_COUNTS_REQUEST',
	GET_COUNTS_SUCCESS: 'SAVED_ITEMS_GET_COUNTS_SUCCESS',
	GET_COUNTS_FAILURE: 'SAVED_ITEMS_GET_COUNTS_FAILURE',
	GET_COUNTS_CLEAR: 'SAVED_ITEMS_GET_COUNTS_CLEAR',
};

export const savedItemActionType = {
	ADD: 'ADD',
	REMOVE: 'REMOVE'
}

export const savedItemType = {
	PACKAGE: 'PACKAGE',
	ARTICLE: 'ARTICLE',
	VENDOR: 'VENDOR'
}
