import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, InputItem, InputField, Desc } from '../../../../_components';

import './InputForm.scss';

import img_star_off from './img/details_icon_reviews_popup_star_off.svg';
import img_star_on from './img/details_icon_reviews_popup_star_on.svg';

class InputForm extends Component {
	onRate(rate, setFieldTouched, setFieldValue) {
		setFieldTouched('rate', true);
		setFieldValue('rate', rate);
	}

	render() {
		const { t, data } = this.props;

		const rates = [1, 2, 3, 4, 5];
		let initialValues = {
			rate: '5',
			title: '',
			content: ''
		};

		if (data) {
			initialValues.id = data.id;
			initialValues.rate = `${Number(data.rate)}`;
			initialValues.title = data.title;
			initialValues.content = data.content;
		}

		const schema = Yup.object().shape({
			rate: Yup.number()
				.required(t('required')),
			title: Yup.string()
				.required(t('required')),
			content: Yup.string()
				.required(t('required')),
		});

		return (
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={(values, formikBag) => this.props.onSubmit(values, formikBag)}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					setFieldTouched,
					setFieldValue
					/* and other goodies */
				}) => (
						<Form id="customer_order_review_form" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('leave_review')}</Title>
							</ModalHeader>
							<ModalBody>
								<InputItem className="input-item-rate">
									{rates.map((item, index) =>
										<Button key={item} className="btn-img" onClick={() => this.onRate(item, setFieldTouched, setFieldValue)}><img src={(values.rate >= item) ? img_star_on : img_star_off} alt="" /></Button>
									)}
									<Desc>{t(`rate.${values.rate}`)}</Desc>
								</InputItem>
								<InputItem>
									<InputField type="text" name="title" t={t} />
								</InputItem>
								<InputItem>
									<InputField component="textarea" type="text" name="content" t={t} />
								</InputItem>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={this.props.submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</Form>
					)}
			</Formik>
		);
	}
}

const translation = withNamespaces(['review', 'default'])(InputForm);
export { translation as InputForm };