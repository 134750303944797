import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import { orderActions } from '../../../_actions';

import './Receipts.scss';

import { ResponsiveTable, Loading } from '../../../_components';
import { getOrderReceiptTypeNameByType } from '../../../_constants';

class Receipts extends Component {
	componentDidMount() {
		const { data } = this.props;
		this.props.dispatch(orderActions.getReceipts(false, data.id));
	}

	render() {
		const { t, order } = this.props;

		return (
			<div id="customer_order_receipts">
				{order.receipts.loading && <Loading />}
				{order.receipts.error && <Loading error={order.receipts.error} />}
				{order.receipts.success && !order.receipts.list && <div className="no-result">{t('no_receipts')}</div>}
				{order.receipts.success && order.receipts.list &&
					<ResponsiveTable
						t={t}
						columns={[
							{ data_key: 'created_at', key: 'created_at', name: t('date'), grid_size: '3' },
							{ data_key: 'type', key: 'type', name: t('receipt'), grid_size: '6' },
							{ data_key: 'id', key: 'view_receipt', name: ' ', grid_size: '3' },
						]}
						data={order.receipts.list}
						renderCellValue={(column, cellData, rowData) => {
							switch (column.key) {
								case 'created_at':
									return Moment.utc(cellData.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma');
								case 'type':
									let name = getOrderReceiptTypeNameByType(cellData);
									return t(name ? `type.${name}` : 'receipt');
								case 'view_receipt':
									return (
										<Link to={`/customer/me/order/${rowData.order_id}/receipt/${cellData}`}>{t('view_receipt')}</Link>
									);
								default:
									return null; //return null to use default rendering
							}
						}}
					/>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['receipt', 'default'])(Receipts);
const connected = connect(mapStateToProps)(translation);
export { connected as Receipts };