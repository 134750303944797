import { myCalendarConstants, userConstants } from '../_constants';

const myCalendarEditInitialState = {
	loading: false,
	success: false,
	error: undefined,
}

const myCalendarOrdersInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const myCalendarInitialState = {
	list: undefined,
	loading: false,
	success: false,
	error: undefined,
	edit: myCalendarEditInitialState,
	orders: myCalendarOrdersInitialState
}

export function my_calendar(state = myCalendarInitialState, action) {
	switch (action.type) {
		case myCalendarConstants.GET_CLEAR:
		case userConstants.LOGOUT_SUCCESS:
			return { ...myCalendarInitialState };
		case myCalendarConstants.GET_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case myCalendarConstants.GET_SUCCESS: {
			let uniqueList;
			if (state.list) {
				uniqueList = action.responseData.reduce((uniqList, currentValue) => {
					let ids = uniqList.map(item => { return item.date.date });
					if (ids.indexOf(currentValue.date.date) < 0) {
						uniqList.push(currentValue);
					}
					return uniqList;
				}, state.list);
			} else {
				uniqueList = action.responseData;
			}

			return { ...state, loading: false, success: true, list: uniqueList, error: undefined };
		}
		case myCalendarConstants.GET_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case myCalendarConstants.EDIT_CLEAR:
			return { ...state, edit: { ...myCalendarEditInitialState }};
		case myCalendarConstants.EDIT_REQUEST:
			return { ...state, edit: { ...myCalendarEditInitialState, loading: true }};
		case myCalendarConstants.EDIT_SUCCESS:
			return { ...state, edit: { ...myCalendarEditInitialState, success: true }};
		case myCalendarConstants.EDIT_FAILURE:
			return { ...state, edit: { ...myCalendarEditInitialState, error: action.error }};

		case myCalendarConstants.GET_ORDERS_CLEAR:
			return { ...state, orders: { ...myCalendarOrdersInitialState }};
		case myCalendarConstants.GET_ORDERS_REQUEST:
			return { ...state, orders: { ...myCalendarOrdersInitialState, loading: true }};
		case myCalendarConstants.GET_ORDERS_SUCCESS:
			return { ...state, orders: { ...myCalendarOrdersInitialState, list: action.responseData }};
		case myCalendarConstants.GET_ORDERS_FAILURE:
			return { ...state, orders: { ...myCalendarOrdersInitialState, error: action.error }};
		default:
			return state
	}
}