import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { articleActions } from '../../../_actions';
import { Title, Loading } from '../../../_components';
import { Section } from '../../Section';
import { HomeSwiper } from '../../HomeSwiper';

import './Articles.scss';

import { GridItemContent as ArticleGridItem } from '../../../Article';

class Articles extends Component {
	componentDidMount() {
		this.props.dispatch(articleActions.getHotList());
	}

	render() {
		const { t, hot_articles, saved_item_action, auth, dispatch } = this.props;

		return (
			<>
				{hot_articles.loading && <Loading />}
				{hot_articles.error && <Loading error={hot_articles.error} />}
				{hot_articles.list &&
					<Section className="home-section-articles">
						<Row>
							<Col xs={{ size: true }}>
								<Title size="xs" className="section-title">{t('customer.hot_articles')}</Title>
							</Col>
							<Col xs={{ size: 'auto' }}>
								<Link to={`/articles`} className="view-all-articles">{`${t('customer.view_all_articles')} >`}</Link>
							</Col>
						</Row>
						<HomeSwiper
							swiperParams={{
								hideDots: true,
								slidesPerView: 4,
								slidesPerColumn: 2,
								hideNavs: hot_articles.list.length <= 8,
								loop: hot_articles.list.length > 8,
								slidesPerColumnFill: 'row',
								breakpoints: {
									576: {
										slidesPerView: 1,
										slidesPerColumn: 1,
										hideNavs: hot_articles.list.length <= 1,
										loop: hot_articles.list.length > 1,
									},
									768: {
										slidesPerView: 2,
										slidesPerColumn: 1,
										hideNavs: hot_articles.list.length <= 2,
										loop: hot_articles.list.length > 2,
									},
									992: {
										slidesPerView: 2,
										slidesPerColumn: 2,
										hideNavs: hot_articles.list.length <= 4,
										loop: hot_articles.list.length > 4,
									},
									1200: {
										slidesPerView: 3,
										slidesPerColumn: 2,
										hideNavs: hot_articles.list.length <= 6,
										loop: hot_articles.list.length > 6,
									}
								},
							}}>
							{hot_articles.list.map((item, index) =>
								<div key={item.id} className="article">
									<ArticleGridItem
										item={item}
										t={t}
										saved_item_action={saved_item_action}
										auth={auth}
										dispatch={dispatch}
									/>
								</div>
							)}
						</HomeSwiper>
					</Section>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { hot_articles, saved_item_action, auth } = state;
	return {
		hot_articles, saved_item_action, auth
	};
}

const translation = withNamespaces(['home', 'article', 'default'])(Articles);
const connected = connect(mapStateToProps)(translation);
export { connected as Articles };