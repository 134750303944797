import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, appConstants } from '../_constants';

export const appActions = {
	getCurrencies,
	setCurrency,
	getLanguages,
	setLanguage,
	setWindowSize,
	showHideFooter
};

function getCurrencies() {
	const requestOptions = api.requestOption(apiEndpoint.app.currencies);

    return dispatch => {
		dispatch(clear());
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function clear() { return { type: appConstants.GET_CURRENCIES_CLEAR } }
    function request() { return { type: appConstants.GET_CURRENCIES_REQUEST } }
    function success(responseData) { return { type: appConstants.GET_CURRENCIES_SUCCESS, responseData } }
    function failure(error) { return { type: appConstants.GET_CURRENCIES_FAILURE, error } }
}

function setCurrency(currency) {
	analytics.event({
		category: `App Currency: ${currency.name}`,
		action: 'Edit',
		label: 'Customer'
	});

	localStorage.setItem('currency', JSON.stringify(currency));
	return { type: appConstants.SET_CURRENCY, data: currency }
}

function getLanguages() {
	return {
		type: appConstants.GET_LANGUAGES_SUCCESS, responseData: [
			{
				"id": 'en',
				"value": "English",
				"i18n_value": "en"
			},
			{
				"id": 'hk',
				"value": "繁體中文",
				"i18n_value": "zh_HK"
			},
			{
				"id": 'cn',
				"value": "简体中文",
				"i18n_value": "zh_CN"
			}
		]
	}
}

function setLanguage(isVendor, language) {
	analytics.event({
		category: `App Language: ${language.value}`,
		action: 'Edit',
		label: isVendor? 'Vendor' : 'Customer',
	});

	localStorage.setItem('language', JSON.stringify(language));
	return { type: appConstants.SET_LANGUAGE, data: language }
}

function setWindowSize(width, height) {
	return { type: appConstants.SET_WINDOW_SIZE, width: width, height: height };
}

function showHideFooter(show) {
	return { type: appConstants.SHOW_HIDE_FOOTER, show };
}