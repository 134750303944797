export const vendorConstants = {
	GET_LANGUAGE_OPTIONS_REQUEST: 'VENDOR_GET_LANGUAGE_OPTIONS_REQUEST',
    GET_LANGUAGE_OPTIONS_SUCCESS: 'VENDOR_GET_LANGUAGE_OPTIONS_SUCCESS',
	GET_LANGUAGE_OPTIONS_FAILURE: 'VENDOR_GET_LANGUAGE_OPTIONS_FAILURE',
	GET_LANGUAGE_OPTIONS_CLEAR: 'VENDOR_GET_LANGUAGE_OPTIONS_CLEAR',

	GET_CITY_OPTIONS_REQUEST: 'VENDOR_GET_CITY_OPTIONS_REQUEST',
    GET_CITY_OPTIONS_SUCCESS: 'VENDOR_GET_CITY_OPTIONS_SUCCESS',
	GET_CITY_OPTIONS_FAILURE: 'VENDOR_GET_CITY_OPTIONS_FAILURE',
	GET_CITY_OPTIONS_CLEAR: 'VENDOR_GET_CITY_OPTIONS_CLEAR',

	GET_LIST_REQUEST: 'VENDOR_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'VENDOR_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'VENDOR_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'VENDOR_GET_LIST_CLEAR',

	GET_FEATURED_LIST_REQUEST: 'VENDOR_GET_FEATURED_LIST_REQUEST',
    GET_FEATURED_LIST_SUCCESS: 'VENDOR_GET_FEATURED_LIST_SUCCESS',
	GET_FEATURED_LIST_FAILURE: 'VENDOR_GET_FEATURED_LIST_FAILURE',
	GET_FEATURED_LIST_CLEAR: 'VENDOR_GET_FEATURED_LIST_CLEAR',
}