import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownMenu, Container } from 'reactstrap';

import { Loading } from '../../../../_components';

import './MegaMenu.scss';

export const MegaMenuType = {
	destinations: 'destinations',
	categories: 'categories',
	search: 'search'
}

export class MegaMenu extends Component {
	render() {
		const { type, loading, all, featured } = this.props;

		if (all) {
			all.forEach((item, index) => {
				switch (type) {
					case MegaMenuType.destinations:
						item.url = `/packages/destination/${item.id}`;
						break;
					case MegaMenuType.categories:
						item.url = `/packages/category/${item.id}`;
						break;
					case MegaMenuType.search:
						item.url = `/search/${item.search_type}`;
						break;
				
					default:
						break;
				}
		});
	}

		return (
			<DropdownMenu className="nav-megamenu">
				<Container>
					{loading ?
						<Loading></Loading>
						:
						<div className="d-xl-flex">
							{all.map((item, index) =>
								<Link key={item.id} to={item.url} className="nav-megamenu-item">
									<div className="img-wrapper">
										<img src={item.thumbnail_url} alt={item.value} />
									</div>
									<span>{item.value}</span>
								</Link>
							)}
						</div>
					}
				</Container>
			</DropdownMenu>
		);
	}
}

MegaMenu.propTypes = {
	type: PropTypes.oneOf(Object.keys(MegaMenuType))
}