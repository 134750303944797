import React, { Component } from 'react';
import Moment from 'moment';

import { ProfilePicImg, Title, Desc } from '../../../_components';
import {
	sbGetChannelInfo as sb_getChannelInfo,
	CONNECTION_STATUS_ONLINE
} from '../../../_sendbird';

import './Header.scss';

export class Header extends Component {
	render() {
		const { t, channel, chat } = this.props;

		const channelInfo = channel? sb_getChannelInfo(channel) : undefined;
		let typingMembers = (channel && chat)? chat.typing : [];

		const isOnline = channelInfo.connectionStatus === CONNECTION_STATUS_ONLINE;

		return (
			<div id="chat_area_header">
				{channelInfo ?
					<>
						<ProfilePicImg src={channelInfo.imageUrl} />
						<div className="name-status">
						<Title size="sm" className="name">{channelInfo.title}</Title>
						{typingMembers.length > 0 ?
							<Desc className="typing">
								{typingMembers.map((sb_user) => sb_user.nickname).join(', ') + ' ' + (typingMembers.length > 1 ? t('are_typing') : t('is_typing'))}
							</Desc>
							:
							channelInfo.lastSeenAt !== -1 ?
								<Desc className={`online-status ${isOnline? 'online' : ''}`}>
									{isOnline ?
										t('online')
										:
										channelInfo.lastSeenAt > 0 ? t('online_n_ago', { n: Moment(channelInfo.lastSeenAt).fromNow(true) }) : null
									}
								</Desc>
								:
								null
						}
						</div>
					</>
					:
					null
				}
			</div>
		);
	}
}