import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import { PageContainer } from '../../_components';

import './Register.scss';
import { InputForm } from './InputForm';
import { Success } from './Success';

export const RegisterUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class Register extends Component {
	componentDidMount() {
		this.props.dispatch(userActions.forceLogout(false));
		this.props.dispatch(userActions.registerClear());
	}
	
	onSubmit(values, { setSubmitting }) {
		this.props.dispatch(userActions.register(values));
	}

	render() {
		const { register, userType } = this.props;

		return (
			<PageContainer id="user_register">
				<div className="form-container">
					{register.success ?
						<Success
							userType={userType}
						/>
						:
						<InputForm
							userType={userType}
							onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
							submitting={register.loading}
						/>
					}
				</div>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { auth, register } = state;
    return {
		auth,
		register,
    };
}

const connected = connect(mapStateToProps)(Register);
export { connected as Register };