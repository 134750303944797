import { userConstants } from '../_constants';
import { auth as authHelper } from '../_helpers';

const logoutState = { loading: false, loggedIn: false };
let response = JSON.parse(localStorage.getItem('loginData'));
const initialState = response ? { loggedIn: true, user: response.user } : logoutState;

export function auth(state = initialState, action) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				...logoutState,
				loading: true,
				user: action.user
			};
		case userConstants.LOGIN_SUCCESS:
		case userConstants.REGISTER_SUCCESS:
		case userConstants.EDIT_PROFILE_SUCCESS:
		case userConstants.EDIT_EMAIL_BY_CODE_SUCCESS:
		case userConstants.GET_USER_SUCCESS:
			return {
				...logoutState,
				loggedIn: true,
				user: action.responseData.user
			};
		case userConstants.LOGIN_FAILURE:
			return { 
				...logoutState,
			};
		case userConstants.LOGOUT_SUCCESS:
			authHelper.logout();
			return {
				...logoutState,
				redirect: action.redirect,
				redirectPath: action.redirectPath
			};
		default:
			return state
	}
}
