export const vendorPackageConstants = {
	GET_FORM_INFO_REQUEST: 'VENDOR_PACKAGE_GET_FORM_INFO_REQUEST',
    GET_FORM_INFO_SUCCESS: 'VENDOR_PACKAGE_GET_FORM_INFO_SUCCESS',
	GET_FORM_INFO_FAILURE: 'VENDOR_PACKAGE_GET_FORM_INFO_FAILURE',
	GET_FORM_INFO_CLEAR: 'VENDOR_PACKAGE_GET_FORM_INFO_CLEAR',

	GET_REVENUE_REQUEST: 'VENDOR_PACKAGE_GET_REVENUE_REQUEST',
    GET_REVENUE_SUCCESS: 'VENDOR_PACKAGE_GET_REVENUE_SUCCESS',
	GET_REVENUE_FAILURE: 'VENDOR_PACKAGE_GET_REVENUE_FAILURE',
	GET_REVENUE_CLEAR: 'VENDOR_PACKAGE_GET_REVENUE_CLEAR',

	SAVE_REQUEST: 'VENDOR_PACKAGE_SAVE_REQUEST',
    SAVE_SUCCESS: 'VENDOR_PACKAGE_SAVE_SUCCESS',
	SAVE_FAILURE: 'VENDOR_PACKAGE_SAVE_FAILURE',
	SAVE_CLEAR: 'VENDOR_PACKAGE_SAVE_CLEAR',

	EDIT_REQUEST: 'VENDOR_PACKAGE_EDIT_REQUEST',
    EDIT_SUCCESS: 'VENDOR_PACKAGE_EDIT_SUCCESS',
	EDIT_FAILURE: 'VENDOR_PACKAGE_EDIT_FAILURE',
	EDIT_CLEAR: 'VENDOR_PACKAGE_EDIT_CLEAR',

	GET_REQUEST: 'VENDOR_PACKAGE_GET_REQUEST',
    GET_SUCCESS: 'VENDOR_PACKAGE_GET_SUCCESS',
	GET_FAILURE: 'VENDOR_PACKAGE_GET_FAILURE',
	GET_CLEAR: 'VENDOR_PACKAGE_GET_CLEAR',

	GET_LOCATIONS_REQUEST: 'VENDOR_PACKAGE_GET_LOCATIONS_REQUEST',
    GET_LOCATIONS_SUCCESS: 'VENDOR_PACKAGE_GET_LOCATIONS_SUCCESS',
	GET_LOCATIONS_FAILURE: 'VENDOR_PACKAGE_GET_LOCATIONS_FAILURE',
	GET_LOCATIONS_CLEAR: 'VENDOR_PACKAGE_GET_LOCATIONS_CLEAR',

	PUBLISH_REQUEST: 'VENDOR_PACKAGE_PUBLISH_REQUEST',
    PUBLISH_SUCCESS: 'VENDOR_PACKAGE_PUBLISH_SUCCESS',
	PUBLISH_FAILURE: 'VENDOR_PACKAGE_PUBLISH_FAILURE',
	PUBLISH_CLEAR: 'VENDOR_PACKAGE_PUBLISH_CLEAR',

	GET_DRAFTS_REQUEST: 'VENDOR_PACKAGE_GET_DRAFTS_REQUEST',
    GET_DRAFTS_SUCCESS: 'VENDOR_PACKAGE_GET_DRAFTS_SUCCESS',
	GET_DRAFTS_FAILURE: 'VENDOR_PACKAGE_GET_DRAFTS_FAILURE',
	GET_DRAFTS_CLEAR: 'VENDOR_PACKAGE_GET_DRAFTS_CLEAR',

	GET_LIST_REQUEST: 'VENDOR_PACKAGE_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'VENDOR_PACKAGE_GET_LIST_SUCCESS',
	GET_LIST_FAILURE: 'VENDOR_PACKAGE_GET_LIST_FAILURE',
	GET_LIST_CLEAR: 'VENDOR_PACKAGE_GET_LIST_CLEAR',

	REMOVE_REQUEST: 'VENDOR_PACKAGE_REMOVE_REQUEST',
    REMOVE_SUCCESS: 'VENDOR_PACKAGE_REMOVE_SUCCESS',
	REMOVE_FAILURE: 'VENDOR_PACKAGE_REMOVE_FAILURE',
	REMOVE_CLEAR: 'VENDOR_PACKAGE_REMOVE_CLEAR',

	GET_COUNT_REQUEST: 'VENDOR_PACKAGE_GET_COUNT_REQUEST',
    GET_COUNT_SUCCESS: 'VENDOR_PACKAGE_GET_COUNT_SUCCESS',
	GET_COUNT_FAILURE: 'VENDOR_PACKAGE_GET_COUNT_FAILURE',
	GET_COUNT_CLEAR: 'VENDOR_PACKAGE_GET_COUNT_CLEAR',

	GET_DRAFTS_COUNT_REQUEST: 'VENDOR_DRAFTS_GET_COUNT_REQUEST',
    GET_DRAFTS_COUNT_SUCCESS: 'VENDOR_DRAFTS_GET_COUNT_SUCCESS',
	GET_DRAFTS_COUNT_FAILURE: 'VENDOR_DRAFTS_GET_COUNT_FAILURE',
	GET_DRAFTS_COUNT_CLEAR: 'VENDOR_DRAFTS_GET_COUNT_CLEAR',
}