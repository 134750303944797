import { vendorConstants, savedItemsConstants, userConstants } from '../_constants';

const vendorProfileOptionsOptionsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

const vendorProfileOptionsInitialState = {
	cities: vendorProfileOptionsOptionsInitialState,
	languages: vendorProfileOptionsOptionsInitialState
}

export function vendor_profile_options(state = vendorProfileOptionsInitialState, action) {
	switch (action.type) {
		case vendorConstants.GET_LANGUAGE_OPTIONS_CLEAR:
			return { ...state, languages: vendorProfileOptionsOptionsInitialState };
		case vendorConstants.GET_LANGUAGE_OPTIONS_REQUEST:
			return {
				...state,
				languages: {
					...vendorProfileOptionsOptionsInitialState,
					loading: true
				}
			};
		case vendorConstants.GET_LANGUAGE_OPTIONS_SUCCESS:
			return {
				...state,
				languages: {
					...vendorProfileOptionsOptionsInitialState,
					success: true,
					list: action.responseData.languages
				}
			};
		case vendorConstants.GET_LANGUAGE_OPTIONS_FAILURE:
			return {
				...state,
				languages: {
					...vendorProfileOptionsOptionsInitialState,
					error: action.error
				}
			};

		case vendorConstants.GET_CITY_OPTIONS_CLEAR:
			return { ...state, cities: vendorProfileOptionsOptionsInitialState };
		case vendorConstants.GET_CITY_OPTIONS_REQUEST:
			return {
				...state,
				cities: {
					...vendorProfileOptionsOptionsInitialState,
					loading: true
				}
			};
		case vendorConstants.GET_CITY_OPTIONS_SUCCESS:
			return {
				...state,
				cities: {
					...vendorProfileOptionsOptionsInitialState,
					success: true,
					list: action.responseData.cities
				}
			};
		case vendorConstants.GET_CITY_OPTIONS_FAILURE:
			return {
				...state,
				cities: {
					...vendorProfileOptionsOptionsInitialState,
					error: action.error
				}
			};

		default:
			return state
	}
}

const vendorsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

export function vendors(state = vendorsInitialState, action) {
	switch (action.type) {
		case vendorConstants.GET_LIST_CLEAR:
			return { ...vendorsInitialState };
		case vendorConstants.GET_LIST_REQUEST:
			return { ...state, loading: true, success: false, error: undefined };
		case vendorConstants.GET_LIST_SUCCESS:
			let list = state.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.users;
			} else {
				list = list.concat(action.responseData.users);
			}
			return { ...state,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined
			};
		case vendorConstants.GET_LIST_FAILURE:
			return { ...state, loading: false, success: false, error: action.error };

		case savedItemsConstants.ADD_VENDOR_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			
			return state;
		}
		case savedItemsConstants.REMOVE_VENDOR_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = false;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		case userConstants.LOGOUT_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					item.is_bookmarked = false;
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}

		default:
			return state
	}
}

const vendorsFeaturedListInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
}

export function featured_vendors(state = vendorsFeaturedListInitialState, action) {
	switch (action.type) {
		case vendorConstants.GET_FEATURED_LIST_CLEAR:
			return { ...vendorsFeaturedListInitialState };
		case vendorConstants.GET_FEATURED_LIST_REQUEST:
			return { ...vendorsFeaturedListInitialState, loading: true };
		case vendorConstants.GET_FEATURED_LIST_SUCCESS:
			return { ...vendorsFeaturedListInitialState, loading: false, success: true, list: action.responseData, };
		case vendorConstants.GET_FEATURED_LIST_FAILURE:
			return {...vendorsFeaturedListInitialState, loading: false, error: action.error };

		case savedItemsConstants.ADD_VENDOR_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = true;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			
			return state;
		}
		case savedItemsConstants.REMOVE_VENDOR_SUCCESS: {
			if (state.list) {
				const updatedList = state.list.map((item) => {
					if (item.id == action.id) {
						item.is_bookmarked = false;
					}
					return item;
				})
				return { ...state, list: updatedList };
			}
			return state;
		}
		
		default:
			return state
	}
}
