import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';

import { ActionModal } from '../../../../_components';

import { orderActions } from '../../../../_actions';
import './MakeBookingModal.scss';

import { InputForm } from './InputForm';
import { Success } from './Success';


class MakeBookingModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.sendRequestClear());
	}

	onSubmit(values, { setSubmitting }) {
		const { data } = this.props;
		const params = { ...values, id: data.id };
		this.props.dispatch(orderActions.sendRequest(false, params));
	}

	render() {
		const { data, selectedDate, order, isOpen } = this.props;

		return (
			<ActionModal
				id="package_details_makebooking_modal"
				confirmComponent={
					<InputForm
						data={data}
						selectedDate={selectedDate}
						submitting={order.make_booking.loading}
						onSubmit={(values, { setSubmitting }) => this.onSubmit(values, { setSubmitting })}
						toggle={() => this.props.toggle()}
					/>
				}
				successComponent={
					<Success toggle={() => this.props.toggle()} />
				}
				isLoading={order.make_booking.loading}
				isSuccess={order.make_booking.success}
				error={order.make_booking.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.sendRequestClear());
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
    return {
		order
    };
}

const connected = connect(mapStateToProps)(MakeBookingModal);
export { connected as MakeBookingModal };