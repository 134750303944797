import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col } from 'reactstrap';

import { userActions } from '../../../_actions';

import { InputField, OTPButton, OTPButtonType, InputSelect, InputItem, Title } from '../../../_components';

import './InputForm.scss';

export const RegisterInputFormUserType = {
	customer: 'customer',
	vendor: 'vendor',
}

class InputForm extends Component {
	render() {
		const { t, userType, submitting } = this.props;

		const schema = Yup.object().shape({
			user_type: Yup.number()
				.required(t('required')),
			email: Yup.string()
				.email(t('email_format_error'))
				.required(t('required'))
				.test('email', t('empty_role_error'), function (value) {
					const { user_type } = this.parent;
					if (!user_type) return false;
		
					return true;
				})
				.test('email', t('email_unique_error'), function (value) {
					if (!value) return false;

					const { user_type } = this.parent;
		
					return userActions.checkEmailAPI(user_type, value)
						.then(response => {
							return true;
						})
						.catch(error => {
							return false;
						});
				}),
			email_code: Yup.string()
				.required(t('required')),
			password: Yup.string()
				.matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, t('password_remarks'))
				.required(t('required')),
			password_confirmation: Yup.string()
				.oneOf([Yup.ref('password')], t('password_confirmation_not_match'))
				.required(t('required')),
			nickname: Yup.string()
				.required(t('required')),
		});

		let loginLinkTo = (userType === RegisterInputFormUserType.vendor)? '/vendor/login' : '/customer/login';

		return (
			<Container id="user_register_form">
				<Formik
					enableReinitialize
					initialValues={{
						user_type: (userType === RegisterInputFormUserType.vendor? 0 : 1),
						email: '',
						email_code: '',
						password: '',
						password_confirmation: '',
						nickname: ''
					}}
					validationSchema={schema}
					onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
				>
					{({ values,
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setFieldTouched
						/* and other goodies */
					}) => (
							<Form>
								<Title>
									{t('title')}
								</Title>
								{userType === RegisterInputFormUserType.vendor &&
									<InputItem>
										<InputSelect
											name="user_type" t={t}
											options={[{ id: 2, value: t('vendor.photographer') }, { id: 3, value: t('vendor.agency') }]}
											onChange={(name, value) => {
												if (value !== values.user_type) {
													//role did change
													setFieldValue('email_code', '');
													setFieldTouched('email_code', false);
												}
											}}
											isSearchable={false}
										/>
										<div className="remarks role-remarks">{t('role_remarks')}</div>
									</InputItem>
								}
								<InputItem>
									<InputField type="email" name="email" t={t} />
								</InputItem>
								<InputItem>
									<Row className="row-m-8">
										<Col md={{ size: true }}>
											<InputField type="text" name="email_code" t={t} />
										</Col>
										<Col md={{ size: 'auto' }}>
											<OTPButton
												type={OTPButtonType.register}
												disabled={!values.user_type || !values.email.length || errors.user_type != null || errors.email != null}
												email={values.email}
												user_type={values.user_type}
											/>
										</Col>
									</Row>
									<div className="remarks">{t('email_code_remarks')}</div>
								</InputItem>
								<InputItem>
									<InputField type="password" name="password" t={t} />
									<div className="remarks">{t('password_remarks')}</div>
								</InputItem>
								<InputItem>
									<InputField type="password" name="password_confirmation" t={t} />
								</InputItem>
								<InputItem>
									<InputField type="text" name="nickname" t={t} />
								</InputItem>
								<InputItem className="policy" dangerouslySetInnerHTML={{ __html: t('policy') }}></InputItem>
								<InputItem>
									<Button
										className="btn-submit"
										type="submit"
										disabled={submitting}
										onClick={handleSubmit}>
										{t('sign_up')}
									</Button>
								</InputItem>
								<InputItem className="login">{t('already_have_account')} <Link to={loginLinkTo}>{t('login')}</Link></InputItem>
							</Form>
						)}
				</Formik>
			</Container>
		);
	}
}

const translation = withNamespaces(['register', 'default'])(InputForm);
export { translation as InputForm };