import axios from 'axios';

import { auth, api, analytics } from '../_helpers';
import { apiEndpoint, userConstants, userType } from '../_constants';
import { alertActions } from './';

export const userActions = {
	checkEmail,
	checkEmailClear,
	checkEmailAPI,

	registerSendCode,
	registerSendCodeClear,
	register,
	registerClear,

	login,
	loginClear,
	logout,
	forceLogout,

	resetPasswordSendCode,
	resetPasswordSendCodeClear,
	resetPasswordVerifyCode,
	resetPasswordVerifyCodeClear,
	resetPasswordByCode,
	resetPasswordByCodeClear,

	editProfile,
	editProfileClear,

	editEmailSendCode,
	editEmailSendCodeClear,
	editEmailByCode,
	editEmailByCodeClear,

	editPassword,
	editPasswordClear,

	getUser,
	getUserClear,
};

function checkEmail(user_type, email) {
	const requestOptions = api.requestOption(apiEndpoint.user.checkEmail, { user_type, email });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function request() { return { type: userConstants.CHECK_EMAIL_REQUEST } }
	function success(responseData) { return { type: userConstants.CHECK_EMAIL_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.CHECK_EMAIL_FAILURE, error } }
}

function checkEmailClear() {
	return { type: userConstants.CHECK_EMAIL_CLEAR }
}

function checkEmailAPI(user_type, email) {
	const requestOptions = api.requestOption(apiEndpoint.user.checkEmail, { user_type, email });

	return axios.request(requestOptions)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
}

function registerSendCode(user_type, email) {
	analytics.event({
		category: 'Register Send Code',
		action: 'Submit',
		label: user_type === userType.customer? 'Customer' : 'Vendor'
	});
	
	const requestOptions = api.requestOption(apiEndpoint.user.sendCode, { user_type, email });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: userConstants.SEND_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.SEND_CODE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.SEND_CODE_FAILURE, error } }
}

function registerSendCodeClear() {
	return { type: userConstants.SEND_CODE_CLEAR }
}

function register(data) {
	analytics.event({
		category: 'Register',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.register, data);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				auth.login(response.data.data);
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
				dispatch(alertActions.error(error.response.data.message));
			});
    };

	function request() { return { type: userConstants.REGISTER_REQUEST } }
	function success(responseData) { return { type: userConstants.REGISTER_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function registerClear() {
	return { type: userConstants.REGISTER_CLEAR }
}

function login(data) {
	analytics.event({
		category: 'Login',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.login, data);

    return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				auth.login(response.data.data);
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function request() { return { type: userConstants.LOGIN_REQUEST } }
	function success(responseData) { return { type: userConstants.LOGIN_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function loginClear() {
	return { type: userConstants.LOGIN_CLEAR }
}

function logout(user_type) {
	analytics.event({
		category: 'Logout',
		action: 'Submit',
		label: user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.logout);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function request() { return { type: userConstants.LOGOUT_REQUEST } }
	function success(responseData) { return { type: userConstants.LOGOUT_SUCCESS, responseData, redirectPath: (user_type === userType.customer? '/' : '/vendor') } }
	function failure(error) { return { type: userConstants.LOGOUT_FAILURE, error } }
}

function forceLogout(redirect = true ,redirectPath = '/') {
    return { type: userConstants.LOGOUT_SUCCESS, redirect: redirect, redirectPath: redirectPath };
}

function resetPasswordSendCode(user_type, email) {
	analytics.event({
		category: 'Reset Password Send Code',
		action: 'Submit',
		label: user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.reset_password.send_code, { user_type, email });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function request() { return { type: userConstants.RESETPASSWORD_SEND_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.RESETPASSWORD_SEND_CODE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.RESETPASSWORD_SEND_CODE_FAILURE, error } }
}

function resetPasswordSendCodeClear() {
	return { type: userConstants.RESETPASSWORD_SEND_CODE_CLEAR }
}

function resetPasswordVerifyCode(data) {
	analytics.event({
		category: 'Reset Password Verify Code',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.reset_password.verify_code, data);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };
	
	function request() { return { type: userConstants.RESETPASSWORD_VERIFY_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.RESETPASSWORD_VERIFY_CODE_SUCCESS, responseData, data } }
	function failure(error) { return { type: userConstants.RESETPASSWORD_VERIFY_CODE_FAILURE, error } }
}

function resetPasswordVerifyCodeClear() {
	return { type: userConstants.RESETPASSWORD_VERIFY_CODE_CLEAR }
}

function resetPasswordByCode(data) {
	analytics.event({
		category: 'Reset Password By Code',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.reset_password.edit_by_code, data);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };
	
	function request() { return { type: userConstants.RESETPASSWORD_BY_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.RESETPASSWORD_BY_CODE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.RESETPASSWORD_BY_CODE_FAILURE, error } }
}

function resetPasswordByCodeClear() {
	return { type: userConstants.RESETPASSWORD_BY_CODE_CLEAR }
}

function editProfile(user_type, data) {
	analytics.event({
		category: 'My Profile',
		action: 'Edit',
		label: user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.edit_profile, data);

    return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				auth.updateUserInfo(response.data.data.user);
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };
	
	function request() { return { type: userConstants.EDIT_PROFILE_REQUEST } }
	function success(responseData) { return { type: userConstants.EDIT_PROFILE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.EDIT_PROFILE_FAILURE, error } }
}

function editProfileClear() {
	return { type: userConstants.EDIT_PROFILE_CLEAR }
}

function editEmailSendCode(user_type, email) {
	analytics.event({
		category: 'My Profile Edit Email Send Code',
		action: 'Submit',
		label: user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.edit_email.send_code, { email });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

	function request() { return { type: userConstants.EDIT_EMAIL_SEND_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.EDIT_EMAIL_SEND_CODE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.EDIT_EMAIL_SEND_CODE_FAILURE, error } }
}

function editEmailSendCodeClear() {
	return { type: userConstants.EDIT_EMAIL_SEND_CODE_CLEAR }
}

function editEmailByCode(data) {
	analytics.event({
		category: 'My Profile Edit Email By Code',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.edit_email.edit_by_code, data);

    return dispatch => {
		dispatch(request());
		
		axios.request(requestOptions)
			.then(response => {
				auth.updateUserInfo(response.data.data.user);
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };
	
	function request() { return { type: userConstants.EDIT_EMAIL_BY_CODE_REQUEST } }
	function success(responseData) { return { type: userConstants.EDIT_EMAIL_BY_CODE_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.EDIT_EMAIL_BY_CODE_FAILURE, error } }
}

function editEmailByCodeClear() {
	return { type: userConstants.EDIT_EMAIL_BY_CODE_CLEAR }
}

function editPassword(data) {
	analytics.event({
		category: 'My Profile Edit Password',
		action: 'Submit',
		label: data.user_type === userType.customer? 'Customer' : 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.user.edit_password, data);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: userConstants.EDIT_PASSWORD_REQUEST } }
	function success(responseData) { return { type: userConstants.EDIT_PASSWORD_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.EDIT_PASSWORD_FAILURE, error } }
}

function editPasswordClear() {
	return { type: userConstants.EDIT_PASSWORD_CLEAR }
}

function getUser() {
	const requestOptions = api.requestOption(apiEndpoint.user.get);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				auth.updateUserInfo(response.data.data.user);
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: userConstants.GET_USER_REQUEST } }
	function success(responseData) { return { type: userConstants.GET_USER_SUCCESS, responseData } }
	function failure(error) { return { type: userConstants.GET_USER_FAILURE, error } }
}

function getUserClear() {
	return { type: userConstants.GET_USER_CLEAR }
}