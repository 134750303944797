import { 
	INIT_LOGIN,
	LOGIN_REQUEST,
    LOGIN_SUCCESS, 
	LOGIN_FAIL,
	DISCONNECT_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	success: false,
    error: '',
	user: null,
	isLoading: false
}

export function login (state = INITIAL_STATE, action) {
    switch (action.type) {
		case INIT_LOGIN:
		case DISCONNECT_SUCCESS:
			return { ...state, ...INITIAL_STATE };
		case LOGIN_REQUEST:
            return { ...state, ...INITIAL_STATE, isLoading: true };
        case LOGIN_SUCCESS: 
            return { ...state, ...INITIAL_STATE, success: true, user: action.payload };
        case LOGIN_FAIL:
            return { ...state, ...INITIAL_STATE, error: action.payload };
        default: 
            return state;
    }
};
