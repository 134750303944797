import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { myCalendarActions } from '../../../_actions';
import { Loading, Title } from '../../../_components';

import './Orders.scss';

import { Order } from './Order';

class Orders extends Component {
	componentDidMount() {
		const { startDate, endDate } = this.props;

		if (startDate && endDate) {
			this.props.dispatch(myCalendarActions.getOrders(startDate, endDate));
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { startDate, endDate } = this.props;

		if ((startDate && startDate !== prevProps.startDate) ||
			(endDate && endDate !== prevProps.endDate)) {
			this.props.dispatch(myCalendarActions.getOrders(startDate, endDate));
		}
	}
	
	render() {
		const { t, my_calendar } = this.props;

		return (
			<div id="vendor_mycalendar_confirmed_orders">
				<Title size="md">{t('confirmed_order') + ((my_calendar.orders.list && my_calendar.orders.list.length > 0)? ` (${my_calendar.orders.list.length})` : '')}</Title>
				{my_calendar.orders.loading && <Loading />}
				{my_calendar.orders.error && <Loading error={my_calendar.orders.error} />}
				{my_calendar.orders.list && !my_calendar.orders.list.length && <div className="no-result">{t('no_confirmed_orders')}</div>}
				{my_calendar.orders.list && my_calendar.orders.list.length > 0 &&
					<div className="list">
						{my_calendar.orders.list.map((item, index) =>
							<Order key={item.id} data={item} />
						)}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { my_calendar } = state;
	return {
		my_calendar
	};
}

const translation = withNamespaces(['mycalendar'])(Orders);
const connected = connect(mapStateToProps)(translation);
export { connected as Orders };