import { 
    INIT_INVITE,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    CREATE_GROUP_CHANNEL_SUCCESS,
    CREATE_GROUP_CHANNEL_FAIL,
    INVITE_GROUP_CHANNEL_SUCCESS,
	INVITE_GROUP_CHANNEL_FAIL,
	RECONNECTION_START,
	RECONNECTION_SUCCESS,
	RECONNECTION_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    list: [],
    channel: null
}

export function groupChannelInvite (state = INITIAL_STATE, action) {
    switch(action.type) {
        case INIT_INVITE:
            return { ...state, ...INITIAL_STATE };
        case USER_LIST_SUCCESS:
            return { ...state, list: action.list };
        case USER_LIST_FAIL:
            return { ...state, list: [] };
        case CREATE_GROUP_CHANNEL_SUCCESS: 
            return { ...state, channel: action.channel };
        case CREATE_GROUP_CHANNEL_FAIL:
            return { ...state, channel: null };
        case INVITE_GROUP_CHANNEL_SUCCESS:
            return { ...state, channel: action.channel };
        case INVITE_GROUP_CHANNEL_FAIL:
			return { ...state, channel: null };
		case RECONNECTION_START:
		case RECONNECTION_SUCCESS:
		case RECONNECTION_FAIL:
			return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}
