import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { savedItemsActions } from '../../../../_actions';
import { savedItemType } from '../../../../_constants';
import { ResultsGrid } from '../../../../_components';
import './Articles.scss';

import { GridItem } from '../../../../Article';

class Articles extends Component {
	componentDidMount() {
		this.props.dispatch(savedItemsActions.getSavedItemsArticlesClear());
	}

	componentDidUpdate(prevProps, prevState) {
		const { saved_item_action } = this.props;

		let shouldRefreshList = false;
		shouldRefreshList = saved_item_action.type == savedItemType.ARTICLE && saved_item_action.success && saved_item_action.success !== prevProps.saved_item_action.success;

		if (shouldRefreshList) {
			this.infiniteScrollComponent.reload();
		}
	}

	render() {
		const { t, saved_items } = this.props;

		return (
			<div id="customer_profile_saved_items_articles">
				<ResultsGrid
					infiniteScrollComponentRef={(element) => this.infiniteScrollComponent = element}
					showHeader={false}
					noOfCol_lg={3}
					noOfCol_md={2}
					loadMoreProp={saved_items.articles}
					onLoadMore={(nextPage) => {
						this.props.dispatch(savedItemsActions.getSavedItemsArticles(nextPage));
					}}
					renderItem={(item) => {
						return <GridItem item={item.article} />;
					}}
					noResultDesc={t('no_saved_items')}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
    const { saved_items, saved_item_action } = state; 
    return {
		saved_items,
		saved_item_action
    };
}

const translation = withNamespaces(['saveditems'])(Articles);
const connected = connect(mapStateToProps)(translation);
export { connected as Articles };