import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';

import { userType, canReplyOrderPendingItemsByData } from '../../../_constants';
import { Desc } from '../../../_components';

import './PendingItem.scss';
import { ReplyModal } from './ReplyModal';

const PendingItemActionType = {
	none: 'none',
	cancel: 'cancel',
	accept: 'accept',
	reject: 'reject'
}

class PendingItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actionType: PendingItemActionType.none,
			confirmModalIsOpen: false,
			replySuccess: false
		}
	}

	onCancel() {
		this.setState({ confirmModalIsOpen: true, actionType: PendingItemActionType.cancel });
	}

	onReply(accept) {
		this.setState({ confirmModalIsOpen: true, actionType: accept? PendingItemActionType.accept : PendingItemActionType.reject });
	}

	render() {
		const { t, data, order, auth } = this.props;
		const { actionType, confirmModalIsOpen, replySuccess } = this.state;

		const isVendor = auth.user.user_type !== userType.customer;
		const canReply = canReplyOrderPendingItemsByData(order);

		return (
			<>
				<Card className={`order-pendingItems-pendingItem ${replySuccess? 'disabled' : ''}`}>
					<CardHeader>
						<CardTitle tag="h4">
							{t('request_title', { name: t(data.edit_datetime.from_user.id == auth.user.id ? 'you' : data.edit_datetime.from_user.user_type === userType.customer ? 'customer' : 'vendor.vendor') })}
						</CardTitle>
					</CardHeader>
					<CardBody>
						<Row>
							<Col md={{ size: isVendor ? 4 : 3 }} className="bold">
								{t('original_shooting_datetime')}
							</Col>
							<Col md={{ size: isVendor ? 8 : 9 }}>
								{`${Moment.utc(data.edit_datetime.from_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(order.timezone.offset).format('D MMM YYYY h:mma')} ${order.timezone.offset}`}
							</Col>
						</Row>
						<Row>
							<Col md={{ size: isVendor ? 4 : 3 }} className="bold">
								{t('new_shooting_datetime')}
							</Col>
							<Col md={{ size: isVendor ? 8 : 9 }}>
								{`${Moment.utc(data.edit_datetime.to_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(order.timezone.offset).format('D MMM YYYY h:mma')} ${order.timezone.offset}`}
							</Col>
						</Row>
						{data.edit_datetime.remarks &&
							<Desc>
								{data.edit_datetime.remarks}
							</Desc>
						}
					</CardBody>
					{data.edit_datetime.from_user.id == auth.user.id ?
						<CardFooter>
							<Button outline className="btn-cancel-request" onClick={() => this.onCancel()} disabled={!canReply}>{t('cancel_request')}</Button>
						</CardFooter>
						:
						<CardFooter>
							<Row className="row-m-8">
								<Col sm={{ size: 'auto' }}>
									<Button block onClick={() => this.onReply(true)} disabled={!canReply}>{t('accept.accept')}</Button>
								</Col>
								<Col sm={{ size: 'auto' }}>
									<Button color="danger" block onClick={() => this.onReply(false)} disabled={!canReply}>{t('reject.reject')}</Button>
								</Col>
							</Row>
						</CardFooter>
					}
				</Card>
				<ReplyModal
					data={data}
					isVendor={isVendor}
					actionType={actionType}
					isOpen={confirmModalIsOpen}
					toggle={() => this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen })}
					onSuccess={() => {
						this.setState({ replySuccess: true });
					}}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['pendingitem', 'default'])(PendingItem);
const connected = connect(mapStateToProps)(translation);
export { connected as PendingItem };