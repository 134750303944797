import { savedItemsConstants, savedItemActionType, savedItemType, userConstants } from '../_constants';

const savedItemsPackagesInitialState = {
	loading: false,
	list: undefined,
	success: false,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}
const savedItemsArticlesInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}
const savedItemsVendorsInitialState = {
	loading: false,
	success: false,
	list: undefined,
	error: undefined,
	currentPage: 0,
	nextPage: 1,
	total: undefined,
	hasMore: true
}

const savedItemsInitialState = {
	packages: savedItemsPackagesInitialState,
	articles: savedItemsArticlesInitialState,
	vendors: savedItemsVendorsInitialState,
	counts: undefined,
}

export function saved_items(state = savedItemsInitialState, action) {
	switch (action.type) {
	case userConstants.LOGOUT_SUCCESS:
		return { ...savedItemsInitialState };
	case savedItemsConstants.GET_COUNTS_CLEAR:
		return { ...state, counts: undefined };
	case savedItemsConstants.GET_COUNTS_REQUEST:
		return { ...state, counts: undefined };
	case savedItemsConstants.GET_COUNTS_SUCCESS:
		return { ...state, counts: action.responseData };
	case savedItemsConstants.GET_COUNTS_FAILURE:
		return { ...state, counts: undefined };

	case savedItemsConstants.GET_PACKAGES_CLEAR:
		return { ...state, packages: savedItemsPackagesInitialState };
	case savedItemsConstants.GET_PACKAGES_REQUEST:
		return { ...state, packages: { ...state.packages, loading: true, success: false, error: undefined } };
	case savedItemsConstants.GET_PACKAGES_SUCCESS: {
		let list = state.packages.list;
			if (action.responseData.meta.pagination.current_page == 1) {
				list = action.responseData.package;
			} else {
				list = list.concat(action.responseData.package);
			}
			return { ...state,
				packages: {
					...state.packages,
					loading: false,
					success: true,
					list,
					currentPage: action.responseData.meta.pagination.current_page,
					nextPage: action.responseData.meta.pagination.current_page + 1,
					hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
					total: action.responseData.meta.pagination.total,
					error: undefined,
				}
			};
		}
	case savedItemsConstants.GET_PACKAGES_FAILURE:
		return { ...state, packages: { ...state.packages, loading: false, success: false, error: action.error } };
	case savedItemsConstants.REMOVE_PACKAGE_SUCCESS: {
		if (state.packages.list) {
			const updatedList = state.packages.list.filter((item) => item.id !== action.id);

			return { ...state,
				packages: {
					...state.packages,
					list: updatedList
				}
			};
		}
		return state;
	}
	
	case savedItemsConstants.GET_VENDORS_CLEAR:
		return { ...state, vendors: savedItemsVendorsInitialState };
	case savedItemsConstants.GET_VENDORS_REQUEST:
		return { ...state, vendors: { ...state.vendors, loading: true, success: false, error: undefined } };
	case savedItemsConstants.GET_VENDORS_SUCCESS: {
		let list = state.vendors.list;
		if (action.responseData.meta.pagination.current_page == 1) {
			list = action.responseData.vendor;
		} else {
			list = list.concat(action.responseData.vendor);
		}
		return { ...state,
			vendors: {
				...state.vendors,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined,
			}
		};
	}
	case savedItemsConstants.GET_VENDORS_FAILURE:
		return { ...state, vendors: { ...state.vendors, loading: false, success: false, error: action.error } };
	case savedItemsConstants.REMOVE_VENDOR_SUCCESS: {
		if (state.vendors.list) {
			const updatedList = state.vendors.list.filter((item) => item.id !== action.id);

			return { ...state,
				vendors: {
					...state.vendors,
					list: updatedList
				}
			};
		}
		return state;
	}

	case savedItemsConstants.GET_ARTICLES_CLEAR:
		return { ...state, articles: savedItemsArticlesInitialState };
	case savedItemsConstants.GET_ARTICLES_REQUEST:
		return { ...state, articles: { ...state.articles, loading: true, success: false, error: undefined } };
	case savedItemsConstants.GET_ARTICLES_SUCCESS: {
		let list = state.articles.list;
		if (action.responseData.meta.pagination.current_page == 1) {
			list = action.responseData.article;
		} else {
			list = list.concat(action.responseData.article);
		}
		return { ...state,
			articles: {
				...state.articles,
				loading: false,
				success: true,
				list,
				currentPage: action.responseData.meta.pagination.current_page,
				nextPage: action.responseData.meta.pagination.current_page + 1,
				hasMore: action.responseData.meta.pagination.current_page < action.responseData.meta.pagination.total_pages,
				total: action.responseData.meta.pagination.total,
				error: undefined,
			}
		};
	}
	case savedItemsConstants.GET_ARTICLES_FAILURE:
		return { ...state, articles: { ...state.articles, loading: false, success: false, error: action.error } };
	case savedItemsConstants.REMOVE_ARTICLE_SUCCESS: {
		if (state.articles.list) {
			const updatedList = state.articles.list.filter((item) => item.id !== action.id);

			return { ...state,
				articles: {
					...state.articles,
					list: updatedList
				}
			};
		}
		return state;
	}

	default:
		return state
	}
}

const savedItemActionInitialState = {
	type: undefined,
	action: undefined,
	id: undefined,
	loading: false,
	success: false,
	error: undefined
}

export function saved_item_action(state = savedItemActionInitialState, action) {
	switch (action.type) {
	case userConstants.LOGOUT_SUCCESS:
		return { ...savedItemActionInitialState };
	case savedItemsConstants.ADD_PACKAGE_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.ADD_PACKAGE_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.ADD 
		};
	case savedItemsConstants.ADD_PACKAGE_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.ADD,
			success: true
		};
	case savedItemsConstants.ADD_PACKAGE_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.ADD,
			error: action.error
		};

	case savedItemsConstants.REMOVE_PACKAGE_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.REMOVE_PACKAGE_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.REMOVE 
		};
	case savedItemsConstants.REMOVE_PACKAGE_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.REMOVE,
			success: true
		};
	case savedItemsConstants.REMOVE_PACKAGE_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.PACKAGE,
			id: action.id,
			action: savedItemActionType.REMOVE,
			error: action.error
		};

	case savedItemsConstants.ADD_VENDOR_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.ADD_VENDOR_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.ADD 
		};
	case savedItemsConstants.ADD_VENDOR_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.ADD,
			success: true
		};
	case savedItemsConstants.ADD_VENDOR_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.ADD,
			error: action.error
		};

	case savedItemsConstants.REMOVE_VENDOR_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.REMOVE_VENDOR_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.REMOVE 
		};
	case savedItemsConstants.REMOVE_VENDOR_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.REMOVE,
			success: true
		};
	case savedItemsConstants.REMOVE_VENDOR_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.VENDOR,
			id: action.id,
			action: savedItemActionType.REMOVE,
			error: action.error
		};

	case savedItemsConstants.ADD_ARTICLE_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.ADD_ARTICLE_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.ADD 
		};
	case savedItemsConstants.ADD_ARTICLE_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.ADD,
			success: true
		};
	case savedItemsConstants.ADD_ARTICLE_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.ADD,
			error: action.error
		};

	case savedItemsConstants.REMOVE_ARTICLE_CLEAR: 
		return { ...savedItemActionInitialState };
	case savedItemsConstants.REMOVE_ARTICLE_REQUEST:
		return { ...savedItemActionInitialState,
			loading: true,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.REMOVE 
		};
	case savedItemsConstants.REMOVE_ARTICLE_SUCCESS:
		return { ...savedItemActionInitialState,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.REMOVE,
			success: true
		};
	case savedItemsConstants.REMOVE_ARTICLE_FAILURE:
		return { ...savedItemActionInitialState,
			type: savedItemType.ARTICLE,
			id: action.id,
			action: savedItemActionType.REMOVE,
			error: action.error
		};

	default:
		return state
	}
}