import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';

import { headerActions, articleActions, packageActions, vendorActions } from '../_actions';
import { Title, PageContainer } from '../_components';
import { Articles, Destinations, Specials, Vendors } from './Sections';

import './Home.scss';

import img_banner from './img/banner_home.jpg';

class Home extends Component {
	constructor(props) {
		super(props);
		
	}

	componentWillMount() {
		this.props.dispatch(headerActions.solidStyle(false));
	}

	componentDidMount() {
		this.props.dispatch(articleActions.getHotList());
		this.props.dispatch(packageActions.getDestinations());
		this.props.dispatch(vendorActions.search({ id: 1 }, undefined, 1));
	}

	componentWillUnmount() {
		this.props.dispatch(headerActions.solidStyle(true));
	}

	render() {
		const { t } = this.props;

		return (
			<PageContainer fullPage id="home">
				<div className="top-banner top-banner-img">
					<img src={img_banner} alt="Home Banner" />
					<Title>{t('customer.title')}</Title>
				</div>
				<Container className="content-container">
					<Articles />
					<Destinations />
					<Specials />
					<Vendors />
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { 
		// app, vendors, saved_item_action, auth
	 } = state;
	return {
		// app,
		// vendors,
		// saved_item_action,
		// auth
	};
}

const translation = withNamespaces(['home', 'vendor', 'default'])(Home);
const connected = connect(mapStateToProps)(translation);
export { connected as Home };