import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../_components';

import { MyPackages } from './MyPackages';

const MyPackagesTabPathnames = ['packages', 'drafts'];

export class MyPackagesRouter extends Component {
	render() {
		const { match } = this.props;

		return (
			<Switch>
				<PrivateRoute exact path={`${match.path}/:tab(${MyPackagesTabPathnames.join('|')})`} component={MyPackages} />
				<PrivateRoute exact path={`${match.path}`} component={MyPackages} />
				<Redirect to='/error/404' />
			</Switch>
		);
	}
}