import { contactUsConstants } from '../_constants';

const contactUsInitialState = {
	loading: false,
	success: undefined,
	error: undefined,
}
export function contactUs(state = contactUsInitialState, action) {
	switch (action.type) {
		case contactUsConstants.CLEAR:
			return { ...contactUsInitialState };
		case contactUsConstants.REQUEST:
			return { ...contactUsInitialState, loading: true };
		case contactUsConstants.SUCCESS:
			return { ...contactUsInitialState, success: true };
		case contactUsConstants.FAILURE:
			return { ...contactUsInitialState, error: action.error };

		default:
			return state
	}
}