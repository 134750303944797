import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, payoutSettingsConstants } from '../_constants';

export const payoutSettingsActions = {
	getFormInfo,
	getFormInfoClear,
	get,
	getClear,
	edit,
	editClear,
}

function getFormInfo(values) {
	const requestOptions = api.requestOption(apiEndpoint.payout_settings.form_info, values);

	return dispatch => {
		dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: payoutSettingsConstants.GET_FORM_INFO_REQUEST } }
	function success(responseData) { return { type: payoutSettingsConstants.GET_FORM_INFO_SUCCESS, responseData } }
	function failure(error) { return { type: payoutSettingsConstants.GET_FORM_INFO_FAILURE, error } }
}

function getFormInfoClear() {
	return { type: payoutSettingsConstants.GET_FORM_INFO_CLEAR }
}

function get() {
	const requestOptions = api.requestOption(apiEndpoint.payout_settings.get);

	return dispatch => {
		dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: payoutSettingsConstants.GET_REQUEST } }
	function success(responseData) { return { type: payoutSettingsConstants.GET_SUCCESS, responseData } }
	function failure(error) { return { type: payoutSettingsConstants.GET_FAILURE, error } }
}

function getClear() {
	return { type: payoutSettingsConstants.GET_CLEAR }
}

function edit(data) {
	analytics.event({
		category: 'Payout Settings',
		action: 'Edit',
		label: 'Vendor'
	});

	const requestOptions = api.requestOption(apiEndpoint.payout_settings.edit, data);

	return dispatch => {
		dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
	};

	function request() { return { type: payoutSettingsConstants.EDIT_REQUEST} }
	function success(responseData) { return { type: payoutSettingsConstants.EDIT_SUCCESS, responseData } }
	function failure(error) { return { type: payoutSettingsConstants.EDIT_FAILURE, error } }
}

function editClear() {
	return { type: payoutSettingsConstants.EDIT_CLEAR };
}