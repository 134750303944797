import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { app } from '../../../../../../_helpers';
import { Title, BaseModal } from '../../../../../../_components';

import './RevenueInfoModal.scss';

class RevenueInfoModal extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<BaseModal
				id="vendor_order_details_summary_revenue_info"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>
						{t('you_will_receive')}
					</Title>
				</ModalHeader>
				<ModalBody>
					<Container className="amount-breakdown">
						<Row>
							<Col xs={{ size: true }}>
								{t('price')}
							</Col>
							<Col xs={{ size: 'auto' }}>
								{app.toCurrencyString(data.currency.value, data.vendor_total_amount)}
							</Col>
						</Row>
						<Row>
							<Col xs={{ size: true }}>
								{t('service_fee')}
							</Col>
							<Col xs={{ size: 'auto' }} className="minus-value">
								{app.toCurrencyString(data.currency.value, data.vendor_service_fee)}
							</Col>
						</Row>
						<hr />
						<Row className="total">
							<Col xs={{ size: true }}>
								{t('you_will_receive')}
							</Col>
							<Col xs={{ size: 'auto' }}>
								{app.toCurrencyString(data.currency.value, data.revenue)}
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => this.props.toggle()}>{t('ok')}</Button>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['package', 'default'])(RevenueInfoModal);
export { translation as RevenueInfoModal };