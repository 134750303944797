import { userType } from '../_constants';

export const auth = {
	login,
	logout,
	updateUserInfo,
	getLogin,
	isLoggedIn
};

function login(data) {
	localStorage.setItem('loginData', JSON.stringify(data));
}

function logout() {
    // remove user from local storage to log user out
	localStorage.removeItem('loginData');
}

function updateUserInfo(user) {
	let storedResponse = auth.getLogin();
	storedResponse['user'] = user;
	localStorage.setItem('loginData', JSON.stringify(storedResponse));
}

function getLogin() {
	return JSON.parse(localStorage.getItem('loginData'));
}

function isLoggedIn(isVendor, user) {
	return user && ((!isVendor && user.user_type === userType.customer) || (isVendor && (user.user_type === userType.photographer || user.user_type === userType.agency)));
}