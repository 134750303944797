import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import './VendorRemarks.scss';

class VendorRemarks extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<Card id="customer_order_details_card_vendor_remarks">
				<CardHeader>
					<CardTitle tag="h4">
						{t('vendor_remarks')}
					</CardTitle>
				</CardHeader>
				<CardBody>
					{data.remarks}
				</CardBody>
			</Card>
		);
	}
}

const translation = withNamespaces(['order'])(VendorRemarks);
export { translation as VendorRemarks };