import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { orderActions } from '../../../_actions';
import { ActionModal, Title, Desc } from '../../../_components';

import './CompleteBookingModal.scss';
import { ReviewModal } from '../ReviewModal';

class CompleteBookingModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reviewModalIsOpen: false
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.completeClear());
	}

	onReview() {
		this.setState({ reviewModalIsOpen: true });
	}

	render() {
		const { t, isOpen, order, order_id } = this.props;
		const { reviewModalIsOpen } = this.state;

		return (
			<div>
				<ActionModal
					id="customer_order_complete_modal"
					confirmTitle={t('complete_order.confirm.title')}
					confirmDesc={t('complete_order.confirm.desc')}
					successComponent={
						<div id="customer_order_complete_modal_success" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('complete_order.success.title')}</Title>
							</ModalHeader>
							<ModalBody>
								<Desc>{t('complete_order.success.desc')}</Desc>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button onClick={() => {
												this.onReview();
											}}>{t('leave_review')}</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button outline onClick={() => this.props.toggle()}>{t('later')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
						</div>
					}
					isLoading={order.complete.loading}
					isSuccess={order.complete.success}
					error={order.complete.error}
					isOpen={isOpen}
					onClosed={() => {
						this.props.dispatch(orderActions.completeClear());
					}}
					toggle={() => this.props.toggle()}
					onSubmit={() => {
						this.props.dispatch(orderActions.complete(false, order_id));
					}}
				/>
				<ReviewModal
					isOpen={reviewModalIsOpen}
					order_id={order_id}
					data={undefined}
					toggle={() => this.setState({ reviewModalIsOpen: !this.state.reviewModalIsOpen })}
					onClosed={() => {
						this.props.toggle();
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order', 'review', 'default'])(CompleteBookingModal);
const connected = connect(mapStateToProps)(translation);
export { connected as CompleteBookingModal };