import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Register, RegisterUserType } from '../../User';

import './RegisterWrapper.scss';

class RegisterWrapper extends Component {
	render() {
		return (
			<Register
				userType={RegisterUserType.vendor}
				className='vendor-register'
			/>
		);
	}
}

const connected = connect()(RegisterWrapper);
export { connected as RegisterWrapper };