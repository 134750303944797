import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderActions } from '../../../_actions';
import { ActionModal } from '../../../_components';

import './ReviewModal.scss';

import { InputForm } from './InputForm';

class ReviewModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.editReviewClear());
	}

	onSubmit(values, formikBag) {
		this.props.dispatch(orderActions.editReview(false, this.props.order_id, values));
	}

	render() {
		const { t, isOpen, order, data } = this.props;

		return (
			<ActionModal
				id="customer_order_review_modal"
				confirmComponent={
					<InputForm
					data={data}
					submitting={false}
					onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
					toggle={() => this.props.toggle()}
					/>
				}
				successTitle={t('success.title')}
				successDesc={t('success.desc')}
				isLoading={order.review.edit.loading}
				isSuccess={order.review.edit.success}
				error={order.review.edit.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.editReviewClear());

					if (this.props.onClosed) {
						this.props.onClosed();
					}
				}}
				toggle={() => this.props.toggle()}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['review', 'default'])(ReviewModal);
const connected = connect(mapStateToProps)(translation);
export { connected as ReviewModal };