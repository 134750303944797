import React, { Component } from 'react';

import { Loading } from '../Loading';

import './InputFilePreview.scss';

export class InputFilePreview extends Component {
	constructor(props) {
		super(props);

		let reader = new FileReader();
		reader.onloadend = () => {
			this.setState({ loading: false, img: reader.result });
		};

		if (props.file) {
			reader.readAsDataURL(props.file);
		}

		this.state = {
			loading: props.file !== undefined,
			img: undefined,
			reader: reader
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { file } = this.props;
		const { loading } = this.state;

		if (!file) { return; }
		if (prevProps.file === file) return;
		
		if (loading) {
			this.state.reader.abort();
		}

		this.setState({ loading: true }, () => {
			this.state.reader.readAsDataURL(file);
		});
	}
	
	render() {
		const { file } = this.props;
		const { loading, img } = this.state;

		if (!file) { return null; }

		if (loading) { return <Loading/>; }

		return (
			<img
				className="input-file-preview"
				src={img}
				alt={file.name} />
		);
	}
}