import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';
import Moment from 'moment';
import qs from 'qs';

import { myCalendarActions, alertActions } from '../../_actions';
import { Calendar, PageContainer, Title, Desc } from '../../_components';

import './MyCalendar.scss';

import { Orders } from './Orders';
import { history } from '../../_helpers';

const serverDateFormat = 'YYYY-MM-DD HH:mm:ss.SSSSSS';

class MyCalendar extends Component {
	constructor(props) {
		super(props);

		const minDate = Moment();
		const maxDate = Moment().add(3, 'month');

		let currentMonth = Moment();
		const query = qs.parse(props.location.search.replace('?', ''));
		if (query.date) {
			const queryDate = Moment(query.date, 'YYYY-MM');

			if (queryDate.isSameOrAfter(minDate, 'month') && queryDate.isSameOrBefore(maxDate, 'month')) {
				currentMonth = queryDate;
			}
		}

		this.state = {
			occupiedDates: new Set(),
			minDate,
			maxDate,
			currentMonth,
			startDate: Moment().format('YYYY-MM-DD'),
			endDate: Moment().endOf('month').format('YYYY-MM-DD'),
			isCurrentMonthOccupied: false
		}
	}

	componentDidMount() {
		this.props.dispatch(myCalendarActions.getClear());
		this.props.dispatch(myCalendarActions.editClear());

		const { minDate, maxDate } = this.state;
		const monthEnd = maxDate.clone().endOf('month');
		let end_date = Moment(monthEnd).endOf('week');
		this.props.dispatch(myCalendarActions.get(minDate.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')));
	}

	componentDidUpdate(prevProps, prevState) {
		const { my_calendar, t, location } = this.props; 
		if (my_calendar.success && prevProps.my_calendar.success !== my_calendar.success && my_calendar.list) {
			let occupiedDates = new Set();
			my_calendar.list.forEach(item => {
				occupiedDates.add(item.date.date);
			});
			

			this.setState({ occupiedDates });
		}

		if (my_calendar.edit.success && my_calendar.edit.success !== prevProps.my_calendar.edit.success) {
			this.props.dispatch(alertActions.success(t('alert.success')));
		}

		if (location.search !== prevProps.location.search) {
			let { currentMonth, minDate, maxDate } = this.state;
			const query = qs.parse(location.search.replace('?', ''));
			if (query.date) {
				const queryDate = Moment(query.date, 'YYYY-MM');

				if (queryDate.isSameOrAfter(minDate, 'month') && queryDate.isSameOrBefore(maxDate, 'month') && (!currentMonth.isSame(queryDate, 'year') || !currentMonth.isSame(queryDate, 'month'))) {
					this.calendarOnMonthChange(queryDate);
					this.setState({ currentMonth: queryDate });
				}
			}
		}
	}

	calendarIsDayDisabled(day) {
		const { minDate, maxDate } = this.state;

		return day.isBefore(minDate, 'days') || day.isAfter(maxDate, 'days');
	}

	calendarIsDayOccupied(day) {
		return this.state.occupiedDates.has(day.format(serverDateFormat));
	}

	calendarIsMonthOccupied(month) {
		const monthStart = Moment(month).startOf('month');
		const monthEnd = Moment(monthStart).endOf('month');
		const { occupiedDates, minDate, maxDate } = this.state;

		let result = true;

		for (let day = monthStart; day.isSameOrBefore(monthEnd); day.add(1, 'days')) {
			if (day.isSameOrAfter(minDate, 'day') && day.isSameOrBefore(maxDate, 'day') && !occupiedDates.has(day.format(serverDateFormat))) {
				result = false;
				break;
			}
		}

		return result;
	}

	calendarOnMonthChange(month, visibleMonths) {
		//get all selectable months' data onMount
		
		//update isCurrentMonthOccupied
		const { minDate, maxDate } = this.state;
		let monthStart = Moment(month).startOf('month');
		if (monthStart.isBefore(minDate)) {
			monthStart = minDate;
		}
		let monthEnd = Moment(monthStart).endOf('month');
		if (monthEnd.isAfter(maxDate)) {
			monthEnd = maxDate;
		}

		this.setState({ isCurrentMonthOccupied: this.calendarIsMonthOccupied(month), startDate: monthStart.format('YYYY-MM-DD'), endDate: monthEnd.format('YYYY-MM-DD') });
	}

	calendarOnDayClick(day) {
		let occupiedDates = this.state.occupiedDates;
		let formattedDate = day.format(serverDateFormat);
		if (occupiedDates.has(formattedDate)) {
			occupiedDates.delete(formattedDate);
		} else {
			occupiedDates.add(formattedDate);
		}
		this.setState({ occupiedDates });
	}

	calendarOnMonthSelect(month, isSelect) {
		const monthStart = Moment(month).startOf('month');
		const monthEnd = Moment(monthStart).endOf('month');
		let occupiedDates = this.state.occupiedDates;

		const { minDate, maxDate } = this.state;

		for (let day = monthStart; day.isSameOrBefore(monthEnd); day.add(1, 'days')) {
			if (day.isSameOrAfter(minDate, 'day') && day.isSameOrBefore(maxDate, 'day')) {
				let formattedDate = day.format(serverDateFormat);

				if (isSelect) {
					occupiedDates.add(formattedDate);
				} else {
					occupiedDates.delete(formattedDate);
				}
			}
		}

		this.setState({ occupiedDates, isCurrentMonthOccupied: this.calendarIsMonthOccupied(month) });
	}

	calendarRenderMonthHeader(month, componentMonthTitle) {
		return null;
	}

	calendarRenderCalendarControls(visibleMonths, componentBtnNavPrevMonth, componentBtnNavNextMonth) {
		const { t } = this.props;
		let month = visibleMonths[0];
		const { isCurrentMonthOccupied } = this.state;

		return (
			<Row className="header">
				<Col xs={{ size: 'auto' }}>
					{componentBtnNavPrevMonth}
				</Col>
				<Col xs={{ size: true }} className="month-title">
					<span>
						<span className="big">{month.format('MM')}</span>
						<span className="stroke">|</span>
						{month.format('MMM YYYY')}
					</span>
				</Col>
				<Col xs={{ size: 'auto' }}>
					{componentBtnNavNextMonth}
				</Col>
				<Col md={{ size: 'auto' }}  className="col-select-month">
					<Button size="sm" block outline onClick={() => this.calendarOnMonthSelect(month, !isCurrentMonthOccupied)}>
						{t(isCurrentMonthOccupied ? 'mark_this_month_available' : 'mark_this_month_occupied')}
					</Button>
				</Col>
			</Row>
		);
	}

	calendarRenderDay(month, day, onDayClick) {
		const isDisabled = this.calendarIsDayDisabled(day);
		const isOccupied = this.calendarIsDayOccupied(day);
		const isSameMonth = day.isSame(month, 'month');

		let cell = (
			<Col key={day} xs={{ size: true }} className="my-calendar-day-col">
				<div className={`my-calendar-day ${isSameMonth ? '' : 'outside-month'} ${isDisabled ? 'disabled' : ''} ${isOccupied ? 'occupied' : ''}`} onClick={onDayClick}>
					<div className="my-calendar-day-number-container">
						<div className="my-calendar-day-number">
							{day.format('D')}
						</div>
						<div className="my-calendar-availability">{isOccupied ? 'Occupied' : 'Available'}</div>
					</div>
				</div>
			</Col>
		);

		return cell;
	}

	onSubmit() {
		const { occupiedDates } = this.state;
		let array = Array.from(occupiedDates);
		this.props.dispatch(myCalendarActions.edit(array));
	}

	render() {
		const { t, my_calendar } = this.props;
		const { minDate, maxDate, startDate, endDate, currentMonth } = this.state;

		return (
			<PageContainer id="vendor_mycalendar">
				<Container>
					<Row>
						<Col xs={{ size: true }}>
							<Title>{t('title')}</Title>
						</Col>
						<Col xs={{ size: 'auto' }} className="display-desktop">
							<Button
								block
								onClick={() => this.onSubmit()}>
								{t('save_changes')}
							</Button>
						</Col>
					</Row>
					<Desc>{t('desc')}</Desc>
					<hr />
					<Row>
						<Col xl={{ size: 8 }}>
							<Calendar
								className={`${my_calendar.error ? 'd-none' : ''}`}
								isLoading={my_calendar.loading}
								error={my_calendar.error}
								currentMonth={currentMonth}
								minDate={minDate}
								maxDate={maxDate}
								onPrevMonth={() => {
									const date = Moment(currentMonth).subtract(1, 'month').format('YYYY-MM');
									history.push(`/vendor/me/calendar?date=${date}`);
								}}
								onNextMonth={() => {
									const date = Moment(currentMonth).add(1, 'month').format('YYYY-MM');
									history.push(`/vendor/me/calendar?date=${date}`);
								}}
								onMonthChange={(month, visibleMonths) => this.calendarOnMonthChange(month, visibleMonths)}
								onDayClick={(day) => this.calendarOnDayClick(day)}
								onMonthSelect={(month, isSelect) => this.calendarOnMonthSelect(month, isSelect)}
								renderMonthHeader={(month, componentMonthTitle) => this.calendarRenderMonthHeader(month, componentMonthTitle)}
								renderCalendarControls={(visibleMonths, componentBtnNavPrevMonth, componentBtnNavNextMonth) => this.calendarRenderCalendarControls(visibleMonths, componentBtnNavPrevMonth, componentBtnNavNextMonth)}
								renderDay={(month, day, onDayClick) => this.calendarRenderDay(month, day, onDayClick)}
							/>
							<Button
								className="display-responsive"
								block
								onClick={() => this.onSubmit()}>
								{t('save_changes')}
							</Button>
						</Col>
						<Col xl={{ size: 4 }}>
							<Orders startDate={startDate} endDate={endDate} />
						</Col>
					</Row>
				</Container>
			</PageContainer>
		);
	}
}

function mapStateToProps(state) {
	const { my_calendar } = state;
	return {
		my_calendar
	};
}

const translation = withNamespaces(['mycalendar', 'default'])(MyCalendar);
const connected = connect(mapStateToProps)(translation);
export { connected as MyCalendar };