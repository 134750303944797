import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';

import { app } from '../../../../../_helpers';
import { Title, Desc } from '../../../../../_components';
import { PaymentSummary } from '../../../../../Order';

import './Breakdown.scss';

import img_info from '../../img/order_details_status_icon_question.svg';

import { RevenueInfoModal } from './RevenueInfoModal';

class Breakdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			revenueModalIsOpen: false
		}
	}

	onRevenueInfo() {
		if (this.state.revenueModalIsOpen) return;
		this.setState({ revenueModalIsOpen: true });
	}

	onCloseRevenueInfoModal() {
		if (!this.state.revenueModalIsOpen) return;
		this.setState({ revenueModalIsOpen: false });
	}

	render() {
		const { t, data, className } = this.props;
		const { revenueModalIsOpen } = this.state;

		return (
			<PaymentSummary id="vendor_order_details_summary_breakdown" className={className}>
				<div className="amount-breakdown">
					<Row>
						<Col xs={{ size: true }}>
							{data.package_name}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(data.currency.value, data.package_amount)}
						</Col>
					</Row>
					<Row>
						<Col xs={{ size: true }}>
							{t('additional_items_&_charges')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(data.currency.value, data.additional_amount)}
						</Col>
					</Row>
				</div>
				<hr />
				<div className="total">
					<Row>
						<Col xs={{ size: true }}>
							{t('total')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{app.toCurrencyString(data.currency.value, data.vendor_total_amount)}
						</Col>
					</Row>
				</div>
				<div className="revenue">
					<Button color="img" className="btn-info" onClick={() => this.onRevenueInfo()}><img src={img_info} alt={``} /></Button>
					<div>{`${t('you_will_receive')} ${app.toCurrencyString(data.currency.value, data.revenue)}`}</div>
				</div>
				<hr />
				<div className="vendor-remarks">
					<Title size='sm'>{t('vendor_remarks')}</Title>
					<Desc>
						{data.remarks ? data.remarks : t('N/A')}
					</Desc>
				</div>
				<RevenueInfoModal
					data={data}
					isOpen={revenueModalIsOpen}
					toggle={() => this.setState({ revenueModalIsOpen: !this.state.revenueModalIsOpen })}
				/>
			</PaymentSummary>
		);
	}
}

const translation = withNamespaces(['order', 'payment', 'package','default'])(Breakdown);
export { translation as Breakdown };