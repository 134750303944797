import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';

import { headerActions, homeActions } from '../../_actions';
import { PageContainer, Title, Desc, VideoPlayer, LoadingPage, ErrorPage } from '../../_components';

import { GridItem as ArticleGridItem } from '../../Article';
import { GridItem as PackageGridItem } from './Package';

import './Special.scss';

class Special extends Component {
	constructor(props){
		super(props);

		this.handleScroll = this.handleScroll.bind(this);

		this.state = {
			backgroundDimPercentage: 0,
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		
		this.props.dispatch(headerActions.solidStyle(false));
		this.props.dispatch(homeActions.getSpecialDetails(this.props.match.params.id));
	}


	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;

		if (prevProps.location !== location) {
			this.onRouteChanged(this.props);
		}
	}

	onRouteChanged(props) {
		this.props.dispatch(homeActions.getSpecialDetails(props.match.params.id));
	}
	
	componentWillUnmount() {
		this.props.dispatch(headerActions.solidStyle(true));
		// this.props.dispatch(headerActions.darkStyle(false));
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event) {
		const offsetY = window.pageYOffset;
		let bgImgHeight = window.innerWidth / 1920 * 960 - 200;
		if (window.innerWidth < 575.98) {
			bgImgHeight = window.innerWidth / 1920 * 960;
		}

		this.setState({ backgroundDimPercentage: Math.min(0.7, offsetY / bgImgHeight) })
	}

	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
	
	render() {
		const { t, special, app } = this.props;
		const { backgroundDimPercentage } = this.state;
		
		let backgroundImg = special.details.data? app.windowSize.width < 576? special.details.data.responsive_background_url: special.details.data.desktop_background_url : undefined;
		let backgroundColor = this.hexToRgb(`#${special.details.data? special.details.data.theme_color : '000000'}`);

		return (
			<>
				{special.details.loading && <LoadingPage />}
				{special.details.error && <ErrorPage match={this.props.match} error={special.details.error} />}
				{special.details.data &&

					<PageContainer fullPage id="special"
						style={{
							backgroundImage: `linear-gradient(rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundDimPercentage}), rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundDimPercentage})), url('${backgroundImg}')`,
						}}

					>
						<Container className="container-xl">
							<div className="spacer"></div>
							<Title className="title-page">{special.details.data.inner_title}</Title>

							<Desc className="desc-page" dangerouslySetInnerHTML={{ __html: special.details.data.inner_desc }}></Desc>

							{special.details.data.video_url &&
								<div className="section section-video">
									<VideoPlayer
										// hideControls
										videoUrl={special.details.data.video_url}
									/>
								</div>
							}

							{special.details.data.article_items &&
								<div className="section section-articles">
									<Title size="sm" className="title-page">{t('customer.special.related_articles')}</Title>
									<div className="item-grid">
										<Row>
											{special.details.data.article_items.map((item, index) =>
												<Col key={item.id} sm={{ size: 12 }} md={{ size: 6 }} lg={{ size: 4 }} xl={{ size: 3 }} className={`col-xxl-3`}>
													<ArticleGridItem item={item.article} />
												</Col>
											)}
										</Row>
									</div>
								</div>
							}

							{special.details.data.package_items &&
								<div className="section section-packages">
									<Title size="sm" className="title-page">{t('customer.special.related_packages')}</Title>
									{
										special.details.data.package_items.map((item, index) => {
											return <PackageGridItem key={item.id} item={item.package} />;
										})
									}
								</div>
							}

						</Container>
					</PageContainer>
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { special, app } = state;
	return {
		special,
		app
	};
}

const translation = withNamespaces(['home', 'default'])(Special);
const connected = connect(mapStateToProps)(translation);
export { connected as Special };