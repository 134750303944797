import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StripeProvider, Elements, } from 'react-stripe-elements';

import { orderActions } from '../../../_actions';
import { LoadingPage } from '../../../_components';

import './Pay.scss';

import { InputForm } from './InputForm';
import { Success } from './Success';

class Pay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stripe: null
		}
	}

	componentDidMount() {
		this.props.dispatch(orderActions.paymentProcessClear());

		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) });
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) });
			});
		}
	}

	onSubmit(values) {
		this.props.dispatch(orderActions.paymentProcess(false, values));
	}

	render() {
		const { order, match } = this.props;
		const { stripe } = this.state;

		return (
			<>
				{order.payment.process.success ?
					<Success order_id={match.params.id} />
					:
					stripe ?
						<StripeProvider stripe={stripe}>
							<Elements
								fonts={[
									{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700' }
								]}>
								<InputForm
								 	match={this.props.match}
									order_id={match.params.id}
									payment_id={match.params.payment_id}
									onSubmit={(values) => this.onSubmit(values)}
									submitting={order.payment.process.loading}
								/>
							</Elements>
						</StripeProvider>
						:
						<LoadingPage />
				}
			</>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const connected = connect(mapStateToProps)(Pay);
export { connected as Pay };