import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap';
import { getIn } from 'formik';

import { payoutSettingsActions } from '../../../../_actions';
import { Loading } from '../../../../_components';

import './PayoutSettings.scss';

class PayoutSettings extends Component {
	componentDidMount() {
		this.props.dispatch(payoutSettingsActions.getFormInfo(this.props.data.payout_setting));
	}

	render() {
		const { t, data, payout_settings_form_info } = this.props;

		return (
			<div id="vendor_profile_payout_card_payout_settings">
				<Card className="card-payout-settings">
					<CardHeader>
						<CardTitle tag="h4">
							{t('payout_details')}
						</CardTitle>
					</CardHeader>
					<CardFooter>
						<Row>
							<Col sm={{ size: true }}>
								{t('currency')}
							</Col>
							<Col sm={{ size: 'auto' }}>
								{data.payout_setting.currency.value}
							</Col>
						</Row>
					</CardFooter>
					<CardFooter>
						<Row>
							<Col sm={{ size: true }}>
								{t('accountHolderName')}
							</Col>
							<Col sm={{ size: 'auto' }}>
								{data.payout_setting.payout_gateway_info.accountHolderName}
							</Col>
						</Row>
					</CardFooter>
					{payout_settings_form_info.loading && <CardFooter><Loading /></CardFooter>}
					{payout_settings_form_info.error && <CardFooter><Loading error={payout_settings_form_info.error} /></CardFooter>}
					{payout_settings_form_info.data &&
						payout_settings_form_info.data.map((object, index) => {
							if (object.type === data.payout_setting.payout_gateway_info.type) {
								return object.fields.map((item, index) => {
									return item.group.map((groupItem, index) => {
										let value = getIn(data.payout_setting.payout_gateway_info.details, groupItem.key);
										if (groupItem.type === 'select') {
											let selectedValue = groupItem.valuesAllowed.find((item) => {
												return item.key === value;
											});
											value = selectedValue.name;
										}
										return (
											<CardFooter key={groupItem.key}>
												<Row>
													<Col sm={{ size: true }}>
														{groupItem.name}
													</Col>
													<Col sm={{ size: 'auto' }}>
														{value}
													</Col>
												</Row>
											</CardFooter>
										);
									});
								});
							}
						})
					}
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state) {
    const { payout_settings_form_info } = state; 
    return {
		payout_settings_form_info
    };
}

const translation = withNamespaces(['payout'])(PayoutSettings);
const connected = connect(mapStateToProps)(translation);
export { connected as PayoutSettings };