import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, Desc } from '../../../../_components';

import './InputForm.scss';

import { Breakdown } from '../../Details';

class InputForm extends Component {
	render() {
		const { t, data, isLoading, title, desc } = this.props;

		return (
			<div id="vendor_order_make_offer_form" className="modal-content-container">
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>{title}</Title>
				</ModalHeader>
				<ModalBody>
					<Desc>{desc}</Desc>
					{data && <Breakdown className="border border-light-grey" data={data} />}
				</ModalBody>
				<ModalFooter>
					<Container className="container-p-8">
						<Row className="row-m-8">
							<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
								<Button onClick={() => this.props.onSubmit()} disabled={isLoading}>{t('submit')}</Button>
							</Col>
							<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
								<Button color="cancel" onClick={() => this.props.toggle()}>{t('cancel')}</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</div>
		);
	}
}

const translation = withNamespaces(['order', 'package', 'default'])(InputForm);
export { translation as InputForm };