import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';

import { app } from '../../../_helpers';
import { orderPaymentStatus, userType, orderPaymentType, getOrderPaymentStatusNameByData } from '../../../_constants';
import { SVGImg, Desc } from '../../../_components';
import { PaymentSummary } from '../../PaymentSummary';

import './Payment.scss';

class Payment extends Component {
	render() {
		const { t, data, order, isReceipt, auth, isPay } = this.props;
		const isVendor = auth.user.user_type !== userType.customer;

		let title = t('payment_summary');
		switch (data.type) {
			case orderPaymentType.full_paid:
				title = t('payment_summary');
				break;
			case orderPaymentType.half_refund:
			case orderPaymentType.full_refund:
				title = t('order_refund');
				break;
			default:
				break;
		}

		return (
			<PaymentSummary className={`card ${isVendor ? '' : 'card-secondary'} card-order-payment`}>
				<CardHeader>
					<Row className="row-status">
						<Col xs={{ size: true }} sm={{ size: true }} className="col-title">
							<CardTitle tag="h4">
								{title}
							</CardTitle>
						</Col>
						{!isReceipt && !isPay && data.type === orderPaymentType.full_paid && data.status === orderPaymentStatus.unpaid &&
							<Col sm={{ size: 'auto' }} className="col-pay-now">
								<Link className="btn btn-block btn-primary btn-pay" to={`/customer/me/order/${data.order_id}/payment/${data.id}/pay`}>{t('pay_now')}</Link>
							</Col>
						}
						{!isReceipt && !isPay && ((data.type === orderPaymentType.full_paid && data.status !== orderPaymentStatus.unpaid) || (data.type === orderPaymentType.half_refund || data.type === orderPaymentType.full_refund)) &&
							<Col xs={{ size: 'auto' }} className="col-status">
								<div className="status">
									{data.status === orderPaymentStatus.paid && 
										<SVGImg img="tick" color={`${isVendor? 'success' : ''}`} />
									}
									{(data.type !== orderPaymentType.full_paid && (data.status === orderPaymentStatus.unpaid || data.status === orderPaymentStatus.processing)) && 
										<SVGImg img="processing" />
									}
									<span className={isVendor? `color-${getOrderPaymentStatusNameByData(data)}` : ''}>{t(`status.${getOrderPaymentStatusNameByData(data)}`)}</span>
								</div>
							</Col>
						}
					</Row>
					<Row className="row-remarks">
						{!isReceipt && !isPay && data.type === orderPaymentType.full_paid && data.status === orderPaymentStatus.unpaid &&
							<Col md={{ size: true }}>
								<div dangerouslySetInnerHTML={{ __html: t(`payment_remarks.${isVendor? 'vendor' : 'customer'}`, { date: Moment.utc(data.expiry_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma') }) }}></div>
							</Col>
						}
						{!isReceipt && !isPay && (data.type === orderPaymentType.half_refund || data.type === orderPaymentType.full_refund) &&
							<Col md={{ size: true }}>
								{t('refund_desc')}
							</Col>
						}
						{!isVendor && !isReceipt && !isPay && data.status === orderPaymentStatus.paid &&
							<>
								<Col xs={{ size: true }} className="col-view-receipt">
									<Link className="btn btn-sm btn-outline-primary" to={`/customer/me/order/${data.order_id}/receipt/${data.order_receipt_id}`}>{t('view_receipt')}</Link>
								</Col>
							</>
						}
						{isPay &&
							<Col xs={{ size: '12' }} className="col-package-details">
								<Row className="row-m-6">
										<Col md={{ size: '5' }} className="col-key">
											{t('package')}
										</Col>
										<Col md={{ size: true }} className="col-value">
											{data.package_name}
										</Col>
								</Row>
								<Row className="row-m-6">
										<Col md={{ size: '5' }} className="col-key">
											{t('shooting_datetime')}
										</Col>
										<Col md={{ size: true }} className="col-value">
											{`${Moment.utc(order.shoot_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').utcOffset(order.timezone.offset).format('D MMM YYYY h:mma')} ${order.timezone.offset}`}
										</Col>
								</Row>
							</Col>
						}
					</Row>
				</CardHeader>
				<CardBody>
					<div className="amount-breakdown">
						{data.type === orderPaymentType.full_paid &&
							<>
								<Row>
									<Col xs={{ size: true }}>
										{isReceipt? data.package_name : t('package_total')}
									</Col>
									<Col xs={{ size: 'auto' }}>
										{app.toCurrencyString(data.currency.value, order.package_amount)}
									</Col>
								</Row>
								{order.additional_amount > 0 &&
									<Row>
										<Col xs={{ size: true }}>
											{t('additional_amount')}
										</Col>
										<Col xs={{ size: 'auto' }}>
											{app.toCurrencyString(data.currency.value, order.additional_amount)}
										</Col>
									</Row>
								}
								{!isVendor &&
									<Row>
										<Col xs={{ size: true }}>
											{t('service_fee')}
										</Col>
										<Col xs={{ size: 'auto' }}>
											{app.toCurrencyString(data.currency.value, order.customer_service_fee)}
										</Col>
									</Row>
								}
								{order.promotion_code && order.promotion_amount &&
									<Row>
										<Col xs={{ size: true }}>
											{`${t('promotion_code')} - ${order.promotion_code}`}
										</Col>
										<Col xs={{ size: 'auto' }} className="value minus-value">
											{app.toCurrencyString(data.currency.value, order.promotion_amount)}
										</Col>
									</Row>
								}
							</>
						}

						{(data.type === orderPaymentType.half_refund || data.type === orderPaymentType.full_refund) &&
							<>
								<Row>
									<Col xs={{ size: true }}>
										{t('order_total_without_service_fee')}
									</Col>
									<Col xs={{ size: 'auto' }} className="value">
										{app.toCurrencyString(data.currency.value, (Number(order.customer_total_amount) - Number(order.customer_service_fee)).toFixed(2))}
									</Col>
								</Row>
								{data.type === orderPaymentType.half_refund &&
									<Row>
										<Col xs={{ size: true }}>
											{t(`refund_type.${data.type}`)}
										</Col>
										<Col xs={{ size: 'auto' }} className="value minus-value">
											{app.toCurrencyString(data.currency.value, (Number(order.customer_total_amount) - Number(data.customer_amount)).toFixed(2))}
										</Col>
									</Row>
								}
							</>
						}
					</div>
				</CardBody>
				<CardFooter>
					<Row className="amount-breakdown total">
						<Col xs={{ size: true }}>
							{t('total')}
						</Col>
						<Col xs={{ size: 'auto' }}>
							{isVendor ?
								app.toCurrencyString(data.currency.value, data.vendor_amount)
								:
								app.toCurrencyString(data.currency.value, data.customer_amount)
							}
						</Col>
					</Row>
				</CardFooter>
				{data.status === orderPaymentStatus.paid &&
					<CardFooter>
						<div className="amount-breakdown breakdown">
						{data.type === orderPaymentType.full_paid &&
							<Row>
								<Col xs={{ size: true }}>
									{t('paid_by')}
								</Col>
								<Col xs={{ size: 'auto' }}>
									{t('credit_card')}
								</Col>
							</Row>
						}
							<Row>
								<Col xs={{ size: true }}>
									{t('transaction_date')}
								</Col>
								<Col xs={{ size: 'auto' }}>
									{Moment.utc(data.transaction_datetime.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').local().format('D MMM YYYY h:mma')}
								</Col>
							</Row>
						</div>
					</CardFooter>
				}
				{data.desc &&
					<CardFooter>
						<Desc>
							{data.desc}
						</Desc>
					</CardFooter>
				}
			</PaymentSummary>
		);
	}
}

function mapStateToProps(state) {
	const { auth } = state;
	return {
		auth
	};
}

const translation = withNamespaces(['payment', 'receipt', 'package', 'default'])(Payment);
const connected = connect(mapStateToProps)(translation);
export { connected as Payment };