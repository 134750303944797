import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';

import { Desc, ProfilePicImg, RowStroked, Title } from '../../../_components';
import { history } from '../../../_helpers';

import '../Card.scss';
import './Vendor.scss';

import { ReportModal } from '../../ReportModal';

class Vendor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reportModalIsOpen: false,
		}
	}

	onReport() {
		this.setState({ reportModalIsOpen: true });
	}

	onChat() {
		const { data } = this.props;
		history.push(`/chat?target=${data.vendor.id}`);
	}

	render() {
		const { t, data } = this.props;
		const { reportModalIsOpen } = this.state;

		return (
			<Card id="order_card_vendor" className="order-info-card">
				<CardHeader>
					<CardTitle tag='div'>
						{t('vendor')}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<Link to={`/package/vendor/${data.vendor.id}`}>
						<Row className="row-m-8 vendor-info">
							<Col xs={{ size: 'auto' }}>
								<ProfilePicImg src={data.vendor.profile_pic_url} alt={data.vendor.nickname} />
							</Col>
							<Col xs={{ size: true }}>
								<Title size='xs'>
									{data.vendor.nickname}
								</Title>
								<Desc>
									{data.vendor.city.value}
								</Desc>
							</Col>
						</Row>
					</Link>
				</CardBody>
				<CardFooter>
					<RowStroked persist>
						<Col xs={{ size: 'auto' }}>
							<Button color="link" onClick={() => this.onReport()}>{t('report')}</Button>
						</Col>
						<Col xs={{ size: 'auto' }}>
							<Button color="link" onClick={() => this.onChat()}>{t('chat')}</Button>
						</Col>
					</RowStroked>
				</CardFooter>
				<ReportModal
					isOpen={reportModalIsOpen}
					order_id={data.id}
					toggle={() => this.setState({ reportModalIsOpen: !this.state.reportModalIsOpen })}
				/>
			</Card>
		);
	}
}

const translation = withNamespaces(['order', 'report', 'default'])(Vendor);
export { translation as Vendor };