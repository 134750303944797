import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { Title, BaseModal, Desc } from '../../../../../_components';

import './TotalSalesInfoModal.scss';

class TotalSalesInfoModal extends Component {
	render() {
		const { t, data } = this.props;

		return (
			<BaseModal
				id="vendor_mypackages_package_total_sales_info_modal"
				isOpen={this.props.isOpen}
				toggle={() => this.props.toggle()}
			>
				<ModalHeader toggle={() => this.props.toggle()}>
					<Title>
						{t('total_sales')}
					</Title>
				</ModalHeader>
				<ModalBody>
					<Desc>
						{t('total_sales_desc')}
					</Desc>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => this.props.toggle()}>{t('ok')}</Button>
				</ModalFooter>
			</BaseModal>
		);
	}
}

const translation = withNamespaces(['mypackages', 'default'])(TotalSalesInfoModal);
export { translation as TotalSalesInfoModal };