import axios from 'axios';

import { api, analytics } from '../_helpers';
import { apiEndpoint, vendorConstants } from '../_constants';

export const vendorActions = {
	getLanguageOptions,
	getLanguageOptionsClear,
	getCityOptions,
	getCityOptionsClear,
	search,
	searchClear,
	getFeaturedList,
	getFeaturedListClear
}

function getLanguageOptions() {
	const requestOptions = api.requestOption(apiEndpoint.vendor.options.language);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: vendorConstants.GET_LANGUAGE_OPTIONS_REQUEST } }
    function success(responseData) { return { type: vendorConstants.GET_LANGUAGE_OPTIONS_SUCCESS, responseData } }
    function failure(error) { return { type: vendorConstants.GET_LANGUAGE_OPTIONS_FAILURE, error } }
}

function getLanguageOptionsClear() {
	return { type: vendorConstants.GET_LANGUAGE_OPTIONS_CLEAR }
}

function getCityOptions() {
	const requestOptions = api.requestOption(apiEndpoint.vendor.options.city);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: vendorConstants.GET_CITY_OPTIONS_REQUEST } }
    function success(responseData) { return { type: vendorConstants.GET_CITY_OPTIONS_SUCCESS, responseData } }
    function failure(error) { return { type: vendorConstants.GET_CITY_OPTIONS_FAILURE, error } }
}

function getCityOptionsClear() {
	return { type: vendorConstants.GET_CITY_OPTIONS_CLEAR }
}

function search(city, keyword, page_number) {
	analytics.event({
		category: 'Vendors',
		action: 'Search',
		label: 'Customer'
	});

	if (city) {
		analytics.event({
			category: `Vendors - city: ${city.name}`,
			action: 'Search',
			label: 'Customer'
		});
	}
	if (keyword) {
		analytics.event({
			category: `Vendors - keyword: ${keyword}`,
			action: 'Search',
			label: 'Customer'
		});
	}

	let city_id = city? city.id : undefined;

	const per_page = 10;
	const requestOptions = api.requestOption(apiEndpoint.vendor.search, { city_id, keyword, per_page, page_number });

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: vendorConstants.GET_LIST_REQUEST } }
    function success(responseData) { return { type: vendorConstants.GET_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: vendorConstants.GET_LIST_FAILURE, error } }
}

function searchClear() {
	return { type: vendorConstants.GET_LIST_CLEAR }
}

function getFeaturedList() {
	const requestOptions = api.requestOption(apiEndpoint.vendor.featured_list);

    return dispatch => {
        dispatch(request());

		axios.request(requestOptions)
			.then(response => {
				dispatch(success(response.data.data));
			})
			.catch(error => {
				dispatch(failure(api.handleError(error, dispatch, true)));
			});
    };

    function request() { return { type: vendorConstants.GET_FEATURED_LIST_REQUEST } }
    function success(responseData) { return { type: vendorConstants.GET_FEATURED_LIST_SUCCESS, responseData } }
    function failure(error) { return { type: vendorConstants.GET_FEATURED_LIST_FAILURE, error } }
}

function getFeaturedListClear() {
	return { type: vendorConstants.GET_FEATURED_LIST_CLEAR }
}