import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import {
	FacebookShareButton,
	GooglePlusShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	PinterestShareButton,
	VKShareButton,
	OKShareButton,
	RedditShareButton,
	TumblrShareButton,
	LivejournalShareButton,
	MailruShareButton,
	ViberShareButton,
	WorkplaceShareButton,
	LineShareButton,
	EmailShareButton,
	WeiboShareButton,
	FacebookIcon,
	TwitterIcon,
	GooglePlusIcon,
	LinkedinIcon,
	PinterestIcon,
	VKIcon,
	OKIcon,
	TelegramIcon,
	WhatsappIcon,
	RedditIcon,
	TumblrIcon,
	MailruIcon,
	EmailIcon,
	LivejournalIcon,
	ViberIcon,
	WorkplaceIcon,
	LineIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { alertActions } from '../../_actions';

import './ShareButton.scss';

import img_share from '../../_common/img/home_article_icon_share.svg';
import img_copy_link from './img/copy-document.svg';
import img_weibo from './img/weibo-icon.png';

class ShareButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false
		}

	}

	toggleDropdown() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	render() {
		const { t, url, title, body, dropdownMenuAlignRight, hashtags, imgUrl, btnImg, disabled } = this.props;
		let _hashtags = ['WEDDINGUNO'];
		_hashtags = _hashtags.concat(hashtags);

		const medias = [
			'facebook',
			'whatsapp',
			'line',
			'weibo',
			'twitter',
			'email',
			'copy_link',
		];

		return (
			<div className="share-button">
				<UncontrolledButtonDropdown>
					<DropdownToggle className="btn-img" disabled={disabled}>
						<img src={btnImg? btnImg : img_share} alt={t('share')}/>
					</DropdownToggle>
					<DropdownMenu right={dropdownMenuAlignRight}>
						{medias.map((item, index) => {
							let btn = null;

							switch (item) {
								case 'facebook':
									btn = (
										<FacebookShareButton
											url={url}
											quote={title}
											hashtag={_hashtags.map(item => `#${item}`).join(' ')}
											className={`share-btn share-btn-${item}`}>
											<FacebookIcon
												size={32}
												round />
											<span>{t(item)}</span>
										</FacebookShareButton>
									);
									break;
								case 'whatsapp':
									btn = (
										<WhatsappShareButton
											url={url}
											title={title}
											className={`share-btn share-btn-${item}`}>
											<WhatsappIcon
												size={32}
												round />
											<span>{t(item)}</span>
										</WhatsappShareButton>
									);
									break;
								case 'line':
									btn = (
										<LineShareButton
											url={url}
											className={`share-btn share-btn-${item}`}>
											<LineIcon
												size={32}
												round />
											<span>{t(item)}</span>
										</LineShareButton>
									);
									break;
								case 'weibo':
									btn = (
										<WeiboShareButton
											url={url}
											title={title}
											image={imgUrl? imgUrl : img_weibo} //TODO: An absolute link to the image that will be shared (string)
											className={`share-btn share-btn-${item}`}>
											<img src={img_weibo} alt={t(item)} />
											<span>{t(item)}</span>
										</WeiboShareButton>
									);
									break;
								case 'twitter':
									btn = (
										<TwitterShareButton
											url={url}
											quote={title}
											hashtags={_hashtags}
											className={`share-btn share-btn-${item}`}>
											<TwitterIcon
												size={32}
												round />
											<span>{t(item)}</span>
										</TwitterShareButton>
									);
									break;
								case 'email':
									btn = (
										<EmailShareButton
											url={url}
											subject={title}
											body={body}
											className={`share-btn share-btn-${item}`}>
											<EmailIcon
												size={32}
												round />
											<span>{t(item)}</span>
										</EmailShareButton>
									);
									break;
								case 'copy_link':
									btn = (
										<CopyToClipboard text={url}
											onCopy={() => {
												this.props.dispatch(alertActions.success(t('copy_link_success')));
											}}>
											<div className={`share-btn share-btn-${item}`}>
											<img src={img_copy_link} alt={t(item)}/>
											<span>{t(item)}</span>
											</div>
										</CopyToClipboard>
									);
									break;
								default:
									break;
							}
							return (
								<DropdownItem key={item}>
								{btn}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {  } = state;
	return {
		
	};
}

const translation = withNamespaces(['share', 'default'])(ShareButton);
const connected = connect(mapStateToProps)(translation);
export { connected as ShareButton };