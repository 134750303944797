
import React from 'react';

import './LoadingPage.scss';

import { Loading } from '../Loading';
import { PageContainer } from '../PageContainer';

export const LoadingPage = ({ ...props}) => {
	return (
		<PageContainer id="loading_page">
			<Loading { ...props}/>
		</PageContainer>
	);
}