import { headerConstants } from '../_constants';

export const headerActions = {
	solidStyle,
	gradientStyle,
	darkStyle
};

function solidStyle(solid) {
    return { type: headerConstants.SOLID_STYLE, solid: solid };
}

function gradientStyle(active) {
    return { type: headerConstants.GRADIENT_STYLE, active: active };
}

function darkStyle(dark) {
    return { type: headerConstants.DARK_STYLE, dark: dark };
}