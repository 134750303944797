import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { orderStatus } from '../../../_constants';
import { orderActions } from '../../../_actions';
import { ActionModal } from '../../../_components';

import './CancelOfferModal.scss';

class CancelOfferModal extends Component {
	componentDidMount() {
		this.props.dispatch(orderActions.cancelClear());
	}

	render() {
		const { t, data, order, isOpen } = this.props;

		const isReject = data.status === orderStatus.requesting;
		const translationPrefix = isReject? 'reject_booking' : 'cancel_offer';

		return (
			<ActionModal
				id="vendor_order_cancel_offer_modal"
				confirmTitle={t(`${translationPrefix}.confirm.title`)}
				confirmDesc={t(`${translationPrefix}.confirm.desc`)}
				successTitle={t(`${translationPrefix}.success.title`)}
				successDesc={t(`${translationPrefix}.success.desc`)}
				isLoading={order.cancel.loading}
				isSuccess={order.cancel.success}
				error={order.cancel.error}
				isOpen={isOpen}
				onClosed={() => {
					this.props.dispatch(orderActions.cancelClear());
				}}
				toggle={() => this.props.toggle()}
				onSubmit={() => {
					this.props.dispatch(orderActions.cancel(true, this.props.data.id));
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	const { order } = state;
	return {
		order
	};
}

const translation = withNamespaces(['order', 'default'])(CancelOfferModal);
const connected = connect(mapStateToProps)(translation);
export { connected as CancelOfferModal };