import React, { Component } from 'react';
import { Field, ErrorMessage, getIn, connect } from 'formik';

import './InputField.scss';

class InputField extends Component {
	_handleOnBlur = e => {
		const { onBlur, name, formik } = this.props;
		const { handleBlur } = formik;

		if (onBlur) {
			onBlur(name, e.currentTarget.value);
		}

		handleBlur(e);
	}
	
	_handleOnChange = e => {
		const { onChange, name, formik } = this.props;
		const { handleChange, setFieldTouched } = formik;

		setFieldTouched(name, true);
	
		if (onChange) {
			onChange(name, e.currentTarget.value);
		}
	
		handleChange(e);
	}

	render() {
		const { component, field_id, type, name, placeholder, t, className, disabled, disableAutoComplete, hideErrorMessage, error_id, formik, showError, validate } = this.props;
		const { touched, errors, values } = formik;

		let value = getIn(values, name);

		return(
			<>
				<Field component={component} id={field_id} type={type} name={name}
					placeholder={placeholder ? placeholder : t(name)}
					className={`${className ? className : ''} ${((getIn(touched, name) && getIn(errors, name)) || showError) ? "input-error" : ""}`}
					disabled={disabled}
					autoComplete={disableAutoComplete ? 'off' : 'on'} 
					onBlur={(e) => this._handleOnBlur(e)}
					onChange={(e) => this._handleOnChange(e)}
					validate={validate}
					value={value? value : ''}
					/>
				{!hideErrorMessage && <ErrorMessage id={error_id} name={name} component="span" className="error" />}
			</>
		);
	}
}

const connected = connect(InputField);
export { connected as InputField };

export const InputFieldRemarks = ({ className, children, ...props}) => {
	return <div className={`input-field-remarks ${className? className : ''}`} {...props}>{children}</div>;
}