import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { GridItemContent } from './GridItemContent';

class GridItem extends Component {
	render() {
		const { t, item, saved_item_action, hideBookmark, auth, dispatch } = this.props;

		return (
			<GridItemContent
				t={t}
				item={item}
				saved_item_action={saved_item_action}
				hideBookmark={hideBookmark}
				auth={auth}
				dispatch={dispatch}
			/>
		);
	}
}

function mapStateToProps(state) {
    const { auth, saved_item_action } = state; 
    return {
		auth,
		saved_item_action
    };
}

const translation = withNamespaces(['vendor', 'default'])(GridItem);
const connected = connect(mapStateToProps)(translation);
export { connected as GridItem };
