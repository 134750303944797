import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Button } from 'reactstrap';

import { vendorProfileActions } from '../../../../../_actions';

import { InputSelect, Calendar, InputField, InputItem, InputItemLabel, Title, BaseModal } from '../../../../../_components';

import './InputForm.scss';

class InputForm extends Component {
	constructor(props){
		super(props);

		this.state = {
			vendor_disabled_dates: [],
			calendarDropdownIsOpen: false,
		}
	}

	componentDidMount() {
		this.props.dispatch(vendorProfileActions.getCalendarClear());
	}
	
	toggleCalendarDropdown() {
		this.setState({ calendarDropdownIsOpen: !this.state.calendarDropdownIsOpen });
	}

	calendarIsDayDisabled(day) {
		if (!this.props.vendor_calendar.list) return false;
		
		let result = this.props.vendor_calendar.list.some((item) => {
			return day.isSame(Moment(item.date.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS'), 'day');
		});
		return result || day.isBefore(Moment());
	}

	calendarOnMonthChange(month, visibleMonths) {
		const monthStart = Moment(visibleMonths[0]).startOf('month');
		const monthEnd = Moment(visibleMonths[visibleMonths.length - 1]).endOf('month');
		let start_date = Moment(monthStart).startOf('week');
		let end_date = Moment(monthEnd).endOf('week');

		if (Moment(end_date).isBefore(Moment(), 'day')) {
			return;
		}

		if (Moment(start_date).isBefore(Moment(), 'day')) {
			start_date = Moment();
		}

		this.props.dispatch(vendorProfileActions.getCalendar(this.props.vendor_id, start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), this.props.order_id));
	}

	render() {
		const { t, submitting, selectedDate, selectedTime, includeRemarks } = this.props;
		const { calendarDropdownIsOpen } = this.state;

		let shooting_date = '';
		let shooting_time_h = '';
		let shooting_time_m = '';
		let shooting_time_a = '';

		if (selectedDate) {
			shooting_date = selectedDate.format('YYYY-MM-DD');
		}
		if (selectedTime) {
			shooting_time_h = selectedTime.format('h');
			shooting_time_m = selectedTime.format('mm');;
			shooting_time_a = selectedTime.format('a');;
		}

		const schema = Yup.object().shape({
			shooting_date: Yup.mixed().required(t('required')),
			shooting_time_h: Yup.string()
				.required(t('required')),
			shooting_time_m: Yup.string()
				.required(t('required')),
			shooting_time_a: Yup.string()
				.required(t('required')),
		});

		let initialValues = {
			'shooting_date': shooting_date,
			'shooting_time_h': shooting_time_h,
			'shooting_time_m': shooting_time_m,
			'shooting_time_a': shooting_time_a,
		};

		if (includeRemarks) {
			initialValues = {
				'shooting_date': shooting_date,
				'shooting_time_h': shooting_time_h,
				'shooting_time_m': shooting_time_m,
				'shooting_time_a': shooting_time_a,
				'remarks': ''
			};
		}

		return (
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={(values, { setSubmitting }) => this.props.onSubmit(values, { setSubmitting })}
			>
				{({ values,
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setFieldTouched
					/* and other goodies */
				}) => (
						<Form id="order_edit_datetime_modal_form" className="modal-content-container">
							<ModalHeader toggle={() => this.props.toggle()}>
								<Title>{t('edit_date.title')}</Title>
							</ModalHeader>
							<ModalBody>
								<Container>
									<InputItem className="shooting_date">
										<InputItemLabel>
											{t('shooting_date')}
										</InputItemLabel>
										<Button className={`dropdown-toggle ${values.shooting_date ? '' : 'placeholder'} ${(touched.shooting_date && errors.shooting_date) ? "input-error" : ""}`} onClick={() => {
											setFieldTouched('shooting_date', true);
											this.toggleCalendarDropdown();
										}}>
											{values.shooting_date ? values.shooting_date : t('select')}
										</Button>
										<ErrorMessage name="shooting_date" component="span" className="error" />
									</InputItem>
									<InputItem className="shooting_time">
										<InputItemLabel>
											{t('shooting_time')}
										</InputItemLabel>
										<Row className="row-m-4">
											<Col xs={{ size: true }}>
												<InputSelect
													name="shooting_time_h" t={t}
													placeholder={t('hour')}
													options={[
														{ id: '1', value: '1' },
														{ id: '2', value: '2' },
														{ id: '3', value: '3' },
														{ id: '4', value: '4' },
														{ id: '5', value: '5' },
														{ id: '6', value: '6' },
														{ id: '7', value: '7' },
														{ id: '8', value: '8' },
														{ id: '9', value: '9' },
														{ id: '10', value: '10' },
														{ id: '11', value: '11' },
														{ id: '12', value: '12' },
													]}
													isSearchable={false}
												/>
											</Col>
											<Col xs={{ size: true }}>
												<InputSelect
													name="shooting_time_m" t={t}
													placeholder={t('minute')}
													options={[
														{ id: '00', value: '00' },
														{ id: '15', value: '15' },
														{ id: '30', value: '30' },
														{ id: '45', value: '45' },
													]}
													isSearchable={false}
												/>
											</Col>
											<Col xs={{ size: true }}>
												<InputSelect
													name="shooting_time_a" t={t}
													placeholder={t('am/pm')}
													options={[
														{ id: 'am', value: t('am') },
														{ id: 'pm', value: t('pm') },
													]}
													isSearchable={false}
												/>
											</Col>
										</Row>
									</InputItem>
									{includeRemarks &&
										<InputItem className="remarks">
											<InputItemLabel>
												{t('remarks')}
											</InputItemLabel>
											<InputField component="textarea" type="text" name="remarks" placeholder={t('remarks_hint')} t={t} />
										</InputItem>
									}
								</Container>
							</ModalBody>
							<ModalFooter>
								<Container className="container-p-8">
									<Row className="row-m-8">
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button
												block
												type="submit"
												disabled={submitting}
												onClick={handleSubmit}>
												{t('submit')}
											</Button>
										</Col>
										<Col sm={{ size: 'auto' }} xs={{ size: 6 }}>
											<Button color="cancel" block onClick={() => this.props.toggle()}>{t('cancel')}</Button>
										</Col>
									</Row>
								</Container>
							</ModalFooter>
							<BaseModal
								id="order_edit_datetime_modal_form_modal_calendar"
								className="modal-calendar"
								size="sm"
								isOpen={calendarDropdownIsOpen}
								toggle={() => this.toggleCalendarDropdown()}
							>
								<ModalHeader toggle={() => this.toggleCalendarDropdown()}>
									<Title>{t('shooting_date')}</Title>
								</ModalHeader>
								<ModalBody>
									<Calendar
										onMonthChange={(month, visibleMonths) => this.calendarOnMonthChange(month, visibleMonths)}
										onDayClick={(day) => {
											setFieldValue('shooting_date', day.format('YYYY-MM-DD'));
											setFieldTouched('shooting_date', true);
											this.toggleCalendarDropdown();
										}}
										isDayDisabled={(day) => this.calendarIsDayDisabled(day)}
										isDaySelected={(day) => {
											return values.shooting_date ? day.isSame(values.shooting_date) : false;
										}}
									/>
								</ModalBody>
							</BaseModal>
						</Form>
					)}
			</Formik>
		);
	}
}

function mapStateToProps(state) {
	const { vendor_calendar } = state;
    return {
		vendor_calendar
    };
}

const translation = withNamespaces(['order', 'package', 'default'])(InputForm);
const connected = connect(mapStateToProps)(translation);
export { connected as InputForm };